import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { UserDto } from './dto';

class UserService extends AxiosRequestService {
  async getUser(): Promise<UserDto> {
    const request = await this.privateRequest.get('/account/getuserinfo');

    return request.data;
  }
}

export const UserApi = new UserService();
