import { AppExportButton } from '@app/shared/components/AppExportButton';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useMemo } from 'react';
import { ProductLabelingHeader } from './components/ProductLabelingHeader';
import { ProductTable } from './components/Table';
import { useHistory } from 'react-router';
import { PRODUCT_VALUE_SECTION_1 } from '@app/routes/urls';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import qs from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { selectUpdateAllocation } from '@app/store/productValue/selectors';
import {
  resetUpdateAllocationAction,
  saveAllocationValueAction,
  updateAllocationValueAction,
} from '@app/store/productValue';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';

export const ProductLabeling: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const updateDate = useSelector(selectUpdateAllocation);
  const hasChangedData = useMemo(() => {
    return !!Object.keys(updateDate).length;
  }, [updateDate]);
  const goToGroupTable = () => {
    history.push({
      pathname: PRODUCT_VALUE_SECTION_1.urlTemplate,
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  };
  const onFetchDataAfterImportFile = useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);
  const handleSaveAllocationLevel = useCallback(() => {
    const data = Object.keys(updateDate).map((item) => {
      return {
        valueAllocation: updateDate[item].valueAllocation,
        mainGroupId: item,
      };
    });
    dispatch(
      saveAllocationValueAction({
        payload: {
          data,
        },
      })
    );
  }, [updateDate, dispatch]);
  const handlePromptConfirm = useCallback(() => {
    dispatch(updateAllocationValueAction({}));
  }, [dispatch]);
  const handleDontSave = useCallback(() => {
    dispatch(resetUpdateAllocationAction({}));
  }, [dispatch]);
  return (
    <>
      <ProductLabelingHeader
        shouldAskForConfirm={hasChangedData}
        onConfirm={handlePromptConfirm}
        onSkip={handleDontSave}
      />
      <Grid item xs={12} style={{ flex: '1', display: 'flex' }}>
        <ProductTable />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadProductValueSecondSection'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton url={'/CreateExcel/ProductValueSecondSection'} />
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={goToGroupTable}>
            {'Back'}
          </Button>
        </Grid>

        <Grid item>
          <RoleProtectedButton
            color="primary"
            variant="contained"
            onClick={handleSaveAllocationLevel}
          >
            Save
          </RoleProtectedButton>
        </Grid>
      </Grid>
      <PageLeavePrompt
        onSkip={handleDontSave}
        when={hasChangedData}
        onConfirm={handlePromptConfirm}
      />
    </>
  );
};
