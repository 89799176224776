import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { selectSelectedFilters } from '@app/store/filters-module/filters-state/selectors';
import {
  saveValueRightsAction,
  selectValueIsOpen,
  selectValueUpdateData,
  setValueRightsDataAction,
  toggleModalAction,
  updateValueRightsAction,
} from '@app/store/settings/value-rights';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersSettings } from '../FiltersSettings';
import { ConfigModal } from './components/ConfigModal';
import { ValueRightsTable } from './components/ValueRightsTable';

export const ProductValueRights: React.FC = () => {
  const dispatch = useDispatch();
  const updateData = useSelector(selectValueUpdateData);
  const modal = useSelector(selectValueIsOpen);

  const filtersValue: TFilterItemResponse[] = useSelector(
    selectSelectedFilters
  );
  const filter4Level = filtersValue.filter((f) => f.level === 4);

  const when = React.useMemo(() => {
    return !!Object.keys(updateData).length;
  }, [updateData]);
  const validSave = React.useMemo(() => {
    let valid = true;
    if (Object.keys(updateData).length) {
      Object.values(updateData).forEach((i) => {
        const validManager = i.managers.some(
          (s) => s.isPrimaryProductOwner === true
        );
        if (!validManager) {
          valid = false;
        }
      });
      return !valid;
    }
    return true;
  }, [updateData]);
  const onSkip = React.useCallback(() => {
    dispatch(updateValueRightsAction({ items: {} }));
  }, [dispatch]);
  const onSave = React.useCallback(() => {
    dispatch(saveValueRightsAction({}));
  }, [dispatch]);
  React.useEffect(() => {
    return () => {
      dispatch(setValueRightsDataAction({ items: [], total: 0 }));
      dispatch(updateValueRightsAction({ items: {} }));
    };
  }, [dispatch]);
  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item container xs={10}>
            <Grid container spacing={2}>
              <FiltersSettings
                when={when}
                onSkip={onSkip}
                onSave={onSave}
                coFilter={false}
              />
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
          <Grid item container xs={2}>
            <Grid container wrap="nowrap" justify="flex-end" spacing={2}>
              <Grid item>
                <RoleProtectedButton
                  variant="contained"
                  onClick={() => dispatch(toggleModalAction({ isOpen: true }))}
                  color="primary"
                  style={{ height: '100%' }}
                >
                  Configure Rights
                </RoleProtectedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <PageLeavePrompt
          disabledSave={validSave}
          onSkip={onSkip}
          onConfirm={onSave}
          when={when}
        />
      </Grid>
      <Grid item xs={12} container>
        <ValueRightsTable />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
       <Grid item>
         <AppExportButton
          url={'/CreateExcel/ProductValueRights'}
          isPost={true}
            postData={{
              orderColumn: 0,
              isAsc: true,
              limit: 10,
              groupIds: filter4Level.map((i) => i.id)
            }}
         />
        </Grid>
      </Grid>
      {modal ? <ConfigModal /> : null}
    </Grid>
  );
};
