import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { AppDropdown } from '@app/shared/components/AppDropdown/AppDropdown';
import { AppSelect } from '@app/shared/components/AppSelect';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import { selectCoData } from '@app/store/filters-module/co';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setFiltersIsFullListAction } from '@app/store/filters-module/filters-state';

export interface VolumeDriverFiltersPayload {
  co: TCommercialOrganization;
}

interface Props {
  value: VolumeDriverFiltersPayload;
  onChange: (value: VolumeDriverFiltersPayload) => void;
}

const VolumeDriverFiltersDumb = (props: Props) => {
  const { value, onChange } = props;
  const dispatch = useDispatch();

  const coList = useSelector(selectCoData);

  useEffect(() => {
    dispatch(setFiltersIsFullListAction({ isFullList: false }));
  }, [dispatch]);

  const handleChange = React.useCallback(
    (e: any) => {
      const coId = e.target.value;
      const co = coList.find((co) => co.id === coId) || null;

      onChange({
        ...value,
        co: co || ({} as TCommercialOrganization),
      });
    },
    [value, onChange, coList]
  );

  return (
    <AppDropdown head={'CO'}>
      <AppDropdown.Item title="CO">
        <AppSelect
          value={value.co.id || ''}
          onChange={handleChange}
          disabled={!!!coList.length}
        >
          {coList.map((co) => (
            <AppSelect.Option key={co.id} value={co.id}>
              {co.name}
            </AppSelect.Option>
          ))}
        </AppSelect>
      </AppDropdown.Item>
    </AppDropdown>
  );
};

export const VolumeDriverFilters = withConfirmation(['onChange'])(
  VolumeDriverFiltersDumb,
  ConfirmationModal
);
