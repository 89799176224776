import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';

export const FEATURE_NEGOTIATION_ROOM: TAccountRightsFeatureList =
  'NegotiationRoom';
export const FEATURE_NEGOTIATION_PRODUCT_LINE: TAccountRightsFeatureList =
  'NegotiationRoomProductLine';
export const FEATURE_NEGOTIATION_QUOTES: TAccountRightsFeatureList =
  'NegotiationRoomQuotes';
export const FEATURE_NEGOTIATION_EXSEPTIONS: TAccountRightsFeatureList =
  'NegotiationRoomExceptions';
