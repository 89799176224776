import { RoleProtectedInput } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedInput';
import * as React from 'react';
import { MarginSystemLevelInput } from '../MarginSystemLevelInput';
import Grid from '@material-ui/core/Grid';
import { marginSystemFormSchema } from './marginSystemFormSchema';
import { validate } from '@app/shared/utils/validate';

export interface MarginSystemFormData {
  name: string;
  description: string;
  firstLevel: string;
  secondLevel: string;
  thirdLevel: string;
  fourthLevel: string;
  fifthLevel: string;
}

interface Props {
  formId: string;
  onSubmit: (payload: MarginSystemFormData) => void;
}

const initialFormData: MarginSystemFormData = {
  name: '',
  description: '',
  firstLevel: null as any,
  secondLevel: 0 as any,
  thirdLevel: null as any,
  fourthLevel: null as any,
  fifthLevel: null as any,
};

export const MarginSystemForm = (props: Props) => {
  const { onSubmit, formId } = props;

  const [formData, setFormData] = React.useState<MarginSystemFormData>(
    initialFormData
  );

  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const handleChange = React.useCallback((e: any) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const errors = validate(formData, marginSystemFormSchema);

      if (Object.keys(errors).length) {
        setErrors(errors);
        return;
      }

      setFormData(initialFormData);

      onSubmit(formData);
    },
    [onSubmit, formData]
  );

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <Grid container wrap="nowrap" direction="column">
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter system name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors['name']}
              helperText={errors['name']}
            />
          </Grid>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <MarginSystemLevelInput
              placeholder="Enter level 1"
              name="firstLevel"
              value={formData.firstLevel || ''}
              onChange={handleChange}
              error={!!errors['firstLevel']}
              helperText={errors['firstLevel']}
            />
          </Grid>
          <Grid item>
            <MarginSystemLevelInput
              placeholder="Enter level 2"
              name="secondLevel"
              value={0}
              disabled={true}
              onChange={handleChange}
              error={!!errors['secondLevel']}
              helperText={errors['secondLevel']}
            />
          </Grid>
          <Grid item>
            <MarginSystemLevelInput
              placeholder="Enter level 3"
              name="thirdLevel"
              value={formData.thirdLevel || ''}
              onChange={handleChange}
              error={!!errors['thirdLevel']}
              helperText={errors['thirdLevel']}
            />
          </Grid>
          <Grid item>
            <MarginSystemLevelInput
              placeholder="Enter level 4"
              name="fourthLevel"
              value={formData.fourthLevel || ''}
              onChange={handleChange}
              error={!!errors['fourthLevel']}
              helperText={errors['fourthLevel']}
            />
          </Grid>
          <Grid item>
            <MarginSystemLevelInput
              placeholder="Enter level 5"
              name="fifthLevel"
              value={formData.fifthLevel || ''}
              onChange={handleChange}
              error={!!errors['fifthLevel']}
              helperText={errors['fifthLevel']}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
