import {
  setUpdateWizardPGAVolumeDriverAction,
  setWizardUpdateProductAllocationInfoAction,
} from '@app/store/wizard';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useWizardStepSkipSwitch = () => {
  const dispatch = useDispatch();

  const save = useCallback(
    (index: number): void => {
      switch (index) {
        case 0: {
          dispatch(setWizardUpdateProductAllocationInfoAction({ payload: {} }));
          break;
        }
        case 1: {
          //===== todo add store.ts reset functions =====
          console.log('Skip step 2');
          break;
        }
        case 2: {
          //===== todo add store.ts reset functions =====
          console.log('Skip step 3');
          break;
        }
        case 3: {
          dispatch(setUpdateWizardPGAVolumeDriverAction({ payload: {} }));
          break;
        }
      }
    },
    [dispatch]
  );

  return save;
};

export default useWizardStepSkipSwitch;
