export function flatArray<T = any>(array: T[], result = [] as T[]): any {
  array.forEach((item) => {
    if (!Array.isArray(item)) {
      result.push(item);
    } else {
      flatArray(item, result);
    }
  });

  return result;
}
