import { AppSelect } from '@app/shared/components/AppSelect';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import { SelectProps } from '@material-ui/core';
import * as React from 'react';
import { unitOptions } from './unit-options';

interface Props extends SelectProps {}

export const UnitSelect = (props: Props) => {
  const Options = React.useMemo(() => {
    return unitOptions.map((option) => (
      <AppSelect.Option value={option.value} key={option.name}>
        {option.name}
      </AppSelect.Option>
    ));
  }, []);

  return <RoleProtectedSelect {...props}>{Options}</RoleProtectedSelect>;
};
