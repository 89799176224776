import React from 'react';
import { Layout } from '@shared/components/Layout';
import { SimulatorWizard } from './components/SimulatorWizard';
import { useAccountRoleRights } from '@shared/hooks/useAccountRoleRights';
import { Wooops } from '@modules/Simulator/Wooops';

export const Simulator = () => {
  const [canVisit, canEdit] = useAccountRoleRights('Wizard');
  return (
    <Layout pageName="Wizard">
      {canVisit() ? <SimulatorWizard
        canEdit={canEdit()}
        /> : <Wooops />}
    </Layout>
  );
};
