import { Action } from 'redux';
import {
  closeSnackAction,
  enqueSnackAction,
  removeSnackAction,
} from './actions';
import { initialState, SnacksState } from './state';

export function snacksReducer(
  state = initialState,
  action: Action
): SnacksState {
  if (enqueSnackAction.is(action)) {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        {
          ...action.notification,
        },
      ],
    };
  }

  if (closeSnackAction.is(action)) {
    return {
      ...state,
      notifications: state.notifications.map((notification) =>
        action.dismissAll || notification.key === action.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      ),
    };
  }

  if (removeSnackAction.is(action)) {
    return {
      ...state,
      notifications: state.notifications.filter(
        (notification) => notification.key !== action.key
      ),
    };
  }

  return state;
}
