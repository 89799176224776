import { AppState } from '@app/store';

export const selectNegotiationExceptionsState = (store: AppState) =>
  store.negotiationRoom.negotiationExceptions;

export const selectNegotiationExceptionsData = (store: AppState) =>
  selectNegotiationExceptionsState(store).items;
export const selectNegotiationExceptionsTotal = (store: AppState) =>
  selectNegotiationExceptionsState(store).totalItems;
export const selectNegotiationExceptionsUpdate = (store: AppState) =>
  selectNegotiationExceptionsState(store).updateData;
export const selectIsOpenModal = (store: AppState) =>
  selectNegotiationExceptionsState(store).modalIsOpen;
export const selectNegotiationExceptionsLoading = (store: AppState) =>
  selectNegotiationExceptionsState(store).isLoading;
