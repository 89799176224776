import { call } from 'redux-saga/effects';
import {
  setBaseMarginMatrixOriginAction,
  setBaseMarginMatrixStateAction,
} from '@app/store/wizard';
import WizardServices from '@services/wizard/wizardServices';
import { put } from 'redux-saga/effects';
import { bmmConverterOriginToState } from '../utils/convetres';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';
import { select } from 'redux-saga/effects';
import { getWizardCurrentProductIdSelector } from '@app/store/wizard';
import { getValueAllocationQuerySelector } from '@app/store/wizard';

export function* fetchWizardBaseMarginMatrixSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);
    const productId = yield select(getWizardCurrentProductIdSelector);
    const valuesAllocations = yield select(getValueAllocationQuerySelector);

    const bmmOrigin = yield call(
      WizardServices.getBaseMarginMatrix,
      coId,
      [productId],
      valuesAllocations
    );
    yield put(
      setBaseMarginMatrixOriginAction({
        payload: bmmOrigin.data,
      })
    );
    yield put(
      setBaseMarginMatrixStateAction({
        payload: bmmConverterOriginToState(bmmOrigin.data),
      })
    );
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
