import { CreateTemporaryUserRightsStateType } from '../UserRightsTable';
import {
  TeamReferenceDataType,
  UserReferenceDataType,
} from '@app/store/settings/user-rights';

//===== TYPES =====

const setPermanentUserType = 'SET_PERMANENT_USER';
const setTemporaryUserType = 'SET_TEMPORARY_USER';
const setTeamType = 'SET_TEAM';
const setSectionRightValueType = 'SET_SECTION_RIGHTS_VALUE';
const setValidFromType = 'SET_VALID_FROM';
const setValidToType = 'SET_VALID_TO';
const resetStateType = 'RESET_STATE';
const setIsTemporaryApprovalInCrmType = 'SET_IS_TEMPORARY_APPROVAL_IN_CRM';

//===== INITIAL STATE =====

const sections = [
  {
    key: 'ProductValueLabeling',
    value: false,
  },
  {
    key: 'BaseMarginMatrix',
    value: false,
  },
  {
    key: 'Exceptions',
    value: false,
  },
  {
    key: 'VolumeDriverSystemConfiguration',
    value: false,
  },
  {
    key: 'VolumeDriverSystemAllocation',
    value: false,
  },
  {
    key: 'PackagingDriverConfiguration',
    value: false,
  },
  {
    key: 'PackagingDriverMargins',
    value: false,
  },
  {
    key: 'NegotiationRoomProductLine',
    value: false,
  },
  {
    key: 'NegotiationRoomQuotes',
    value: false,
  },
  {
    key: 'NegotiationRoomExceptions',
    value: false,
  },
  {
    key: 'SettingsProductRights',
    value: false,
  },
  {
    key: 'SettingsTeamsRights',
    value: false,
  },
  {
    key: 'SettingsUserRights',
    value: false,
  },
  {
    key: 'SettingsValueRights',
    value: false,
  },
  {
    key: 'Wizard',
    value: false,
  },
  {
    key: 'ContactAndSupport',
    value: false,
  },
];

const nowDate = new Date();
nowDate.setHours(0, 0, 0, 0);
const nextDate = new Date();
nextDate.setHours(23,59,59,999);

export const initialState: CreateTemporaryUserRightsStateType = {
  permanentUser: null,
  temporaryUser: null,
  profile: null,
  sections: sections,
  validFrom: nowDate.toISOString(),
  validTo: nextDate.toISOString(),
  isTemporaryApprovalInCRM: false
};

//===== REDUCER =====

export const createTemporaryUserRightsReducer = (
  state: CreateTemporaryUserRightsStateType,
  action: { type: string; payload?: any }
): CreateTemporaryUserRightsStateType => {
  switch (action.type) {
    case setPermanentUserType:
      return { ...state, permanentUser: action.payload };
    case setTemporaryUserType:
      return { ...state, temporaryUser: action.payload };
    case setTeamType:
      return { ...state, profile: action.payload };
    case setSectionRightValueType:
      return {
        ...state,
        sections: state.sections.map((i) =>
          i.key === action.payload.key ? action.payload : i
        ),
      };
    case setValidFromType:
      return { ...state, validFrom: action.payload };
    case setValidToType:
      return { ...state, validTo: action.payload };
    case resetStateType:
      return {
        permanentUser: null,
        temporaryUser: null,
        profile: null,
        sections: sections,
        validFrom: nowDate.toISOString(),
        validTo: nextDate.toISOString(),
        isTemporaryApprovalInCRM: false
      };
    case setIsTemporaryApprovalInCrmType:
      return { ...state, isTemporaryApprovalInCRM: action.payload };
    default:
      return state;
  }
};

//===== ACTIONS =====

export const setPermanentUserAction = (user: UserReferenceDataType | null) => ({
  type: setPermanentUserType,
  payload: user,
});
export const setTemporaryUserAction = (user: UserReferenceDataType | null) => ({
  type: setTemporaryUserType,
  payload: user,
});
export const setTeamAction = (team: TeamReferenceDataType | null) => ({
  type: setTeamType,
  payload: team,
});
export const setSectionRightsValueAction = (rights: {
  key: string;
  value: boolean;
}) => ({ type: setSectionRightValueType, payload: rights });
export const setValidFromAction = (value: string) => ({
  type: setValidFromType,
  payload: value,
});
export const setValidToAction = (value: string) => ({
  type: setValidToType,
  payload: value,
});
export const resetStateAction = () => ({ type: resetStateType });

export const setIsTemporaryApprovalInCrmAction = (value: boolean) => ({
  type: setIsTemporaryApprovalInCrmType,
  payload: value
});