import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import makeStyles from '@material-ui/styles/makeStyles';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { useDispatch } from 'react-redux';
import { setConfigurationsOpenedAction } from '@app/store/volume-driver/volume-driver-configurations';
import { SystemSelectionDialog } from './components/SystemSelectionDialog';
import { AppTableRef } from '@app/shared/components/AppTable';

const useStyles = makeStyles(() => ({
  button: { minHeight: '52px' },
}));

interface Props {
  msRef: React.MutableRefObject<AppTableRef>;
  vsRef: React.MutableRefObject<AppTableRef>;
}

export const SystemConfigurationDialog = (props: Props) => {
  const { msRef, vsRef } = props;

  const styles = useStyles();
  const dispatch = useDispatch();

  const openDialog = React.useCallback(() => {
    dispatch(setConfigurationsOpenedAction({ opened: true }));
  }, [dispatch]);

  const closeDialog = React.useCallback(() => {
    dispatch(setConfigurationsOpenedAction({ opened: false }));
  }, [dispatch]);

  const handleOpenDialogClick = React.useCallback(openDialog, [openDialog]);

  const beforeSubmit = React.useCallback(
    (type: 'ms' | 'vs') => {
      if (type === 'ms' && msRef.current) {
        msRef.current.resetPagination();
      }

      if (type === 'vs' && vsRef.current) {
        vsRef.current.resetPagination();
      }
    },
    [msRef, vsRef]
  );

  return (
    <React.Fragment>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item>
          <RoleProtectedButton
            color={'primary'}
            classes={{ root: styles.button }}
            onClick={handleOpenDialogClick}
          >
            <pre>Add System</pre>
          </RoleProtectedButton>
        </Grid>
        <Grid item container alignItems="center" justify="center">
          <Tooltip title={'Volume and margin systems are centrally defined'}>
            <HelpIcon color="primary" />
          </Tooltip>
        </Grid>
      </Grid>
      <SystemSelectionDialog
        beforeSubmit={beforeSubmit}
        closeDialog={closeDialog}
        openDialog={openDialog}
      />
    </React.Fragment>
  );
};
