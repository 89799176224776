import React from 'react';
import {
  SETTINGS_GROUP_RIGHTS,
  SETTINGS_TEAM_RIGHTS,
  SETTINGS_USER_RIGHTS,
  SETTINGS_VALUE_RIGHTS,
} from '@app/routes/urls';
import { TabRouterElement } from '@app/shared/components/TabRouterLayout';
import { ProductGroupRights } from './components/ProductGroupRights';
import { ProductValueRights } from './components/ProductValueRights';
import { TeamsRights } from './components/TeamsRights';
import { ProtectedComponent } from '@app/shared/components/ProtectedComponent';
import { UserRights } from './components/UserRights';

export const settingsTabRoutes: TabRouterElement[] = [
  {
    title: 'Team Rights',
    component: () => (
      <ProtectedComponent
        Component={TeamsRights}
        feature={'SettingsTeamsRights'}
      />
    ),
    link: SETTINGS_TEAM_RIGHTS.urlTemplate,
    feature: 'SettingsTeamsRights',
  },
  {
    title: 'User Rights',
    component: () => (
      <ProtectedComponent
        Component={UserRights}
        feature={'SettingsUserRights'}
      />
    ),
    link: SETTINGS_USER_RIGHTS.urlTemplate,
    feature: 'SettingsUserRights',
  },
  {
    title: 'Product groups rights',
    component: () => (
      <ProtectedComponent
        Component={ProductGroupRights}
        feature={'SettingsProductRights'}
      />
    ),
    link: SETTINGS_GROUP_RIGHTS.urlTemplate,
    feature: 'SettingsProductRights',
  },
  {
    title: 'Product value rights',
    component: () => (
      <ProtectedComponent
        Component={ProductValueRights}
        feature={'SettingsValueRights'}
      />
    ),
    link: SETTINGS_VALUE_RIGHTS.urlTemplate,
    feature: 'SettingsValueRights',
  },
];
