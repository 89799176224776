import React from 'react';
import { Layout } from '@shared/components/Layout';
import styles from './ProductValue.module.scss';
import Grid from '@material-ui/core/Grid';

import { Switch } from 'react-router';
import { productValueRoutes } from './definitions';
import { RoleBasedRoute } from '@app/shared/components/RoleBasedRoute';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';
import Typography from '@material-ui/core/Typography';

export const ProductValue: React.FC = () => {
  const [canVisit] = useAccountRoleRights(
    'ProductValueLabeling' as TAccountRightsFeatureList
  );
  return (
    <Layout pageName="Product Value">
      <Layout.Grid className={styles.productWrapper}>
        <Grid
          container
          direction="column"
          spacing={4}
          className={styles.contentGrid}
        >
          {!canVisit() ? (
            <>
              <Typography variant="h3" align="center">
                Woops.
              </Typography>
              <Typography variant="h4" align="center">
                Unfortunately you don't have permission to view this page :(
              </Typography>
            </>
          ) : (
            <Switch>
              {productValueRoutes.map((route) => (
                <RoleBasedRoute
                  component={route.component}
                  path={route.link}
                  exact
                  key={route.link}
                  feature={'ProductValueLabeling'}
                />
              ))}
            </Switch>
          )}
        </Grid>
      </Layout.Grid>
    </Layout>
  );
};
