import { Action } from 'redux';
import {
  setVolumeOptionsDataAction,
  setVolumeOptionsLoadingAction,
} from './actions';
import { initialState, VolumeOptionsState } from './store';

export function volumeOptionsReducer(
  state = initialState,
  action: Action
): VolumeOptionsState {
  if (setVolumeOptionsDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
    };
  }

  if (setVolumeOptionsLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }

  return state;
}
