//===== TYPE =====

const setOrderTableType = 'SET_ORDER_TABLE_TYPE';
const openEditModalType = 'OPEN_EDIT_MODAL';
const closeEditModalType = 'CLOSE_EDIT_MODAL';
const openDeleteModalType = 'OPEN_DELETE_MODAL';
const closeDeleteModalType = 'CLOSE_DELETE_MODAL';
const toggleDeleteItemType = 'TOGGLE_DELETE_ITEM';
const cleanDeleteItemsType = 'CLEAN_DELETE_ITEMS';

//===== STATE =====

export interface PackagingMarginTableState {
  delete: {
    modal: boolean;
    items: Record<string, boolean>;
  };
  editModal: {
    status: boolean;
    id?: string;
    index?: number;
    value?: string;
  };
  order: {
    column: string;
    index: number | null;
    direction: 'asc' | 'desc';
    isNumberString: boolean;
  };
}

export const initialPackagingMarginTableState: PackagingMarginTableState = {
  delete: {
    modal: false,
    items: {},
  },
  editModal: {
    status: false,
  },
  order: {
    column: '',
    index: null,
    direction: 'asc',
    isNumberString: false,
  },
};

//===== REDUCER =====

export const packagingMarginTableReducer = (
  state: PackagingMarginTableState,
  action: ActionType
): PackagingMarginTableState => {
  switch (action.type) {
    case setOrderTableType:
      return { ...state, order: action.payload };

    case openEditModalType:
      return {
        ...state,
        editModal: {
          status: true,
          id: action.payload.id,
          index: action.payload.index,
          value: action.payload.value,
        },
      };
    case closeEditModalType:
      return { ...state, editModal: { status: false } };

    case openDeleteModalType:
      return { ...state, delete: { ...state.delete, modal: true } };
    case closeDeleteModalType:
      return { ...state, delete: { ...state.delete, modal: false } };
    case cleanDeleteItemsType:
      return { ...state, delete: { ...state.delete, items: {} } };
    case toggleDeleteItemType:
      return {
        ...state,
        delete: {
          ...state.delete,
          items: {
            ...state.delete.items,
            [action.payload]: !state.delete.items[action.payload],
          },
        },
      };

    default:
      return state;
  }
};

//===== ACTION =====

export interface ActionType {
  type: string;
  payload?: any;
}

export const setOrderTableAction = (
  column: string,
  index: number | null,
  direction: 'asc' | 'desc',
  isNumberString: boolean
): ActionType => ({
  type: setOrderTableType,
  payload: { column, index, direction, isNumberString },
});

export const openEditModalAction = (
  id: string,
  index: number,
  value: string
): ActionType => ({ type: openEditModalType, payload: { id, index, value } });

export const closeEditModalAction = (): ActionType => ({
  type: closeEditModalType,
});

export const openDeleteModalAction = (): ActionType => ({
  type: openDeleteModalType,
});
export const closeDeleteModalAction = (): ActionType => ({
  type: closeDeleteModalType,
});
export const cleanDeleteItemsAction = (): ActionType => ({
  type: cleanDeleteItemsType,
});
export const toggleDeleteItemAction = (id: string): ActionType => ({
  type: toggleDeleteItemType,
  payload: id,
});
