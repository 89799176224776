import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';

interface SectionCheckboxPropsType {
  label: string;
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const SectionCheckbox = ({
  label,
  name,
  value,
  onChange,
}: SectionCheckboxPropsType) => {

  return (
    <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={() => onChange(!value)}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    </Grid>
  );
};
