import { SETTINGS_TEAM_RIGHTS } from '@app/routes/urls';
import { LocationChangeAction } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { fetchTeamRightsAction } from '../actions';

export function* teamRightsWorkerSaga(action: LocationChangeAction) {
  if (
    SETTINGS_TEAM_RIGHTS.match(action.payload.location.pathname, true)
      .isMatched &&
    action.payload.location.search
  ) {
    yield put(fetchTeamRightsAction({}));
  }
}
