import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { validatePercentValuePackagingMargin } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/util/validateMarginValue';
import { WizardPackagingMarginState } from '@app/shared/types/wizard';

interface EditMarginModalProps {
  status: boolean;
  data: WizardPackagingMarginState;
  editIndex?: number;
  value?: string;
  close: () => void;
  accept: (value: string) => void;
}

export const EditPackagingMarginModal = (props: EditMarginModalProps) => {
  const { status, data, editIndex, value, close, accept } = props;
  const [newValue, setNewValue] = useState('');

  //===== UPDATE NEW VALUE =====

  useEffect(() => {
    value && setNewValue(value);
  }, [setNewValue, value]);

  //===== VALIDATE =====

  const [isValid, setValid] = useState(false);

  useEffect(() => {
    if (editIndex != null) {
      setValid(
        validatePercentValuePackagingMargin(
          newValue,
          data.packaging[editIndex].marginType
        )
      );
    }
  }, [newValue, data.packaging, editIndex, setValid]);

  return (
    <Dialog open={status} onClose={close}>
      <DialogTitle id="alert-dialog-title">Edit packaging margin</DialogTitle>
      <DialogContent>
        <TextField
          error={!isValid}
          fullWidth
          type="number"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          disabled={!isValid}
          color="primary"
          onClick={() => accept(newValue)}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
