import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal';
import { GroupHierarchiesFilter } from '@app/shared/components/GroupHierarchiesFilter';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import {
  selectCoFilters,
  selectCoSelectedFilters,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { Grid } from '@material-ui/core';
import { CoFilter } from '@app/shared/components/CoFilter';
import {
  selectFilters,
  selectSelectedFilters,
  setFiltersIsFullListAction,
  setSelectedFiltersAction,
} from '@app/store/filters-module/filters-state';

const InterceptedGroupHierarchyFilters = withConfirmation([
  'onFiltersChange',
  'onAllocationLevelChange',
])(GroupHierarchiesFilter, ConfirmationModal);

interface Props {
  onSkip: () => void;
  onSave: () => void;
  when: boolean;
  coFilter?: boolean;
}

export const FiltersSettings: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { when, onSave, onSkip, coFilter = true } = props;
  const coFilters = useSelector(selectCoFilters);

  const filtersValue = useSelector(selectCoSelectedFilters);
  const filtersWithOutCo = useSelector(selectFilters);
  const filtersValueWhithOutCo = useSelector(selectSelectedFilters);

  useEffect(() => {
    dispatch(setFiltersIsFullListAction({ isFullList: true }));
  }, [dispatch]);

  const onChange = React.useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);

  const handleFiltersChange = React.useCallback(
    (filters: TFilterItemResponse[]) => {
      dispatch(
        setSelectedCoFilters({
          filters,
        })
      );
      onChange();
    },
    [onChange, dispatch]
  );
  const onFiltersChangeWithOutCo = React.useCallback(
    (filters: TFilterItemResponse[]) => {
      dispatch(setSelectedFiltersAction({ filters }));
      onChange();
    },
    [onChange, dispatch]
  );

  return (
    <React.Fragment>
      <Grid item xs={3}>
        <InterceptedGroupHierarchyFilters
          filters={coFilter ? coFilters : filtersWithOutCo}
          allocationLevels={[]}
          onFiltersChange={
            coFilter ? handleFiltersChange : onFiltersChangeWithOutCo
          }
          // disabled={disabled}
          allocationLevelsValue={[]}
          onAllocationLevelChange={() => {}}
          filtersValue={coFilter ? filtersValue : filtersValueWhithOutCo}
          modalProps={undefined}
          shouldAskForConfirm={when}
          onSkip={onSkip}
          onConfirm={onSave}
          disabledAllocation={true}
        />
      </Grid>
      {coFilter ? (
        <Grid item xs={3}>
          <CoFilter
            shouldAskForConfirm={when}
            onSkip={onSkip}
            onConfirm={onSave}
          />
        </Grid>
      ) : null}
    </React.Fragment>
  );
};
