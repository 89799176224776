import { put, call, select } from 'redux-saga/effects';
import { setProductGroup, fetchProductGroups } from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { ProductGroups } from '@app/shared/types/baseMargin';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';

export function* fetchProductGroupWorkerSaga(
  action: ReturnType<typeof fetchProductGroups>
) {
  try {
    const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);

    const result: ProductGroups = yield call(
      BaseMarginService.fetchProductGroup,
      {
        ...action.payload,
        coId: selectedCo.id,
      }
    );
    yield put(setProductGroup({ payload: result }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch product group error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch product group error',
        },
      })
    );
  }
}
