import { all, put } from 'redux-saga/effects';
import { saveMarginSystemConfigurationsAction } from '../../margin-system-configurations';
import { saveVolumeSystemConfigurationsAction } from '../../volume-system-configurations';
import { saveConfigurationsAction } from '../actions';

export function* saveConfigurationsSaga(
  action: ReturnType<typeof saveConfigurationsAction>
) {
  yield all([
    yield put(saveVolumeSystemConfigurationsAction({})),
    yield put(saveMarginSystemConfigurationsAction({})),
  ]);
}
