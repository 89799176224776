import { put } from 'redux-saga/effects';
import { enqueSnackAction } from '@app/store/snacks';
import { fetchUserRightsDataAction, selectUserRightsLoading } from '..';
import { SETTINGS_USER_RIGHTS } from '@app/routes/urls';
import { LocationChangeAction } from 'connected-react-router';

export function* fetchUserRightsSagaWorker(action: LocationChangeAction) {
  try {
    const { pathname, search } = action.payload.location;

    if (SETTINGS_USER_RIGHTS.match(pathname, true).isMatched && search) {
      yield put(fetchUserRightsDataAction({}));
    }

    console.log('fetchUserRightsSagaWorker');
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get User Rights.',
          options: {
            variant: 'error',
          },
          key: 'team-rights-fetch',
        },
      })
    );
  }
}
