import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import styles from './AppTable.module.scss';
import { appTableContext, AppTableContext } from './appTableContext';
import Scrollbars from 'react-scrollbars-custom';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import {
  AppTablePagination,
  useAppTablePagination,
} from '../AppTablePagination';
import { AppTableRow } from './components/AppTableRow';
import { AppTableHead } from './components/AppTableHead';
import { AppTableBody } from './components/AppTableBody';
import { AppTableHeadCell } from './components/AppTableHeadCell';
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_PAGE_LIMIT,
} from '../AppTablePagination/consts';
import Fade from '@material-ui/core/Fade';
import { AppTableCell } from './components/AppTableCell';

export interface AppTableRef {
  resetPagination: () => void;
}

interface Props<T> {
  data: T[];
  dataCount: number;
  loading?: boolean;
  onDelete?: (items: T[]) => void;
  deletableItems?: boolean;
  onPageChange?: (page: number, items: number, skip: number) => void;
  children: React.ReactNode;
  useInMemoryPagination?: boolean;
  disabledPagination?: boolean;
  refObj?: React.MutableRefObject<AppTableRef>;
  tableLayout?: 'auto' | 'fixed';
  isEmpty?: boolean;
  hidePagination?: boolean;
}

export function AppTable<T>(props: Props<T>) {
  const {
    data,
    dataCount,
    children,
    loading = false,
    onPageChange,
    deletableItems = false,
    onDelete,
    useInMemoryPagination,
    disabledPagination = false,
    refObj,
    tableLayout,
    isEmpty,
    hidePagination = false,
  } = props;

  const [
    activePage,
    handlePageChangePagination,
    itemsPerPage,
    handleItemPerPageChangePagination,
    resetPaginationHook,
  ] = useAppTablePagination(
    DEFAULT_PAGINATION_PAGE,
    DEFAULT_PAGINATION_PAGE_LIMIT,
    !useInMemoryPagination
  );

  React.useLayoutEffect(() => {
    if (refObj) {
      refObj.current = { resetPagination: resetPaginationHook };
    }
  }, [refObj, resetPaginationHook]);

  const [applySort, setSortHandler] = React.useState<{
    sort: ((data: T[], order: 'asc' | 'desc') => T[]) | null;
    order: 'asc' | 'desc';
  }>({ sort: null, order: 'asc' });

  const [deletionQueue, setDeletionQueue] = React.useState<T[]>([]);

  const enqueueForDeletion = React.useCallback((item: T) => {
    setDeletionQueue((prev) => [...prev, item]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ejectDeletedItem = React.useCallback((item: T) => {
    setDeletionQueue((prev) =>
      prev.filter((deletedItem) => deletedItem !== item)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const releaseDeletionQueue = React.useCallback(() => {
    const queue = deletionQueue;

    setDeletionQueue([]);

    return queue;
  }, [deletionQueue]);

  const deleteItems = React.useCallback(() => {
    onDelete && onDelete(releaseDeletionQueue());
  }, [releaseDeletionQueue, onDelete]);

  const [activeCellId, setActiveCellId] = React.useState<string>('');

  const tableRef = React.createRef<HTMLTableElement>();

  const context: AppTableContext = React.useMemo(() => {
    const ctx: AppTableContext<any> = {
      applySortHandler: (handler, order) => {
        setSortHandler({ sort: handler, order });
      },
      data: applySort.sort ? applySort.sort([...data], applySort.order) : data,
      loading,
      deletableItems,
      enqueueForDeletion,
      ejectDeletedItem,
      deletionQueue: deletionQueue,
      deleteItems,
      setActiveCell: setActiveCellId,
      activeCellId,
      scrollRef: tableRef,
      isEmpty,
    };

    return ctx;
  }, [
    applySort,
    data,
    loading,
    deletableItems,
    enqueueForDeletion,
    ejectDeletedItem,
    deletionQueue,
    deleteItems,
    activeCellId,
    tableRef,
    isEmpty,
  ]);

  const changeHandler = React.useCallback(
    (activePage: number, limit: number, skip: number) => {
      onPageChange && onPageChange(activePage, limit, skip);
    },
    [onPageChange]
  );

  const handleChangePage = React.useCallback(
    (evt: any, page: number) => {
      handlePageChangePagination(evt, page);
      changeHandler(page, itemsPerPage, page === 0 ? 0 : page * itemsPerPage);
    },
    [handlePageChangePagination, itemsPerPage, changeHandler]
  );

  const handleItemPerPageChange = React.useCallback(
    (evt: any) => {
      handleItemPerPageChangePagination(evt);

      changeHandler(
        activePage,
        evt.target.value,
        activePage === 0 ? 0 : activePage * evt.target.value
      );
    },
    [handleItemPerPageChangePagination, activePage, changeHandler]
  );

  return (
    <appTableContext.Provider value={context}>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        className={styles.tableContainer}
      >
        <Paper className={styles.paperWrapper}>
          <Fade in={loading} timeout={{ enter: 500, exit: 500 }}>
            <LinearProgress className={styles.progress}></LinearProgress>
          </Fade>
          <Grid item xs={12} style={{ height: '0' }} container>
            <Grid
              item
              xs={12}
              style={{ height: '100%' }}
              direction="column"
              container
            >
              <Scrollbars
                trackXProps={{
                  style: { left: '0', width: 'calc(100% - 10px)' },
                }}
                trackYProps={{
                  style: { top: '0', height: 'calc(100% - 10px)' },
                }}
              >
                <Table
                  ref={tableRef}
                  style={{ tableLayout: tableLayout ? tableLayout : 'fixed' }}
                >
                  {children}
                </Table>
              </Scrollbars>
            </Grid>
          </Grid>
          <Divider />
          <Grid item className={styles.paginationWrapper}>
            {!hidePagination ? (
              <AppTablePagination
                page={dataCount ? activePage : 0}
                rowsPerPage={itemsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleItemPerPageChange}
                count={dataCount}
                disabled={loading || disabledPagination}
              ></AppTablePagination>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    </appTableContext.Provider>
  );
}

AppTable.Head = AppTableHead;
AppTable.Body = AppTableBody;
AppTable.HeadCell = AppTableHeadCell;
AppTable.Row = AppTableRow;
AppTable.Cell = AppTableCell;
