export const calculator = (
  bmd: number,
  bmdt: number,
  c: number,
  pd: number,
  pdt: number,
  vd: number,
  tableView: string
): number => {
  let tm = 0;
  let tp = 0;
  let tma = 0;

  // BMD and PD are %
  if (bmdt === 0 && pdt === 0) {
    const {
      bbcal_targetmargin,
      bbcal_totaltargetprice,
      bbcal_thetargetmarginabsoluteterms,
    } = CalculatePricingValuesWhenBMDAndPDPercent(bmd, pd, vd, c);
    tm = bbcal_targetmargin;
    tp = bbcal_totaltargetprice;
    tma = bbcal_thetargetmarginabsoluteterms;
  }

  // BMD is a number and PD is %
  if (bmdt > 0 && pdt === 0) {
    if (pd > 0) {
      tm = 0;
      tp = 0;
      tma = 0;
    } else {
      const {
        bbcal_targetmargin,
        bbcal_totaltargetprice,
        bbcal_thetargetmarginabsoluteterms,
      } = CalculatePricingValuesWhenBMDNumberAndPDPercent(bmd, vd, c);
      tm = bbcal_targetmargin;
      tp = bbcal_totaltargetprice;
      tma = bbcal_thetargetmarginabsoluteterms;
    }
  }

  // BMD and PD are number
  if (bmdt > 0 && pdt > 0) {
    const {
      bbcal_targetmargin,
      bbcal_totaltargetprice,
      bbcal_thetargetmarginabsoluteterms,
    } = CalculatePricingValuesWhenBMDAndPDNumbers(bmd, pd, vd, c);
    tm = bbcal_targetmargin;
    tp = bbcal_totaltargetprice;
    tma = bbcal_thetargetmarginabsoluteterms;
  }

  // BMD is % and PD is a number
  if (bmdt === 0 && pdt > 0) {
    const {
      bbcal_targetmargin,
      bbcal_totaltargetprice,
      bbcal_thetargetmarginabsoluteterms,
    } = CalculatePricingValuesWhenBMDPercentAndPDNumber(bmd, pd, vd, c);
    tm = bbcal_targetmargin;
    tp = bbcal_totaltargetprice;
    tma = bbcal_thetargetmarginabsoluteterms;
  }

  switch (tableView) {
    case 'absolute':
      return tma;
    case '%':
      return tm;
    case 'price':
      return tp;
  }

  return 0;
};

const CalculatePricingValuesWhenBMDAndPDPercent = (
  bmd: number,
  bbcal_packagingdriver: number,
  bbcal_volumedriver: number,
  bbcal_costtotal: number
) => {
  var bbcal_targetmargin = CalculateTargetMargin1(
    bmd,
    bbcal_packagingdriver,
    bbcal_volumedriver
  );
  var bbcal_totaltargetprice = CalculateTargetPrice1(
    bbcal_costtotal,
    bbcal_targetmargin
  );
  var bbcal_thetargetmarginabsoluteterms = CalculateTargetMarginAbsolute1(
    bbcal_totaltargetprice,
    bbcal_costtotal
  );

  return {
    bbcal_targetmargin,
    bbcal_totaltargetprice,
    bbcal_thetargetmarginabsoluteterms,
  };
};

const CalculatePricingValuesWhenBMDNumberAndPDPercent = (
  bmd: number,
  bbcal_volumedriver: number,
  bbcal_costtotal: number
) => {
  var bbcal_thetargetmarginabsoluteterms = CalculateTargetMarginAbsolute2(
    bmd,
    0,
    bbcal_volumedriver
  );

  var bbcal_totaltargetprice = CalculateTargetPrice2(
    bbcal_costtotal,
    bbcal_thetargetmarginabsoluteterms
  );

  var bbcal_targetmargin = CalculateTargetMargin3(
    bbcal_thetargetmarginabsoluteterms,
    bbcal_totaltargetprice
  );

  return {
    bbcal_targetmargin,
    bbcal_totaltargetprice,
    bbcal_thetargetmarginabsoluteterms,
  };
};

const CalculatePricingValuesWhenBMDAndPDNumbers = (
  bmd: number,
  bbcal_packagingdriver: number,
  bbcal_volumedriver: number,
  bbcal_costtotal: number
) => {
  var bbcal_thetargetmarginabsoluteterms = CalculateTargetMarginAbsolute2(
    bmd,
    bbcal_packagingdriver,
    bbcal_volumedriver
  );
  var bbcal_totaltargetprice = CalculateTargetPrice2(
    bbcal_costtotal,
    bbcal_thetargetmarginabsoluteterms
  );
  var bbcal_targetmargin = CalculateTargetMargin3(
    bbcal_thetargetmarginabsoluteterms,
    bbcal_totaltargetprice
  );

  return {
    bbcal_targetmargin,
    bbcal_totaltargetprice,
    bbcal_thetargetmarginabsoluteterms,
  };
};

const CalculatePricingValuesWhenBMDPercentAndPDNumber = (
  bmd: number,
  bbcal_packagingdriver: number,
  bbcal_volumedriver: number,
  bbcal_costtotal: number
) => {
  let bbcal_thetargetmarginabsoluteterms: number;
  let bbcal_totaltargetprice: number;
  let bbcal_targetmargin: number;

  if (bbcal_packagingdriver > 0) {
    bbcal_thetargetmarginabsoluteterms = CalculateTargetMarginAbsolute2(
      bmd,
      bbcal_packagingdriver,
      bbcal_volumedriver,
      bbcal_costtotal
    );
    bbcal_totaltargetprice = CalculateTargetPrice2(
      bbcal_costtotal,
      bbcal_thetargetmarginabsoluteterms
    );
    bbcal_targetmargin = CalculateTargetMargin3(
      bbcal_thetargetmarginabsoluteterms,
      bbcal_totaltargetprice
    );
  } else {
    bbcal_targetmargin = CalculateTargetMargin2(
      bmd,
      0,
      bbcal_volumedriver,
      bbcal_costtotal
    );
    bbcal_totaltargetprice = CalculateTargetPrice1(
      bbcal_costtotal,
      bbcal_targetmargin
    );
    bbcal_thetargetmarginabsoluteterms = CalculateTargetMarginAbsolute1(
      bbcal_totaltargetprice,
      bbcal_costtotal
    );
  }

  return {
    bbcal_targetmargin,
    bbcal_totaltargetprice,
    bbcal_thetargetmarginabsoluteterms,
  };
};

const CalculateTargetMargin1 = (bmd: number, pd: number, vd: number) => {
  return (bmd / 100 + pd / 100) * (1 + vd / 100) * 100;
};

const CalculateTargetMargin2 = (
  bmd: number,
  pd: number,
  vd: number,
  c: number
) => {
  var pdc = (pd / (pd + bmd / 100 + c)) * 100; // Packaging driver % converted
  var sm = (pdc / 100 + bmd / 100) * 100; // Subtotal margin %

  return (sm / 100) * (1 + vd / 100) * 100;
};

const CalculateTargetMargin3 = (tma: number, tp: number) => {
  return (tma / tp) * 100;
};

const CalculateTargetPrice1 = (c: number, tm: number) => {
  return c / (1 - tm / 100);
};

const CalculateTargetPrice2 = (c: number, tma: number) => {
  return tma + c;
};

const CalculateTargetMarginAbsolute1 = (tp: number, c: number) => {
  return tp - c;
};

const CalculateTargetMarginAbsolute2 = (
  bmd: number,
  pd: number,
  vd: number,
  c: number | null = null
) => {
  if (c != null) {
    bmd = c / (1 - bmd / 100) - c; //  Base margin default (converted to flat amount)
  }

  return (bmd + pd) * (1 + vd / 100);
};
