import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

interface Props {
  isOpened?: boolean;
  isClearing?: boolean;
  title: string;
  onClose: () => void;
  onClear?: () => void;
  onConfirm: () => void;
  children: JSX.Element | JSX.Element[];
}

export const InputContainer = (props: Props) => {
  const {
    onClose,
    onClear,
    onConfirm,
    isOpened = false,
    isClearing = false,
    title,
    children,
  } = props;

  return (
    <Dialog open={isOpened} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        {Array.isArray(children) ? (
          children.map((child) => <Box paddingTop={2}>{child}</Box>)
        ) : (
          <Box paddingTop={2}>{children}</Box>
        )}
      </DialogContent>
      <DialogActions disableSpacing>
        <Box p={2}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </Grid>
            {isClearing && (
              <Grid item>
                <Button onClick={onClear} color="primary">
                  Clear
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button color="primary" variant="contained" onClick={onConfirm}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
