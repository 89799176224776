import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { DEFAULT_PAGINATION_PAGE_LIMIT } from '@app/shared/components/AppTablePagination/consts';
import { throttleEffect } from '@app/shared/utils/sagaUtils/sagaUtils';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import {
  createMarginSystemConfigurationAction,
  fetchMarginSystemConfigurationsAction,
  setMarginSystemConfigurationsCreatingAction,
  setMarginSystemConfigurationsModalOpenedAction,
} from '../actions';

export function* createMarginSystemConfigurationSaga(
  action: ReturnType<typeof createMarginSystemConfigurationAction>
) {
  const {
    name,
    description,
    firstLevel,
    secondLevel,
    thirdLevel,
    fourthLevel,
    fifthLevel,
  } = action;

  try {
    yield put(setMarginSystemConfigurationsCreatingAction({ creating: true }));
    yield throttleEffect(
      yield call(VolumeDriverApi.createMarginSystemConfiguration, {
        name,
        description,
        firstLevel: String(firstLevel),
        secondLevel: String(secondLevel),
        thirdLevel: String(thirdLevel),
        fourthLevel: String(fourthLevel),
        fifthLevel: String(fifthLevel),
      })
    );
    yield put(setMarginSystemConfigurationsCreatingAction({ creating: true }));
    yield put(
      setMarginSystemConfigurationsModalOpenedAction({ opened: false })
    );
    yield put(
      fetchMarginSystemConfigurationsAction({
        skip: 0,
        limit: DEFAULT_PAGINATION_PAGE_LIMIT,
      })
    );
    yield put(setMarginSystemConfigurationsCreatingAction({ creating: false }));
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Margin system configuration has been created.',
          options: {
            variant: 'success',
          },
          key: 'margin-system-configuration-create-success',
        },
      })
    );
  } catch (e) {
    yield put(setMarginSystemConfigurationsCreatingAction({ creating: false }));
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message:
              'Margin System configuration with provided name already exists, please try again.',
            options: {
              variant: 'warning',
            },
            key: 'margin-system-configuration-create-failed',
          },
        })
      );
    } else {
      yield put(
        enqueSnackAction({
          notification: {
            message: 'Failed to create Margin System configuration',
            options: {
              variant: 'error',
            },
            key: 'margin-system-configuration-create-failed',
          },
        })
      );
    }
  }
}
