import { RouteProps } from 'react-router-dom';
import { Home } from '@modules/Home';
import {
  FEATURE_PRODUCT_VALUE_LABELING,
  ProductValue,
} from '@modules/ProductValue';
import {
  BaseMarginMatrix,
  FEATURE_BASE_MARGIN_MATRIX,
} from '@modules/BaseMarginMatrix';
import { VolumeDriver } from '@modules/VolumeDriver/VolumeDriver';
import { Auth } from '@modules/Auth';
import * as urls from './urls';
import { FEATURE_WIZARD, Simulator } from '@modules/Simulator';
import { ResetPassword } from '@modules/ResetPassword';
import { FEATURE_SETTINGS, Settings } from '@modules/Settings';
import {
  FEATURE_PACKAGING_DRIVER,
  PackagingDriver,
} from '@modules/PackagingDriver';
import {
  FEATURE_NEGOTIATION_ROOM,
  NegotiationsRoom,
} from '@app/modules/NegotiationsRoom';
import { FEATURE_VOLUME_DRIVER } from '@app/modules/VolumeDriver';
import { ContactAndSupport } from '@app/modules/ContactAndSupport';

export const routes: RouteProps[] = [
  {
    path: urls.LOGIN_URL.urlTemplate,
    exact: true,
    component: Auth,
  },
  {
    path: urls.RESET_PASSWORD_URL.urlTemplate,
    exact: true,
    component: ResetPassword,
  },
];

export const privateRoutes = [
  {
    path: urls.ROOT_URL.urlTemplate,
    exact: true,
    component: Home,
  },
  {
    path: urls.PRODUCT_VALUE_URL.urlTemplate,
    component: ProductValue,
    feature: FEATURE_PRODUCT_VALUE_LABELING,
  },
  {
    path: urls.BASE_MARGIN_MATRIX.urlTemplate,
    component: BaseMarginMatrix,
    feature: FEATURE_BASE_MARGIN_MATRIX,
  },
  {
    path: urls.VOLUME_DRIVER.urlTemplate,
    component: VolumeDriver,
    feature: FEATURE_VOLUME_DRIVER,
  },
  {
    path: urls.CONTACT_AND_SUPPORT.urlTemplate,
    component: ContactAndSupport,
  },
  {
    path: urls.SETTINGS_URL.urlTemplate,
    component: Settings,
    feature: FEATURE_SETTINGS,
  },
  {
    path: urls.PACKAGING_DRIVER.urlTemplate,
    component: PackagingDriver,
    feature: FEATURE_PACKAGING_DRIVER,
  },
  {
    path: urls.SIMULATOR_WIZARD_URL.urlTemplate,
    component: Simulator,
    feature: FEATURE_WIZARD,
  },
  {
    path: urls.NEGOTIATIONS_URL.urlTemplate,
    component: NegotiationsRoom,
    feature: FEATURE_NEGOTIATION_ROOM,
  },
];
