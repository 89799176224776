import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import { setCurrencyCOAction } from '../action';
import { MapResponseCurrency } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes-currency,response';

export function* fetchCurrencyCoSaga() {
  try {
    const request: MapResponseCurrency[] = yield call(
      NegotiationRoomApi.getCurrencyCo
    );
    yield put(setCurrencyCOAction({ currencyCo: request }));
  } catch (e) {
    console.log('Failed to get quotes items', e.message);
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.title,
            options: {
              variant: 'error',
            },
            key: 'fetch quotes currency',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'fetch quotes currency',
        },
      })
    );
  }
}
