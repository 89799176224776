import { VOLUME_DRIVER_CONFIGURATIONS } from '@app/routes/urls';
import { TMarginSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-options.response';
import { TVolumeSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-options.response';
import { LocationChangeAction } from 'connected-react-router';
import { all, put, select, take } from 'redux-saga/effects';
import {
  fetchMarginOptionsAction,
  selectMarginOptionsItems,
  setMarginOptionsDataAction,
} from '../../margin-options';
import {
  fetchVolumeOptionsAction,
  selectVolumeOptionsItems,
  setVolumeOptionsDataAction,
} from '../../volume-options';

export function* volumeSystemConfigurationsWorkerSaga(
  action: LocationChangeAction
) {
  if (
    VOLUME_DRIVER_CONFIGURATIONS.match(action.payload.location.pathname)
      .isMatched
  ) {
    const requiredOptions: (
      | TMarginSystemOption[]
      | TVolumeSystemOption[]
    )[] = yield all([
      select(selectMarginOptionsItems),
      select(selectVolumeOptionsItems),
    ]);

    if (requiredOptions.some((options) => !options.length)) {
      yield all([
        yield put(fetchMarginOptionsAction({})),
        yield put(fetchVolumeOptionsAction({})),
      ]);

      yield all([
        take(setMarginOptionsDataAction.TYPE),
        take(setVolumeOptionsDataAction.TYPE),
      ]);
    }
  }
}
