import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';

export interface PackagingGroupsState {
  items: TPackagingOption[];
  loading: boolean;
  selectedPackagingGroups: TPackagingOption[];
  isFullList: boolean;
}

export const initialState: PackagingGroupsState = {
  items: [],
  loading: true,
  selectedPackagingGroups: [],
  isFullList: false,
};
