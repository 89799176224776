import { createUserRightsItemAction } from '@app/store/settings/user-rights';
import { call } from 'redux-saga/effects';
import { RightsApi } from '@services/rights-service/rights-service';
import { put } from 'redux-saga/effects';
import { enqueSnackAction } from '@app/store/snacks';
import { fetchUserRightsDataAction } from '@app/store/settings/user-rights';

export function* createUserRightsSaga(
  action: ReturnType<typeof createUserRightsItemAction>
) {
  try {
    yield call(RightsApi.createUserRights, action.payload);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'User rights successfully create.',
          options: {
            variant: 'success',
          },
          key: 'user-rights-updated',
        },
      })
    );
    yield put(fetchUserRightsDataAction({}));
  } catch (e) {
    console.log('Failed to create user rights', e.message);

    if (e.response) {
      let message =
        e.response.status === 409 || e.response.status === 400
          ? e.response.data.message
          : 'Something wrong...';

      yield put(
        enqueSnackAction({
          notification: {
            message: message,
            options: {
              variant: 'error',
            },
            key: 'user-rights-update',
          },
        })
      );
    }
  }
}
