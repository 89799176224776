import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { GetValueGroupRightsDto } from '@app/services/rights-service/dto/get-value-rights.dto';
import { TValueRights } from '@app/services/rights-service/response-interfaces/get-rights.response';
import { RightsApi } from '@app/services/rights-service/rights-service';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { selectSelectedFilters } from '@app/store/filters-module/filters-state';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  setValueRightsDataAction,
  setValueRightsLoadingAction,
} from '../actions';

export function* fetchValueRightsWorkerSaga() {
  try {
    yield put(setValueRightsLoadingAction({ loading: true }));
    const [skip, limit] = [getCurrentSkip(), getCurrentPageLimit()];
    const filtersValue: TFilterItemResponse[] = yield select(
      selectSelectedFilters
    );
    const filter4Level = filtersValue.filter((f) => f.level === 4);
    const dto: GetValueGroupRightsDto = {
      skip,
      limit,
      isAsc: true,
      orderColumn: 0,
      groupIds: filter4Level.map((i) => i.id),
    };
    const payload: { data: TValueRights[]; total: number } = yield call(
      RightsApi.getValueGroupRights,
      dto
    );
    yield put(
      setValueRightsDataAction({
        items: payload.data,
        total: payload.total,
      })
    );
  } catch (e) {
    console.log('Failed to fetch value rights', e.message);
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'value-rights-fatch',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'value-rights-fatch',
        },
      })
    );
  } finally {
    yield put(setValueRightsLoadingAction({ loading: false }));
  }
}
