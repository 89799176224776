import {
  updateBaseMarginExceptionAction,
  setBaseMarginLoadingAction,
  setUpdateBaseMarginException,
  setBaseMarginExceptionAction,
} from '../actions';
import { call, put, select } from 'redux-saga/effects';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { AppState } from '@app/store';
import { BaseMarginExceptions } from '@app/shared/types/baseMargin';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { enqueSnackAction } from '@app/store/snacks';
import { setBackdropLoadingAction } from '@app/store/app/actions';

export function* updateBaseMarginExceptionSaga(
  action: ReturnType<typeof updateBaseMarginExceptionAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));

    const coId: string = yield select(
      (state: AppState) => state.filters.co.selectedItem?.id
    );
    yield put(setBaseMarginLoadingAction({ payload: true }));
    yield call(BaseMarginService.updateBaseMarginExceptions, action.payload);
    const newData: BaseMarginExceptions = yield call(
      BaseMarginService.fetchBaseMarginExceptions,
      {
        coId,
        skip: getCurrentSkip(),
        limit: getCurrentPageLimit(),
      }
    );
    yield put(setUpdateBaseMarginException({ payload: {} }));
    yield put(setBaseMarginExceptionAction({ payload: newData }));
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Exceptions is saved',
          options: {
            variant: 'success',
          },
          key: 'save exception margin',
        },
      })
    );
  } catch (e) {
    yield put(setUpdateBaseMarginException({ payload: {} }));
    console.log(`Failed to update base margin exception: ${e.toString()}`);
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create update error',
          },
        })
      );
    } else {
      yield put(
        enqueSnackAction({
          notification: {
            message: 'Something wrong...',
            options: {
              variant: 'error',
            },
            key: 'error exceptions',
          },
        })
      );
    }
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
