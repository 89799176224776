import { RightsApi } from '@app/services/rights-service/rights-service';
import { throttleEffect } from '@app/shared/utils/sagaUtils/sagaUtils';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import {
  createTeamRightsAction,
  setTeamRightsSubmittingAction,
} from '../actions';
import { fetchTeamRightsSagaWorker } from './fetchTeamRightsSagaWorker';

export function* createTeamRightsSaga(
  action: ReturnType<typeof createTeamRightsAction>
) {
  const { profileName, sections, roleAccessType } = action;
  try {
    yield put(
      setTeamRightsSubmittingAction({ submitting: true, submitted: false })
    );
    yield throttleEffect(
      call(RightsApi.createTeamRights, {
        profileName,
        sections,
        roleAccessType,
      })
    );
    yield call(fetchTeamRightsSagaWorker);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Team rights successfully added.',
          options: {
            variant: 'success',
          },
          key: 'team-rights-add',
        },
      })
    );
  } catch (e) {
    console.log('Failed to add team rights', e.message);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to add team rights.',
          options: {
            variant: 'error',
          },
          key: 'team-rights-add',
        },
      })
    );
  } finally {
    yield put(
      setTeamRightsSubmittingAction({ submitting: false, submitted: true })
    );
  }
}
