import { Action } from 'redux';
import {
  setIndustiesDataAction,
  setIndustiesLoadingAction,
  setSelectedIndustries,
} from './actions';
import { IndustriesState, initialState } from './state';

export function industriesReducer(
  state = initialState,
  action: Action
): IndustriesState {
  if (setIndustiesLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setIndustiesDataAction.is(action)) {
    return {
      ...state,
      items: action.payload.items,
      total: action.payload.total,
    };
  }

  if (setSelectedIndustries.is(action)) {
    return {
      ...state,
      selectedIndustries: action.filters,
    };
  }

  return state;
}
