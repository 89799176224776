//===== TYPES =====

const openEditModalType = 'OPEN_EDIT_MODAL';
const closeEditModalType = 'CLOSE_EDIT_MODAL';
const openDeleteModalType = 'OPEN_DELETE_MODAL';
const closeDeleteModalType = 'CLOSE_DELETE_MODAL';
const openDefaultModalType = 'OPEN_DEFAULT_MODAL';
const closeDefaultModalType = 'CLOSE_DEFAULT_MODAL';
const setTableOrderType = 'SET_TABLE_ORDER_TYPE';
const toggleDeleteMarginsByIdType = 'TOGGLE_DELETE_MARGIN_BY_ID';
const resetDeleteMarginsType = 'RESET_DELETE_MARGINS';

//===== STATE =====

export interface BaseMarginMatrixTableState {
  editMarginModal: {
    status: boolean;
    id: string;
    value?: string;
  };
  deleteMarginModal: {
    status: boolean;
  };
  editDefaultMarginModal: {
    status: boolean;
    unit?: number;
    value?: string;
  };
  deleteMargins: Record<string, boolean>;
  order: {
    column: string;
    direction: 'asc' | 'desc';
  };
}

export const baseMarginMatrixTableInitState: BaseMarginMatrixTableState = {
  editMarginModal: {
    status: false,
    id: '',
  },
  deleteMarginModal: {
    status: false,
  },
  editDefaultMarginModal: {
    status: false,
  },
  deleteMargins: {},
  order: {
    column: '',
    direction: 'asc',
  },
};

//===== REDUCER =====

export const baseMarginMatrixTableReducer = (
  state: BaseMarginMatrixTableState,
  action: ActionType
): BaseMarginMatrixTableState => {
  switch (action.type) {
    case openEditModalType:
      return {
        ...state,
        editMarginModal: {
          status: true,
          ...action.payload,
        },
      };

    case closeEditModalType:
      return { ...state, editMarginModal: { status: false, id: '' } };

    case openDeleteModalType:
      return {
        ...state,
        deleteMarginModal: { status: true },
      };

    case closeDeleteModalType:
      return { ...state, deleteMarginModal: { status: false } };

    case openDefaultModalType:
      return {
        ...state,
        editDefaultMarginModal: {
          status: true,
          ...action.payload,
        },
      };
    case closeDefaultModalType:
      return { ...state, editDefaultMarginModal: { status: false } };

    case setTableOrderType:
      return { ...state, order: action.payload };

    case toggleDeleteMarginsByIdType:
      return {
        ...state,
        deleteMargins: {
          ...state.deleteMargins,
          [action.payload]: !state.deleteMargins[action.payload],
        },
      };

    case resetDeleteMarginsType:
      return { ...state, deleteMargins: {} };

    default:
      return state;
  }
};

//===== ACTION =====

export interface ActionType {
  type: string;
  payload?: any;
}

export const openEditModalAction = (id: string, value: string): ActionType => ({
  type: openEditModalType,
  payload: { id, value },
});

export const closeEditModalAction = (): ActionType => ({
  type: closeEditModalType,
});

export const openDeleteModalAction = (): ActionType => ({
  type: openDeleteModalType,
});

export const closeDeleteModalAction = (): ActionType => ({
  type: closeDeleteModalType,
});

export const openDefaultModalAction = (
  value: string,
  unit: number
): ActionType => ({
  type: openDefaultModalType,
  payload: { value, unit },
});

export const closeDefaultModalAction = (): ActionType => ({
  type: closeDefaultModalType,
});

export const setTableOrderAction = (
  column: string,
  direction: 'asc' | 'desc'
): ActionType => ({ type: setTableOrderType, payload: { column, direction } });

export const toggleDeleteMarginsByIdActions = (id: string): ActionType => ({
  type: toggleDeleteMarginsByIdType,
  payload: id,
});

export const resetDeleteMarginsAction = (): ActionType => ({
  type: resetDeleteMarginsType,
});
