import { Action } from 'redux';
import {
  setMarginSystemConfigurationsCreatingAction,
  setMarginSystemConfigurationsDataAction,
  setMarginSystemConfigurationsLoadingAction,
  setMarginSystemConfigurationsModalOpenedAction,
  updateMarginSystemConfigurationAction,
  resetMarginSystemConfigurationsUpdatedItemsAction,
} from './actions';
import { initialState, MarginSystemConfigurationsState } from './store';

export function marginSystemConfigurationsReducer(
  state = initialState,
  action: Action
): MarginSystemConfigurationsState {
  if (setMarginSystemConfigurationsDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
      totalItems: action.total,
    };
  }

  if (setMarginSystemConfigurationsLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }

  if (setMarginSystemConfigurationsModalOpenedAction.is(action)) {
    return {
      ...state,
      isCreationModalOpened: action.opened,
    };
  }

  if (setMarginSystemConfigurationsCreatingAction.is(action)) {
    return {
      ...state,
      isCreating: action.creating,
    };
  }

  if (updateMarginSystemConfigurationAction.is(action)) {
    return {
      ...state,
      updatedConfigurations: {
        ...state.updatedConfigurations,
        [action.id]: action.updatedPayload,
      },
    };
  }

  if (resetMarginSystemConfigurationsUpdatedItemsAction.is(action)) {
    return {
      ...state,
      items: state.items.map((item) => ({
        ...item,
        ...state.updatedConfigurations[item.id],
      })),
      updatedConfigurations: {},
    };
  }

  return state;
}
