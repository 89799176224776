import { setTPRCOAction } from '../actions';
import { call, put } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';

export function* fetchTPRCOSaga() {
  try {
    const coData = yield call(WizardServices.getCO);
    yield put(setTPRCOAction({ payload: coData.data }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardProductGroupAllocationLevelsWorkerSaga: ${e.toString()}`
    );
  }
}
