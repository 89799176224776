import { SETTINGS_VALUE_RIGHTS } from '@app/routes/urls';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import {
  fetchCoAction,
  fetchCoFiltersAction,
  selectCoFiltersId,
  selectSelectedCo,
  setSelectedCo,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import {
  selectFilters,
  selectFiltersIsFullList,
} from '@app/store/filters-module/filters-state';
import { fetchFiltersSaga } from '@app/store/filters-module/filters-state/sagas/fetchFIltersSaga';
import { LocationChangeAction } from 'connected-react-router';
import { call, put, select, take } from 'redux-saga/effects';
import { fetchValueRightsAction } from '../actions';
import { selectPackagingGroupsIsFullList } from '@app/store/filters-module/packaging-groups/selectors';

export function* valueRightsWorkerSaga(action: LocationChangeAction) {
  const { pathname, search } = action.payload.location;
  if (SETTINGS_VALUE_RIGHTS.match(pathname, true).isMatched && search) {
    const selectedCo: TCommercialOrganization | null = yield select(
      selectSelectedCo
    );
    if (!selectedCo) {
      yield put(fetchCoAction({}));
      yield take(setSelectedCo.TYPE);
    }
    const filtersCoId: string = yield select(selectCoFiltersId);

    const filterIsFullList: boolean = yield select(selectFiltersIsFullList);
    const packagingIsFullList: boolean = yield select(
      selectPackagingGroupsIsFullList
    );

    if (
      selectedCo?.id !== filtersCoId ||
      packagingIsFullList !== filterIsFullList
    ) {
      yield put(fetchCoFiltersAction({}));

      yield take(setSelectedCoFilters.TYPE);
    }
    const filters: TFilterItemResponse[] = yield select(selectFilters);
    if (!filters.length) {
      yield call(fetchFiltersSaga);
    }
    yield put(fetchValueRightsAction({}));
  }
}
