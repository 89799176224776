import { Action } from 'redux';
import {
  setCurrencyCOAction,
  setQuotesDataAction,
  setQuotesLoadingAction,
  setUpdateDataQuotesAction,
} from './action';
import { initialState, QuotesState } from './state';

export function quotesReducer(
  state = initialState,
  action: Action
): QuotesState {
  if (setQuotesDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
      totalItems: action.total,
    };
  }

  if (setQuotesLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }
  if (setUpdateDataQuotesAction.is(action)) {
    return {
      ...state,
      updateData: action.updateData,
    };
  }
  if (setCurrencyCOAction.is(action)) {
    return {
      ...state,
      currencyCO: action.currencyCo,
    };
  }
  return state;
}
