const ELLIPSIS = '...';

export function truncateStringAndAmmendSuffix(
  string: string,
  limit: number,
  suffix = ELLIPSIS
) {
  const limitWithSuffix = limit - suffix.length;

  if (limit <= suffix.length)
    throw new Error(
      `Limit is ${limit} lower or equal to suffix length, which should not be the case.`
    );

  const truncatedString = string.slice(0, limitWithSuffix);

  return `${truncatedString}${suffix}`;
}
