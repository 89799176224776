import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { call, put, select } from 'redux-saga/effects';
import { selectUpdateDataQuotes } from '../selectors';
import { SaveQuotesDTO } from '@services/negotiation-room-service/dto/save-quotes.dto';
import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { enqueSnackAction } from '@app/store/snacks';
import { fetchQuotesDataAction, setUpdateDataQuotesAction } from '../action';

export function* saveQuotesSaga() {
  try {
    const selectedCo: TCommercialOrganization | null = yield select(
      selectSelectedCo
    );
    const updateData: Record<string, TQuotesItem> = yield select(
      selectUpdateDataQuotes
    );
    const DTO: SaveQuotesDTO = {
      coId: selectedCo?.id as string,
      items: Object.values(updateData).map((i) => i),
    };
    yield call(NegotiationRoomApi.saveQuotesItems, DTO);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Save successfully',
          options: {
            variant: 'success',
          },
          key: 'save quotes currency',
        },
      })
    );
    yield put(fetchQuotesDataAction({}));
    yield put(setUpdateDataQuotesAction({ updateData: {} }));
  } catch (e) {
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message || e.response.data.title,
            options: {
              variant: 'error',
            },
            key: 'save quotes currency',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'save quotes currency',
        },
      })
    );
  }
}
