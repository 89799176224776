import { put, call } from 'redux-saga/effects';
import {
  setPageProductsLevelAction,
  setProductsSavingAction,
} from '../actions';
import { ProductsApi } from '@services/product-service/product-service';
import { enqueSnackAction } from '@app/store/snacks';

export function* setPageProductsLevelSaga(
  action: ReturnType<typeof setPageProductsLevelAction>
) {
  try {
    const dataToDb = {
      mainProducts: [...action.payload.mainProducts],
      filterGroupIds: [...action.payload.filterGroupIds],
      productGroupValues: [...action.payload.productGroupValues],
    };
    yield put(setProductsSavingAction({ saving: true }));
    yield call(ProductsApi.setPageProductsLevel, dataToDb);
    if (action.payload.isSave) {
      yield put(
        enqueSnackAction({
          notification: {
            message: 'Products successfully saved.',
            options: {
              variant: 'success',
            },
            key: 'products-save-success',
          },
        })
      );
    }
  } catch (error) {
    console.log('page groups saga', error.message);
    if (action.payload.isSave) {
      yield put(
        enqueSnackAction({
          notification: {
            message: 'Failed to save products, please try again later.',
            options: {
              variant: 'error',
            },
            key: 'products-save-failed',
          },
        })
      );
    }
  } finally {
    yield put(setProductsSavingAction({ saving: false }));
  }
}
