import { call, put, take } from 'redux-saga/effects';
import {
  fetchBaseMarginAction,
  saveBaseMarginAction,
  setBaseMarginAction,
  setBaseMarginLoadingAction,
} from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { enqueSnackAction } from '@app/store/snacks';
import { setBackdropLoadingAction } from '@app/store/app/actions';

export function* saveBaseMarginSaga(
  action: ReturnType<typeof saveBaseMarginAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));

    yield put(setBaseMarginLoadingAction({ payload: true }));
    yield call(BaseMarginService.saveBaseMargin, action.payload);
    yield put(fetchBaseMarginAction({}));
    yield take([setBaseMarginAction.TYPE]);

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Margins are saved',
          options: {
            variant: 'success',
          },
          key: 'save margins',
        },
      })
    );
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'save margin',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'save margin',
        },
      })
    );
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
