export interface FormatMessageTemplate {
  pattern: string | RegExp;
  value: any;
}

export function formatErrorMessageTemplate(
  template: string,
  formatters: FormatMessageTemplate[]
) {
  return formatters.reduce((str, formatter) => {
    return str.replace(formatter.pattern, formatter.value);
  }, template);
}
