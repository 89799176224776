import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';

import { TGetPackagingConfigurationsResposne } from './response-interfaces/get-configurations.response';
import { GetConfigurationListDto } from './dto/get-configuration-list.dto';
import { SaveConfigurationsDto } from './dto/save-configurations.dto';
import {
  TGetPackagingMarginListResponse,
  TPackagingMarginListItem,
  TPackagingMarginResponseListItem,
} from './response-interfaces/get-margin-list.response';
import { GetMarginListDto } from './dto/get-margin-list.dto';
import { SaveMarginDto } from './dto/save-margin.dto';
import { SaveMarginBulk } from './dto/save-margin-bulk.dto';
import { TGroupItem } from '../groups-service/response-interfaces/get-groups.response';
import { ValueAllocationIdComposer } from '@app/shared/utils/value-allocation-id-composer';
import { GetCommercialOrganizationsWithCurrencyResponse } from './response-interfaces/get-commercial-organizations-with-currency.response';

class PackagingDriverService extends AxiosRequestService {
  saveMarginBulk = async (dto: SaveMarginBulk) => {
    await this.privateRequest.put(
      'PackagingDriver/BulkUpdatePackagingMargin',
      dto
    );
  };

  saveMargin = async (dto: SaveMarginDto): Promise<void> => {
    dto.items = dto.items.map((item) => ({
      ...item,
      productGroupId: ValueAllocationIdComposer.decompose(item.productGroupId)
        .id,
    }));

    await this.privateRequest.put('PackagingDriver/UpdatePackagingMargin', dto);
  };

  getMarginList = async (
    dto: GetMarginListDto
  ): Promise<TGetPackagingMarginListResponse<TPackagingMarginListItem>> => {
    const request = await this.privateRequest.post<
      TGetPackagingMarginListResponse<TPackagingMarginResponseListItem>
    >('/PackagingDriver/GetPackagingMargins', dto);

    const normalizedItems = request.data.data.map((item) => ({
      ...item,
      packagingGroups: item.packagingGroups.map((group) => ({
        ...group,
        value: group.value.replace(',', '.'),
      })),
    }));

    request.data.data = normalizedItems;

    return this.normalizeMarginListResponse(request.data);
  };

  private normalizeMarginListResponse = (
    response: TGetPackagingMarginListResponse<TPackagingMarginResponseListItem>
  ): TGetPackagingMarginListResponse<TPackagingMarginListItem> => {
    const keyToLevelMap = {
      firstGroupName: 1,
      secondGroupName: 2,
      thirdGroupName: 3,
      fourthGroupName: 4,
    };
    const mapResult = {
      ...response,
      data: response.data.map((item) => {
        const result = {} as TPackagingMarginListItem;

        result.id = ValueAllocationIdComposer.compose(item);
        result.valueAllocationLevel = item.valueAllocation;
        result.packagingGroups = item.packagingGroups;
        result.managerName = item.productManagerName;
        result.groupLevels = [...Object.keys(keyToLevelMap)].map(
          (key, index) => {
            const groupLevel: TGroupItem = {
              id: String(index),
              name: item[key as keyof typeof item] as string,
              level: keyToLevelMap[key as keyof typeof keyToLevelMap],
              parentId: null,
            };

            return groupLevel;
          }
        );

        return result;
      }),
    };

    return mapResult;
  };

  saveConfigurations = async (dto: SaveConfigurationsDto) => {
    await this.privateRequest.put(
      '/PackagingDriver/UpdatePackagingConfiguration',
      dto
    );
  };

  getConfigurationsList = async (
    dto: GetConfigurationListDto
  ): Promise<TGetPackagingConfigurationsResposne> => {
    const request = await this.privateRequest.post(
      '/PackagingDriver/GetPackagingConfigurations',
      dto
    );

    return {
      data: request.data.data,
      total: request.data.total,
    };
  };

  getCommercialOrganizationsWithCurrency = async (): Promise<
    GetCommercialOrganizationsWithCurrencyResponse[]
  > => {
    const result = await this.privateRequest.get(
      '/Filter/CommercialOrganizationsWithCurrency'
    );

    return result.data;
  };
}

export const PackagingDriverApi = new PackagingDriverService();
