import { call, put, select, take } from 'redux-saga/effects';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';
import {
  fetchWizardMarginSystemAction,
  getValueAllocationQuerySelector,
  getWizardCurrentProductIdSelector,
  setWizardMarginSystemAction,
  setWizardPGAVolumeDriverAction,
} from '@app/store/wizard';
import WizardServices from '@services/wizard/wizardServices';

export function* fetchWizardPGAVolumeDriverSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);
    const productId = yield select(getWizardCurrentProductIdSelector);
    const valuesAllocations = yield select(getValueAllocationQuerySelector);

    yield put(fetchWizardMarginSystemAction({}));
    yield take(setWizardMarginSystemAction.TYPE);

    const pgaVolumeDriver = yield call(
      WizardServices.getPGAVolumeDriver,
      coId,
      [productId],
      valuesAllocations
    );
    yield put(
      setWizardPGAVolumeDriverAction({ payload: pgaVolumeDriver.data })
    );
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
