export const validateMarginValue = (
  value: string,
  unitType: number = 0
): boolean => {
  const valueNumb = Number(value);
  return unitType === 0
    ? valueNumb >= 0 && valueNumb <= 99.99
    : valueNumb >= 0 && valueNumb <= 999999.99;
};

export const validatePercentValue = (value: string): boolean => {
  const valueNumb = Number(value);
  return valueNumb >= 0 && valueNumb <= 99.99;
};

export const validatePercentValuePackagingMargin = (
  value: string,
  unitType: number
): boolean => {
  const valueNumb = Number(value);
  if (unitType === 0) {
    return valueNumb >= -100 && valueNumb <= 100;
  } else {
    return valueNumb >= -999999 && valueNumb <= 999999;
  }
};

export const validatePercentValueWithEmpty = (value: string): boolean => {
  const valueNumb = Number(value);
  return (valueNumb >= 0 && valueNumb <= 99.99) || value === '—';
};

export const validatePercentValueWithEmptyPackagingMargin = (
  value: string,
  unitType: number
): boolean => {
  const valueNumb = Number(value);
  if (unitType === 0) {
    return (valueNumb >= -100 && valueNumb <= 100) || value === '—';
  } else {
    return (valueNumb >= -999999 && valueNumb <= 999999) || value === '—';
  }
};
