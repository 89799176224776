import { defineActionGroup } from 'rd-redux-utils';
import {
  ProductGroupAllocations,
  ProductGroupLabeling,
  UpdateAllocationsLevel,
  UpdateAllocationValue,
  UpdateAllocationValueDTO,
  UpdateBulkAllocationLevelDTO,
  UpdateBulkAllocationValueDTO,
} from '@shared/types/productValue';
import { TFilterAllocationLevels } from '@app/services/filter-service/response-interfaces/get-filters.response';

export const productValueActionGroup = defineActionGroup(
  'PRODUCT_VALUE_ACTIONS'
);

export const fetchProductGroupAllocations = productValueActionGroup.defineAction(
  'FETCH PRODUCT GROUP ALLOCATIONS'
);

export const setProductGroupAllocations = productValueActionGroup.defineAction<{
  payload: {
    total: number;
    data: ProductGroupAllocations[];
  };
}>('SET PRODUCT GROUP ALLOCATIONS');

export const updateAllocationsLevelAction = productValueActionGroup.defineAction(
  'UPDATE ALLOCATION LEVELS'
);

export const resetUpdateLevelAction = productValueActionGroup.defineAction(
  'RESET LEVEL UPDATE'
);

export const updateBulkAllocationLevelAction = productValueActionGroup.defineAction<{
  payload: UpdateBulkAllocationLevelDTO;
}>('UPDATE BULK ALLOCATION LEVEL');

export const setLoadingGroupsTableAction = productValueActionGroup.defineAction<{
  payload: boolean;
}>('SET LOADING GROUPS TABLE');

export const setUpdateDataLevel = productValueActionGroup.defineAction<{
  payload: Record<string, UpdateAllocationsLevel>;
}>('SET UPDATE DATA LEVEL');

//table group labeling

export const fetchProductGroupLabeling = productValueActionGroup.defineAction(
  'FETCH GROUP LABELING'
);

export const setProductGroupLabeling = productValueActionGroup.defineAction<{
  payload: {
    total: number;
    data: ProductGroupLabeling[];
  };
}>('SET GROUP LABELING');

export const updateBulkAllocationValueAction = productValueActionGroup.defineAction<{
  payload: UpdateBulkAllocationValueDTO;
}>('UPDATE BULK ALLOCATION VALUE');

export const updateAllocationValueAction = productValueActionGroup.defineAction(
  'UPDATE ALLOCATION VALUE'
);

export const saveAllocationValueAction = productValueActionGroup.defineAction<{
  payload: UpdateAllocationValueDTO;
}>('SAVE ALLOCATION VALUE');

export const resetUpdateAllocationAction = productValueActionGroup.defineAction(
  'RESET ALLOCATION UPDATE'
);

export const setLoadingGroupsLabelingTableAction = productValueActionGroup.defineAction<{
  payload: boolean;
}>('SET LOADING LABELING GROUPS TABLE');
export const setUpdateDataAllocation = productValueActionGroup.defineAction<{
  payload: Record<string, UpdateAllocationValue>;
}>('SET UPDATE DATA ALLOCATION');

export const importFileAction = productValueActionGroup.defineAction<{
  payload: {
    file: FormData;
    isProduct: boolean;
    productGroupIds: string[];
    valueAllocations: number[];
  };
}>('IMPORT FILE');

export const setProductValueAllocationLevels = productValueActionGroup.defineAction<{
  levels: TFilterAllocationLevels;
}>('SET PRODUCT VALUE ALLOCATION LEVELS');

export const setSelectedProductValueAllocationLevels = productValueActionGroup.defineAction<{
  levels: TFilterAllocationLevels;
}>('SET SELECTED PRODUCT VALUE ALLOCATION LEVELS');
