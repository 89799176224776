import TableBody, { TableBodyProps } from '@material-ui/core/TableBody';
import * as React from 'react';
import { appTableContext, AppTableContext } from '../../appTableContext';
import { EmptyListRow } from './components/EmptyListRow';

interface Props<T> extends TableBodyProps {
  children: (items: T[]) => React.ReactNodeArray;
  contentColSpan?: number;
  noDataText?: string;
}

export function AppTableBody<TData = any>(props: Props<TData>) {
  const { children, contentColSpan = 0, noDataText, ...tbodyProps } = props;
  const { data, loading, isEmpty, deletableItems } = React.useContext<
    AppTableContext<TData>
  >(appTableContext);

  const EmptyBodyPlaceholder = React.useMemo(() => {
    if (isEmpty === undefined) return null;

    return (
      <EmptyListRow
        colSpan={deletableItems ? contentColSpan + 1 : contentColSpan}
        emptyListText={noDataText}
      />
    );
  }, [isEmpty, contentColSpan, deletableItems, noDataText]);

  const Children = React.useMemo(() => {
    return children(data);
  }, [children, data]);

  return (
    <TableBody
      {...tbodyProps}
      style={{ background: '#fff', opacity: loading ? '0.4' : '1' }}
    >
      {isEmpty ? EmptyBodyPlaceholder : Children}
    </TableBody>
  );
}
