import makeStyles from '@material-ui/styles/makeStyles';
import { TableCellProps } from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import * as React from 'react';
import { AppTableContext, appTableContext } from '../../appTableContext';
import { AppTableCell } from '../AppTableCell';

const styles = makeStyles(() => ({
  root: { background: '#f2f2f2', position: 'sticky', top: 0, zIndex: 15 },
}));

interface Props<T> extends TableCellProps {
  sortHandler?: (data: T[], order: 'asc' | 'desc') => T[];
  defaultSort?: boolean;
  order?: 'asc' | 'desc';
  id?: string;
  preWrap?: boolean;
  sticky?: boolean;
}

export function AppTableHeadCell<TData = any>(props: Props<TData>) {
  const {
    sortHandler,
    defaultSort,
    onClick,
    children,
    order = 'asc',
    id = '',
    preWrap,
    sticky = true,
    ...cellProps
  } = props;
  const classes = styles();

  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>(order);

  const {
    applySortHandler,
    setActiveCell,
    activeCellId,
  }: AppTableContext<TData> = React.useContext(appTableContext);

  const isActive = React.useMemo(() => id === activeCellId, [id, activeCellId]);

  React.useLayoutEffect(() => {
    if (defaultSort && sortHandler) {
      applySortHandler(sortHandler, order);
      setActiveCell(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCellClick = React.useCallback(
    (e: any) => {
      if (!sortHandler) return;

      if (isActive) {
        const nextSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

        applySortHandler(sortHandler, nextSortOrder);
        setSortOrder(nextSortOrder);
      } else {
        setActiveCell(id);
        applySortHandler(sortHandler, sortOrder);
      }
      onClick && onClick(e);
    },
    [
      sortHandler,
      onClick,
      applySortHandler,
      isActive,
      sortOrder,
      id,
      setActiveCell,
    ]
  );

  return sortHandler ? (
    <AppTableCell
      {...cellProps}
      classes={
        sticky ? { ...props.classes, root: classes.root } : { ...props.classes }
      }
    >
      <TableSortLabel
        active={isActive}
        direction={sortOrder}
        onClick={handleCellClick}
      >
        <pre>{children}</pre>
      </TableSortLabel>
    </AppTableCell>
  ) : (
    <AppTableCell
      onClick={handleCellClick}
      {...cellProps}
      classes={
        sticky ? { ...props.classes, root: classes.root } : { ...props.classes }
      }
    >
      {preWrap ? <pre>{children}</pre> : children}
    </AppTableCell>
  );
}
