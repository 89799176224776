import { call, put } from 'redux-saga/effects';
import { setIndustiesLoadingAction, setIndustiesDataAction } from '../actions';
import { FilterApi } from '@services/filter-service/filter-service';
import { ResponseIndustries } from '@services/filter-service/response-interfaces/get-industries';

export function* fetchIndustiesSaga() {
  try {
    yield put(setIndustiesLoadingAction({ loading: true }));
    const filters: ResponseIndustries = yield call(FilterApi.getIndustries);
    yield put(
      setIndustiesDataAction({
        payload: {
          items: filters.data,
          total: filters.total,
        },
      })
    );
  } catch (error) {
  } finally {
    yield put(setIndustiesLoadingAction({ loading: false }));
  }
}
