import React from 'react';
import {
  NEGOTIATIONS_EXCEPTIONS_URL,
  NEGOTIATIONS_QUOTES_URL,
  NEGOTIATIONS_URL_PRODUCT_LINE,
} from '@app/routes/urls';
import { TabRouterElement } from '@app/shared/components/TabRouterLayout';
import { ExceptionsTab } from './tabs/ExceptionsTab';
import { ProductLine } from './tabs/ProductLine';
import { QuotesTab } from './tabs/QuotesTab';
import { ProtectedComponent } from '@app/shared/components/ProtectedComponent';

export const negotiationRoomTabs: TabRouterElement[] = [
  {
    title: ProductLine.TAB_TITLE,
    link: NEGOTIATIONS_URL_PRODUCT_LINE.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={ProductLine}
        feature={'NegotiationRoomProductLine'}
      />
    ),
    feature: 'NegotiationRoomProductLine'
  },
  {
    title: QuotesTab.TAB_TITLE,
    link: NEGOTIATIONS_QUOTES_URL.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={QuotesTab}
        feature={'NegotiationRoomQuotes'}
      />
    ),
    feature: 'NegotiationRoomQuotes'
  },
  {
    title: ExceptionsTab.TAB_TITLE,
    link: NEGOTIATIONS_EXCEPTIONS_URL.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={ExceptionsTab}
        feature={'NegotiationRoomExceptions'}
      />
    ),
    feature: 'NegotiationRoomExceptions'
  },
];
