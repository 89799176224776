import React, { useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { validateMarginValue } from '../../BaseMarginMatrixTable/util/validateMarginValue';
import { useSelector } from 'react-redux';
import { getWizardBaseMarginMatrixSelector } from '@app/store/wizard';
import { useEffect } from 'react';

interface MarginValueFieldProps {
  handlerChange: (value: string) => void;
  marginValue: string | null;
  productValue: number | null;
  disabled: boolean;
  isValid: boolean;
  setValid: (is: boolean) => void;
}

export const MarginValueField = ({
  handlerChange,
  marginValue,
  productValue,
  disabled,
  isValid,
  setValid,
}: MarginValueFieldProps) => {
  //===== DATA FROM STORE =====

  const baseMarginMatrix = useSelector(getWizardBaseMarginMatrixSelector);
  const baseUnit: number = useMemo(
    () =>
      productValue === null
        ? 0
        : (baseMarginMatrix.filter((i) => i.valueAllocation === productValue)[0]
            .marginTypes as number),
    [baseMarginMatrix, productValue]
  );

  //===== VALIDATE =====

  useEffect(() => {
    setValid(
      marginValue !== null &&
        marginValue !== '' &&
        validateMarginValue(marginValue, baseUnit)
    );
  }, [marginValue, baseUnit, setValid]);

  return (
    <TextField
      disabled={disabled}
      fullWidth
      error={!isValid}
      type="number"
      value={marginValue === null ? '' : marginValue}
      onChange={(e) => handlerChange(e.target.value)}
    />
  );
};
