import { AppState } from '@app/store/store';

export const selectDriverMarginState = (store: AppState) =>
  store.driver.margins;

export const selectDriverMarginListItems = (store: AppState) =>
  selectDriverMarginState(store).items;

export const selectDriverMarginLoadingState = (store: AppState) =>
  selectDriverMarginState(store).loading;

export const selectDriverMarginTotalItems = (store: AppState) =>
  selectDriverMarginState(store).totalItems;

export const selectDriverMarginSavingState = (store: AppState) =>
  selectDriverMarginState(store).isSaving;

export const selectDriverMarginLoadedState = (store: AppState) =>
  selectDriverMarginState(store).isLoaded;

export const selectDriverCOCurrencies = (state: AppState) =>
  selectDriverMarginState(state).coWithCurrency?.coCurrencies ?? [];
