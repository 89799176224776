import React from 'react';
import landingBg from './landing-bg.png';

export { landingBg };
export const driverIcon = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7778 0.5H2.22222C1 0.5 0 1.5 0 2.72222V18.2778C0 19.5 1 20.5 2.22222 20.5H17.7778C19 20.5 20 19.5 20 18.2778V2.72222C20 1.5 19 0.5 17.7778 0.5ZM6.66667 16.0556H4.44444V8.27778H6.66667V16.0556ZM11.1111 16.0556H8.88889V4.94444H11.1111V16.0556ZM15.5556 16.0556H13.3333V11.6111H15.5556V16.0556Z"
      fill="#758391"
    />
  </svg>
);

export const expectionsIcon = (
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5 3H18.23C17.6 1.26 15.95 0 14 0C12.05 0 10.4 1.26 9.77 3H3.5C1.85 3 0.5 4.35 0.5 6V27C0.5 28.65 1.85 30 3.5 30H24.5C26.15 30 27.5 28.65 27.5 27V6C27.5 4.35 26.15 3 24.5 3ZM14 3C14.825 3 15.5 3.675 15.5 4.5C15.5 5.325 14.825 6 14 6C13.175 6 12.5 5.325 12.5 4.5C12.5 3.675 13.175 3 14 3ZM20 21H14V25.5L6.5 18L14 10.5V15H20V21Z"
      fill="#FF5000"
    />
  </svg>
);

export const marginIcon = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 8H7.5V13H12.5V8Z" fill="#758391" />
    <path d="M4.99998 0.5H0V5.49998H4.99998V0.5Z" fill="#758391" />
    <path d="M12.5 15.5H7.5V20.5H12.5V15.5Z" fill="#758391" />
    <path d="M4.99998 8H0V13H4.99998V8Z" fill="#758391" />
    <path d="M4.99998 15.5H0V20.5H4.99998V15.5Z" fill="#758391" />
    <path d="M20 0.5H15V5.49998H20V0.5Z" fill="#758391" />
    <path d="M12.5 0.5H7.5V5.49998H12.5V0.5Z" fill="#758391" />
    <path d="M20 8H15V13H20V8Z" fill="#758391" />
    <path d="M20 15.5H15V20.5H20V15.5Z" fill="#758391" />
  </svg>
);

export const negotiationIcon = (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8H19V9.5H12V8ZM12 5.5H19V7H12V5.5ZM12 10.5H19V12H12V10.5ZM20 0H2C0.9 0 0 0.9 0 2V15C0 16.1 0.9 17 2 17H20C21.1 17 22 16.1 22 15V2C22 0.9 21.1 0 20 0ZM20 15H11V2H20V15Z"
      fill="#758391"
    />
  </svg>
);

export const settingsIcon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0023 16C11.048 16 10.1328 15.6313 9.45797 14.9749C8.78318 14.3185 8.40408 13.4283 8.40408 12.5C8.40408 11.5717 8.78318 10.6815 9.45797 10.0251C10.1328 9.36875 11.048 9 12.0023 9C12.9566 9 13.8718 9.36875 14.5466 10.0251C15.2214 10.6815 15.6005 11.5717 15.6005 12.5C15.6005 13.4283 15.2214 14.3185 14.5466 14.9749C13.8718 15.6313 12.9566 16 12.0023 16ZM19.6407 13.47C19.6818 13.15 19.7127 12.83 19.7127 12.5C19.7127 12.17 19.6818 11.84 19.6407 11.5L21.8099 9.87C22.0052 9.72 22.0566 9.45 21.9333 9.23L19.8772 5.77C19.7538 5.55 19.4762 5.46 19.25 5.55L16.6902 6.55C16.1556 6.16 15.6005 5.82 14.9528 5.57L14.5724 2.92C14.5313 2.68 14.3154 2.5 14.0584 2.5H9.94616C9.68915 2.5 9.47326 2.68 9.43214 2.92L9.05176 5.57C8.40408 5.82 7.84894 6.16 7.31435 6.55L4.7545 5.55C4.52832 5.46 4.25075 5.55 4.12738 5.77L2.07128 9.23C1.93763 9.45 1.99931 9.72 2.19464 9.87L4.36384 11.5C4.32271 11.84 4.29187 12.17 4.29187 12.5C4.29187 12.83 4.32271 13.15 4.36384 13.47L2.19464 15.13C1.99931 15.28 1.93763 15.55 2.07128 15.77L4.12738 19.23C4.25075 19.45 4.52832 19.53 4.7545 19.45L7.31435 18.44C7.84894 18.84 8.40408 19.18 9.05176 19.43L9.43214 22.08C9.47326 22.32 9.68915 22.5 9.94616 22.5H14.0584C14.3154 22.5 14.5313 22.32 14.5724 22.08L14.9528 19.43C15.6005 19.17 16.1556 18.84 16.6902 18.44L19.25 19.45C19.4762 19.53 19.7538 19.45 19.8772 19.23L21.9333 15.77C22.0566 15.55 22.0052 15.28 21.8099 15.13L19.6407 13.47Z"
      fill="#758391"
    />
  </svg>
);

export const valueIcon = (
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.321 5.71466L9.99988 0.5L0.678955 5.71466L9.99988 10.9295L19.321 5.71466Z"
      fill="#758391"
    />
    <path
      d="M10.6765 22.4999L20 17.2837V6.84888L10.6765 12.0649V22.4999Z"
      fill="#758391"
    />
    <path
      d="M0 6.84888V17.2837L9.32335 22.4999V12.0649L0 6.84888Z"
      fill="#758391"
    />
  </svg>
);

export const volumneIcon = (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.26562 10.8125C3.78561 10.8125 4.25626 10.6039 4.60403 10.2688L6.51195 11.2227C6.50238 11.3004 6.48828 11.3768 6.48828 11.457C6.48828 12.5232 7.35571 13.3906 8.42188 13.3906C9.48804 13.3906 10.3555 12.5232 10.3555 11.457C10.3555 11.1594 10.2823 10.8808 10.1616 10.6287L12.7498 8.04051C13.0019 8.16119 13.2805 8.23438 13.5781 8.23438C14.6443 8.23438 15.5117 7.36694 15.5117 6.30078C15.5117 6.10004 15.4723 5.9102 15.4152 5.72809L17.664 4.0419C17.9708 4.24684 18.3386 4.36719 18.7344 4.36719C19.8005 4.36719 20.668 3.49976 20.668 2.43359C20.668 1.36743 19.8005 0.5 18.7344 0.5C17.6682 0.5 16.8008 1.36743 16.8008 2.43359C16.8008 2.63434 16.8402 2.82417 16.8973 3.00629L14.6485 4.69247C14.3417 4.48753 13.9739 4.36719 13.5781 4.36719C12.512 4.36719 11.6445 5.23462 11.6445 6.30078C11.6445 6.59837 11.7177 6.877 11.8384 7.1291L9.2502 9.7173C8.99809 9.59662 8.71947 9.52344 8.42188 9.52344C7.90189 9.52344 7.43124 9.73207 7.08347 10.0671L5.17555 9.11322C5.18512 9.03551 5.19922 8.95914 5.19922 8.87891C5.19922 7.81274 4.33179 6.94531 3.26562 6.94531C2.19946 6.94531 1.33203 7.81274 1.33203 8.87891C1.33203 9.94507 2.19946 10.8125 3.26562 10.8125Z"
      fill="#758391"
    />
    <path
      d="M21.3555 21.2109H20.668V7.58984C20.668 7.23367 20.3796 6.94531 20.0234 6.94531H17.4453C17.0891 6.94531 16.8008 7.23367 16.8008 7.58984V21.2109H15.5117V11.457C15.5117 11.1009 15.2234 10.8125 14.8672 10.8125H12.2891C11.9329 10.8125 11.6445 11.1009 11.6445 11.457V21.2109H10.3555V16.6133C10.3555 16.2571 10.0671 15.9688 9.71094 15.9688H7.13281C6.77664 15.9688 6.48828 16.2571 6.48828 16.6133V21.2109H5.19922V14.0352C5.19922 13.679 4.91086 13.3906 4.55469 13.3906H1.97656C1.62039 13.3906 1.33203 13.679 1.33203 14.0352V21.2109H0.644531C0.288361 21.2109 0 21.4993 0 21.8555C0 22.2116 0.288361 22.5 0.644531 22.5H21.3555C21.7116 22.5 22 22.2116 22 21.8555C22 21.4993 21.7116 21.2109 21.3555 21.2109Z"
      fill="#758391"
    />
  </svg>
);

export const wizardIcon = (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9394 0.5H8.48486L14.5455 8.5L8.48486 16.5H13.9394L20 8.5L13.9394 0.5Z"
      fill="#758391"
    />
    <path
      d="M5.45455 0.5H0L6.06061 8.5L0 16.5H5.45455L11.5152 8.5L5.45455 0.5Z"
      fill="#758391"
    />
  </svg>
);

export const logoutIcon = (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.79 13.79C8.18 14.18 8.81 14.18 9.2 13.79L12.79 10.2C13.18 9.81 13.18 9.18 12.79 8.79L9.2 5.2C8.81 4.81 8.18 4.81 7.79 5.2C7.4 5.59 7.4 6.22 7.79 6.61L9.67 8.5H1C0.45 8.5 0 8.95 0 9.5C0 10.05 0.45 10.5 1 10.5H9.67L7.79 12.38C7.4 12.77 7.41 13.41 7.79 13.79ZM16 0.5H2C0.89 0.5 0 1.4 0 2.5V5.5C0 6.05 0.45 6.5 1 6.5C1.55 6.5 2 6.05 2 5.5V3.5C2 2.95 2.45 2.5 3 2.5H15C15.55 2.5 16 2.95 16 3.5V15.5C16 16.05 15.55 16.5 15 16.5H3C2.45 16.5 2 16.05 2 15.5V13.5C2 12.95 1.55 12.5 1 12.5C0.45 12.5 0 12.95 0 13.5V16.5C0 17.6 0.9 18.5 2 18.5H16C17.1 18.5 18 17.6 18 16.5V2.5C18 1.4 17.1 0.5 16 0.5Z"
      fill="#758391"
    />
  </svg>
);

export const supportIcon = (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0.5H2C0.9 0.5 0.00999999 1.4 0.00999999 2.5L0 14.5C0 15.6 0.9 16.5 2 16.5H18C19.1 16.5 20 15.6 20 14.5V2.5C20 1.4 19.1 0.5 18 0.5ZM17.6 4.75L10.53 9.17C10.21 9.37 9.79 9.37 9.47 9.17L2.4 4.75C2.15 4.59 2 4.32 2 4.03C2 3.36 2.73 2.96 3.3 3.31L10 7.5L16.7 3.31C17.27 2.96 18 3.36 18 4.03C18 4.32 17.85 4.59 17.6 4.75Z"
      fill="#758391"
    />
  </svg>
);
