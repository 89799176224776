import { TProduct } from '@app/services/product-service/response-interfaces/get-products.response';
import { defineActionGroup } from 'rd-redux-utils';

export const productActions = defineActionGroup('PRODUCTS');

export const fetchProductsAction = productActions.defineAction<{
  groups: {
    groupId: string;
    allocationGroupId: string;
  }[];
}>('GET PRODUCTS');

export const setProductsLoadingAction = productActions.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setProductsSavingAction = productActions.defineAction<{
  saving: boolean;
}>('SET SAVING');

export const setProductsDataAction = productActions.defineAction<{
  payload: TProduct[];
  count: number;
}>('SET DATA');

export const setPageProductsLevelAction = productActions.defineAction<{
  payload: {
    mainProducts: {
      mainProductId: string;
      valueAllocationLevel: number;
    }[];
    filterGroupIds: string[];
    productGroupValues: number[];
    levels?: number[];
    isSave?: boolean;
  };
}>('SET PAGE PRODUCTS LEVEL');
