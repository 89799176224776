import { PACKAGING_DRIVER_MARGIN } from '@app/routes/urls';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import {
  fetchCoAction,
  fetchCoFiltersAction,
  selectCoFilters,
  selectCoFiltersId,
  selectSelectedCo,
  setSelectedCo,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import {
  fetchPackagingGroupsAction,
  setSelectedPackagingGroupsAction,
} from '@app/store/filters-module/packaging-groups/actions';
import { LocationChangeAction } from 'connected-react-router';
import { all, put, select, take } from 'redux-saga/effects';
import {
  fetchCOWithCurrencyAction,
  fetchDriverMarginListAction,
  fetchDriverMarginListDoneAction,
  setMarginListLoadedAction,
} from '../actions';
import { selectDriverMarginLoadedState } from '../selectors';
import { selectFiltersIsFullList } from '@app/store/filters-module/filters-state';
import { selectPackagingGroupsIsFullList } from '@app/store/filters-module/packaging-groups/selectors';

export function* driverMarginWorkerSaga(action: LocationChangeAction) {
  const { search, pathname } = action.payload.location;

  if (PACKAGING_DRIVER_MARGIN.match(pathname, true).isMatched && search) {
    const isLoaded: boolean = yield select(selectDriverMarginLoadedState);

    if (!isLoaded) {
      yield all([
        yield put(fetchCoAction({})),
        yield put(fetchPackagingGroupsAction({})),
      ]);

      yield all([
        take(setSelectedCo.TYPE),
        take(setSelectedPackagingGroupsAction.TYPE),
      ]);

      yield put(setMarginListLoadedAction({ loaded: true }));
    }
    const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
    const filtersCoId: string = yield select(selectCoFiltersId);
    const filterIsFullList: boolean = yield select(selectFiltersIsFullList);
    const packagingIsFullList: boolean = yield select(
      selectPackagingGroupsIsFullList
    );

    yield put(fetchCOWithCurrencyAction({ coId: selectedCo.id }));

    if (
      selectedCo.id !== filtersCoId ||
      filterIsFullList !== packagingIsFullList
    ) {
      yield put(fetchCoFiltersAction({}));

      yield take(setSelectedCoFilters.TYPE);
    }
    const coFilters: TFilterItemResponse[] = yield select(selectCoFilters);

    if (!coFilters.length) {
      yield put(fetchDriverMarginListDoneAction({ items: [], total: 0 }));
    } else {
      yield put(fetchDriverMarginListAction({}));
    }
  }
}
