import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import { SaveMarginDto } from '@app/services/packaging-driver-service/dto/save-margin.dto';
import { PackagingMarginTableData } from './components/PackagingMarginTable';

export function mapPackagingMarginsTableDataToRequestPayload(
  co: TCommercialOrganization,
  tableData: PackagingMarginTableData
): SaveMarginDto {
  return {
    coId: co.id,
    items: Object.entries(tableData).map((valuePair) => {
      const productGroupId = valuePair[0];
      const valuesList = valuePair[1];
      let allocation: null | number = null;

      const groups = Object.entries(valuesList).map((pair) => {
        const packagingGroupId = pair[0];
        const packagingOption = pair[1];

        if (allocation === null) {
          allocation = packagingOption.valueAllocation;
        }

        return {
          id: packagingGroupId,
          margin: packagingOption.value,
          marginType: packagingOption.marginType,
        };
      });

      return {
        productGroupId,
        valueAllocation: allocation ?? 0,
        packagingGroups: groups,
      };
    }),
  };
}

export const composePackagingGroupsWithTableData = (
  entityId: string,
  groups: TPackagingOption[],
  tableData: PackagingMarginTableData
): (TPackagingOption | ({ allocationValue: number } & TPackagingOption))[] => {
  const entity = tableData[entityId];

  const groupsObject = groups.reduce((accum, item) => {
    accum[item.id] = item;
    return accum;
  }, {} as Record<string, TPackagingOption>);

  const composedData = {
    ...groupsObject,
    ...entity,
  };

  const groupsListFromComposedData = Object.values(composedData);

  return groupsListFromComposedData;
};
