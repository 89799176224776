import { defineActionGroup } from 'rd-redux-utils';
import { Industrie } from '@services/filter-service/response-interfaces/get-industries';

const IndustiesActionGrop = defineActionGroup('INDUSTRIES');

export const setIndustiesLoadingAction = IndustiesActionGrop.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setIndustiesDataAction = IndustiesActionGrop.defineAction<{
  payload: {
    items: Industrie[];
    total: number;
  };
}>('SET DATA');

export const fetchIndustiesAction = IndustiesActionGrop.defineAction(
  'FETCH DATA'
);

export const setSelectedIndustries = IndustiesActionGrop.defineAction<{
  filters: Industrie[];
}>('SET SELECTED FILTERS');
