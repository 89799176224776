import React from 'react';
import { AppDropdown } from '../AppDropdown/AppDropdown';
import { IndustriesFilters } from './IndustriesFilter';
import {
  selectCoData,
  selectSelectedCo,
} from '@app/store/filters-module/co/selectors';
import { useSelector } from 'react-redux';
import { AppSelect } from '../AppSelect';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import { ConfirmationModal } from '../ConfirmationModal';

interface Props {
  filters: any[];
  disabled?: boolean;
  handlaChangeCoFilter: (e: any) => void;
}

export const IndustriesWrapFilterDumb: React.FC<Props> = (props) => {
  const { filters, handlaChangeCoFilter } = props;
  const CO = useSelector(selectCoData);
  const selectedCo = useSelector(selectSelectedCo);
  return (
    <AppDropdown head="Industry & Branches" disabled={false}>
      <AppDropdown.Item title="CO">
        <AppSelect
          value={selectedCo ? selectedCo.id : ''}
          onChange={handlaChangeCoFilter}
        >
          {CO.map((co) => (
            <AppSelect.Option key={co.id} value={co.id}>
              {co.name}
            </AppSelect.Option>
          ))}
        </AppSelect>
      </AppDropdown.Item>
      <IndustriesFilters filters={filters} />
    </AppDropdown>
  );
};

export const IndustriesWrapFilter = withConfirmation(['handlaChangeCoFilter'])(
  IndustriesWrapFilterDumb,
  ConfirmationModal
);
