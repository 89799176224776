import { all } from 'redux-saga/effects';
import { productLineWatcherSaga } from '../product-line/saga/productLineWatcherSaga';
import { quotesWatcherSaga } from '../quotes/saga/quotesWatcherSaga';
import { exceptionsWatcherSaga } from '../exceptions/saga/exceptionsWatcherSaga';

export function* negotiationRoomWatcherSaga() {
  yield all([
    productLineWatcherSaga(),
    quotesWatcherSaga(),
    exceptionsWatcherSaga(),
  ]);
}
