import { call, put } from 'redux-saga/effects';
import { saveBaseMarginToPagination } from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { enqueSnackAction } from '@app/store/snacks';

export function* saveBaseMarginToPaginationWorkerSaga(
  action: ReturnType<typeof saveBaseMarginToPagination>
) {
  try {
    yield call(BaseMarginService.saveBaseMargin, action.payload);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Margins are saved',
          options: {
            variant: 'success',
          },
          key: 'save margins',
        },
      })
    );
  } catch (e) {
    console.log(`Failed to fetch user: ${e.toString()}`);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'error margins',
        },
      })
    );
  }
}
