import * as React from 'react';
import Grid from '@material-ui/core/Grid';

interface Props {
  title: string;
  children: JSX.Element;
}

export const AppDropdownItem = (props: Props) => {
  const { title, children } = props;

  return (
    <Grid item container spacing={1} direction="column">
      <Grid item>
        <b>
          <pre>{title}</pre>
        </b>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};
