import { Action } from 'redux';
import {
  setVolumeDriverConfigurationsLoadingAction,
  setVolumeSystemConfigurationsCreatingAction,
  setVolumeSystemConfigurationsModalOpenedAction,
  updateVolumeDriverConfigurationAction,
  resetUpdatedVolumeConfigurationsAction,
  fetchVolumeDriverConfigurationsDataAction,
  fetchVolumeDriverConfigurationsDoneAction,
  setNameExsitsAction,
} from './actions';
import { initialState, VolumeSystemsConfigurationsState } from './store';

export function volumeSystemConfigurationsReducer(
  state = initialState,
  action: Action
): VolumeSystemsConfigurationsState {
  if (fetchVolumeDriverConfigurationsDataAction.is(action)) {
    return {
      ...state,
      isLoaded: false,
      isLoading: true,
    };
  }

  if (setVolumeDriverConfigurationsLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }

  if (fetchVolumeDriverConfigurationsDoneAction.is(action)) {
    return {
      ...state,
      items: action.items,
      totalItems: action.total,
      isLoading: false,
      isLoaded: true,
    };
  }

  if (updateVolumeDriverConfigurationAction.is(action)) {
    return {
      ...state,
      updatedConfigurations: {
        ...state.updatedConfigurations,
        [action.id]: action.updatedPayload,
      },
    };
  }

  if (setVolumeSystemConfigurationsModalOpenedAction.is(action)) {
    return {
      ...state,
      isCreationModalOpened: action.opened,
    };
  }

  if (setVolumeSystemConfigurationsCreatingAction.is(action)) {
    return {
      ...state,
      isCreating: action.creating,
    };
  }
  if (setNameExsitsAction.is(action)) {
    return {
      ...state,
      nameExists: action.payload,
    };
  }
  if (resetUpdatedVolumeConfigurationsAction.is(action)) {
    return {
      ...state,
      items: state.items.map((item) => ({
        ...item,
        ...state.updatedConfigurations[item.id],
      })),
      updatedConfigurations: {},
    };
  }
  return state;
}
