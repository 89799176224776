import { pickQueryParams } from '@app/shared/utils/pickQueryParams';
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_PAGE_LIMIT,
} from './consts';

export const parseUrlForPageIndex = (): number | undefined => {
  const { page } = pickQueryParams<{ page: string | undefined }>(
    window.location.search,
    ['page']
  );

  return page === undefined ? undefined : Number(page);
};

export const parseUrlForPageLimit = (): number | undefined => {
  const { limit } = pickQueryParams<{ limit: string | undefined }>(
    window.location.search,
    ['limit']
  );

  return limit === undefined ? undefined : Number(limit);
};
export const getCurrentPage = () => {
  const page = parseUrlForPageIndex();

  return page ? +page : DEFAULT_PAGINATION_PAGE;
};

export const getCurrentPageLimit = () => {
  const limit = parseUrlForPageLimit();

  return limit ? +limit : DEFAULT_PAGINATION_PAGE_LIMIT;
};

export const getCurrentSkip = () => {
  const page = getCurrentPage();
  const limit = getCurrentPageLimit();

  return page === 1 ? 0 : (Number(page) - 1) * Number(limit);
};
