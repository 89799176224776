import { AuthPageLayout } from '@app/shared/components/AuthPageLayout';
import * as React from 'react';
import {
  ResetPasswordForm,
  ResetPasswordFormData,
} from './components/ResetPasswordForm';
import { useLocationQuery } from '@shared/hooks/useLocationQuery';
import { AccountAPI } from '@app/services/account-service/account-service';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { LOGIN_URL } from '@app/routes/urls';

interface PageQuery {
  email: string;
  code: string;
}

export const ResetPassword = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { email, code } = useLocationQuery<PageQuery>(['code', 'email']);

  const handleSubmit = React.useCallback(
    async (formData: ResetPasswordFormData) => {
      closeSnackbar();
      setSubmitting(true);

      try {
        await AccountAPI.changePassword(email, formData.password, code);
        enqueueSnackbar('Your password has been changed,you can login now!', {
          variant: 'success',
          autoHideDuration: 5000,
        });
        history.push(LOGIN_URL.urlTemplate);
      } catch (e) {
        enqueueSnackbar(
          'Reset password link may be expired or invalid.Please try to reset password again.',
          {
            variant: 'error',
          }
        );
      } finally {
        setSubmitting(false);
      }
    },
    [email, code, history, closeSnackbar, enqueueSnackbar]
  );

  return (
    <AuthPageLayout title="Reset password">
      <ResetPasswordForm onSubmit={handleSubmit} isSubmitting={submitting} />
    </AuthPageLayout>
  );
};
