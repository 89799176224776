import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';

export const FEATURE_PACKAGING_DRIVER: TAccountRightsFeatureList =
  'PackagingDriver';

export const FEATURE_PACKAGING_CONFIGURATION: TAccountRightsFeatureList =
  'PackagingDriverConfiguration';

export const FEATURE_PACKAGING_MARGINS: TAccountRightsFeatureList =
  'PackagingDriverMargins';
