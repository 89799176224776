import React, { useCallback, useEffect, useMemo } from 'react';
import { ExceptionsTable } from './ExceptionsTable';
import { ConfigureDialog } from './ConfigureDialog';
import Grid from '@material-ui/core/Grid';
import {
  fetchUpdateBaseMarginException,
  openModalExceptionAction,
  resetException,
  updateBaseMarginExceptionAction,
} from '@app/store/baseMargin';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBaseMarginExceptionSelector,
  getExceptionUpdateDataSelector,
  openModalSelector,
} from '@app/store/baseMargin/selectors';
import { useHistory } from 'react-router';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { getSelectedLevel4IndustriesIds } from '@app/store/filters-module/industries';
export const ExeptionsConfigurations: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dataException = useSelector(getBaseMarginExceptionSelector);
  const updateDataException = useSelector(getExceptionUpdateDataSelector);
  const openModal = useSelector(openModalSelector);

  const baseMartrixCO = useSelector(selectSelectedCo);

  const selectedIndustiesIds: string[] = useSelector(
    getSelectedLevel4IndustriesIds
  );

  useEffect(() => {
    history.push({
      search: '',
    });
  }, [history]);

  const saveExceptions = useCallback(() => {
    dispatch(
      updateBaseMarginExceptionAction({
        payload: Object.values(updateDataException).map((item) => item),
      })
    );
    dispatch(resetException({}));
  }, [dispatch, updateDataException]);

  const onReminder = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      id: string,
      data: Record<string, string>
    ) => {
      const newData = { ...data };
      delete newData[e.target.name];
      dispatch(
        fetchUpdateBaseMarginException({
          payload: {
            ...updateDataException,
            [id]: {
              ...updateDataException[id],
              id,
              ...newData,
              reminder: e.target.checked,
            },
          },
        })
      );
    },
    [updateDataException, dispatch]
  );
  const onChangeInput = useCallback(
    (e: any, id: string, data: Record<string, string | boolean>) => {
      const newData = { ...data };
      delete newData[e.target.name];
      dispatch(
        fetchUpdateBaseMarginException({
          payload: {
            ...updateDataException,
            [id]: {
              ...updateDataException[id],
              id,
              ...newData,
              [e.target.name]: e.target.value,
            },
          },
        })
      );
    },
    [updateDataException, dispatch]
  );

  const handleClickOpenConfigure = () => {
    dispatch(openModalExceptionAction({ payload: true }));
  };
  const handleCloseConfigure = () => {
    dispatch(openModalExceptionAction({ payload: false }));
  };

  const composedTableData = useMemo(() => {
    return dataException.map((item) => ({
      ...item,
      ...updateDataException[item.id],
    }));
  }, [dataException, updateDataException]);

  const hasChangedData = useMemo(() => {
    return !!Object.keys(updateDataException).length;
  }, [updateDataException]);
  const handlePromptConfirm = useCallback(() => {
    saveExceptions();
  }, [saveExceptions]);
  const handleDontSave = useCallback(() => {
    dispatch(resetException({}));
  }, [dispatch]);

  return (
    <>
      <Grid container direction="column" spacing={2} wrap="nowrap" item>
        <Grid item container spacing={2} wrap="nowrap" justify="space-between">
          <Grid item>
            <RoleProtectedButton
              variant="contained"
              onClick={handleClickOpenConfigure}
              color="primary"
            >
              Add Exception
            </RoleProtectedButton>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              variant="contained"
              onClick={() => saveExceptions()}
              color="primary"
              disabled={!Object.keys(updateDataException).length}
            >
              Save
            </RoleProtectedButton>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <ExceptionsTable
            dataException={composedTableData}
            onReminder={onReminder}
            onChangeInput={onChangeInput}
          />
        </Grid>
        {openModal ? (
          <ConfigureDialog
            configureOpen={openModal}
            handleClickOpen={handleClickOpenConfigure}
            handleClose={handleCloseConfigure}
          />
        ) : null}
        <Grid item container spacing={1} justify="flex-end">
          <Grid item>
            <AppExportButton
              url={'/CreateExcel/BaseMarginException'}
              industryIds={selectedIndustiesIds}
              coId={baseMartrixCO?.id}
              isPost={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <PageLeavePrompt
        onSkip={handleDontSave}
        when={hasChangedData}
        onConfirm={handlePromptConfirm}
      />
    </>
  );
};
