import React, { useMemo, useReducer } from 'react';
import { AppButton } from '@app/shared/components/AppButton';
import { DateValidPicker } from './component/DateValidPicker';
import { useDispatch } from 'react-redux';
import {
  createTemporaryUserRightsReducer,
  initialState,
  resetStateAction,
  setIsTemporaryApprovalInCrmAction,
  setPermanentUserAction,
  setSectionRightsValueAction,
  setTemporaryUserAction,
  setValidFromAction,
  setValidToAction,
} from './';
import { AppFormDialog } from '@shared/components/AppFormDialog';
import { Grid, Switch } from '@material-ui/core';
import { RoleProtectedButton } from '@shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { UserPermanentSelect } from './component/UserPermanentSelect';
import {
  createUserTemporaryRightsItemAction,
  UserReferenceDataType,
} from '@app/store/settings/user-rights';
import { UserTemporarySelect } from './component/UserTemporarySelect';
import { SectionCheckbox } from './component/SectionCheckbox';
import { sectionsFieldLabels } from '@modules/Settings/components/UserRights/ConfigureDialog';
import { rightsHeaders } from '@modules/Settings/components/UserRights/UserRightsTable';
import moment from 'moment';

interface Props {
  configureOpen: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
}

const dialogFormName: string = 'user-temporary-rights-form';

const formValidationState = {
  isPermUserValid: false,
  isTempUserValid: false,
  isDateRangeValid: false,
  isDateFromValid: false,
  isDateToValid: false
}

export const ConfigureTemporaryDialog: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  //===== CREATE LOCAL STATE =====

  const [localState, localDispatch] = useReducer(
    createTemporaryUserRightsReducer,
    initialState
  );

  //===== VALIDATE DATE =====

  const minFromDate = new Date().toISOString();
  const minToDate = useMemo(() => {
    const fromDate = new Date(localState.validFrom);
    fromDate.setDate(fromDate.getDate());
    return fromDate.toISOString();
  }, [localState.validFrom]);

  const validateForm = useMemo(() => {
    formValidationState.isPermUserValid = !!localState.permanentUser;
    formValidationState.isTempUserValid = !!localState.temporaryUser;
    formValidationState.isDateFromValid = !!localState.validFrom;
    formValidationState.isDateToValid = !!localState.validTo;
    formValidationState.isDateRangeValid = moment(localState.validTo).isSameOrAfter(localState.validFrom, 'date');

    return !Object.values(formValidationState).some(x => !x);
  }, [localState])


  //===== HANDLERS =====

  const handlerSubmit = (e: any) => {
    e.preventDefault();

    dispatch(
      createUserTemporaryRightsItemAction({
        payload: {
          donorUserId: localState.permanentUser?.id,
          recipientUserId: localState.temporaryUser?.id,
          sections: localState.sections,
          validFrom: localState.validFrom,
          validTo: localState.validTo,
          isTemporaryApprovalInCRM: localState.isTemporaryApprovalInCRM
        },
      })
    );

    props.handleClose();
    localDispatch(resetStateAction());
  };

  const handlerChangePermanentUser = (value: UserReferenceDataType | null) =>
    localDispatch(setPermanentUserAction(value));

  const handlerChangeTemporaryUser = (value: UserReferenceDataType | null) =>
    localDispatch(setTemporaryUserAction(value));

  const handlerSectionRightsValue = (value: { key: string; value: boolean }) =>
    localDispatch(setSectionRightsValueAction(value));

  const handlerValidFrom = (value: string) => {
    let newValue = new Date(value)
    newValue.setHours(0, 0, 0, 0)

    localDispatch(setValidFromAction(newValue.toISOString()));
  };

  const handlerValidTo = (value: string) => {
    let newValue = new Date(value)
    newValue.setHours(23, 59, 59, 999)

    localDispatch(setValidToAction(newValue.toISOString()));
  };

  const handleIsTemporaryApprovalInCrm = (event: React.ChangeEvent<HTMLInputElement>) => {
    localDispatch(setIsTemporaryApprovalInCrmAction(event.target.checked))
  }

  return (
    <AppFormDialog
      size={'sm'}
      isOpened={props.configureOpen}
      isLoading={false}
      onClose={props.handleClose}
      title={'Configuring temporary rights'}
      content={
        <form onSubmit={handlerSubmit} id={dialogFormName}>
          <Grid container spacing={2}>
            <UserPermanentSelect
              label={'Select permanent user'}
              placeholder={'Select user'}
              value={localState.permanentUser}
              onChange={handlerChangePermanentUser}
              selectUser={localState.temporaryUser}
            />
            <UserTemporarySelect
              label={'Select temporary user'}
              placeholder={'Select user'}
              value={localState.temporaryUser}
              onChange={handlerChangeTemporaryUser}
              selectUser={localState.permanentUser}
            />
            <Grid container item spacing={2}>
              <Grid item container alignItems="flex-start" xs={6}>
                Select functionality to transfer
              </Grid>
              <Grid item container xs={6} direction="column">
                {rightsHeaders.map((i) => (
                  <SectionCheckbox
                    key={i.key}
                    label={sectionsFieldLabels[i.key]}
                    name={i.key}
                    value={Boolean(
                      localState.sections.find(
                        (section) => section.key === i.key
                      )?.value
                    )}
                    onChange={(value) =>
                      handlerSectionRightsValue({ ...i, value })
                    }
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              Temporary approval in CRM
            </Grid>
            <Switch
              color="primary"
              checked={localState.isTemporaryApprovalInCRM}
              onChange={handleIsTemporaryApprovalInCrm}
            />
            <DateValidPicker
              label={'Valid from'}
              placeholder={''}
              value={localState.validFrom}
              onChange={handlerValidFrom}
              minDate={minFromDate}
            />
            <DateValidPicker
              label={'Valid to'}
              placeholder={''}
              value={localState.validTo}
              onChange={handlerValidTo}
              minDate={minToDate}
            />
          </Grid>
        </form>
      }
      actions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <AppButton onClick={props.handleClose} color="default">
              Cancel
            </AppButton>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              type="submit"
              color="primary"
              form={dialogFormName}
              disabled={!validateForm}
            >
              Create
            </RoleProtectedButton>
          </Grid>
        </Grid>
      }
    />
  );
};
