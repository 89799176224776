export type TAccountRightsPermissions = 'ViewOnly' | 'Hidden' | 'Edit';

export const VIEW_ONLY: TAccountRightsPermissions = 'ViewOnly';
export const HIDDEN: TAccountRightsPermissions = 'Hidden';
export const EDIT: TAccountRightsPermissions = 'Edit';

export interface TAccountRights {
  ProductValueLabeling: TAccountRightsPermissions;
  BaseMarginMatrix: TAccountRightsPermissions;
  VolumeDriver: TAccountRightsPermissions;
  VolumeDriverSystemConfiguration: TAccountRightsPermissions;
  VolumeDriverSystemAllocation: TAccountRightsPermissions;
  PackagingDriver: TAccountRightsPermissions;
  PackagingDriverConfiguration: TAccountRightsPermissions;
  PackagingDriverMargins: TAccountRightsPermissions;
  NegotiationRoom: TAccountRightsPermissions;
  NegotiationRoomProductLine: TAccountRightsPermissions;
  NegotiationRoomQuotes: TAccountRightsPermissions;
  NegotiationRoomExceptions: TAccountRightsPermissions;
  Wizard: TAccountRightsPermissions;
  Exceptions: TAccountRightsPermissions;
  Settings: TAccountRightsPermissions;
  SettingsProductRights: TAccountRightsPermissions;
  SettingsTeamsRights: TAccountRightsPermissions;
  SettingsUserRights: TAccountRightsPermissions;
  SettingsValueRights: TAccountRightsPermissions;
  ContactAndSupport: TAccountRightsPermissions;
}

export type TAccountRightsFeatureList = keyof TAccountRights;

export type TGetAccountRightsResponse = TAccountRights;
