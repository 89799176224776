import React, { useReducer } from 'react';
import { AppButton } from '@app/shared/components/AppButton';
import { AppFormDialog } from '@shared/components/AppFormDialog';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import {
  changeEditableSectionRightsAction,
  createUserRightsReducer,
  initialState,
  resetStateAction,
  setCOAction,
  setGeneralAccessAction,
  setSectionsRightsAction,
  setSectionsRightsBulkAction,
  setTeamAction,
  setUserAction,
  setValueLabelAccessAction,
} from '@modules/Settings/components/UserRights/ConfigureDialog/state';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import {
  AccessValuesType,
  COReferenceDataType,
  createUserRightsItemAction,
  RightsValuesType,
  TeamReferenceDataType,
  UserReferenceDataType,
} from '@app/store/settings/user-rights';
import { UserSelect } from './components/UserSelect';
import { TeamSelect } from './components/TeamSelect';
import { COSelect } from './components/COSelect';
import { ProductAccessSelect } from '@modules/Settings/components/UserRights/ConfigureDialog/components/ProductAccessSelect';
import { RightsSelect } from './components/RightsSelect';
import { rightsValues } from './data-definition';
import { useDispatch } from 'react-redux';
import { rightsHeaders, UserRightsProductAccessType } from '../UserRightsTable';

interface Props {
  configureOpen: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
}

const dialogFormName: string = 'user-rights-form';

export const ConfigureDialog: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  //===== CREATE LOCAL STATE  =====

  const [localState, localDispatch] = useReducer(
    createUserRightsReducer,
    initialState
  );

  //===== HANDLERS =====

  const handlerChangeUser = (value: UserReferenceDataType | null) =>
    localDispatch(setUserAction(value));
  const handlerChangeTeam = (value: TeamReferenceDataType | null) => {
    localDispatch(setTeamAction(value));
    if (value) {
      localDispatch(
        setSectionsRightsBulkAction(
          value.sections.map((i) => ({
            key: i.key,
            value: rightsValues.find((right) => right.key === i.value) || null,
          }))
        )
      );
    }
  };
  const handlerChangeCO = (value: COReferenceDataType | null) =>
    localDispatch(setCOAction(value));
  const handlerChangeValueLabelAccess = (value: AccessValuesType | null) =>
    localDispatch(setValueLabelAccessAction(value));
  const handlerChangeGeneralAccess = (value: AccessValuesType | null) =>
    localDispatch(setGeneralAccessAction(value));
  const handlerChangeSectionsRights = (value: {
    key: string;
    value: RightsValuesType | null;
  }) => localDispatch(setSectionsRightsAction(value));
  const handlerChangeEditableSectionRights = () => {
    localDispatch(
      changeEditableSectionRightsAction(!localState.sectionsEditable)
    );
  };

  const handlerSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      createUserRightsItemAction({
        payload: {
          userId: localState?.userId?.id || '',
          profileId: localState?.teamId?.userProfileId || '',
          coId: localState?.COId?.id || null,
          valueLabelProductAccess:
            localState?.valueLabelAccess?.key ||
            (1 as UserRightsProductAccessType),
          generalProductAccess:
            localState?.generalAccess?.key ||
            (1 as UserRightsProductAccessType),
          sections: localState.sections.map((i) =>
            i.value
              ? { key: i.key, value: i.value.key }
              : { key: i.key, value: 0 }
          ),
        },
      })
    );
    props.handleClose();
    localDispatch(resetStateAction());
  };

  return (
    <AppFormDialog
      isOpened={props.configureOpen}
      isLoading={false}
      onClose={props.handleClose}
      title="Configuring right for users"
      content={
        <form onSubmit={handlerSubmit} id={dialogFormName}>
          <Grid container spacing={2}>
            <UserSelect
              label={'User'}
              placeholder={'Select user'}
              value={localState.userId}
              onChange={handlerChangeUser}
            />
            <TeamSelect
              label={'User profile'}
              placeholder={'Select role'}
              value={localState.teamId}
              onChange={handlerChangeTeam}
            />

            {localState.teamId && localState.teamId.roleAccessType === 2 && (
              <COSelect
                label={'CO'}
                placeholder={'Select CO'}
                value={localState.COId}
                onChange={handlerChangeCO}
              />
            )}
            <ProductAccessSelect
              label={'Product access for value label'}
              placeholder={'Select access'}
              value={localState.valueLabelAccess}
              onChange={handlerChangeValueLabelAccess}
            />
            <ProductAccessSelect
              label={'General product access'}
              placeholder={'Select access'}
              value={localState.generalAccess}
              onChange={handlerChangeGeneralAccess}
            />
            {rightsHeaders.map((i) => (
              <RightsSelect
                key={i.key}
                label={i.name}
                placeholder={'Select rights'}
                value={
                  localState.sections.find((section) => section.key === i.key)
                    ?.value || null
                }
                onChange={(value) =>
                  handlerChangeSectionsRights({ ...i, value })
                }
                disabled={!localState.sectionsEditable}
              />
            ))}
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localState.sectionsEditable}
                    onChange={handlerChangeEditableSectionRights}
                    name="editable-section-rights"
                    color="primary"
                  />
                }
                label="I want to make an exception to the user profile for this user"
              />
            </Grid>
          </Grid>
        </form>
      }
      actions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <AppButton onClick={props.handleClose} color="default">
              Cancel
            </AppButton>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              type="submit"
              color="primary"
              form={dialogFormName}
            >
              Create
            </RoleProtectedButton>
          </Grid>
        </Grid>
      }
    />
  );
};
