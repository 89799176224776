import { setAuthAction, setLoggedInAction } from '../actions';
import StorageUtils from '@app/shared/utils/storage/storage-utils';
import { put } from 'redux-saga/effects';

export function* setSessionSaga(action: ReturnType<typeof setAuthAction>) {
  const { accessToken, refreshToken } = action;

  StorageUtils.setAuthToken(accessToken);
  StorageUtils.setRefreshToken(refreshToken);
  yield put(setLoggedInAction({ loggedIn: true }));
}
