import { TMarginSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-configurations.response';

export interface MarginSystemConfigurationsState {
  isLoading: boolean;
  items: TMarginSystemConfiguration[];
  totalItems: number;
  isCreationModalOpened: boolean;
  isCreating: boolean;
  updatedConfigurations: Record<string, TMarginSystemConfiguration>;
}

export const initialState: MarginSystemConfigurationsState = {
  isLoading: true,
  items: [],
  totalItems: 0,
  isCreationModalOpened: false,
  isCreating: false,
  updatedConfigurations: {},
};
