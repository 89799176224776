import { validatePercentValuePackagingMargin } from '../../../SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/util/validateMarginValue';

//===== STATE =====
interface StateType {
  packaging: { id: string; name: string } | null;
  values: string;
  marginType: number;
  isValid: boolean;
}

export const initialState: StateType = {
  packaging: null,
  values: '',
  marginType: 0,
  isValid: false,
};

//===== TYPES =====

export const setPackagingType = 'SET_PACKAGING';
export const setPackagingValueType = 'SET_PACKAGING_VALUE';
export const setMarginType = 'SET_MARGIN_TYPE';
export const resetPackagingAddType = 'RESET_PACKAGING_ADD';

//===== ACTIONS =====

interface ActionType {
  type: string;
  payload?: any;
}

export const setPackagingAction = (
  value: { id: string; name: string } | null
): ActionType => ({
  type: setPackagingType,
  payload: value,
});

export const setPackagingValueAction = (value: string): ActionType => ({
  type: setPackagingValueType,
  payload: value,
});

export const setMarginTypeAction = (marginType: number): ActionType => ({
  type: setMarginType,
  payload: marginType,
});

export const resetPackagingAddAction = (): ActionType => ({
  type: resetPackagingAddType,
});

//===== REDUCER =====

export const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case setPackagingType:
      return { ...state, packaging: action.payload };

    case setPackagingValueType:
      return {
        ...state,
        values: action.payload,
        isValid: validatePercentValuePackagingMargin(
          action.payload,
          state.marginType
        ),
      };

    case setMarginType:
      return {
        ...state,
        marginType: action.payload,
        isValid: validatePercentValuePackagingMargin(
          state.values,
          action.payload
        ),
      };

    case resetPackagingAddType:
      return initialState;

    default:
      return state;
  }
};
