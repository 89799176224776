import { call, put, select } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';
import {
  setWizardCOIdAction,
  setWizardCommercialOrganizationsAction,
} from '../actions';
import { getWizardCOIdSelector } from '@app/store/wizard';

export function* fetchCOIdWorkerSaga() {
  try {
    let coId = yield select(getWizardCOIdSelector);
    if (coId === '') {
      const res = yield call(WizardServices.getCO);
      coId = res.data[0].id;
      yield put(setWizardCommercialOrganizationsAction({ payload: res.data }));
      yield put(setWizardCOIdAction({ payload: coId }));
    }
    return coId;
  } catch (e) {
    console.error(`Failed to fetchCOIdWorkerSaga: ${e.toString()}`);
  }
}
