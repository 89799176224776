import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useReducer } from 'react';
import {
  createExceptionReducer,
  initialCreateExceptionState,
  resetExceptionAction,
  setIndustryAction,
  setIndustryLevelAction,
  setMarginValueAction,
  setMarginValueValidateAction,
  setNameAction,
  setNameValidateAction,
  setProductValueAction,
} from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/AddMarginModal/store';
import { useEffect } from 'react';
import {
  addNewBaseMarginMatrixItemAction,
  getWizardBaseMarginMatrixSelector,
} from '@app/store/wizard';
import {
  IndustrySelect,
  IndustryLevelSelect,
  MarginValueField,
  NameField,
  ProductValueSelect,
} from './components';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { WizardBaseMarginEntryState } from '@shared/types/wizard';
import { useDispatch } from 'react-redux';
import { bmmIndustryLevelByKey } from '@modules/Simulator/data-definition';

interface AddMarginModalProps {
  status: boolean;
  close: () => void;
  accept: () => void;
}

export const AddMarginModal = ({
  status,
  close,
  accept,
}: AddMarginModalProps) => {
  const dispatch = useDispatch();

  //===== CREATE LOCAL STATE =====

  const [formState, formDispatch] = useReducer(
    createExceptionReducer,
    initialCreateExceptionState
  );

  //===== DATE FROM STORE =====

  const baseMarginMatrix = useSelector(getWizardBaseMarginMatrixSelector);

  //===== CHANGE HANDLERS =====

  const handlerChangeName = (name: string) => {
    formDispatch(setNameAction(name));
  };
  const handlerChangeProductValue = (value: number | null) => {
    formDispatch(setProductValueAction(value));
  };
  const handlerChangeIndustryLevel = (level: number | null) => {
    formDispatch(setIndustryLevelAction(level));
  };
  const handlerChangeIndustry = (id: string | null, name: string | null) => {
    formDispatch(setIndustryAction(id, name));
  };
  const handlerChangeMarginValue = (value: string) => {
    formDispatch(setMarginValueAction(value));
  };

  //===== START OR RESET FORM =====

  useEffect(() => {
    if (status) {
      const last_number =
        baseMarginMatrix.reduce((value, i) => {
          const reg = /Exception (\d+)/;
          return Math.max(
            i.industryMargins.reduce(
              (number: number, margin: WizardBaseMarginEntryState): number => {
                let parseName = margin.name.match(reg);
                return Math.max(
                  Number(parseName && parseName[1] ? parseName[1] : 0),
                  number
                );
              },
              value
            ),
            value
          );
        }, 0) + 1;

      formDispatch(setNameAction('Exception ' + last_number));
    } else {
      formDispatch(resetExceptionAction());
    }
  }, [formDispatch, baseMarginMatrix, status]);

  //===== VALIDATE MODAL =====

  const setNameValid = useCallback(
    (isValid: boolean) => {
      formDispatch(setNameValidateAction(isValid));
    },
    [formDispatch]
  );
  const setMarginValueValid = useCallback(
    (isValid: boolean) => {
      formDispatch(setMarginValueValidateAction(isValid));
    },
    [formDispatch]
  );

  const formValid = useMemo(
    (): boolean =>
      formState.productValue !== null &&
      formState.industryLevel !== null &&
      formState.industryId !== null &&
      formState.industryName !== null &&
      formState.nameIsValid &&
      formState.marginValueIsValid,
    [formState]
  );

  //===== ACCEPT HANDLER =====

  const handlerAccept = () => {
    formState.productValue !== null &&
      formState.industryLevel !== null &&
      formState.industryId !== null &&
      formState.industryName !== null &&
      formState.name !== null &&
      formState.marginValue !== null &&
      dispatch(
        addNewBaseMarginMatrixItemAction({
          payload: {
            valueAllocation: formState.productValue,
            item: {
              id: 'new--' + Date.now().toString(36),
              name: formState.name,
              industryLevel: bmmIndustryLevelByKey[formState.industryLevel],
              industryId: formState.industryId,
              industryName: formState.industryName,
              marginValue: formState.marginValue,
            },
          },
        })
      );
    accept();
  };

  return (
    <Dialog open={status} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Add new margin type</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={4}
          style={{ marginBottom: 0 }}
        >
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              Margin type name:
            </Grid>
            <Grid item xs={8}>
              <NameField
                isValid={formState.nameIsValid}
                setValid={setNameValid}
                name={formState.name}
                handlerChange={handlerChangeName}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              Product value:
            </Grid>
            <Grid item xs={8}>
              <ProductValueSelect
                productValue={formState.productValue}
                handlerChange={handlerChangeProductValue}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              Industry level:
            </Grid>
            <Grid item xs={8}>
              <IndustryLevelSelect
                handlerChange={handlerChangeIndustryLevel}
                industryLevel={formState.industryLevel}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              Industry name:
            </Grid>
            <Grid item xs={8}>
              <IndustrySelect
                disabled={
                  formState.industryLevel === null ||
                  formState.productValue === null
                }
                industryId={formState.industryId}
                industryName={formState.industryName}
                handlerChange={handlerChangeIndustry}
                level={formState.industryLevel}
                productValue={formState.productValue}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              Margin:
            </Grid>
            <Grid item xs={8}>
              <MarginValueField
                isValid={formState.marginValueIsValid}
                setValid={setMarginValueValid}
                disabled={formState.productValue === null}
                handlerChange={handlerChangeMarginValue}
                marginValue={formState.marginValue}
                productValue={formState.productValue}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          color="primary"
          onClick={handlerAccept}
          autoFocus
          disabled={!formValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
