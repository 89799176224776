import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface Props {
  isOpened: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onSkip: () => void;
}

export const ConfirmationModal: React.FC<Props> = (props) => {
  const { isOpened, onCancel, onConfirm, onSkip } = props;
  return (
    <Dialog open={isOpened}>
      <DialogTitle id="alert-dialog-title">
        Note: You have unsaved data.
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The data has not been saved, are you sure you want to leave?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onSkip} color="primary">
          Don't save
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus
          onClick={onConfirm}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
