import Box from '@material-ui/core/Box';
import React from 'react';
import { Header } from './Header';
import classnames from 'classnames';
import styles from './Layout.module.scss';
import Fade from '@material-ui/core/Fade';

interface Props {
  children: React.ReactNode;
  pageName?: string;
  className?: string;
}

export const Layout = function ({ children, pageName }: Props) {
  return (
    <div className={styles.layout}>
      <Header pageName={pageName} />
      <Fade appear={true} in={true} timeout={200}>
        <div className={styles.layout_content}>{children}</div>
      </Fade>
    </div>
  );
};

const LayoutGrid: React.FC<Props> = ({ children, pageName, className }) => {
  return (
    <Box p={4} className={classnames(className)}>
      {children}
    </Box>
  );
};

Layout.Grid = LayoutGrid;
