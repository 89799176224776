export interface UnitOption {
    value: number;
    name: string;
}

export const unitOptions: UnitOption[] = [
    {
        value: 1,
        name: 'KG',
    },
    {
        value: 2,
        name: 'Pallets',
    },
    {
        value: 3,
        name: 'MT',
    },
    {
        value: 4,
        name: 'PCS',
    },
    {
        value: 5,
        name: 'L',
    }
];
