import { useCallback } from 'react';
import {
  EDIT,
  HIDDEN,
  TAccountRightsFeatureList,
} from '@app/services/account-rights-service/response-interfaces/account-rights';
import { AppState } from '@app/store';
import { selectAccountPermissionsByFeatureName } from '@app/store/account-rights';
import { useSelector } from 'react-redux';

type CanHandler = () => boolean;

export function useAccountRoleRights(
  feature: TAccountRightsFeatureList | null | undefined
): [CanHandler, CanHandler] {
  const featurePermission = useSelector((store: AppState) =>
    selectAccountPermissionsByFeatureName(
      store,
      feature as TAccountRightsFeatureList
    )
  );

  const canVisit = useCallback(() => {
    if (!featurePermission) return true;

    return featurePermission !== HIDDEN;
  }, [featurePermission]);

  const canEdit = useCallback(() => {
    if (!featurePermission) return true;
    return featurePermission === EDIT;
  }, [featurePermission]);

  return [canVisit, canEdit];
}
