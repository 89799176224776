import * as React from 'react';
import styles from './ResetPasswordForm.module.scss';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { resetPasswordSchema } from './schema';
import { validate } from '@shared/utils/validate';
export interface ResetPasswordFormData {
  password: string;
  confirm: string;
}

interface Props {
  onSubmit: (formData: ResetPasswordFormData) => void;
  isSubmitting?: boolean;
}

const initialFormData = {
  password: '',
  confirm: '',
};

export const ResetPasswordForm = (props: Props) => {
  const { onSubmit, isSubmitting } = props;

  const [formData, setFormData] = React.useState(initialFormData);
  const [errors, setFormErrors] = React.useState<Record<string, string>>({
    password: '',
    confirm: '',
  });

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (errors[name]) {
        setFormErrors((errors) => ({ ...errors, [name]: '' }));
      }

      setFormData({ ...formData, [name]: value });
    },
    [formData, errors]
  );

  const handleSubmit = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();

      const errors = validate(formData, resetPasswordSchema);

      if (Object.keys(errors).length) {
        return setFormErrors(errors);
      }

      setFormErrors({});

      onSubmit(formData);
    },
    [formData, onSubmit]
  );

  return (
    <form className={styles.resetPasswordForm} onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            type="password"
            name="password"
            label="Password"
            placeholder="Enter new password"
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange}
            error={!!errors['password']}
            helperText={errors['password']}
            required
          />
        </Grid>
        <Grid item>
          <TextField
            type="password"
            name="confirm"
            label="Repeat password"
            placeholder="Repeat password"
            onChange={handleChange}
            error={!!errors['confirm']}
            helperText={errors['confirm']}
            required
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                disabled={isSubmitting}
                color="primary"
                variant="contained"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
