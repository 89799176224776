import { AppState } from '@app/store';
import { VolumeDriverConfigurationsState } from './store';

export const selectVolumeDriverConfigurationsState = (
  state: AppState
): VolumeDriverConfigurationsState => state.volumeDriver.configurations;

export const selectVolumeDriverConfigurationsCreatingState = (
  state: AppState
) => selectVolumeDriverConfigurationsState(state).isCreating;

export const selectVolumeDriverConfigurationsModalState = (state: AppState) =>
  selectVolumeDriverConfigurationsState(state).isModalOpened;
