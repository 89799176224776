import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import {
  saveValueRightsAction,
  deleteValueRightsAction,
  fetchValueRightsAction,
  createValueRightAction,
  fetchFullProductGroupsAction,
  fetchFullChildrenGroupsAction,
  forceSaveValueRightsAction,
} from '..';
import { createValueRightsSaga } from './createValueSaga';
import { deleteValueRightsSaga } from './deleteValueRights';
import { fetchFullChildrenGroupsWorkerSaga } from './fetchFullChildrenGroupsWorkerSaga';
import { fetchFullProductGroupsWorkerSaga } from './fetchFullProductGroupsWorkerSaga';
import { fetchValueRightsWorkerSaga } from './fetchValueRightsSaga';
import { saveValueRightsSaga } from './saveValueRightsSaga';
import { valueRightsWorkerSaga } from './valueRightsWorkerSaga';
import { forceSaveValueRightsSaga } from './forceSaveValueRightsSaga';

export function* valueRightsWatcherSaga() {
  yield all([
    takeEvery(fetchValueRightsAction.TYPE, fetchValueRightsWorkerSaga),
    takeEvery(saveValueRightsAction.TYPE, saveValueRightsSaga),
    takeEvery(forceSaveValueRightsAction.TYPE, forceSaveValueRightsSaga),
    takeEvery(deleteValueRightsAction.TYPE, deleteValueRightsSaga),
    takeEvery(createValueRightAction.TYPE, createValueRightsSaga),
    takeEvery(
      fetchFullProductGroupsAction.TYPE,
      fetchFullProductGroupsWorkerSaga
    ),
    takeEvery(
      fetchFullChildrenGroupsAction.TYPE,
      fetchFullChildrenGroupsWorkerSaga
    ),
    takeEvery(LOCATION_CHANGE, valueRightsWorkerSaga),
  ]);
}
