import { PackagingConfigurationTableItem } from './PackagingConfigurationsTable';

export const sortByPackagingType = (
  items: PackagingConfigurationTableItem[],
  order: 'asc' | 'desc'
) => {
  return items.sort((a, b) => {
    return order === 'asc'
      ? a.packagingType.name.localeCompare(b.packagingType.name)
      : b.packagingType.name.localeCompare(a.packagingType.name);
  });
};

export const sortByPackagingGroup = (
  items: PackagingConfigurationTableItem[],
  order: 'asc' | 'desc'
) => {
  return items.sort((a, b) => {
    const aField = a.packagingGroup ? a.packagingGroup.name : '';
    const bField = b.packagingGroup ? b.packagingGroup.name : '';
    return order === 'asc'
      ? aField.localeCompare(bField)
      : bField.localeCompare(aField);
  });
};
