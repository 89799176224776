import * as React from 'react';
import { StepLayout } from '../StepLayout';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { StepContentLayout } from '../StepContentLayout';
import {
  WizardDispatchContext,
  WizardStepComponentProps,
} from '../SimulatorWizard';
import { useContext, useEffect } from 'react';
import { changeActiveStepStatusAction } from '@modules/Simulator/components/SimulatorWizard/store';
import { useSelector } from 'react-redux';
import {
  getPackagingMarginSelector,
  getWizardCurrentProductNameSelector,
} from '@app/store/wizard';
import { PackagingMarginTableContainer } from './components/PackagingMarginTable';
import { useState } from 'react';
import { AddPackagingMargin } from '@modules/Simulator/components/SimulatorPackagingMarginStep/components/AddPackagingMargin/AddPackagingMargin';
import { getAvailablePackagingGroupSelector } from '@app/store/wizard';
import { getPackagingMarginOriginSelector } from '@app/store/wizard';
import {
  pmChangedValidate,
  pmCurrentValidate,
  pmOriginValidate,
} from './utils/stepValidate';

export const SimulatorPackagingMarginStep = ({
  step,
  next,
  prev,
  canEdit
}: WizardStepComponentProps) => {
  const wizardDispatch = useContext(WizardDispatchContext);
  useEffect(() => {
    wizardDispatch(changeActiveStepStatusAction({ showed: true }));
  }, [wizardDispatch]);

  //===== DATA FROM STATE =====

  const productName = useSelector(getWizardCurrentProductNameSelector);
  const packagingGroup = useSelector(getAvailablePackagingGroupSelector);
  const packagingMargin = useSelector(getPackagingMarginSelector);
  const packagingMarginOrigin = useSelector(getPackagingMarginOriginSelector);

  //===== VALIDATE STEP =====

  useEffect(() => {
    wizardDispatch(
      changeActiveStepStatusAction({
        originValidate: pmOriginValidate(packagingMarginOrigin),
        currentValidate: pmCurrentValidate(packagingMargin),
        changed: pmChangedValidate(packagingMarginOrigin, packagingMargin),
      })
    );
  }, [wizardDispatch, packagingMargin, packagingMarginOrigin]);

  //===== ADD MARGIN MODAL STATE =====

  const [showAddModal, setAddModal] = useState(false);

  const ModalButton = () => (
    <Button
      onClick={() => setAddModal(true)}
      variant="contained"
      color="primary"
      disabled={packagingGroup.length === 0 || !canEdit}
    >
      Add Packaging Margin
    </Button>
  );

  return (
    <StepLayout
      title="Configuration"
      subheader={`Packaging driver for ${productName}`}
      HeadSelector={ModalButton}
    >
      <StepContentLayout
        footer={
          <Grid item container direction="row" justify="flex-end" spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={prev}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={next}
                disabled={!step.currentValidate}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        }
      >
        <>
          <AddPackagingMargin
            status={showAddModal}
            close={() => setAddModal(false)}
            accept={() => setAddModal(false)}
          />
          <Grid direction="column" container spacing={2}>
            <Grid item>
              <PackagingMarginTableContainer 
                canEdit={canEdit}
              />
            </Grid>
          </Grid>
        </>
      </StepContentLayout>
    </StepLayout>
  );
};
