import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';

export const emptyData: TQuotesItem[] = [
  {
    id: '1',
    quoteType: 1,
    description: '',
    firstThreshold: '',
    secondThreshold: '',
    currencyType: 0,
  },
  {
    id: '2',
    quoteType: 2,
    description: '',
    firstThreshold: '',
    secondThreshold: '',
    currencyType: 0,
  },
  {
    id: '3',
    quoteType: 3,
    description: '',
    firstThreshold: '',
    secondThreshold: '',
    currencyType: 0,
  },
];
