import React from 'react';
import './styles/styles.scss';
import styles from './App.module.scss';
import { Route, Switch } from 'react-router-dom';
import { privateRoutes, routes } from './routes';
import { PrivateRoute } from '@shared/components/PrivateRoute';
import { NotFound } from '@shared/components/NotFound';
import { AppNotifier } from '@shared/components/AppNotifier';
import { AppBackdropLoader } from './shared/components/AppBackdropLoader/AppBackdropLoader';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <div className={styles.App}>
        <Switch>
          {routes.map((routeProps, index) => (
            <Route {...routeProps} key={index} />
          ))}
          {privateRoutes.map((routeProps, index) => {
            return <PrivateRoute {...routeProps} key={index} />;
          })}
          <Route component={NotFound} />
        </Switch>
        <AppBackdropLoader />
      </div>
      <AppNotifier />
    </React.Fragment>
  );
};

export default App;
