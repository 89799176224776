import { getProductGroups } from '@app/store/baseMargin/selectors';
import {
  fetchFullChildrenGroupsAction,
  fetchFullProductGroupsAction,
} from '@app/store/settings/value-rights';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

interface Props {
  productGroupsLevel: Record<
    number,
    | {
        id: string;
        name: string;
      }[]
    | undefined
  >;
  exceptionProductLevel: number;
  onProductGroups: (data: { id: string; name: string } | undefined) => void;
  formError: Record<string, string> | undefined;
  isFullChildren: boolean;
  isFullList: boolean;
}

export const ProductGroupsLevel: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    productGroupsLevel,
    exceptionProductLevel,
    onProductGroups,
    formError,
    isFullChildren,
    isFullList,
  } = props;
  const productGroup = useSelector(getProductGroups);

  const [productGroupDefaultOptions, setProductGroupDefaultOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [productGroupLoading, setProductGroupLoading] = useState<boolean>(true);

  const fetchCurrentProductGroup = useCallback(
    (index) => {
      setProductGroupDefaultOptions([]);
      setProductGroupLoading(true);

      if (isFullChildren) {
        dispatch(
          fetchFullChildrenGroupsAction({
            payload: {
              id:
                index === 1
                  ? undefined
                  : (productGroupsLevel[index - 1]?.map(
                      (item) => item.id
                    ) as string[]),
            },
          })
        );
      } else {
        dispatch(
          fetchFullProductGroupsAction({
            payload: {
              id:
                index === 1
                  ? undefined
                  : (productGroupsLevel[index - 1]?.map(
                      (item) => item.id
                    ) as string[]),
              isFullList: isFullList,
            },
          })
        );
      }
    },
    [productGroupsLevel, dispatch, isFullList, isFullChildren]
  );

  useEffect(() => {
    setProductGroupDefaultOptions(productGroup);
    setProductGroupLoading(false);
  }, [productGroup]);

  const resetProductGroupsLevel = useCallback(
    (index: number, obj?: any): any => {
      let current = index + 1;
      let resetGroups = obj || {};

      const checkLevels = productGroupsLevel[current];
      if (!checkLevels || current > 5) {
        return { ...resetGroups };
      }

      resetGroups = {
        ...resetGroups,
        [current]: [],
      };
      return resetProductGroupsLevel(current, resetGroups);
    },
    [productGroupsLevel]
  );
  const handleProductGroups = useCallback(
    (data: { id: string; name: string }[] | undefined, index: number) => {
      const resetGroups = resetProductGroupsLevel(index);
      onProductGroups({
        ...resetGroups,
        [index]: data,
      });
    },
    [resetProductGroupsLevel, onProductGroups]
  );

  const checkGroupLevelDisabled = useCallback(
    (index: number) => {
      if (index !== 1) {
        return productGroupsLevel[index - 1]?.length ? false : true;
      }
      return false;
    },
    [productGroupsLevel]
  );
  const renderProductGroupAutocomplete = useMemo(() => {
    const autoComplites = [];
    for (let i = 1; i <= exceptionProductLevel && i < 6; i++) {
      autoComplites.push(
        <Grid key={i} container alignItems="center" direction="row" spacing={4}>
          <Grid item xs={4}>
            {i !== 5 ? `Product group level ${i}` : 'On main product'}
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              disabled={checkGroupLevelDisabled(i)}
              multiple={true}
              id={`tags-standard-${i}`}
              options={productGroupDefaultOptions}
              onChange={(_e, data) => handleProductGroups(data, i)}
              onOpen={() => fetchCurrentProductGroup(i)}
              limitTags={2}
              // onClick={() => fetchCurrentProductGroup(i)}
              value={productGroupsLevel[i] || []}
              loading={productGroupLoading}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  helperText={
                    formError && formError[`productGroupsLevel.${i}`]
                      ? formError[`productGroupsLevel.${i}`]
                          .split(' ')
                          .slice(1)
                          .join(' ')
                      : false
                  }
                  error={
                    formError && formError[`productGroupsLevel.${i}`]
                      ? true
                      : false
                  }
                  {...params}
                  variant="standard"
                />
              )}
            />
          </Grid>
        </Grid>
      );
    }
    return autoComplites;
  }, [
    exceptionProductLevel,
    productGroupsLevel,
    productGroupDefaultOptions,
    productGroupLoading,
    checkGroupLevelDisabled,
    fetchCurrentProductGroup,
    handleProductGroups,
    formError,
  ]);
  return <>{renderProductGroupAutocomplete}</>;
};
