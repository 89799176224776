import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { getAvailablePackagingGroupSelector } from '@app/store/wizard';
import { useSelector } from 'react-redux';
import { PackagingGroup } from '@app/shared/types/wizard';

interface PackagingSelectProps {
  handlerChange: (value: PackagingGroup | null) => void;
  packaging: PackagingGroup | null;
}

export const PackagingSelect = ({
  handlerChange,
  packaging,
}: PackagingSelectProps) => {
  //===== DATA FROM STATE =====

  const packagingGroup = useSelector(getAvailablePackagingGroupSelector);

  return (
    <Autocomplete
      value={packaging}
      options={packagingGroup}
      getOptionSelected={(i, value) => i.id === value.id}
      getOptionLabel={(i) => i.name}
      onChange={(e, value) => handlerChange(value)}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
