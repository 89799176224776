import { TPackagingDriverConfiguration } from '@app/services/packaging-driver-service/response-interfaces/get-configurations.response';
import { defineActionGroup } from 'rd-redux-utils';

const driverConfigurationActionGroup = defineActionGroup(
  'PACKAGING DRIVER CONFIGURATIONS'
);

export const fetchDriverConfigurationListAction = driverConfigurationActionGroup.defineAction<{
  skip: number;
  limit: number;
}>('FETCH CONFIGURATIONS LIST');

export const setDriverConfigurationsLoadingAction = driverConfigurationActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setDriverConfigurationsDataAction = driverConfigurationActionGroup.defineAction<{
  items: TPackagingDriverConfiguration[];
  total: number;
}>('SET DATA');

export const setDriverConfigurationsSavingAction = driverConfigurationActionGroup.defineAction<{
  saving: boolean;
}>('SET SAVING');

export const saveDriverConfigurationsAction = driverConfigurationActionGroup.defineAction<{
  items: { packageTypeId: string; packageGroupId: string }[];
}>('SAVE CONFIGURATION ITEMS');

export const setUpdateDataAction = driverConfigurationActionGroup.defineAction<{
  updateData: Record<string, string>;
}>('SET UPDATE DATA');
export const resetUpdateDataAction = driverConfigurationActionGroup.defineAction(
  'RESET UPDATE DATA'
);
