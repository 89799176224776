import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';
import { TTeamRights } from '@app/services/rights-service/response-interfaces/get-team-rights.response';
import { defineActionGroup } from 'rd-redux-utils';

const teamRightsActionGroup = defineActionGroup('TEAM RIGHTS');

export const fetchTeamRightsAction = teamRightsActionGroup.defineAction<{}>(
  'FETCH DATA'
);

export const fetchTeamRightsDoneAction = teamRightsActionGroup.defineAction<{
  items: TTeamRights[];
  total: number;
}>('FETCH DATA DONE');

export const setTeamRightsLoadingAction = teamRightsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const deleteTeamRightsAction = teamRightsActionGroup.defineAction<{
  profileIds: string[];
}>('DELETE TEAM RIGHTS');

export const setTeamRightsSubmittingAction = teamRightsActionGroup.defineAction<{
  submitting: boolean;
  submitted: boolean;
}>('SET SUBMITTING');

export const createTeamRightsAction = teamRightsActionGroup.defineAction<{
  profileName: string;
  roleAccessType: number;

  sections: { key: TAccountRightsFeatureList; value: number }[];
}>('CREATE TEAM RIGHTS');

export const updateTeamRightsAction = teamRightsActionGroup.defineAction<{
  teamRights: {
    profileId: string;
    roleAccessType: number;
    sections: { key: TAccountRightsFeatureList; value: number }[];
  }[];
}>('UPDATE TEAM RIGHTS');
