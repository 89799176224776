import {
  ProductRightsState,
  initialState as productRightsState,
} from './product-rights';
import {
  TeamRightsState,
  initialState as teamRightsState,
} from './team-rights';
import {
  ValueRightsState,
  initialState as valueRightsState,
} from './value-rights';
import {
  UserRightsState,
  initialState as userRightsState,
} from '@app/store/settings/user-rights';

export interface SettingsState {
  productRights: ProductRightsState;
  teamRights: TeamRightsState;
  valueRights: ValueRightsState;
  userRights: UserRightsState;
  isLoaded: boolean;
}

export const initialState: SettingsState = {
  productRights: productRightsState,
  teamRights: teamRightsState,
  valueRights: valueRightsState,
  userRights: userRightsState,
  isLoaded: false,
};
