import * as React from 'react';
import { StepLayout } from '../StepLayout';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { StepContentLayout } from '../StepContentLayout';
import {
  WizardDispatchContext,
  WizardStepComponentProps,
} from '../SimulatorWizard';
import { useContext, useEffect } from 'react';
import { changeActiveStepStatusAction } from '@modules/Simulator/components/SimulatorWizard/store';
import { MarginSystemContainer } from '@modules/Simulator/components/SimulatorVolumeMarginStep/components/MarginSystem/MarginSystemContainer';
import { PGAVolumeDriverContainer } from '@modules/Simulator/components/SimulatorVolumeMarginStep/components/PGAVolumeDriver';
import { useSelector } from 'react-redux';
import { getWizardCurrentProductNameSelector } from '@app/store/wizard';

export const SimulatorVolumeMarginStep = ({
  step,
  next,
  prev,
  canEdit
}: WizardStepComponentProps) => {
  const wizardDispatch = useContext(WizardDispatchContext);
  useEffect(() => {
    wizardDispatch(changeActiveStepStatusAction({ showed: true }));
  }, [wizardDispatch]);

  const productName = useSelector(getWizardCurrentProductNameSelector);

  return (
    <StepLayout
      title="Configuration"
      subheader={`Volume driver for ${productName}`}
    >
      <StepContentLayout
        footer={
          <Grid item container direction="row" justify="flex-end" spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={prev}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={next}
                disabled={!(step.currentValidate && step.changed)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container direction="column" style={{ width: '100%' }}>
          <Grid item>
            <PGAVolumeDriverContainer
              canEdit={canEdit}
            />
          </Grid>
          <Grid item>
            <MarginSystemContainer />
          </Grid>
        </Grid>
      </StepContentLayout>
    </StepLayout>
  );
};
