import * as React from 'react';
import { ChildData } from 'react-router-navigation-prompt';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export type NavPromptBag = ChildData;

interface Props {
  navPromptBag: NavPromptBag;
  onConfirm: () => void;
  onCancel?: () => void;
  onSkip?: () => void;
  isQuotes: boolean;
  disabledSave?: boolean;
}

export const PageLeaveDialog = (props: Props) => {
  const {
    navPromptBag,
    onConfirm,
    onCancel,
    onSkip,
    isQuotes,
    disabledSave = false,
  } = props;

  const handleConfirmClick = React.useCallback(() => {
    onConfirm();
    navPromptBag.onConfirm();
  }, [onConfirm, navPromptBag]);

  const handleSkipClick = React.useCallback(() => {
    onSkip && onSkip();
    navPromptBag.onConfirm();
  }, [onSkip, navPromptBag]);

  const handleCancelClick = React.useCallback(() => {
    onCancel && onCancel();
    navPromptBag.onCancel();
  }, [onCancel, navPromptBag]);

  return (
    <Dialog open={true}>
      <DialogTitle id="alert-dialog-title">Leaving page?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The data has not been saved, are you sure you want to leave?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSkipClick} color="primary">
          Don't save
        </Button>
        {!isQuotes ? (
          <Button
            variant="contained"
            color="primary"
            autoFocus
            onClick={handleConfirmClick}
            disabled={disabledSave}
          >
            Save
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
