import { TVolumeDriverAllocation } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';
import { defineActionGroup } from 'rd-redux-utils';

const volumeDriverAllocationsActionGroup = defineActionGroup(
  'VOLUME DRIVER ALLOCATIONS'
);

export const fetchDriverAllocationsAction = volumeDriverAllocationsActionGroup.defineAction<{}>(
  'FETCH DATA'
);

export const fetchDriverAllocationsDoneAction = volumeDriverAllocationsActionGroup.defineAction<{
  items: TVolumeDriverAllocation[];
  totalItems: number;
}>('FETCH DATA DONE');

export const setDriverAllocationLoadedAction = volumeDriverAllocationsActionGroup.defineAction<{
  loaded: boolean;
}>('SET LOADED');

export const setDriverAllocationLoadingAction = volumeDriverAllocationsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export interface VolumeDriverSaveItem {
  productGroupId: string;
  volumeSystemId: string | null;
  marginSystemId: string | null;
  valueAllocation: number;
}
export interface SaveVolumeDriverActionPayload {
  coId: string;
  items: VolumeDriverSaveItem[];
  shouldFetch?: boolean;
}

export const saveVolumeDriverAllocationsAction = volumeDriverAllocationsActionGroup.defineAction<
  SaveVolumeDriverActionPayload
>('SAVE ALLOCATIONS');

export const setVolumeDriverSavingAction = volumeDriverAllocationsActionGroup.defineAction<{
  isSaving: boolean;
}>('SET SAVING');

export interface SaveVolumeDriverAllocationBulkActionPayload {
  systemId: string;
  systemType: 'vs' | 'ms';
}

export const saveVolumeDriverAllocationBulkAction = volumeDriverAllocationsActionGroup.defineAction<
  SaveVolumeDriverAllocationBulkActionPayload
>('SAVE BULK');
