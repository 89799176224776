import { ManagersApi } from '@app/services/managers-service/managers-service';
import { TManagersResponse } from '@app/services/managers-service/response-interfaces/get-managers.response';
import { put } from 'redux-saga/effects';
import { setManagersDataAction, setManagersLoadingAction } from '../actions';

export function* fetchManagersSaga() {
  try {
    yield put(setManagersLoadingAction({ loading: true }));

    const request: TManagersResponse = yield ManagersApi.getManagers();

    yield put(setManagersDataAction({ payload: request.managers }));
  } catch (e) {
    console.log('Failed to get managers', e.message);
  } finally {
    yield put(setManagersLoadingAction({ loading: false }));
  }
}
