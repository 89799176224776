import { Action } from 'redux';
import {
  setValueRightsDataAction,
  setValueRightsLoadingAction,
  toggleModalAction,
  updateValueRightsAction,
} from './actions';
import { initialState, ValueRightsState } from './store';

export function valueRightsReducer(
  state = initialState,
  action: Action
): ValueRightsState {
  if (setValueRightsLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setValueRightsDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
      total: action.total,
    };
  }
  if (toggleModalAction.is(action)) {
    return {
      ...state,
      isOpenModal: action.isOpen,
    };
  }
  if (updateValueRightsAction.is(action)) {
    return {
      ...state,
      updateItems: action.items,
    };
  }

  return state;
}
