import { initializeSessionSaga } from '@app/store/auth/sagas';
import { fetchUserSaga } from '@app/store/user/sagas';
import { all, call, put, select } from 'redux-saga/effects';
import { setAppLoadedAction, setAppLoadingAction } from '../actions';

export function* loadAppWorker() {
  yield call(initializeSessionSaga);

  const loggedIn: boolean = yield select((store) => store.auth.loggedIn);

  if (loggedIn) {
    yield all([
      // here belongs everything what should be loaded before app displayed to user
      fetchUserSaga(),
    ]);
  }

  yield put(setAppLoadingAction({ loading: false }));
  yield put(setAppLoadedAction({ loaded: true }));
}
