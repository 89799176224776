import { AppState } from '@app/store/store';

export const selectVolumeOptionsState = (store: AppState) =>
  store.volumeDriver.options.volume;

export const selectVolumeOptionsLoadingState = (store: AppState) =>
  selectVolumeOptionsState(store).isLoading;

export const selectVolumeOptionsItems = (store: AppState) =>
  selectVolumeOptionsState(store).items;
