import { put } from 'redux-saga/effects';
import StorageUtils from '@app/shared/utils/storage/storage-utils';
import { setLoggedInAction } from '../actions';

export function* initializeSessionSaga() {
  const accessToken = StorageUtils.getAuthToken();

  if (accessToken) {
    yield put(setLoggedInAction({ loggedIn: true }));
  }
}
