import { put, all, take } from 'redux-saga/effects';
import {
  fetchMarginOptionsAction,
  setMarginOptionsDataAction,
} from '../../margin-options';
import {
  fetchVolumeOptionsAction,
  setVolumeOptionsDataAction,
} from '../../volume-options';

export function* fetchRequiredOptionsSaga() {
  yield all([
    yield put(fetchMarginOptionsAction({})),
    yield put(fetchVolumeOptionsAction({})),
  ]);

  yield all([
    take(setMarginOptionsDataAction.TYPE),
    take(setVolumeOptionsDataAction.TYPE),
  ]);
}
