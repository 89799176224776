import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { WizardMarginSystem } from '@shared/types/wizard';

interface VolumeSystemSelectProps {
  marginSystem: WizardMarginSystem[];
  selectMarginSystem: WizardMarginSystem[];
  handlerChangeSelectMarginSystem: (values: WizardMarginSystem[]) => void;
}

export const MarginSystemSelect = ({
  marginSystem,
  selectMarginSystem,
  handlerChangeSelectMarginSystem,
}: VolumeSystemSelectProps) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="row"
      justify="flex-start"
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1">Look up margin system</Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={selectMarginSystem}
          multiple
          getOptionSelected={(o, v) => o.id === v.id}
          options={marginSystem}
          getOptionLabel={(i) => i.name}
          onChange={(event, value) => handlerChangeSelectMarginSystem(value)}
          size="small"
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
    </Grid>
  );
};
