import { defineActionGroup } from 'rd-redux-utils';

export const authActionGroup = defineActionGroup('AUTH_ACTIONS');

export const setAuthAction = authActionGroup.defineAction<{
  accessToken: string;
  refreshToken: string;
}>('SET LOADING');

export const logoutAction = authActionGroup.defineAction('LOGOUT');

export const setLoggedInAction = authActionGroup.defineAction<{
  loggedIn: boolean;
}>('SET LOGGED IN');
