import { defineActionGroup } from 'rd-redux-utils';
import { TNotification } from './state';

const snacksActionGroup = defineActionGroup('SNACKS');

export const enqueSnackAction = snacksActionGroup.defineAction<{
  notification: TNotification;
}>('ENQUE SNACK');

export const closeSnackAction = snacksActionGroup.defineAction<{
  dismissAll?: string;
  key: string;
}>('CLOSE SNACK');

export const removeSnackAction = snacksActionGroup.defineAction<{
  key: string;
}>('REMOVE SNACK');
