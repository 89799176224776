import { put } from 'redux-saga/effects';
import { enqueSnackAction } from '@app/store/snacks';
import { call } from 'redux-saga/effects';
import { RightsApi } from '@services/rights-service/rights-service';
import { setUserForTempRightsAction } from '..';

export function* fetchUserForTempURSagaWorker() {
  try {
    const payload = yield call(RightsApi.getUserForTempUR);

    yield put(setUserForTempRightsAction({ payload: payload.data }));
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get Users info.',
          options: {
            variant: 'error',
          },
          key: 'team-rights-fetch',
        },
      })
    );
  }
}
