import { UpdateValueRightsItem } from '@app/services/rights-service/dto/update-product-group-rights.dto';
import { TValueRights } from '@app/services/rights-service/response-interfaces/get-rights.response';

export interface ValueRightsState {
  items: TValueRights[];
  total: number;
  updateItems: Record<string, UpdateValueRightsItem>;
  loading: boolean;
  isOpenModal: boolean;
}

export const initialState: ValueRightsState = {
  items: [],
  total: 0,
  loading: true,
  updateItems: {},
  isOpenModal: false,
};
