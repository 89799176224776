import { call, put } from 'redux-saga/effects';
import {
  fetchProductGroupAllocations,
  setLoadingGroupsTableAction,
  updateBulkAllocationLevelAction,
} from '../actions';
import ProductValueService from '@services/product-value-service/product-value-service';
import { enqueSnackAction } from '@app/store/snacks';
import { setBackdropLoadingAction } from '@app/store/app/actions';

export function* updateBulkAllocationLevelSaga(
  action: ReturnType<typeof updateBulkAllocationLevelAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));

    yield put(setLoadingGroupsTableAction({ payload: true }));
    yield call(ProductValueService.updateBulkAllocationLevel, action.payload);
    yield put(
      fetchProductGroupAllocations({
        payload: {
          productGroupIds: action.payload.productGroupIds,
          valueAllocations: action.payload.valueAllocations,
        },
      })
    );
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Allocations Level is saved',
          options: {
            variant: 'success',
          },
          key: 'save bulk allocation level',
        },
      })
    );
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create product bulk error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create product bulk error',
        },
      })
    );
  } finally {
    yield put(setLoadingGroupsTableAction({ payload: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
