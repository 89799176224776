export const colors: Record<number, string>[] = [
  {
    2: '#1B6F2E',
    3: '#27A242',
    4: '#32D556',
  },
  {
    2: '#A1800D',
    3: '#C39B0F',
    4: '#F4C20D',
  },
  {
    2: '#3E5580',
    3: '#5B7BB9',
    4: '#779FEE',
  },
];

export const getHeaderColor = (indexOfRow: number, level: number): string => {
  return colors[indexOfRow % colors.length][level];
};
