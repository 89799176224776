import { AppState } from '@app/store/store';

export const selectPackagingGroupsState = (store: AppState) =>
  store.filters.packagingGroups;

export const selectPackagingGroupsItems = (store: AppState) => {
  return selectPackagingGroupsState(store).items;
};

export const selectPackagingGroupsLoadingState = (store: AppState) =>
  selectPackagingGroupsState(store).loading;

export const selectPackagingGroupsIsFullList = (store: AppState) =>
  selectPackagingGroupsState(store).isFullList;

export const selectSelectedPackagingGroups = (store: AppState) =>
  selectPackagingGroupsState(store).selectedPackagingGroups;
