import React from 'react';
import {
  UpdateWizardVolumeDriver,
  WizardMarginSystem,
  WizardPGAVolumeDriver,
} from '@shared/types/wizard';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import WizardTableContainer from '@modules/Simulator/components/WizardTableContainer/WizardTableContainer';
import {
  pgaVolumeDriverTableHeaders,
  productAllocation,
} from '@app/modules/Simulator/data-definition';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { MarginSystemTableSelect } from '@modules/Simulator/components/SimulatorVolumeMarginStep/components/MarginSystemTableSelect';
import { getUpdateWizardPGAVolumeDriverSelector } from '@app/store/wizard';
import { useSelector } from 'react-redux';
import { getWizardCurrentProductNameSelector } from '@app/store/wizard';
import { UnitOption, unitOptions } from '@modules/VolumeDriver/SystemConfigurations/components/UnitSelect/unit-options';

interface PGAVolumeDriverTableProps {
  data: WizardPGAVolumeDriver[];
  marginSystem: Record<string, WizardMarginSystem>;
  handlerChangeMarginSystem: (
    value: Record<string, UpdateWizardVolumeDriver>
  ) => void;
  canEdit: boolean
}

export const PGAVolumeDriverTable = ({
  data,
  marginSystem,
  handlerChangeMarginSystem,
  canEdit
}: PGAVolumeDriverTableProps) => {
  //===== store data =====

  const updateData = useSelector(getUpdateWizardPGAVolumeDriverSelector);
  const productName = useSelector(getWizardCurrentProductNameSelector);

  return (
    <Grid container direction="column" style={{ margin: '2rem 0' }}>
      <Grid item>
        <Typography variant="subtitle1" style={{ margin: '0 0 1.5rem' }}>
          Volume driver for {productName}:
        </Typography>
      </Grid>
      <Grid item>
        <WizardTableContainer>
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                {pgaVolumeDriverTableHeaders.map((i) => (
                  <TableCell key={i.key} style={{ minWidth: '5rem' }}>
                    {i.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((i) => {
                const marginId = updateData[i.id + i.valueAllocation]
                  ? updateData[i.id + i.valueAllocation].marginSystemId
                  : i.marginSystemIds.length === 1
                  ? i.marginSystemIds[0]
                  : null;
                const unit = unitOptions.find(
                  (u: UnitOption): boolean => u.value === i.unit
                );
                return (
                  <TableRow key={i.id + i.valueAllocation}>
                    <TableCell>{i.productGroupAllocName}</TableCell>
                    <TableCell>
                      {productAllocation[i.valueAllocation]}
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>{i.volumeSystemName}</Grid>
                        <Grid item>
                          <MarginSystemTableSelect
                            isError={!marginId}
                            value={
                              marginId
                                ? marginId
                                : i.marginSystemIds.length > 1
                                ? 'multiple'
                                : 'default'
                            }
                            handlerChange={(e) => {
                              if (
                                i.marginSystemIds.length === 1 &&
                                e === i.marginSystemIds[0]
                              ) {
                                const {
                                  [i.id + i.valueAllocation]: remove,
                                  ...newData
                                } = updateData;
                                handlerChangeMarginSystem(newData);
                              } else {
                                handlerChangeMarginSystem({
                                  ...updateData,
                                  [i.id + i.valueAllocation]: {
                                    productGroupAllocId: i.id,
                                    valueAllocation: i.valueAllocation,
                                    marginSystemId: e,
                                  },
                                });
                              }
                            }}
                            canEdit={canEdit}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {i.vsFirstLevel} {unit?.name}
                          </span>
                        </Grid>
                        <Grid item>
                          {marginId
                            ? marginSystem[marginId].firstLevel + '%'
                            : '—'}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {i.vsSecondLevel} {unit?.name}
                          </span>
                        </Grid>
                        <Grid item>
                          {marginId
                            ? marginSystem[marginId].secondLevel + '%'
                            : '—'}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {i.vsThirdLevel} {unit?.name}
                          </span>
                        </Grid>
                        <Grid item>
                          {marginId
                            ? marginSystem[marginId].thirdLevel + '%'
                            : '—'}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {i.vsFourthLevel} {unit?.name}
                          </span>
                        </Grid>
                        <Grid item>
                          {marginId
                            ? marginSystem[marginId].fourthLevel + '%'
                            : '—'}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {i.vsFifthLevel} {unit?.name}
                          </span>
                        </Grid>
                        <Grid item>
                          {marginId
                            ? marginSystem[marginId].fifthLevel + '%'
                            : '—'}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </WizardTableContainer>
      </Grid>
    </Grid>
  );
};
