import { call, put, select } from 'redux-saga/effects';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';
import {
  fetchTPRTableDataAction,
  fetchWizardPGAVolumeDriverAction,
  getTPRSelectedCOSelector,
  getTPRSelectedProductSelector,
  getTPRSelectedValueAllocationSelector,
  getUpdateWizardPGAVolumeDriverSelector,
} from '..';
import WizardServices from '@services/wizard/wizardServices';
import { UpdateWizardVolumeDriver } from '@shared/types/wizard';

export function* sendWizardPGAVolumeDriverSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);


    const co = yield select(getTPRSelectedCOSelector);
    const product = yield select(getTPRSelectedProductSelector);
    const valueAllocation = yield select(getTPRSelectedValueAllocationSelector);

    const updatePVolumeGroup: Record<
      string,
      UpdateWizardVolumeDriver
    > = yield select(getUpdateWizardPGAVolumeDriverSelector);
    const res = yield call(
      WizardServices.updatePGAVolumeDriver,
      coId,
      Object.values(updatePVolumeGroup)
    );
    if (res.status === 200)  {
      yield put(fetchWizardPGAVolumeDriverAction({}));

      if (co && product && valueAllocation) {
        if (co.key === coId) {
          yield put(
            fetchTPRTableDataAction({
              payloads: {
                coId: co.key || '',
                productId: product.key || '',
                valuesAllocations: valueAllocation.key || '',
              },
            })
          );
        }
      }
      
    }
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
