import { PackagingDriverApi } from '@app/services/packaging-driver-service/packaging-driver.service';
import { GetCommercialOrganizationsWithCurrencyResponse } from '@app/services/packaging-driver-service/response-interfaces/get-commercial-organizations-with-currency.response';
import { call, put } from 'redux-saga/effects';
import {
  fetchCOWithCurrencyAction,
  fetchCOWithCurrencyDoneAction,
} from '../actions';

export function* fetchCOWithCurrencySaga(
  action: ReturnType<typeof fetchCOWithCurrencyAction>
) {
  try {
    const coWithCurrencies: GetCommercialOrganizationsWithCurrencyResponse =
      yield call(PackagingDriverApi.getCommercialOrganizationsWithCurrency);

    const coWithCurrency = coWithCurrencies.data.find(
      (coWC) => coWC.id === action.coId
    );

    yield put(fetchCOWithCurrencyDoneAction({ coWithCurrency }));
  } catch (error) {
    console.error(
      'Failed to get commercial organizations with currency data.',
      error
    );
  }
}
