import { ExceptionsNegotiationUpdate } from '@app/modules/NegotiationsRoom/tabs/ExceptionsTab/components/ExceptionsTable';
import { ResponseNegotitaionException } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-exception.response';

export interface NegotiationExceptionState {
  items: ResponseNegotitaionException[];
  totalItems: number;
  isLoading: boolean;
  updateData: Record<string, ExceptionsNegotiationUpdate>;
  modalIsOpen: boolean;
}

export const initialState: NegotiationExceptionState = {
  isLoading: true,
  totalItems: 0,
  items: [],
  updateData: {},
  modalIsOpen: false,
};
