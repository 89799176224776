import { TGetVolumeSystemConfigurationResponse } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import {
  fetchVolumeDriverConfigurationsDataAction,
  fetchVolumeDriverConfigurationsDoneAction,
} from '../actions';

export function* fetchVolumeSystemConfigurationsSaga(
  action: ReturnType<typeof fetchVolumeDriverConfigurationsDataAction>
) {
  const { skip, limit } = action;

  try {
    const request: TGetVolumeSystemConfigurationResponse = yield call(
      VolumeDriverApi.getVolumeSystemConfigurations,
      { skip, limit }
    );
    yield put(
      fetchVolumeDriverConfigurationsDoneAction({
        items: request.data,
        total: request.total,
      })
    );
  } catch (e) {
    console.log('Failed to fetch Volume driver Configurations list', e.message);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get Volume driver Configurations list.',
          options: {
            variant: 'error',
          },
          key: 'volume-driver-configurations-fetch-failed',
        },
      })
    );
    yield put(
      fetchVolumeDriverConfigurationsDoneAction({
        items: [],
        total: 0,
      })
    );
  }
}
