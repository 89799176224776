import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import { PackagingDriverApi } from '@app/services/packaging-driver-service/packaging-driver.service';
import { TGetPackagingMarginListResponse } from '@app/services/packaging-driver-service/response-interfaces/get-margin-list.response';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import {
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { selectSelectedPackagingGroups } from '@app/store/filters-module/packaging-groups/selectors';
import { put, call, select } from 'redux-saga/effects';
import { fetchDriverMarginListDoneAction } from '../actions';

export function* fetchDriverMarginSaga() {
  const [skip, limit] = [getCurrentSkip(), getCurrentPageLimit()];

  const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
  const selectedAllocationLevels: TFilterAllocationLevels = yield select(
    selectCoSelectedAllocationLevels
  );
  const filtersValue: TFilterItemResponse[] = yield select(
    selectCoSelectedFilters
  );
  if (!filtersValue.length) {
    yield put(
      fetchDriverMarginListDoneAction({
        items: [],
        total: 0,
      })
    );
    return;
  }
  const selectedPackagingGroups: TPackagingOption[] = yield select(
    selectSelectedPackagingGroups
  );
  const selectedProductGroups = filtersValue.filter((f) => f.level === 4);
  try {
    const request: TGetPackagingMarginListResponse = yield call(
      PackagingDriverApi.getMarginList,
      {
        skip,
        limit,
        packagingGroupIds: selectedPackagingGroups.map((group) => group.id),
        productGroupIds: selectedProductGroups.map((group) => group.id),
        valueAllocations: selectedAllocationLevels.map((level) => level.key),
        coId: selectedCo.id,
      }
    );
    yield put(
      fetchDriverMarginListDoneAction({
        items: request.data,
        total: request.total,
      })
    );
  } catch (e) {
    console.log('Failed to get margin list data', e.message);
  }
}
