import { AppTable } from '@app/shared/components/AppTable';
import { BaseMarginExceptionDataTable } from '@app/shared/types/baseMargin';
import {
  deleteExceptionAction,
  fetchBaseMarginExceptionAction,
  setCurrentExceptionIds,
} from '@app/store/baseMargin';
import {
  getBaseMarginLoadingSelector,
  getCOCurrencySelector,
  getExceptions,
  getExceptionTotal,
} from '@app/store/baseMargin/selectors';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell/TableCell';
import { headCells } from './headers';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { TableCellInput } from '@app/shared/components/TableCellInput';
import styles from './ExceptionsTable.module.scss';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { parseDate, parseDateAsLocal, parseTimeDate, validateExpiration } from '@app/shared/utils/dateTimeUtil';

const map: Record<number, string> = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

interface Props {
  onReminder: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    data: Record<string, string>
  ) => void;
  onChangeInput: (
    e: any,
    id: string,
    data: Record<string, string | boolean>
  ) => void;
  dataException: BaseMarginExceptionDataTable[];
}

export const ExceptionsTable: React.FC<Props> = ({
  onReminder,
  onChangeInput,
  dataException,
}) => {
  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;

  const dispatch = useDispatch();
  const total = useSelector(getExceptionTotal);
  const loading = useSelector(getBaseMarginLoadingSelector);
  const currentExceptionIds = useSelector(getExceptions);
  const baseMartrixCO = useSelector(selectSelectedCo);
  const coCurrency = useSelector(getCOCurrencySelector);

  const currentCurrency = useMemo(() => {
    const currentCO = coCurrency.find((item) => item.id === baseMartrixCO?.id);
    return currentCO;
  }, [coCurrency, baseMartrixCO]);
  useEffect(() => {
    dispatch(
      fetchBaseMarginExceptionAction({
        payload: {
          coId: baseMartrixCO?.id as string,
          skip: 0,
          limit: 25,
        },
      })
    );
  }, [dispatch, baseMartrixCO]);
  const onPagination = useCallback(
    (_page: number, item: number, skip: number) => {
      dispatch(
        fetchBaseMarginExceptionAction({
          payload: {
            coId: baseMartrixCO?.id as string,
            skip: skip,
            limit: item,
          },
        })
      );
    },
    [dispatch, baseMartrixCO]
  );

  const onDeleteRows = (data: BaseMarginExceptionDataTable[]) => {
    dispatch(deleteExceptionAction({ payload: data.map((item) => item.id) }));
  };

  const checkCurrentException = (id: string) => {
    const exception = currentExceptionIds.some((item) => item.id === id);
    return exception;
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentExceptionIds({ payload: [] }));
    };
  }, [dispatch]);

  return (
    <AppTable
      data={dataException}
      dataCount={total}
      onPageChange={onPagination}
      onDelete={(data) => onDeleteRows(data)}
      deletableItems={true}
      loading={loading}
      tableLayout="auto"
      useInMemoryPagination
    >
      <AppTable.Head>
        <AppTable.Row isHead={true} disableDeletionBehavior={true}>
          {headCells.map((item) => (
            <AppTable.HeadCell sortHandler={item.sort} key={item.id}>
              {item.label}
            </AppTable.HeadCell>
          ))}
        </AppTable.Row>
      </AppTable.Head>
      <AppTable.Body>
        {(data: BaseMarginExceptionDataTable[]) =>
          data.map((row, index) => {
            const marginType = currentCurrency?.coCurrencies.find(
              (item) => item.key === row.marginType
            );

            const isException = checkCurrentException(row.id);

            return (
              <AppTable.Row<BaseMarginExceptionDataTable>
                className={isException ? styles.exceptionRow : undefined}
                role="checkbox"
                item={row}
                tabIndex={-1}
                key={index}
                style={isException ? { background: '#FFF5F1' } : undefined}
              >
                <TableCell style={{ width: '100px' }}>
                  <TableCellInput
                    roleProtected={isDisabled}
                    label="Exception Name"
                    name="name"
                    value={row.name}
                    onChange={(e: any) =>
                      onChangeInput(e, row.id, {
                        name: row.name,
                        description: row.description,
                        reminder: row.reminder,
                      })
                    }
                  />
                </TableCell>
                <TableCell style={{ minWidth: '156px' }}>
                  <TableCellInput
                    roleProtected={isDisabled}
                    label="Exception Description"
                    name="description"
                    symbolsLimit={20}
                    value={row.description}
                    type="description"
                    onChange={(e: any) =>
                      onChangeInput(e, row.id, {
                        name: row.name,
                        description: row.description,
                        reminder: row.reminder,
                      })
                    }
                  />
                </TableCell>
                <TableCell>{row.groupLevelOneName}</TableCell>
                <TableCell>{row.groupLevelTwoName}</TableCell>
                <TableCell>{row.groupLevelThreeName}</TableCell>
                <TableCell>{row.groupLevelFourName}</TableCell>
                <TableCell>{row.mainGroupName}</TableCell>
                <TableCell>{map[row.valueAllocationLevel]}</TableCell>
                <TableCell>{row.productName}</TableCell>
                <TableCell>{row.productNumber}</TableCell>
                <TableCell>{row.industryLevelOneName}</TableCell>
                <TableCell>{row.industryLevelTwoName}</TableCell>
                <TableCell>{row.industryLevelThreeName}</TableCell>
                <TableCell>{row.industryLevelFourName}</TableCell>
                <TableCell>
                  {row.margin} {marginType ? marginType.value : '%'}
                </TableCell>
                <TableCell>{parseDateAsLocal(row.validFrom)}</TableCell>
                <TableCell>{parseDateAsLocal(row.validTo)}</TableCell>
                <TableCell>
                  <Switch
                    disabled={isDisabled}
                    checked={row.reminder}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onReminder(e, row.id, {
                        name: row.name,
                        description: row.description,
                      })
                    }
                    color="primary"
                  />
                </TableCell>
                    <TableCell>
                        {validateExpiration(row.validFrom, row.validTo) === 1 ? 'Inactive' : 'Active'}
                    </TableCell>
                <TableCell>{row.enteredByUser ?? '—'}</TableCell>
                <TableCell>{parseTimeDate(row.creationDate)}</TableCell>
              </AppTable.Row>
            );
          })
        }
      </AppTable.Body>
    </AppTable>
  );
};
