import { ManagersApi } from '@app/services/managers-service/managers-service';
import { TGetManagersByCoResponse } from '@app/services/managers-service/response-interfaces/get-managers-by-co.response';
import { put } from 'redux-saga/effects';
import {
  fetchManagersByCoAction,
  setManagersDataAction,
  setManagersLoadingAction,
} from '../actions';

export function* fetchManagersByCoSaga(
  action: ReturnType<typeof fetchManagersByCoAction>
) {
  try {
    yield put(setManagersLoadingAction({ loading: true }));

    const request: TGetManagersByCoResponse = yield ManagersApi.getManagersByCo(
      { coId: action.coId }
    );

    yield put(
      setManagersDataAction({ payload: request.data, coId: action.coId })
    );
  } catch (e) {
    console.log('Failed to get managers', e.message);
  } finally {
    yield put(setManagersLoadingAction({ loading: false }));
  }
}
