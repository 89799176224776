import {
  setBaseMarginLoadingAction,
  setUpdateBaseMarginException,
  updateBaseMarginExceptionPaginationAction,
} from '../actions';
import { call, put } from 'redux-saga/effects';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { enqueSnackAction } from '@app/store/snacks';

export function* updateBaseMarginExceptionPaginationSaga(
  action: ReturnType<typeof updateBaseMarginExceptionPaginationAction>
) {
  try {
    yield put(setBaseMarginLoadingAction({ payload: true }));
    yield call(BaseMarginService.updateBaseMarginExceptions, action.payload);
    yield put(setUpdateBaseMarginException({ payload: {} }));
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Exceptions is saved',
          options: {
            variant: 'success',
          },
          key: 'save exception margin',
        },
      })
    );
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'error exceptions',
        },
      })
    );
    console.log(`Failed to update base margin exception: ${e.toString()}`);
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
  }
}
