import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { TProductRightResponse } from '@app/services/rights-service/response-interfaces/get-rights.response';
import { RightsApi } from '@app/services/rights-service/rights-service';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import {
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  setProductRightsDataAction,
  setProductRightsLoadingAction,
} from '../actions';

export function* fetchProductRightsSaga() {
  const [skip, limit] = [getCurrentSkip(), getCurrentPageLimit()];
  const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);
  const filtersValue: TFilterItemResponse[] = yield select(
    selectCoSelectedFilters
  );
  const selectedProductGroups = filtersValue.filter((f) => f.level === 4);

  try {
    yield put(setProductRightsLoadingAction({ loading: true }));
    const payload: TProductRightResponse = yield call(
      RightsApi.getProductGroupRights,
      {
        skip,
        limit,
        isAsc: true,
        orderColumn: 0,
        coId: selectedCO.id,
        groupIds: selectedProductGroups.map((i) => i.id),
      }
    );

    yield put(
      setProductRightsDataAction({
        items: payload.data,
        total: payload.total,
      })
    );
  } catch (e) {
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'product-rights-fetch',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'product-rights-fetch',
        },
      })
    );

    yield put(setProductRightsDataAction({ items: [], total: 0 }));
  } finally {
    yield put(setProductRightsLoadingAction({ loading: false }));
  }
}
