import { VOLUME_DRIVER_ALLOCATION } from '@app/routes/urls';
import { LocationChangeAction } from 'connected-react-router';
import { call, put, select, take, all } from 'redux-saga/effects';
import {
  fetchDriverAllocationsAction,
  setDriverAllocationLoadingAction,
} from '../actions';
import { fetchRequiredOptionsSaga } from './fetchRequiredOptionsSaga';
import {
  selectSelectedCo,
  fetchCoAction,
  setSelectedCo,
  selectCoFiltersId,
  fetchCoFiltersAction,
  setCoDataAction,
  selectCoData,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { selectFiltersIsFullList } from '@app/store/filters-module/filters-state';
import { selectPackagingGroupsIsFullList } from '@app/store/filters-module/packaging-groups/selectors';

export function* volumeDriverAllocationsWorkerSaga(
  action: LocationChangeAction
) {
  const { search, pathname } = action.payload.location;

  if (VOLUME_DRIVER_ALLOCATION.match(pathname, true).isMatched && search) {
    yield put(setDriverAllocationLoadingAction({ loading: true }));
    const coItems: TCommercialOrganization[] = yield select(selectCoData);

    if (!coItems.length) {
      yield put(fetchCoAction({}));
      yield all([take([setCoDataAction.TYPE]), take([setSelectedCo.TYPE])]);
    }

    const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
    const filtersCoId: string = yield select(selectCoFiltersId);

    const filterIsFullList: boolean = yield select(selectFiltersIsFullList);
    const packagingIsFullList: boolean = yield select(
      selectPackagingGroupsIsFullList
    );

    if (
      selectedCo.id !== filtersCoId ||
      packagingIsFullList !== filterIsFullList
    ) {
      yield put(fetchCoFiltersAction({}));
      yield take(setSelectedCoFilters.TYPE);
    }
    yield call(fetchRequiredOptionsSaga);

    yield put(fetchDriverAllocationsAction({}));
  }
}
