import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { appStore } from './store';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/store';
import { defaultTheme } from './themes/default.theme';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <Provider store={appStore}>
    <ThemeProvider theme={defaultTheme}>
      <ConnectedRouter history={history}>
        <CssBaseline />
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
