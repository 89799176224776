import { NEGOTIATIONS_URL_PRODUCT_LINE } from '@app/routes/urls';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  fetchCoAction,
  selectSelectedCo,
  setSelectedCo,
} from '@app/store/filters-module/co';
import { LocationChangeAction } from 'connected-react-router';
import { put, select, take } from 'redux-saga/effects';
import { fetchProductLineDataAction } from '../action';

export function* productLineWorkerSaga(action: LocationChangeAction) {
  const { pathname, search } = action.payload.location;

  if (NEGOTIATIONS_URL_PRODUCT_LINE.match(pathname, true).isMatched && search) {
    const selectedCo: TCommercialOrganization | null = yield select(
      selectSelectedCo
    );

    if (!selectedCo) {
      yield put(fetchCoAction({}));

      yield take(setSelectedCo.TYPE);
    }

    yield put(fetchProductLineDataAction({}));
  }
}
