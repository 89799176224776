import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWizardProductGroupAllocationLevelsAction,
  getWizardCurrentAllocationLevelSelector,
  getWizardProductGroupAllocationLevelSelector,
  setWizardCurrentAllocationLevelsAction,
} from '@app/store/wizard';
import { Typography } from '@material-ui/core';

export const ProductGroupSelect = () => {
  const productGroups = useSelector(
    getWizardProductGroupAllocationLevelSelector
  );
  const currentProductGroup = useSelector(
    getWizardCurrentAllocationLevelSelector
  );
  let [loading, toggleLoading] = useState(false);
  useEffect(() => {
    toggleLoading(productGroups.length !== 0);
  }, [productGroups]);

  const dispatch = useDispatch();
  const fetchProductGroups = useCallback(() => {
    if (productGroups.length === 0) {
      toggleLoading(true);
      dispatch(fetchWizardProductGroupAllocationLevelsAction({}));
    }
  }, [dispatch, productGroups]);

  const changeProductGroup = useCallback(
    (value) => {
      dispatch(setWizardCurrentAllocationLevelsAction({ payload: value }));
    },
    [dispatch]
  );

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="row"
      justify="flex-start"
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1">
          2. Select a product group allocation level
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          loading={loading}
          value={currentProductGroup}
          onOpen={fetchProductGroups}
          options={productGroups}
          getOptionLabel={(i) => i.name}
          onChange={(event, value) => changeProductGroup(value)}
          renderInput={(params) => (
            <TextField {...params} label="Product group" variant="outlined" />
          )}
        />
      </Grid>
    </Grid>
  );
};
