import * as React from 'react';
import styles from './HomeQuickLink.module.scss';

import { Link } from 'react-router-dom';

export interface QuickLinkProps {
  iconSrc: any;
  title: string;
  pathTo: string;
}

interface Props extends QuickLinkProps {}

export const HomeQuickLink = (props: Props) => {
  const { iconSrc, title, pathTo } = props;

  return (
    <Link to={pathTo} className={styles.quickLink}>
      <div className={styles.quickLink_icon}>
        <span className={styles.quickLink_link}>{iconSrc}</span>
      </div>
      <div className={styles.quickLink_title}>
        <span className={styles.quickLink_link}>
          <span title={title}>{title}</span>
        </span>
      </div>
    </Link>
  );
};
