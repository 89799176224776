import {
  createExceptionAction,
  setBaseMarginExceptionAction,
  setBaseMarginLoadingAction,
  setUpdateBaseMarginException,
  openModalExceptionAction,
} from '../actions';
import { call, put, select } from 'redux-saga/effects';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { AppState } from '@app/store';
import { BaseMarginExceptions } from '@app/shared/types/baseMargin';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { enqueSnackAction } from '@app/store/snacks';
import { setBackdropLoadingAction } from '@app/store/app/actions';

export function* createExceptionWorkerSaga(
  action: ReturnType<typeof createExceptionAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));
    yield put(setBaseMarginLoadingAction({ payload: true }));
    const coId: string = yield select(
      (state: AppState) => state.filters.co.selectedItem?.id
    );
    const data = {
      ...action.payload,
      coId,
    };
    yield call(BaseMarginService.createException, data);
    const newData: BaseMarginExceptions = yield call(
      BaseMarginService.fetchBaseMarginExceptions,
      {
        coId,
        skip: getCurrentSkip(),
        limit: getCurrentPageLimit(),
      }
    );
    yield put(setUpdateBaseMarginException({ payload: {} }));
    yield put(setBaseMarginExceptionAction({ payload: newData }));
    yield put(openModalExceptionAction({ payload: false }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create eexception error',
          },
        })
      );
      yield put(openModalExceptionAction({ payload: true }));
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create eexception error',
        },
      })
    );
    console.log(`Failed to create exception: ${e.toString()}`);
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
