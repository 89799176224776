import { UserDto } from '@app/services/user-service/dto';
import { UserApi } from '@app/services/user-service/user-service';
import { fetchAccountRightsWorkerSaga } from '@app/store/account-rights/saga/fetchAccountRightsWorkerSaga';
import { put, call } from 'redux-saga/effects';
import { setUserAction, setUserLoadingAction } from '../actions';

export function* fetchUserSaga() {
  yield put(setUserLoadingAction({ loading: true }));

  try {
    const user: UserDto = yield UserApi.getUser();

    yield put(
      setUserAction({
        id: user.id,
        fullName: user.fullName,
        email: user.email,
      })
    );
    yield call(fetchAccountRightsWorkerSaga);
    console.log('User loaded');
  } catch (e) {
    console.log(`Failed to fetch user: ${e.toString()}`);
  } finally {
    yield put(setUserLoadingAction({ loading: false }));
  }
}
