import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import classnames from 'classnames';
import styles from './AppFormDialog.module.scss';

interface Props {
  isOpened: boolean;
  isLoading?: boolean;
  title: string | React.ReactNode;
  actions: React.ReactNode | React.ReactNodeArray;
  content: React.ReactNode | React.ReactNodeArray;
  onClose?: Function;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

export const AppFormDialog = (props: Props) => {
  const {
    isOpened,
    isLoading = false,
    title,
    actions,
    content,
    onClose,
    size,
  } = props;

  return (
    <Dialog
      open={isOpened}
      fullWidth={true}
      maxWidth={size ? size : 'xs'}
      onClose={typeof onClose === 'function' ? (onClose as any) : undefined}
    >
      <LinearProgress
        className={classnames(styles.progress, {
          [styles.progress_disabled]: !isLoading,
        })}
      />
      {typeof title === 'object' ? (
        <DialogTitle disableTypography>{title}</DialogTitle>
      ) : (
        <DialogTitle disableTypography>
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
      )}
      <Divider />
      <DialogContent
        className={classnames(styles.dialogContent, {
          [styles.dialogContent_disabled]: isLoading,
        })}
      >
        {content}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
