import { AppTable } from '@app/shared/components/AppTable';
import { HelpTooltip } from '@app/shared/components/HelpTooltip';
import { selectSelectedCo } from '@app/store/filters-module/co';
import {
  selectProductLineItems,
  selectProductTotalItems,
  selectProductLineItemsLoadingState,
  setUpdateProductLineAction,
  selectProductLineUpdateItems,
} from '@app/store/negotiation-room/product-line';
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { TProductLineItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-product-line.response';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import { TableCellInput } from '@app/shared/components/TableCellInput';
import {
  transformToPercentString,
  transformToSaveDecimalString,
} from '@app/shared/utils/transformUtils';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';

const { Row, Cell, HeadCell, Body, Head } = AppTable;

export const ProductLineTable = () => {

  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;

  const dispatch = useDispatch();
  const selectedCo = useSelector(selectSelectedCo);

  const totalItems = useSelector(selectProductTotalItems);
  const items = useSelector(selectProductLineItems);
  const isLoading = useSelector(selectProductLineItemsLoadingState);
  const updateData = useSelector(selectProductLineUpdateItems);

  const dataTable = React.useMemo(() => {
    return items.map((i) => {
      const find = Object.keys(updateData).some((s) => s === i.id);
      if (find) {
        return {
          ...i,
          ...updateData[i.id],
        };
      }
      return {
        ...i,
      };
    });
  }, [items, updateData]);
  const handleInputChange = React.useCallback(
    (e: any) => {
      const id = e.target.getAttribute('data-id');
      const name = e.target.name;
      const value = isNaN(e.target.value)
        ? e.target.value
        : transformToSaveDecimalString(e.target.value);
      const currentRow = dataTable.find((f) => f.id === id);
      dispatch(
        setUpdateProductLineAction({
          payload: {
            ...(currentRow as TProductLineItem),
            [name]: value,
          },
        })
      );
    },
    [dispatch, dataTable]
  );

  return (
    <AppTable
      isEmpty={!dataTable.length ? true : false}
      data={dataTable}
      dataCount={totalItems}
      loading={isLoading}
    >
      <Head>
        <Row isHead>
          <HeadCell colSpan={1}></HeadCell>
          <HeadCell colSpan={3} align="center">
            {selectedCo ? selectedCo.name : null}
          </HeadCell>
        </Row>
        <Row>
          <HeadCell colSpan={1} align={'center'} preWrap>
            Customer Segment
          </HeadCell>

          <HeadCell align="center">
            Negotiation room limit 1
            <br />
            <Grid container>
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                style={{ fontWeight: 'normal' }}
              >
                (in margin index){' '}
                <HelpTooltip hintText="Approval by sales lead" />
              </Grid>
            </Grid>
          </HeadCell>

          <HeadCell align="center">
            Negotiation room limit 2
            <br />
            <Grid container>
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                style={{ fontWeight: 'normal' }}
              >
                (in margin index){' '}
                <HelpTooltip hintText="Approval by product manager" />
              </Grid>
            </Grid>
          </HeadCell>

          <HeadCell align="center">
            Negotiation room limit 3
            <br />
            <Grid container>
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                style={{ fontWeight: 'normal' }}
              >
                (in margin index){' '}
                <HelpTooltip hintText="Approval by managing director " />
              </Grid>
            </Grid>
          </HeadCell>
        </Row>
      </Head>
      <Body<TProductLineItem>
        noDataText={NO_FILTERS_DATA_TABLE_TEXT}
        contentColSpan={4}
      >
        {(items) =>
          items.map((item) => {
            return (
              <Row key={item.id}>
                <Cell align={'center'}>
                  <TableCellInput
                    value={item.name}
                    type={'text'}
                    label={'Customer Segment'}
                    data-id={item.id}
                    name={'name'}
                    onChange={handleInputChange}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell align={'center'}>
                  <TableCellInput
                    value={item.firstLimit}
                    type={'number'}
                    label={'Negotiation room limit 1'}
                    data-id={item.id}
                    name={'firstLimit'}
                    onChange={handleInputChange}
                    transformToViewValue={transformToPercentString}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell align={'center'}>
                  <TableCellInput
                    value={item.secondLimit}
                    type={'number'}
                    label={'Negotiation room limit 2'}
                    data-id={item.id}
                    name={'secondLimit'}
                    onChange={handleInputChange}
                    transformToViewValue={transformToPercentString}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell align={'center'}>
                  <TableCellInput
                    value={item.thirdLimit}
                    type={'number'}
                    label={'Negotiation room limit 3'}
                    data-id={item.id}
                    name={'thirdLimit'}
                    onChange={handleInputChange}
                    transformToViewValue={transformToPercentString}
                    roleProtected={isDisabled}
                  />
                </Cell>
              </Row>
            );
          })
        }
      </Body>
    </AppTable>
  );
};
