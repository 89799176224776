import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const timezone = new Date().getTimezoneOffset();

export class RequestService {
  constructor(private readonly adapter: AxiosInstance) {}

  async get<TData = any>(
    url: string,
    options: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<TData>> {
    return this.adapter.get(url, options);
  }

  async post<TData = any>(
    url: string,
    data: any,
    options: AxiosRequestConfig = {
      headers: {
        'x-timezoneoffset': timezone
      }
    }
  ): Promise<AxiosResponse<TData>> {
    return this.adapter.post(url, data, options);
  }

  async put<TData = any>(
    url: string,
    data: any,
    options: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<TData>> {
    return this.adapter.put(url, data, options);
  }

  async delete<TData = any>(
    url: string,
    options: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<TData>> {
    return this.adapter.delete(url, options);
  }
}
