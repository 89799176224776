import { TPackagingMarginListItem } from '@app/services/packaging-driver-service/response-interfaces/get-margin-list.response';
import { CommercialOrganizationsWithCurrency } from '@app/services/packaging-driver-service/response-interfaces/get-commercial-organizations-with-currency.response';
import { SaveMarginDto } from '@app/services/packaging-driver-service/dto/save-margin.dto';
import { defineActionGroup } from 'rd-redux-utils';

const driverMarginActionGroup = defineActionGroup('DRIVER MARGIN');

export const fetchDriverMarginListAction =
  driverMarginActionGroup.defineAction<{}>('FETCH LIST');

export const fetchDriverMarginListDoneAction =
  driverMarginActionGroup.defineAction<{
    items: TPackagingMarginListItem[];
    total: number;
  }>('FETCH LIST DONE');

export const fetchCOWithCurrencyAction = driverMarginActionGroup.defineAction<{
  coId: string;
}>('FETCH COMMERCIAL ORGANIZATIONS WITH CURRENCY');

export const fetchCOWithCurrencyDoneAction =
  driverMarginActionGroup.defineAction<{
    coWithCurrency?: CommercialOrganizationsWithCurrency;
  }>('FETCH COMMERCIAL ORGANIZATIONS WITH CURRENCY DONE');

export const setMarginListLoadingAction = driverMarginActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setMarginListDataAction = driverMarginActionGroup.defineAction<{
  items: TPackagingMarginListItem[];
  total: number;
}>('SET DATA');

export const setMarginSavingAction = driverMarginActionGroup.defineAction<{
  isSaving: boolean;
}>('SET SAVING');

export const saveMarginValuesAction =
  driverMarginActionGroup.defineAction<SaveMarginDto>('SAVE MARGIN VALUES');

export const setMarginListLoadedAction = driverMarginActionGroup.defineAction<{
  loaded: boolean;
}>('SET LOADED');

export const saveBulkMarginAction = driverMarginActionGroup.defineAction<{
  margin: string;
}>('SAVE MARGIN BULK');
