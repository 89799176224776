import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import {
  createSystemConfigurationAction,
  deleteVolumeSystemConfigurationsAction,
  fetchVolumeDriverConfigurationsDataAction,
  saveVolumeSystemConfigurationsAction,
} from '../actions';
import { createrVolumeSystemConfigurationSaga } from './createrVolumeSystemConfigurationSaga';
import { deleteVolumeSystemConfigurationsSaga } from './deleteVolumeSystemConfigurationsSaga';
import { fetchVolumeSystemConfigurationsSaga } from './fetchVolumeSystemConfigurationsSaga';
import { saveVolumeSystemConfigurationsSaga } from './saveVolumeSystemConfigurationsSaga';
import { volumeSystemConfigurationsWorkerSaga } from './volumeSystemConfigurationsWorkerSaga';

export function* volumeSystemConfigurationsWatcherSaga() {
  yield all([
    takeEvery(LOCATION_CHANGE, volumeSystemConfigurationsWorkerSaga),
    takeEvery(
      fetchVolumeDriverConfigurationsDataAction.TYPE,
      fetchVolumeSystemConfigurationsSaga
    ),
    takeEvery(
      createSystemConfigurationAction.TYPE,
      createrVolumeSystemConfigurationSaga
    ),
    takeEvery(
      deleteVolumeSystemConfigurationsAction.TYPE,
      deleteVolumeSystemConfigurationsSaga
    ),
    takeEvery(
      saveVolumeSystemConfigurationsAction.TYPE,
      saveVolumeSystemConfigurationsSaga
    ),
  ]);
}
