import {
  WizardBaseMarginMatrixOrigin,
  WizardPackagingMarginOrigin,
} from '@shared/types/wizard';
import { WizardPGAVolumeDriver } from '@shared/types/wizard';
import { LookupValueSystem } from '@shared/types/wizard';
import {
  UnitOption,
  unitOptions,
} from '@modules/VolumeDriver/SystemConfigurations/components/UnitSelect/unit-options';

export const getTPRBaseMargin = (
  baseMargin: WizardBaseMarginMatrixOrigin[],
  defaultMargin: boolean,
  industryLevel: string,
  industryId: string
): number | null =>
  baseMargin.length
    ? baseMargin[0].marginTypes.length === 1
      ? defaultMargin
        ? baseMargin[0].marginValues.length === 1
          ? Number(baseMargin[0].marginValues[0])
          : null
        : baseMargin[0].industryMargins.reduce(
            (res, i) =>
              res !== null
                ? res
                : String(i.industryLevel) === industryLevel &&
                  i.industryId === industryId
                ? i.marginValues.length === 1
                  ? Number(i.marginValues[0])
                  : null
                : null,
            null as null | number
          )
      : null
    : null;

export const getTPRBaseMarginIsPercent = (
  baseMargin: WizardBaseMarginMatrixOrigin[]
): boolean =>
  baseMargin.length !== 0 &&
  baseMargin[0].marginTypes.length === 1 &&
  baseMargin[0].marginTypes[0] !== 0;

export const getTPRPackagingDriver = (
  packagingDriver: WizardPackagingMarginOrigin[]
): { id: string; name: string; margin: number; marginType: number }[] => {
  if (packagingDriver.length > 0) {
    return packagingDriver.map((x) => ({
      id: x.packagingGroupId,
      name: x.packagingGroupName,
      margin: Number(x.productValues[0].margin[0].replace(',', '.')),
      marginType: x.productValues[0].marginType,
    }));
  }

  return [];
};

export const getTPRMarginVolumeDriver = (
  volumeDriver: WizardPGAVolumeDriver[],
  marginVolumeDriver: LookupValueSystem[]
): { key: string; name: string; value: number }[] | null => {
  const margin = marginVolumeDriver.find(
    (mvd) => mvd.id === volumeDriver[0].marginSystemIds[0]
  );

  return volumeDriver.length && marginVolumeDriver.length && margin
    ? [
        {
          key: 'firstLevel',
          name: volumeDriver[0].vsFirstLevel,
          value: margin.firstLevel,
        },
        {
          key: 'secondLevel',
          name: volumeDriver[0].vsSecondLevel,
          value: margin.secondLevel,
        },
        {
          key: 'thirdLevel',
          name: volumeDriver[0].vsThirdLevel,
          value: margin.thirdLevel,
        },
        {
          key: 'fourthLevel',
          name: volumeDriver[0].vsFourthLevel,
          value: margin.fourthLevel,
        },
        {
          key: 'fifthLevel',
          name: volumeDriver[0].vsFifthLevel,
          value: margin.fifthLevel,
        },
      ]
    : null;
};

export const getTPRUnit = (volumeDriver: WizardPGAVolumeDriver[]): string => {
  const unit =
    volumeDriver[0] &&
    unitOptions.find(
      (u: UnitOption): boolean => u.value === volumeDriver[0].unit
    );
  return unit ? unit.name : '';
};
