import { setUserForTempRightsAction } from './actions';
import {
  fetchUserRightsDataAction,
  initialState,
  setCOReferenceDataAction,
  setTeamReferenceDataAction,
  setUserReferenceDataAction,
  setUserRightsDataAction,
  UserRightsState,
} from '@app/store/settings/user-rights';

export const userRightReducer = (
  state: UserRightsState = initialState,
  action: any
): UserRightsState => {
  if (fetchUserRightsDataAction.is(action)) {
    return {
      ...state,
      loading: true,
      isLoaded: false,
    };
  }

  if (setUserRightsDataAction.is(action)) {
    return {
      ...state,
      loading: false,
      isLoaded: true,
      items: action.payload.items,
      total: action.payload.total,
    };
  }

  //===== SET REFERENCE DATA =====

  if (setUserReferenceDataAction.is(action)) {
    return {
      ...state,
      referenceData: {
        ...state.referenceData,
        users: action.payload,
      },
    };
  }

  if (setUserForTempRightsAction.is(action)) {
    return {
      ...state,
      referenceData: {
        ...state.referenceData,
        usersForTempRights: action.payload,
      },
    };
  }

  if (setTeamReferenceDataAction.is(action)) {
    return {
      ...state,
      referenceData: {
        ...state.referenceData,
        teams: action.payload,
      },
    };
  }

  if (setCOReferenceDataAction.is(action)) {
    return {
      ...state,
      referenceData: {
        ...state.referenceData,
        CO: action.payload,
      },
    };
  }

  return state;
};
