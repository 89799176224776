import { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { HelpTooltip } from '../HelpTooltip';

interface Props extends ButtonProps {
  hintColor?: 'primary' | 'secondary';
  hintText: string;
}

type Colors = 'primary' | 'secondary';

const colorInversionMap: Record<Colors, Colors> = {
  primary: 'secondary',
  secondary: 'primary',
};

export const ButtonWithHintText = (props: Props) => {
  const {
    hintText,
    hintColor,
    color = 'primary',
    variant = 'contained',
    children,
    ...btnProps
  } = props;

  const invertedHintColor = !hintColor
    ? colorInversionMap[color as Colors]
    : hintColor;

  return (
    <Button variant={variant} color={color} {...btnProps}>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item container alignItems="center">
          <pre>{children}</pre>
        </Grid>
        <Grid item container alignItems="center">
          <HelpTooltip hintText={hintText} color={invertedHintColor} />
        </Grid>
      </Grid>
    </Button>
  );
};
