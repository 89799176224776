import { FilterApi } from '@app/services/filter-service/filter-service';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { AppState } from '@app/store';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  fetchCoAction,
  setCoDataAction,
  setCoLoadingAction,
  setSelectedCo,
} from '../actions';

export function* fetchCoSaga(action: ReturnType<typeof fetchCoAction>) {
  try {
    yield put(setCoLoadingAction({ loading: true }));
    const request: TCommercialOrganization[] = yield call(FilterApi.getCo);
    yield put(setCoDataAction({ payload: request }));
    const selectedCo: TCommercialOrganization | null = yield select(
      (state: AppState) => state.filters.co.selectedItem
    );
    yield put(setSelectedCo({ co: selectedCo && request.some(x => x.id == selectedCo?.id) ? 
      selectedCo : request.length ? request[0] : null }));
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get Commercial Organizations list.',
          options: {
            variant: 'error',
          },
          key: 'co-fetch',
        },
      })
    );
  } finally {
    yield put(setCoLoadingAction({ loading: false }));
  }
}
