import { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import * as qs from 'query-string';
import { pickQueryParams } from '@app/shared/utils/pickQueryParams';
import { getCurrentPage, getCurrentPageLimit } from './paginationUtils';

type PageChangeHandler = (_: any, nextPage: number) => void;
type ItemsLimitChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

type ResetPaginationHook = () => void;

interface PaginationState {
  page: number;
  limit: number;
}

export function useAppTablePagination(
  page: number,
  limit: number,
  useUrlPagination = true,
  disabled?: boolean
): [
  number,
  PageChangeHandler,
  number,
  ItemsLimitChangeHandler,
  ResetPaginationHook
] {
  const history = useHistory();
  const { search } = history.location;
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: page - 1,
    limit: limit,
  });

  const searchRef = useRef<string>();

  useEffect(() => {
    if (!searchRef.current) {
      searchRef.current = search;
    }
  }, [search]);

  useEffect(() => {
    if (!searchRef.current) return;
    setPaginationState({
      page: getCurrentPage() - 1,
      limit: getCurrentPageLimit(),
    });
  }, [searchRef, search]);

  useEffect(() => {
    if (!useUrlPagination) return;
    let { page, limit } = pickQueryParams<{ page: number; limit: number }>(
      history.location.search,
      ['page', 'limit']
    );

    if (!page || !limit) {
      history.push({
        search: qs.stringify({
          page: paginationState.page + 1,
          limit: paginationState.limit,
        }),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, useUrlPagination]);

  const setActivePage = useCallback(
    (page: number) => {
      const searchQuery = history.location.search;
      if (useUrlPagination) {
        history.push({
          search: qs.stringify({
            ...qs.parse(searchQuery),
            page: page + 1,
          }),
        });
      } else {
        setPaginationState((prev) => ({ ...prev, page }));
      }
    },
    [history, useUrlPagination]
  );
  const setItemsPerPage = useCallback(
    (limit: number) => {
      if (useUrlPagination) {
        history.push({ search: qs.stringify({ page: 1, limit }) });
      } else {
        setPaginationState((prev) => ({ ...prev, limit }));
      }
    },
    [history, useUrlPagination]
  );

  const handlePageChange: PageChangeHandler = useCallback(
    (_, nextPage) => {
      !disabled && setActivePage(nextPage);
    },
    [disabled, setActivePage]
  );
  const handleItemsPerPageChange: ItemsLimitChangeHandler = useCallback(
    (event) => {
      if (disabled) return;

      const { value: limit } = event.target;

      setItemsPerPage(+limit);
    },
    [disabled, setItemsPerPage]
  );

  const resetPaginationHook = useCallback(() => {
    const pageToReset = useUrlPagination ? page : page - 1;
    const limitToReset = limit;

    if (useUrlPagination) {
      history.push({
        search: qs.stringify({ page: pageToReset, limit: limitToReset }),
      });
    } else {
      setPaginationState({ page: pageToReset, limit: limitToReset });
    }
  }, [page, limit, history, useUrlPagination]);

  return [
    paginationState.page,
    handlePageChange,
    paginationState.limit,
    handleItemsPerPageChange,
    resetPaginationHook,
  ];
}
