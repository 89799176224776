import { RightsApi } from '@app/services/rights-service/rights-service';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import { deleteTeamRightsAction, setTeamRightsLoadingAction } from '../actions';
import { fetchTeamRightsSagaWorker } from './fetchTeamRightsSagaWorker';

export function* deleteTeamRightsSaga(
  action: ReturnType<typeof deleteTeamRightsAction>
) {
  const { profileIds } = action;

  try {
    yield put(setTeamRightsLoadingAction({ loading: true }));
    yield call(RightsApi.deleteTeamRights, { profileIds });

    yield call(fetchTeamRightsSagaWorker);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Team rights successfully deleted.',
          options: {
            variant: 'success',
          },
          key: 'team-rights-delete',
        },
      })
    );
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'deleted team rights',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to delete product rights.',
          options: {
            variant: 'error',
          },
          key: 'product-rights-delete-failed',
        },
      })
    );
  } finally {
    yield put(setTeamRightsLoadingAction({ loading: false }));
  }
}
