import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';
import {
  BaseMarginDataTable,
  Exception,
  ExceptionHighlight,
  IndustrieDataTable,
} from '@app/shared/types/baseMargin';

const parseDataToEmptyField = (
  tableData: BaseMarginDataTable[],
  filters: Industrie[],
  exceptionHighlights: ExceptionHighlight[]
): BaseMarginDataTable[] => {

  const newData = tableData.map((item) => {
    const newItem = filters.map((f) => {
      const find = item.industries.find((find) => find.id === f.id);

      const excs = exceptionHighlights.filter(x => x.groupId === item.id
        && x.industryId === f.id
        && x.valueAllocation === item.valueAllocation).map(x => {
          return {
            id: x.id,
            name: x.name
          } as Exception;
        })

      if (find) {
        return { ...find, rowId: item.id, exceptions: excs };
      }

      return {
        id: f.id,
        customMargin: '',
        customMarginType: 0,
        exceptions: excs,
        rowId: item.id,
      };
    });
    return {
      ...item,
      industries: newItem,
    };
  });

  return newData;
};

export function mapIndustryFiltersWithTableData(
  tableData: BaseMarginDataTable[],
  filters: Industrie[],
  exceptionHighlights: ExceptionHighlight[]
): BaseMarginDataTable[] {
  const parseData = parseDataToEmptyField(tableData, filters, exceptionHighlights);
  const nextTableData = parseData.map((tableDataItem) => {
    const nextItem = { ...tableDataItem };
    nextItem.industries = filters.map((industry) => {
      const filter =
        tableDataItem.industries.find((margin) => margin.id === industry.id) ||
        ({} as IndustrieDataTable);
      return filter;
    });
    return nextItem;
  });
  return nextTableData;
}
