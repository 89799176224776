import { TTeamRights } from '@app/services/rights-service/response-interfaces/get-team-rights.response';

export interface TeamRightsState {
  loading: boolean;
  isSubmitting: boolean;
  isSubmitted: boolean;
  total: number;
  items: TTeamRights[];
  isLoaded: boolean;
}

export const initialState: TeamRightsState = {
  loading: true,
  isSubmitting: false,
  isSubmitted: false,
  total: 0,
  items: [],
  isLoaded: false,
};
