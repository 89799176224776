import { OptionsObject } from 'notistack';

export interface TNotification {
  options: OptionsObject;
  dismissed?: boolean;
  message: string;
  key: string;
}

export interface SnacksState {
  notifications: TNotification[];
}

export const initialState: SnacksState = {
  notifications: [],
};
