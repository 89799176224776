import { WizardStep, WizardStepsStatus } from '../types';
import { steps } from '@modules/Simulator/steps-definition';
import { updateStepByIndex } from '@modules/Simulator/components/SimulatorWizard/utils/stepsArray';

//===== TYPES =====
const changeActiveStepStatusType = 'CHANGE_ACTIVE_STEP_STATUS';
const toNextStepType = 'TO_NEXT_STEP';
const toPrevStepType = 'TO_PREV_STEP';
const changeActiveStepByIndexType = 'CHANGE_ACTIVE_STEP_BY_INDEX';
const changeModalQueryType = 'CHANGE_MODAL_QUERY';
const resetStepsType = 'RESET_STEPS';
const resetAllStepsType = 'RESET_ALL_STEPS';

//===== STATE =====
export type ModalStatus = null | number;
export interface WizardState {
  steps: WizardStep[];
  activeStepIndex: number;
  modalQuery: ModalStatus;
}

export const initialWizardState: WizardState = {
  steps: steps,
  activeStepIndex: 0,
  modalQuery: null, // null is off modal or index of changed step
};

//===== SELECTOR =====
export const useWizardSelector = (state: WizardState) => ({
  getSteps: (): WizardStep[] => state.steps,
  getActiveIndex: (): number => state.activeStepIndex,
  getActiveStep: (): WizardStep => state.steps[state.activeStepIndex],
  isFirstActiveStep: (): boolean => state.activeStepIndex === 0,
  isLastActiveStep: (): boolean =>
    state.activeStepIndex === state.steps.length - 1,
  getModalQuery: (): ModalStatus => state.modalQuery,
});

export type WizardSelector = ReturnType<typeof useWizardSelector>;

//===== REDUCER =====
export const wizardReducer = (
  state: WizardState,
  action: ActionType
): WizardState => {
  switch (action.type) {
    case changeActiveStepStatusType:
      return {
        ...state,
        steps: updateStepByIndex(
          state.steps,
          state.activeStepIndex,
          action.payload
        ),
      };
    case toNextStepType:
      return {
        ...state,
        activeStepIndex:
          state.activeStepIndex === state.steps.length - 1
            ? state.activeStepIndex
            : state.activeStepIndex + 1,
      };
    case toPrevStepType:
      return {
        ...state,
        activeStepIndex:
          state.activeStepIndex === 0
            ? state.activeStepIndex
            : state.activeStepIndex - 1,
      };
    case changeActiveStepByIndexType:
      return {
        ...state,
        activeStepIndex: action.payload,
      };
    case changeModalQueryType:
      return { ...state, modalQuery: action.payload };
    case resetStepsType:
      return {
        ...state,
        modalQuery: null,
        activeStepIndex: 0,
        steps: state.steps.map((i, index) =>
          index === 0
            ? i
            : {
                ...i,
                showed: false,
                changed: false,
                saved: false,
                originValidate: false,
                currentValidate: false,
              }
        ),
      };
    case resetAllStepsType:
      return {
        ...state,
        modalQuery: null,
        activeStepIndex: 0,
        steps: state.steps.map((i, index) =>
          index === 0
            ? {
                ...i,
                changed: false,
                saved: false,
                originValidate: false,
                currentValidate: false,
              }
            : {
                ...i,
                showed: false,
                changed: false,
                saved: false,
                originValidate: false,
                currentValidate: false,
              }
        ),
      };
    default:
      return state;
  }
};

//===== ACTIONS  =====
export interface ActionType {
  type: string;
  payload?: any;
}

export const changeActiveStepStatusAction = (
  updateStatus: WizardStepsStatus
): ActionType => ({
  type: changeActiveStepStatusType,
  payload: updateStatus,
});

export const toNextStepAction = (): ActionType => ({ type: toNextStepType });
export const toPrevStepAction = (): ActionType => ({ type: toPrevStepType });

export const changeActiveStepByIndexAction = (index: number): ActionType => ({
  type: changeActiveStepByIndexType,
  payload: index,
});

export const changeModalQueryAction = (query: ModalStatus): ActionType => ({
  type: changeModalQueryType,
  payload: query,
});

export const resetStepsAction = (): ActionType => ({
  type: resetStepsType,
});

export const resetAllStepsAction = (): ActionType => ({
  type: resetAllStepsType,
});
