import React from 'react';
import {
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import { WizardStep } from '@modules/Simulator/components/SimulatorWizard';
import './SimulatorStepper.css';

interface SimulatorStepperProps {
  steps: WizardStep[];
  activeStep: number;
  setActiveStep: (index: number) => void;
}

export const SimulatorStepper = ({
  steps,
  activeStep,
  setActiveStep,
}: SimulatorStepperProps) => {
  return (
    <Stepper activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step
          key={step.id}
          completed={false}
          active={step.showed}
          classes={{
            root: step.showed && index !== activeStep ? 'pointer' : 'default',
          }}
          style={{
            cursor:
              step.showed && index !== activeStep
                ? 'simulator-stepper-step'
                : 'simulator-stepper-step--active',
          }}
        >
          <Tooltip
            title={
              <Markdown
                options={{
                  overrides: {
                    p: {
                      component: ({ children }) => (
                        <Typography variant="body2" gutterBottom>
                          {children}
                        </Typography>
                      ),
                    },
                  },
                }}
              >
                {step.meta.description}
              </Markdown>
            }
            placement="bottom"
          >
            <StepLabel
              onClick={() => step.showed && setActiveStep(index)}
              classes={{
                iconContainer: `simulator-stepper-label ${
                  index === activeStep ? 'simulator-stepper-label--active' : ''
                }`,
                root: `simulator-stepper-step ${
                  step.showed && index !== activeStep
                    ? 'simulator-stepper-step--active'
                    : ''
                }`,
              }}
            >
              {step.meta.title}
            </StepLabel>
          </Tooltip>
        </Step>
      ))}
    </Stepper>
  );
};
