import { combineReducers } from 'redux';
import { negotiantionExceptionsReducer } from './exceptions';
import { productLineReducer } from './product-line';
import { quotesReducer } from './quotes';

export const negotiationRoomReducer = combineReducers({
  productLine: productLineReducer,
  quotes: quotesReducer,
  negotiationExceptions: negotiantionExceptionsReducer,
});
