import { Action } from 'redux';
import {
  setConfigurationsOpenedAction,
  setConfigurationsCreatingAction,
} from './actions';
import { initialState, VolumeDriverConfigurationsState } from './store';

export function volumeDriverConfigurationsReducer(
  state = initialState,
  action: Action
): VolumeDriverConfigurationsState {
  if (setConfigurationsOpenedAction.is(action)) {
    return { ...state, isModalOpened: action.opened };
  }

  if (setConfigurationsCreatingAction.is(action)) {
    return {
      ...state,
      isCreating: action.creating,
    };
  }

  return state;
}
