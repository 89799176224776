import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';

interface Props<T> {
  options: T[];
  value: T[];
  defaultAllValue: T[];
  onChange: (data: T[]) => void;
  getOptionSelected: (option: T) => boolean;
  getOptionLabel: (option: T) => string;
  renderTags: (
    options: T[],
    getProps: AutocompleteGetTagProps
  ) => React.ReactNode;
}

// TO DO implement recieving of ALL option name inside of component

const ALL_OPTION = {
  id: 'all',
  name: 'All',
};

export function AppAutocomplete<T extends { id?: any }>(props: Props<T>) {
  const {
    defaultAllValue,
    options,
    onChange,
    renderTags,
    getOptionSelected,
    getOptionLabel,
    value,
  } = props;

  const [isAllSelected, setAllSelected] = React.useState(!!defaultAllValue);
  const [innerOptions, setInnerOptions] = React.useState<T[]>([
    ALL_OPTION as any,
  ]);

  React.useEffect(() => {
    const matchesDefaultValue = defaultAllValue.every((item: any) =>
      value.find((i: any) => i.id === item.id)
    );

    setAllSelected(matchesDefaultValue);
  }, [value, defaultAllValue]);

  React.useEffect(() => {
    if (defaultAllValue) {
      const customOptions = [ALL_OPTION as any, ...options];
      setInnerOptions(customOptions);
    }
  }, [options, defaultAllValue]);

  React.useEffect(() => {
    const matchesDefaultValue = defaultAllValue.every((item: any) =>
      value.find((i: any) => i.id === item.id)
    );

    setAllSelected(matchesDefaultValue);
  }, [value, defaultAllValue]);

  const customOnChange = React.useCallback(
    (_, data: any) => {
      const isAllSelected = (data[data.length - 1] as any) === ALL_OPTION;
      if (isAllSelected) {
        onChange(defaultAllValue);
        return;
      }
      const filteredPayload = data.filter((d: any) => d.id !== 'all');

      onChange(filteredPayload);
    },
    [onChange, defaultAllValue]
  );

  const renderInnerInput = React.useCallback(
    (props: AutocompleteRenderInputParams) => (
      <TextField
        {...props}
        fullWidth
        variant="outlined"
        style={{ background: '#fff' }}
      ></TextField>
    ),
    []
  );

  const checkIfOptionSelected = React.useCallback(
    (o: any) => {
      if (isAllSelected && value.length) {
        if (o.id === ALL_OPTION.id) return true;
        return false;
      }

      return getOptionSelected(o);
    },
    [getOptionSelected, isAllSelected, value]
  );

  return (
    <Autocomplete
      size={'small'}
      limitTags={2}
      filterSelectedOptions={true}
      options={innerOptions}
      disableClearable={true}
      onChange={customOnChange}
      renderInput={renderInnerInput}
      renderTags={renderTags}
      getOptionSelected={checkIfOptionSelected}
      getOptionLabel={getOptionLabel}
      multiple
      value={isAllSelected ? [ALL_OPTION as any] : value || []}
    />
  );
}
