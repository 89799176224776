import React from 'react';
import { TabRouterLayout } from '@app/shared/components/TabRouterLayout';
import { packagingDriverTabs } from './tab-definitions';
import { useDispatch } from 'react-redux';
import { setMarginListLoadedAction } from '@app/store/driver/driver-margins/actions';

export const PackagingDriver = React.memo(() => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(setMarginListLoadedAction({ loaded: false }));
    };
  }, [dispatch]);

  return (
    <TabRouterLayout
      routes={packagingDriverTabs}
      pageTitle="Packaging Driver"
    />
  );
});
