import { TMarginSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-options.response';
import { TVolumeDriverAllocation } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';
import { TVolumeSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-options.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import { selectMarginOptionsItems } from '../../margin-options';
import { selectVolumeOptionsItems } from '../../volume-options';
import {
  fetchDriverAllocationsDoneAction,
  saveVolumeDriverAllocationsAction,
  setVolumeDriverSavingAction,
  VolumeDriverSaveItem,
} from '../actions';
import {
  selectDriverAllocationItems,
  selectDriverAllocationTotalItems,
} from '../selectors';

export function* saveVolumeDriverAllocationsSaga(
  action: ReturnType<typeof saveVolumeDriverAllocationsAction>
) {
  const { items, coId } = action;

  try {
    const volumeDriverAllocations: TVolumeDriverAllocation[] = yield select(
      selectDriverAllocationItems
    );
    const totalItems: number = yield select(selectDriverAllocationTotalItems);

    const marginSystems: TMarginSystemOption[] = yield select(
      selectMarginOptionsItems
    );
    const volumeSystems: TVolumeSystemOption[] = yield select(
      selectVolumeOptionsItems
    );

    yield put(setBackdropLoadingAction({ payload: true }));
    yield put(setVolumeDriverSavingAction({ isSaving: true }));

    yield call(VolumeDriverApi.updateVolumeDriverAllocation, { items, coId });

    // if (action.shouldFetch) {
    //   yield put(fetchDriverAllocationsAction({ coId }));
    // }

    const updatedItemsMap = action.items.reduce((accum, item) => {
      accum[item.productGroupId] = item;

      return accum;
    }, {} as Record<string, VolumeDriverSaveItem>);

    const mapChangedDataToExistingItems = () => {
      return volumeDriverAllocations.map((volumeAllocation) => {
        const { id } = volumeAllocation;

        const updatedItem = updatedItemsMap[id];

        if (updatedItem) {
          const result = {
            ...volumeAllocation,
            marginSystem: updatedItem.marginSystemId
              ? marginSystems.find(
                  (system) => system.id === updatedItem.marginSystemId
                )
              : null,
            volumeSystem: updatedItem.volumeSystemId
              ? volumeSystems.find(
                  (system) => system.id === updatedItem.volumeSystemId
                )
              : null,
          } as TVolumeDriverAllocation;

          return result;
        }

        return volumeAllocation;
      });
    };

    yield put(
      fetchDriverAllocationsDoneAction({
        items: mapChangedDataToExistingItems(),
        totalItems,
      })
    );

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Volume driver allocations has been saved.',
          options: {
            variant: 'success',
          },
          key: 'volume-driver-save-success',
        },
      })
    );
  } catch (e) {
    console.log('Failed to save Volume driver allocations', e.message);

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to save Volume driver allocations. Try again later.',
          options: {
            variant: 'error',
          },
          key: 'volume-driver-save-failed',
        },
      })
    );
  } finally {
    yield put(setVolumeDriverSavingAction({ isSaving: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
