import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import {
  createTeamRightsAction,
  deleteTeamRightsAction,
  fetchTeamRightsAction,
  updateTeamRightsAction,
} from '../actions';
import { createTeamRightsSaga } from './createTeamRightsSaga';
import { deleteTeamRightsSaga } from './deleteTeamRightsSaga';
import { fetchTeamRightsSagaWorker } from './fetchTeamRightsSagaWorker';
import { teamRightsWorkerSaga } from './teamRightsWorkerSaga';
import { updateTeamRightsSaga } from './updateTeamRightsSaga';

export function* teamRightsWatcherSaga() {
  yield all([
    takeEvery(fetchTeamRightsAction.TYPE, fetchTeamRightsSagaWorker),
    takeEvery(createTeamRightsAction.TYPE, createTeamRightsSaga),
    takeEvery(deleteTeamRightsAction.TYPE, deleteTeamRightsSaga),
    takeEvery(updateTeamRightsAction.TYPE, updateTeamRightsSaga),
    takeEvery(LOCATION_CHANGE, teamRightsWorkerSaga),
  ]);
}
