import { UpdateProductGroupRightsItem } from '@app/services/rights-service/dto/update-product-group-rights.dto';
import { TProductRight } from '@app/services/rights-service/response-interfaces/get-rights.response';
import { defineActionGroup } from 'rd-redux-utils';

const productRightsActionGroup = defineActionGroup('PRODUCT RIGHTS');

export const setProductRightsLoadingAction = productRightsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const fetchProductRightsAction = productRightsActionGroup.defineAction(
  'FETCH PRODUCT RIGHTS'
);

export const setProductRightsDataAction = productRightsActionGroup.defineAction<{
  total: number;
  items: TProductRight[];
}>('SET DATA');

export const updateGroupRightsAction = productRightsActionGroup.defineAction<{
  items: Record<string, UpdateProductGroupRightsItem>;
}>('UPDATE RIGHTS');

export const forceSaveGroupRightsAction = productRightsActionGroup.defineAction<{
  items: Record<string, UpdateProductGroupRightsItem>;
}>('FORCE SAVE RIGHTS');

export const saveGroupRightsAction = productRightsActionGroup.defineAction(
  'SAVE RIGHTS'
);

export const createProductRightAction = productRightsActionGroup.defineAction<{
  item: { userId: string; isPrimaryProductOwner: boolean; groupIds: string[] };
}>('CREATE PRODUCT RIGHT');

export const deleteProductRightsAction = productRightsActionGroup.defineAction<{
  items: {
    userId: string;
    productGroupId: string;
  }[];
}>('DELETE PRODUCT RIGHTS');

export const toggleModalAction = productRightsActionGroup.defineAction<{
  isOpen: boolean;
}>('TOGGLE MODAL');
