import { combineReducers } from 'redux';
import { marginOptionsReducer } from './margin-options';
import { marginSystemConfigurationsReducer } from './margin-system-configurations';
import { volumeDriverAllocationsReducer } from './volume-driver-allocations';
import { volumeDriverConfigurationsReducer } from './volume-driver-configurations';
import { volumeOptionsReducer } from './volume-options';
import { volumeSystemConfigurationsReducer } from './volume-system-configurations';

export const volumeDriverReducer = combineReducers({
  allocations: volumeDriverAllocationsReducer,
  configurations: volumeDriverConfigurationsReducer,
  volumeConfigurations: volumeSystemConfigurationsReducer,
  marginConfigurations: marginSystemConfigurationsReducer,
  options: combineReducers({
    volume: volumeOptionsReducer,
    margin: marginOptionsReducer,
  }),
});
