import { ProductGroupsLevel } from '@app/modules/BaseMarginMatrix/components/ExceptionsConfigurations/ConfigureDialog/components/ProductGroupsLevel';
import { validate } from '@app/shared/utils/validate';
import {
  createProductRightAction,
  toggleModalAction,
} from '@app/store/settings/product-rights/actions';
import { selectProductRightsModalIsOpen } from '@app/store/settings/product-rights/selectors';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { schema } from './sheme';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TGetUserCoId } from '@app/services/rights-service/response-interfaces/get-user-coid';
import { enqueSnackAction } from '@app/store/snacks';
import { RightsApi } from '@app/services/rights-service/rights-service';
import { selectSelectedCo } from '@app/store/filters-module/co';

interface Data {
  userName: null | TGetUserCoId;
  groupLevel: number;
  isPrimaryProductOwner: boolean;
}

export const ConfigModal: React.FC = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectProductRightsModalIsOpen);
  const selectedCO = useSelector(selectSelectedCo);
  const [data, setData] = React.useState<Data>({
    userName: null,
    groupLevel: 1,
    isPrimaryProductOwner: false,
  });
  const [productGroupsLevel, setProductGroupsLevel] = React.useState<
    Record<number, undefined | { id: string; name: string }[]>
  >({ 1: [], 2: [], 3: [], 4: [], 5: [] });
  const [userLoading, setUserLoading] = React.useState(true);
  const [users, setUsers] = React.useState<TGetUserCoId[]>([]);
  const [formError, setFormError] = React.useState<Record<string, string>>();
  const onProductGroups = React.useCallback(
    (data: { id: string; name: string } | undefined) => {
      setProductGroupsLevel((state: any) => {
        return {
          ...state,
          ...data,
        };
      });
    },
    []
  );
  const handleUserName = React.useCallback((item: TGetUserCoId | null) => {
    setData((state) => {
      return {
        ...state,
        userName: item,
      };
    });
  }, []);
  const handleGroupLevel = React.useCallback(
    (e: any) => {
      const newGropus = { ...productGroupsLevel };
      for (let prop in newGropus) {
        if (prop > e.target.value) {
          newGropus[prop] = [];
        }
      }

      setProductGroupsLevel(newGropus);
      setData((state) => {
        return {
          ...state,
          groupLevel: e.target.value,
        };
      });
    },
    [productGroupsLevel]
  );
  const checkFormIsValid = React.useCallback(() => {
    const productValueObj = Object.values(productGroupsLevel)
      .slice(0, data.groupLevel)
      .reduce(
        (
          previousValue: Record<number, { id: string; name: string }[]>,
          currentValue: any,
          index: number
        ) => {
          return {
            ...previousValue,
            [index + 1]: currentValue,
          };
        },
        {} as Record<number, { id: string; name: string }[]>
      );
    const valid = validate(
      {
        userName: data.userName?.name,
        productGroupsLevel: productValueObj,
      },
      schema
    );
    setFormError(valid);
    if (Object.keys(valid).length) {
      return false;
    }
    return true;
  }, [data.userName, data.groupLevel, productGroupsLevel]);

  const saveRights = React.useCallback(() => {
    const valid = checkFormIsValid();
    if (!valid) return;
    const groups = productGroupsLevel[data.groupLevel];
    dispatch(
      createProductRightAction({
        item: {
          userId: data.userName?.id as string,
          isPrimaryProductOwner: data.isPrimaryProductOwner,
          groupIds: groups?.map((i) => i.id) as string[],
        },
      })
    );
  }, [data, productGroupsLevel, checkFormIsValid, dispatch]);

  const fetchManagers = React.useCallback(async () => {
    try {
      const response = await RightsApi.getUserWithSpecificProductsRigthsByCOId(
        selectedCO?.id as string
      );
      setUsers(response);
      setUserLoading(false);
    } catch (e) {
      if (
        e.response &&
        (e.response.status === 409 || e.response.status === 400)
      ) {
        dispatch(
          enqueSnackAction({
            notification: {
              message: e.response.data.message,
              options: {
                variant: 'error',
              },
              key: 'sproduct-rights-save',
            },
          })
        );
      }
      dispatch(
        enqueSnackAction({
          notification: {
            message: 'Something wrong...',
            options: {
              variant: 'error',
            },
            key: 'product-rights-save',
          },
        })
      );
    }
  }, [dispatch, selectedCO]);
  return (
    <Dialog
      open={open}
      onClose={() => dispatch(toggleModalAction({ isOpen: false }))}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {'Configuring rights for product groups'}
      </DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid container alignItems="center" direction="row" spacing={4}>
              <Grid xs={4} item>
                User
              </Grid>
              <Grid xs={8} item>
                <Autocomplete
                  options={users}
                  onChange={(_e, data) => handleUserName(data)}
                  onOpen={fetchManagers}
                  value={data.userName}
                  loading={userLoading}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      error={formError?.userName ? true : false}
                      helperText={
                        formError?.userName ? 'field is required' : ''
                      }
                      {...params}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="row" spacing={4}>
              <Grid xs={4} item>
                Grant rights on product level
              </Grid>
              <Grid xs={8} item>
                <Select
                  fullWidth
                  value={data.groupLevel}
                  onChange={handleGroupLevel}
                  variant="outlined"
                >
                  <MenuItem value={1}>On product group level 1</MenuItem>
                  <MenuItem value={2}>On product group level 2</MenuItem>
                  <MenuItem value={3}>On product group level 3</MenuItem>
                  <MenuItem value={4}>On product group level 4</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <ProductGroupsLevel
              productGroupsLevel={productGroupsLevel}
              onProductGroups={onProductGroups}
              exceptionProductLevel={data.groupLevel}
              formError={formError}
              isFullChildren={false}
              isFullList={true}
            />
            <Grid container alignItems="center" direction="row" spacing={4}>
              <Grid xs={4} item>
                Primary product owner
              </Grid>
              <Grid xs={8} item>
                <Switch
                  onChange={(e: any) => {
                    setData((state) => {
                      return {
                        ...state,
                        isPrimaryProductOwner: e.target.checked,
                      };
                    });
                  }}
                  checked={data.isPrimaryProductOwner}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(toggleModalAction({ isOpen: false }))}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={saveRights}
          color="primary"
          autoFocus
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
