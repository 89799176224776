import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, select, put } from '@redux-saga/core/effects';
import {
  bulkProductLineAction,
  fetchProductLineDataAction,
  resetUpdateProductLineAction,
  setProductLineLoadingAction,
} from '../action';

export function* bulkSaga(action: ReturnType<typeof bulkProductLineAction>) {
  try {
    yield put(setProductLineLoadingAction({ loading: true }));
    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);
    yield call(NegotiationRoomApi.bulkProductLineItems, {
      limit: action.payload.limit,
      limitLevel: action.payload.limitLevels,
      coId: selectedCO.id,
    });
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Bulk Update successfully',
          options: {
            variant: 'success',
          },
          key: 'update product line',
        },
      })
    );
    yield put(resetUpdateProductLineAction({}));
    yield put(fetchProductLineDataAction({}));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'Bulk update product line',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'Bulk update product line',
        },
      })
    );
  } finally {
    yield put(setProductLineLoadingAction({ loading: false }));
  }
}
