import {
  createUserTemporaryRightsItemAction,
  fetchUserRightsDataAction,
} from '@app/store/settings/user-rights';
import { call, put } from 'redux-saga/effects';
import { RightsApi } from '@services/rights-service/rights-service';
import { enqueSnackAction } from '@app/store/snacks';

export function* createUserTemporaryRightsSaga(
  action: ReturnType<typeof createUserTemporaryRightsItemAction>
) {
  try {
    yield call(RightsApi.createUserTemporaryRights, action.payload);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'User temporary rights successfully create.',
          options: {
            variant: 'success',
          },
          key: 'user-rights-updated',
        },
      })
    );
    yield put(fetchUserRightsDataAction({}));
  } catch (e) {
    console.log('Failed to create temporary user rights', e.message);
    let message = 'Failed to create user temporary rights.'
    if (e.response) {
       message =
        e.response.status === 409 || e.response.status === 400
          ? e.response.data.message
          : message;
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: message,
          options: {
            variant: 'error',
          },
          key: 'user-rights-update',
        },
      })
    );
  }
}
