import { bmmIndustryLevelByKey, bmmIndustryLevelByValue } from '@app/modules/Simulator/data-definition';
import {
  WizardBaseMarginEntryOrigin,
  WizardBaseMarginMatrixOrigin,
  WizardBaseMarginMatrixSending,
  WizardBaseMarginMatrixState,
  WizardPackagingMarginEntryValueState,
  WizardPackagingMarginOrigin,
  WizardPackagingMarginSending,
  WizardPackagingMarginState,
} from '@app/shared/types/wizard';
import { WizardPackagingValuesOrigin } from '@app/shared/types/wizard';

//===== BASE MARGIN MATRIX =====

export const bmmConverterOriginToState = (
  origin: WizardBaseMarginMatrixOrigin[]
): WizardBaseMarginMatrixState[] =>
  origin.map((i) => ({
    ...i,
    marginTypes: i.marginTypes.length === 1 ? i.marginTypes[0] : null,
    marginValues:
      i.marginTypes.length > 1
        ? 'Multiple margin types'
        : i.marginValues.length === 1
        ? i.marginValues[0]
        : i.marginValues.length !== 0
        ? String(Math.min(...i.marginValues.map((i) => Number(i))).toFixed(2)) +
          ' $UNIT$ - ' +
          String(Math.max(...i.marginValues.map((i) => Number(i))).toFixed(2)) +
          ' $UNIT$'
        : '—',
    industryMargins: i.industryMargins.map((i) => ({
      ...i,
      marginValue:
        i.marginValues.length === 1
          ? i.marginValues[0]
          : i.marginValues.length && i.marginValues.some(x => !x)
          ? 'NA - ' +  String(
            Math.max(...i.marginValues.map((i) => Number(i))).toFixed(2)
          ) +
          ' $UNIT$'
          : i.marginValues.length !== 0
          ? String(
              Math.min(...i.marginValues.map((i) => Number(i))).toFixed(2)
            ) +
            ' $UNIT$ - ' +
            String(
              Math.max(...i.marginValues.map((i) => Number(i))).toFixed(2)
            ) +
            ' $UNIT$'
          : '—',
        industryLevel:  bmmIndustryLevelByKey[+i.industryLevel]
    })),
  }));

export const bmmConverterStateToOrigin = (
  state: WizardBaseMarginMatrixState[]
): WizardBaseMarginMatrixOrigin[] =>
  state.map(
    (i): WizardBaseMarginMatrixOrigin => ({
      ...i,
      marginValues: [i.marginValues],
      marginTypes: i.marginTypes === null ? [] : [i.marginTypes],
      industryMargins: i.industryMargins.map(
        (i): WizardBaseMarginEntryOrigin => ({
          name: i.name,
          industryName: i.industryName,
          industryLevel: bmmIndustryLevelByValue[i.industryLevel],
          industryId: i.industryId,
          marginValues: [i.marginValue],
          id: i.id,
        })
      ),
    })
  );
//TODO change null to none
export const bmmConverterStateToSending = (
  state: WizardBaseMarginMatrixState[]
): WizardBaseMarginMatrixSending[] =>
  state.map(
    (i): WizardBaseMarginMatrixSending => ({
      valueAllocation: i.valueAllocation,
      marginValue: i.marginValues,
      marginTypes: i.marginTypes || 0,
      industryMargins: i.industryMargins.map((i) => ({
        id: i.id.match(/new--\S*/) === null ? i.id : null,
        name: i.name,
        industryId: i.industryId,
        marginValue: i.marginValue
      })),
    })
  );

//===== PACKAGING MARGIN  =====

export const pmOriginToState = (
  origin: WizardPackagingMarginOrigin[],
  defaultPackaging: Record<number, string>
): WizardPackagingMarginState => {
  const valueAllocationIndexed = Object.keys(
    origin.reduce(
      (res, i) =>
        i.productValues.reduce(
          (res, i) => ({ ...res, [i.valueAllocation]: true }),
          res
        ),
      {}
    )
  ).map((i) => Number(i));

  return origin.length
    ? {
        valueAllocation: valueAllocationIndexed,
        packaging: origin.map((wpm) => ({
          id: wpm.packagingGroupId,
          name: wpm.packagingGroupName,
          marginType: wpm.productValues[0].marginType,
          values: valueAllocationIndexed.map((valueAllocation) =>
            wpm.productValues.reduce(
              (res, valueOrigin) =>
                valueOrigin.valueAllocation === valueAllocation
                  ? {
                      value:
                        valueOrigin.margin.length === 1
                          ? valueOrigin.margin[0].replace(',', '.')
                          : valueOrigin.margin.length !== 0
                          ? String(
                              Math.min(
                                ...valueOrigin.margin.map((i) =>
                                  Number(i.replace(',', '.'))
                                )
                              ).toFixed(2)
                            ) +
                            ' % - ' +
                            String(
                              Math.max(
                                ...valueOrigin.margin.map((i) =>
                                  Number(i.replace(',', '.'))
                                )
                              ).toFixed(2)
                            ) +
                            ' %'
                          : '—',
                      default:
                        defaultPackaging[valueOrigin.valueAllocation] ===
                        wpm.packagingGroupId,
                    }
                  : res,
              { value: '—' } as WizardPackagingMarginEntryValueState
            )
          ),
        })),
      }
    : { valueAllocation: [], packaging: [] };
};

export const pmStateToOrigin = (
  state: WizardPackagingMarginState
): WizardPackagingMarginOrigin[] =>
  state.packaging
    .map((packaging, index) => ({
      packagingGroupId: packaging.id,
      packagingGroupName: packaging.name,
      productValues: packaging.values.reduce(
        (res, values, index2) =>
          values.value === '—'
            ? res
            : [
                ...res,
                {
                  valueAllocation: state.valueAllocation[index2],
                  margin: [
                    isNaN(Number(values.value))
                      ? values.value
                      : String(Number(values.value).toFixed(2)),
                  ],
                  marginType: state.packaging[index]?.marginType,
                },
              ],
        [] as WizardPackagingValuesOrigin[]
      ),
    }))
    .sort((a, b) => (a.packagingGroupName > b.packagingGroupName ? 1 : -1));

export const pmStateToSending = (
  state: WizardPackagingMarginState,
  origin: WizardPackagingMarginOrigin[]
): WizardPackagingMarginSending => ({
  items: state.packaging.reduce(
    (res, packaging) =>
      packaging.values.reduce(
        (res, values, index) =>
          values.value === '—'
            ? res
            : [
                ...res,
                {
                  valueAllocation: state.valueAllocation[index],
                  packagingGroupId: packaging.id,
                  margin: values.value,
                  marginType: packaging.marginType,
                },
              ],
        res
      ),
    [] as {
      valueAllocation: number;
      packagingGroupId: string;
      margin: string;
      marginType: number;
    }[]
  ),
  deletedIds: origin.reduce(
    (res, packagingOrigin) =>
      state.packaging.some(
        (packaging) => packaging.id === packagingOrigin.packagingGroupId
      )
        ? res
        : [...res, packagingOrigin.packagingGroupId],
    [] as string[]
  ),
});
