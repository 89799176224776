import { UpdateMarginSystemDto } from '@app/services/volume-driver-service/dto/update-margin-system.dto';
import { TMarginSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-configurations.response';
import { TVolumeSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  setMarginSystemConfigurationsCreatingAction,
  setMarginSystemConfigurationsDataAction,
  resetMarginSystemConfigurationsUpdatedItemsAction,
} from '../actions';
import {
  selectMarginSystemConfigurationsItems,
  selectMarginSystemConfiguration,
  selectMarginSystemConfigurationsTotalItems,
} from '../selectors';

export function* saveMarginSystemConfigurationsSaga() {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));
    const marginSystemList: TVolumeSystemConfiguration[] = yield select(
      selectMarginSystemConfigurationsItems
    );
    const updatedItemsList: Record<
      string,
      TMarginSystemConfiguration
    > = yield select(selectMarginSystemConfiguration);

    const updatedItems: TMarginSystemConfiguration[] = marginSystemList
      .filter((item) => !!updatedItemsList[item.id])
      .map((item) => updatedItemsList[item.id]);

    if (!updatedItems.length) return;

    yield put(setMarginSystemConfigurationsCreatingAction({ creating: true }));

    const requestPayload: UpdateMarginSystemDto = { data: updatedItems };

    requestPayload.data = requestPayload.data.map((item) => {
      return {
        ...item,
        firstLevel: String(item.firstLevel),
        secondLevel: String(item.secondLevel),
        thirdLevel: String(item.thirdLevel),
        fourthLevel: String(item.fourthLevel),
        fifthLevel: String(item.fifthLevel),
      };
    });

    const totalItems: number = yield select(
      selectMarginSystemConfigurationsTotalItems
    );

    yield put(
      setMarginSystemConfigurationsDataAction({
        items: marginSystemList.map((item) =>
          updatedItemsList[item.id] ? updatedItemsList[item.id] : item
        ),
        total: totalItems,
      })
    );

    yield call(
      VolumeDriverApi.updateMarginSystemConfigurations,
      requestPayload
    );

    yield put(setMarginSystemConfigurationsCreatingAction({ creating: false }));

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Margin system configurations has been saved.',
          options: {
            variant: 'success',
          },
          key: 'margin-system-configurations-save-success',
        },
      })
    );

    yield put(resetMarginSystemConfigurationsUpdatedItemsAction({}));
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to save margin system configurations.',
          options: {
            variant: 'error',
          },
          key: 'margin-system-configurations-save-failed',
        },
      })
    );
    yield put(setMarginSystemConfigurationsCreatingAction({ creating: false }));
  } finally {
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
