import { Action } from 'redux';
import {
  resetSelectedFilters,
  setAllocationLevels,
  setFiltersAction,
  setFiltersIsFullListAction,
  setFiltersLoadingAction,
  setSelectedAllocationLevels,
  setSelectedFiltersAction,
  setSelectedProductGroupFiltersAction,
} from './actions';
import { FiltersState, initialState } from './state';

export function filtersReducer(
  state: FiltersState = initialState,
  action: Action
): FiltersState {
  if (setSelectedFiltersAction.is(action)) {
    return {
      ...state,
      selectedFilters: action.filters,
    };
  }

  if (setSelectedAllocationLevels.is(action)) {
    return {
      ...state,
      selectedAllocationLevels: action.allocationLevels,
    };
  }

  if (setFiltersLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
      isLoaded: action.loaded ?? false,
    };
  }

  if (setFiltersIsFullListAction.is(action)) {
    return {
      ...state,
      isFullList: action.isFullList,
    };
  }

  if (setFiltersAction.is(action)) {
    return {
      ...state,
      items: action.filters,
    };
  }

  if (setAllocationLevels.is(action)) {
    return {
      ...state,
      allocationLevels: action.levels,
    };
  }

  if (setSelectedProductGroupFiltersAction.is(action)) {
    return {
      ...state,
      selectedProductGroupFilters: action.filters,
    };
  }

  if (resetSelectedFilters.is(action)) {
    return {
      ...state,
      selectedProductGroupFilters: [...state.items],
    };
  }

  return state;
}
