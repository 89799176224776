import * as React from 'react';
import styles from './TabRouterLayout.module.scss';
import { Layout } from '../Layout';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import TabsComponent from '@material-ui/core/Tabs';
import TabComponent from '@material-ui/core/Tab';
import { Switch, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { RoleBasedRoute } from '../RoleBasedRoute';
import { TAccountRights } from '@app/services/account-rights-service/response-interfaces/account-rights';

export interface TabRouterElement {
  link: string;
  title: string;
  component: React.ComponentType<any>;
  feature?: keyof TAccountRights | undefined;
}

interface Props {
  pageTitle: string;
  routes: TabRouterElement[];
}

export const TabRouterLayout = (props: Props) => {
  const { pageTitle, routes } = props;
  const history = useHistory();
  React.useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize')); // hack to fix Material Tabs indicator position
  }, []);

  const handleChange = React.useCallback(
    (_, link: string) => {
      history.push(link);
    },
    [history]
  );

  const Tabs = React.useMemo(() => {
    return routes.map((route) => (
      <TabComponent
        key={route.link}
        component={Link}
        to={route.link}
        label={route.title}
        value={route.link}
        data-value={route.link}
      />
    ));
  }, [routes]);
  return (
    <Layout pageName={pageTitle}>
      <Layout.Grid className={styles.tabRouterLayout}>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          <Grid item>
            <AppBar position="static" color="default">
              <TabsComponent
                indicatorColor="primary"
                textColor="primary"
                value={history.location.pathname}
                onChange={handleChange}
              >
                {Tabs}
              </TabsComponent>
            </AppBar>
          </Grid>
          <Grid item xs={12} container>
            <Switch>
              {routes.map((route) => (
                <RoleBasedRoute
                  component={route.component}
                  path={route.link}
                  exact
                  key={route.link}
                  feature={route.feature}
                />
              ))}
            </Switch>
          </Grid>
        </Grid>
      </Layout.Grid>
    </Layout>
  );
};
