import { PackagingDriverApi } from '@app/services/packaging-driver-service/packaging-driver.service';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import {
  fetchDriverMarginListAction,
  saveMarginValuesAction,
  setMarginSavingAction,
} from '../actions';

export function* saveMarginValuesSaga(
  action: ReturnType<typeof saveMarginValuesAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: false }));
    yield put(setMarginSavingAction({ isSaving: true }));

    yield call(PackagingDriverApi.saveMargin, {
      coId: action.coId,
      items: action.items,
    });

    yield put(fetchDriverMarginListAction({}));

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Product Margins saved.',
          options: {
            variant: 'success',
          },
          key: 'product-margins-save-saved',
        },
      })
    );
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to save margins.',
          options: {
            variant: 'error',
          },
          key: 'product-margins-save-error',
        },
      })
    );
  } finally {
    yield put(setMarginSavingAction({ isSaving: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
