import { put, call } from 'redux-saga/effects';
import {
  fetchGroupFilterBaseMatrixAction,
  setGroupFilterBaseMatrixAction,
} from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { enqueSnackAction } from '@app/store/snacks';

export function* fetchFilterGroupBaseWorkerSaga(
  action: ReturnType<typeof fetchGroupFilterBaseMatrixAction>
) {
  try {
    const result: { total: number; data: TFilterItemResponse[] } = yield call(
      BaseMarginService.getFiltersGroupBaseMatrix,
      action.payload
    );
    yield put(setGroupFilterBaseMatrixAction({ payload: result }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch filter group error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch filter group error',
        },
      })
    );
    console.log(`Failed to get filter base margin: ${e.toString()}`);
  }
}
