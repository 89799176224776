import * as React from 'react';
import { TabRouterLayout } from '@shared/components/TabRouterLayout';
import { negotiationRoomTabs } from './tab-definitions';

export const NegotiationsRoom = () => {
  return (
    <TabRouterLayout
      pageTitle={'Negotiation room'}
      routes={negotiationRoomTabs}
    />
  );
};
