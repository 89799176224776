import { TMarginSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-configurations.response';
import { defineActionGroup } from 'rd-redux-utils';

const marginSystemConfigurationsActionGroup = defineActionGroup(
  'MARGIN SYSTEM CONFIGURATIONS'
);

export const fetchMarginSystemConfigurationsAction = marginSystemConfigurationsActionGroup.defineAction<{
  skip: number;
  limit: number;
}>('FETCH DATA');

export const setMarginSystemConfigurationsLoadingAction = marginSystemConfigurationsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setMarginSystemConfigurationsDataAction = marginSystemConfigurationsActionGroup.defineAction<{
  items: TMarginSystemConfiguration[];
  total: number;
}>('SET DATA');

export const setMarginSystemConfigurationsModalOpenedAction = marginSystemConfigurationsActionGroup.defineAction<{
  opened: boolean;
}>('SET MODAL OPENED');

export const setMarginSystemConfigurationsCreatingAction = marginSystemConfigurationsActionGroup.defineAction<{
  creating: boolean;
}>('SET CREATING');

export interface CreateMarginSystemConfigurationActionPayload {
  name: string;
  description: string;
  firstLevel: string;
  secondLevel: string;
  thirdLevel: string;
  fourthLevel: string;
  fifthLevel: string;
}

export const createMarginSystemConfigurationAction = marginSystemConfigurationsActionGroup.defineAction<
  CreateMarginSystemConfigurationActionPayload
>('CREATE CONFIGURATION');

export const deleteMarginSystemConfigurationsAction = marginSystemConfigurationsActionGroup.defineAction<{
  ids: string[];
}>('DELETE ITEMS');

export const updateMarginSystemConfigurationAction = marginSystemConfigurationsActionGroup.defineAction<{
  id: string;
  updatedPayload: TMarginSystemConfiguration;
}>('UPDATE ITEM');

export const saveMarginSystemConfigurationsAction = marginSystemConfigurationsActionGroup.defineAction(
  'SAVE CONFIGURATIONS'
);

export const resetMarginSystemConfigurationsUpdatedItemsAction = marginSystemConfigurationsActionGroup.defineAction(
  'RESET UPDATED CONFIGURATIONS'
);
