export const MINIMUM_LENGTH_ERROR_TEMPLATE = `Entered text must be longer than {length}`;

export const MAXIMUM_LENGTH_ERROR_TEMPLATE = `Entered text can't exceed length more than {length}`;

export const MINIMUM_VALUE_ERROR_TEMPLATE = `Minimum allowed value is {value}.`;
export const MAXIMUM_VALUE_ERROR_TEMPLATE = 'Maximum allowed value is {value}.';

export const INVALID_SYSTEM_LEVEL_ERROR_TEMPLATE = `{issuer} value must greater than or equal to {dependency}.`;
export const INVALID_SYSTEM_MARGIN_LEVEL_ERROR_TEMPLATE = `{issuer} value must be less than or equal to {dependency}.`;

export const DUPLICATE_RECORD_ERROR_TEMPLATE =
  'Record with provided {name} is arelady exists,please update your input.';

export const INVALID_DECIMAL_VALUE_ERROR_TEMPLATE = `Invalid decimal value provided, minimum is {min} and maximum {max}.`;
