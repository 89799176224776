import AppBar from '@material-ui/core/AppBar/AppBar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import React, { useState, useCallback } from 'react';
import styles from './Header.module.scss';
import { Navigation } from './Navigation';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import logo from '@assets/images/logo.png';
import { ROOT_URL } from '@app/routes/urls';

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  };
});

interface Props {
  pageName?: string;
}

export const Header: React.FC<Props> = ({ pageName }) => {
  const history = useHistory();
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  const goToHome = () => history.push('/');

  return (
    <React.Fragment>
      <Navigation drawer={drawer} toggleDrawer={toggleDrawer} />
      <AppBar
        position="relative"
        color="default"
        classes={{
          root: classes.appBar,
        }}
        className={classnames(styles.appBarWrapper)}
      >
        <Toolbar className={styles.toolbar}>
          <Grid container wrap="nowrap">
            <Grid item xs={12} container alignItems="center">
              <IconButton
                onClick={toggleDrawer}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon color="action" />
              </IconButton>
              <Typography
                className={styles.pageNameWrapper}
                variant="h6"
                onClick={goToHome}
              >
                Target Pricing Solution -{' '}
                <span className={styles.pageName}>{pageName}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Link to={ROOT_URL.urlTemplate}>
                <img src={logo} alt="" className={styles.logo} />
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
