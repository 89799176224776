import { Action } from 'redux';
import { BaseMarginState, initialState } from './state';
import {
  setBaseMarginAction,
  setBaseMarginLoadingAction,
  setBaseMarginExceptionAction,
  setProductGroup,
  setProductsName,
  setIndusties,
  setExceptionName,
  setUpdateBaseMarginException,
  setCurrentExceptionIds,
  setCOWithCurrency,
  openModalExceptionAction,
  setShowExceptionAction,
  setGroupFilterBaseMatrixAction,
  setIndustriesFilterIds,
  setIndustriesFiltersSelectedAction,
  resetException,
  setExceptionHighlights,
} from './actions';

export const baseMarginReducer = (
  state: BaseMarginState = initialState,
  action: Action
): BaseMarginState => {
  if (setBaseMarginAction.is(action)) {
    return {
      ...state,
      baseMargin: { ...action.payload },
    };
  }
  if (setBaseMarginLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.payload,
    };
  }

  if (setBaseMarginExceptionAction.is(action)) {
    return {
      ...state,
      exeptions: action.payload,
    };
  }
  if (setProductGroup.is(action)) {
    return {
      ...state,
      productGroups: action.payload,
    };
  }
  if (setProductsName.is(action)) {
    return {
      ...state,
      productsName: action.payload,
    };
  }
  if (setIndusties.is(action)) {
    return {
      ...state,
      industries: action.payload,
    };
  }
  if (setShowExceptionAction.is(action)) {
    return {
      ...state,
      showException: action.payload,
    };
  }
  if (setExceptionName.is(action)) {
    return {
      ...state,
      exceptionName: action.payload,
    };
  }
  if (setUpdateBaseMarginException.is(action)) {
    return {
      ...state,
      updateExceptionData: action.payload,
    };
  }
  if (setCurrentExceptionIds.is(action)) {
    return {
      ...state,
      currentExceptionIds: action.payload,
    };
  }
  if (setExceptionHighlights.is(action)) {
    return {
      ...state,
      exceptionHighlights: action.payload,
    };
  }
  if (setCOWithCurrency.is(action)) {
    return {
      ...state,
      currencyCO: action.payload,
    };
  }
  if (openModalExceptionAction.is(action)) {
    return {
      ...state,
      openModal: action.payload,
    };
  }
  if (setGroupFilterBaseMatrixAction.is(action)) {
    return {
      ...state,
      groupFilter: action.payload,
    };
  }
  if (setIndustriesFilterIds.is(action)) {
    return {
      ...state,
      filterIdsFetched: true,
      industriesFilterIds: action.payload,
    };
  }
  if (setIndustriesFiltersSelectedAction.is(action)) {
    return {
      ...state,
      currentSelectedFilters: action.payload,
    };
  }
  if (resetException.is(action)) {
    return {
      ...state,
      updateExceptionData: {},
    };
  }

  return state;
};
