import * as React from 'react';
import styles from './ClippedText.module.scss';
import classnames from 'classnames';
import { truncateStringAndAmmendSuffix } from './utils';

interface Props {
  className?: string;
  clipLimit: number;
  onClick?: () => void;
  text: string;
  suffix?: string;
}

export const ClippedText = React.memo((props: Props) => {
  const { className, clipLimit, suffix = '...', text, onClick } = props;

  const clippedText = React.useMemo(() => {
    return text.length > clipLimit
      ? truncateStringAndAmmendSuffix(text, clipLimit, suffix)
      : text;
  }, [clipLimit, suffix, text]);

  return (
    <span
      className={classnames(styles.clippedText, className)}
      title={text}
      onClick={onClick}
    >
      <pre>{clippedText}</pre>
    </span>
  );
});
