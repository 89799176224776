import { AppState } from '@app/store/store';

export const selectMarginOptionsState = (store: AppState) =>
  store.volumeDriver.options.margin;

export const selectMarginOptionsItems = (store: AppState) =>
  selectMarginOptionsState(store).items;

export const selectMarginOptionsLoadingState = (store: AppState) =>
  selectMarginOptionsState(store).isLoading;
