import { defineActionGroup } from 'rd-redux-utils';
import {
  UserRightsTablePayloadCreateType,
  UserRightsTablePayloadDeleteType,
  UserRightsTablePayloadType,
  UserRightsTablePayloadUpdateType,
  UserTemporaryRightsPayloadType,
} from '@modules/Settings/components/UserRights/UserRightsTable';
import {
  COReferenceDataType,
  TeamReferenceDataType,
  UserReferenceDataType,
} from '.';

const userRightsActionGroup = defineActionGroup('SETTING_USER_RIGHTS');

export const fetchUserRightsDataAction = userRightsActionGroup.defineAction<{}>(
  'FETCH_DATA'
);

export const setUserRightsDataAction = userRightsActionGroup.defineAction<{
  payload: {
    items: UserRightsTablePayloadType[];
    total: number;
  };
}>('SET_DATA');

export const deleteUserRightsItemsAction = userRightsActionGroup.defineAction<{
  payload: {
    items: UserRightsTablePayloadDeleteType[];
  };
}>('DELETE_ITEMS');

export const updateUserRightsItemsAction = userRightsActionGroup.defineAction<{
  payload: UserRightsTablePayloadUpdateType[];
}>('UPDATE_ITEMS');

export const createUserRightsItemAction = userRightsActionGroup.defineAction<{
  payload: UserRightsTablePayloadCreateType;
}>('CREATE_ITEM');

export const createUserTemporaryRightsItemAction = userRightsActionGroup.defineAction<{
  payload: UserTemporaryRightsPayloadType;
}>('CREATE_TEMPORARY_RIGHTS_ITEM');

//===== REFERENCE DATA =====

export const fetchUserReferenceDataAction = userRightsActionGroup.defineAction<{}>(
  'FETCH_USER_REFERENCE_DATA'
);

export const setUserReferenceDataAction = userRightsActionGroup.defineAction<{
  payload: UserReferenceDataType[];
}>('SET_USER_REFERENCE_DATA');

export const fetchTeamReferenceDataAction = userRightsActionGroup.defineAction<{}>(
  'FETCH_TEAM_REFERENCE_DATA'
);

export const setTeamReferenceDataAction = userRightsActionGroup.defineAction<{
  payload: TeamReferenceDataType[];
}>('SET_TEAM_REFERENCE_DATA');

export const fetchCOReferenceDataAction = userRightsActionGroup.defineAction<{}>(
  'FETCH_CO_REFERENCE_DATA'
);

export const setCOReferenceDataAction = userRightsActionGroup.defineAction<{
  payload: COReferenceDataType[];
}>('SET_CO_REFERENCE_DATA');


export const fetchUserForTempRightsAction = userRightsActionGroup.defineAction<{}>(
  'FETCH_USER_FOR_TEMP_RIGHTS_DATA'
);

export const setUserForTempRightsAction = userRightsActionGroup.defineAction<{
  payload: UserReferenceDataType[];
}>('SET_USER_FOR_TEMP_RIGHTS_DATA');
