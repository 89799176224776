import { getWizardMarginSystemSelector } from '@app/store/wizard';
import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, Select } from '@material-ui/core';

interface MarginSystemTableSelectProps {
  isError: boolean;
  value: string;
  handlerChange: (value: string) => void;
  canEdit: boolean
}

export const MarginSystemTableSelect = ({
  isError,
  value,
  handlerChange,
  canEdit
}: MarginSystemTableSelectProps) => {
  //===== store data =====
  const marginSystem = useSelector(getWizardMarginSystemSelector);

  return (
    <Select
      error={isError}
      displayEmpty
      value={value}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
        handlerChange(event.target.value as string)
      }
      disabled={!canEdit}
    >
      <MenuItem value="default" disabled>
        Choose margin system
      </MenuItem>
      {value === 'multiple' && (
        <MenuItem value="multiple" disabled>
          Multiple
        </MenuItem>
      )}
      {marginSystem.map((i) => (
        <MenuItem key={i.id} value={i.id}>
          {i.name}
        </MenuItem>
      ))}
    </Select>
  );
};
