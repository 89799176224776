import {
  FEATURE_BASE_MARGIN_MATRIX,
  FEATURE_EXCEPTIONS,
} from '@app/modules/BaseMarginMatrix';
import {
  FEATURE_NEGOTIATION_EXSEPTIONS,
  FEATURE_NEGOTIATION_PRODUCT_LINE,
  FEATURE_NEGOTIATION_QUOTES,
} from '@app/modules/NegotiationsRoom';
import {
  FEATURE_PACKAGING_CONFIGURATION,
  FEATURE_PACKAGING_MARGINS,
} from '@app/modules/PackagingDriver';
import { FEATURE_PRODUCT_VALUE_LABELING } from '@app/modules/ProductValue';
import {
  FEATURE_SETTINGS_PRODUCT_RIGHTS,
  FEATURE_SETTINGS_TEAMS_RIGHTS,
  FEATURE_SETTINGS_USER_RIGHTS,
  FEATURE_SETTINGS_VALUE_PRODUCTS,
} from '@app/modules/Settings/module-meta';
import { FEATURE_WIZARD } from '@app/modules/Simulator';
import { FEATURE_SYSTEM_CONFIGURATION } from '@app/modules/VolumeDriver/SystemConfigurations/module-meta';
import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';
import { TRights } from '@app/services/rights-service/response-interfaces/get-rights.response';
import {
  TTeamRights,
  TTeamRightSection,
} from '@app/services/rights-service/response-interfaces/get-team-rights.response';
import { AppTable } from '@app/shared/components/AppTable';
import { ClippedText } from '@app/shared/components/ClippedText';
import { NO_TABLE_DATA_TEXT } from '@app/shared/texts/texts';
import { TableCell } from '@material-ui/core';
import * as React from 'react';
import { PermissionSelectInput } from '../../PermissionSelectInput';
import {
  mapTableDataToChangePayload,
  normalizeTeamRightsForTable,
  TeamRightsTableItem,
} from './mapUtils';
import styles from '../TeamsRights.module.scss';
import MenuItem from '@material-ui/core/MenuItem';
import { FEATURE_ALLOCATION } from '@app/modules/VolumeDriver/VolumeDriverAllocation/module-meta';
import { FEATURE_CONTACT_AND_SUPPORT } from '@app/modules/ContactAndSupport/module-meta';
import { sortAccessType, sortTeamName } from './sortHelper';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';

const permissionSelectRights: TRights[] = [0, 1, 2];

export type TeamRightsTableData = Record<
  string,
  Record<TAccountRightsFeatureList | string, TTeamRightSection>
>;

export type TeamRightsTableOnChangePayload = {
  profileId: string;
  roleAccessType: number;
  sections: {
    key: TAccountRightsFeatureList;
    value: TRights;
  }[];
}[];
interface Props {
  items: TTeamRights[];
  itemsCount: number;
  loading?: boolean;
  onDelete: (items: TTeamRights[]) => void;
  onChange: (payload: TeamRightsTableOnChangePayload) => void;
  isEmpty: boolean;
}

export const TeamsRightsTable = (props: Props) => {
  const { items, itemsCount, loading, onDelete, onChange, isEmpty } = props;
  const normalizedItems = React.useMemo(() => {
    return normalizeTeamRightsForTable(items);
  }, [items]);

  const handleDelete = React.useCallback(
    (normalizedTableItems: TeamRightsTableItem[]) => {
      const teamRightsItems = normalizedTableItems.map((item) => {
        const originItem = items.find(
          (originItem) => originItem.userProfileId === item.userProfileId
        );
        if (!originItem)
          throw new Error(`Item with id: ${item.userProfileId} not found!`);

        return originItem;
      });

      onDelete(teamRightsItems);
    },
    [items, onDelete]
  );

  const valueMap = React.useMemo(() => {
    const nextValueMap = normalizedItems.reduce((accum, item) => {
      accum[item.userProfileId] = item.sections as any;

      return accum;
    }, {} as TeamRightsTableData);

    return nextValueMap;
  }, [normalizedItems]);

  const isInitialValuesSet = React.useMemo(
    () => !!Object.keys(valueMap).length,
    [valueMap]
  );
  const [changedDataMap, setChangedDataMap] = React.useState<
    Record<
      string,
      Record<TAccountRightsFeatureList | string, TTeamRightSection>
    >
  >({});
  const [updateAccessType, setUpdateAccessType] = React.useState<
    Record<string, number>
  >({});
  React.useEffect(() => {
    const accessTypes: Record<string, number> = {};
    normalizedItems.forEach((i) => {
      accessTypes[i.userProfileId] = i.roleAccessType;
    });
    setUpdateAccessType(accessTypes);
  }, [normalizedItems]);
  const pickValueForInput = React.useCallback(
    (id: string, feature: TAccountRightsFeatureList | string) => {
      const { value } =
        (changedDataMap[id]
          ? changedDataMap[id][feature]
          : valueMap[id][feature]) || {};

      return value;
    },
    [valueMap, changedDataMap]
  );

  const handleChange = React.useCallback(
    (id: string, name: string, value: number) => {
      const nextValue = {
        ...changedDataMap,
        [id]: {
          ...(changedDataMap && changedDataMap[id]
            ? changedDataMap[id]
            : valueMap[id]),
          [name]: {
            value: value,
          },
        },
      };

      setChangedDataMap(nextValue as TeamRightsTableData);
      onChange(
        mapTableDataToChangePayload(
          nextValue as TeamRightsTableData,
          updateAccessType
        )
      );
    },
    [onChange, valueMap, changedDataMap, updateAccessType]
  );
  const handleChangeAccessType = React.useCallback(
    (e: any, id: string) => {
      const updateData = {
        ...updateAccessType,
        [id]: +e.target.value,
      };
      setUpdateAccessType(updateData);
      let nextValue = {
        ...changedDataMap,
      };
      if (!changedDataMap[id]) {
        nextValue = {
          ...nextValue,
          [id]: valueMap[id],
        };
        setChangedDataMap(nextValue as TeamRightsTableData);
      }

      onChange(
        mapTableDataToChangePayload(
          nextValue as TeamRightsTableData,
          updateData
        )
      );
    },
    [changedDataMap, onChange, updateAccessType, valueMap]
  );
  const dataToRender = React.useMemo(() => {
    return normalizedItems.map((i) => {
      if (updateAccessType[i.userProfileId]) {
        return {
          ...i,
          roleAccessType: updateAccessType[i.userProfileId],
        };
      }
      return {
        ...i,
      };
    });
  }, [normalizedItems, updateAccessType]);
  const renderTableBody = React.useCallback(
    (items: TeamRightsTableItem[]) => {
      return items.map((item) => {
        return (
          <AppTable.Row
            isCanBeDeleted={
              item.isSystemRole === null ? true : !item.isSystemRole
            }
            key={item.userProfileId}
            item={item}
          >
            <TableCell
              style={{
                left: '68px',
                zIndex: 10,
                position: 'sticky',
                background: '#fff',
              }}
            >
              <ClippedText text={item.userProfileName} clipLimit={30} />
            </TableCell>
            <TableCell align="center">
              <RoleProtectedSelect
                onChange={(e) => handleChangeAccessType(e, item.userProfileId)}
                style={{ minWidth: '150px' }}
                variant="outlined"
                value={item.roleAccessType}
              >
                <MenuItem disabled value={0}>
                  Choose type
                </MenuItem>
                <MenuItem value={1}>Global</MenuItem>
                <MenuItem value={2}>Local CO</MenuItem>
              </RoleProtectedSelect>
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_PRODUCT_VALUE_LABELING
                )}
                rights={permissionSelectRights}
                name={FEATURE_PRODUCT_VALUE_LABELING}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_BASE_MARGIN_MATRIX
                )}
                rights={permissionSelectRights}
                name={FEATURE_BASE_MARGIN_MATRIX}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_EXCEPTIONS
                )}
                rights={permissionSelectRights}
                name={FEATURE_EXCEPTIONS}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_SYSTEM_CONFIGURATION
                )}
                rights={permissionSelectRights}
                name={FEATURE_SYSTEM_CONFIGURATION}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_ALLOCATION
                )}
                rights={permissionSelectRights}
                name={FEATURE_ALLOCATION}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_PACKAGING_CONFIGURATION
                )}
                rights={permissionSelectRights}
                name={FEATURE_PACKAGING_CONFIGURATION}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_PACKAGING_MARGINS
                )}
                rights={permissionSelectRights}
                name={FEATURE_PACKAGING_MARGINS}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_NEGOTIATION_PRODUCT_LINE
                )}
                rights={permissionSelectRights}
                name={FEATURE_NEGOTIATION_PRODUCT_LINE}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_NEGOTIATION_QUOTES
                )}
                rights={permissionSelectRights}
                name={FEATURE_NEGOTIATION_QUOTES}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_NEGOTIATION_EXSEPTIONS
                )}
                rights={permissionSelectRights}
                name={FEATURE_NEGOTIATION_EXSEPTIONS}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(item.userProfileId, FEATURE_WIZARD)}
                rights={permissionSelectRights}
                name={FEATURE_WIZARD}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_SETTINGS_TEAMS_RIGHTS
                )}
                rights={permissionSelectRights}
                name={FEATURE_SETTINGS_TEAMS_RIGHTS}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_SETTINGS_USER_RIGHTS
                )}
                rights={permissionSelectRights}
                name={FEATURE_SETTINGS_USER_RIGHTS}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_SETTINGS_PRODUCT_RIGHTS
                )}
                rights={permissionSelectRights}
                name={FEATURE_SETTINGS_PRODUCT_RIGHTS}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_SETTINGS_VALUE_PRODUCTS
                )}
                rights={permissionSelectRights}
                name={FEATURE_SETTINGS_VALUE_PRODUCTS}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>

            <TableCell align="center">
              <PermissionSelectInput
                value={pickValueForInput(
                  item.userProfileId,
                  FEATURE_CONTACT_AND_SUPPORT
                )}
                rights={permissionSelectRights}
                name={FEATURE_CONTACT_AND_SUPPORT}
                onChange={(name: string, value: number) =>
                  handleChange(item.userProfileId, name, value)
                }
              />
            </TableCell>
          </AppTable.Row>
        );
      });
    },
    [pickValueForInput, handleChange, handleChangeAccessType]
  );

  return (
    <AppTable
      data={dataToRender}
      dataCount={itemsCount}
      loading={loading}
      deletableItems={true}
      onDelete={handleDelete}
      isEmpty={isEmpty}
      tableLayout={'auto'}
    >
      <AppTable.Head>
        <AppTable.Row isHead={true}>
          <AppTable.HeadCell
            className={styles.borderTopHeader}
            colSpan={2}
            style={{
              left: '0',
              zIndex: 16,
              position: 'sticky',
            }}
          />
          <AppTable.HeadCell colSpan={2} sticky={false} />
          <AppTable.HeadCell
            className={styles.borderTable}
            colSpan={2}
            align="center"
            sticky={false}
          >
            <pre>Base margin matrix</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            className={styles.borderTable}
            colSpan={2}
            align="center"
            sticky={false}
          >
            <pre>Volume driver</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            className={styles.borderTable}
            colSpan={2}
            align="center"
            sticky={false}
          >
            <pre>Packaging driver</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            className={styles.borderTable}
            colSpan={3}
            align="center"
            sticky={false}
          >
            <pre>Negotiation room</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            className={styles.borderTable}
            align="center"
            sticky={false}
          >
            <pre>Wizard</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            className={styles.borderTable}
            colSpan={4}
            align="center"
            sticky={false}
          >
            <pre>Settings</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            className={styles.borderTable}
            colSpan={2}
            align="center"
            sticky={false}
          />
        </AppTable.Row>
        <AppTable.Row isHead={true} disableDeletionBehavior={true}>
          <AppTable.HeadCell
            sortHandler={sortTeamName}
            className={styles.borderTable}
            style={{
              left: '68px',
              zIndex: 16,
              position: 'sticky',
            }}
          >
            <pre>Team Name</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            sortHandler={sortAccessType}
            className={styles.borderTable}
          >
            <pre>Access Type</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Product value labeling</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Base margin matrix</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Exceptions</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Systems configuration</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Volume driver allocation</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Packaging configuration</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Packaging margins</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Product line negotiations</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Quotes negotiations</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Negotiation exceptions</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Wizard</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Team rights</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>User rights</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Product group rights</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Product value rights</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell className={styles.borderTable}>
            <pre>Contact & support</pre>
          </AppTable.HeadCell>
        </AppTable.Row>
      </AppTable.Head>
      {isInitialValuesSet ? (
        <AppTable.Body<TeamRightsTableItem>
          contentColSpan={9}
          noDataText={NO_TABLE_DATA_TEXT}
        >
          {renderTableBody}
        </AppTable.Body>
      ) : null}
    </AppTable>
  );
};
