import React from 'react';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { AppButton } from '@app/shared/components/AppButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import { RoleProtectedInput } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedInput';
import { selectFeatureList } from './select-feature.definition';
import MenuItem from '@material-ui/core/MenuItem';
import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';
import { AppFormDialog } from '@app/shared/components/AppFormDialog';

export interface TeamRightsConfigureDialogFormData {
  profileName: string;
  roleAccessType: number;
  sections: { key: TAccountRightsFeatureList; value: number }[];
}

interface Props {
  isOpened: boolean;
  isLoading: boolean;
  onClose: () => void;
  closeModal: () => void;
  onSubmit: (formData: TeamRightsConfigureDialogFormData) => void;
}

const RIGHTS_SELECT_PLACEHOLDER = 'Select Rights';

export const TeamRightsConfigureDialog: React.FC<Props> = (props) => {
  const { isOpened, onClose, onSubmit, isLoading } = props;
  const [selectsFormData, setSelectsFormData] = React.useState<
    Record<string, string | number>
  >({});

  const handleSelectChange = React.useCallback((e: any) => {
    const { name, value } = e.target;

    setSelectsFormData((prev) => ({ ...prev, [name]: value }));
  }, []);
  const [textInputData, setTextInputData] = React.useState<
    Record<string, string | number>
  >({ profileName: '' });
  const [roleAccessType, setRoleAccessType] = React.useState<number>(1);
  const handleAccessType = React.useCallback((e: any) => {
    setRoleAccessType(+e.target.value);
  }, []);
  const handleInputChange = React.useCallback((e: any) => {
    const { name, value } = e.target;

    setTextInputData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const selects = React.useMemo(() => {
    return selectFeatureList.map((feature) => {
      return (
        <Grid item xs={12} container key={feature.name}>
          <Grid item xs={5} alignItems="center" container>
            {feature.label}
          </Grid>
          <Grid item xs={7}>
            <RoleProtectedSelect
              required
              id="product_value_select"
              label={RIGHTS_SELECT_PLACEHOLDER}
              name={feature.name}
              value={selectsFormData[feature.name] ?? ''}
              onChange={handleSelectChange}
            >
              <MenuItem value={0}>Hidden</MenuItem>
              <MenuItem value={1}>View only</MenuItem>
              <MenuItem value={2}>Edit</MenuItem>
            </RoleProtectedSelect>
          </Grid>
        </Grid>
      );
    });
  }, [selectsFormData, handleSelectChange]);

  const composedFormData = React.useMemo(() => {
    const data = {
      profileName: textInputData.profileName,
      roleAccessType,
      sections: Object.entries(selectsFormData).map((payload) => {
        const [key, value] = payload as [TAccountRightsFeatureList, number];

        const section = {
          key,
          value,
        };

        return section;
      }),
    };

    return data as TeamRightsConfigureDialogFormData;
  }, [textInputData, selectsFormData, roleAccessType]);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      onSubmit(composedFormData);
      setSelectsFormData({});
      setTextInputData({});
    },
    [composedFormData, onSubmit]
  );

  return (
    <AppFormDialog
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={onClose}
      title="Configure rights for Team"
      content={
        <form onSubmit={handleSubmit} id="team-rights-form">
          <Box paddingTop={2} paddingBottom={2}>
            <Grid container spacing={2} wrap="wrap" direction="column">
              <Grid item xs={12} container>
                <Grid item xs={5} alignItems="center" container>
                  Team Name
                </Grid>
                <Grid item xs={7}>
                  <RoleProtectedInput
                    size="small"
                    placeholder="Team Name"
                    variant="outlined"
                    name="profileName"
                    value={textInputData.profileName || ''}
                    required
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={5} alignItems="center" container>
                  Access Type
                </Grid>
                <Grid item xs={7}>
                  <RoleProtectedSelect
                    name="roleAccessType"
                    variant="outlined"
                    value={roleAccessType}
                    onChange={handleAccessType}
                  >
                    <MenuItem value={1}>Global</MenuItem>
                    <MenuItem value={2}>Local CO</MenuItem>
                  </RoleProtectedSelect>
                </Grid>
              </Grid>
              {selects}
            </Grid>
          </Box>
        </form>
      }
      actions={
        <Box p={2} width="100%">
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <AppButton onClick={onClose} color="default">
                Cancel
              </AppButton>
            </Grid>
            <Grid item>
              <RoleProtectedButton
                type="submit"
                color="primary"
                form="team-rights-form"
              >
                Create
              </RoleProtectedButton>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};
