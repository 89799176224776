import {
  deleteExceptionAction,
  setBaseMarginExceptionAction,
  setBaseMarginLoadingAction,
  setUpdateBaseMarginException,
} from '../actions';
import { call, put, select } from 'redux-saga/effects';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { AppState } from '@app/store';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { BaseMarginExceptions } from '@app/shared/types/baseMargin';
import { enqueSnackAction } from '@app/store/snacks';

export function* deleteExceptionWorkerSaga(
  action: ReturnType<typeof deleteExceptionAction>
) {
  try {
    yield put(setBaseMarginLoadingAction({ payload: true }));
    const coId: string = yield select(
      (state: AppState) => state.filters.co.selectedItem?.id
    );
    yield call(BaseMarginService.deleteException, action.payload);
    const newData: BaseMarginExceptions = yield call(
      BaseMarginService.fetchBaseMarginExceptions,
      {
        coId,
        skip: getCurrentSkip(),
        limit: getCurrentPageLimit(),
      }
    );
    yield put(setUpdateBaseMarginException({ payload: {} }));
    yield put(setBaseMarginExceptionAction({ payload: newData }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create delete exception error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create delete exception error',
        },
      })
    );
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
  }
}
