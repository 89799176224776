import { Action } from 'redux';
import {
  resetSelectedPackagingGroupAction,
  setPackagingGroupsDataAction,
  setPackagingGroupsLoadingAction,
  setSelectedPackagingGroupsAction,
  setPackagingGroupsIsFullListAction,
} from './actions';
import { initialState, PackagingGroupsState } from './state';

export function packagingGroupsReducer(
  state = initialState,
  action: Action
): PackagingGroupsState {
  if (setPackagingGroupsDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
    };
  }

  if (setPackagingGroupsLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setPackagingGroupsIsFullListAction.is(action)) {
    return {
      ...state,
      isFullList: action.isFullList,
    };
  }

  if (setSelectedPackagingGroupsAction.is(action)) {
    return {
      ...state,
      selectedPackagingGroups: action.items,
    };
  }

  if (resetSelectedPackagingGroupAction.is(action)) {
    return {
      ...state,
      selectedPackagingGroups: [...state.items],
    };
  }

  return state;
}
