import {
  setWizardPackagingGroupAction,
  setWizardProductAllocationInfoAction,
} from '../actions';
import { call, put, select } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';
import { fetchCOIdWorkerSaga } from './fetchCOIdWorkerSaga';
import { getWizardCurrentProductIdSelector } from '@app/store/wizard';

export function* fetchWizardProductGroupInfoWorkerSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);
    const productId = yield select(getWizardCurrentProductIdSelector);

    const productGroupInfoRes = yield call(
      WizardServices.getProductGroupAllocationWithFullInfo,
      coId,
      productId
    );
    const packagingGroupRes = yield call(
      WizardServices.getPackagingTypes,
      coId,
      productId
    );
    yield put(
      setWizardProductAllocationInfoAction({
        payload: productGroupInfoRes.data,
      })
    );
    yield put(
      setWizardPackagingGroupAction({ payload: packagingGroupRes.data })
    );
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
