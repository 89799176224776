import {
  fetchWizardLookupVolumeSystemAction,
  setWizardLookupVolumeSystemAction,
} from '../actions';
import { call, put } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';

export function* fetchWizardLookupVolumeSystemWorkerSaga(
  action: ReturnType<typeof fetchWizardLookupVolumeSystemAction>
) {
  try {
    const res = yield call(
      WizardServices.getLookupVolumeSystem,
      action.payloads || []
    );
    yield put(setWizardLookupVolumeSystemAction({ payload: res.data }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
