import { call, put, select } from 'redux-saga/effects';
import { setIndustriesFilterIds } from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { enqueSnackAction } from '@app/store/snacks';
import { setSelectedCo } from '@app/store/filters-module/co';
import {
  getIndustriesFilter,
  setSelectedIndustries,
} from '@app/store/filters-module/industries';
import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';
import { buildFilterMatrix } from '@app/shared/hooks/useProductFilters.ts/utils/buildFilterMatrix';
import { flatArray } from '@app/shared/utils/flatArray';
import { formatFilterMatrix } from '@app/shared/hooks/useProductFilters.ts/utils/formatFilterMatrix';
import { setBackdropLoadingAction } from '@app/store/app/actions';

export function* fetchIndustriesIdsWorkerSaga(
  action: ReturnType<typeof setSelectedCo>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));

    const data: string[] = yield call(
      BaseMarginService.getFiltersIndustriesIds,
      action.co ? action.co.id : ''
    );

    const industries: Industrie[] = yield select(getIndustriesFilter);

    const level4IndustriesMapById = industries
      .filter((industry) => industry.level === 4)
      .reduce((map, industry) => {
        map[industry.id] = industry;

        return map;
      }, {} as Record<string, Industrie>);

    const industriesInApiBaseMatrixData = data.map((industryId) => {
      const industry = level4IndustriesMapById[industryId];
      if (!industry) {
        throw new Error(
          `Industry with id: ${industryId} not found!Something went wrong.`
        );
      }
      return industry;
    });

    const industryMatrix = buildFilterMatrix(industries);

    const matrixValue = [...industryMatrix];
    matrixValue[3] = industriesInApiBaseMatrixData;

    const matrixFromApiDataIndustries = formatFilterMatrix(
      3,
      industryMatrix,
      matrixValue,
      'up'
    ) as Industrie[][];

    const filtersFromBaseMarginData: Industrie[] = flatArray(
      matrixFromApiDataIndustries
    );
    yield put(setIndustriesFilterIds({ payload: data }));
    yield put(setSelectedIndustries({ filters: filtersFromBaseMarginData }));
    yield put(setBackdropLoadingAction({ payload: false }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch industries id error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch industries id error',
        },
      })
    );
    console.log(`Failed to fetch industriesIDs: ${e.toString()}`);
  }
}
