import {
  WizardBaseMarginMatrixOrigin,
  WizardBaseMarginMatrixState,
} from '@app/shared/types/wizard';
import { bmmConverterStateToOrigin } from '@app/store/wizard/utils/convetres';
import { validateMarginValue } from '../components/BaseMarginMatrixTable/util/validateMarginValue';

export const bmmOriginValidate = (
  data: WizardBaseMarginMatrixOrigin[]
): boolean =>
  data.reduce<boolean>((isValid, i): boolean => {
    return (
      isValid &&
      i.marginValues.length === 1 &&
      !isNaN(Number(i.marginValues[0])) &&
      i.marginTypes.length === 1 &&
      validateMarginValue(i.marginValues[0], i.marginTypes[0]) &&
      i.industryMargins.reduce<boolean>((isValid, item): boolean => {
        return (
          isValid &&
          item.marginValues.length === 1 &&
          !isNaN(Number(item.marginValues[0])) &&
          validateMarginValue(item.marginValues[0], i.marginTypes[0])
        );
      }, true)
    );
  }, true);

export const bmmStateValidate = (
  data: WizardBaseMarginMatrixState[]
): boolean =>
  data.reduce<boolean>((isValid, i): boolean => {
    return (
      isValid &&
      !isNaN(Number(i.marginValues)) &&
      i.marginTypes !== null &&
      validateMarginValue(i.marginValues, i.marginTypes) &&
      i.industryMargins.reduce<boolean>((isValid, item): boolean => {
        return (
          isValid &&
          !isNaN(Number(item.marginValue)) &&
          i.marginTypes !== null &&
          validateMarginValue(item.marginValue, i.marginTypes)
        );
      }, true)
    );
  }, true);

export const bmmChangeValidate = (
  origin: WizardBaseMarginMatrixOrigin[],
  state: WizardBaseMarginMatrixState[],
  canEdit: boolean
): boolean => canEdit
    ? JSON.stringify(origin) !== JSON.stringify(bmmConverterStateToOrigin(state))
    : false
