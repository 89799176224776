import { pickTableError } from '@app/modules/VolumeDriver/utils/tableUtils';
import { TMarginSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-configurations.response';
import { AppTable, AppTableRef } from '@app/shared/components/AppTable';
import { validate } from '@app/shared/utils/validate';
import {
  deleteMarginSystemConfigurationsAction,
  fetchMarginSystemConfigurationsAction,
  selectMarginSystemConfiguration,
  selectMarginSystemConfigurationsItemsList,
  selectMarginSystemConfigurationsLoadingState,
  selectMarginSystemConfigurationsTotalItems,
  selectMarginSystemConfigurationValues,
  updateMarginSystemConfigurationAction,
} from '@app/store/volume-driver/margin-system-configurations';
import { debounce } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { marginSystemTableSchema } from './marginTableSchema';
import { transformToPercentString } from '@app/shared/utils/transformUtils';
import { NO_TABLE_DATA_TEXT } from '@app/shared/texts/texts';
import {
  systemSort,
  descriptionSort,
  level1Sort,
  level2Sort,
  level3Sort,
  level4Sort,
  level5Sort,
} from './sortHelpers';
import { TableCellInput } from '@app/shared/components/TableCellInput';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';

const { Head, HeadCell, Row, Body, Cell } = AppTable;

interface Props {
  refObj: React.MutableRefObject<AppTableRef>;
  onErrorStateChange: (hasErrors: boolean) => void;
}

export const MarginSystemTable = (props: Props) => {
  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const configurationsList = useSelector(
    selectMarginSystemConfigurationsItemsList
  );
  const configurationsValues = useSelector(
    selectMarginSystemConfigurationValues
  );
  const updateData = useSelector(selectMarginSystemConfiguration);
  const totalItems = useSelector(selectMarginSystemConfigurationsTotalItems);

  const isConfigurationsListLoading = useSelector(
    selectMarginSystemConfigurationsLoadingState
  );
  const isLoading = React.useMemo(() => {
    return [isConfigurationsListLoading].some((loading) => loading);
  }, [isConfigurationsListLoading]);

  const fetchData = React.useCallback(
    (skip: number, limit: number) => {
      dispatch(fetchMarginSystemConfigurationsAction({ skip, limit }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    fetchData(0, 25);
  }, [fetchData]);

  const handlePageChange = React.useCallback(
    (_, items: number, skip: number) => {
      fetchData(skip, items);
    },
    [fetchData]
  );

  const handleDelete = React.useCallback(
    (items: TMarginSystemConfiguration[]) => {
      props.refObj.current.resetPagination();
      dispatch(
        deleteMarginSystemConfigurationsAction({
          ids: items.map((item) => item.id),
        })
      );
    },
    [dispatch, props.refObj]
  );

  const handleInputChange = React.useCallback(
    (e: any) => {
      const id = e.target.getAttribute('data-id');
      const name = e.target.name;
      const value = isNaN(e.target.value) ? e.target.value : +e.target.value;

      const findUpdate = Object.values(updateData).some((f) => f.id === id);
      const values = findUpdate ? updateData[id] : configurationsValues[id];
      dispatch(
        updateMarginSystemConfigurationAction({
          id: id,
          updatedPayload: {
            ...values,
            [name]: value,
          },
        })
      );
    },
    [dispatch, configurationsValues, updateData]
  );

  React.useEffect(() => {
    const errors = validate(configurationsList, marginSystemTableSchema);
    setErrors(errors);
  }, [configurationsList]);

  const liftErrorState = debounce(() => {
    const hasErrors = !!Object.keys(errors).length;
    props.onErrorStateChange(hasErrors);
  }, 50);

  React.useLayoutEffect(liftErrorState, [errors]);

  return (
    <AppTable
      data={configurationsList}
      dataCount={totalItems}
      deletableItems={true}
      useInMemoryPagination
      onPageChange={handlePageChange}
      loading={isLoading}
      refObj={props.refObj}
      tableLayout="auto"
      onDelete={handleDelete}
    >
      <Head>
        <Row isHead={true}>
          <HeadCell colSpan={3}></HeadCell>
          <HeadCell
            colSpan={5}
            align="center"
            style={{ backgroundColor: '#d1d1d1' }}
          >
            Margin adjustment for volume threshold
            <br />
            (as % discount of the basis margin percentage)
          </HeadCell>
        </Row>
        <Row isHead={true} disableDeletionBehavior={true}>
          <HeadCell sortHandler={systemSort} preWrap>
            Margin System
          </HeadCell>
          <HeadCell sortHandler={descriptionSort} width="80px">
            Description
          </HeadCell>
          <HeadCell
            sortHandler={level1Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 1
          </HeadCell>
          <HeadCell
            sortHandler={level2Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 2
          </HeadCell>
          <HeadCell
            sortHandler={level3Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 3
          </HeadCell>
          <HeadCell
            sortHandler={level4Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 4
          </HeadCell>
          <HeadCell
            sortHandler={level5Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 5
          </HeadCell>
        </Row>
      </Head>
      <Body<TMarginSystemConfiguration> noDataText={NO_TABLE_DATA_TEXT}>
        {(items) => {
          return items.map((item, index) => {
            const nameError = pickTableError(index, 'name', errors);

            const firstLevelError = pickTableError(index, 'firstLevel', errors);
            const secondLevelError = pickTableError(
              index,
              'secondLevel',
              errors
            );
            const thirdLevelError = pickTableError(index, 'thirdLevel', errors);
            const fourthLevelError = pickTableError(
              index,
              'fourthLevel',
              errors
            );
            const fifthLevelError = pickTableError(index, 'fifthLevel', errors);
            return (
              <Row key={item.id} item={item}>
                <Cell>
                  <TableCellInput
                    label="Margin System name"
                    name="name"
                    value={item.name}
                    onChange={handleInputChange}
                    data-id={item.id}
                    data-index={index}
                    error={nameError}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell style={{ maxWidth: '150px', minWidth: '150px' }}>
                  <TableCellInput
                    label="Margin System Description"
                    value={item.description || '—'}
                    name="description"
                    onChange={handleInputChange}
                    data-id={item.id}
                    type="description"
                    symbolsLimit={20}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell>
                  <TableCellInput
                    name="firstLevel"
                    value={item.firstLevel}
                    label="Level 1"
                    data-id={item.id}
                    onChange={handleInputChange}
                    error={firstLevelError}
                    transformToViewValue={transformToPercentString}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell>
                  <TableCellInput
                    name="secondLevel"
                    value={item.secondLevel}
                    label="Level 2"
                    data-id={item.id}
                    onChange={handleInputChange}
                    error={secondLevelError}
                    transformToViewValue={transformToPercentString}
                    type="number"
                    roleProtected={true}
                  />
                </Cell>
                <Cell>
                  <TableCellInput
                    name="thirdLevel"
                    value={item.thirdLevel}
                    label="Level 3"
                    data-id={item.id}
                    onChange={handleInputChange}
                    error={thirdLevelError}
                    transformToViewValue={transformToPercentString}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell>
                  <TableCellInput
                    name="fourthLevel"
                    value={item.fourthLevel}
                    label="Level 4"
                    data-id={item.id}
                    onChange={handleInputChange}
                    error={fourthLevelError}
                    transformToViewValue={transformToPercentString}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell>
                  <TableCellInput
                    name="fifthLevel"
                    value={item.fifthLevel}
                    label="Level 5"
                    data-id={item.id}
                    onChange={handleInputChange}
                    error={fifthLevelError}
                    transformToViewValue={transformToPercentString}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
              </Row>
            );
          });
        }}
      </Body>
    </AppTable>
  );
};
