export const AUTH_FORM_INVALID_CREDENTIALS = 'Invalid username or password.';
export const AUTH_FORM_DEFAULT_ERROR =
  "Something wen't wrong, try again later.";

export const getFormError = (code?: number): string => {
  switch (code) {
    case 401:
      return AUTH_FORM_INVALID_CREDENTIALS;
    default:
      return AUTH_FORM_DEFAULT_ERROR;
  }
};
