import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';
import { buildIndustriesMapByLevel } from './buildIndustriesMapByLevel';
import { sortIndustriesByParentId } from './sortIndustriesByParentId';

export function calculateColSpanSize(
  industryList: Industrie[],
  industry: Industrie[],
  accum = 0
): number {
  const filteredIndustries = industryList.filter((childIndustry, index) => {
    const isMatched = industry.some((ind) => ind.id === childIndustry.parentId);

    return isMatched;
  });

  if (filteredIndustries.length) {
    accum = filteredIndustries.length;
  }

  return filteredIndustries.length
    ? calculateColSpanSize(industryList, filteredIndustries, accum)
    : accum;
}

export function createIndustryGroup(industry: Industrie[]) {
  const industryByLevel = buildIndustriesMapByLevel(industry);

  const groups: Record<number, { colSpan: number; industry: Industrie }[]> = {};

  let level = 1;

  while (level < 5) {
    const industriesByLevel = industryByLevel[level];

    if (!industriesByLevel.length) return groups;

    for (let i = 0; i < industriesByLevel.length; i++) {
      const colSpan = calculateColSpanSize(industry, [industriesByLevel[i]]);

      const groupItem = {
        colSpan: colSpan,
        industry: industriesByLevel[i],
      };

      if (Array.isArray(groups[level])) {
        groups[level].push(groupItem);
      } else {
        groups[level] = [groupItem];
      }
    }

    level++;
  }

  return sortIndustriesByParentId(groups);
}
