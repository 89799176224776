import * as React from 'react';

type ChildComponent<TProps> =
  | React.ComponentType<TProps>
  | React.FC<TProps>
  | ((props: TProps) => JSX.Element);

export interface ConfirmationComponentProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  onSkip?: () => void;
  shouldAskForConfirm?: boolean;
}
export interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel?: () => void;
  isOpened?: boolean;
}

export function withConfirmation(eventsToIntercept: string[]) {
  return function <TComponentProps, TModalProps>(
    InnerComponent: ChildComponent<TComponentProps>,
    Modal: ChildComponent<TModalProps & ConfirmationModalProps>
  ) {
    const Component = (
      props: TComponentProps &
        ConfirmationComponentProps & {
          modalProps?:
            | Omit<
                TModalProps,
                'onConfirm' | 'onCancel' | 'isOpened' | 'onSkip'
              >
            | undefined;
        }
    ) => {
      const { modalProps, shouldAskForConfirm, ...componentProps } = props;
      const [isModalOpened, setModalOpened] = React.useState<boolean>(false);
      const [interceptedEvent, setInterceptedEvent] = React.useState<any>(null);

      const interceptHandler = React.useCallback(
        (originEvent) =>
          function () {
            const args = arguments;

            if (shouldAskForConfirm) {
              setInterceptedEvent({
                event: () => originEvent.apply(null, args),
              });
              setModalOpened(true);
            } else {
              originEvent.apply(null, arguments);
            }
          },
        [shouldAskForConfirm]
      );

      const enhancedProps = React.useMemo(() => {
        const props = {
          ...componentProps,
        } as any;

        eventsToIntercept.forEach((eventName) => {
          props[eventName] = interceptHandler(props[eventName]);
        });

        return props;
      }, [componentProps, interceptHandler]);

      const handleCancel = React.useCallback(async () => {
        props.onCancel && (await props.onCancel());
        setInterceptedEvent(null);
        setModalOpened(false);
      }, [props]);

      const handleSkip = React.useCallback(async () => {
        props.onSkip && (await props.onSkip());

        interceptedEvent.event();
        setInterceptedEvent(null);
        setModalOpened(false);
      }, [interceptedEvent, props]);

      const handleConfirm = React.useCallback(async () => {
        props.onConfirm && (await props.onConfirm());

        interceptedEvent.event();
        setInterceptedEvent(null);
        setModalOpened(false);
      }, [interceptedEvent, props]);
      return (
        <React.Fragment>
          <InnerComponent {...(enhancedProps as TComponentProps)} />
          <Modal
            {...(props.modalProps as TModalProps)}
            isOpened={isModalOpened}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            onSkip={handleSkip}
          />
        </React.Fragment>
      );
    };

    return Component;
  };
}
