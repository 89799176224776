import { call, put, select } from 'redux-saga/effects';
import {
  setLoadingGroupsTableAction,
  setProductGroupAllocations,
} from '../actions';
import ProductValueService from '@services/product-value-service/product-value-service';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import {
  ProductGroupAllocations,
  ProductGroupAllocationsDTO,
} from '@app/shared/types/productValue';
import { selectSelectedProductValueAllocationLevels } from '../selectors';
import { selectSelectedFilters } from '@app/store/filters-module/filters-state';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { enqueSnackAction } from '@app/store/snacks';

export function* fetchProductGroupAllocationsSaga() {
  try {
    yield put(setLoadingGroupsTableAction({ payload: true }));
    const filtersValue: TFilterItemResponse[] = yield select(
      selectSelectedFilters
    );
    const filter4Level = filtersValue.filter((f) => f.level === 4);
    const allocationLevelsValue: TFilterAllocationLevels = yield select(
      selectSelectedProductValueAllocationLevels
    );

    const dataToReq: ProductGroupAllocationsDTO = {
      skip: getCurrentSkip(),
      limit: getCurrentPageLimit(),
      productGroupIds: filter4Level.map((i) => i.id),
      valueAllocations: allocationLevelsValue.map((i) => i.key),
      orderColumn: 0,
      isAsc: true,
    };
    const data: { data: ProductGroupAllocations[]; total: number } = yield call(
      ProductValueService.getProductGroupAllocations,
      dataToReq
    );
    yield put(
      setProductGroupAllocations({
        payload: {
          data: data.data,
          total: data.total,
        },
      })
    );
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create product group error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create product group error',
        },
      })
    );
  } finally {
    yield put(setLoadingGroupsTableAction({ payload: false }));
  }
}
