export interface UserState {
  id: string;
  fullName: string;
  email: string;
  loading: boolean;
}

export const initialState: UserState = {
  id: '',
  fullName: '',
  email: '',
  loading: true,
};
