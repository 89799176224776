import { applyMiddleware, createStore } from 'redux';
import { AuthState } from './auth/state';
import { createRootReducer } from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './rootSaga';
import { UserState } from './user/state';
import { ProductValueState } from './productValue/state';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { ManagersState } from './managers';
import { RootAppState } from './app';
import { FiltersModuleState } from './filters-module';
import { ProductsState } from './products/state';
import { SnacksState } from './snacks';
import { SettingsState } from './settings/store';
import { configureInterceptors } from '@app/shared/utils/request-service/adapters/private-adapter';
import { AccountRightsState } from './account-rights';
import { BaseMarginState } from './baseMargin';
import { PackagingDriverState } from './driver';
import { VolumeDriverState } from './volume-driver';
import { NegotiationRoomState } from './negotiation-room';
import { WizardState } from './wizard';
import { ConfirmationModalState } from './confirmationModal';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export interface AppState {
  app: RootAppState;
  router: ConnectedRouter;
  auth: AuthState;
  user: UserState;
  productValue: ProductValueState;
  managers: ManagersState;
  rootAppState: RootAppState;
  filters: FiltersModuleState;
  products: ProductsState;
  snacks: SnacksState;
  settings: SettingsState;
  accountRights: AccountRightsState;
  driver: PackagingDriverState;
  baseMarginReducer: BaseMarginState;
  volumeDriver: VolumeDriverState;
  negotiationRoom: NegotiationRoomState;
  wizard: WizardState;
  confirmationModal: ConfirmationModalState;
}

export const appStore = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  )
);

// export const appStore = createStore(
//   createRootReducer(history),
//   process.env.NODE_ENV === 'production'
//     ? applyMiddleware(routerMiddleware(history), sagaMiddleware)
//     : composeWithDevTools(
//         applyMiddleware(routerMiddleware(history), sagaMiddleware)
//       )
// );

configureInterceptors(appStore);

sagaMiddleware.run(rootSaga);
