import { fetchTPRIndustriesAction, setTPRIndustriesAction } from '..';
import WizardServices from '@services/wizard/wizardServices';
import { call, put } from 'redux-saga/effects';

export function* fetchTPRIndustriesSaga(
  action: ReturnType<typeof fetchTPRIndustriesAction>
) {
  try {
    const res = yield call(WizardServices.getIndustry, action.payload);
    yield put(setTPRIndustriesAction({ payload: res.data }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
