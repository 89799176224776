import { all, takeEvery } from 'redux-saga/effects';
import { logoutAction, setAuthAction } from '../actions';
import { logoutSaga } from './logoutSaga';
import { setSessionSaga } from './setSessionSaga';

export function* authSagaWatcher() {
  yield all([
    takeEvery(logoutAction.TYPE, logoutSaga),
    takeEvery(setAuthAction.TYPE, setSessionSaga),
  ]);
}
