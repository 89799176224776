import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import {
  bulkProductLineAction,
  fetchProductLineDataAction,
  saveUpdateProductLineAction,
} from '../action';
import { bulkSaga } from './bulkSaga';
import { fetchProductLineSaga } from './fetchProductLineSaga';
import { productLineWorkerSaga } from './productLineWorkerSaga';
import { updateProductLineSaga } from './updateSaga';

export function* productLineWatcherSaga() {
  yield all([
    takeEvery(LOCATION_CHANGE, productLineWorkerSaga),
    takeEvery(fetchProductLineDataAction.TYPE, fetchProductLineSaga),
    takeEvery(saveUpdateProductLineAction.TYPE, updateProductLineSaga),
    takeEvery(bulkProductLineAction.TYPE, bulkSaga),
  ]);
}
