import * as React from 'react';
import { StepLayout } from '../StepLayout';
import { StepContentLayout } from '../StepContentLayout';
import { ProductGroupContainer } from './components/ProductGroupContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  WizardDispatchContext,
  WizardStepComponentProps,
} from '../SimulatorWizard';
import { VolumeSystemContainer } from '@modules/Simulator/components/SimulatorBasicParametersStep/components/VolumeSystemContainer/VolumeSystemContainer';
import { ProductGroupInfo } from '@modules/Simulator/components/SimulatorBasicParametersStep/components/ProductListTable';
import { useContext, useEffect } from 'react';
import { changeActiveStepStatusAction } from '@modules/Simulator/components/SimulatorWizard/store';
import { COIdSelector } from './components/COIdSelector/COIdSelector';
import { useSelector } from 'react-redux';
import { getWizardCOIdSelector } from '@app/store/wizard';

export const SimulatorBasicParametersStep = ({
  step,
  next,
  canEdit
}: WizardStepComponentProps) => {
  const wizardDispatch = useContext(WizardDispatchContext);
  useEffect(() => {
    wizardDispatch(changeActiveStepStatusAction({ showed: true }));
  }, [wizardDispatch]);

  const coId = useSelector(getWizardCOIdSelector);
  useEffect(() => {
    wizardDispatch(
      changeActiveStepStatusAction({
        changed: false,
        originValidate: false,
        currentValidate: false,
      })
    );
  }, [coId, wizardDispatch]);

  return (
    <StepLayout title="Configuration" key={coId}>
      <StepContentLayout
        footer={
          <Grid item container direction="row" justify="flex-end" spacing={1}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={next}
                disabled={!step.currentValidate}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container direction="column" style={{ width: '100%' }}>
          <Grid item>
            <COIdSelector />
          </Grid>
          <Grid item>
            <ProductGroupContainer />
          </Grid>
          <Grid item>
            <ProductGroupInfo 
              canEdit={canEdit}
            />
          </Grid>
          <Grid item>
            <VolumeSystemContainer />
          </Grid>
        </Grid>
      </StepContentLayout>
    </StepLayout>
  );
};
