import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { SelectFormItem } from '../../../store';
import { OptionItem } from '@modules/Simulator/components/TargetPriceResults/utils';
import { Grid } from '@material-ui/core';

interface SelectFormFieldProps extends SelectFormItem {
  handlerChange: (value: OptionItem) => void;
  handlerOpen: (name: string) => void;
}

export const SelectFormField = ({
  name,
  label,
  placeholder,
  current,
  options,
  loaded,
  handlerOpen,
  handlerChange,
}: SelectFormFieldProps) => {
  return (
    <Grid container item spacing={2}>
      <Grid item container justify="flex-end" alignItems="center" xs={6}>
        {label}:
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={current}
          options={options}
          getOptionSelected={(i, value) => i.key === value.key}
          getOptionLabel={(i) => i.name}
          onOpen={() => !loaded && handlerOpen(name)}
          loading={!loaded}
          onChange={(e, value) => handlerChange(value as OptionItem)}
          renderInput={(params) => (
            <TextField {...params} label={placeholder} variant="outlined" />
          )}
          fullWidth={true}
          size="small"
        />
      </Grid>
    </Grid>
  );
};
