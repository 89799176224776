import React, { useCallback } from 'react';
import {
  mergeAccessValueToUserRightsItem,
  mergeSectionRightsValueToUserRightsItem,
  permissionSelectRights,
  rightsHeaders,
  sortByCO,
  sortByTeamName,
  sortByUserName,
  statusTitle,
  UserRightsProductAccessType,
  UserRightsTablePayloadType,
  UserRightsTablePropsType,
} from '.';
import { getSectionsRecord } from '.';
import { AppTable } from '@shared/components/AppTable';
import { Switch, TableCell } from '@material-ui/core';
import { ClippedText } from '@shared/components/ClippedText';
import { NO_TABLE_DATA_TEXT } from '@shared/texts/texts';
import { PermissionSelectInput } from '../../PermissionSelectInput';
import { TRights } from '@app/services/rights-service/response-interfaces/get-rights.response';
import { ProductAccessSelect } from './component/ProductAccessSelect';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { parseDate } from '@app/shared/utils/dateTimeUtil';

const style = {
  headCell: {
    border: '2px solid #fff',
  },
};

export const UserRightsTable = ({
  originItems,
  updateItems,
  itemsCount,
  loading,
  onDelete,
  onChange,
  isEmpty,
}: UserRightsTablePropsType) => {
  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;

  //===== HANDLERS =====

  const handlerDelete = useCallback(
    (deletedItems: UserRightsTablePayloadType[]) => {
      onDelete(
        deletedItems.map((i) => ({ userRightId: i.id, userId: i.userId }))
      );
    },
    [onDelete]
  );

  const handlerChangeSectionRights = useCallback(
    (item: UserRightsTablePayloadType, featureName: string, value: TRights) => {
      const newItem = mergeSectionRightsValueToUserRightsItem(
        item,
        featureName,
        value
      );
      onChange(newItem);
    },
    [onChange]
  );
  const handlerChangeAccess = useCallback(
    (
      item: UserRightsTablePayloadType,
      featureName: string,
      value: UserRightsProductAccessType
    ) => {
      const newItem = mergeAccessValueToUserRightsItem(
        item,
        featureName,
        value
      );
      onChange(newItem);
    },
    [onChange]
  );

  //===== TABLE BODY =====

  const renderTableBody = useCallback(
    (items: UserRightsTablePayloadType[]) =>
      items.map((originItem) => {
        // merge update data with origin data
        const item = updateItems[originItem.id]
          ? updateItems[originItem.id]
          : originItem;

        const itemSectionRecord = getSectionsRecord(item.sections);

        return (
          <AppTable.Row key={item.id} isCanBeDeleted item={item}>
            <TableCell
              style={{
                left: '68px',
                zIndex: 10,
                position: 'sticky',
                background: '#fff',
              }}
            >
              <ClippedText
                text={item.firstName + ' ' + item.lastName}
                clipLimit={30}
              />
            </TableCell>
            <TableCell align="center">
              {originItem.profile ? originItem.profile : '—'}
            </TableCell>
            <TableCell align="center">
              {originItem.co ? originItem.co : 'Global'}
            </TableCell>
            <TableCell align="center">
              <ProductAccessSelect
                value={item.valueLabelProductAccess}
                onChange={(value) =>
                  value &&
                  handlerChangeAccess(item, 'valueLabelProductAccess', value)
                }
                disabled={(item && item.status === 2) || isDisabled}
              />
            </TableCell>
            <TableCell align="center">
              <ProductAccessSelect
                value={item.generalProductAccess}
                onChange={(value) =>
                  value &&
                  handlerChangeAccess(item, 'generalProductAccess', value)
                }
                disabled={(item && item.status === 2) || isDisabled}
              />
            </TableCell>
            {rightsHeaders.map((i) => (
              <TableCell align="center" key={i.key + item.id}>
                <PermissionSelectInput
                  value={itemSectionRecord[i.key]}
                  rights={permissionSelectRights}
                  name={i.key}
                  onChange={(name: string, value: TRights) =>
                    handlerChangeSectionRights(item, name, value)
                  }
                  disabled={(item && item.status === 2) || isDisabled}
                />
              </TableCell>
            ))}
            <TableCell align="center">
            <Switch
              color="primary"
              checked={item.isTemporaryApprovalInCRM}
            />
            </TableCell>
            <TableCell align="center">{statusTitle[item.status]}</TableCell>
            <TableCell align="center">
              {item.primaryRightsOwner ? item.primaryRightsOwner : '—'}
            </TableCell>
            <TableCell align="center">
              {item.validFrom ? parseDate(new Date(item.validFrom)) : '—'}
            </TableCell>
            <TableCell align="center">
              {item.validTo ? parseDate(new Date(item.validTo)) : '—'}
            </TableCell>
          </AppTable.Row>
        );
      }),
    [updateItems, handlerChangeSectionRights, handlerChangeAccess, isDisabled]
  );

  return (
    <AppTable
      data={originItems}
      dataCount={itemsCount}
      loading={loading}
      deletableItems={true}
      onDelete={handlerDelete}
      isEmpty={isEmpty}
      tableLayout={'auto'}
    >
      <AppTable.Head>
        <AppTable.Row isHead={true}>
          <AppTable.HeadCell colSpan={7} />
          <AppTable.HeadCell colSpan={2} align="center" style={style.headCell}>
            <pre>Base margin matrix</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell colSpan={2} align="center" style={style.headCell}>
            <pre>Volume driver</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell colSpan={2} align="center" style={style.headCell}>
            <pre>Packaging driver</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell colSpan={3} align="center" style={style.headCell}>
            <pre>Negotiation room</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>Wizard</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell colSpan={4} align="center" style={style.headCell}>
            <pre>Settings</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell colSpan={5} />
        </AppTable.Row>
        <AppTable.Row isHead={true} disableDeletionBehavior={true}>
          <AppTable.HeadCell
            sortHandler={sortByUserName}
            align="center"
            style={{
              left: '68px',
              zIndex: 16,
              position: 'sticky',
              border: '2px solid #fff',
            }}
          >
            <pre>User</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            sortHandler={sortByTeamName}
            align="center"
            style={style.headCell}
          >
            <pre>User profile</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell
            sortHandler={sortByCO}
            align="center"
            style={style.headCell}
          >
            <pre>CO</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>Product access for value label</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>General product access</pre>
          </AppTable.HeadCell>
          {rightsHeaders.map((i) => (
            <AppTable.HeadCell
              key={i.key}
              align="center"
              style={style.headCell}
            >
              <pre>{i.name}</pre>
            </AppTable.HeadCell>
          ))}
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>Temporary approval in CRM</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>Status</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>Permanent rights owner</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>Valid from</pre>
          </AppTable.HeadCell>
          <AppTable.HeadCell align="center" style={style.headCell}>
            <pre>Valid to</pre>
          </AppTable.HeadCell>
        </AppTable.Row>
      </AppTable.Head>
      {originItems.length ? (
        <AppTable.Body<UserRightsTablePayloadType>
          contentColSpan={9}
          noDataText={NO_TABLE_DATA_TEXT}
        >
          {renderTableBody}
        </AppTable.Body>
      ) : null}
    </AppTable>
  );
};
