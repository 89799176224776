export const sortTableByName = (a: any, b: any, name: string) => {
  if (b[name] < a[name]) {
    return -1;
  }
  if (b[name] > a[name]) {
    return 1;
  }
  return 0;
};

export const sortByDirection = (order: 'asc' | 'desc', name: string) => {
  return order === 'desc'
    ? (a: any, b: any) => sortTableByName(a, b, name)
    : (a: any, b: any) => -sortTableByName(a, b, name);
};

export const sortTableByNameWithRange = (
  a: any,
  b: any,
  name: string,
  isRange: boolean
) => {
  if (isRange) {
    const aNumb = isNaN(Number(a[name])) ? 0 : Number(a[name]);
    const bNumb = isNaN(Number(b[name])) ? 0 : Number(b[name]);

    if (bNumb < aNumb) return -1;
    if (bNumb > aNumb) return 1;
    return 0;
  }

  if (b[name] < a[name]) {
    return -1;
  }
  if (b[name] > a[name]) {
    return 1;
  }
  return 0;
};

export const sortByDirectionWithRange = (
  order: 'asc' | 'desc',
  name: string,
  isPropertyWithRange: boolean
) => {
  return order === 'desc'
    ? (a: any, b: any) =>
        sortTableByNameWithRange(a, b, name, isPropertyWithRange)
    : (a: any, b: any) =>
        -sortTableByNameWithRange(a, b, name, isPropertyWithRange);
};

//===== sort function with string and array =====

export const sortTableByNameAndIndex = (
  a: any,
  b: any,
  name: string,
  index: number | null,
  isNumberString: boolean
) => {
  const aValue = index === null ? a[name] : a[name][index];
  const bValue = index === null ? b[name] : b[name][index];

  // change if value is number in string
  if (isNumberString) {
    const aNumb = isNaN(Number(aValue)) ? 0 : Number(aValue);
    const bNumb = isNaN(Number(bValue)) ? 0 : Number(bValue);

    if (bNumb < aNumb) return -1;
    if (bNumb > aNumb) return 1;
    return 0;
  }

  if (bValue < aValue) return -1;
  if (bValue > aValue) return 1;
  return 0;
};

export const sortByDirectionWithIndex = (
  order: 'asc' | 'desc',
  name: string,
  index: number | null,
  isNumberString: boolean
) => {
  return order === 'desc'
    ? (a: any, b: any) =>
        sortTableByNameAndIndex(a, b, name, index, isNumberString)
    : (a: any, b: any) =>
        -sortTableByNameAndIndex(a, b, name, index, isNumberString);
};
