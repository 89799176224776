import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { GetManagersByCoDto } from './dto/get-managers-by-co.dto';
import { TGetManagersByCoResponse } from './response-interfaces/get-managers-by-co.response';
import {
  TManager,
  TManagersResponse,
} from './response-interfaces/get-managers.response';

class ManagersService extends AxiosRequestService {
  getManagersByCo = async (
    dto: GetManagersByCoDto
  ): Promise<TGetManagersByCoResponse> => {
    const request = await this.privateRequest.get<TGetManagersByCoResponse>(
      `/Filter/Managers?commercialOrganizationId=${dto.coId}`
    );

    return request.data;
  };

  getManagers = async (): Promise<TManagersResponse> => {
    const request = await this.privateRequest.get<TManagersResponse>(
      '/manager/getlist'
    );

    return request.data;
  };

  pickFullName = (manager: TManager): string =>
    `${manager.firstName} ${manager.lastName}`;
}

export const ManagersApi = new ManagersService();
