import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import {
  fetchProductGroupAllocations,
  updateAllocationsLevelAction,
  updateBulkAllocationLevelAction,
  fetchProductGroupLabeling,
  updateBulkAllocationValueAction,
  updateAllocationValueAction,
  saveAllocationValueAction,
} from '../actions';
import { fetchProductGroupAllocationsSaga } from './fetchProductGroupAllocationsSaga';
import { fetchProductGroupLabelingSaga } from './fetchProductGroupLabelingSaga';
import { productLabelingWorkerSaga } from './productLabelingWorkerSaga';
import { productLevelsWorkerSaga } from './productLevelsWorkerSaga';
import { productValueWorkerSaga } from './productValueWorkerSaga';
import { saveProductValueSaga } from './saveProductValueSaga';
import { updateAllocationLevelsSaga } from './updateAllocationLevelsSaga';
import { updateAllocationValueSaga } from './updateAllocationValueSaga';
import { updateBulkAllocationLevelSaga } from './updateBulkAllocationLevelSaga';
import { updateBulkAllocationValueSaga } from './updateBulkAllocationValueSaga';

export function* productValueRootSaga() {
  yield all([
    takeEvery(LOCATION_CHANGE, productValueWorkerSaga),
    takeEvery(
      fetchProductGroupAllocations.TYPE,
      fetchProductGroupAllocationsSaga
    ),
    takeEvery(updateAllocationsLevelAction.TYPE, updateAllocationLevelsSaga),
    takeEvery(
      updateBulkAllocationLevelAction.TYPE,
      updateBulkAllocationLevelSaga
    ),
    takeEvery(fetchProductGroupLabeling.TYPE, fetchProductGroupLabelingSaga),
    takeEvery(
      updateBulkAllocationValueAction.TYPE,
      updateBulkAllocationValueSaga
    ),
    takeEvery(updateAllocationValueAction.TYPE, updateAllocationValueSaga),
    takeEvery(saveAllocationValueAction.TYPE, saveProductValueSaga),
    takeEvery(LOCATION_CHANGE, productLevelsWorkerSaga),
    takeEvery(LOCATION_CHANGE, productLabelingWorkerSaga),
  ]);
}
