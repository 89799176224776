//===== INTERFACES =====

export interface OptionItem {
  key: string;
  name: string;
}

export interface FormData {
  name: string;
  label: string;
  placeholder: string;
  options?: OptionItem[];
}

//===== SELECT FORM DATA =====

export const selectorFormData: FormData[] = [
  {
    name: 'coId',
    label: 'Select commercial organization',
    placeholder: 'Commercial organization',
  },
  {
    name: 'productGroup',
    label: 'Select product Group (allocation level)',
    placeholder: 'Product Group',
  },
  {
    name: 'default',
    label: 'Select default or industry deviation',
    placeholder: 'Default or industry deviation',
    options: [
      { key: 'default', name: 'Default' },
      { key: 'industry', name: 'Industry deviation' },
    ],
  },
  {
    name: 'industryLevel',
    label: 'Select industry level',
    placeholder: 'Industry level',
    options: [
      { key: '1', name: 'Level 1' },
      { key: '2', name: 'Level 2' },
      { key: '3', name: 'Level 3' },
      { key: '4', name: 'Level 4' },
    ],
  },
  {
    name: 'industryName',
    label: 'Select industry name',
    placeholder: 'Industry name',
  },
  {
    name: 'productValue',
    label: 'Select product value',
    placeholder: 'Product value',
  },
];

//===== COST FORM DATA =====

export const costFormData: FormData[] = [
  {
    name: 'tableView',
    label: 'Table view',
    placeholder: 'Table view',
    options: [
      { key: 'absolute', name: 'Target margin absolute' },
      { key: '%', name: 'Target margin %' },
      { key: 'price', name: 'Target price' },
    ],
  },
  {
    name: 'currency',
    label: 'Select currency',
    placeholder: 'Currency',
    options: [
      { key: 'eur', name: 'EUR' },
      { key: 'gbp', name: 'GBP' },
    ],
  },
  {
    name: 'productCost',
    label: 'Product cost',
    placeholder: 'Enter product cost',
  },
];
