import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWizardMarginSystemAction,
  getWizardMarginSystemSelector,
} from '@app/store/wizard';
import { CircularProgress, Grid } from '@material-ui/core';
import { WizardMarginSystem } from '@shared/types/wizard';
import { MarginSystemTable } from './MarginSystemTable';
import { MarginSystemSelect } from './MarginSystemSelect';

export const MarginSystemContainer = () => {
  //===== fetch data =====
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWizardMarginSystemAction({}));
  }, [dispatch]);

  //===== data from store =====
  const marginSystem = useSelector(getWizardMarginSystemSelector);

  //===== filter data =====
  const [selectMarginSystem, setSelectMarginSystem] = useState<
    WizardMarginSystem[]
  >([]);

  const handlerChangeMarginSystem = useCallback((values) => {
    setSelectMarginSystem(values);
  }, []);

  //===== loading options =====
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (marginSystem.length) setLoading(false);
    else setLoading(true);
  }, [marginSystem]);

  return loading ? (
    <div
      style={{
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Grid container direction="column" style={{ margin: '2rem 0' }}>
      <Grid item>
        <MarginSystemSelect
          marginSystem={marginSystem}
          selectMarginSystem={selectMarginSystem}
          handlerChangeSelectMarginSystem={handlerChangeMarginSystem}
        />
      </Grid>
      <Grid item>
        <MarginSystemTable
          marginSystem={
            selectMarginSystem.length ? selectMarginSystem : marginSystem
          }
        />
      </Grid>
    </Grid>
  );
};
