import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { enqueSnackAction } from '@app/store/snacks';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { withEditPermissionCheck } from '@app/shared/hocs/withEditPermissionCheck';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none',
    },
  })
);

interface Props {
  onFetchDataAfterImportFile: () => void;
  url: string;
  disabled?: boolean;
}

export const ImportButton: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const handleFile = async (e: any) => {
    try {
      dispatch(setBackdropLoadingAction({ payload: true }));
      const request = new AxiosRequestService();
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('File', file);
      await request.privateRequest.post(
        `${process.env.REACT_APP_API_URI}${props.url}`,
        formData
      );
      props.onFetchDataAfterImportFile();
      dispatch(
        enqueSnackAction({
          notification: {
            message: 'File has imported',
            options: {
              variant: 'success',
            },
            key: 'File has imported',
          },
        })
      );
    } catch (e) {
      if (e.response) {
        let message =
          e.response.status === 409 || e.response.status === 400
            ? e.response.data.message
            : 'Something wrong...';

        dispatch(
          enqueSnackAction({
            notification: {
              message,
              options: {
                variant: 'error',
              },
              key: 'create fetch base margin error',
            },
          })
        );
        return;
      }

      dispatch(
        enqueSnackAction({
          notification: {
            message: 'Something wrong...',
            options: {
              variant: 'error',
            },
            key: 'import file',
          },
        })
      );
    } finally {
      dispatch(setBackdropLoadingAction({ payload: false }));
    }

    e.target.value = '';
  };
  const classes = useStyles();
  return (
    <div>
      <input
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleFile}
        disabled={props.disabled}
      />
      <label htmlFor="contained-button-file">
        <Button
          disabled={props.disabled}
          variant="contained"
          color="default"
          component="span"
        >
          Import
        </Button>
      </label>
    </div>
  );
};

export const AppImportButton = withEditPermissionCheck(ImportButton);
