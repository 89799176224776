import { AppState } from '@app/store/store';

export const selectVolumeDriverAllocationsState = (store: AppState) =>
  store.volumeDriver.allocations;

export const selectDriverAllocationItems = (store: AppState) =>
  selectVolumeDriverAllocationsState(store).items;

export const selectDriverAllocationLoadedState = (store: AppState) =>
  selectVolumeDriverAllocationsState(store).isLoaded;

export const selectDriverAllocationLoadingState = (store: AppState) =>
  selectVolumeDriverAllocationsState(store).isLoading;

export const selectDriverAllocationTotalItems = (store: AppState) =>
  selectVolumeDriverAllocationsState(store).totalItems;

export const selectDriverAllocationSavingState = (store: AppState) =>
  selectVolumeDriverAllocationsState(store).isSaving;
