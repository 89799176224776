import { UserRightsProductAccessType, UserRightsTablePayloadType } from '.';
import { TRights } from '@services/rights-service/response-interfaces/get-rights.response';

export const mergeSectionRightsValueToUserRightsItem = (
  item: UserRightsTablePayloadType,
  featureName: string,
  value: TRights
): UserRightsTablePayloadType => ({
  ...item,
  sections: item.sections.map((i) =>
    i.key === featureName ? { ...i, value } : i
  ),
});

export const mergeAccessValueToUserRightsItem = (
  item: UserRightsTablePayloadType,
  featureName: string,
  value: UserRightsProductAccessType
): UserRightsTablePayloadType => ({
  ...item,
  [featureName]: value,
});

export const getSectionsRecord = (
  sections: {
    key: string;
    value: TRights;
  }[]
): Record<string, TRights> =>
  sections.reduce(
    (res, i) => ({ ...res, [i.key]: i.value }),
    {} as Record<string, TRights>
  );

//===== STATIC DATA =====

export const permissionSelectRights: TRights[] = [0, 1, 2];
