import { updateUserRightsItemsAction } from '@app/store/settings/user-rights';
import { call } from 'redux-saga/effects';
import { RightsApi } from '@services/rights-service/rights-service';
import { put } from 'redux-saga/effects';
import { enqueSnackAction } from '@app/store/snacks';
import { fetchUserRightsDataAction } from '@app/store/settings/user-rights';

export function* updateUserRightsSaga(
  action: ReturnType<typeof updateUserRightsItemsAction>
) {
  try {
    yield call(RightsApi.saveUserRights, action.payload);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'User rights successfully updated.',
          options: {
            variant: 'success',
          },
          key: 'user-rights-updated',
        },
      })
    );
    yield put(fetchUserRightsDataAction({}));
  } catch (e) {
    console.log('Failed to update user rights', e.message);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to update user rights.',
          options: {
            variant: 'error',
          },
          key: 'user-rights-update',
        },
      })
    );
  }
}
