import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff5000',
    },
    secondary: {
      // inverted colors
      main: '#fff',
      light: '#ff5000',
    },
  },
});
