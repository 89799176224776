import * as React from 'react';
import styles from './AuthPageLayout.module.scss';

import logo from '@assets/images/logo.png';
import { ROOT_URL } from '@app/routes/urls';
import { Link } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';

interface Props {
  children?: JSX.Element;
  title?: string;
}

export const AuthPageLayout = (props: Props) => {
  const { children, title } = props;

  return (
    <Grid container className={styles.authPageLayout}>
      <Grid item xs={6} className={styles.backdrop_main}></Grid>
      <Grid item xs={6} className={styles.backdrop}></Grid>
      <div className={styles.authPageLayout_contentWrapper}>
        <Paper className={styles.authPageLayout_content}>
          <Link to={ROOT_URL.urlTemplate}>
            <img src={logo} alt="" className={styles.logo} />
          </Link>
          <h1 className={styles.title}>{title}</h1>
          {children}
        </Paper>
      </div>
    </Grid>
  );
};
