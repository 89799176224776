import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { LookupValueSystem } from '@shared/types/wizard';
import {
  lookupVolumeSystemHeaders,
} from '../../../../data-definition';
import WizardTableContainer from '@modules/Simulator/components/WizardTableContainer/WizardTableContainer';
import { sortByDirection } from '@app/shared/utils/table-sort';
import TablePagination from '@material-ui/core/TablePagination';
import { UnitOption, unitOptions } from '@modules/VolumeDriver/SystemConfigurations/components/UnitSelect/unit-options';

interface VolumeSystemTableProps {
  volumeSystem: LookupValueSystem[];
}

export const VolumeSystemTable = ({ volumeSystem }: VolumeSystemTableProps) => {
  //===== table sort options =====
  const [orderColumnName, setOrderColumnName] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handlerChangeOrder = (columnName: string): void => {
    const isAsc = orderColumnName === columnName && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderColumnName(columnName);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dataTable = React.useMemo(() => {
    const newData = [...volumeSystem].splice(page * rowsPerPage, rowsPerPage);
    return newData;
  }, [volumeSystem, page, rowsPerPage]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <WizardTableContainer>
        <Table stickyHeader={true} style={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              {lookupVolumeSystemHeaders.map((i) => (
                <TableCell key={i.key}>
                  <TableSortLabel
                    active
                    direction={orderColumnName === i.key ? order : 'asc'}
                    onClick={() => handlerChangeOrder(i.key)}
                  >
                    {i.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTable
              .sort(sortByDirection(order, orderColumnName))
              .map((i) => {
                const unit = unitOptions.find(
                  (u: UnitOption): boolean => u.value === i.unit
                );
                return (
                  <TableRow key={i.id}>
                    <TableCell>{i.name}</TableCell>
                    <TableCell>{i.description}</TableCell>
                    <TableCell>
                      {i.firstLevel.toFixed(2)} {i.firstLevel ? unit?.name : ''}
                    </TableCell>
                    <TableCell>
                      {i.secondLevel.toFixed(2)}{' '}
                      {i.secondLevel ? unit?.name : ''}
                    </TableCell>
                    <TableCell>
                      {i.thirdLevel.toFixed(2)} {i.thirdLevel ? unit?.name : ''}
                    </TableCell>
                    <TableCell>
                      {i.fourthLevel.toFixed(2)}{' '}
                      {i.fourthLevel ? unit?.name : ''}
                    </TableCell>
                    <TableCell>
                      {i.fifthLevel.toFixed(2)} {i.fifthLevel ? unit?.name : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </WizardTableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={volumeSystem.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
