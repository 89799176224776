import { TVolumeDriverAllocation } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';

export interface VolumeDriverAllocationsState {
  isLoading: boolean;
  isLoaded: boolean;
  items: TVolumeDriverAllocation[];
  totalItems: number;
  isSaving: boolean;
}

export const initialState: VolumeDriverAllocationsState = {
  isLoading: true,
  isLoaded: false,
  totalItems: 0,
  items: [],
  isSaving: false,
};
