import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import { fetchExceptionsSaga } from './fetchExceptionsSaga';
import { exceptionsWorkerSaga } from './exceptionsWorkerSaga';
import {
  createExceptionNegotiationAction,
  deleteExceptionNegotiationAction,
  fetchExceptionsNegotiationDataAction,
  saveExceptionNegotiationAction,
} from '../action';
import { saveExceptionsSaga } from './saveExceptionsSaga';
import { deleteExceptionsSaga } from './deleteExceptionsSaga';
import { createExceptionsSaga } from './createExceptionsSaga';

export function* exceptionsWatcherSaga() {
  yield all([
    takeEvery(LOCATION_CHANGE, exceptionsWorkerSaga),
    takeEvery(fetchExceptionsNegotiationDataAction.TYPE, fetchExceptionsSaga),
    takeEvery(saveExceptionNegotiationAction.TYPE, saveExceptionsSaga),
    takeEvery(deleteExceptionNegotiationAction.TYPE, deleteExceptionsSaga),
    takeEvery(createExceptionNegotiationAction.TYPE, createExceptionsSaga),
  ]);
}
