import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  PackagingMarginTable,
  PackagingMarginTableData,
} from './components/PackagingMarginTable';
import { SetMarginForm } from './components/SetMarginForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDriverMarginListItems,
  selectDriverMarginLoadedState,
  selectDriverMarginLoadingState,
  selectDriverMarginSavingState,
  selectDriverMarginTotalItems,
} from '@app/store/driver/driver-margins/selectors';
import {
  selectCoAllocaitonLevels,
  selectCoFilters,
  selectCoLoadingState,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { selectSelectedPackagingGroups } from '@app/store/filters-module/packaging-groups/selectors';
import {
  fetchDriverMarginListAction,
  saveBulkMarginAction,
  saveMarginValuesAction,
} from '@app/store/driver/driver-margins/actions';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import { useHistory } from 'react-router';
import * as qs from 'query-string';
import { PackagingMarginFilters } from './components/PackagingMarginFilters';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import {
  composePackagingGroupsWithTableData,
  mapPackagingMarginsTableDataToRequestPayload,
} from './mapUtils';
import { useEmptyTableCheck } from '@app/shared/hooks/useEmptyTableCheck';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';

export const PackagingMargins = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedPackagingGroups = useSelector(selectSelectedPackagingGroups);

  const marginListItems = useSelector(selectDriverMarginListItems);
  const totalListItems = useSelector(selectDriverMarginTotalItems);
  const marginListLoading = useSelector(selectDriverMarginLoadingState);

  const filters = useSelector(selectCoFilters);

  const allocationLevels = useSelector(selectCoAllocaitonLevels);

  const selectedCo = useSelector(selectSelectedCo);

  const coListLoading = useSelector(selectCoLoadingState);

  const isLoading = React.useMemo(
    () => [marginListLoading, coListLoading].some((truthy) => truthy === true),
    [marginListLoading, coListLoading]
  );

  const isLoaded = useSelector(selectDriverMarginLoadedState);

  const isSaving = useSelector(selectDriverMarginSavingState);

  const handleFiltersChange = React.useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);

  const [tableData, setTableData] = React.useState<PackagingMarginTableData>(
    {}
  );

  const handleTableChange = React.useCallback(
    (value: PackagingMarginTableData) => {
      setTableData(value);
    },
    []
  );

  const isUpdated = React.useMemo(
    () => !!Object.keys(tableData).length,
    [tableData]
  );

  const handleSaveClick = React.useCallback(() => {
    if (selectedCo) {
      const tableDataPayload = mapPackagingMarginsTableDataToRequestPayload(
        selectedCo,
        tableData
      );

      dispatch(saveMarginValuesAction(tableDataPayload));
    }

    setTimeout(() => {
      // used to prevent table rerender with old data
      setTableData({});
    }, 200);
  }, [tableData, dispatch, selectedCo]);

  const handleBulkMarginSave = React.useCallback(
    (value: number) => {
      dispatch(
        saveBulkMarginAction({
          margin: String(value),
        })
      );
    },
    [dispatch]
  );

  const tableItems = React.useMemo(() => {
    return marginListItems.map((item) => {
      const result = {
        ...item,
        packagingGroups: composePackagingGroupsWithTableData(
          item.id,
          item.packagingGroups,
          tableData
        ),
      };

      return result;
    });
  }, [tableData, marginListItems]);

  const isTableEmpty = useEmptyTableCheck(isLoaded, isLoading, totalListItems);

  const [hasErrors, setErrors] = React.useState(false);

  const handleErrors = React.useCallback((hasErrors) => {
    setErrors(hasErrors);
  }, []);
  const onFetchDataAfterImportFile = useCallback(() => {
    dispatch(fetchDriverMarginListAction({}));
    dispatch(setBackdropLoadingAction({ payload: false }));
  }, [dispatch]);

  const handleDontSave = useCallback(() => {
    setTableData({});
  }, []);

  const handleConfirm = useCallback(() => {
    handleSaveClick();
    setTableData({});
  }, [handleSaveClick]);

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <PackagingMarginFilters
                onSkip={handleDontSave}
                onConfirm={handleConfirm}
                filters={filters}
                allocationLevels={allocationLevels}
                onChange={handleFiltersChange}
                shouldAskForConfirm={isUpdated}
              />
              <Grid item>
                <SetMarginForm
                  onSubmit={handleBulkMarginSave}
                  disabled={isSaving}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              onClick={handleSaveClick}
              color="primary"
              style={{ height: '100%' }}
              disabled={!isUpdated || hasErrors}
            >
              Save
            </RoleProtectedButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <PackagingMarginTable
          co={selectedCo || ({} as TCommercialOrganization)}
          loading={isLoading}
          items={tableItems}
          totalItems={totalListItems}
          packagingGroups={selectedPackagingGroups}
          value={tableData}
          onChange={handleTableChange}
          isEmpty={isTableEmpty}
          onErrors={handleErrors}
        />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadPackagingDriver'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton
            url={`/CreateExcel/PackagingDriver?coId=${selectedCo?.id}`}
          />
        </Grid>
      </Grid>
      <PageLeavePrompt
        onSkip={handleDontSave}
        when={isUpdated}
        onConfirm={handleConfirm}
      />
    </Grid>
  );
};
