import { put } from 'redux-saga/effects';
import { getCurrentExceptionIds, setCurrentExceptionIds } from '../actions';

export function* setCurrentExceptionIdsWorkerSaga(
  action: ReturnType<typeof getCurrentExceptionIds>
) {
  try {
    yield put(setCurrentExceptionIds({ payload: action.payload }));
  } catch (e) {
    console.log(`Failed to current exception ids: ${e.toString()}`);
  }
}
