export type SystemSelectValue = 'ms' | 'vs';

export const SYSTEM_SELECT_MS_VALUE: SystemSelectValue = 'ms';
export const SYSTEM_SELECT_VS_VALUE: SystemSelectValue = 'vs';

export interface SystemSelectOption {
  name: string;
  value: SystemSelectValue;
}

export const systemSelectOptions: SystemSelectOption[] = [
  {
    name: 'Volume System',
    value: 'vs',
  },
  {
    name: 'Margin System',
    value: 'ms',
  },
];
