import {
  DUPLICATE_RECORD_ERROR_TEMPLATE,
  formatErrorMessageTemplate,
} from '@app/shared/validation';
import { volumeSystemFormSchema } from '../SystemConfigurationDialog/components/VolumeSystemFormDialog/components/VolumeSystemForm/volumeSystemFormSchema';
import { customYup as Yup } from '@app/vendor/customYup';

export const VOLUME_SYSTEM_TABLE_DUPLICATE_NAME_ERROR = formatErrorMessageTemplate(
  DUPLICATE_RECORD_ERROR_TEMPLATE,
  [{ pattern: '{name}', value: 'Margin System name' }]
);

export const volumeTableSchema = Yup.array().of(
  volumeSystemFormSchema.uniqueProperty(
    'name',
    VOLUME_SYSTEM_TABLE_DUPLICATE_NAME_ERROR
  )
);
