import { ExceptionsNegotiationUpdate } from '@app/modules/NegotiationsRoom/tabs/ExceptionsTab/components/ExceptionsTable';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, select } from '@redux-saga/core/effects';
import { put } from 'redux-saga/effects';
import {
  fetchExceptionsNegotiationDataAction,
  selectNegotiationExceptionsUpdate,
  setExceptionsNegotiationLoadingAction,
  setUpdateDataExceptionsNegotiationAction,
} from '..';

export function* saveExceptionsSaga() {
  try {
    yield put(setExceptionsNegotiationLoadingAction({ loading: true }));

    const updateData: Record<
      string,
      ExceptionsNegotiationUpdate
    > = yield select(selectNegotiationExceptionsUpdate);
    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);

    yield call(
      NegotiationRoomApi.saveExceptions,
      Object.values(updateData).map((i) => i),
      selectedCO.id
    );
    yield put(fetchExceptionsNegotiationDataAction({}));
  } catch (e) {
    console.log('Failed to get exceptions items', e.message);
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'save exception',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'save exception',
        },
      })
    );
  } finally {
    yield put(setUpdateDataExceptionsNegotiationAction({ updateData: {} }));
    yield put(setExceptionsNegotiationLoadingAction({ loading: false }));
  }
}
