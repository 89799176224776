import * as React from 'react';
import styles from './TableCellInput.module.scss';
import Grid from '@material-ui/core/Grid';
import { ErrorTooltip } from '../ErrorTooltip';
import { InputContainer } from './components/InputContainer';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { ClippedText } from '../ClippedText';
import { MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectDriverCOCurrencies } from '@app/store/driver/driver-margins/selectors';

interface Props extends React.HTMLProps<HTMLInputElement> {
  type?: 'text' | 'description' | 'number';
  value?: string;
  marginType?: number;
  error?: string;
  label?: string;
  isClearing?: boolean;
  symbolsLimit?: number;
  roleProtected?: boolean;
  showMarginType?: boolean;
  transformToViewValue?: (value: string) => string;
  onClear?: () => void;
}

export const TableCellInput = (props: Props) => {
  const {
    type = 'text',
    value,
    marginType,
    error = '',
    label = '',
    name,
    onChange,
    onClear,
    transformToViewValue,
    symbolsLimit,
    roleProtected,
    isClearing,
    showMarginType = false,
    ...inputProps
  } = props;

  const coCurrencies = useSelector(selectDriverCOCurrencies);

  const [isEditing, setEditing] = React.useState(false);
  const [eventSnapshot, setEventSnapshot] =
    React.useState<React.ChangeEvent<HTMLInputElement> | null>(null);
  const [margin, setMargin] = React.useState<number | undefined>(marginType);

  const inputRef = React.useRef<HTMLDivElement>();

  const handleContainerClick = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      if (roleProtected) return;
      e.stopPropagation();
      setEditing(true);
    },
    [roleProtected]
  );

  const handleContainerClose = React.useCallback(() => {
    setEditing(false);
  }, []);

  const handleContainerClear = React.useCallback(() => {
    setEditing(false);
    onClear && onClear();
  }, [onClear]);

  const handleContainerConfirm = React.useCallback(() => {
    setEditing(false);
    if (showMarginType) {
      if (onChange != null) {
        onChange({
          value: eventSnapshot?.target.value,
          marginType: margin,
        } as any);
      }
    } else {
      if (onChange != null && eventSnapshot != null) {
        onChange(eventSnapshot);
        setEventSnapshot(null);
      }
    }
  }, [eventSnapshot, margin]);

  const handleInputChage = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEventSnapshot(e);
    },
    []
  );

  const handleMarginTypeChange = React.useCallback(
    (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
      setMargin(e.target.value as number);
    },
    []
  );

  const renderValue = React.useMemo(() => {
    return transformToViewValue ? transformToViewValue(value as string) : value;
  }, [value, transformToViewValue]);

  return (
    <React.Fragment>
      <Grid
        container
        wrap="nowrap"
        onClick={handleContainerClick}
        alignContent="center"
        className={styles.tableCellInput}
      >
        <Grid item xs={12} style={{ minHeight: '20px', minWidth: '20px' }}>
          {!roleProtected ? (
            <Tooltip
              title="Click to view & edit field"
              placement="top-start"
              PopperProps={{ disablePortal: true }}
            >
              <span className={styles.tableCellInput_text}>
                {symbolsLimit !== undefined ? (
                  <ClippedText
                    clipLimit={symbolsLimit}
                    text={renderValue || ''}
                  />
                ) : (
                  renderValue
                )}
              </span>
            </Tooltip>
          ) : (
            <span className={styles.tableCellInput_text}>
              {symbolsLimit !== undefined ? (
                <ClippedText
                  clipLimit={symbolsLimit}
                  text={renderValue || ''}
                />
              ) : (
                renderValue
              )}
            </span>
          )}
        </Grid>
        <Grid item>
          <ErrorTooltip opened={!!error} errorText={error} />
        </Grid>
      </Grid>
      <InputContainer
        title={label}
        isOpened={isEditing}
        isClearing={isClearing}
        onClose={handleContainerClose}
        onClear={handleContainerClear}
        onConfirm={handleContainerConfirm}
      >
        <TextField
          autoFocus
          name={name}
          onChange={handleInputChage}
          inputRef={inputRef}
          size="small"
          fullWidth
          variant="outlined"
          type={type === 'description' ? undefined : type}
          value={eventSnapshot ? eventSnapshot.target.value : value}
          inputProps={inputProps}
          {...(type === 'description'
            ? { multiline: true, rows: 4 }
            : undefined)}
        />
        {showMarginType ? (
          <Select
            name="marginType"
            fullWidth
            value={margin}
            onChange={handleMarginTypeChange}
          >
            {coCurrencies.map((unit) => (
              <MenuItem key={unit.key} value={unit.key}>
                {unit.value}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <></>
        )}
      </InputContainer>
    </React.Fragment>
  );
};
