import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './Navigation.module.scss';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { CONTACT_AND_SUPPORT, SETTINGS_TEAM_RIGHTS } from '@app/routes/urls';
import { useDispatch } from 'react-redux';
import { logoutAction } from '@app/store/auth';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import clsx from 'clsx';
import {
  settingsIcon,
  supportIcon,
  logoutIcon,
} from '@app/assets/images/pageIcon/icon';
import { navigationLinks } from './navigation-definition';
import classnames from 'classnames';
import { RoleProtectedLink } from '@app/shared/components/RoleBasedRoute';
import { FEATURE_SETTINGS } from '@app/modules/Settings';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appDrawer: {
      zIndex: `${theme.zIndex.appBar - 1} !important` as any,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      overflowX: 'hidden',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) - 10,
      },
    },
  })
);

interface Props {
  drawer: boolean;
  toggleDrawer: () => void;
}

export const Navigation: React.FC<Props> = ({ drawer, toggleDrawer }) => {
  const classes = useStyles(() => {});
  const router = useHistory();

  const dispatch = useDispatch();

  const handleLogoutClick = React.useCallback(() => {
    dispatch(logoutAction({}));
  }, [dispatch]);

  const memoizedLinks = React.useMemo(() => {
    return navigationLinks.map((link, index) => (
      <RoleProtectedLink
        to={link.link}
        feature={link.feature}
        className={styles.linkItem}
        key={index}
      >
        <Tooltip
          title={link.name}
          placement="right"
          disableFocusListener={drawer}
          disableHoverListener={drawer}
          disableTouchListener={drawer}
        >
          <ListItem
            className={classnames(styles.listItem, {
              [styles.listItemActive]: router.location.pathname === link.link,
            })}
            button
          >
            <ListItemIcon className={styles.iconWrapper}>
              {link.iconSrc}
            </ListItemIcon>
            <ListItemText primary={link.name} />
          </ListItem>
        </Tooltip>
      </RoleProtectedLink>
    ));
  }, [router, drawer]);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.appDrawer, classes.drawer, {
        [classes.drawerOpen]: drawer,
        [classes.drawerClose]: !drawer,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawer,
          [classes.drawerClose]: !drawer,
        }),
      }}
    >
      <Toolbar />
      <Grid
        container
        direction="column"
        className={styles.navigationContainer}
        wrap="nowrap"
      >
        <Grid item xs={12}>
          <List className={styles.listWrapper}>{memoizedLinks}</List>
        </Grid>
        <Grid item>
          <List className={styles.listWrapper}>
            <RoleProtectedLink
              to={SETTINGS_TEAM_RIGHTS.urlTemplate}
              feature={FEATURE_SETTINGS}
            >
              <Tooltip
                title={'Settings'}
                placement="right"
                disableFocusListener={drawer}
                disableHoverListener={drawer}
                disableTouchListener={drawer}
              >
                <ListItem
                  button
                  className={
                    router.location.pathname !==
                    SETTINGS_TEAM_RIGHTS.urlTemplate
                      ? styles.listItem
                      : `${styles.listItem} ${styles.listItemActive}`
                  }
                >
                  <ListItemIcon className={styles.iconWrapper}>
                    {settingsIcon}
                  </ListItemIcon>
                  <ListItemText primary={'Settings'} />
                </ListItem>
              </Tooltip>
            </RoleProtectedLink>
            <RoleProtectedLink to={'/contact-and-support'} feature={'' as any}>
              <Tooltip
                title={'Contact & Support'}
                placement="right"
                disableFocusListener={drawer}
                disableHoverListener={drawer}
                disableTouchListener={drawer}
              >
                <ListItem
                  className={
                    router.location.pathname !== CONTACT_AND_SUPPORT.urlTemplate
                      ? styles.listItem
                      : `${styles.listItem} ${styles.listItemActive}`
                  }
                  button
                >
                  <ListItemIcon className={styles.iconWrapper}>
                    {supportIcon}
                  </ListItemIcon>
                  <ListItemText primary={'Contact & Support'} />
                </ListItem>
              </Tooltip>
            </RoleProtectedLink>
            <RoleProtectedLink to={'#'} feature={'' as any}>
              <Tooltip
                title={'Log out'}
                placement="right"
                disableFocusListener={drawer}
                disableHoverListener={drawer}
                disableTouchListener={drawer}
              >
                <ListItem
                  onClick={handleLogoutClick}
                  button
                  className={styles.listItem}
                >
                  <ListItemIcon className={styles.iconWrapper}>
                    {logoutIcon}
                  </ListItemIcon>
                  <ListItemText primary={'Log out'} />
                </ListItem>
              </Tooltip>
            </RoleProtectedLink>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
};
