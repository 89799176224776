import {
  BaseMarginDataTable,
  CustomMargins,
  DefaultMargin,
  ExceptionHighlight,
  SetBulkBaseMargin,
  UpdateBaseMatrixDto,
} from '@app/shared/types/baseMargin';
import {
  fetchExceptionHighlightsAction,
  saveBaseMarginAction,
  setBaseMarginAction,
  setBaseMarginBulk,
  // setIndusties,
  setShowExceptionAction,
} from '@app/store/baseMargin';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseMatrixTable } from './BaseMatrixTable';
import {
  getBaseMarginDataSelector,
  getCOCurrencySelector,
  getExceptionHighlights,
  getShowException,
  getTotalBaseMargin,
} from '@app/store/baseMargin/selectors';
import Button from '@material-ui/core/Button';
import { BaseMarginFilters } from './BaseMatrixFilters';
import {
  getSelectedIndustries,
  getSelectedLevel4IndustriesIds,
  // setIndustiesDataAction,
  // setSelectedIndustries,
} from '@app/store/filters-module/industries';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import Switch from '@material-ui/core/Switch';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FilterContainer } from '@app/shared/components/FilterContainer';
import { validate } from '@app/shared/utils/validate';
import { schema } from './utils/validation';
import { EditMarginModal } from './EditMarginModal';
import { useHistory } from 'react-router';
// import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import qs from 'query-string';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { AppExportButton } from '@app/shared/components/AppExportButton';

import { setBackdropLoadingAction } from '@app/store/app/actions';

import { selectCoSelectedAllocationLevels, selectCoSelectedFilters, selectSelectedCo, setSelectedCo } from '@app/store/filters-module/co';

import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';
import { createIndustryGroup } from './utils/createIndustryGroups';
import { mapIndustryFiltersWithTableData } from './BaseMatrixTable/utils/mapIndustryFiltersWithTableData';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { RoleProtectedInput } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedInput';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import Tooltip from '@material-ui/core/Tooltip';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';


const useStyles = makeStyles(() => ({
  input: {
    height: '32px',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: '80px',
  },
  button: {
    lineHeight: '1.25',
    height: '52px',
  },
  filterContainer: {
    maxWidth: '320px',
  },
}));

export interface CurrentDataToUpdate {
  row: BaseMarginDataTable;
  isDefault: boolean;
  industrieId?: string;
}

export const BaseMatrix: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const baseMartrixCO = useSelector(selectSelectedCo);

  const selectedIndustiesIds: string[] = useSelector(
    getSelectedLevel4IndustriesIds
  );
  const showException = useSelector(getShowException);
  const coCurrency = useSelector(getCOCurrencySelector);

  const filtersValue: TFilterItemResponse[] = useSelector(
    selectCoSelectedFilters
  );
  const filter4Level = filtersValue.filter((f) => f.level === 4);

  const exceptionHighlights: ExceptionHighlight[] = useSelector(
    getExceptionHighlights
  );

  const currentCurrency = useMemo(() => {
    const currentCO = coCurrency.find((item) => item.id === baseMartrixCO?.id);
    return currentCO;
  }, [coCurrency, baseMartrixCO]);

  const [marginValues, setMarginValues] = useState<
    Record<string, CustomMargins>
  >({});

  const [defaultMargin, setDefaultMargin] = useState<
    Record<string, DefaultMargin>
  >({});

  const hasChangedData = useMemo(() => {
    return (
      !!Object.keys(defaultMargin).length || !!Object.keys(marginValues).length
    );
  }, [defaultMargin, marginValues]);

  const bulkMarginInitState = {
    value: '',
    type: currentCurrency?.coCurrencies[0].key || 0,
  };

  const [bulkMargin, setBulkMargin] = useState<{ value: string; type: number }>(
    bulkMarginInitState
  );
  const [bulkError, setBulkError] = useState<Record<string, string>>({});

  const [openEditModal, setOpenModalEdit] = useState(false);

  const [dataToRender, setDataToRender] = React.useState<BaseMarginDataTable[]>(
    []
  );
  const selectedIndustries = useSelector(getSelectedIndustries);
  const matrixDataTable = useSelector(getBaseMarginDataSelector);
  const total = useSelector(getTotalBaseMargin);

  const [industryFiltersMap, setIndustryFiltersMap] = React.useState<
    Record<number, { colSpan: number; industry: Industrie }[]>
  >({});
  useEffect(() => {
    if (!selectedIndustries.length) {
      setIndustryFiltersMap({});
      return;
    }
    const industryGroups = createIndustryGroup(selectedIndustries);
    setIndustryFiltersMap(industryGroups);
  }, [selectedIndustries]);
  const industrysMemo = useMemo(() => {
    return industryFiltersMap && industryFiltersMap[4]
      ? industryFiltersMap[4].map((item) => item.industry)
      : [];
  }, [industryFiltersMap]);

  React.useEffect(() => {

    const renderData = mapIndustryFiltersWithTableData(
      matrixDataTable,
      industrysMemo,
      exceptionHighlights
    );
    setDataToRender(renderData);
  }, [industrysMemo, matrixDataTable, exceptionHighlights]);

  const saveBaseMargin = useCallback(() => {
    const defaultMarginsData = Object.values(defaultMargin).map((item) => {
      return {
        productGroupId: item.productGroupId,
        valueAllocation: item.valueAllocation,
        value: item.value,
        type: item.type,
      };
    });
    const marginValuesData = Object.values(marginValues).map((item) => {
      return {
        productGroupId: item.productGroupId,
        industryId: item.industryId,
        value: item.value,
        type: item.type,
        valueAllocation: item.valueAllocation,
      };
    });
    if (baseMartrixCO) {
      const saveData: UpdateBaseMatrixDto = {
        coId: baseMartrixCO.id,
        defaultMargins: defaultMarginsData,
        customMargins: marginValuesData,
      };
      dispatch(saveBaseMarginAction({ payload: saveData }));
      setDefaultMargin({});
      setMarginValues({});
    }
  }, [baseMartrixCO, defaultMargin, dispatch, marginValues]);
  const [currentDataToUpdate, setCurrentDataToUpdate] = useState<
    CurrentDataToUpdate
  >();

  const onChangeMarginValue = useCallback(
    (data: CustomMargins | DefaultMargin, isDefault: boolean, id: string) => {
      if (isDefault) {
        if (currentDataToUpdate?.row.defaultMarginType !== data.type || data.value === '') {
          const customMargins = { ...marginValues };
          Object.keys(customMargins).forEach((k) => {
            if (customMargins[k].rowId === currentDataToUpdate?.row.id) {
              delete customMargins[k];
            }
          });
          setMarginValues(customMargins);
          const newData = matrixDataTable.map((i) => {
            const idUpdateData =
              (currentDataToUpdate?.row.id as string) +
              (currentDataToUpdate?.row.valueAllocation as number);
            const idRow = i.id + i.valueAllocation;

            if (idRow === idUpdateData) {
              const industries = i.industries.map((item) => {
                return {
                  ...item,
                  customMargin: '',
                  customMarginType: data.type,
                };
              });

              return {
                ...i,
                industries,
                defaultMargin: data.value,
                defaultMarginType: data.type,
              };
            }
            return i;
          });
          dispatch(
            setBaseMarginAction({
              payload: {
                data: newData,
                total: total,
              },
            })
          );
        }
        setDefaultMargin((state) => {
          return {
            ...state,
            [id]: data as DefaultMargin,
          };
        });
      }
      if (!isDefault) {
        setMarginValues((state) => {
          return {
            ...state,
            [id]: data as CustomMargins,
          };
        });
      }
    },
    [currentDataToUpdate, marginValues, dispatch, matrixDataTable, total]
  );
  const validToSave = useCallback(() => {
    if (Object.keys(defaultMargin).length || Object.keys(marginValues).length) {
      return false;
    }
    return true;
  }, [defaultMargin, marginValues]);

  const handleFilters = useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);

  const handleBulkMargin = useCallback((e: any) => {
    const value = e.target.value;

    setBulkMargin((state) => {
      return {
        ...state,
        value,
      };
    });
  }, []);

  const setAllMargin = useCallback(() => {
    const validBulk = validate(
      { marginType: bulkMargin.type, margin: bulkMargin.value },
      schema
    );
    if (Object.keys(validBulk).length) {
      setBulkError(validBulk);
      return;
    }
    setBulkError({});
    const dataToDb: SetBulkBaseMargin = {
      margin: bulkMargin.value,
    };
    setMarginValues({});

    dispatch(setBaseMarginBulk({ payload: dataToDb }));

    setBulkMargin(bulkMarginInitState);

  }, [bulkMargin.type, bulkMargin.value, dispatch]);

  const handleShowMargin = useCallback(async () => {

    if(!showException) {
      dispatch(fetchExceptionHighlightsAction({}));
    }
    dispatch(setShowExceptionAction({ payload: !showException }));
  }, [showException, dispatch]);

  const onCloseEditModal = useCallback(() => {
    setOpenModalEdit(false);
  }, []);

  // ADD HANDLE ROLE
  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;
  const onOpenEditModal = useCallback(
    (row: BaseMarginDataTable, isDefault: boolean, industrieId?: string) => {
          if (isDisabled ||
              ((!row.defaultMargin || row.defaultMargin === '') && !isDefault)) return;
      setCurrentDataToUpdate({
        row,
        isDefault,
        industrieId: industrieId ? industrieId : '',
      });
      setOpenModalEdit(true);
    },
    [isDisabled]
  );

  const onFetchDataAfterImportFile = useCallback(() => {
    dispatch(setSelectedCo({ co: baseMartrixCO }));
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
    dispatch(setBackdropLoadingAction({ payload: false }));
  }, [dispatch, history, baseMartrixCO]);

  const handlePromptConfirm = useCallback(() => {
    saveBaseMargin();
  }, [saveBaseMargin]);
  const handleDontSave = useCallback(() => {
    setDefaultMargin({});
    setMarginValues({});
  }, []);

  return (
    <>
      <Grid container direction="column" spacing={2} wrap="nowrap" item>
        <Grid item container justify="space-between" alignItems="center">
          <Grid item container spacing={2} wrap="nowrap">
            <Grid container alignItems="center" item xs={12} spacing={2}>
              <BaseMarginFilters
                handleFilters={handleFilters}
                shouldAskForConfirmation={hasChangedData}
                onConfirm={handlePromptConfirm}
                onSkip={handleDontSave}
              />
              <Grid item container spacing={2} alignItems="stretch" xs={4}>
                <Grid item classes={{ root: styles.filterContainer }}>
                  <FilterContainer>
                    <RoleProtectedInput
                      classes={{ root: styles.input }}
                      value={bulkMargin.value}
                      onChange={handleBulkMargin}
                      type="number"
                      error={bulkError?.margin ? true : false}
                      helperText={bulkError?.margin ? 'Please input value' : ''}
                      variant="standard"
                    />
                  </FilterContainer>
                </Grid>
                <Grid item classes={{ root: styles.filterContainer }}></Grid>
                <Tooltip
                  title={
                    !validToSave()
                      ? 'You have unsaved data. Save them for bulk setting.'
                      : ''
                  }
                  aria-label="Save your change"
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={setAllMargin}
                      classes={{ root: styles.button }}
                      disabled={bulkMargin.value === '' || !validToSave()}
                    >
                      Set margin
                    </Button>
                  </Grid>
                </Tooltip>
              </Grid>
              <Grid item container xs={2} alignItems="center">
                <Switch
                  checked={showException}
                  onChange={handleShowMargin}
                  color="primary"
                />
                <span>Show margin exceptions</span>
              </Grid>
            </Grid>

            <Grid item>
              <RoleProtectedButton
                color="primary"
                variant="contained"
                onClick={() => saveBaseMargin()}
                style={{ height: '100%' }}
                disabled={validToSave()}
              >
                Save
              </RoleProtectedButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container>
          <EditMarginModal
            open={openEditModal}
            onClose={onCloseEditModal}
            data={currentDataToUpdate}
            marginValues={marginValues}
            defaultMargin={defaultMargin}
            currentCurrency={currentCurrency}
            onChangeMarginValue={onChangeMarginValue}
          />
          <BaseMatrixTable
            onOpenEditModal={onOpenEditModal}
            marginValues={marginValues}
            defaultMargin={defaultMargin}
            currentCurrency={currentCurrency}
            showMarginExceptions={showException}
            dataToRender={dataToRender}
            industryFiltersMap={industryFiltersMap}
          />
        </Grid>
        <Grid item container spacing={1} justify="flex-end">
          <Grid item>
            <AppImportButton
              url={'/DataParser/UploadBaseMargin'}
              onFetchDataAfterImportFile={onFetchDataAfterImportFile}
            />
          </Grid>
          <Grid item>
            <AppExportButton
              url={'/CreateExcel/BaseMarginMatrix'}
              industryIds={selectedIndustiesIds}
              coId={baseMartrixCO?.id}
              isPost={true}
            />
          </Grid>
        </Grid>
        <PageLeavePrompt
          onSkip={handleDontSave}
          when={hasChangedData}
          onConfirm={handlePromptConfirm}
        />
      </Grid>
    </>
  );
};
