import * as React from 'react';
import { roleBasedRouteContext } from '../components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '../hooks/useAccountRoleRights';

//Should be used on component which will be used inside RoleBasedRoute
//Simply checks if component can be edited,otherwise pass down disabled: false

export function withEditPermissionCheck<
  TComponentProps extends { disabled?: boolean }
>(Component: React.ComponentType<TComponentProps>) {
  const WrappedComponent = function (props: TComponentProps) {
    const ctx = React.useContext(roleBasedRouteContext);
    if (!ctx || !ctx.feature) {
      // console.warn(
      //   'Attempt to use withEditpermissionCheck outside of context or feature is missing'
      // );
    }
    const { disabled, ...restProps } = props;

    const roleHooks = useAccountRoleRights(ctx.feature);
    const canEdit = roleHooks[1];
    const isDisabled = canEdit() ? disabled : true;

    return (
      <Component {...(restProps as TComponentProps)} disabled={isDisabled} />
    );
  };

  WrappedComponent.displayName = `${Component.displayName}(withEditPermissionCheck)`;

  return WrappedComponent;
}
