import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import {
  fetchBaseMargiMatrixIndustriesActions,
  getWizardBMMIndustriesFilterSelector,
} from '@app/store/wizard';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

interface IndustryIdSelectProps {
  handlerChange: (id: string | null, name: string | null) => void;
  industryId: string | null;
  industryName: string | null;
  disabled: boolean;
  level: number | null;
  productValue: number | null;
}

export const IndustrySelect = ({
  handlerChange,
  industryId,
  industryName,
  disabled,
  level,
  productValue,
}: IndustryIdSelectProps) => {
  const dispatch = useDispatch();

  //===== LOCAL STATE =====

  const [loading, setLoading] = useState(false);

  //===== DATA FROM STATE =====

  const filterIndustries = useSelector(getWizardBMMIndustriesFilterSelector);

  //===== HANDLERS =====

  const handlerOpen = () => {
    if (level !== null) {
      dispatch(fetchBaseMargiMatrixIndustriesActions({ payload: level }));
      setLoading(true);
    }
  };

  //===== WATCH UPDATE =====

  useEffect(() => {
    if (filterIndustries) setLoading(false);
  }, [filterIndustries]);

  return (
    <Autocomplete
      disabled={disabled}
      value={
        industryId === null
          ? null
          : {
              id: industryId,
              name: industryName || '',
            }
      }
      options={
        productValue !== null && filterIndustries[productValue]
          ? filterIndustries[productValue]
          : []
      }
      getOptionSelected={(i, value) => i.id === value.id}
      getOptionLabel={(i) => i.name}
      onOpen={handlerOpen}
      loading={loading}
      onChange={(e, value) =>
        handlerChange(value ? value.id : null, value ? value.name : null)
      }
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
