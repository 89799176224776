import {
  TAccountRightsFeatureList,
  TAccountRightsPermissions,
} from '@app/services/account-rights-service/response-interfaces/account-rights';
import { AppState } from '../store';

export const selectAccountPermissions = (store: AppState) =>
  store.accountRights;

export const selectAccountPermissionsByFeatureName = (
  store: AppState,
  feature: TAccountRightsFeatureList
): TAccountRightsPermissions | null | undefined => {
  return selectAccountPermissions(store)[feature];
};
