//===== TYPES =====

const setNameType = 'SET_NAME';
const setProductValueType = 'SET_PRODUCT_VALUE';
const setIndustryLevelType = 'SET_INDUSTRY_LEVEL';
const setIndustryIdType = 'SET_INDUSTRY_ID';
const setMarginValueType = 'SET_MARGIN_VALUE';
const resetExceptionType = 'RESET_EXCEPTION';
const setNameValidateType = 'SET_NAME_VALIDATE';
const setMarginValueValidateType = 'SET_MARGIN_VALUE_VALIDATE';

//===== STATE =====

export interface CreateExceptionStateType {
  name: string | null;
  productValue: number | null;
  industryLevel: number | null;
  industryId: string | null;
  industryName: string | null;
  marginValue: string | null;
  nameIsValid: boolean;
  marginValueIsValid: boolean;
}

export const initialCreateExceptionState: CreateExceptionStateType = {
  name: null,
  productValue: null,
  industryLevel: null,
  industryId: null,
  industryName: null,
  marginValue: '',
  nameIsValid: false,
  marginValueIsValid: false,
};

//===== REDUCER =====

export const createExceptionReducer = (
  state: CreateExceptionStateType,
  action: ActionType
): CreateExceptionStateType => {
  switch (action.type) {
    case setNameType:
      return { ...state, name: action.payload };
    case setProductValueType:
      return { ...state, productValue: action.payload, industryId: null };
    case setIndustryLevelType:
      return { ...state, industryLevel: action.payload, industryId: null };
    case setIndustryIdType:
      return {
        ...state,
        industryId: action.payload.id,
        industryName: action.payload.name,
      };
    case setMarginValueType:
      return { ...state, marginValue: action.payload };
    case resetExceptionType:
      return {
        ...state,
        name: null,
        productValue: null,
        industryLevel: null,
        industryId: null,
        marginValue: null,
      };
    case setNameValidateType:
      return { ...state, nameIsValid: action.payload };
    case setMarginValueValidateType:
      return { ...state, marginValueIsValid: action.payload };
    default:
      return state;
  }
};

//===== ACTIONS =====

export interface ActionType {
  type: string;
  payload?: any;
}

export const setNameAction = (name: string): ActionType => ({
  type: setNameType,
  payload: name,
});
export const setProductValueAction = (value: number | null): ActionType => ({
  type: setProductValueType,
  payload: value,
});
export const setIndustryLevelAction = (level: number | null): ActionType => ({
  type: setIndustryLevelType,
  payload: level,
});
export const setIndustryAction = (
  id: string | null,
  name: string | null
): ActionType => ({
  type: setIndustryIdType,
  payload: { id, name },
});
export const setMarginValueAction = (value: string): ActionType => ({
  type: setMarginValueType,
  payload: value,
});
export const resetExceptionAction = (): ActionType => ({
  type: resetExceptionType,
});
export const setNameValidateAction = (isValid: boolean): ActionType => ({
  type: setNameValidateType,
  payload: isValid,
});
export const setMarginValueValidateAction = (isValid: boolean): ActionType => ({
  type: setMarginValueValidateType,
  payload: isValid,
});
