const TOKEN_PATH = 'token';
const REFRESH_TOKEN_PATH = 'refresh_token';

export default class StorageUtils {
  static getAuthToken = (): string | null => {
    return window.localStorage.getItem(TOKEN_PATH);
  };

  static setAuthToken = (token: string) => {
    window.localStorage.setItem(TOKEN_PATH, token);
  };

  static getRefreshToken = (): string | null => {
    return window.localStorage.getItem(REFRESH_TOKEN_PATH);
  };

  static setRefreshToken = (token: string) => {
    window.localStorage.setItem(REFRESH_TOKEN_PATH, token);
  };

  static clearPair = () => {
    window.localStorage.removeItem(REFRESH_TOKEN_PATH);
    window.localStorage.removeItem(TOKEN_PATH);
  };
}
