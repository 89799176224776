import * as React from 'react';
import styles from './ProductListTable.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  productAllocation,
  standardPackingTableHeaders
} from '@app/modules/Simulator/data-definition';
import { Grid, Paper, Typography, Select, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWizardProductGroupInfoAction,
  getUpdateProductGroupInfoSelector,
  getWizardCurrentProductIdSelector,
  getWizardLookupVolumeSystemSelector,
  getWizardPackagingGroupSelector,
  getWizardProductGroupInfoWithFilterSelector,
  getWizardVolumeSystemByIdSelector,
  setWizardUpdateProductAllocationInfoAction,
} from '@app/store/wizard';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ProductGroupAllocationInfo } from '@app/shared/types/wizard';
import { WizardDispatchContext } from '@modules/Simulator/components/SimulatorWizard';
import { changeActiveStepStatusAction } from '../../../SimulatorWizard/store';
import isEmptyObject from '@shared/utils/isEmptyObject';
import { Alert } from '@material-ui/lab';
import WizardTableContainer from '@modules/Simulator/components/WizardTableContainer/WizardTableContainer';
import { UnitOption, unitOptions } from '@modules/VolumeDriver/SystemConfigurations/components/UnitSelect/unit-options';

interface IProps {
  canEdit: boolean
}

export const ProductListTable = ({canEdit}: IProps) => {
  const dispatch = useDispatch();

  //===== fetch base data =====
  const productId = useSelector(getWizardCurrentProductIdSelector);
  useEffect(() => {
    dispatch(fetchWizardProductGroupInfoAction({}));
  }, [productId, dispatch]); // fetch data after every product id of current value allocation change

  //===== store data =====
  const data = useSelector(getWizardProductGroupInfoWithFilterSelector);
  const volumeSystemById = useSelector(getWizardVolumeSystemByIdSelector);
  const volumeSystem = useSelector(getWizardLookupVolumeSystemSelector);
  const packagingGroup = useSelector(getWizardPackagingGroupSelector);

  //===== update data =====
  const newData = useSelector(getUpdateProductGroupInfoSelector);
  const setNewData = useCallback(
    (newData: Record<string, ProductGroupAllocationInfo>) => {
      dispatch(
        setWizardUpdateProductAllocationInfoAction({ payload: newData })
      );
    },
    [dispatch]
  );

  const handlerChangeData = (
    originValue: ProductGroupAllocationInfo,
    newValue: ProductGroupAllocationInfo
  ): void => {
    if (JSON.stringify(originValue) === JSON.stringify(newValue)) {
      delete newData[newValue.id + newValue.valueAllocation];
      setNewData({ ...newData });
    } else {
      setNewData({
        ...newData,
        [newValue.id + newValue.valueAllocation]: newValue,
      });
    }
  };

  //===== steps validate =====
  const wizardDispatch = useContext(WizardDispatchContext);
  const [tableErrorEmpty, setTableErrorEmpty] = useState(false);
  const [tableErrorMultiple, setTableErrorMultiple] = useState(false);

  // set step origin validate
  useEffect(() => {
    wizardDispatch(
      changeActiveStepStatusAction({
        originValidate: !data.reduce(
          (res: boolean, i) =>
            res ||
            !(i.volumeSystemIds.length === 1) ||
            !(i.standardPackagingIds.length === 1),
          false
        ),
        changed: false,
      })
    );
    setNewData({});
  }, [data, wizardDispatch, setNewData]);

  // set step current validate
  useEffect(() => {
    let isCurrentValidate = !data.reduce((res: boolean, i) => {
      const item = newData[i.id + i.valueAllocation]
        ? newData[i.id + i.valueAllocation]
        : i;
      return (
        res ||
        !(item.standardPackagingIds.length === 1) ||
        !(item.volumeSystemIds.length === 1)
      );
    }, false);

    let isCurrentValidateForNotEmpty = !data.reduce((res: boolean, i) => {
      const item = newData[i.id + i.valueAllocation]
        ? newData[i.id + i.valueAllocation]
        : i;
      return (
        res ||
        item.standardPackagingIds.length === 0 ||
        item.volumeSystemIds.length === 0
      );
    }, false);
    let isCurrentValidateForNotMultiple = !data.reduce((res: boolean, i) => {
      const item = newData[i.id + i.valueAllocation]
        ? newData[i.id + i.valueAllocation]
        : i;
      return (
        res ||
        item.standardPackagingIds.length > 1 ||
        item.volumeSystemIds.length > 1
      );
    }, false);

    wizardDispatch(
      changeActiveStepStatusAction({
        currentValidate: isCurrentValidate,
        changed: !isEmptyObject(newData),
      })
    );

    setTableErrorEmpty(!isCurrentValidateForNotEmpty);
    setTableErrorMultiple(!isCurrentValidateForNotMultiple);
  }, [data, newData, wizardDispatch]);

  return (
    <Grid container direction="column" style={{ margin: '2rem 0' }}>
      <Grid item>
        <Typography variant="subtitle1" style={{ margin: '0 0 1.5rem' }}>
          3. Define the standard packaging and the volume system for each
          product value level:
        </Typography>
      </Grid>
      <Grid item>
        <WizardTableContainer>
          <Table className={styles.table} stickyHeader={true}>
            <TableHead>
              <TableRow className={styles.row}>
                {standardPackingTableHeaders.map((i) => (
                  <TableCell key={i.key}>{i.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((i) => {
                const item = newData[i.id + i.valueAllocation]
                  ? newData[i.id + i.valueAllocation]
                  : i;
                const unit = unitOptions.find((u: UnitOption): boolean =>
                  item.volumeSystemIds.length === 1
                    ? u.value ===
                      volumeSystemById[item.volumeSystemIds[0]]?.unit
                    : false
                );
                const standardVolume =
                  item.volumeSystemIds.length === 1
                    ? `${volumeSystemById[
                        item.volumeSystemIds[0]
                      ]?.secondLevel.toFixed(2)} ${
                        volumeSystemById[item.volumeSystemIds[0]]?.secondLevel
                          ? unit?.name
                          : ''
                      }`
                    : '—';
                return (
                  <TableRow key={item.id + item.valueAllocation}>
                    <TableCell>{item.productGroupAllocName}</TableCell>
                    <TableCell>
                      {productAllocation[item.valueAllocation]}
                    </TableCell>
                    <TableCell>
                      <Select
                        error={item.standardPackagingIds.length !== 1}
                        displayEmpty
                        value={
                          item.standardPackagingIds &&
                          item.standardPackagingIds.length
                            ? item.standardPackagingIds.length > 1
                              ? 'multiple'
                              : item.standardPackagingIds[0] || 'default'
                            : 'default'
                        }
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) =>
                          handlerChangeData(i, {
                            ...item,
                            standardPackagingIds: [
                              event.target.value as string,
                            ],
                          })
                        }
                        disabled={!canEdit}
                      >
                        <MenuItem value="default" disabled>
                          Choose standard packaging
                        </MenuItem>
                        {item.standardPackagingIds.length > 1 && (
                          <MenuItem value="multiple" disabled>
                            Multiple
                          </MenuItem>
                        )}
                        {packagingGroup.map((i) => (
                          <MenuItem key={i.id} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select
                        error={item.volumeSystemIds.length !== 1}
                        displayEmpty
                        value={
                          item.volumeSystemIds?.length
                            ? item.volumeSystemIds.length > 1
                              ? 'multiple'
                              : item.volumeSystemIds[0] || 'default'
                            : 'default'
                        }
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) =>
                          handlerChangeData(i, {
                            ...item,
                            volumeSystemIds: [event.target.value as string],
                          })
                        }
                        disabled={!canEdit}
                      >
                        <MenuItem value="default" disabled>
                          Choose volume system
                        </MenuItem>
                        {item.volumeSystemIds.length > 1 && (
                          <MenuItem value="multiple" disabled>
                            Multiple
                          </MenuItem>
                        )}
                        {volumeSystem.map((i) => (
                          <MenuItem key={i.id} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>{standardVolume}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </WizardTableContainer>
      </Grid>
      {tableErrorEmpty && (
        <Grid item>
          <Paper style={{ margin: '1rem 0 0' }}>
            <Alert severity="error">
              Standard packaging and Volume System cannot be empty
            </Alert>
          </Paper>
        </Grid>
      )}
      {tableErrorMultiple && (
        <Grid item>
          <Paper style={{ margin: '1rem 0 0' }}>
            <Alert severity="error">
              Standard packaging and Volume System cannot be multiple
            </Alert>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
