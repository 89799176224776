import { RightsApi } from '@app/services/rights-service/rights-service';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import { setTeamRightsLoadingAction, updateTeamRightsAction } from '../actions';
import { fetchTeamRightsSagaWorker } from './fetchTeamRightsSagaWorker';

export function* updateTeamRightsSaga(
  action: ReturnType<typeof updateTeamRightsAction>
) {
  const { teamRights } = action;
  yield put(setTeamRightsLoadingAction({ loading: true }));
  try {
    yield call(RightsApi.updateTeamRights, teamRights);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Team rights successfully updated.',
          options: {
            variant: 'success',
          },
          key: 'team-rights-updated',
        },
      })
    );
    yield call(fetchTeamRightsSagaWorker);
  } catch (e) {
    console.log('Failed to update team rights', e.message);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to update team rights.',
          options: {
            variant: 'error',
          },
          key: 'team-rights-update',
        },
      })
    );
  } finally {
    yield put(setTeamRightsLoadingAction({ loading: false }));
  }
}
