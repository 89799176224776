import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { BulkProductLineDto } from './dto/bulk-product-line.dto';
import { CreateExceptionsDTO } from './dto/create-exceptions.dto';
import { GetExceptionsNegotiationDTO } from './dto/get-exceptions.items.dto';
import { GetProductLineItemsDto } from './dto/get-product-line-items.dto';
import { GetQuotesItemsDto } from './dto/get-quotes.dto';
import { SaveExceptionsNegotiationDTO } from './dto/save-exceptions.dto';
import { SaveQuotesDTO } from './dto/save-quotes.dto';
import { ResponseNegotitaionException } from './response-interfaces/negotiation-get-exception.response';
import {
  TGetProductLineItemsReponse,
  TProductLineItem,
} from './response-interfaces/negotiation-get-product-line.response';
import {
  MapResponseCurrency,
  ResponseCurrency,
} from './response-interfaces/negotiation-get-quotes-currency,response';
import { TQuotesItem } from './response-interfaces/negotiation-get-quotes.response';

class NegotiationRoomService extends AxiosRequestService {
  getProductLineItems = async (
    dto: GetProductLineItemsDto
  ): Promise<TGetProductLineItemsReponse> => {
    const data = await this.privateRequest.post<TGetProductLineItemsReponse>(
      '/Negotiation/Get',
      dto
    );
    return data.data;
  };
  updateProductLineItems = async (dto: TProductLineItem[]) => {
    await this.privateRequest.put('/Negotiation/Update', { data: dto });
  };
  bulkProductLineItems = async (dto: BulkProductLineDto) => {
    await this.privateRequest.put('/Negotiation/BulkUpdate', dto);
  };
  //Quotes

  getQuotesItems = async (dto: GetQuotesItemsDto): Promise<TQuotesItem[]> => {
    const request = await this.privateRequest.get<{ data: TQuotesItem[] }>(
      '/QuoteNegotiation/Get',
      {
        params: {
          coId: dto.coId,
        },
      }
    );
    return request.data.data;
  };
  saveQuotesItems = async (dto: SaveQuotesDTO) => {
    const data = { ...dto };
    if (data.items[0].id === '1') {
      data.items.forEach((i) => ((i.id as string | null) = null));
    }
    await this.privateRequest.put('/QuoteNegotiation/Update', dto);
  };
  getCurrencyCo = async (): Promise<MapResponseCurrency[]> => {
    const data = await this.privateRequest.get<{ data: ResponseCurrency[] }>(
      '/Filter/CommercialOrganizationsWithCurrency'
    );
    const mapData: MapResponseCurrency[] = data.data.data.map((i) => {
      return {
        id: i.id,
        currency: [...i.coSimpleCurrencies],
      };
    });
    return mapData;
  };

  //exceptions

  getExceptions = async (
    dto: GetExceptionsNegotiationDTO
  ): Promise<{
    data: ResponseNegotitaionException[];
    total: number;
  }> => {
    const result = await this.privateRequest.post<{
      data: ResponseNegotitaionException[];
      total: number;
    }>('/NegotiationException/Get', dto);
    return result.data;
  };
  saveExceptions = async (
    dto: SaveExceptionsNegotiationDTO[],
    coId: string
  ) => {
    await this.privateRequest.put('/NegotiationException/Update', {
      coId,
      items: dto,
    });
  };
  deleteExceptions = async (dto: string[], coId: string) => {
    await this.privateRequest.delete('/NegotiationException/Delete', {
      data: {
        ids: dto,
        coId,
      },
    });
  };
  createExceptions = async (dto: CreateExceptionsDTO, coId: string) => {
    await this.privateRequest.post('/NegotiationException/Create', {
      ...dto,
      coId,
    });
  };
}

export const NegotiationRoomApi = new NegotiationRoomService();
