import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

interface EditMarginModalProps {
  status: boolean;
  close: () => void;
  accept: () => void;
}

export const DeleteMarginModal = ({
  status,
  close,
  accept,
}: EditMarginModalProps) => {
  return (
    <Dialog open={status} onClose={close}>
      <DialogTitle id="alert-dialog-title">Delete margin</DialogTitle>
      <DialogContent>Are you sure you want to delete the margin?</DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button color="primary" onClick={accept} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
