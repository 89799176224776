import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { validateMarginValue } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/util/validateMarginValue';

interface EditMarginModalProps {
  status: boolean;
  value?: string;
  unitType: number | null;
  close: () => void;
  accept: (value: string) => void;
}

export const EditMarginModal = ({
  status,
  value,
  unitType,
  close,
  accept,
}: EditMarginModalProps) => {
  const [newValue, setNewValue] = useState('');

  //===== UPDATE NEW VALUE =====

  useEffect(() => {
    value && setNewValue(value);
  }, [setNewValue, value]);

  //===== VALIDATE =====

  const [isValid, setValid] = useState(false);

  useEffect(() => {
    setValid(unitType !== null && validateMarginValue(newValue, unitType));
  }, [unitType, newValue, setValid]);

  return (
    <Dialog open={status} onClose={close}>
      <DialogTitle id="alert-dialog-title">Edit margin</DialogTitle>
      <DialogContent>
        <TextField
          error={!isValid}
          fullWidth
          type="number"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Disagree</Button>
        <Button
          disabled={!isValid}
          color="primary"
          onClick={() => accept(newValue)}
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
