import { AppSelect } from '@app/shared/components/AppSelect';
import { SelectProps } from '@material-ui/core';
import * as React from 'react';
import { systemSelectOptions } from './system-select-options';

interface Props extends SelectProps {}

export const SystemSelect = (props: Props) => {
  const { value, ...restProps } = props;

  const [opened, setOpened] = React.useState<boolean>(false);

  const handleOpen = React.useCallback(() => {
    setOpened(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setTimeout(() => {
      if (opened) {
        setOpened(false);
      }
    }, 150);
  }, [opened]);

  const Options = React.useMemo(() => {
    return systemSelectOptions.map((option) => (
      <AppSelect.Option key={option.name} value={option.value}>
        {option.name}
      </AppSelect.Option>
    ));
  }, []);

  const PlaceholderOption = React.useMemo(() => {
    return (
      <AppSelect.Option value={'none'} hidden={opened}>
        Select system
      </AppSelect.Option>
    );
  }, [opened]);

  return (
    <AppSelect
      {...restProps}
      value={value}
      fullWidth
      onOpen={handleOpen}
      onClose={handleClose}
    >
      {PlaceholderOption}
      {Options}
    </AppSelect>
  );
};
