import { PACKAGING_DRIVER_CONFIGURATION } from '@app/routes/urls';
import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { fetchPackagingGroupsAction } from '@app/store/filters-module/packaging-groups/actions';
import { selectPackagingGroupsItems } from '@app/store/filters-module/packaging-groups/selectors';
import { LocationChangeAction } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';
import { fetchDriverConfigurationListAction } from '../actions';

export function* driverConfigurationWorkerSaga(action: LocationChangeAction) {
  if (
    PACKAGING_DRIVER_CONFIGURATION.match(action.payload.location.pathname, true)
      .isMatched &&
    action.payload.location.search
  ) {
    const packagingOptions: TPackagingOption[] = yield select(
      selectPackagingGroupsItems
    );

    if (!packagingOptions.length) {
      yield put(fetchPackagingGroupsAction({}));
    }

    const skip = getCurrentSkip();
    const limit = getCurrentPageLimit();
    yield put(fetchDriverConfigurationListAction({ skip, limit }));
  }
}
