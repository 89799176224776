import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { Action, combineReducers } from 'redux';
import { authReducer, logoutAction } from './auth';
import { userReducer } from './user';
import { productValueReducer } from './productValue';
import { managersReducer } from './managers';
import { rootAppReducer } from './app';
import { productsReducer } from './products';
import { snacksReducer } from './snacks';
import { settingsReducer } from './settings';
import { filtersModuleReducer } from './filters-module/reducer';
import { accountRightsReducer } from './account-rights';
import { baseMarginReducer } from './baseMargin';
import { packagingDriverReducer } from './driver/reducer';
import { volumeDriverReducer } from './volume-driver';
import { negotiationRoomReducer } from './negotiation-room';
import { confirmationModalReducer } from './confirmationModal';
import { wizardReducer } from '@app/store/wizard';
import { authProvider } from '@app/shared/utils/authProvider';

export const createRootReducer = (history: History) => {
  const appReducer = combineReducers({
    app: rootAppReducer,
    router: connectRouter(history),
    auth: authReducer,
    user: userReducer,
    productValue: productValueReducer,
    managers: managersReducer,
    filters: filtersModuleReducer,
    products: productsReducer,
    snacks: snacksReducer,
    settings: settingsReducer,
    accountRights: accountRightsReducer,
    driver: packagingDriverReducer,
    baseMarginReducer: baseMarginReducer,
    volumeDriver: volumeDriverReducer,
    negotiationRoom: negotiationRoomReducer,
    wizard: wizardReducer,
    confirmationModal: confirmationModalReducer,
  });

  return (state: any, action: Action) => {
    if (logoutAction.is(action)) {
      state = undefined;

      if (authProvider.getAccount()) {
        authProvider.logout();
      }
    }

    return appReducer(state, action);
  };
};

export type RootState = ReturnType<typeof createRootReducer>;
