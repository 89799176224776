import { call } from 'redux-saga/effects';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';
import { select } from 'redux-saga/effects';
import {
  fetchTPRTableDataAction,
  getTPRSelectedCOSelector,
  getTPRSelectedProductSelector,
  getTPRSelectedValueAllocationSelector,
  getWizardCurrentProductIdSelector
} from '@app/store/wizard';
import { getWizardBaseMarginMatrixSendingSelector } from '@app/store/wizard';
import WizardServices from '@services/wizard/wizardServices';
import { put } from 'redux-saga/effects';
import { fetchWizardBaseMarginMatrixAction } from '@app/store/wizard';

export function* sendWizardBaseMarginMatrixSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);
    const productId = yield select(getWizardCurrentProductIdSelector);
    const items = yield select(getWizardBaseMarginMatrixSendingSelector);


    const co = yield select(getTPRSelectedCOSelector);
    const product = yield select(getTPRSelectedProductSelector);
    const valueAllocation = yield select(getTPRSelectedValueAllocationSelector);

    const res = yield call(
      WizardServices.updateBaseMarginMatrix,
      coId,
      productId,
      items
    );
    if (res.status === 200) {
      yield put(fetchWizardBaseMarginMatrixAction({}));

      if (co && product && valueAllocation) {
        if (co.key === coId &&
          product.key == productId) {
          yield put(
            fetchTPRTableDataAction({
              payloads: {
                coId: co.key || '',
                productId: product.key || '',
                valuesAllocations: valueAllocation.key || '',
              },
            })
          );
        }
      }
    };
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
