import * as React from 'react';
import { AppSelectOption } from './AppSelectOption';
import classnames from 'classnames';
import styles from './AppSelect.module.scss';
import makeStyles from '@material-ui/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { SelectProps } from '@material-ui/core/Select';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
  },
});

export interface AppSelectProps<T> extends SelectProps {
  useAllOption?: boolean;
  defaultAllValue?: T[];
}

type Props<T> = AppSelectProps<T>;

export function AppSelect<TValue>(props: Props<TValue>) {
  const {
    className,
    children,
    placeholder = '',
    label = '',
    useAllOption = false,
    defaultAllValue,
    value,
    onChange,
    classes,
    ...selectProps
  } = props;
  const style = useStyles();

  if (useAllOption && !(defaultAllValue || Array.isArray(defaultAllValue))) {
    throw new Error(
      'defaultAllValue must be array type when useAllOption is used.'
    );
  }

  const [isAllSelected, setAllSelected] = React.useState<boolean>(useAllOption);

  const myOnChange = React.useCallback(
    (e: any, data: any) => {
      const isAll = Array.isArray(e.target.value)
        ? e.target.value[e.target.value.length - 1] === 'all'
        : false;
      if (!useAllOption) return onChange && onChange(e, data);
      if (!isAll) {
        e.target.value = e.target.value.filter((value: any) => value !== 'all');
        setAllSelected(false);
      } else {
        setAllSelected(true);
        e.target.value = defaultAllValue;
      }

      onChange && onChange(e, data);
    },
    [onChange, useAllOption, defaultAllValue]
  );

  return (
    <TextField
      size="small"
      select
      variant="outlined"
      fullWidth
      label={label}
      placeholder={placeholder}
      SelectProps={{
        ...selectProps,
        placeholder,
        onChange: myOnChange,
        value: isAllSelected ? ['all'] : value,
      }}
      classes={{ root: style.root, ...(classes ? classes : {}) }}
      className={classnames(styles.select, className)}
    >
      {children}
      {useAllOption && <AppSelectOption value="all">All</AppSelectOption>}
    </TextField>
  );
}

AppSelect.Option = AppSelectOption;
