import { defineActionGroup } from 'rd-redux-utils';

const appActionGroup = defineActionGroup('APP');

export const setAppLoadedAction = appActionGroup.defineAction<{
  loaded: boolean;
}>('SET APP LOADED');

export const setAppLoadingAction = appActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setBackdropLoadingAction = appActionGroup.defineAction<{
  payload: boolean;
}>('SET BACKDROP LOADING');
