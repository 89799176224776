import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWizardLookupVolumeSystemAction,
  getWizardLookupVolumeSystemSelector,
} from '@app/store/wizard';
import { CircularProgress, Grid } from '@material-ui/core';
import { VolumeSystemSelect } from './VolumeSystemSelect';
import { LookupValueSystem } from '@shared/types/wizard';
import { VolumeSystemTable } from '@modules/Simulator/components/SimulatorBasicParametersStep/components/VolumeSystemContainer/VolumeSystemTable';
import { getWizardCurrentAllocationLevelSelector } from '@app/store/wizard';

export const VolumeSystemContainer = () => {
  //===== fetch data =====
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWizardLookupVolumeSystemAction({}));
  }, [dispatch]);

  //===== data from store =====
  const volumeSystem = useSelector(getWizardLookupVolumeSystemSelector);
  const currentProduct = useSelector(getWizardCurrentAllocationLevelSelector);

  //===== filter data =====
  const [selectVolumeSystem, setSelectVolumeSystem] = useState<
    LookupValueSystem[]
  >([]);

  const handlerChangeVolumeSystem = useCallback((values) => {
    setSelectVolumeSystem(values);
  }, []);

  //===== loading options =====
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (volumeSystem.length) setLoading(false);
    else setLoading(true);
  }, [volumeSystem]);

  return currentProduct ? (
    loading ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    ) : (
      <Grid container direction="column" style={{ margin: '2rem 0' }}>
        <Grid item>
          <VolumeSystemSelect
            volumeSystem={volumeSystem}
            selectVolumeSystem={selectVolumeSystem}
            handlerChangeSelectVolumeSystem={handlerChangeVolumeSystem}
          />
        </Grid>
        <Grid item>
          <VolumeSystemTable
            volumeSystem={
              selectVolumeSystem.length ? selectVolumeSystem : volumeSystem
            }
          />
        </Grid>
      </Grid>
    )
  ) : null;
};
