import { Action } from 'redux';
import {
  setProductsDataAction,
  setProductsLoadingAction,
  setProductsSavingAction,
} from './actions';
import { initialState, ProductsState } from './state';

export function productsReducer(
  state = initialState,
  action: Action
): ProductsState {
  if (setProductsLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setProductsDataAction.is(action)) {
    return {
      ...state,
      items: action.payload,
      itemCount: action.count,
    };
  }

  if (setProductsSavingAction.is(action)) {
    return {
      ...state,
      isSaving: action.saving,
    };
  }

  return state;
}
