export interface AbstractFilter {
  id: string;
  level: number;
  name: string;
  parentId: string | null;
}

export type FilterMatrix = AbstractFilter[][];

export function buildFilterMatrix<T extends AbstractFilter>(
  filters: T[]
): FilterMatrix {
  const matrix: FilterMatrix = [];
  for (let i = 0; i < filters.length; i++) {
    const item = filters[i];
    const filterLevelAsMatrixIndex = item.level - 1;

    const matrixChild = matrix[filterLevelAsMatrixIndex];

    if (!matrixChild) {
      matrix[filterLevelAsMatrixIndex] = [item];
    } else matrixChild.push(item);
  }

  return matrix;
}
