import * as React from 'react';
import { StepLayout } from '../StepLayout';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { StepContentLayout } from '../StepContentLayout';
import {
  WizardDispatchContext,
  WizardStepComponentProps,
} from '../SimulatorWizard';
import { useContext, useEffect } from 'react';
import { changeActiveStepStatusAction } from '@modules/Simulator/components/SimulatorWizard/store';
import { useSelector } from 'react-redux';
import { getWizardCurrentProductNameSelector } from '@app/store/wizard';
import { BaseMarginMatrixContainer } from './components/BaseMarginMatrixContainer/BaseMarginMatrixContainer';
import { AddMarginModal } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/AddMarginModal/AddMarginModal';
import { useState } from 'react';

export const SimulatorBaseMarginMatrixStep = ({
  step,
  next,
  prev,
  canEdit
}: WizardStepComponentProps) => {
  const wizardDispatch = useContext(WizardDispatchContext);
  useEffect(() => {
    wizardDispatch(changeActiveStepStatusAction({ showed: true }));
  }, [wizardDispatch]);

  const productName = useSelector(getWizardCurrentProductNameSelector);
  //===== ADD MARGIN MODAL STATE =====

  const [showAddMarginModal, setAddMarginModal] = useState(false);

  const ModalButton = () => (
    <Button
      onClick={() => setAddMarginModal(true)}
      variant="contained"
      color="primary"
      disabled={!canEdit}
    >
      Add Margin
    </Button>
  );

  return (
    <StepLayout
      title="Configuration"
      subheader={`Base margin for ${productName}`}
      HeadSelector={ModalButton}
    >
      <StepContentLayout
        footer={
          <Grid item container direction="row" justify="flex-end" spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={prev}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={next}
                disabled={!step.currentValidate}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        }
      >
        <>
          <AddMarginModal
            status={showAddMarginModal}
            close={() => setAddMarginModal(false)}
            accept={() => setAddMarginModal(false)}
          />
          <Grid direction="column" container spacing={2}>
            <Grid item>
              <BaseMarginMatrixContainer 
                canEdit={canEdit}
              />
            </Grid>
          </Grid>
        </>
      </StepContentLayout>
    </StepLayout>
  );
};
