export const sortGroup1 = (data: any[], order: 'asc' | 'desc'): any[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.groupLevelOneName.toLowerCase();
    const bField = b.groupLevelOneName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
export const sortGroup2 = (data: any[], order: 'asc' | 'desc'): any[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.groupLevelTwoName.toLowerCase();
    const bField = b.groupLevelTwoName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
export const sortGroup3 = (data: any[], order: 'asc' | 'desc'): any[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.groupLevelThreeName.toLowerCase();
    const bField = b.groupLevelThreeName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
export const sortGroup4 = (data: any[], order: 'asc' | 'desc'): any[][] => {
  const sorting = (a: any, b: any) => {
    const aField = a.groupLevelFourName.toLowerCase();
    const bField = b.groupLevelFourName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};

export const sortUserName = (data: any[], order: 'asc' | 'desc'): any[][] => {
  const sorting = (a: any, b: any) => {
    const aField = a.userName.toLowerCase();
    const bField = b.userName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
