import { selectExportLoading } from '@app/store/app/selectors';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1000,
      color: '#fff',
    },
  })
);

export const AppBackdropLoader: React.FC = () => {
  const exportLoading = useSelector(selectExportLoading);
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={exportLoading}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
