import { publicAdapter } from '@app/shared/utils/request-service/adapters/public-adapter';
import { RequestService } from '@app/shared/utils/request-service/request-service';
import { LoginDto, RefreshTokenDto } from './dto';

// CANNOT EXTEND AXIOSREQUEST SERVICE SINCE ACCOUNT SERVICE BEING USED IN INTERCEPTORS BEFORE SERVICE INTIALIZATION
class AccountService {
  private publicRequest: RequestService;

  constructor() {
    this.publicRequest = new RequestService(publicAdapter);
  }

  async login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<LoginDto> {
    const body = {
      email,
      password,
      isRememberMe: rememberMe,
    };

    const request = await this.publicRequest.post<LoginDto>(
      '/account/login',
      body
    );
    return request.data;
  }

  async refreshToken(refreshToken: string): Promise<RefreshTokenDto> {
    
    const request = await this.publicRequest.post('/account/refreshtoken', {
      refreshToken,
    });

    return request.data;
  }

  async azureLogin(token: string): Promise<LoginDto> {
    const request = await this.publicRequest.post('/account/azurelogin', {
      token,
    });
    return request.data;
  }

  async changePassword(
    email: string,
    newPassword: string,
    code: string
  ): Promise<any> {
    const request = await this.publicRequest.post('/account/resetpassword', {
      password: newPassword,
      code,
      email,
    });

    return request.data;
  }

  async requestPasswordReset(email: string): Promise<any> {
    const request = await this.publicRequest.post('/account/forgotpassword', {
      email,
    });

    return request.data;
  }
}

export const AccountAPI = new AccountService();
