import React from 'react';
import TextField from '@material-ui/core/TextField';
import { CostFormItem } from '../../../store';
import { Grid } from '@material-ui/core';

interface CostFormTextFieldProps extends CostFormItem {
  current: string;
  handlerChange: (name: string, value: string) => void;
}

export const CostFormTextField = ({
  name,
  label,
  placeholder,
  current,
  handlerChange,
}: CostFormTextFieldProps) => {
  return (
    <Grid container item spacing={2}>
      <Grid item container justify="flex-end" alignItems="center" xs={6}>
        {label}:
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={placeholder}
          variant="outlined"
          type="number"
          value={current}
          name={name}
          size="small"
          fullWidth={true}
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value) >= 0 && Number(value) <= 999999.99) {
              handlerChange(name, value);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
