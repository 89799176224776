import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { getWizardBaseMarginMatrixSelector } from '@app/store/wizard';
import { useEffect } from 'react';
import { WizardBaseMarginEntryState } from '@app/shared/types/wizard';

interface NameFieldProps {
  isValid: boolean;
  setValid: (is: boolean) => void;
  handlerChange: (name: string) => void;
  name: string | null;
}

export const NameField = ({
  handlerChange,
  name,
  isValid,
  setValid,
}: NameFieldProps) => {
  //===== DATA FROM STORE =====

  const baseMarginMatrix = useSelector(getWizardBaseMarginMatrixSelector);

  //===== ERROR VALIDATE =====

  useEffect(() => {
    const error = baseMarginMatrix.some((base): boolean =>
      base.industryMargins.some(
        (i: WizardBaseMarginEntryState) => i.name === name
      )
    );

    setValid(!error);
  }, [baseMarginMatrix, name, setValid]);

  return (
    <TextField
      error={!isValid}
      fullWidth
      type="text"
      value={name === null ? '' : name}
      helperText={!isValid ? 'The name must be original' : ''}
      onChange={(e) => handlerChange(e.target.value)}
    />
  );
};
