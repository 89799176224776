import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { AppState } from '@app/store/store';
import { createSelector } from 'reselect';

export const selectCoState = (store: AppState) => store.filters.co;

export const selectCoLoadingState = (store: AppState) =>
  selectCoState(store).loading;

export const selectCoData = (store: AppState) => selectCoState(store).items;

export const selectSelectedCo = (store: AppState) =>
  selectCoState(store).selectedItem;

export const selectCoFilters = (store: AppState) =>
  selectCoState(store).filters;

export const selectCoFiltersId = (store: AppState) =>
  selectCoState(store).coFiltersId;

export const selectCoProductGroupFilters = (store: AppState) =>
  selectCoState(store).selectedFilters.filter((f) => f.level === 4);

export const selectCoProductFilters4Level = (store: AppState) =>
  selectCoState(store).filters.filter((f) => f.level === 4);

export const selectCoSelectedFilters = (store: AppState) =>
  selectCoState(store).selectedFilters;

export const selectCoAllocaitonLevels = (store: AppState) =>
  selectCoState(store).allocationLevels;

export const selectCoSelectedAllocationLevels = (store: AppState) =>
  selectCoState(store).selectedLevels;

export const selectCOFiltersMatrix = createSelector(
  selectCoFilters,
  (filters) => {
    const filterMatrix: TFilterItemResponse[][] = filters.reduce(
      (accum, filter) => {
        const matrixIndexFromLevel = filter.level - 1;

        if (!accum[matrixIndexFromLevel]) {
          accum[matrixIndexFromLevel] = [filter];
        } else {
          accum[matrixIndexFromLevel].push(filter);
        }

        return accum;
      },
      [] as TFilterItemResponse[][]
    );

    filterMatrix.sort((a, b) => a[0].level - b[0].level);

    return filterMatrix;
  }
);
