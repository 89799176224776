import * as React from 'react';
import styles from './RoleProtectedLink.module.scss';
import classnames from 'classnames';
import { AppLink, AppLinkProps } from '@app/shared/components/AppLink';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';

interface Props extends AppLinkProps {
  feature: TAccountRightsFeatureList;
  restrictedTitle?: string;
}

const NO_ACCESS_TEXT = "You don't have rights to visit this page";

export const RoleProtectedLink = (props: Props) => {
  const {
    feature,
    className,
    title,
    restrictedTitle = NO_ACCESS_TEXT,
    ...linkProps
  } = props;
  const [canVisit] = useAccountRoleRights(feature);

  const isCanVisit = React.useMemo(() => canVisit(), [canVisit]);

  return (
    <AppLink
      {...linkProps}
      title={isCanVisit ? title : restrictedTitle}
      className={classnames(className, { [styles.roleDisabled]: !isCanVisit })}
    />
  );
};
