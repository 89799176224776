import { TPackagingMarginListItem } from '@app/services/packaging-driver-service/response-interfaces/get-margin-list.response';

function createProductGroupSorter(level: number) {
  return (data: TPackagingMarginListItem[], order: 'asc' | 'desc') => {
    const sortedData = data.sort((a, b) => {
      const groupA = a.groupLevels[level - 1];
      const groupB = b.groupLevels[level - 1];

      if (order === 'asc') {
        return groupA?.name.localeCompare(groupB?.name || '') as number;
      }

      if (order === 'desc') {
        return groupB?.name.localeCompare(groupA?.name || '') as number;
      }

      return 0;
    });

    return sortedData;
  };
}

export const packagingMarginSortByGroupLevel: Record<
  number,
  (
    data: TPackagingMarginListItem[],
    order: 'asc' | 'desc'
  ) => TPackagingMarginListItem[]
> = {
  1: createProductGroupSorter(1),
  2: createProductGroupSorter(2),
  3: createProductGroupSorter(3),
  4: createProductGroupSorter(4),
  5: createProductGroupSorter(5),
};

export const packagingMarginAllocationSortByManager = (
  data: TPackagingMarginListItem[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.managerName ? a.managerName : '';
    const bLevel = b.managerName ? b.managerName : '';
    if (order === 'asc') {
      return aLevel.localeCompare(bLevel) as number;
    }

    if (order === 'desc') {
      return bLevel.localeCompare(aLevel) as number;
    }

    return 0;
  });
};

export const packagingMarginAllocationSortByAllocationLevel = (
  data: TPackagingMarginListItem[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.valueAllocationLevel;
    const bLevel = b.valueAllocationLevel;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};
