import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import { marginOptionsSagaWatcher } from '../../margin-options/saga/marginOptionsSagaWatcher';
import { volumeOptionsWatcherSaga } from '../../volume-options/saga/volumeOptionsWatcherSaga';
import {
  fetchDriverAllocationsAction,
  saveVolumeDriverAllocationBulkAction,
  saveVolumeDriverAllocationsAction,
} from '../actions';
import { fetchVolumeDrviverAllocationsSaga } from './fetchVolumeDriverAllocationsSaga';
import { volumeDriverAllocationsWorkerSaga } from './volumeDriverAllocationsWorkerSaga';
import { saveVolumeDriverAllocationsSaga } from './saveVolumeDriverAllocations';
import { saveVolumeDriverAllocationBulkSaga } from './saveVolumeDriverAllocationBulkSaga';

export function* volumeDriverAllocationsWatcherSaga() {
  yield all([
    takeEvery(LOCATION_CHANGE, volumeDriverAllocationsWorkerSaga),
    takeEvery(
      fetchDriverAllocationsAction.TYPE,
      fetchVolumeDrviverAllocationsSaga
    ),
    marginOptionsSagaWatcher(),
    volumeOptionsWatcherSaga(),
    takeEvery(
      saveVolumeDriverAllocationsAction.TYPE,
      saveVolumeDriverAllocationsSaga
    ),
    takeEvery(
      saveVolumeDriverAllocationBulkAction.TYPE,
      saveVolumeDriverAllocationBulkSaga
    ),
  ]);
}
