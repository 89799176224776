import { PackagingDriverApi } from '@app/services/packaging-driver-service/packaging-driver.service';
import { TGetPackagingConfigurationsResposne } from '@app/services/packaging-driver-service/response-interfaces/get-configurations.response';
import { call, put } from 'redux-saga/effects';
import {
  fetchDriverConfigurationListAction,
  setDriverConfigurationsDataAction,
  setDriverConfigurationsLoadingAction,
} from '../actions';

export function* fetchDriverConfigurationListSaga(
  action: ReturnType<typeof fetchDriverConfigurationListAction>
) {
  const { skip, limit } = action;

  try {
    yield put(setDriverConfigurationsLoadingAction({ loading: true }));
    const request: TGetPackagingConfigurationsResposne = yield call(
      PackagingDriverApi.getConfigurationsList,
      { skip, limit }
    );

    yield put(
      setDriverConfigurationsDataAction({
        items: request.data,
        total: request.total,
      })
    );
  } catch (e) {
    console.log('Failed to get configurations list', e.message);
  } finally {
    yield put(setDriverConfigurationsLoadingAction({ loading: false }));
  }
}
