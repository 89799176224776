import {
  PRODUCT_VALUE_SECTION_1,
  PRODUCT_VALUE_SECTION_2,
} from '@app/routes/urls';
import { ProductLabeling } from './components/ProductLabeling';
import { ProductLevels } from './components/ProductLevels';

export const productValueRoutes: any[] = [
  {
    title: 'Volume Driver Allocation',
    link: PRODUCT_VALUE_SECTION_1.urlTemplate,
    component: ProductLevels,
  },
  {
    title: 'System Configurations',
    link: PRODUCT_VALUE_SECTION_2.urlTemplate,
    component: ProductLabeling,
  },
];
