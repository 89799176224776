import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Auth.module.scss';
import { AuthForm, AuthFormData } from './components/AuthForm';
import { getFormError } from './error-map';
import { useDispatch } from 'react-redux';
import { setAuthAction } from '@app/store/auth';
import { ROOT_URL } from '@app/routes/urls';
import Dialog from '@material-ui/core/Dialog';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { AuthPageLayout } from '@app/shared/components/AuthPageLayout';
import { AccountAPI } from '@app/services/account-service/account-service';
import {  authProvider } from '@app/shared/utils/authProvider';
import { IdTokenResponse, } from 'react-aad-msal';
import { useEffect } from 'react';

export const Auth: React.FC = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [requestErrorText, setRequestErrorText] = React.useState('');
  const [isModalOpened, toggleModal] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleAuthSubmit = React.useCallback(
    async (formData: AuthFormData) => {
      setRequestErrorText('');
      setSubmitting(true);

      try {
        const request = await AccountAPI.login(
          formData.username,
          formData.password,
          formData.isRememberMe
        );

        dispatch(
          setAuthAction({
            accessToken: request.accessToken,
            refreshToken: request.refreshToken,
          })
        );

        history.push(ROOT_URL.urlTemplate);
      } catch (e) {
        setRequestErrorText(getFormError(e.response.status));
        setSubmitting(false);
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (authProvider.getAccount()) {
      authProvider.getIdToken().then((token: IdTokenResponse) => {
        if (token) {
          login(token);
        }
      }).catch(e => {
        setRequestErrorText('Azure login failed');
        setSubmitting(false);
      });
    }
  }, [])

  const handleAzureAuthSubmit = React.useCallback(
    async () => {
      setRequestErrorText('');

      try {
        authProvider
          .loginRedirect();
      } catch (e) {
        setRequestErrorText('Azure login failed');
        setSubmitting(false);
      }
    },
    [dispatch, history]
  );

  const login = (token: IdTokenResponse) => {
    if (token && token.idToken) {
      AccountAPI.azureLogin(
        token.idToken.rawIdToken,
      ).then((request) => {
        dispatch(
          setAuthAction({
            accessToken: request.accessToken,
            refreshToken: request.refreshToken,
          })
        );
        history.push(ROOT_URL.urlTemplate);
      }).catch((err) => {
        setRequestErrorText("Something wen't wrong, try again later.");
        setSubmitting(false);
      });
    } else {
      setRequestErrorText('Azure login failed');
      setSubmitting(false);
    }
  }

  return (
    <React.Fragment>
      <AuthPageLayout title="Sign in to start your session">
        <AuthForm
          onSubmit={handleAuthSubmit}
          isSubmitting={submitting}
          error={requestErrorText}
          onForgotPasswordClick={() => toggleModal(!isModalOpened)}
          azureLogin={handleAzureAuthSubmit}
        />
      </AuthPageLayout>
      <Dialog
        open={isModalOpened}
        className={styles.authPage_modal}
        onClose={() => toggleModal(false)}
      >
        <ForgotPasswordForm />
      </Dialog>
    </React.Fragment>
  );
};
