import { TProductLineItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-product-line.response';
import { defineActionGroup } from 'rd-redux-utils';

const productLineActionGroup = defineActionGroup('PRODUCT LINE');

export const fetchProductLineDataAction = productLineActionGroup.defineAction(
  'FETCH DATA'
);

export const setProductLineDataAction = productLineActionGroup.defineAction<{
  items: TProductLineItem[];
  total: number;
}>('SET DATA');

export const setProductLineLoadingAction = productLineActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setUpdateProductLineAction = productLineActionGroup.defineAction<{
  payload: TProductLineItem;
}>('SET UPDATE LINE');

export const resetUpdateProductLineAction = productLineActionGroup.defineAction(
  'RESET UPDATE LINE'
);

export const saveUpdateProductLineAction = productLineActionGroup.defineAction(
  'SAVE UPDATE ITEMS'
);

export const bulkProductLineAction = productLineActionGroup.defineAction<{
  payload: {
    limit: string;
    limitLevels: number;
  };
}>('BULK PRODUCT LINE');
