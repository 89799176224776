import { FilterApi } from '@app/services/filter-service/filter-service';
import { GetPackagingGroupsResponse } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import { call, put } from 'redux-saga/effects';
import {
  setPackagingGroupsDataAction,
  setPackagingGroupsLoadingAction,
  setSelectedPackagingGroupsAction,
} from '../actions';

export function* fetchPackagingGroupsSaga() {
  try {
    yield put(setPackagingGroupsLoadingAction({ loading: true }));

    const request: GetPackagingGroupsResponse = yield call(
      FilterApi.getPackagingGroups
    );

    yield put(setPackagingGroupsDataAction({ items: request.data }));
    yield put(setSelectedPackagingGroupsAction({ items: request.data }));
  } catch (e) {
    console.log('Failed to get packaging groups', e.message);
  } finally {
    yield put(setPackagingGroupsLoadingAction({ loading: false }));
  }
}
