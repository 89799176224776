import * as yup from 'yup';
/* eslint-disable */
const regExpAbsolute: RegExp = /^[1-9]?[0-9]{0,4}?\d((\.|\,)\d\d?)?$/;
const regExpProcent: RegExp = /^[1-9]?\d((\.|\,)\d([0-9]{0,1}?))?$/;

const arrScheme = yup.array().min(1);

export const schema = yup.object().shape({
  exceptionName: yup.string().required(),
  description: yup.string().required(),
  productGroupsLevel: yup.lazy(((value: any) => {
    const productShemes = Object.keys(value).reduce(
      (accum: any, current: any) => {
        return {
          ...accum,
          [current]: arrScheme,
        };
      },
      {} as Record<number, any>
    );
    return yup.object(productShemes);
  }) as any),
  industryGroups: yup.lazy(((value: any) => {
    const industryShemes = Object.keys(value).reduce(
      (accum: any, current: any) => {
        return {
          ...accum,
          [current]: arrScheme,
        };
      },
      {} as Record<number, any>
    );
    return yup.object(industryShemes);
  }) as any),

  marginType: yup.number(),
  margin: yup.lazy(((_value: string, obj: any) => {
    if (obj.parent.marginType === 0) {
      return yup.string().matches(regExpProcent).required();
    }
    return yup.string().matches(regExpAbsolute).required();
  }) as any),
  exceptionProductLevel: yup.number(),
  productsName: yup.lazy(((_value: any, obj: any) => {
    if (obj.parent && obj.parent.exceptionProductLevel === 6) {
      return arrScheme;
    }
    return yup.array();
  }) as any),
  valueAllocation: arrScheme,
});
