import { TProductLineItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-product-line.response';

export interface ProductLineState {
  items: TProductLineItem[];
  totalItems: number;
  isLoading: boolean;
  updateData: Record<string, TProductLineItem>;
}

export const initialState: ProductLineState = {
  isLoading: true,
  totalItems: 0,
  items: [],
  updateData: {},
};
