import { AppState } from '@app/store/store';

export const selectTeamRightsState = (store: AppState) =>
  store.settings.teamRights;

export const selectTeamRightsItems = (store: AppState) =>
  selectTeamRightsState(store).items;

export const selectTeamRightsLoading = (store: AppState) =>
  selectTeamRightsState(store).loading;

export const selectTeamRightsItemCount = (store: AppState) =>
  selectTeamRightsState(store).total;

export const selectTeamRightsSubmitting = (store: AppState) =>
  selectTeamRightsState(store).isSubmitting;

export const selectTeamRightsSubmitted = (store: AppState) =>
  selectTeamRightsState(store).isSubmitted;

export const selectTeamRightsLoadedState = (store: AppState) =>
  selectTeamRightsState(store).isLoaded;
