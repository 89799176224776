import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { VolumeDriverAllocationFilters } from './components/VolumeDriverAllocationFilters';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllocationLevels } from '@app/store/filters-module/filters-state';
import {
  selectDriverAllocationItems,
  selectDriverAllocationSavingState,
} from '@app/store/volume-driver/volume-driver-allocations/selectors';
import { VolumeDriverAllocationTable } from './components/VolumeDriverAllocationTable';
import {
  fetchDriverAllocationsAction,
  saveVolumeDriverAllocationBulkAction,
  saveVolumeDriverAllocationsAction,
} from '@app/store/volume-driver/volume-driver-allocations';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import { mapVolumeDriverTableDataToActionPayload } from './mapUtils';
import { VolumeSystemForm } from './components/VolumeSystemForm';
import { MarginSystemForm } from './components/MarginSystemForm';
import {
  selectCoFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { TVolumeDriverAllocation } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { useCallback } from 'react';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';

export const VolumeDriverAllocation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const filters = useSelector(selectCoFilters);
  const levels = useSelector(selectAllocationLevels);
  const selectedCo = useSelector(selectSelectedCo);
  const allocationItems = useSelector(selectDriverAllocationItems);

  const isAllocationSaving = useSelector(selectDriverAllocationSavingState);

  const [tableData, setTableData] = React.useState<
    Record<string, TVolumeDriverAllocation>
  >({});

  const handleChange = React.useCallback(
    (payload: TVolumeDriverAllocation) =>
      setTableData((prev) => ({ ...prev, [payload.id]: payload })),
    []
  );

  const handleFiltersChange = React.useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);

  const hasChangedData = React.useMemo(() => {
    return !!Object.keys(tableData).length;
  }, [tableData]);

  const saveVolumeDriverAllocation = React.useCallback(() => {
    if (!selectedCo) return;

    dispatch(
      saveVolumeDriverAllocationsAction(
        mapVolumeDriverTableDataToActionPayload(selectedCo.id, tableData)
      )
    );
  }, [tableData, selectedCo, dispatch]);

  const handleSaveClick = React.useCallback(() => {
    saveVolumeDriverAllocation();
    setTableData({});
  }, [saveVolumeDriverAllocation]);

  const handleVolumeSystemSubmit = React.useCallback(
    (systemId: string) => {
      setTableData({});
      dispatch(
        saveVolumeDriverAllocationBulkAction({
          systemType: 'vs',
          systemId,
        })
      );
    },
    [dispatch]
  );

  const handleMarginSystemSubmit = React.useCallback(
    (systemId: string) => {
      dispatch(
        saveVolumeDriverAllocationBulkAction({
          systemType: 'ms',
          systemId,
        })
      );
    },
    [dispatch]
  );

  const tableItems = React.useMemo(() => {
    return allocationItems.map((item) => ({ ...item, ...tableData[item.id] }));
  }, [tableData, allocationItems]);

  const [isValidTable, setValidTable] = React.useState<boolean>(true);

  const handleError = React.useCallback((error) => setValidTable(error), []);

  const isSaveDisabled = React.useMemo(
    () =>
      [isValidTable, !hasChangedData, isAllocationSaving].some(
        (truthy) => truthy
      ),
    [isValidTable, hasChangedData, isAllocationSaving]
  );
  const onFetchDataAfterImportFile = useCallback(() => {
    dispatch(fetchDriverAllocationsAction({}));
    dispatch(setBackdropLoadingAction({ payload: false }));
  }, [dispatch]);

  const handlePromptConfirm = React.useCallback(() => {
    if (!selectedCo) return;

    if (!hasChangedData) return;

    saveVolumeDriverAllocation();
    setTableData({});
  }, [saveVolumeDriverAllocation, selectedCo, hasChangedData]);

  const handleConfirmSkip = React.useCallback(() => {
    setTableData({});
  }, []);

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item xs={12}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item xs={5}>
                <VolumeDriverAllocationFilters
                  onSave={handlePromptConfirm}
                  allocationLevels={levels}
                  filters={filters}
                  onChange={handleFiltersChange}
                  shouldAskForConfirmation={hasChangedData}
                  onSkip={handleConfirmSkip}
                />
              </Grid>
              <Grid item>
                <VolumeSystemForm
                  onSubmit={handleVolumeSystemSubmit}
                  disabled={isAllocationSaving}
                />
              </Grid>
              <Grid item>
                <MarginSystemForm
                  onSubmit={handleMarginSystemSubmit}
                  disabled={isAllocationSaving}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              color="primary"
              onClick={handleSaveClick}
              style={{ height: '100%' }}
              disabled={isSaveDisabled}
            >
              Save
            </RoleProtectedButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <VolumeDriverAllocationTable
          items={tableItems}
          onError={handleError}
          onChange={handleChange}
        />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadVolumeDriver'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton
            url={`/CreateExcel/VolumeDriver?coId=${selectedCo?.id}`}
          />
        </Grid>
      </Grid>
      <PageLeavePrompt
        when={hasChangedData}
        onSkip={handleConfirmSkip}
        onConfirm={handlePromptConfirm}
      />
    </Grid>
  );
};
