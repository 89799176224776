import { UserRightsStatusType } from '.';

export const rightsHeaders: { key: string; name: string }[] = [
  { key: 'ProductValueLabeling', name: 'Product value labeling' },
  { key: 'BaseMarginMatrix', name: 'Base margin matrix' },
  { key: 'Exceptions', name: 'Exceptions' },
  { key: 'VolumeDriverSystemConfiguration', name: 'Systems configuration' },
  { key: 'VolumeDriverSystemAllocation', name: 'Volume driver allocation' },
  { key: 'PackagingDriverConfiguration', name: 'Packaging configuration' },
  { key: 'PackagingDriverMargins', name: 'Packaging margins' },
  { key: 'NegotiationRoomProductLine', name: 'Product line negotiations' },
  { key: 'NegotiationRoomQuotes', name: 'Quotes negotiations' },
  { key: 'NegotiationRoomExceptions', name: 'Negotiation exceptions' },
  { key: 'Wizard', name: 'Wizard' },
  { key: 'SettingsTeamsRights', name: 'Team rights' },
  { key: 'SettingsUserRights', name: 'User rights' },
  { key: 'SettingsProductRights', name: 'Product group rights' },
  { key: 'SettingsValueRights', name: 'Product value rights' },
  { key: 'ContactAndSupport', name: 'Contact & support' },
];

export const statusTitle: Record<UserRightsStatusType, string> = {
  1: 'Permanent',
  2: 'Temporary',
};
