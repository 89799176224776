import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';
import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import {
  RoleBasedRouteContext,
  roleBasedRouteContext,
} from './roleBasedRouteContext';

export interface RoleBasedRouteProps extends RouteProps {
  feature?: TAccountRightsFeatureList;
}

type Props = RoleBasedRouteProps;

export const RoleBasedRoute = (props: Props) => {
  const { feature, ...routeProps } = props;
  const ctx: RoleBasedRouteContext = React.useMemo(() => {
    return {
      feature: feature || null,
    };
  }, [feature]);

  return (
    <roleBasedRouteContext.Provider value={ctx}>
      {<Route {...routeProps} />}
    </roleBasedRouteContext.Provider>
  );
};
