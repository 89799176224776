import { AppExportButton } from '@app/shared/components/AppExportButton';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useMemo } from 'react';
import { ProductLevelsHeader } from './components/ProductLevelsHeader';
import { HierarchiesTable } from './components/Table';
import { useHistory } from 'react-router';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import qs from 'query-string';
import { PRODUCT_VALUE_SECTION_2 } from '@app/routes/urls';
import { useSelector, useDispatch } from 'react-redux';
import { selectUpdateLevel } from '@app/store/productValue/selectors';
import {
  resetUpdateLevelAction,
  updateAllocationsLevelAction,
} from '@app/store/productValue';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';

export const ProductLevels: React.FC = () => {
  const dispatch = useDispatch();
  const updateData = useSelector(selectUpdateLevel);
  const hasChangedData = useMemo(() => {
    return !!Object.keys(updateData).length;
  }, [updateData]);
  const history = useHistory();
  const onFetchDataAfterImportFile = useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);
  const handleNextClick = () => {
    history.push({
      pathname: PRODUCT_VALUE_SECTION_2.urlTemplate,
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  };
  const handlePromptConfirm = useCallback(() => {
    dispatch(updateAllocationsLevelAction({}));
  }, [dispatch]);
  const handleDontSave = useCallback(() => {
    dispatch(resetUpdateLevelAction({}));
  }, [dispatch]);
  return (
    <>
      <ProductLevelsHeader
        shouldAskForConfirm={hasChangedData}
        onConfirm={handlePromptConfirm}
        onSkip={handleDontSave}
      />
      <Grid item xs={12} style={{ flex: '1', display: 'flex' }}>
        <HierarchiesTable />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadProductValueFirstSection'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton url={'/CreateExcel/ProductValueFirstSection'} />
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleNextClick}>
            {'Next'}
          </Button>
        </Grid>
      </Grid>
      <PageLeavePrompt
        onSkip={handleDontSave}
        when={hasChangedData}
        onConfirm={handlePromptConfirm}
      />
    </>
  );
};
