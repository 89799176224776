import { NEGOTIATIONS_QUOTES_URL } from '@app/routes/urls';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { MapResponseCurrency } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes-currency,response';
import {
  fetchCoAction,
  selectSelectedCo,
  setSelectedCo,
} from '@app/store/filters-module/co';
import { LocationChangeAction } from 'connected-react-router';
import { put, select, take } from 'redux-saga/effects';
import {
  fetchCurrencyCOAction,
  fetchQuotesDataAction,
  setCurrencyCOAction,
} from '../action';
import { selectQuotesCurrencyCO } from '../selectors';

export function* quotesWorkerSaga(action: LocationChangeAction) {
  const { pathname, search } = action.payload.location;

  if (NEGOTIATIONS_QUOTES_URL.match(pathname, true).isMatched && search) {
    const selectedCo: TCommercialOrganization | null = yield select(
      selectSelectedCo
    );
    const currencyCO: MapResponseCurrency[] = yield select(
      selectQuotesCurrencyCO
    );
    if (!currencyCO.length) {
      yield put(fetchCurrencyCOAction({}));
      yield take(setCurrencyCOAction.TYPE);
    }
    if (!selectedCo) {
      yield put(fetchCoAction({}));
      yield take(setSelectedCo.TYPE);
    }

    yield put(fetchQuotesDataAction({}));
  }
}
