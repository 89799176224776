import { call } from 'redux-saga/effects';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';
import { select } from 'redux-saga/effects';
import {
  getWizardCurrentProductIdSelector,
  setPackagingMarginOriginAction,
  setPackagingMarginStateAction,
} from '@app/store/wizard';
import { getValueAllocationQuerySelector } from '@app/store/wizard';
import WizardServices from '@services/wizard/wizardServices';
import { put } from 'redux-saga/effects';
import { pmOriginToState } from '../utils/convetres';
import { getDefaultProductGroupSelector } from '@app/store/wizard';

export function* fetchWizardPackagingMarginSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);
    const productId = yield select(getWizardCurrentProductIdSelector);
    const valuesAllocations = yield select(getValueAllocationQuerySelector);

    const packagingMarginOrigin = yield call(
      WizardServices.getPackagingMargin,
      coId,
      [productId],
      valuesAllocations
    );
    yield put(
      setPackagingMarginOriginAction({
        payload: packagingMarginOrigin.data,
      })
    );

    const defaultPackagingGroup = yield select(getDefaultProductGroupSelector);

    yield put(
      setPackagingMarginStateAction({
        payload: pmOriginToState(
          packagingMarginOrigin.data,
          defaultPackagingGroup
        ),
      })
    );
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
