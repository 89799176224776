import { BaseMarginExceptionDataTable } from '@app/shared/types/baseMargin';

export const sortGroup1 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.groupLevelOneName.toLowerCase();
    const bField = b.groupLevelOneName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortGroup2 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.groupLevelTwoName.toLowerCase();
    const bField = b.groupLevelTwoName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortGroup3 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.groupLevelThreeName.toLowerCase();
    const bField = b.groupLevelThreeName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortGroup4 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.groupLevelFourName.toLowerCase();
    const bField = b.groupLevelFourName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortMainGroup = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.mainGroupName.toLowerCase();
    const bField = b.mainGroupName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortIndustry1 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.industryLevelOneName.toLowerCase();
    const bField = b.industryLevelOneName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortIndustry2 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.industryLevelTwoName.toLowerCase();
    const bField = b.industryLevelTwoName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortIndustry3 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.industryLevelThreeName.toLowerCase();
    const bField = b.industryLevelThreeName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortIndustry4 = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.industryLevelFourName.toLowerCase();
    const bField = b.industryLevelFourName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortProductValueAllocation = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.valueAllocationLevel;
    const bField = b.valueAllocationLevel;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortExceptionName = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.name.toLowerCase();
    const bField = b.name.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortDescription = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.description.toLowerCase();
    const bField = b.description.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortProductName = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.productName.toLowerCase();
    const bField = b.productName.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortProductNumber = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.productNumber.toLowerCase();
    const bField = b.productNumber.toLowerCase();

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortTargetMarginException = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = +a.margin;
    const bField = +b.margin;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortValidFrom = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.validFrom;
    const bField = b.validFrom;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortValidTo = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.validTo;
    const bField = b.validTo;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};

export const sortReminder = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.reminder;
    const bField = b.reminder;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortStatus = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.status;
    const bField = b.status;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortEnteredByUser = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.enteredByUser;
    const bField = b.enteredByUser;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};
export const sortCreationDate = (
  data: BaseMarginExceptionDataTable[],
  order: any
): BaseMarginExceptionDataTable[] => {
  const sorting = (
    a: BaseMarginExceptionDataTable,
    b: BaseMarginExceptionDataTable
  ) => {
    const aField = a.creationDate;
    const bField = b.creationDate;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort(
    (a: BaseMarginExceptionDataTable, b: BaseMarginExceptionDataTable) => {
      return order === 'desc' ? -sorting(a, b) : sorting(a, b);
    }
  );
};