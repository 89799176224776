import { all, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { fetchUserRightsSagaWorker } from '@app/store/settings/user-rights/saga/fetchUserRightsSagaWorker';
import {
  createUserRightsItemAction,
  createUserTemporaryRightsItemAction,
  deleteUserRightsItemsAction,
  fetchCOReferenceDataAction,
  fetchTeamReferenceDataAction,
  fetchUserReferenceDataAction,
  fetchUserRightsDataAction,
  fetchUserForTempRightsAction,
  updateUserRightsItemsAction,
} from '..';
import { deleteUserRightsSaga } from '@app/store/settings/user-rights/saga/deleteUserRightsSaga';
import { updateUserRightsSaga } from '@app/store/settings/user-rights/saga/updateUserRightsSaga';
import { fetchTeamReferenceURSagaWorker } from '@app/store/settings/user-rights/saga/fetchTeamReferenceURSagaWorker';
import { fetchUserReferenceURSagaWorker } from '@app/store/settings/user-rights/saga/fetchUserReferenceURSagaWorker';
import { fetchCOReferenceURSagaWorker } from '@app/store/settings/user-rights/saga/fetchCOReferenceURSagaWorker';
import { createUserRightsSaga } from '@app/store/settings/user-rights/saga/createUserRightsSaga';
import { createUserTemporaryRightsSaga } from '@app/store/settings/user-rights/saga/createUserTemporaryRightsSaga';
import { fetchUserRightsSaga } from './fetchUserRightsSaga';
import { fetchUserForTempURSagaWorker } from './fetchUserForTempURSagaWorker';

export function* userRightsWatcherSaga() {
  yield all([
    takeEvery(fetchUserRightsDataAction.TYPE, fetchUserRightsSaga),
    takeEvery(deleteUserRightsItemsAction.TYPE, deleteUserRightsSaga),
    takeEvery(updateUserRightsItemsAction.TYPE, updateUserRightsSaga),
    takeEvery(createUserRightsItemAction.TYPE, createUserRightsSaga),
    takeEvery(
      createUserTemporaryRightsItemAction.TYPE,
      createUserTemporaryRightsSaga
    ),
    takeEvery(LOCATION_CHANGE, fetchUserRightsSagaWorker),
    takeEvery(
      fetchUserReferenceDataAction.TYPE,
      fetchUserReferenceURSagaWorker
    ),
    takeEvery(
      fetchUserForTempRightsAction.TYPE,
      fetchUserForTempURSagaWorker
    ),
    takeEvery(
      fetchTeamReferenceDataAction.TYPE,
      fetchTeamReferenceURSagaWorker
    ),
    takeEvery(fetchCOReferenceDataAction.TYPE, fetchCOReferenceURSagaWorker),
  ]);
}
