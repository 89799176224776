import * as React from 'react';
import styles from './ForgotPasswordForm.module.scss';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import { AccountAPI } from '@app/services/account-service/account-service';

export interface ForgotPasswordFormFormData {
  email: string;
}

interface Props {}

export const ForgotPasswordForm = (props: Props) => {
  const [email, setEmail] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const handleSubmit = React.useCallback(
    async (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSubmitting(true);
      closeSnackbar();

      try {
        await AccountAPI.requestPasswordReset(email);
        enqueueSnackbar(
          "Instructions for resetting your password has been sen't to provided email.",
          { variant: 'success' }
        );
      } catch (e) {
        enqueueSnackbar("Something wen't wrong, please try again later.", {
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [email, enqueueSnackbar, closeSnackbar]
  );

  return (
    <Box p={2} className={styles.forgotPasswordForm}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <p className={styles.forgotPasswordForm_title}>
            <b>Note:</b> Confirmation link will be sent to your email.
          </p>
        </Grid>
        <Grid item>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="example@mail.com"
                  required
                  value={email}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item>
                <Button
                  className={styles.forgotPasswordForm_btn}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
