import { AppFormDialog } from '@app/shared/components/AppFormDialog';
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { AppButton } from '@app/shared/components/AppButton';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVolumeSystemConfigurationCreatingState,
  selectVolumeSystemConfigurationModalOpenedState,
  setVolumeSystemConfigurationsModalOpenedAction,
} from '@app/store/volume-driver/volume-system-configurations';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  VolumeSystemForm,
  VolumeSystemFormData,
} from './components/VolumeSystemForm/VolumeSystemForm';

interface Props {
  onBack?: () => void;
  onSubmit: (payload: VolumeSystemFormData) => void;
}

const FORM_ID = 'VOLUME_SYSTEM_CONFIGURATION_FORM';

export const VolumeSystemFormDialog = (props: Props) => {
  const { onBack, onSubmit } = props;

  const dispatch = useDispatch();

  const isOpened = useSelector(selectVolumeSystemConfigurationModalOpenedState);
  const isLoading = useSelector(selectVolumeSystemConfigurationCreatingState);

  const closeModal = React.useCallback(() => {
    dispatch(setVolumeSystemConfigurationsModalOpenedAction({ opened: false }));
  }, [dispatch]);

  const handleBackClick = React.useCallback(() => {
    closeModal();

    onBack && onBack();
  }, [closeModal, onBack]);

  const handleSubmit = React.useCallback(
    (payload: VolumeSystemFormData) => {
      onSubmit(payload);
    },
    [onSubmit]
  );

  return (
    <AppFormDialog
      isOpened={isOpened}
      isLoading={isLoading}
      title="Add Volume System configuration"
      content={<VolumeSystemForm onSubmit={handleSubmit} formId={FORM_ID} />}
      actions={
        <Box p={2} width="100%">
          <Grid container wrap="nowrap">
            <Grid item xs={12}>
              <IconButton
                color="primary"
                title="Back to system selection"
                onClick={handleBackClick}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item container alignItems="center">
              <Grid container spacing={2} wrap="nowrap" alignContent="center">
                <Grid item>
                  <AppButton color="default" onClick={closeModal}>
                    Cancel
                  </AppButton>
                </Grid>
                <Grid item>
                  <RoleProtectedButton
                    type="submit"
                    color="primary"
                    form={FORM_ID}
                  >
                    <pre>Add system</pre>
                  </RoleProtectedButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};
