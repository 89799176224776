import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { TGetProductsDto } from './dto/get-products.dto';
import { TServiceProducts } from './response-interfaces/get-products.response';

class ProductService extends AxiosRequestService {
  getProducts = async (query: TGetProductsDto): Promise<TServiceProducts> => {
    const payload = {
      groups: query.groups,
      skip: query.skip,
      limit: query.limit,
    };

    const request = await this.privateRequest.post<TServiceProducts>(
      'Product/GetList',
      payload
    );
    return request.data;
  };
  setPageProductsLevel = async (data: {
    mainProducts: {
      valueAllocationLevel: number;
      mainProductId: string;
    }[];
    filterGroupIds: string[];
    productGroupValues: number[];
  }) => {
    await this.privateRequest.put('/Product/UpdateValueAllocations', data);
  };
}

export const ProductsApi = new ProductService();
