import { Action } from 'redux';
import { setUserAction, setUserLoadingAction } from './actions';
import { initialState, UserState } from './state';

export function userReducer(state: UserState = initialState, action: Action) {
  if (setUserAction.is(action)) {
    return {
      ...state,
      id: action.id,
      email: action.email,
      fullName: action.fullName,
    };
  }

  if (setUserLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  return state;
}
