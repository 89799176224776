import { WizardStep } from './components/SimulatorWizard/types';
import { SimulatorBasicParametersStep } from './components/SimulatorBasicParametersStep';
import { SimulatorPackagingMarginStep } from './components/SimulatorPackagingMarginStep';
import { SimulatorBaseMarginMatrixStep } from './components/SimulatorBaseMarginMatrixStep';
import { SimulatorVolumeMarginStep } from './components/SimulatorVolumeMarginStep';

export const steps: WizardStep[] = [
  {
    id: 'first',
    component: SimulatorBasicParametersStep,
    meta: {
      title: 'Set basic parameters',
      description: `
Select a product group allocation, then define the standard packaging and the volume system that apply to the selected group.
`,
    },
  },
  {
    id: 'second',
    component: SimulatorBaseMarginMatrixStep,
    meta: {
      title: 'Set base margin',
      description: `
For the product group allocation selected in step 1, now define the default target margin for each product value level and the exception(s) if any.
`,
    },
  },
  {
    id: 'third',
    component: SimulatorPackagingMarginStep,
    meta: {
      title: 'Set packaging margin',
      description: `
The product group allocation was selected in step 1, along with the standard packaging.

In the table, the standard packaging is shown with a "0%". This means no additional margin will be added to the base margin.<br/>
For each other packaging type, please enter the absolute margin that will be added to the base target margin per product value level defined in step 2.
`,
    },
  },
  {
    id: 'fourth',
    component: SimulatorVolumeMarginStep,
    meta: {
      title: 'Set volume margin',
      description: `
The product group allocation was selected in step 1, along with the volume systems for each product value label.

Now for each volume system selected, define the margin system that should apply.
`,
    },
  },
];
