import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TFilterAllocationLevel } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import { TGroupItem } from '@app/services/groups-service/response-interfaces/get-groups.response';
import { TPackagingMarginListItem } from '@app/services/packaging-driver-service/response-interfaces/get-margin-list.response';
import { AppTable } from '@app/shared/components/AppTable';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { TableCellInput } from '@app/shared/components/TableCellInput';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import { transformToPercentString } from '@app/shared/utils/transformUtils';
import { validate } from '@app/shared/utils/validate';
import { selectCOFiltersMatrix } from '@app/store/filters-module/co';
import { selectAllocationLevels } from '@app/store/filters-module/filters-state';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { packagingMarginSchema } from './packagingMarginSchema';
import styles from './PackagingMarginTable.module.scss';
import {
  packagingMarginAllocationSortByAllocationLevel,
  packagingMarginAllocationSortByManager,
  packagingMarginSortByGroupLevel,
} from './sortHelpers';

const { Head, Cell, HeadCell, Row, Body } = AppTable;

export interface PackagingMarginTableDataItem {
  productGroupId: string;
  packagingGroups: Record<string, string>; // groupId: marginValue
}

export type PackagingMarginTableData = Record<
  string,
  Record<string, TPackagingOption & { valueAllocation: number }>
>; // Record<TPackagingMarginListItem id, Record<TPackagingOption id, TPackagingOption>>

interface Props {
  loading?: boolean;
  totalItems: number;
  items: TPackagingMarginListItem[];
  packagingGroups: TPackagingOption[];
  value: PackagingMarginTableData;
  onChange: (data: PackagingMarginTableData) => void;
  co: TCommercialOrganization;
  isEmpty?: boolean;
  onErrors: (hasErrors: boolean) => void;
}

export const PackagingMarginTable = (props: Props) => {
  const {
    loading,
    items,
    totalItems,
    packagingGroups,
    onChange,
    value,
    co,
    isEmpty,
    onErrors,
  } = props;
  const allocationLevels = useSelector(selectAllocationLevels);
  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;
  const allocationLevelToValueMap = React.useMemo(() => {
    return allocationLevels.reduce((accum, level) => {
      accum[level.key] = level;

      return accum;
    }, {} as Record<number, TFilterAllocationLevel>);
  }, [allocationLevels]);

  const packagingGroupsInOrder = React.useMemo(() => {
    return [...packagingGroups].sort((a, b) => a.name.localeCompare(b.name));
  }, [packagingGroups]);

  const filtersMatrix = useSelector(selectCOFiltersMatrix);
  const errors = React.useMemo(() => {
    const itemsToValidationMap = items.map((item) => ({
      id: item.id,
      groups: item.packagingGroups.map((groupItem) => ({
        ...groupItem,
        value: groupItem.value,
        parentId: item.id,
      })),
    }));

    return validate(itemsToValidationMap, packagingMarginSchema);
  }, [items]);

  React.useEffect(() => {
    onErrors(!!Object.keys(errors).length);
  }, [errors, onErrors]);

  const handleMarginChange = (
    value: PackagingMarginTableData,
    item: TPackagingMarginListItem,
    packagingGroup: TPackagingOption,
    margin: string,
    marginType: number
  ) => {
    onChange({
      ...value,
      [item.id]: {
        ...value[item.id],
        [packagingGroup.id]: {
          ...packagingGroup,
          valueAllocation: item.valueAllocationLevel,
          value: margin,
          marginType,
        },
      },
    });
  };

  const renderTableBody = React.useCallback(
    (data: TPackagingMarginListItem[]) => {
      return data.map((item, index) => {
        const groupsLevelMap = item.groupLevels.reduce((accum, group) => {
          accum[group.level] = group;

          return accum;
        }, {} as Record<number, TGroupItem>);

        const packagingGroupsValue = item.packagingGroups.reduce(
          (accum, item) => {
            accum[item.id] = item;

            return accum;
          },
          {} as Record<string, TPackagingOption>
        );

        return (
          <Row key={item.id}>
            <Cell align="center">{item.managerName || '—'}</Cell>
            {filtersMatrix.map((_, index) => {
              const groupLevel = index + 1;

              const value = groupsLevelMap[groupLevel];

              return (
                <Cell
                  key={groupLevel}
                  style={!value ? { color: 'red' } : undefined}
                >
                  {value ? value.name : 'Not provided'}
                </Cell>
              );
            })}
            <Cell>
              {allocationLevelToValueMap[item.valueAllocationLevel].value}
            </Cell>
            <React.Fragment>
              {packagingGroupsInOrder.map((packagingGroup) => {
                const errorPath = `${item.id}.${packagingGroup.id}`;
                const packagingGroupValue = packagingGroupsValue[
                  packagingGroup.id
                ]
                  ? packagingGroupsValue[packagingGroup.id].value
                  : '';

                const packagingGroupMarginType = packagingGroupsValue[
                  packagingGroup.id
                ]
                  ? packagingGroupsValue[packagingGroup.id].marginType
                  : 0;

                const isNumericValue = packagingGroupValue !== '';

                return (
                  <Cell key={packagingGroup.id} style={{ minWidth: '100px' }}>
                    <TableCellInput
                      roleProtected={isDisabled}
                      name={packagingGroup.id}
                      type="number"
                      label={packagingGroup.name}
                      value={isNumericValue ? packagingGroupValue : ''}
                      marginType={packagingGroupMarginType}
                      transformToViewValue={(val) =>
                        transformToPercentString(val, packagingGroupMarginType)
                      }
                      error={errors[errorPath]}
                      isClearing={true}
                      showMarginType={true}
                      onChange={(obj: any) => {
                        handleMarginChange(
                          value,
                          item,
                          packagingGroup,
                          obj.value,
                          obj.marginType
                        );
                      }}
                      onClear={() => {
                        onChange({
                          ...value,
                          [item.id]: {
                            ...value[item.id],
                            [packagingGroup.id]: {
                              ...packagingGroup,
                              valueAllocation: item.valueAllocationLevel,
                              value: '',
                            },
                          },
                        });
                      }}
                    />
                  </Cell>
                );
              })}
            </React.Fragment>
          </Row>
        );
      });
    },
    [
      allocationLevelToValueMap,
      packagingGroupsInOrder,
      onChange,
      value,
      filtersMatrix,
      errors,
      isDisabled,
    ]
  );

  return (
    <AppTable
      data={items}
      dataCount={totalItems}
      loading={loading}
      tableLayout="auto"
      isEmpty={isEmpty}
    >
      <Head>
        <Row isHead>
          <HeadCell colSpan={6} />
          <HeadCell
            colSpan={packagingGroupsInOrder.length}
            align="center"
            className={styles.headCell}
          >
            {co.name}
          </HeadCell>
        </Row>
        <Row isHead>
          <HeadCell
            preWrap
            sortHandler={packagingMarginAllocationSortByManager}
          >
            Product Manager
          </HeadCell>
          <HeadCell
            sortHandler={packagingMarginSortByGroupLevel[1]}
            preWrap
            style={{ minWidth: '100px' }}
          >
            Product Group 1
          </HeadCell>
          <HeadCell
            sortHandler={packagingMarginSortByGroupLevel[2]}
            preWrap
            style={{ minWidth: '100px' }}
          >
            Product Group 2
          </HeadCell>
          <HeadCell
            sortHandler={packagingMarginSortByGroupLevel[3]}
            preWrap
            style={{ minWidth: '100px' }}
          >
            Product Group 3
          </HeadCell>
          <HeadCell
            sortHandler={packagingMarginSortByGroupLevel[4]}
            preWrap
            style={{ minWidth: '100px' }}
          >
            Product Group 4
          </HeadCell>
          <HeadCell
            preWrap
            sortHandler={packagingMarginAllocationSortByAllocationLevel}
          >
            Product Value
          </HeadCell>
          <React.Fragment>
            {packagingGroupsInOrder.map((group) => (
              <HeadCell
                preWrap
                className={styles.subHeadCell}
                key={group.id}
                style={{ minWidth: '100px' }}
              >
                {group.name}
              </HeadCell>
            ))}
          </React.Fragment>
        </Row>
      </Head>
      <Body
        contentColSpan={6 + packagingGroupsInOrder.length}
        noDataText={NO_FILTERS_DATA_TABLE_TEXT}
      >
        {renderTableBody}
      </Body>
    </AppTable>
  );
};
