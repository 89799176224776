import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call } from '@redux-saga/core/effects';
import { put, select } from 'redux-saga/effects';
import {
  deleteExceptionNegotiationAction,
  fetchExceptionsNegotiationDataAction,
  setExceptionsNegotiationLoadingAction,
} from '..';

export function* deleteExceptionsSaga(
  action: ReturnType<typeof deleteExceptionNegotiationAction>
) {
  try {
    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);
    yield put(setExceptionsNegotiationLoadingAction({ loading: true }));
    yield call(NegotiationRoomApi.deleteExceptions, action.ids, selectedCO.id);
    yield put(fetchExceptionsNegotiationDataAction({}));
  } catch (e) {
    console.log('Failed to delete exceptions items', e.message);
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'delete exception',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'delete exception',
        },
      })
    );
  } finally {
    yield put(setExceptionsNegotiationLoadingAction({ loading: false }));
  }
}
