import { setWizardProductGroupAllocationLevelsAction } from '../actions';
import { call, put } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';

export function* fetchWizardProductGroupAllocationLevelsWorkerSaga() {
  try {
    let coId = yield call(fetchCOIdWorkerSaga);
    const res = yield call(
      WizardServices.getProductGroupAllocationLevels,
      coId
    );
    yield put(
      setWizardProductGroupAllocationLevelsAction({ payload: res.data })
    );
  } catch (e) {
    console.error(
      `Failed to fetchWizardProductGroupAllocationLevelsWorkerSaga: ${e.toString()}`
    );
  }
}
