import {
  setUpdateBaseMarginException,
  fetchUpdateBaseMarginException,
} from '../actions';
import { put } from 'redux-saga/effects';

export function* updateExceptionDataWorkerSaga(
  action: ReturnType<typeof fetchUpdateBaseMarginException>
) {
  try {
    yield put(setUpdateBaseMarginException({ payload: action.payload }));
  } catch (e) {
    console.log(`Failed to update data base margin exception: ${e.toString()}`);
  }
}
