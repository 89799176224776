import StorageUtils from '@app/shared/utils/storage/storage-utils';
import { cancelSagasAction } from '@app/store/actions';
import {
  setAppLoadedAction,
  setAppLoadingAction,
} from '@app/store/app/actions';
import { put } from '@redux-saga/core/effects';

export function* logoutSaga() {
  StorageUtils.clearPair();
  yield put(cancelSagasAction({}));
  yield put(setAppLoadingAction({ loading: false }));
  yield put(setAppLoadedAction({ loaded: true }));
}
