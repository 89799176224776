import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';

export function buildIndustriesMapByLevel(
  industries: Industrie[]
): Record<number, Industrie[]> {
  const industriesMap = industries.reduce((accum, industry) => {
    const levelValueList = accum[industry.level];

    if (levelValueList) {
      accum[industry.level].push(industry);
    } else {
      accum[industry.level] = [industry];
    }

    return accum;
  }, {} as Record<number, Industrie[]>);

  return industriesMap;
}
