import { MapResponseCurrency } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes-currency,response';
import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';

export interface QuotesState {
  items: TQuotesItem[];
  totalItems: number;
  isLoading: boolean;
  currencyCO: MapResponseCurrency[];
  updateData: Record<string, TQuotesItem>;
}

export const initialState: QuotesState = {
  isLoading: true,
  totalItems: 0,
  items: [],
  currencyCO: [],
  updateData: {},
};
