import { TManager } from '@app/services/managers-service/response-interfaces/get-managers.response';

export type ManagersItem = TManager;

export interface ManagersState {
  loading: boolean;
  items: ManagersItem[];
  coId: string;
}

export const initialState: ManagersState = {
  loading: true,
  items: [],
  coId: '',
};
