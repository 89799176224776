import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export const Wooops = () => (
  <Grid container direction="column" alignItems="center">
    <Typography variant="h3" align="center">
      Woops.
    </Typography>
    <Typography variant="h4" align="center">
      Unfortunately you don't have permission to view this page :(
    </Typography>
  </Grid>
);
