import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWizardCOIdAction,
  getWizardCOIdSelector,
  getWizardCommercialOrganizationsSelector,
  setWizardCOIdAction,
} from '@app/store/wizard';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';

export const COIdSelector = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWizardCOIdAction({}));
  }, [dispatch]);
  const co = useSelector(getWizardCommercialOrganizationsSelector);
  const coId = useSelector(getWizardCOIdSelector);

  const handlerChangeCOId = (value: string): void => {
    dispatch(setWizardCOIdAction({ payload: value }));
  };
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="row"
      justify="flex-start"
      style={{ marginBottom: '2rem' }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1">1. Select a CO</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="wizard-coid-select">
            Commercial organization
          </InputLabel>
          <Select
            style={{ height: '60px', padding: '0 16px' }}
            labelId="wizard-coid-select"
            value={coId}
            label="Commercial organization"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
              handlerChangeCOId(event.target.value as string)
            }
            fullWidth
          >
            {co.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
