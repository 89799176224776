import moment from "moment";

export const parseDate = (date: Date) => {
    if (date) {
        return moment(date).format('DD-MM-YYYY');
    }
    return '';
};

export const parseTimeDate = (date: Date) => {
    if (date) {
        return moment(date).format('DD-MM-YYYY HH:mm:ss')
    }
    return '';
};

export const parseDateAsLocal = (date: Date) => {
    if (date) {
        const dateTimeStr = date.toString().replace("Z", "");
        return moment(dateTimeStr).format('DD-MM-YYYY')
    }
    return '';
};

export const validateExpiration = (dateFrom: Date, dateTo: Date) => {
    if (!dateFrom  || !dateTo) {
        return 1;
    }

    const parsedDateFrom = moment(dateFrom).utc();
    const parsedDateTo = moment(dateTo).utc();
    const currentDate = moment(moment().toString().concat("Z")).utc();

   const result = currentDate.isBetween(parsedDateFrom,parsedDateTo) 
    ? 2
    : 1;

    return result;
};