import {
  TAccountRightsFeatureList,
  TAccountRightsPermissions,
} from '@app/services/account-rights-service/response-interfaces/account-rights';

export type AccountRightsState = Record<
  TAccountRightsFeatureList,
  TAccountRightsPermissions | null
>;

export const initialState: AccountRightsState = {
  ProductValueLabeling: null,
  BaseMarginMatrix: null,
  VolumeDriver: null,
  VolumeDriverSystemConfiguration: null,
  VolumeDriverSystemAllocation: null,
  PackagingDriver: null,
  PackagingDriverConfiguration: null,
  PackagingDriverMargins: null,
  NegotiationRoom: null,
  NegotiationRoomProductLine: null,
  NegotiationRoomQuotes: null,
  NegotiationRoomExceptions: null,
  Wizard: null,
  Exceptions: null,
  Settings: null,
  SettingsProductRights: null,
  SettingsTeamsRights: null,
  SettingsUserRights: null,
  SettingsValueRights: null,
  ContactAndSupport: null,
};
