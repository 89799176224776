import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';
import { AppState } from '../store';

export const getBaseMarginDataSelector = (state: AppState) =>
  state.baseMarginReducer.baseMargin.data;

export const getTotalBaseMargin = (state: AppState) =>
  state.baseMarginReducer.baseMargin.total;

export const getBaseMarginLoadingSelector = (state: AppState) =>
  state.baseMarginReducer.loading;

export const getBaseMarginExceptionSelector = (state: AppState) =>
  state.baseMarginReducer.exeptions.data;

export const getExceptionTotal = (state: AppState) =>
  state.baseMarginReducer.exeptions.total;

export const getProductGroups = (state: AppState) =>
  state.baseMarginReducer.productGroups.data;

export const getProductsName = (state: AppState) =>
  state.baseMarginReducer.productsName.data;

export const getIndustries = (state: AppState) =>
  state.baseMarginReducer.industries;

export const getExceptionNameSelector = (state: AppState) =>
  state.baseMarginReducer.exceptionName;

export const getExceptionUpdateDataSelector = (state: AppState) =>
  state.baseMarginReducer.updateExceptionData;

export const getExceptions = (state: AppState) =>
  state.baseMarginReducer.currentExceptionIds;

  export const getExceptionHighlights = (state: AppState) =>
  state.baseMarginReducer.exceptionHighlights;

export const getCOCurrencySelector = (state: AppState) =>
  state.baseMarginReducer.currencyCO;

export const openModalSelector = (state: AppState) =>
  state.baseMarginReducer.openModal;

export const getShowException = (state: AppState) =>
  state.baseMarginReducer.showException;

export const selectFilterGroups = (state: AppState) =>
  state.baseMarginReducer.groupFilter.data;

export const selectCurrentIndustriesFilter = (state: AppState) => {
  const industriesIds = state.baseMarginReducer.industriesFilterIds;
  if (!industriesIds.length) return [];
  const allIndustries = state.filters.industries.items;
  const filters4level: Industrie[] = allIndustries.filter((f) => f.level === 4);
  const currentFilters: TFilterItemResponse[] = [];
  industriesIds.forEach((item) => {
    const find = filters4level.find((f) => f.id === item);
    if (find) {
      currentFilters.push(find);
    }
  });
  return currentFilters;
};
export const selectCurrentFilterSelected = (state: AppState) =>
  state.baseMarginReducer.currentSelectedFilters;
