import * as React from 'react';
import styles from './TargetPriceResults.module.scss';
import { TargetPriceResultsContainer } from './components/TargetPriceResultsContainer/TargetPriceResultsContainer';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';

export const TargetPriceResults = () => (
  <Box p={2}>
    <Grid container spacing={2} direction="column">
      <Grid item>
        <h2 className={styles.title}>Target price results</h2>
      </Grid>
      <Grid item>
        <TargetPriceResultsContainer />
      </Grid>
    </Grid>
  </Box>
);
