import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { TGetMarginSystemOptionsResponse } from './response-interfaces/get-margin-system-options.response';
import { TGetVolumeSystemOptionsResponse } from './response-interfaces/get-volume-system-options.response';
import {
  TGetVolumeDriverAllocationResponse,
  TVolumeDriverAllocation,
  TVolumeDriverAllocationResponse,
} from './response-interfaces/get-volume-driver-allocation.response';
import { GetVolumeDriverAllocationDto } from './dto/get-volume-driver-allocation.dto';
import { UpdateVolumeDriverAllocationDto } from './dto/update-volume-driver-allocation.dto';
import { UpdateVolumeDriverAllocationBulkDto } from './dto/update-volume-driver-allocation-bulk.dto';
import { TGetVolumeSystemConfigurationDto } from './dto/get-volume-system-configurations.dto';
import { TGetMarginSystemConfigurationsDto } from './dto/get-margin-system-configurations.dto';
import { TGetMarginSystemConfigurationsResponse } from './response-interfaces/get-margin-system-configurations.response';
import { CreateMarginSystemConfigurationDto } from './dto/create-margin-driver-system.dto';
import { CreateVolumeSystemConfigurationDto } from './dto/create-volume-driver-system.dto';
import { DeleteVolumeSystemConfigurationsDto } from './dto/delete-volume-system-configurations.dto';
import { DeleteMarginSystemConfigurationsDto } from './dto/delete-margin-system-configurations.dto';
import { UpdateVolumeSystemDto } from './dto/update-volume-system.dto';
import { transformToFloatString } from '@app/shared/utils/transformUtils';
import { UpdateMarginSystemDto } from './dto/update-margin-system.dto';
import { TGroupItem } from '../groups-service/response-interfaces/get-groups.response';
import { ValueAllocationIdComposer } from '@app/shared/utils/value-allocation-id-composer';

class VolumeDriverService extends AxiosRequestService {
  createMarginSystemConfiguration = async (
    dto: CreateMarginSystemConfigurationDto
  ): Promise<void> => {
    await this.privateRequest.post('MarginSystem/Create', dto);
  };

  updateMarginSystemConfigurations = async (dto: UpdateMarginSystemDto) => {
    await this.privateRequest.put('MarginSystem/Update', dto);
  };

  deleteMarginSystemConfigurations = async (
    dto: DeleteMarginSystemConfigurationsDto
  ) => {
    await this.privateRequest.delete('MarginSystem/DeleteByIds', { data: dto });
  };

  createVolumeSystemConfiguration = async (
    dto: CreateVolumeSystemConfigurationDto
  ): Promise<void> => {
    await this.privateRequest.post('VolumeSystem/Create', dto);
  };

  updateVolumeSystemConfigurations = async (dto: UpdateVolumeSystemDto) => {
    await this.privateRequest.put('VolumeSystem/Update', dto);
  };

  deleteVolumeSystemConfigurations = async (
    dto: DeleteVolumeSystemConfigurationsDto
  ) => {
    await this.privateRequest.delete('VolumeSystem/DeleteByIds', { data: dto });
  };

  getMarginSystemConfigurations = async (
    dto: TGetMarginSystemConfigurationsDto
  ): Promise<TGetMarginSystemConfigurationsResponse> => {
    const request = await this.privateRequest.post<
      TGetMarginSystemConfigurationsResponse
    >('MarginSystem/GetByPagination', dto);

    request.data.data = request.data.data.map((item) => ({
      ...item,
      firstLevel: transformToFloatString(item.firstLevel),
      secondLevel: transformToFloatString(item.secondLevel),
      thirdLevel: transformToFloatString(item.thirdLevel),
      fourthLevel: transformToFloatString(item.fourthLevel),
      fifthLevel: transformToFloatString(item.fifthLevel),
    }));

    return request.data;
  };

  getVolumeSystemConfigurations = async (
    dto: TGetVolumeSystemConfigurationDto
  ): Promise<TGetVolumeSystemOptionsResponse> => {
    const request = await this.privateRequest.post<
      TGetVolumeSystemOptionsResponse
    >('VolumeSystem/GetByPagination', dto);

    return request.data;
  };

  updateVolumeDriverAllocationBulk = async (
    dto: UpdateVolumeDriverAllocationBulkDto
  ) => {
    await this.privateRequest.put('VolumeDriver/BulkUpdateSystemByFilter', dto);
  };

  updateVolumeDriverAllocation = async (
    dto: UpdateVolumeDriverAllocationDto
  ): Promise<any> => {
    dto.items = dto.items.map((item) => ({
      ...item,
      productGroupId: ValueAllocationIdComposer.decompose(item.productGroupId)
        .id,
    }));

    await this.privateRequest.put(
      'VolumeDriver/UpdateVolumeDriverAllocation',
      dto
    );
  };

  getVolumeSystemOptions = async (): Promise<
    TGetVolumeSystemOptionsResponse
  > => {
    const request = await this.privateRequest.get<
      TGetVolumeSystemOptionsResponse
    >('VolumeSystem/GetIdName');

    request.data.data = request.data.data.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return request.data;
  };

  getMarginSystemOptions = async (): Promise<
    TGetMarginSystemOptionsResponse
  > => {
    const request = await this.privateRequest.get<
      TGetMarginSystemOptionsResponse
    >('MarginSystem/GetIdName');
    request.data.data = request.data.data.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return request.data;
  };

  getVolumeDriverAllocation = async (
    dto: GetVolumeDriverAllocationDto
  ): Promise<TGetVolumeDriverAllocationResponse> => {
    const request = await this.privateRequest.post<
      TGetVolumeDriverAllocationResponse<TVolumeDriverAllocationResponse>
    >('/VolumeDriver/GetVolumeDiverAllocations', dto);

    return this.normalizeVolumeDriverAllocationResponse(request.data);
  };

  private normalizeVolumeDriverAllocationResponse = (
    response: TGetVolumeDriverAllocationResponse<
      TVolumeDriverAllocationResponse
    >
  ): TGetVolumeDriverAllocationResponse<TVolumeDriverAllocation> => {
    const keyToLevelMap = {
      firstGroupName: 1,
      secondGroupName: 2,
      thirdGroupName: 3,
      fourthGroupName: 4,
    };
    const mapResult = {
      ...response,
      data: response.data.map((item) => {
        const result = {} as TVolumeDriverAllocation;

        result.id = ValueAllocationIdComposer.compose(item);
        result.managerName = item.productManagerName;
        result.valueAllocationLevel = item.valueAllocation;
        result.groupLevels = [...Object.keys(keyToLevelMap)].map(
          (key, index) => {
            const groupLevel: TGroupItem = {
              id: String(index),
              name: item[key as keyof typeof item] as string,
              level: keyToLevelMap[key as keyof typeof keyToLevelMap],
              parentId: null,
            };

            return groupLevel;
          }
        );
        result.marginSystem = item.marginSystem;
        result.volumeSystem = item.volumeSystem;

        return result;
      }),
    };

    return mapResult;
  };

  getDriverAllocationTypeByName = (type: 'ms' | 'vs'): number => {
    const map = {
      vs: 1,
      ms: 2,
    };

    const match = map[type];

    if (!match) throw new Error(`Invalid system type provided: ${type}`);

    return match;
  };
}

export const VolumeDriverApi = new VolumeDriverService();
