import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import { AppAutocomplete } from '@app/shared/components/AppAutocomplete';
import { AppDropdown } from '@app/shared/components/AppDropdown/AppDropdown';
import { AppSelect } from '@app/shared/components/AppSelect';
import { selectCoData } from '@app/store/filters-module/co';
import { selectPackagingGroupsItems } from '@app/store/filters-module/packaging-groups/selectors';
import Chip from '@material-ui/core/Chip';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setFiltersIsFullListAction } from '@app/store/filters-module/filters-state';

interface Props {
  selectedCo: string;
  selectedPackagingGroups: TPackagingOption[];
  disabled?: boolean;
  onChange: (payload: {
    coId: string;
    packagingGroups: TPackagingOption[];
  }) => void;
}

export const PackagingFilter = (props: Props) => {
  const { disabled, selectedCo, selectedPackagingGroups, onChange } = props;
  const dispatch = useDispatch();

  const coList = useSelector(selectCoData);
  const packagingGroups = useSelector(selectPackagingGroupsItems);

  const autocompleteAllValue = React.useMemo(() => [...packagingGroups], [
    packagingGroups,
  ]);

  useEffect(() => {
    dispatch(setFiltersIsFullListAction({ isFullList: false }));
  }, [dispatch]);

  const handleCoSelectChange = React.useCallback(
    (e: any) => {
      onChange({
        packagingGroups: selectedPackagingGroups,
        coId: e.target.value,
      });
    },
    [selectedPackagingGroups, onChange]
  );

  const handleAutocomplete = React.useCallback(
    (value: TPackagingOption[]) => {
      onChange({ packagingGroups: value, coId: selectedCo });
    },
    [selectedCo, onChange]
  );

  return (
    <AppDropdown head="CO & Packaging group" disabled={disabled}>
      <AppDropdown.Item title="CO">
        <AppSelect value={selectedCo} onChange={handleCoSelectChange}>
          {coList.map((co) => (
            <AppSelect.Option key={co.id} value={co.id}>
              {co.name}
            </AppSelect.Option>
          ))}
        </AppSelect>
      </AppDropdown.Item>
      <AppDropdown.Item title="Packaging group">
        <AppAutocomplete
          onChange={handleAutocomplete}
          defaultAllValue={autocompleteAllValue}
          options={packagingGroups}
          getOptionSelected={(o) =>
            !!selectedPackagingGroups.some((g) => g.id === o.id)
          }
          value={selectedPackagingGroups}
          getOptionLabel={(o) => o.name}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              return (
                <Chip
                  label={option.value || (option as any).name}
                  {...getTagProps({ index })}
                />
              );
            });
          }}
        />
      </AppDropdown.Item>
    </AppDropdown>
  );
};
