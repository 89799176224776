import * as React from 'react';
import { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton/RoleProtectedButton';
import { QuotesTable } from './components/QuotesTable';
import { CoFilter } from '../../../../shared/components/CoFilter';
import { QuotesForm } from './components/QuotesForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveSaveQuotesAction,
  selectQuotesItems,
  selectUpdateDataQuotes,
  setUpdateDataQuotesAction,
} from '@app/store/negotiation-room/quotes';
import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';
import { validQuotesFields } from './utils/validEmptyFields';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { selectSelectedCo, setSelectedCo } from '@app/store/filters-module/co';
import qs from 'query-string';
import { getCurrentPageLimit } from '@shared/components/AppTablePagination/paginationUtils';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { useHistory } from 'react-router';
import { setFiltersIsFullListAction } from '@app/store/filters-module/filters-state';

export const QuotesTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(selectQuotesItems);
  const updateData = useSelector(selectUpdateDataQuotes);
  const selectCO = useSelector(selectSelectedCo);
  const [isValidToEmptyData, setIsValidToEmptyData] = React.useState(false);
  const dataTable: TQuotesItem[] = React.useMemo(() => {
    return data.map((i) => {
      return {
        ...i,
        ...updateData[i.id],
      };
    });
  }, [data, updateData]);
  const shouldAskForConfirm = React.useMemo(() => {
    return !!Object.keys(updateData).length;
  }, [updateData]);

  React.useEffect(() => {
    const isValid = validQuotesFields(dataTable);
    setIsValidToEmptyData(isValid);
  }, [dataTable]);

  React.useEffect(() => {
    dispatch(setFiltersIsFullListAction({ isFullList: false }));
  }, [dispatch]);

  const onFetchDataAfterImportFile = useCallback(() => {
    dispatch(setSelectedCo({ co: selectCO }));
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
    dispatch(setBackdropLoadingAction({ payload: false }));
  }, [dispatch, history, selectCO]);

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item container xs={10}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <CoFilter
                  shouldAskForConfirm={shouldAskForConfirm}
                  onSkip={() =>
                    dispatch(setUpdateDataQuotesAction({ updateData: {} }))
                  }
                  onConfirm={() => dispatch(saveSaveQuotesAction({}))}
                />
              </Grid>
              <Grid item xs={3} container>
                <QuotesForm />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={2}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <RoleProtectedButton
                  color="primary"
                  variant="contained"
                  onClick={() => dispatch(saveSaveQuotesAction({}))}
                  style={{ height: '100%' }}
                  disabled={!shouldAskForConfirm || !isValidToEmptyData}
                >
                  Save
                </RoleProtectedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <QuotesTable dataTable={dataTable} />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadQuoteNegotiation'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton
            url={'/CreateExcel/QuotesNegotiations'}
            coId={selectCO?.id}
            isPost={false}
            disabled={!selectCO}
          />
        </Grid>
      </Grid>
      <PageLeavePrompt
        onSkip={() => dispatch(setUpdateDataQuotesAction({ updateData: {} }))}
        when={shouldAskForConfirm}
        onConfirm={() => dispatch(saveSaveQuotesAction({}))}
        isQuotes={!isValidToEmptyData}
      />
    </Grid>
  );
};

QuotesTab.TAB_TITLE = 'Quotes negotiations';
