import { PRODUCT_VALUE_SECTION_1 } from '@app/routes/urls';
import { LocationChangeAction } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import {
  fetchProductGroupAllocations,
  setLoadingGroupsTableAction,
} from '../actions';

export function* productLevelsWorkerSaga(action: LocationChangeAction) {
  const { search, pathname } = action.payload.location;

  if (PRODUCT_VALUE_SECTION_1.match(pathname, true).isMatched && search) {
    yield put(setLoadingGroupsTableAction({ payload: true }));
    yield put(fetchProductGroupAllocations({}));
  }
}
