import { AppState } from '@app/store/store';

export const selectUserRightsState = (state: AppState) =>
  state.settings.userRights;

export const selectUserRightsLoading = (state: AppState) =>
  selectUserRightsState(state).loading;

export const selectUserRightsLoadedState = (state: AppState) =>
  selectUserRightsState(state).isLoaded;

export const selectUserRightsItems = (state: AppState) =>
  selectUserRightsState(state).items;

export const selectUserRightsItemCount = (state: AppState) =>
  selectUserRightsState(state).total;

//===== REFERENCE DATA =====

export const selectUserRightsRDUsers = (state: AppState) =>
  selectUserRightsState(state).referenceData.users;

export const selectUserForTempRightsRDUsers = (state: AppState) =>
  selectUserRightsState(state).referenceData.usersForTempRights;

export const selectUserRightsRDTeams = (state: AppState) =>
  selectUserRightsState(state).referenceData.teams;

export const selectUserRightsRDCO = (state: AppState) =>
  selectUserRightsState(state).referenceData.CO;
