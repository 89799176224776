import { Action } from 'redux';
import {
  setDriverAllocationLoadedAction,
  setDriverAllocationLoadingAction,
  setVolumeDriverSavingAction,
  fetchDriverAllocationsDoneAction,
  fetchDriverAllocationsAction,
} from './actions';
import { initialState } from './store';
import { VolumeDriverAllocationsState } from './store';

export function volumeDriverAllocationsReducer(
  state = initialState,
  action: Action
): VolumeDriverAllocationsState {
  if (setDriverAllocationLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }

  if (setDriverAllocationLoadedAction.is(action)) {
    return {
      ...state,
      isLoaded: action.loaded,
    };
  }

  if (fetchDriverAllocationsDoneAction.is(action)) {
    return {
      ...state,
      items: action.items,
      totalItems: action.totalItems,
      isLoaded: true,
      isLoading: false,
    };
  }

  if (setVolumeDriverSavingAction.is(action)) {
    return {
      ...state,
      isSaving: action.isSaving,
    };
  }

  if (fetchDriverAllocationsAction.is(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  return state;
}
