import * as React from 'react';
import { withEditPermissionCheck } from '@app/shared/hocs/withEditPermissionCheck';
import { TextField, TextFieldProps } from '@material-ui/core';

export const RoleProtectedInput = withEditPermissionCheck(
  (props: TextFieldProps) => {
    const { variant = 'outlined', size = 'small', ...restProps } = props;

    return <TextField variant={variant} size={size} {...restProps} />;
  }
);
