import { Layout } from '@app/shared/components/Layout';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import styles from './ContactAndSupport.module.scss';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';

export const ContactAndSupport: React.FC = () => {
  const [canVisit] = useAccountRoleRights('ContactAndSupport');

  return (
    <Layout pageName="Contact And Support">
      <Box p={4} style={{ flexGrow: 1, display: 'flex' }}>
        {canVisit() ? (
          <Grid
            spacing={4}
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Grid item>
                <Typography variant="h2" gutterBottom>
                  Contact Us
                </Typography>
              </Grid>

              <Grid container direction="row" className={styles.border}>
                <PersonIcon color="primary" />
                <Typography
                  className={styles.icon}
                  variant="subtitle1"
                  gutterBottom
                >
                  Daina van der Wal, Pricing Leader
                </Typography>
              </Grid>

              <Grid container direction="row" className={styles.border}>
                <Grid item>
                  <LocationOnIcon color="primary" />
                </Grid>
                <Grid item className={styles.icon}>
                  <Typography variant="subtitle1" gutterBottom>
                    Westerlaan 1
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    3016 CK Rotterdam
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    The Netherlands
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" className={styles.border}>
                <Grid item>
                  <PhoneIcon color="primary" />
                </Grid>
                <Grid className={styles.icon} item>
                  <Typography variant="subtitle1" gutterBottom>
                    +31 (0)10 7117253 (desk)
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom>
                    +31 (0)10 413 6420 (main)
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    +316 225 79 659 (mobile)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" className={styles.border}>
                <EmailIcon color="primary" />
                <Typography
                  className={styles.icon}
                  variant="subtitle1"
                  gutterBottom
                >
                  d.vanderwal@caldic.nl
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" alignItems="center">
            <Typography variant="h3" align="center">
              Woops.
            </Typography>
            <Typography variant="h4" align="center">
              Unfortunately you don't have permission to view this page :(
            </Typography>
          </Grid>
        )}
      </Box>
    </Layout>
  );
};
