import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import {
  selectCoProductGroupFilters,
  selectCoSelectedAllocationLevels,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  fetchDriverAllocationsAction,
  saveVolumeDriverAllocationBulkAction,
  setVolumeDriverSavingAction,
} from '../actions';

export function* saveVolumeDriverAllocationBulkSaga(
  action: ReturnType<typeof saveVolumeDriverAllocationBulkAction>
) {
  const { systemId, systemType } = action;

  try {
    yield put(setBackdropLoadingAction({ payload: true }));

    yield put(setVolumeDriverSavingAction({ isSaving: true }));

    const selectedProductGroups: TFilterItemResponse[] = yield select(
      selectCoProductGroupFilters
    );

    const allocationLevels: number[] = yield select((store) =>
      selectCoSelectedAllocationLevels(store).map((level) => level.key)
    );

    const co: TCommercialOrganization = yield select(selectSelectedCo);

    const filter = {
      skip: getCurrentSkip(),
      limit: getCurrentPageLimit(),
      productGroupIds: selectedProductGroups.map((i) => i.id),
      valueAllocations: allocationLevels,
      coId: co ? co.id : '',
    };

    yield call(VolumeDriverApi.updateVolumeDriverAllocationBulk, {
      systemId,
      systemType: VolumeDriverApi.getDriverAllocationTypeByName(systemType),
      filter,
    });

    yield put(
      fetchDriverAllocationsAction({
        skip: getCurrentSkip(),
        limit: getCurrentPageLimit(),
      })
    );

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Volume driver allocations has been saved.',
          options: {
            variant: 'success',
          },
          key: 'volume-driver-save-bulk-success',
        },
      })
    );
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to save Volume driver allocations. Try again later.',
          options: {
            variant: 'error',
          },
          key: 'volume-driver-save-bulk-failed',
        },
      })
    );
  } finally {
    yield put(setVolumeDriverSavingAction({ isSaving: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
