import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchDriverMarginListAction,
  fetchCOWithCurrencyAction,
  saveBulkMarginAction,
  saveMarginValuesAction,
} from '../actions';
import { driverMarginWorkerSaga } from './driverMarginWorkerSaga';
import { fetchDriverMarginSaga } from './fetchDriverMarginSaga';
import { fetchCOWithCurrencySaga } from './fetchCOWithCurrencySaga';
import { saveBulkMarginSaga } from './saveBulkMarginSaga';
import { saveMarginValuesSaga } from './saveMarginValuesSaga';

export function* driverMarginWatcherSaga() {
  yield all([
    takeLatest(LOCATION_CHANGE, driverMarginWorkerSaga),
    takeEvery(fetchDriverMarginListAction.TYPE, fetchDriverMarginSaga),
    takeEvery(fetchCOWithCurrencyAction.TYPE, fetchCOWithCurrencySaga),
    takeEvery(saveMarginValuesAction.TYPE, saveMarginValuesSaga),
    takeEvery(saveBulkMarginAction.TYPE, saveBulkMarginSaga),
  ]);
}
