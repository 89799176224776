import {
  fetchTeamReferenceDataAction,
  selectUserRightsRDTeams,
  TeamReferenceDataType,
} from '@app/store/settings/user-rights';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

interface TeamSelectPropsType {
  label: string;
  placeholder: string;
  value: TeamReferenceDataType | null;
  onChange: (value: TeamReferenceDataType | null) => void;
}

export const TeamSelect = ({
  label,
  placeholder,
  value,
  onChange,
}: TeamSelectPropsType) => {
  const dispatch = useDispatch();

  //===== DATA FROM STATE =====

  const options = useSelector(selectUserRightsRDTeams);

  //===== CREATE LOCAL STATE =====

  const [isLoading, setIsLoading] = useState(false);

  //===== DATA WATCHER =====

  useEffect(() => {
    if (isLoading) setIsLoading(options.length === 0); // todo need fix
  }, [isLoading, options]);

  //===== HANDLERS =====

  const handlerOpen = useCallback(() => {
    if (options.length === 0) {
      setIsLoading(true);
      dispatch(fetchTeamReferenceDataAction({}));
    }
  }, [dispatch, options]);

  return (
    <Grid container item spacing={2}>
      <Grid item container alignItems="center" xs={6}>
        {label}:
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={value}
          options={options}
          getOptionSelected={(i, value) =>
            i.userProfileId === value.userProfileId
          }
          getOptionLabel={(i) => i.userProfileName}
          onOpen={() => !isLoading && handlerOpen()}
          loading={isLoading}
          onChange={(e, value) => onChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              required
              variant="outlined"
            />
          )}
          fullWidth={true}
          size="small"
        />
      </Grid>
    </Grid>
  );
};
