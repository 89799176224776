import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { AppState } from '@app/store/store';
import { createSelector } from 'reselect';
import { allocationLevels } from './allocationLevels';

export const getFiltersState = (store: AppState) => store.filters.filters;

export const selectFiltersLoadingState = (state: AppState): boolean =>
  getFiltersState(state).loading;

export const selectFiltersIsFullList = (state: AppState): boolean =>
  getFiltersState(state).isFullList;

export const selectFilters = (state: AppState): TFilterItemResponse[] =>
  getFiltersState(state).items;

export const selectAllocationLevels = (
  store: AppState
): TFilterAllocationLevels => allocationLevels;

export const selectFiltersByLevel = (state: AppState, level: number) => {
  return getFiltersState(state).items.filter((f) => f.level === level) || [];
};

export const selectFiltersLoadedState = (state: AppState) =>
  getFiltersState(state).isLoaded;

export const selectSelectedProductGroupFilters = (state: AppState) =>
  getFiltersState(state).selectedProductGroupFilters;

export const selectSelectedAllocationLevels = (state: AppState) =>
  getFiltersState(state).selectedAllocationLevels;

export const selectFiltersMatrix = createSelector(selectFilters, (filters) => {
  const filterMatrix: TFilterItemResponse[][] = filters.reduce(
    (accum, filter) => {
      const matrixIndexFromLevel = filter.level - 1;

      if (!accum[matrixIndexFromLevel]) {
        accum[matrixIndexFromLevel] = [filter];
      } else {
        accum[matrixIndexFromLevel].push(filter);
      }

      return accum;
    },
    [] as TFilterItemResponse[][]
  );

  filterMatrix.sort((a, b) => a[0].level - b[0].level);

  return filterMatrix;
});

export const selectMainGroupFilters = (state: AppState) => {
  const matrix = selectFiltersMatrix(state);

  return matrix[matrix.length - 1];
};

export const selectSelectedFilters = (state: AppState) =>
  getFiltersState(state).selectedFilters;

export const selectSelectedProductGroupLevel4Ids = createSelector(
  selectSelectedFilters,
  (filters) => {
    return filters.filter((f) => f.level === 4).map((f) => f.id);
  }
);
