import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';

export interface FiltersState {
  loading: boolean;
  isLoaded: boolean;
  items: TFilterItemResponse[];
  selectedFilters: TFilterItemResponse[];
  selectedProductGroupFilters: TFilterItemResponse[];
  allocationLevels: TFilterAllocationLevels;
  selectedAllocationLevels: TFilterAllocationLevels;
  isFullList: boolean;
}

export const initialState: FiltersState = {
  loading: true,
  isLoaded: false,
  items: [],
  selectedFilters: [],
  selectedProductGroupFilters: [],
  allocationLevels: [],
  selectedAllocationLevels: [],
  isFullList: false,
};
