import { Action } from 'redux';
import { setConfirmationModalAction } from './actions';
import { ConfirmationModalState, initialState } from './state';

export const confirmationModalReducer = (
  state: ConfirmationModalState = initialState,
  action: Action
): ConfirmationModalState => {
  if (setConfirmationModalAction.is(action)) {
    return {
      ...state,
      isOpened: action.payload,
    };
  }
  return {
    ...state,
  };
};
