import {
  sendWizardPGAVolumeDriverAction,
  sendWizardProductGroupInfoAction,
  sendWizardBaseMarginMatrixAction,
  sendPackagingMarginAction,
} from '@app/store/wizard';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useWizardStepSaveSwitch = () => {
  const dispatch = useDispatch();

  const save = useCallback(
    (index: number): void => {
      switch (index) {
        case 0: {
          dispatch(sendWizardProductGroupInfoAction({}));
          break;
        }
        case 1: {
          dispatch(sendWizardBaseMarginMatrixAction({}));
          break;
        }
        case 2: {
          dispatch(sendPackagingMarginAction({}));
          break;
        }
        case 3: {
          dispatch(sendWizardPGAVolumeDriverAction({}));
          break;
        }
      }
    },
    [dispatch]
  );

  return save;
};

export default useWizardStepSaveSwitch;
