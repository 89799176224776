import { AppState } from '@app/store/store';

export const selectProductRightsState = (store: AppState) =>
  store.settings.productRights;

export const selectTotalProductRightsQuantity = (store: AppState) =>
  selectProductRightsState(store).total;

export const selectProductRightsItems = (store: AppState) =>
  selectProductRightsState(store).items;
export const selectProductRightsItemsUpdate = (store: AppState) =>
  selectProductRightsState(store).updateItems;

export const selectProductRightsLoadingState = (store: AppState) =>
  selectProductRightsState(store).loading;

export const selectProductRightsLoadedState = (store: AppState) =>
  selectProductRightsState(store).isLoaded;
export const selectProductRightsModalIsOpen = (store: AppState) =>
  selectProductRightsState(store).isOpenModal;
