import { AppFormDialog } from '@app/shared/components/AppFormDialog';
import { selectVolumeDriverConfigurationsModalState } from '@app/store/volume-driver/volume-driver-configurations';
import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { SystemSelect } from '../SystemSelect/SystemSelect';
import { AppButton } from '@app/shared/components/AppButton';
import { MarginSystemFormDialog } from '../MarginSystemFormDialog';
import {
  SystemSelectValue,
  SYSTEM_SELECT_MS_VALUE,
  SYSTEM_SELECT_VS_VALUE,
} from '../SystemSelect/system-select-options';
import {
  createMarginSystemConfigurationAction,
  setMarginSystemConfigurationsModalOpenedAction,
} from '@app/store/volume-driver/margin-system-configurations';
import { MarginSystemFormData } from '../MarginSystemFormDialog/components/MarginSystemForm';
import { VolumeSystemFormDialog } from '../VolumeSystemFormDialog';
import {
  createSystemConfigurationAction,
  setVolumeSystemConfigurationsModalOpenedAction,
} from '@app/store/volume-driver/volume-system-configurations';
import { VolumeSystemFormData } from '../VolumeSystemFormDialog/components/VolumeSystemForm';

interface Props {
  closeDialog: () => void;
  openDialog: () => void;
  beforeSubmit: (tableType: 'ms' | 'vs') => void;
}

type SelectOptions = 'none' | SystemSelectValue;

export const SystemSelectionDialog = (props: Props) => {
  const dispatch = useDispatch();
  const { closeDialog, openDialog, beforeSubmit } = props;

  const isOpened = useSelector(selectVolumeDriverConfigurationsModalState);

  const [selectedSystem, setSelectedSystem] = React.useState<SelectOptions>(
    'none'
  );

  const handleSelect = React.useCallback((e: any) => {
    setSelectedSystem(e.target.value);
  }, []);

  const handleNextClick = React.useCallback(() => {
    closeDialog();

    if (selectedSystem === SYSTEM_SELECT_MS_VALUE) {
      dispatch(
        setMarginSystemConfigurationsModalOpenedAction({ opened: true })
      );
    }

    if (selectedSystem === SYSTEM_SELECT_VS_VALUE) {
      dispatch(
        setVolumeSystemConfigurationsModalOpenedAction({ opened: true })
      );
    }
  }, [dispatch, selectedSystem, closeDialog]);

  const handleBackEvent = React.useCallback(() => {
    openDialog();
  }, [openDialog]);

  const handleMarginSystemFormSubmit = React.useCallback(
    (payload: MarginSystemFormData) => {
      beforeSubmit('ms');
      dispatch(createMarginSystemConfigurationAction(payload));
    },
    [dispatch, beforeSubmit]
  );

  const handleVolumeystemFormSubmit = React.useCallback(
    (payload: VolumeSystemFormData) => {
      beforeSubmit('vs');
      dispatch(createSystemConfigurationAction(payload));
    },
    [dispatch, beforeSubmit]
  );

  return (
    <React.Fragment>
      <AppFormDialog
        onClose={closeDialog}
        isOpened={isOpened}
        title="Select desired system to be created."
        content={
          <SystemSelect
            onChange={handleSelect}
            placeholder="Select system"
            value={selectedSystem}
          />
        }
        actions={
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item>
                <AppButton onClick={closeDialog}>Close</AppButton>
              </Grid>
              <Grid item>
                <AppButton
                  color="primary"
                  disabled={selectedSystem === 'none'}
                  onClick={handleNextClick}
                >
                  Next
                </AppButton>
              </Grid>
            </Grid>
          </Box>
        }
      />
      <MarginSystemFormDialog
        onBack={handleBackEvent}
        onSubmit={handleMarginSystemFormSubmit}
      />
      <VolumeSystemFormDialog
        onSubmit={handleVolumeystemFormSubmit}
        onBack={handleBackEvent}
      />
    </React.Fragment>
  );
};
