import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { defineActionGroup } from 'rd-redux-utils';

const coActionGrop = defineActionGroup('CO');

export const setCoLoadingAction = coActionGrop.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setCoDataAction = coActionGrop.defineAction<{
  payload: TCommercialOrganization[];
}>('SET DATA');

export const fetchCoAction = coActionGrop.defineAction('FETCH DATA');

export const setSelectedCo = coActionGrop.defineAction<{
  co: TCommercialOrganization | null;
}>('SET SELECTED CO');

export const resetSelectedCoAction = coActionGrop.defineAction(
  'RESET SELECTED CO'
);

export const fetchCoFiltersAction = coActionGrop.defineAction(
  'FETCH FILTERS BY CO'
);

export const setCoFiltersAction = coActionGrop.defineAction<{
  productGroupFilters: TFilterItemResponse[];
  coId: string;
}>('SET CO FILTERS');

export const setCoProductGroupFiltersAction = coActionGrop.defineAction<{
  filters: TFilterItemResponse[];
}>('SET PRODUCT GROUP FILTERS');

export const resetCoFilters = coActionGrop.defineAction('RESET FILTERS');

export const setCoAllocationLevels = coActionGrop.defineAction<{
  levels: TFilterAllocationLevels;
}>('SET CO ALLOCATION LEVELS');

export const setSelectedCoFilters = coActionGrop.defineAction<{
  filters: TFilterItemResponse[];
}>('SET SELECTED FILTERS');

export const setSelectedCoAllocationLevels = coActionGrop.defineAction<{
  levels: TFilterAllocationLevels;
}>('SET SELECTED LEVELS');
