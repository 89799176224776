import { UpdateValueRightsItem } from '@app/services/rights-service/dto/update-product-group-rights.dto';
import { TValueRights } from '@app/services/rights-service/response-interfaces/get-rights.response';
import { defineActionGroup } from 'rd-redux-utils';

const valueRightsActionGroup = defineActionGroup('PRODUCT RIGHTS');

export const setValueRightsLoadingAction = valueRightsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING VALUE');

export const fetchValueRightsAction = valueRightsActionGroup.defineAction(
  'FETCH VALUE RIGHTS'
);

export const fetchFullProductGroupsAction = valueRightsActionGroup.defineAction<{
  payload: {
    id: string[] | undefined;
    isFullList: boolean;
  };
}>('FETCH FULL PRODUCT GROUP');

export const fetchFullChildrenGroupsAction = valueRightsActionGroup.defineAction<{
  payload: {
    id: string[] | undefined;
  };
}>('FETCH FULL CHILDREN GROUP');

export const setValueRightsDataAction = valueRightsActionGroup.defineAction<{
  total: number;
  items: TValueRights[];
}>('SET VALUE DATA');

export const updateValueRightsAction = valueRightsActionGroup.defineAction<{
  items: Record<string, UpdateValueRightsItem>;
}>('UPDATE VALUE RIGHTS');

export const forceSaveValueRightsAction = valueRightsActionGroup.defineAction<{
  items: Record<string, UpdateValueRightsItem>;
}>('FORCE SAVE VALUE RIGHTS');

export const saveValueRightsAction = valueRightsActionGroup.defineAction(
  'SAVE VALUE RIGHTS'
);

export const createValueRightAction = valueRightsActionGroup.defineAction<{
  item: { userId: string; isPrimaryProductOwner: boolean; groupIds: string[] };
}>('CREATE VALUE RIGHT');

export const deleteValueRightsAction = valueRightsActionGroup.defineAction<{
  items: {
    userId: string;
    productGroupId: string;
  }[];
}>('DELETE VALUE RIGHTS');

export const toggleModalAction = valueRightsActionGroup.defineAction<{
  isOpen: boolean;
}>('TOGGLE MODAL VALUE');
