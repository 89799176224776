import { parseJwt } from '@app/shared/utils/parseJwt';
import StorageUtils from '@app/shared/utils/storage/storage-utils';
import {
  TAccountRights,
  TGetAccountRightsResponse,
} from './response-interfaces/account-rights';

class AccountRightsService {
  getRights = async (): Promise<TGetAccountRightsResponse> => {
    const accessToken = StorageUtils.getAuthToken() || '';
    const parsePayload =
      parseJwt<TAccountRights>(accessToken) || ({} as TAccountRights);

    const rights = {
      ProductValueLabeling: parsePayload.ProductValueLabeling,
      BaseMarginMatrix: parsePayload.BaseMarginMatrix,
      VolumeDriver: parsePayload.VolumeDriver,
      PackagingDriver: parsePayload.PackagingDriver,
      NegotiationRoom: parsePayload.NegotiationRoom,
      Wizard: parsePayload.Wizard,
      Exceptions: parsePayload.Exceptions,
      Settings: 'Edit' as any,
      VolumeDriverSystemConfiguration:
        parsePayload.VolumeDriverSystemConfiguration,
      VolumeDriverSystemAllocation: parsePayload.VolumeDriverSystemAllocation,
      PackagingDriverConfiguration: parsePayload.PackagingDriverConfiguration,
      PackagingDriverMargins: parsePayload.PackagingDriverMargins,
      NegotiationRoomProductLine: parsePayload.NegotiationRoomProductLine,
      NegotiationRoomQuotes: parsePayload.NegotiationRoomQuotes,
      NegotiationRoomExceptions: parsePayload.NegotiationRoomExceptions,
      SettingsProductRights: parsePayload.SettingsProductRights,
      SettingsTeamsRights: parsePayload.SettingsTeamsRights,
      SettingsUserRights: parsePayload.SettingsUserRights,
      SettingsValueRights: parsePayload.SettingsValueRights,
      ContactAndSupport: parsePayload.ContactAndSupport,
    };

    return rights;
  };
}

export const AccountRightsServiceApi = new AccountRightsService();
