import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { TProductLineItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-product-line.response';
import { enqueSnackAction } from '@app/store/snacks';
import { call, select, put } from '@redux-saga/core/effects';
import {
  fetchProductLineDataAction,
  resetUpdateProductLineAction,
  setProductLineLoadingAction,
} from '../action';
import { selectProductLineUpdateItems } from '../selectors';

export function* updateProductLineSaga() {
  try {
    yield put(setProductLineLoadingAction({ loading: true }));

    const data: Record<string, TProductLineItem> = yield select(
      selectProductLineUpdateItems
    );
    const mapData: TProductLineItem[] = Object.values(data).map((i) => i);
    yield call(NegotiationRoomApi.updateProductLineItems, mapData);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Update successfully',
          options: {
            variant: 'success',
          },
          key: 'update product line',
        },
      })
    );
    yield put(resetUpdateProductLineAction({}));
    yield put(fetchProductLineDataAction({}));
  } catch (e) {
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'update product line',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'update product line',
        },
      })
    );
  } finally {
    yield put(setProductLineLoadingAction({ loading: false }));
  }
}
