import * as React from 'react';
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  disabled: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
}));

type Props = TablePaginationProps & { disabled?: boolean };

const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];

export const AppTablePagination = (props: Props) => {
  const classes = useStyles();

  const {
    rowsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS,
    disabled,
    ...rest
  } = props;

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component={'div' as any}
      backIconButtonProps={
        disabled ? { classes: { root: classes.disabled } } : undefined
      }
      nextIconButtonProps={
        disabled ? { classes: { root: classes.disabled } } : undefined
      }
      {...rest}
    />
  );
};
