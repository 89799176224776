import { all } from 'redux-saga/effects';
import { marginSystemConfigurationsWatcherSaga } from '../margin-system-configurations/saga/marginSystemConfigurationsWatcherSaga';
import { volumeDriverAllocationsWatcherSaga } from '../volume-driver-allocations/saga/volumeDriverAllocationsWatcherSaga';
import { volumeDriverConfigurationsWatcherSaga } from '../volume-driver-configurations/saga/volumeDriverConfigurationsWatcherSaga';
import { volumeSystemConfigurationsWatcherSaga } from '../volume-system-configurations/saga/volumeSystemConfigurationsWatcherSaga';

export function* volumeDriverWatcherSaga() {
  yield all([
    volumeDriverAllocationsWatcherSaga(),
    volumeSystemConfigurationsWatcherSaga(),
    marginSystemConfigurationsWatcherSaga(),
    volumeDriverConfigurationsWatcherSaga(),
  ]);
}
