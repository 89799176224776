import { selectorFormData, OptionItem, costFormData, FormData } from './utils';
import { ActionType } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/store';

//===== STATE =====

export interface SelectFormItem {
  name: string;
  label: string;
  placeholder: string;
  current: OptionItem | null;
  options: OptionItem[];
  loaded: boolean;
  staticOptions: boolean;
}

export interface CostFormItem extends FormData {
  current: OptionItem | string | null;
}

export interface TableDataRow {
  key: string;
  name: string;
  firstLevel: number;
  secondLevel: number;
  thirdLevel: number;
  fourthLevel: number;
  fifthLevel: number;
}

interface State {
  selectForm: SelectFormItem[];
  costForm: CostFormItem[];
  table: {
    data: TableDataRow[];
    order: {
      column: string;
      direction: 'asc' | 'desc';
    };
  };
}

export const initialState: State = {
  selectForm: selectorFormData.map((i) => ({
    ...i,
    options: i.options ? i.options : [],
    current: null,
    loaded: Boolean(i.options),
    staticOptions: Boolean(i.options),
  })),
  costForm: costFormData.map((i) => ({ ...i, current: i.options ? null : '' })),
  table: {
    data: [],
    order: {
      column: '',
      direction: 'asc',
    },
  },
};

//===== TYPES =====

const setSelectFormOptionsByNameType = 'SET_SELECT_FORM_OPTIONS_BY_NAME';
const changeSelectFormCurrentByIndexType = 'CHANGE_SELECT_FORM_CURRENT_BY_NAME';
const resetSelectFormType = 'RESET_SELECT_FORM';

const changeCostFormByNameType = 'CHANGE_COST_FORM_BY_NAME';
const changeCostCurrentAndOptionsByNameType =
  'CHANGE_CONST_CURRENT_AND_OPTIONS_BY_NAME';

const setTableDataType = 'SET_TABLE_DATA';
const resetTableDataType = 'RESET_TABLE_DATA';
const setTableOrderType = 'SET_TABLE_ORDER_TYPE';

//===== ACTION =====

interface Action {
  type: string;
  payload?: any;
}

export const setSelectFormOptionsByNameActions = (
  name: string,
  options: OptionItem[]
): Action => ({
  type: setSelectFormOptionsByNameType,
  payload: { name, options },
});

export const changeSelectFormCurrentByIndexAction = (
  index: number,
  current: OptionItem
): Action => ({
  type: changeSelectFormCurrentByIndexType,
  payload: { index, current },
});

export const resetSelectFormAction = (): Action => ({
  type: resetSelectFormType,
});

export const changeCostFormByNameAction = (
  name: string,
  value: string | OptionItem | null
): Action => ({ type: changeCostFormByNameType, payload: { name, value } });
export const changeCostCurrentAndOptionsByNameAction = (
  name: string,
  current: OptionItem | null,
  options: OptionItem[]
): Action => ({
  type: changeCostCurrentAndOptionsByNameType,
  payload: { name, current, options },
});

export const setTableDataAction = (data: TableDataRow[]): Action => ({
  type: setTableDataType,
  payload: data,
});
export const resetTableDataAction = (): Action => ({
  type: resetTableDataType,
});
export const setTableOrderAction = (
  column: string,
  direction: 'asc' | 'desc'
): ActionType => ({ type: setTableOrderType, payload: { column, direction } });

//===== REDUCER =====

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    //===== select form =====

    case setSelectFormOptionsByNameType:
      return {
        ...state,
        selectForm: state.selectForm.map((i) =>
          i.name === action.payload.name
            ? { ...i, ...action.payload, loaded: true }
            : i
        ),
      };

    case changeSelectFormCurrentByIndexType:
      return {
        ...state,
        selectForm: state.selectForm.reduce(
          (res, i, index): SelectFormItem[] =>
            index < action.payload.index
              ? [...res, i]
              : index === action.payload.index
              ? [...res, { ...i, current: action.payload.current }]
              : [
                  ...res,
                  {
                    ...i,
                    current: null,
                    loaded: i.staticOptions ? i.loaded : false,
                    options: i.staticOptions ? i.options : [],
                  },
                ],
          [] as SelectFormItem[]
        ),
      };

    case resetSelectFormType:
      return {
        ...state,
        selectForm: state.selectForm.map((i) => ({
          ...i,
          current: null,
          loaded: i.staticOptions ? i.loaded : false,
          options: i.staticOptions ? i.options : [],
        })),
      };

    //===== cost =====

    case changeCostFormByNameType:
      return {
        ...state,
        costForm: state.costForm.map((i) =>
          i.name === action.payload.name
            ? { ...i, current: action.payload.value }
            : i
        ),
      };

    case changeCostCurrentAndOptionsByNameType:
      return {
        ...state,
        costForm: state.costForm.map((i) =>
          i.name === action.payload.name ? { ...i, ...action.payload } : i
        ),
      };

    //===== table =====

    case setTableDataType:
      return {
        ...state,
        table: { ...state.table, data: action.payload },
      };

    case resetTableDataType:
      return {
        ...state,
        table: { ...state.table, data: [] },
      };

    case setTableOrderType:
      return {
        ...state,
        table: { ...state.table, order: action.payload },
      };

    default:
      return state;
  }
};
