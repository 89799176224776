import * as React from 'react';
import styles from './PermissionSelectInput.module.scss';
import classnames from 'classnames';
import { TRights } from '@app/services/rights-service/response-interfaces/get-rights.response';
import Grid from '@material-ui/core/Grid';
import {
  valueDefinitions,
  PermissionSelectValueDefinition,
} from './values-definitions';
import Tooltip from '@material-ui/core/Tooltip';
import { withEditPermissionCheck } from '@app/shared/hocs/withEditPermissionCheck';

interface Props {
  value: TRights;
  name: string;
  onChange: (name: string, value: TRights) => void;
  rights: TRights[];
  disabled?: boolean;
}

const PermissionSelectInputDumb = React.memo((props: Props) => {
  const { value, name, onChange, rights, disabled } = props;

  const [items, setItems] = React.useState<PermissionSelectValueDefinition[]>(
    []
  );

  React.useEffect(() => {
    const itemsToLoad = rights
      .map((right) => {
        const rightDefinition = valueDefinitions.find(
          (def) => def.defaultValue === right
        );

        if (!rightDefinition)
          throw new Error(`Right definition not found for value: ${right}`);

        return rightDefinition;
      })
      .sort((a, b) => a.defaultValue - b.defaultValue);

    setItems(itemsToLoad);
  }, [rights]);

  const handleClick = React.useCallback(
    (value: TRights) => {
      if (disabled) return;

      onChange(name, value);
    },
    [name, onChange, disabled]
  );

  const Icons = React.useMemo(() => {
    return items.map((def) => {
      const { Component, defaultValue, tooltip } = def;
      return (
        <Grid item key={defaultValue}>
          <Tooltip title={tooltip}>
            <Component
              className={classnames(styles.permissionIcon, {
                [styles.permissionIcon_active]: value === defaultValue,
                [styles.disabled]: disabled,
              })}
              onClick={() => handleClick(defaultValue)}
            />
          </Tooltip>
        </Grid>
      );
    });
  }, [value, handleClick, items, disabled]);

  return (
    <Grid container spacing={2} wrap="nowrap">
      {Icons}
    </Grid>
  );
});

export const PermissionSelectInput = withEditPermissionCheck(
  PermissionSelectInputDumb
);
