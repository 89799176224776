import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWizardProductGroupAllocationAction,
  getProductGroupAllocationsSelector,
  getProductGroupAllocationsTotalSelector,
  getWizardCurrentAllocationLevelSelector,
  getWizardCurrentsValueAllocationSelector,
  getWizardLoadingSelector,
  getWizardProductGroupInfoSelector,
  setWizardCurrentsValuesAllocationAction,
} from '@app/store/wizard';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  productAllocation,
  productGroupAllocationHeaders,
} from '@modules/Simulator/data-definition';
import { Alert, Autocomplete } from '@material-ui/lab';
import { ProductAllocationValue, ValueAllocation } from '@shared/types/wizard';
import WizardTableContainer from '@modules/Simulator/components/WizardTableContainer/WizardTableContainer';

export const ProductGroupTable = () => {
  const dispatch = useDispatch();

  //===== data from store =====
  const currentProductGroup = useSelector(
    getWizardCurrentAllocationLevelSelector
  );
  const productGroupAllocation = useSelector(
    getProductGroupAllocationsSelector
  );
  const productGroupAllocationTotal = useSelector(
    getProductGroupAllocationsTotalSelector
  );
  const loading = useSelector(getWizardLoadingSelector);
  const valuesAllocationInProduct = useSelector(
    getWizardProductGroupInfoSelector
  );

  //===== table pagination =====
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeLimit = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  //===== table sort =====
  const [orderColumn, setOrderColumn] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handlerChangeOrder = (index: number): void => {
    const isAsc = orderColumn === index && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderColumn(index);
  };

  //===== product allocation filter =====
  const productAllocationOptions = valuesAllocationInProduct.map((i) => ({
    key: i.valueAllocation as ValueAllocation,
    name: productAllocation[i.valueAllocation],
  }));

  const selectedProductAllocation = useSelector(
    getWizardCurrentsValueAllocationSelector
  );
  const handlerChangeSelectProductAllocation = (
    values: ProductAllocationValue[]
  ): void => {
    dispatch(setWizardCurrentsValuesAllocationAction({ payload: values }));
  };

  //===== update data =====
  const [lastCurrentId, setLastCurrentId] = useState('');
  useEffect((): any => {
    if (
      currentProductGroup &&
      (currentProductGroup.id === lastCurrentId || lastCurrentId === '')
    )
      dispatch(
        fetchWizardProductGroupAllocationAction({
          payloads: {
            productGroupAllocIds: [currentProductGroup.id],
            valueAllocations: selectedProductAllocation.map((i) => i.key),
            limit,
            page,
            orderColumn,
            isAsc: order === 'asc',
          },
        })
      );
    else if (currentProductGroup?.id) {
      setPage(0);
      dispatch(
        fetchWizardProductGroupAllocationAction({
          payloads: {
            productGroupAllocIds: [currentProductGroup.id],
            valueAllocations: selectedProductAllocation.map((i) => i.key),
            limit,
            page: 0,
            orderColumn,
            isAsc: order === 'asc',
          },
        })
      );
    } else setPage(0);
    return () => {
      setLastCurrentId(
        currentProductGroup !== null ? currentProductGroup?.id : ''
      );
    };
  }, [
    currentProductGroup,
    limit,
    page,
    orderColumn,
    order,
    selectedProductAllocation,
    dispatch,
    setLastCurrentId,
    lastCurrentId,
  ]);

  return currentProductGroup ? (
    <Grid container spacing={2} direction="column">
      <Grid
        item
        container
        spacing={2}
        alignItems="center"
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={6}>
          <Typography variant="body2" style={{ opacity: 0.7 }}>
            Look up products in groups {currentProductGroup.name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            size="small"
            multiple
            value={selectedProductAllocation}
            options={productAllocationOptions}
            getOptionSelected={(o, v) => o.key === v.key}
            getOptionLabel={(i) => i.name}
            onChange={(event, value) =>
              handlerChangeSelectProductAllocation(value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product value"
                variant="outlined"
                placeholder="choose product value"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item>
        <WizardTableContainer
          loading={loading}
          pagination={
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={productGroupAllocationTotal}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeLimit}
            />
          }
        >
          {productGroupAllocation.length || loading ? (
            <Table stickyHeader={true}>
              <TableHead>
                <TableRow>
                  {productGroupAllocationHeaders.map((i) => (
                    <TableCell key={i.key}>
                      <TableSortLabel
                        active
                        direction={orderColumn === i.key ? order : 'asc'}
                        onClick={() => handlerChangeOrder(i.key)}
                      >
                        {i.name}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {productGroupAllocation.map((i) => (
                  <TableRow key={i.id}>
                    <TableCell>{i.productGroupAllocName}</TableCell>
                    <TableCell>
                      {productAllocation[i.valueAllocation]}
                    </TableCell>
                    <TableCell>{i.mainProductName}</TableCell>
                    <TableCell>{i.productNumber}</TableCell>
                    <TableCell>{i.productName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Alert severity="warning">
              No data is available for value name product group name{' '}
              <b>{currentProductGroup.name}</b>.
            </Alert>
          )}
        </WizardTableContainer>
      </Grid>
    </Grid>
  ) : null;
};
