import { Action } from 'redux';
import {
  deleteTeamRightsAction,
  fetchTeamRightsAction,
  fetchTeamRightsDoneAction,
  setTeamRightsLoadingAction,
  setTeamRightsSubmittingAction,
} from './actions';
import { initialState, TeamRightsState } from './state';

export function teamRightsReducer(
  state = initialState,
  action: Action
): TeamRightsState {
  if (setTeamRightsLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (deleteTeamRightsAction.is(action)) {
    return {
      ...state,
      items: state.items.filter(
        (item) =>
          !action.profileIds.some(
            (profileId) => profileId === item.userProfileId
          )
      ),
    };
  }

  if (setTeamRightsSubmittingAction.is(action)) {
    return {
      ...state,
      isSubmitting: action.submitting,
      isSubmitted: action.submitted,
    };
  }

  if (fetchTeamRightsDoneAction.is(action)) {
    return {
      ...state,
      loading: false,
      isLoaded: true,
      items: action.items,
      total: action.total,
    };
  }

  if (fetchTeamRightsAction.is(action)) {
    return {
      ...state,
      loading: true,
      isLoaded: false,
    };
  }

  return state;
}
