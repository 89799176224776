import { AccountRightsServiceApi } from '@app/services/account-rights-service/account-rights.service';
import { TAccountRights } from '@app/services/account-rights-service/response-interfaces/account-rights';
import { AccountAPI } from '@app/services/account-service/account-service';
import { setAccountRightsAction } from '@app/store/account-rights';
import { logoutAction } from '@app/store/auth';
import storageUtils from '@shared/utils/storage/storage-utils';
import axios from 'axios';
import { Store } from 'redux';
import { publicAdapter } from './public-adapter';

export const privateAdapter = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

export const configureInterceptors = async (store: Store) => {
  privateAdapter.interceptors.request.use((config) => {
    config.headers['Authorization'] = `Bearer ${storageUtils.getAuthToken()}`;
    return config;
  });

  privateAdapter.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalRequest = err.config;

      if (err.response.status === 401) {
        if (originalRequest._retry) {
          store.dispatch(logoutAction({}));
        } else {
          console.log('Access token is expired, refreshing.');

          originalRequest._retry = true;

          const existingRefreshToken = storageUtils.getRefreshToken();

          try {
            const { accessToken, refreshToken } = await AccountAPI.refreshToken(
              existingRefreshToken || ''
            );

            storageUtils.setAuthToken(accessToken);
            storageUtils.setRefreshToken(refreshToken);
            const rights: TAccountRights = await AccountRightsServiceApi.getRights();
            store.dispatch(setAccountRightsAction({ rights }));
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`; // token might be taken either from store or local storage will figure it out later

            return publicAdapter(originalRequest); // retry request with refreshed token
          } catch (e) {
            console.log('Token refresh failed with error.', e.message);

            console.log('Clearing key-pair');
            storageUtils.clearPair();
            store.dispatch(logoutAction({}));
          }
        }
        return;
      }

      throw err;
    }
  );
};
