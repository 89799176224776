import * as React from 'react';
import styles from './AuthForm.module.scss';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/OutlinedInput';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import makeStyles from '@material-ui/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => {
  return {
    input: {
      paddingTop: 14.5,
      paddingBottom: 14.5,
    },
    btn: {
      textTransform: 'none',
    },
  };
});

export interface AuthFormData {
  username: string;
  password: string;
  isRememberMe: boolean;
}

const initialValues: AuthFormData = {
  username: '',
  password: '',
  isRememberMe: false,
};

interface Props {
  onSubmit: (formData: AuthFormData) => void;
  onForgotPasswordClick: () => void;
  isSubmitting?: boolean;
  error?: string;
  azureLogin: () => void;
}

export const AuthForm = React.memo((props: Props) => {
  const classes = useStyles();

  const { isSubmitting, onSubmit, error, onForgotPasswordClick } = props;

  const [values, setValues] = React.useState<AuthFormData>(initialValues);

  const { username, password } = values;

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked, type } = e.target;
      setValues({ ...values, [name]: type === 'checkbox' ? checked : value });
    },
    [values]
  );

  const handleSubmit = React.useCallback(
    (e: React.SyntheticEvent<any>) => {
      e.preventDefault();

      onSubmit(values);

      setValues({
        ...values,
        password: '',
      });
    },
    [values, onSubmit]
  );

  return (
    <form onSubmit={handleSubmit} className={styles.authForm}>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Input
            type="email"
            placeholder={'example@mail.com'}
            name="username"
            value={username}
            className={styles.authForm_input}
            required
            fullWidth
            onChange={handleChange}
            classes={{ input: classes.input }}
            endAdornment={
              <InputAdornment position="end">
                <MailIcon className={styles.icon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="password"
            placeholder="Enter your password"
            type="password"
            required
            className={styles.authForm_input}
            value={password}
            onChange={handleChange}
            fullWidth
            classes={{ input: classes.input }}
            endAdornment={
              <InputAdornment position="end">
                <LockIcon className={styles.icon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item container>
          <Grid item xs={6}>
            <label className={styles.checkBoxWrapper}>
              <Checkbox
                color="primary"
                name="isRememberMe"
                onChange={handleChange}
              />
              <span className="checkboxLabel"> Remember me</span>
            </label>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Button
              color="primary"
              onClick={onForgotPasswordClick}
              className={styles.forgotPwdBtn}
              classes={{ root: classes.btn }}
            >
              Forgot your password?
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={12}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color={'primary'}
                  className={styles.authForm_submitBtn}
                  fullWidth
                  classes={{ root: classes.btn }}
                >
                  Sign In
                </Button>
                <Button
                  onClick={props.azureLogin}
                  disabled={isSubmitting}
                  variant="contained"
                  color={'primary'}
                  className={styles.authForm_submitBtn}
                  fullWidth
                  classes={{ root: classes.btn }}
                  style={{ marginTop: '10px' }}
                >
                  Microsoft Sign In
                </Button>
              </Grid>
              <Grid item>
                <span
                  className={classnames(styles.authForm_errorText, {
                    [styles.hidden]: !error,
                  })}
                >
                  {error}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
});
