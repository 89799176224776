import { TVolumeSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';
import { AppState } from '@app/store';
import { createSelector } from 'reselect';
import { VolumeSystemsConfigurationsState } from './store';

export const selectVolumeSystemConfigurationsState = (
  store: AppState
): VolumeSystemsConfigurationsState => store.volumeDriver.volumeConfigurations;

export const selectVolumeSystemConfigurationsItems = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).items;

export const selectVolumeSystemUpdatedConfigurations = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).updatedConfigurations;

export const selectVolumeSystemConfigurationsItemsList = createSelector(
  selectVolumeSystemConfigurationsItems,
  selectVolumeSystemUpdatedConfigurations,
  (items, updatedPayload) =>
    items.map((item) => ({ ...item, ...updatedPayload[item.id] }))
);

export const selectVolumeSystemConfigurationsValues = createSelector(
  selectVolumeSystemConfigurationsItemsList,
  (items) =>
    items.reduce((accum, item) => {
      accum[item.id] = item;

      return accum;
    }, {} as Record<string, TVolumeSystemConfiguration>)
);

export const selectVolumeSystemConfigurationsLoadingState = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).isLoading;

export const selectVolumeSystemConfigurationsChangedState = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).hasChanged;

export const selectVolumeSystemConfigurationsTotalItems = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).totalItems;

export const selectVolumeSystemConfigurationModalOpenedState = (
  store: AppState
) => selectVolumeSystemConfigurationsState(store).isCreationModalOpened;

export const selectVolumeSystemConfigurationCreatingState = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).isCreating;

export const selectVolumeSystemConfigurationLoadedState = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).isLoaded;

export const selectNameExists = (store: AppState) =>
  selectVolumeSystemConfigurationsState(store).nameExists;
