import { IndustriesData } from '@app/shared/types/baseMargin';
import { fetchIndustries } from '@app/store/baseMargin';
import { getIndustries } from '@app/store/baseMargin/selectors';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

interface Props {
  exceptionIndustryLevel: number;
  industriesLevel: Record<number, undefined | IndustriesData[]>;
  onIndustries: (data: IndustriesData | null) => void;
  formError: Record<string, string> | undefined;
}

export const IndustriesLevels: React.FC<Props> = (props) => {
  const { formError } = props;
  const dispatch = useDispatch();
  const { exceptionIndustryLevel, industriesLevel, onIndustries } = props;
  const industriesStore = useSelector(getIndustries);

  const [industriesLoading, setIndustriesLoading] = useState(false);
  const [industriesDefaultOptions, setIndustriesDefaultOptions] = useState<
    IndustriesData[]
  >([]);

  const fetchIndustriesData = useCallback(
    (index: number) => {
      setIndustriesDefaultOptions([]);
      setIndustriesLoading(true);
      dispatch(
        fetchIndustries({
          payload: {
            industryId:
              index === 1
                ? undefined
                : (industriesLevel[index - 1]?.map(
                    (item) => item.id
                  ) as string[]),
          },
        })
      );
    },
    [industriesLevel, dispatch]
  );

  useEffect(() => {
    if (industriesStore.length) {
      setIndustriesDefaultOptions(industriesStore);
    }
    setIndustriesLoading(false);
  }, [industriesStore]);

  const checkIndustriesDisabled = useCallback(
    (index: number) => {
      if (index !== 1) {
        return industriesLevel[index - 1]?.length ? false : true;
      }
      return false;
    },
    [industriesLevel]
  );

  const resetIndustriesLevel = useCallback(
    (index: number, obj?: any): any => {
      let current = index + 1;
      let resetGroups = obj || {};

      const checkLevels = industriesLevel[current];
      if (!checkLevels || current > 4) {
        return { ...resetGroups };
      }
      resetGroups = {
        ...resetGroups,
        [current]: null,
      };
      return resetIndustriesLevel(current, resetGroups);
    },
    [industriesLevel]
  );
  const handleIndustries = useCallback(
    (data: IndustriesData | IndustriesData[] | null, index: number) => {
      const resetIndustries = resetIndustriesLevel(index);
      onIndustries({ ...resetIndustries, [index]: data });
    },
    [resetIndustriesLevel, onIndustries]
  );

  const renderIndustyAutocomplete = useMemo(() => {
    const autoComplites = [];
    for (let i = 1; i <= exceptionIndustryLevel; i++) {
      autoComplites.push(
        <Grid key={i} container alignItems="center" direction="row" spacing={4}>
          <Grid item xs={4}>
            Industry level {i}
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              multiple
              disabled={checkIndustriesDisabled(i)}
              loading={industriesLoading}
              id={`tags-standard-industry-${i}`}
              options={industriesDefaultOptions}
              value={industriesLevel[i] || []}
              onChange={(_e, data) => handleIndustries(data, i)}
              limitTags={2}
              getOptionLabel={(option) => option.name}
              onOpen={() => fetchIndustriesData(i)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  helperText={
                    formError && formError[`industryGroups.${i}`]
                      ? formError[`industryGroups.${i}`]
                          .split(' ')
                          .slice(1)
                          .join(' ')
                      : false
                  }
                  error={
                    formError && formError[`industryGroups.${i}`] ? true : false
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      );
    }
    return autoComplites;
  }, [
    exceptionIndustryLevel,
    industriesDefaultOptions,
    industriesLoading,
    industriesLevel,
    checkIndustriesDisabled,
    fetchIndustriesData,
    handleIndustries,
    formError,
  ]);
  return <>{renderIndustyAutocomplete}</>;
};
