import { RightsApi } from '@app/services/rights-service/rights-service';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import { deleteValueRightsAction, fetchValueRightsAction } from '../actions';

export function* deleteValueRightsSaga(
  action: ReturnType<typeof deleteValueRightsAction>
) {
  const { items } = action;

  try {
    yield call(RightsApi.deleteValueRights, items);

    yield put(fetchValueRightsAction({}));
    yield put(
      enqueSnackAction({
        notification: {
          message: 'User has been successfully removed from product groups.',
          options: {
            variant: 'success',
          },
          key: 'value-rights-delete',
        },
      })
    );
  } catch (e) {
    console.log('Failed to delete product rights', e.message);
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'value-rights-delete',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'value-rights-delete',
        },
      })
    );
  }
}
