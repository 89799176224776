import * as React from 'react';
import { AppTableCell } from '../../../AppTableCell';
import { AppTableRow } from '../../../AppTableRow';
import PageviewIcon from '@material-ui/icons/Pageview';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface Props {
  colSpan: number;
  emptyListText?: string;
}

export const EmptyListRow = (props: Props) => {
  const { colSpan, emptyListText = 'No data.' } = props;

  return (
    <AppTableRow disableDeletionBehavior={true}>
      <AppTableCell
        colSpan={colSpan}
        style={{ backgroundColor: '#f2f2f2', borderBottom: 'none' }}
      >
        <Box p={2} width="100%">
          <Grid container justify="center">
            <Grid item container spacing={2} direction="column">
              <Grid item container justify="center">
                <PageviewIcon style={{ fontSize: '8rem' }} />
              </Grid>
              <Grid item container justify="center">
                <Typography variant="subtitle2" component="span">
                  {emptyListText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AppTableCell>
    </AppTableRow>
  );
};
