import { UserRightsTableItemType } from '@modules/Settings/components/UserRights/UserRightsTable/types';

export const sortByUserName = (
  data: UserRightsTableItemType[],
  order: any
): UserRightsTableItemType[] => {
  const sorting = (a: UserRightsTableItemType, b: UserRightsTableItemType) => {
    const aField = a.firstName;
    const bField = b.firstName;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};

export const sortByTeamName = (
  data: UserRightsTableItemType[],
  order: any
): UserRightsTableItemType[] => {
  const sorting = (a: UserRightsTableItemType, b: UserRightsTableItemType) => {
    // todo update property to team name
    const aField = a.profile || '';
    const bField = b.profile || '';

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};

export const sortByCO = (
  data: UserRightsTableItemType[],
  order: any
): UserRightsTableItemType[] => {
  const sorting = (a: UserRightsTableItemType, b: UserRightsTableItemType) => {
    // todo update property to CO
    const aField = a.co || '';
    const bField = b.co || '';

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
