import { fetchFullChildrenGroupsAction } from '../actions';
import { put, call } from 'redux-saga/effects';
import { ProductGroups } from '@app/shared/types/baseMargin';
import { enqueSnackAction } from '@app/store/snacks';
import { RightsApi } from '@app/services/rights-service/rights-service';
import { setProductGroup } from '@app/store/baseMargin';

export function* fetchFullChildrenGroupsWorkerSaga(
  action: ReturnType<typeof fetchFullChildrenGroupsAction>
) {
  try {
    const result: ProductGroups = yield call(
      RightsApi.fetchFullChildrenGroups,
      {
        ...action.payload,
      }
    );
    yield put(setProductGroup({ payload: result }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch product group error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch product group error',
        },
      })
    );
  }
}
