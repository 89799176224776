import { AppState } from '../store';

export const selectAppState = (store: AppState) => store.app;

export const selectAppLoadedState = (store: AppState) =>
  selectAppState(store).isLoaded;

export const selectAppLoadingState = (store: AppState) =>
  selectAppState(store).isLoading;

export const selectExportLoading = (state: AppState) => state.app.exportLoading;
