import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { GetProductLineItemsDto } from '@app/services/negotiation-room-service/dto/get-product-line-items.dto';
import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { TGetProductLineItemsReponse } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-product-line.response';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  setProductLineDataAction,
  setProductLineLoadingAction,
} from '../action';

export function* fetchProductLineSaga() {
  try {
    yield put(setProductLineLoadingAction({ loading: true }));

    const [skip, limit] = [getCurrentSkip(), getCurrentPageLimit()];
    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);
    const dto: GetProductLineItemsDto = {
      skip,
      limit,
      orderColumn: 0,
      isAsc: true,
      coId: selectedCO.id,
    };
    const request: TGetProductLineItemsReponse = yield call(
      NegotiationRoomApi.getProductLineItems,
      dto
    );

    yield put(
      setProductLineDataAction({ items: request.data, total: request.total })
    );
  } catch (e) {
    console.log('Failed to get product line items', e.message);
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'fetch product line',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'fetch product line',
        },
      })
    );
  } finally {
    yield put(setProductLineLoadingAction({ loading: false }));
  }
}
