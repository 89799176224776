import { ProductGroupsLevel } from '@app/modules/BaseMarginMatrix/components/ExceptionsConfigurations/ConfigureDialog/components/ProductGroupsLevel';
import { ProductNameData } from '@app/shared/types/baseMargin';
import { validate } from '@app/shared/utils/validate';
import { fetchProductsName } from '@app/store/baseMargin/actions';
import { selectSelectedCo } from '@app/store/filters-module/co';
import {
  createExceptionNegotiationAction,
  selectIsOpenModal,
  setIsOpenModalAction,
} from '@app/store/negotiation-room/exceptions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { schema } from './validation';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { getProductsName } from '@app/store/baseMargin/selectors';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import { Moment } from 'moment';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import MomentUtils from '@date-io/moment';
import { CreateExceptionsDTO } from '@app/services/negotiation-room-service/dto/create-exceptions.dto';

const mapAllocation: Record<number | string, string> = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};
interface DataModal {
  exceptionProductLevel: number;
  productValue: { key: number; value: string }[];
  validFrom: null | Date | any;
  validTo: null | Date | any;
  description: string;
  exceptionName: string;
}

export const AddExceptionModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(selectIsOpenModal);
  const selectedCo = useSelector(selectSelectedCo);
  const productsNameStore = useSelector(getProductsName);

  const [data, setData] = React.useState<DataModal>({
    exceptionProductLevel: 1,
    productValue: [],
    validFrom: null,
    validTo: null,
    description: '',
    exceptionName: '',
  });
  const [productGroupsLevel, setProductGroupsLevel] = React.useState<
    Record<number, undefined | { id: string; name: string }[]>
  >({ 1: [], 2: [], 3: [], 4: [], 5: [] });
  const [formError, setFormError] = React.useState<Record<string, string>>();
  const [productsNameLoading, setProductsNameLoading] = React.useState(false);
  const [currentProductsName, setCurrentProductsName] = React.useState<
    ProductNameData[] | undefined
  >([]);
  const [productsName, setProductsName] = React.useState<ProductNameData[]>([]);
  const [
    currentProductAllocation,
    setCurrentProductAllocation,
  ] = React.useState<{ key: number; value: string }[]>([]);
  const [productLoading, setProductLoading] = React.useState(false);
  React.useEffect(() => {
    if (productsNameStore.length) {
      setProductsName(productsNameStore);
    }
    setProductsNameLoading(false);
  }, [productsNameStore]);
  const getValueAllocation = React.useCallback(async () => {
    setProductLoading(true);
    const level =
      data.exceptionProductLevel === 6 ? 5 : data.exceptionProductLevel;
    const dataToFetch = productGroupsLevel[level];
    if (dataToFetch && selectedCo) {
      const result: {
        data: string[];
      } = await BaseMarginService.getAllocationLevel(
        selectedCo.id,
        dataToFetch.map((i) => i.id),
        level
      );

      const newAllocation = result.data.map((i) => {
        return {
          key: +i,
          value: mapAllocation[i],
        };
      });
      setCurrentProductAllocation(newAllocation);
    }
  }, [data.exceptionProductLevel, productGroupsLevel, selectedCo]);

  const handleChangeInput = React.useCallback((event: any) => {
    setData((state: any) => {
      return {
        ...state,
        [event.target.name]: event.target.value,
      };
    });
  }, []);
  const handleChangeSelect = React.useCallback((event: any) => {
    setData((state: any) => {
      return {
        ...state,
        [event.target.name]: event.target.value,
      };
    });
  }, []);

  const onProductGroups = React.useCallback(
    (data: { id: string; name: string } | undefined) => {
      setData((state: DataModal) => {
        return {
          ...state,
          productValue: [],
        };
      });
      setProductGroupsLevel((state: any) => {
        return {
          ...state,
          ...data,
        };
      });
    },
    []
  );

  const fetchProductsNameData = React.useCallback(() => {
    setProductsNameLoading(true);
    dispatch(
      fetchProductsName({
        payload: {
          id: productGroupsLevel[5]?.map((item) => item.id) as string[],
          valueAllocation:
            !data.productValue.length || data.productValue[0].key === 0
              ? [1, 2, 3]
              : data.productValue.map((item: any) => item.key),
        },
      })
    );
  }, [productGroupsLevel, data.productValue, dispatch]);

  const handleProductsName = React.useCallback(
    (data: ProductNameData[] | undefined) => {
      setCurrentProductsName(data);

      if (!data?.length) {
        setData((prev) => ({ ...prev, productValue: [] }));
      }
    },
    []
  );

  const handleProductValue = React.useCallback(
    (dataAllocation: { key: number; value: string }[] | null) => {
      const findAllocation = dataAllocation?.some((item) => item.key !== 0);
      const findAllAllocation = dataAllocation?.findIndex(
        (item) => item.key === 0
      );

      if (!dataAllocation?.length) {
        setData((state: any) => {
          return {
            ...state,
            productValue: [],
          };
        });
        return;
      }
      if (data.productValue.length === 0) {
        setData((state: any) => {
          return {
            ...state,
            productValue: dataAllocation,
          };
        });
      }

      if (findAllAllocation !== 0 && findAllAllocation !== -1) {
        setData((state: any) => {
          return {
            ...state,
            productValue: [{ key: 0, value: 'All' }],
          };
        });
        return;
      }
      if (findAllocation && dataAllocation) {
        const newDate = dataAllocation.filter((item: any) => item.key !== 0);
        setData((state: any) => {
          return {
            ...state,
            productValue: newDate,
          };
        });
        return;
      }
    },
    [data.productValue]
  );
  const handleChangeDate = React.useCallback(
    (date: Moment | null, isFrom: boolean) => {
      let parseDate: Date = date?.toDate() as Date;
      if (isFrom) {
         parseDate.setHours(0, 0, 0, 0);
        setData((state: any) => {
          return {
            ...state,
            validFrom: parseDate,
          };
        });
        return;
      }
      if (!isFrom && data.validFrom < parseDate) {
        parseDate.setHours(23, 59, 59, 999)
        setData((state: any) => {
          return {
            ...state,
            validTo: parseDate,
          };
        });
      }
    },
    [data.validFrom]
  );
  React.useEffect(() => {
    const isShouldClearChildrenInputs = !productGroupsLevel[
      data.exceptionProductLevel
    ]?.length;

    if (isShouldClearChildrenInputs) {
      setData((prev) => ({ ...prev, productValue: [] }));
      setCurrentProductsName([]);
    }
  }, [data.exceptionProductLevel, productGroupsLevel]);

  const checkFormIsValid = React.useCallback(() => {
    const productValueObj = Object.values(productGroupsLevel)
      .slice(0, data.exceptionProductLevel)
      .reduce(
        (
          previousValue: Record<number, { id: string; name: string }[]>,
          currentValue: any,
          index: number
        ) => {
          return {
            ...previousValue,
            [index + 1]: currentValue,
          };
        },
        {} as Record<number, { id: string; name: string }[]>
      );

    const valid = validate(
      {
        exceptionName: data.exceptionName,
        description: data.description,
        productGroupsLevel: productValueObj,
        exceptionProductLevel: data.exceptionProductLevel,
        valueAllocation: data.productValue,
      },
      schema
    );
    setFormError(valid);
    if (Object.keys(valid).length) {
      return false;
    }
    return true;
  }, [
    data.description,
    data.exceptionProductLevel,
    productGroupsLevel,
    data.exceptionName,
    data.productValue,
  ]);
  const createException = () => {
    const valid = checkFormIsValid();
    if (!valid) return;
    const groupId = productGroupsLevel[
      data.exceptionProductLevel === 6 ? 5 : data.exceptionProductLevel
    ] as { id: string; name: string }[];

    const newException: CreateExceptionsDTO = {
      description: data.description,
      validFrom: new Date(data.validFrom).toISOString(),
      validTo: new Date(data.validTo).toISOString(),
      name: data.exceptionName,
      groupIds: groupId.map((item) => item.id),
      productIds: currentProductsName
        ? currentProductsName.map((item) => item.id)
        : null,
      valueAllocations: data.productValue.map((i) => i.key),
    };
    dispatch(createExceptionNegotiationAction({ data: newException }));
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Dialog
        open={isOpenModal}
        onClose={() => dispatch(setIsOpenModalAction({ isOpen: false }))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Creation of exception</DialogTitle>
        <DialogContent>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid xs={4} item>
                  Exception name
                </Grid>
                <Grid xs={8} item>
                  <TextField
                    error={
                      formError && formError['exceptionName'] ? true : false
                    }
                    helperText={
                      formError && formError['exceptionName']
                        ? formError['exceptionName']
                        : ''
                    }
                    name="exceptionName"
                    onChange={handleChangeInput}
                    value={data.exceptionName}
                    fullWidth
                    placeholder="Enter name for this exception"
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid item xs={4}>
                  Description
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    error={formError && formError['description'] ? true : false}
                    helperText={
                      formError && formError['description']
                        ? formError['description']
                        : ''
                    }
                    name="description"
                    onChange={handleChangeInput}
                    value={data.description}
                    fullWidth
                    placeholder="Enter description for this exception"
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid item xs={4}>
                  Exception product level
                </Grid>
                <Grid item xs={8}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.exceptionProductLevel}
                    onChange={handleChangeSelect}
                    fullWidth
                    name="exceptionProductLevel"
                  >
                    <MenuItem value={1}>On product group level 1</MenuItem>
                    <MenuItem value={2}>On product group level 2</MenuItem>
                    <MenuItem value={3}>On product group level 3</MenuItem>
                    <MenuItem value={4}>On product group level 4</MenuItem>
                    <MenuItem value={5}>On main product</MenuItem>
                    <MenuItem value={6}>On product SKU level</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <ProductGroupsLevel
                productGroupsLevel={productGroupsLevel}
                onProductGroups={onProductGroups}
                exceptionProductLevel={data.exceptionProductLevel}
                formError={formError}
                isFullChildren={false}
                isFullList={false}
              />
              {data.exceptionProductLevel === 6 ? (
                <>
                  <Grid
                    container
                    alignItems="center"
                    direction="row"
                    spacing={4}
                  >
                    <Grid item xs={4}>
                      Product number / name
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        disabled={productGroupsLevel[5]?.length ? false : true}
                        value={currentProductsName || []}
                        loading={productsNameLoading}
                        multiple
                        id={`product-names`}
                        filterOptions={(options, state) => {
                          return options.filter((o) => {
                            return (
                              o.name
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase()) ||
                              o.number
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          });
                        }}
                        options={productsName}
                        onChange={(_e, data) => {
                          handleProductsName(data);
                        }}
                        limitTags={2}
                        getOptionLabel={(option) =>
                          `(${option.number}) ${option.name}`
                        }
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        onOpen={fetchProductsNameData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            helperText={
                              formError && formError[`productsName`]
                                ? formError[`productsName`]
                                    .split(' ')
                                    .slice(1)
                                    .join(' ')
                                : false
                            }
                            error={
                              formError && formError[`productsName`]
                                ? true
                                : false
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
              <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid item xs={4}>
                  Product value
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    multiple
                    id={`product-allocation`}
                    options={currentProductAllocation}
                    onChange={(_e, data) => handleProductValue(data)}
                    limitTags={2}
                    value={data.productValue}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) =>
                      option.key === value.key
                    }
                    loading={productLoading}
                    onOpen={getValueAllocation}
                    disabled={
                      data.exceptionProductLevel === 6
                        ? productGroupsLevel[data.exceptionProductLevel - 1]
                            ?.length
                          ? false
                          : true
                        : productGroupsLevel[data.exceptionProductLevel]?.length
                        ? false
                        : true
                    }
                    renderInput={(params) => (
                      <TextField
                        error={
                          formError && formError['valueAllocation']
                            ? true
                            : false
                        }
                        helperText={
                          formError && formError['valueAllocation']
                            ? formError['valueAllocation']
                            : ''
                        }
                        {...params}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid item xs={4}>
                  Valid from
                </Grid>
                <Grid item xs={8}>
                  <DatePicker
                    format="DD/MM/yyyy"
                    fullWidth
                    value={data.validFrom}
                    onChange={(date) => handleChangeDate(date, true)}
                    disablePast={true}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid item xs={4}>
                  Valid to
                </Grid>
                <Grid item xs={8}>
                  <DatePicker
                    format="DD/MM/yyyy"
                    fullWidth
                    value={data.validTo}
                    onChange={(date) => handleChangeDate(date, false)}
                    disablePast={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(setIsOpenModalAction({ isOpen: false }))}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => createException()}
            color="primary"
            autoFocus
            variant="contained"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};
