import { FilterMatrix } from './buildFilterMatrix';

export type FormatDirection = 'up' | 'down';

function mutateIndex(index: number, direction: FormatDirection): number {
  return direction === 'up' ? index - 1 : index + 1;
}

export function formatFilterMatrix(
  startIndex: number,
  originMatrix: FilterMatrix,
  matrix: FilterMatrix,
  direction: FormatDirection
): FilterMatrix {
  const rootMatrix = matrix[startIndex];
  const siblingIndex = mutateIndex(startIndex, direction);
  const nextSibling = matrix[siblingIndex];

  const originFilters = originMatrix[siblingIndex];

  if (!nextSibling) return matrix;

  if (direction === 'up') {
    matrix[siblingIndex] = originFilters.filter((f) =>
      rootMatrix.some((rootFilter) => rootFilter.parentId === f.id)
    );
  } else {
    matrix[siblingIndex] = originFilters.filter((f) =>
      rootMatrix.some((rootFilter) => rootFilter.id === f.parentId)
    );
  }

  return formatFilterMatrix(
    direction === 'up' ? startIndex - 1 : startIndex + 1,
    originMatrix,
    matrix,
    direction
  );
}

export function getAvailableOptionsFromValue(
  originOptions: FilterMatrix,
  valueMatrix: FilterMatrix,
  startIndex: number
) {
  const options = originOptions.map((options) => [...options]);

  for (
    let i = startIndex === 0 ? startIndex : startIndex - 1;
    i < options.length;
    i++
  ) {
    const parentOptions = valueMatrix[i];
    if (!originOptions[i + 1]) break;

    const childrenOptions = originOptions[i + 1].filter((option) =>
      parentOptions.some((parent) => parent.id === option.parentId)
    );

    options[i + 1] = childrenOptions;
  }

  return options;
}

export function getValuesFromOptions(
  options: FilterMatrix,
  originValues: FilterMatrix,
  startIndex: number
) {
  for (let i = startIndex + 1; i < originValues.length; i++) {
    const optionsAtIndexLevel = options[i];

    if (!optionsAtIndexLevel) break;
    originValues[i] = options[i];
  }

  return originValues;
}

export function rebuildValue(
  originFilters: FilterMatrix,
  valueMatrix: FilterMatrix,
  startIndex: number
) {
  for (let i = startIndex; i < valueMatrix.length; i++) {
    const value = valueMatrix[i];
    let childrenValue = originFilters[i + 1];

    if (!childrenValue) break;

    childrenValue = childrenValue.filter((child) =>
      value.some((parent) => parent.id === child.parentId)
    );

    valueMatrix[i + 1] = childrenValue;
  }

  return valueMatrix;
}
