import { AppTable } from '@app/shared/components/AppTable';

import {
    BaseMarginDataTable,
    CommercialOrganizationsWithCurrency,
    CustomMargins,
    DefaultMargin,
    Exception,
} from '@app/shared/types/baseMargin';
import {
    getBaseMarginLoadingSelector,
    getTotalBaseMargin,
} from '@app/store/baseMargin/selectors';
import TableCell from '@material-ui/core/TableCell/TableCell';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BaseMatrixHeaderTable } from './BaseMatrixHeaderTable';
import { useDispatch } from 'react-redux';
import { getCurrentExceptionIds } from '@app/store/baseMargin';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './baseMatrixTable.module.scss';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import { useHistory } from 'react-router';
import { BASE_MARGIN_MATRIX_EXCEPTIONS } from '@app/routes/urls';
import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';

interface Props {
    marginValues: Record<string, CustomMargins>;
    defaultMargin: Record<string, DefaultMargin>;
    showMarginExceptions: boolean;
    currentCurrency: CommercialOrganizationsWithCurrency | undefined;
    onOpenEditModal: (
        row: BaseMarginDataTable,
        isDefault: boolean,
        industrieId?: string
    ) => void;
    dataToRender: BaseMarginDataTable[];
    industryFiltersMap: Record<
        number,
        {
            colSpan: number;
            industry: Industrie;
        }[]
    >;
}

const parseValueAllocation = (value: number) => {
    const map: Record<number, string> = {
        0: '— —',
        1: 'Low',
        2: 'Medium',
        3: 'High',
    };
    return map[value];
};

const parseToTooltip = (exceptions: Exception[]) => {
    if (exceptions) {
        const nameExceptions = exceptions.map((item) => item.name);
        const namesToString = nameExceptions.join(', ');
        return namesToString;
    }
};

export const BaseMatrixTable: React.FC<Props> = React.memo((props) => {
    const dispatch = useDispatch();
    const {
        marginValues,
        defaultMargin,
        showMarginExceptions,
        currentCurrency,
        onOpenEditModal,
        dataToRender,
        industryFiltersMap,
    } = props;

    const loading = useSelector(getBaseMarginLoadingSelector);
    const history = useHistory();
    const total = useSelector(getTotalBaseMargin);

    const handleException = useCallback(
        (_e: any, exception: Exception[]) => {
            if (showMarginExceptions) {
                dispatch(getCurrentExceptionIds({ payload: exception }));
                history.push(BASE_MARGIN_MATRIX_EXCEPTIONS.urlTemplate);
            }
        },
        [showMarginExceptions, dispatch, history]
    );

    const parseType = useCallback(
        (type: number) => {
            const current = currentCurrency?.coCurrencies.find(
                (item) => item.key === type
            );
            return current?.value;
        },
        [currentCurrency]
    );

    const renderBody = useCallback(
        (data) => {
            return data.map((row: BaseMarginDataTable, index: number) => {
                const typeDefault = parseType(
                    defaultMargin[row.id + row.valueAllocation]
                        ? defaultMargin[row.id + row.valueAllocation].type
                        : row.defaultMarginType
                );
                return (
                    <AppTable.Row<any>
                        hover
                        role="checkbox"
                        item={row}
                        tabIndex={-1}
                        key={index}
                    >
                        <TableCell align="center">
                            {row.productManagerName || '—'}
                        </TableCell>
                        <TableCell align="left">{row.firstGroupName}</TableCell>
                        <TableCell align="left">{row.secondGroupName}</TableCell>
                        <TableCell align="left">{row.thirdGroupName}</TableCell>
                        <TableCell align="left">{row.fourthGroupName}</TableCell>
                        <TableCell align="left">
                            {parseValueAllocation(row.valueAllocation)}
                        </TableCell>
                        <TableCell
                            align="center"
                            onClick={() => onOpenEditModal(row, true)}
                            className={styles.tableCell}
                        >
                            {defaultMargin[row.id + row.valueAllocation]
                                ? defaultMargin[row.id + row.valueAllocation].value || '—'
                                : row.defaultMargin || '—'}

                            {(row.defaultMargin ||
                                defaultMargin[row.id + row.valueAllocation]) &&
                                defaultMargin[row.id + row.valueAllocation]?.value !== '' ? (
                                    <span className={styles.type}>{typeDefault}</span>
                                ) : (
                                    ''
                                )}
                        </TableCell>

                        {row.industries.map((item, index: number) => {
                            const typeDefault = parseType(
                                defaultMargin[row.id + row.valueAllocation]
                                    ? defaultMargin[row.id + row.valueAllocation].type
                                    : row.defaultMarginType
                            );
                            const value = marginValues[row.id + item.id + row.valueAllocation]
                                ? marginValues[row.id + item.id + row.valueAllocation].value
                                : item.customMargin;
                            const withTootltip = (
                                <Tooltip
                                    title={`Exceptions: ${parseToTooltip(
                                        item.exceptions as Exception[]
                                    )}`}
                                    key={index}
                                >
                                    <TableCell
                                        style={{ background: '#FFF5F1' }}
                                        onClick={(e) => handleException(e, item.exceptions || [])}
                                        align="center"
                                        key={index}
                                        className={styles.tableCell}
                                    >
                                        {value || '—'}
                                        {value ? (
                                            <span className={styles.type}>{typeDefault}</span>
                                        ) : (
                                                ''
                                            )}
                                    </TableCell>
                                </Tooltip>
                            );
                            return (
                                <React.Fragment key={index}>
                                    {showMarginExceptions && item.exceptions.length ? (
                                        withTootltip
                                    ) : (
                                            <TableCell
                                                align="center"
                                                onClick={() => onOpenEditModal(row, false, item.id)}
                                                key={index}
                                                className={styles.tableCell}
                                            >
                                                {value || '—'}
                                                {value ? (
                                                    <span className={styles.type}>{typeDefault}</span>
                                                ) : (
                                                        ''
                                                    )}
                                            </TableCell>
                                        )}
                                </React.Fragment>
                            );
                        })}
                    </AppTable.Row>
                );
            });
        },
        [
            marginValues,
            defaultMargin,
            handleException,
            showMarginExceptions,
            onOpenEditModal,
            parseType,
        ]
    );
    return (
        <AppTable
            data={dataToRender}
            dataCount={total}
            deletableItems={false}
            loading={loading}
            tableLayout="auto"
            isEmpty={!dataToRender.length ? true : false}
        >
            <BaseMatrixHeaderTable headersData={industryFiltersMap} />
            <AppTable.Body noDataText={NO_FILTERS_DATA_TABLE_TEXT} contentColSpan={7}>
                {renderBody}
            </AppTable.Body>
        </AppTable>
    );
});
