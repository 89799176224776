import React from 'react';
import { useReducer } from 'react';
import {
  reducer,
  initialState,
  changeSelectFormCurrentByIndexAction,
  setSelectFormOptionsByNameActions,
  resetSelectFormAction,
  changeCostFormByNameAction,
  setTableDataAction,
  resetTableDataAction,
  setTableOrderAction,
  changeCostCurrentAndOptionsByNameAction,
} from '@modules/Simulator/components/TargetPriceResults/store';
import {
  getTPRMarginVolumeDriver,
  getTPRPackagingDriver,
  getTPRBaseMargin,
  OptionItem,
  calculator,
  getTPRUnit,
  getTPRBaseMarginIsPercent,
} from '@modules/Simulator/components/TargetPriceResults/utils';
import { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import {
  SelectFormField,
  CostFormSelectField,
  CostFormTextField,
  ResultTable,
} from './components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  fetchTPRIndustriesAction,
  fetchTPRProductGroupAction,
  fetchTPRProductValuesAction,
  getTPROptionsProductGroupSelector,
  getTPROptionsIndustriesSelector,
  getTPROptionsProductValueSelector,
  fetchTPRTableDataAction,
  getTPRTableDataSelector,
  fetchTPRCOAction,
  getTPROptionsCOSelector,
  setSelectedSimulatorCoAction,
  setSelectedSimulatorProductAction,
  setSelectedSimulatorValueAllocationAction,
} from '@app/store/wizard';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { TableDataRow } from '@modules/Simulator/components/TargetPriceResults/store';
import { Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const TargetPriceResultsContainer = () => {
  const dispatch = useDispatch();

  //===== CRATE LOCAL STATE =====

  const [tprState, tprDispatch] = useReducer(reducer, initialState);

  //===== DATA FROM GLOBAL STATE =====

  const productGroup = useSelector(getTPROptionsProductGroupSelector);
  const industries = useSelector(getTPROptionsIndustriesSelector);
  const productValues = useSelector(getTPROptionsProductValueSelector);
  const tableData = useSelector(getTPRTableDataSelector);
  const coData = useSelector(getTPROptionsCOSelector);

  //===== STATE DATA WATCHER =====

  useEffect(() => {
    if (coData.length) {
      tprDispatch(setSelectFormOptionsByNameActions('coId', coData));
    } else {
      tprDispatch(resetSelectFormAction());
    }
  }, [tprDispatch, coData]);

  useEffect(() => {
    if (productGroup.length) {
      tprDispatch(
        setSelectFormOptionsByNameActions('productGroup', productGroup)
      );
    }
  }, [tprDispatch, productGroup]);

  useEffect(() => {
    tprDispatch(setSelectFormOptionsByNameActions('industryName', industries));
  }, [tprDispatch, industries]);

  useEffect(() => {
    tprDispatch(
      setSelectFormOptionsByNameActions('productValue', productValues)
    );
  }, [tprDispatch, productValues]);

  //===== SELECT FORM HANDLERS =====

  const handlerLoadOptions = (name: string) => {
    switch (name) {
      case 'coId': {
        dispatch(fetchTPRCOAction({}));
        break;
      }
      case 'productGroup': {
        tprState.selectForm.find((i) => i.name === 'coId')?.current &&
          dispatch(
            fetchTPRProductGroupAction({
              payload: String(
                tprState.selectForm.find((i) => i.name === 'coId')?.current?.key
              ),
            })
          );
        break;
      }
      case 'industryName': {
        tprState.selectForm.find((i) => i.name === 'industryLevel')?.current &&
          dispatch(
            fetchTPRIndustriesAction({
              payload: Number(
                tprState.selectForm.find((i) => i.name === 'industryLevel')
                  ?.current?.key
              ),
            })
          );
        break;
      }
      case 'productValue': {
        const currentProductGroup = tprState.selectForm.find(
          (i) => i.name === 'productGroup'
        )?.current;
        const currentCOId = tprState.selectForm.find((i) => i.name === 'coId')
          ?.current;
        currentProductGroup &&
          dispatch(
            fetchTPRProductValuesAction({
              payload: {
                productId: currentProductGroup ? currentProductGroup.key : '',
                coId: currentCOId ? currentCOId.key : '',
              },
            })
          );
        break;
      }
    }
  };

  const handlerSelectChange = useCallback(
    (index: number, value: OptionItem) => {
      if(index === 0) {
        dispatch(setSelectedSimulatorCoAction({
          payload: value
        }))
      }
      if(index === 1) {
        dispatch(setSelectedSimulatorProductAction({
          payload: value
        }))
      }
      if(index === 5) {
        dispatch(setSelectedSimulatorValueAllocationAction({
          payload: value
        }))
      }
      tprDispatch(changeSelectFormCurrentByIndexAction(index, value));
    },
    [tprDispatch]
  );

  //===== COST FORM HANDLERS =====

  const handlerCostChange = useCallback(
    (name: string, value: OptionItem | string | null) => {
      tprDispatch(changeCostFormByNameAction(name, value));
    },
    [tprDispatch]
  );

  //===== TABLE DATA WATCHER =====

  const coId = useMemo(
    () => tprState.selectForm.find((i) => i.name === 'coId')?.current?.key,
    [tprState]
  );

  const productId = useMemo(
    () =>
      tprState.selectForm.find((i) => i.name === 'productGroup')?.current?.key,
    [tprState]
  );

  const valuesAllocations = useMemo(
    () =>
      tprState.selectForm.find((i) => i.name === 'productValue')?.current?.key,
    [tprState]
  );

  const isValidSelector = useMemo(() => {
    if (
      tprState.selectForm.find((i) => i.name === 'default')?.current?.key ===
      'default'
    ) {
      return !tprState.selectForm.some(
        (i) =>
          i.current === null &&
          !(i.name === 'industryLevel' || i.name === 'industryName')
      );
    } else {
      return !tprState.selectForm.some((i) => i.current === null);
    }
  }, [tprState]);

  useEffect(() => {
    if (isValidSelector) {
      dispatch(
        fetchTPRTableDataAction({
          payloads: {
            coId: coId || '',
            productId: productId || '',
            valuesAllocations: valuesAllocations || '',
          },
        })
      );
    } else {
      tprDispatch(resetTableDataAction());
    }
  }, [
    dispatch,
    tprDispatch,
    coId,
    isValidSelector,
    productId,
    valuesAllocations,
  ]);

  //===== TABLE DATA GENERATORS =====

  const baseMarginValue = useMemo(
    () =>
      getTPRBaseMargin(
        tableData.baseMargin,
        tprState.selectForm.some(
          (i) =>
            i.name === 'default' && i.current && i.current.key === 'default'
        ),
        tprState.selectForm.find((i) => i.name === 'industryLevel')?.current
          ?.key || '',
        tprState.selectForm.find((i) => i.name === 'industryName')?.current
          ?.key || ''
      ),
    [tableData, tprState]
  );
  const baseMarginIsNotPercent = useMemo(
    () => getTPRBaseMarginIsPercent(tableData.baseMargin),
    [tableData]
  );

  const selectFormData = useMemo(() => tprState.selectForm, [tprState]);

  useEffect(() => {
    if (baseMarginIsNotPercent) {
      tprDispatch(
        changeCostCurrentAndOptionsByNameAction(
          'currency',
          {
            key: String(tableData.baseMargin[0].marginTypes[0]),
            name:
              tableData?.coId
                ?.find((i) => i.id === coId)
                ?.coCurrencies?.find(
                  (i) => i.key === tableData.baseMargin[0].marginTypes[0]
                )
                ?.value.split('/')[0] || '',
          },
          []
        )
      );
    } else {
      tprDispatch(
        changeCostCurrentAndOptionsByNameAction(
          'currency',
          null,
          // costFormData?.find((i) => i.name === 'currency')?.options || []
          tableData.coId
            ?.find((co) => {
              const test = selectFormData.find(
                (select) => select.name === 'coId'
              )?.current?.key;

              if (test) return test === co.id;
              else return false;
            })
            ?.coCurrencies?.map((i) => ({
              key: String(i.key),
              name: i.value.split('/')[0],
            }))
            .reduce((res, item) => {
              const currencyRE = /[A-Z]{3}/;

              if (
                currencyRE.test(item.name) &&
                !res.some((i) => i.name === item.name)
              )
                return res.concat([item]);
              else return res;
            }, [] as OptionItem[]) || []
        )
      );
    }
  }, [baseMarginIsNotPercent, selectFormData, tprDispatch, tableData, coId]);

  const packagingDriverValue = useMemo(
    () => getTPRPackagingDriver(tableData.packagingDriver),
    [tableData]
  );

  const unit = useMemo(() => getTPRUnit(tableData.volumeDriver), [tableData]);

  const marginVolumeDriverValue = useMemo(
    () =>
      getTPRMarginVolumeDriver(
        tableData.volumeDriver,
        tableData.marginVolumeDriver
      ),
    [tableData]
  );

  const tableView = useMemo(() => {
    const current = tprState.costForm.find((i) => i.name === 'tableView')
      ?.current;

    return current && typeof current === 'object' && 'key' in current
      ? current.key
      : null;
  }, [tprState]);

  const currency = useMemo(() => {
    const current = tprState.costForm.find((i) => i.name === 'currency')
      ?.current;

    return current && typeof current === 'object' && 'name' in current
      ? ' ' + current.name
      : '';
  }, [tprState]);

  const cost = useMemo(() => {
    const current = tprState.costForm.find((i) => i.name === 'productCost')
      ?.current;

    return current && typeof current === 'string' ? Number(current) : null;
  }, [tprState]);

  useEffect(() => {
    if (
      baseMarginValue !== null &&
      packagingDriverValue !== null &&
      marginVolumeDriverValue !== null &&
      tableView !== null
    ) {
      tprDispatch(
        setTableDataAction(
          packagingDriverValue.map((pd) => ({
            key: pd.id,
            name: pd.name,
            ...marginVolumeDriverValue.reduce(
              (res, mVD) => ({
                ...res,
                [mVD.key]: calculator(
                  baseMarginValue || 0,
                  baseMarginIsNotPercent ? 1 : 0,
                  cost || 0,
                  pd.margin,
                  pd.marginType,
                  mVD.value,
                  tableView
                ),
              }),
              {}
            ),
          })) as TableDataRow[]
        )
      );
    } else {
      tprDispatch(resetTableDataAction());
    }
  }, [
    tprDispatch,
    baseMarginValue,
    baseMarginIsNotPercent,
    packagingDriverValue,
    marginVolumeDriverValue,
    cost,
    tableView,
  ]);

  //===== SORT TABLE =====

  const handlerChangeOrder = useCallback(
    (name: string) => {
      const isAsc =
        tprState.table.order.column === name &&
        tprState.table.order.direction === 'asc';
      tprDispatch(setTableOrderAction(name, isAsc ? 'desc' : 'asc'));
    },
    [tprState, tprDispatch]
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container spacing={2}>
        {tprState.selectForm.map((i, index, data) => {
          if (
            (i.name === 'industryLevel' || i.name === 'industryName') &&
            data.some(
              (i) =>
                i.name === 'default' &&
                (i.current === null || i.current.key === 'default')
            )
          )
            return null;
          else
            return (
              <SelectFormField
                key={i.name}
                {...i}
                handlerOpen={handlerLoadOptions}
                handlerChange={(value: OptionItem) =>
                  handlerSelectChange(index, value)
                }
              />
            );
        })}
      </Grid>
      <Grid item container spacing={2}>
        {tprState.costForm.map((i, index, data) => {
          switch (i.name) {
            case 'tableView':
              return (
                <CostFormSelectField
                  key={i.name}
                  name={i.name}
                  label={i.label}
                  placeholder={i.placeholder}
                  current={i.current as OptionItem | null}
                  options={i.options ? i.options : []}
                  handlerChange={handlerCostChange}
                />
              );
            case 'currency':
              return (
                data.some(
                  (i) =>
                    i.name === 'tableView' &&
                    i.current &&
                    typeof i.current === 'object' &&
                    i.current.key !== '%'
                ) &&
                !baseMarginIsNotPercent && (
                  <CostFormSelectField
                    key={i.name}
                    name={i.name}
                    label={i.label}
                    placeholder={i.placeholder}
                    current={i.current as OptionItem | null}
                    options={i.options ? i.options : []}
                    handlerChange={handlerCostChange}
                  />
                )
              );
            case 'productCost':
              return data.some(
                (i) =>
                  i.name === 'tableView' &&
                  i.current &&
                  typeof i.current === 'object'
              ) || baseMarginIsNotPercent ? (
                <CostFormTextField
                  key={i.name}
                  name={i.name}
                  label={i.label}
                  placeholder={i.placeholder}
                  current={i.current as string}
                  handlerChange={handlerCostChange}
                />
              ) : null;
          }
          return null;
        })}
      </Grid>
      <Grid item>
        {isValidSelector &&
        tprState.table.data.length &&
        marginVolumeDriverValue &&
        ((tableView === '%' && !baseMarginIsNotPercent) || cost) ? (
          <ResultTable
            data={tprState.table.data}
            volumeDriver={marginVolumeDriverValue}
            handlerChangeOrder={handlerChangeOrder}
            order={tprState.table.order}
            currency={tableView === '%' ? '%' : currency}
            unit={unit}
          />
        ) : null}
      </Grid>
      {isValidSelector && tableView && !tprState.table.data.length && (
        <Grid item>
          <Paper style={{ margin: '1rem 0 0' }}>
            <Alert severity="error">
              Multiple margin types exist for the base margin of{' '}
              {tprState.selectForm.find((i) => i.name === 'productGroup')
                ?.current?.name || 'product allocation group'}
              . Table cannot be shown.
            </Alert>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
