import * as React from 'react';
import styles from './AppDropdown.module.scss';
import { AppDropdownItem } from './AppDropdownItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';

interface Props {
  head: string | JSX.Element;
  initialOpened?: boolean;
  children: any;
  disabled?: boolean;
}

export function AppDropdown(props: Props) {
  const { initialOpened = false, children, head, disabled } = props;
  const [opened, setOpened] = React.useState(initialOpened);
  const toggle = React.useCallback(() => {
    if (disabled) return;

    setOpened((opened) => !opened);
  }, [disabled]);

  return (
    <>
      <Grid
        container
        direction="column"
        className={classnames(styles.appDropdown, {
          [styles.appDropdown_opened]: disabled ? false : opened,
          [styles.appDropdown_disabled]: disabled,
        })}
      >
        <Grid
          item
          container
          onClick={toggle}
          className={styles.appDropdown_head}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={12}>
            <Box p={2}>
              <span className={styles.title}>{head}</span>
            </Box>
          </Grid>
          <Grid item className={styles.arrowWrapper}>
            <Box p={2}>
              <i
                className={classnames(styles.arrow, {
                  [styles.opened]: opened,
                })}
              ></i>
            </Box>
          </Grid>
        </Grid>
        <Box
          p={2}
          className={classnames(styles.appDropdown_content, {
            [styles.hidden]: !opened || disabled,
          })}
        >
          <Grid container direction="column" item spacing={2}>
            {children}
          </Grid>
        </Box>
      </Grid>
      {opened ? <div onClick={toggle} className={styles.backdrop}></div> : null}
    </>
  );
}

AppDropdown.Item = AppDropdownItem;
