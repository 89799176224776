import { Action } from 'redux';
import {
  resetUpdateProductLineAction,
  setProductLineDataAction,
  setProductLineLoadingAction,
  setUpdateProductLineAction,
} from './action';
import { initialState, ProductLineState } from './state';

export function productLineReducer(
  state = initialState,
  action: Action
): ProductLineState {
  if (setProductLineDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
      totalItems: action.total,
    };
  }

  if (setProductLineLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }
  if (setUpdateProductLineAction.is(action)) {
    return {
      ...state,
      updateData: {
        ...state.updateData,
        [action.payload.id]: action.payload,
      },
    };
  }
  if (resetUpdateProductLineAction.is(action)) {
    return {
      ...state,
      updateData: {},
    };
  }

  return state;
}
