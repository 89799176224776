import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal';
import { GroupHierarchiesFilter } from '@app/shared/components/GroupHierarchiesFilter';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import { setMarginListDataAction } from '@app/store/driver/driver-margins/actions';
import {
  selectCoData,
  selectCoFilters,
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
  setSelectedCo,
  setSelectedCoAllocationLevels,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import {
  selectAllocationLevels,
  selectSelectedAllocationLevels,
} from '@app/store/filters-module/filters-state';
import { setSelectedPackagingGroupsAction } from '@app/store/filters-module/packaging-groups/actions';
import { selectSelectedPackagingGroups } from '@app/store/filters-module/packaging-groups/selectors';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PackagingFilter } from '../PackagingFilter';

interface Props {
  onChange: () => void;
  disabled?: boolean;
  filters: TFilterItemResponse[];
  allocationLevels: TFilterAllocationLevels;
  shouldAskForConfirm: boolean;
  onConfirm: () => void;
  onSkip: () => void;
}

const InterceptedGroupHierarchyFilters = withConfirmation([
  'onFiltersChange',
  'onAllocationLevelChange',
])(GroupHierarchiesFilter, ConfirmationModal);

const InterceptedPackagingFilters = withConfirmation(['onChange'])(
  PackagingFilter,
  ConfirmationModal
);

export const PackagingMarginFilters = (props: Props) => {
  const dispatch = useDispatch();

  const coList = useSelector(selectCoData);

  const {
    onChange,
    disabled = false,
    filters,
    allocationLevels,
    onConfirm,
    onSkip,
    shouldAskForConfirm,
  } = props;

  const levels = useSelector(selectSelectedAllocationLevels);
  const co = useSelector(selectSelectedCo);
  const packagingGroups = useSelector(selectSelectedPackagingGroups);
  const allAllocations = useSelector(selectAllocationLevels);
  const selectedAllocationLevels = useSelector(
    selectCoSelectedAllocationLevels
  );
  const coFilters = useSelector(selectCoFilters);

  const filtersValue = useSelector(selectCoSelectedFilters);

  const handleFiltersChange = React.useCallback(
    (filters: TFilterItemResponse[]) => {
      if (!filters.length) {
        dispatch(setSelectedCoAllocationLevels({ levels: [] }));
        dispatch(setMarginListDataAction({ items: [], total: 0 }));
        dispatch(
          setSelectedCoFilters({
            filters,
          })
        );
        return;
      }
      if (filters.length && !levels.length) {
        dispatch(setSelectedCoAllocationLevels({ levels: allAllocations }));
      }
      dispatch(
        setSelectedCoFilters({
          filters,
        })
      );
      onChange();
    },
    [onChange, dispatch, allAllocations, levels.length]
  );

  const handleAllocationLevelChange = React.useCallback(
    (levels: TFilterAllocationLevels) => {
      dispatch(setSelectedCoAllocationLevels({ levels }));
      if (!filtersValue.length && levels.length) {
        dispatch(setSelectedCoFilters({ filters: coFilters }));
      }
      if (!levels.length) {
        dispatch(setSelectedCoFilters({ filters: [] }));
        dispatch(setMarginListDataAction({ items: [], total: 0 }));
        return;
      }
      onChange();
    },
    [onChange, dispatch, coFilters, filtersValue.length]
  );

  const handlePackagingFilterChange = React.useCallback(
    (payload: { coId: string; packagingGroups: TPackagingOption[] }) => {
      dispatch(
        setSelectedPackagingGroupsAction({ items: payload.packagingGroups })
      );
      dispatch(
        setSelectedCo({
          co: coList.find(
            (co) => co.id === payload.coId
          ) as TCommercialOrganization,
        })
      );

      onChange();
    },
    [onChange, coList, dispatch]
  );

  return (
    <React.Fragment>
      <Grid item xs={3}>
        <InterceptedGroupHierarchyFilters
          filters={filters}
          allocationLevels={allocationLevels}
          onFiltersChange={handleFiltersChange}
          disabled={disabled}
          allocationLevelsValue={selectedAllocationLevels}
          onAllocationLevelChange={handleAllocationLevelChange}
          filtersValue={filtersValue}
          modalProps={undefined}
          shouldAskForConfirm={shouldAskForConfirm}
          onSkip={onSkip}
          onConfirm={onConfirm}
        />
      </Grid>
      <Grid item xs={3}>
        <InterceptedPackagingFilters
          onChange={handlePackagingFilterChange}
          selectedCo={co && co.id ? co.id : ''}
          selectedPackagingGroups={packagingGroups}
          modalProps={undefined}
          shouldAskForConfirm={shouldAskForConfirm}
          onSkip={onSkip}
          onConfirm={onConfirm}
        />
      </Grid>
    </React.Fragment>
  );
};
