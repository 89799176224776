import { Action } from 'redux';
import {
  setMarginOptionsDataAction,
  setMarginOptionsLoadingAction,
} from './actions';
import { initialState, MarginOptionsState } from './store';

export function marginOptionsReducer(
  state = initialState,
  action: Action
): MarginOptionsState {
  if (setMarginOptionsDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
    };
  }

  if (setMarginOptionsLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }

  return state;
}
