import { fetchBaseMarginSaga } from './fetchBaseMarginSaga';
import { saveBaseMarginSaga } from './saveBaseMarginSaga';
import {
  fetchBaseMarginAction,
  saveBaseMarginAction,
  setBaseMarginBulk,
  fetchBaseMarginExceptionAction,
  updateBaseMarginExceptionAction,
  fetchProductGroups,
  fetchProductsName,
  fetchIndustries,
  fetchExceptionName,
  createExceptionAction,
  deleteExceptionAction,
  fetchUpdateBaseMarginException,
  getCurrentExceptionIds,
  fetchCOWithCurrency,
  saveBaseMarginToPagination,
  updateBaseMarginExceptionPaginationAction,
  fetchGroupFilterBaseMatrixAction,
  fetchExceptionHighlightsAction,
} from '../actions';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { baseMarginWorkerSaga } from './baseMarginWorkerSaga';
import { saveBaseMarginBulkSaga } from './saveBaseMarginBulkSaga';
import { fetchBaseMarginExceptionSaga } from './fetchBaseMarginExceptionSaga';
import { updateBaseMarginExceptionSaga } from './updateBaseMarginExceptionSaga';
import { fetchProductGroupWorkerSaga } from './fetchProductGroupWorkerSaga';
import { fetchProductsNameWorkerSaga } from './fetchProductsNameWorkerSaga';
import { fetchIndustriesWorkerSaga } from './fetchIndustriesWorkerSaga';
import { fetchExceptionNameWorkerSaga } from './fetchExceptionNameWorkerSaga';
import { createExceptionWorkerSaga } from './createExceptionWorkerSaga';
import { deleteExceptionWorkerSaga } from './deleteExceptionWorkerSaga';
import { updateExceptionDataWorkerSaga } from './updateExceptionDataWorkerSaga';
import { setCurrentExceptionIdsWorkerSaga } from './setCurrentExceptionIdsWorkerSaga';
import { fetchCOCurrencyWorkerSaga } from './fetchCOCurrencyWorkerSaga';
import { saveBaseMarginToPaginationWorkerSaga } from './saveBaseMarginToPaginationWorkerSaga';
import { updateBaseMarginExceptionPaginationSaga } from './updateBaseMarginExceptionPaginationSaga';
import { fetchFilterGroupBaseWorkerSaga } from './fetchFilterGroupBaseWorkerSaga';
import { fetchExceptionHighlightsSaga } from './fetchExceptionHighlightsSaga';

export function* baseMarginRootSaga() {
  yield all([
    takeEvery(fetchBaseMarginAction.TYPE, fetchBaseMarginSaga),
    takeEvery(saveBaseMarginAction.TYPE, saveBaseMarginSaga),
    takeEvery(setBaseMarginBulk.TYPE, saveBaseMarginBulkSaga),
    takeEvery(
      fetchBaseMarginExceptionAction.TYPE,
      fetchBaseMarginExceptionSaga
    ),
    takeEvery(
      updateBaseMarginExceptionAction.TYPE,
      updateBaseMarginExceptionSaga
    ),
    takeEvery(fetchProductGroups.TYPE, fetchProductGroupWorkerSaga),
    takeEvery(fetchProductsName.TYPE, fetchProductsNameWorkerSaga),
    takeEvery(fetchIndustries.TYPE, fetchIndustriesWorkerSaga),
    takeEvery(fetchExceptionName.TYPE, fetchExceptionNameWorkerSaga),
    takeEvery(createExceptionAction.TYPE, createExceptionWorkerSaga),
    takeEvery(deleteExceptionAction.TYPE, deleteExceptionWorkerSaga),
    takeEvery(
      fetchUpdateBaseMarginException.TYPE,
      updateExceptionDataWorkerSaga
    ),
    takeEvery(getCurrentExceptionIds.TYPE, setCurrentExceptionIdsWorkerSaga),
    takeEvery(fetchCOWithCurrency.TYPE, fetchCOCurrencyWorkerSaga),
    takeLatest(LOCATION_CHANGE, baseMarginWorkerSaga),
    takeEvery(
      saveBaseMarginToPagination.TYPE,
      saveBaseMarginToPaginationWorkerSaga
    ),
    takeEvery(
      updateBaseMarginExceptionPaginationAction.TYPE,
      updateBaseMarginExceptionPaginationSaga
    ),
    takeEvery(
      fetchGroupFilterBaseMatrixAction.TYPE,
      fetchFilterGroupBaseWorkerSaga
    ),
    takeEvery(fetchExceptionHighlightsAction.TYPE, fetchExceptionHighlightsSaga),
    // takeLatest(setSelectedCo.TYPE, fetchIndustriesIdsWorkerSaga),
  ]);
}
