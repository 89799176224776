import React from 'react';
import { useSelector } from 'react-redux';
import {
  fetchPackagingMarginOriginAction,
  getPackagingMarginSelector,
} from '@app/store/wizard';
import WizardTableContainer from '@modules/Simulator/components/WizardTableContainer/WizardTableContainer';
import {
  PackagingMarginTable,
  initialPackagingMarginTableState,
  packagingMarginTableReducer,
  setOrderTableAction,
  openEditModalAction,
  closeEditModalAction,
  toggleDeleteItemAction,
  openDeleteModalAction,
  closeDeleteModalAction,
  cleanDeleteItemsAction,
} from '.';
import { useReducer } from 'react';
import { sortByDirectionWithIndex } from '@app/shared/utils/table-sort';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { changePackagingMarginValueAction } from '@app/store/wizard';
import { EditPackagingMarginModal } from './modals/EditPackagingMarginModal';
import { deletePackagingMarginAction } from '@app/store/wizard';
import { DeletePackagingMarginModal } from './modals/DeletePackaginMarginModal';
import { useEffect } from 'react';

interface IProps {
  canEdit: boolean;
}

export const PackagingMarginTableContainer = ({ canEdit }: IProps) => {
  const dispatch = useDispatch();
  //===== FETCH DATA =====

  useEffect(() => {
    dispatch(fetchPackagingMarginOriginAction({}));
  }, [dispatch]);

  //===== CREATE LOCAL STATE =====

  const [tableState, tableDispatch] = useReducer(
    packagingMarginTableReducer,
    initialPackagingMarginTableState
  );

  //===== DATA FROM STATE =====

  const data = useSelector(getPackagingMarginSelector);

  //===== SORT TABLE =====

  const sortCallback = sortByDirectionWithIndex(
    tableState.order.direction,
    tableState.order.column,
    tableState.order.index,
    tableState.order.isNumberString
  );

  const handlerChangeOrderCallBack = useCallback(
    (
      name: string,
      isNumberString: boolean = false,
      index: number | null = null
    ): void => {
      const isAsc =
        tableState.order.column === name &&
        tableState.order.index === index &&
        tableState.order.direction === 'asc';

      tableDispatch(
        setOrderTableAction(name, index, isAsc ? 'desc' : 'asc', isNumberString)
      );
    },
    [tableState, tableDispatch]
  );

  //===== EDIT MODAL =====

  const handlerOpenModal = useCallback(
    (id: string, index: number, value: string) => {
      tableDispatch(openEditModalAction(id, index, value));
    },
    [tableDispatch]
  );

  const handlerCloseModal = useCallback(() => {
    tableDispatch(closeEditModalAction());
  }, [tableDispatch]);

  const handlerAcceptModal = useCallback(
    (value: string) => {
      tableState.editModal.id &&
        tableState.editModal.index !== undefined &&
        dispatch(
          changePackagingMarginValueAction({
            payload: {
              id: tableState.editModal.id,
              index: 0,
              value: value,
            },
          })
        );
      tableDispatch(closeEditModalAction());
    },
    [tableState, dispatch, tableDispatch]
  );

  //===== DELETE =====

  const handlerToggleDeleteItem = useCallback(
    (id: string) => {
      tableDispatch(toggleDeleteItemAction(id));
    },
    [tableDispatch]
  );

  const handlerOpenDeleteModal = useCallback(() => {
    tableDispatch(openDeleteModalAction());
  }, [tableDispatch]);

  const handlerCloseDeleteModal = useCallback(() => {
    tableDispatch(closeDeleteModalAction());
  }, [tableDispatch]);

  const handlerAcceptDeleteModal = useCallback(() => {
    dispatch(deletePackagingMarginAction({ payload: tableState.delete.items }));
    tableDispatch(closeDeleteModalAction());
    tableDispatch(cleanDeleteItemsAction());
  }, [dispatch, tableDispatch, tableState]);

  return (
    <>
      <WizardTableContainer>
        <PackagingMarginTable
          data={data}
          sort={sortCallback}
          changeOrder={handlerChangeOrderCallBack}
          orderColumn={tableState.order.column}
          orderDirection={tableState.order.direction}
          orderIndex={tableState.order.index}
          changeValue={handlerOpenModal}
          handlerDelete={handlerOpenDeleteModal}
          toggleDeleteItem={handlerToggleDeleteItem}
          deletedItems={tableState.delete.items}
          canEdit={canEdit}
        />
      </WizardTableContainer>
      <EditPackagingMarginModal
        status={tableState.editModal.status}
        data={data}
        editIndex={tableState.editModal.index}
        value={tableState.editModal.value}
        close={handlerCloseModal}
        accept={handlerAcceptModal}
      />
      <DeletePackagingMarginModal
        status={tableState.delete.modal}
        close={handlerCloseDeleteModal}
        accept={handlerAcceptDeleteModal}
      />
    </>
  );
};
