import { call, put } from 'redux-saga/effects';
import {
  resetUpdateAllocationAction,
  saveAllocationValueAction,
} from '../actions';
import ProductValueService from '@services/product-value-service/product-value-service';
import { enqueSnackAction } from '@app/store/snacks';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { push } from 'connected-react-router';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import qs from 'query-string';

export function* saveProductValueSaga(
  action: ReturnType<typeof saveAllocationValueAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));
    yield call(ProductValueService.updateAllocationValue, action.payload);
    yield put(resetUpdateAllocationAction({}));
    yield put(
      push({
        search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
      })
    );
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Allocations Value is saved',
          options: {
            variant: 'success',
          },
          key: 'save allocation level',
        },
      })
    );
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create product save error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create product save error',
        },
      })
    );
    console.log(`Failed to update allocation value: ${e.toString()}`);
  } finally {
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
