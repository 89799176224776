import React from 'react';
import {
  VOLUME_DRIVER_ALLOCATION,
  VOLUME_DRIVER_CONFIGURATIONS,
} from '@app/routes/urls';
import { TabRouterElement } from '@app/shared/components/TabRouterLayout';
import { SystemConfigurations } from './SystemConfigurations';
import { VolumeDriverAllocation } from './VolumeDriverAllocation';
import { ProtectedComponent } from '@app/shared/components/ProtectedComponent';

export const volumeDriverTabs: TabRouterElement[] = [
  {
    title: 'Volume Driver Allocation',
    link: VOLUME_DRIVER_ALLOCATION.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={VolumeDriverAllocation}
        feature={'VolumeDriverSystemAllocation'}
      />
    ),
    feature: 'VolumeDriverSystemAllocation',
  },
  {
    title: 'System Configurations',
    link: VOLUME_DRIVER_CONFIGURATIONS.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={SystemConfigurations}
        feature={'VolumeDriverSystemConfiguration'}
      />
    ),
    feature: 'VolumeDriverSystemConfiguration',
  },
];
