import { UpdateProductGroupRightsItem } from '@app/services/rights-service/dto/update-product-group-rights.dto';
import { TProductRight } from '@app/services/rights-service/response-interfaces/get-rights.response';

export interface ProductRightsState {
  items: TProductRight[];
  total: number;
  updateItems: Record<string, UpdateProductGroupRightsItem>;
  loading: boolean;
  isLoaded: boolean;
  isOpenModal: boolean;
}

export const initialState: ProductRightsState = {
  items: [],
  total: 0,
  loading: true,
  updateItems: {},
  isLoaded: false,
  isOpenModal: false,
};
