import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { TTeamRights } from '@app/services/rights-service/response-interfaces/get-team-rights.response';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { useEmptyTableCheck } from '@app/shared/hooks/useEmptyTableCheck';
import {
  createTeamRightsAction,
  deleteTeamRightsAction,
  setTeamRightsSubmittingAction,
  updateTeamRightsAction,
} from '@app/store/settings/team-rights';
import {
  selectTeamRightsItemCount,
  selectTeamRightsItems,
  selectTeamRightsLoadedState,
  selectTeamRightsLoading,
  selectTeamRightsSubmitted,
  selectTeamRightsSubmitting,
} from '@app/store/settings/team-rights/selectors';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeamRightsConfigureDialog } from './TeamRightsConfigureDialog';
import styles from './TeamsRights.module.scss';
import {
  TeamRightsTableOnChangePayload,
  TeamsRightsTable,
} from './TeamsRightsTable';

export const TeamsRights: React.FC = () => {
  const dispatch = useDispatch();

  const [
    tableData,
    setTableData,
  ] = React.useState<TeamRightsTableOnChangePayload | null>(null);
  const handleTableChange = useCallback(
    (payload: TeamRightsTableOnChangePayload) => {
      setTableData(payload);
    },
    []
  );
  const hasChangedData = useMemo(() => {
    return !!tableData?.length;
  }, [tableData]);
  const teamRightsTotal = useSelector(selectTeamRightsItemCount);
  const teamRightsItems = useSelector(selectTeamRightsItems);
  const teamRightsLoading = useSelector(selectTeamRightsLoading);

  const handleDelete = useCallback(
    (items: TTeamRights[]) => {
      dispatch(
        deleteTeamRightsAction({
          profileIds: items.map((item) => item.userProfileId),
        })
      );
    },
    [dispatch]
  );

  const [isModalOpened, setModalOpened] = useState(false);

  const handleModalClose = React.useCallback(() => {
    setModalOpened(false);
  }, []);

  const closeModal = React.useCallback(() => {
    setModalOpened(false);
  }, []);

  const openModal = React.useCallback(() => {
    setModalOpened(true);
  }, []);

  const isModalSubmitting = useSelector(selectTeamRightsSubmitting);
  const isModalSubmitted = useSelector(selectTeamRightsSubmitted);

  React.useEffect(() => {
    if (!isModalSubmitted) return;

    closeModal();
    dispatch(
      setTeamRightsSubmittingAction({ submitted: false, submitting: false })
    );
  }, [isModalSubmitted, dispatch, closeModal]);

  const onModalFormSubmit = React.useCallback(
    (formData: any) => {
      dispatch(
        createTeamRightsAction({
          ...formData,
        })
      );
    },
    [dispatch]
  );

  const isTableChanged = !!tableData;

  const handleSave = React.useCallback(() => {
    if (!tableData) return;
    dispatch(
      updateTeamRightsAction({
        teamRights: tableData,
      })
    );
    setTableData(null);
  }, [dispatch, tableData]);

  const isTeamRightsLoaded = useSelector(selectTeamRightsLoadedState);

  const isTableEmpty = useEmptyTableCheck(
    isTeamRightsLoaded,
    teamRightsLoading,
    teamRightsTotal
  );
  const handleDontSave = useCallback(() => {
    setTableData(null);
  }, []);
  const handlePromptConfirm = useCallback(() => {
    handleSave();
  }, [handleSave]);
  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      <Grid item container wrap="nowrap">
        <Grid container justify="flex-end" wrap="nowrap" spacing={2}>
          <Grid item>
            <RoleProtectedButton
              variant="contained"
              className={styles.actionBtn}
              onClick={openModal}
            >
              Configure rights
            </RoleProtectedButton>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              variant="contained"
              className={styles.actionBtn}
              disabled={!isTableChanged}
              color="primary"
              onClick={handleSave}
            >
              Save
            </RoleProtectedButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="column" wrap="nowrap">
        <Grid item xs={12}>
          <Paper className={styles.tableWrapper}>
            <Grid container direction="column" wrap="nowrap">
              <Grid item xs={12} container>
                <TeamsRightsTable
                  itemsCount={teamRightsTotal}
                  items={teamRightsItems}
                  loading={teamRightsLoading}
                  onDelete={handleDelete}
                  onChange={handleTableChange}
                  isEmpty={isTableEmpty}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <TeamRightsConfigureDialog
        isLoading={isModalSubmitting}
        onSubmit={onModalFormSubmit}
        isOpened={isModalOpened}
        onClose={handleModalClose}
        closeModal={closeModal}
      />
      <PageLeavePrompt
        onSkip={handleDontSave}
        when={hasChangedData}
        onConfirm={handlePromptConfirm}
      />
    </Grid>
  );
};
