import { call, put } from 'redux-saga/effects';
import { enqueSnackAction } from '@app/store/snacks';
import { deleteUserRightsItemsAction, fetchUserRightsDataAction } from '..';
import { RightsApi } from '@services/rights-service/rights-service';

export function* deleteUserRightsSaga(
  action: ReturnType<typeof deleteUserRightsItemsAction>
) {
  try {
    yield call(RightsApi.deleteUserRights, action.payload.items);
    yield put(fetchUserRightsDataAction({}));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'deleted team rights',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to delete product rights.',
          options: {
            variant: 'error',
          },
          key: 'product-rights-delete-failed',
        },
      })
    );
  }
}
