import {
  fetchTPRProductGroupAction,
  setTPRProductGroupAction,
} from '../actions';
import { call, put } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';

export function* fetchTPRProductGroupSaga(
  action: ReturnType<typeof fetchTPRProductGroupAction>
) {
  try {
    const res = yield call(
      WizardServices.getProductGroupAllocationLevels,
      action.payload
    );
    yield put(setTPRProductGroupAction({ payload: res.data }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardProductGroupAllocationLevelsWorkerSaga: ${e.toString()}`
    );
  }
}
