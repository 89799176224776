import { AppState } from '@app/store/store';

export const selectValueRightsState = (store: AppState) =>
  store.settings.valueRights;

export const selectValueData = (store: AppState) =>
  selectValueRightsState(store).items;
export const selectValueTotal = (store: AppState) =>
  selectValueRightsState(store).total;
export const selectValueLoading = (store: AppState) =>
  selectValueRightsState(store).loading;
export const selectValueUpdateData = (store: AppState) =>
  selectValueRightsState(store).updateItems;
export const selectValueIsOpen = (store: AppState) =>
  selectValueRightsState(store).isOpenModal;
