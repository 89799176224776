import { call, put } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';
import { setWizardMarginSystemAction } from '../actions';

export function* fetchWizardMarginSystemSaga() {
  try {
    const res = yield call(WizardServices.getMarginSystem);
    yield put(setWizardMarginSystemAction({ payload: res.data }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
