import React from 'react';
import {
  WizardPackagingMarginEntryState,
  WizardPackagingMarginState,
} from '@shared/types/wizard';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@material-ui/core';
import { productAllocation } from '@modules/Simulator/data-definition';
import { TableSortLabel } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import { validatePercentValueWithEmptyPackagingMargin } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/util/validateMarginValue';
import { transformToMarginTypeName } from '@app/shared/utils/transformUtils';

interface PackagingMarginTableProps {
  data: WizardPackagingMarginState;
  sort: (a: any, b: any) => any;
  changeOrder: (
    name: string,
    isNumberString?: boolean,
    index?: number | null
  ) => void;
  orderColumn: string;
  orderDirection: 'asc' | 'desc';
  orderIndex: number | null;
  changeValue: (id: string, index: number, value: string) => void;
  handlerDelete: () => void;
  toggleDeleteItem: (id: string) => void;
  deletedItems: Record<string, boolean>;
  canEdit: boolean;
}

export const PackagingMarginTable = ({
  data,
  sort,
  changeOrder,
  orderColumn,
  orderDirection,
  orderIndex,
  changeValue,
  handlerDelete,
  toggleDeleteItem,
  deletedItems,
  canEdit,
}: PackagingMarginTableProps) => {
  const sortByDefault = (
    a: WizardPackagingMarginEntryState,
    b: WizardPackagingMarginEntryState
  ): -1 | 1 | 0 => {
    const defA = a.values.some((i) => i.default);
    const defB = b.values.some((i) => i.default);

    if (defA && defB) return 0;
    if (defA) return -1;
    if (defB) return 1;
    return 0;
  };

  const formatValue = (value: string, marginType: number): string => {
    if (isNaN(Number(value))) {
      return value;
    }
    if (Number(value) >= 0) {
      return `+${Number(value).toFixed(2)}${transformToMarginTypeName(
        marginType
      )}`;
    } else {
      return `${Number(value).toFixed(2)}${transformToMarginTypeName(
        marginType
      )}`;
    }
  };

  return (
    <Table stickyHeader={true}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell
            colSpan={data.valueAllocation.length}
            align="center"
            style={{ border: '2px solid var(--white)' }}
          >
            Product value
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="center"
            style={{ border: '2px solid var(--white)', width: '1px' }}
          >
            <IconButton
              disabled={Object.keys(deletedItems).length === 0}
              size="small"
              onClick={handlerDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
          <TableCell
            align="center"
            style={{ border: '2px solid var(--white)' }}
          >
            <TableSortLabel
              active
              direction={orderColumn === 'name' ? orderDirection : 'asc'}
              onClick={() => changeOrder('name')}
            >
              Packaging
            </TableSortLabel>
          </TableCell>
          {data.valueAllocation.map((i, index) => (
            <TableCell
              align="center"
              style={{ border: '2px solid var(--white)' }}
              key={i}
            >
              <TableSortLabel
                active
                direction={
                  orderColumn === 'values' && orderIndex === index
                    ? orderDirection
                    : 'asc'
                }
                onClick={() => changeOrder('values', true, index)}
              >
                {productAllocation[i]}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.packaging
          .sort(sort)
          .sort(sortByDefault)
          .map((i, index) => (
            <TableRow key={i.id}>
              <TableCell>
                <Checkbox
                  checked={Boolean(deletedItems[i.id])}
                  onChange={() => toggleDeleteItem(i.id)}
                  disabled={
                    i.values.reduce(
                      (res, i) => res && Boolean(i.default),
                      true as boolean
                    ) || !canEdit
                  }
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  name="packagingMargin"
                  color="primary"
                />
              </TableCell>
              <TableCell>{i.name}</TableCell>
              {i.values.map((value, index2) => (
                <TableCell
                  key={index2}
                  align="center"
                  style={{
                    backgroundColor: value.default ? 'var(--main_grey)' : '',
                  }}
                >
                  {value.default ? (
                    `+${value.value}${transformToMarginTypeName(i.marginType)}`
                  ) : (
                    <Box
                      color={
                        validatePercentValueWithEmptyPackagingMargin(
                          value.value,
                          i.marginType
                        )
                          ? 'text.primary'
                          : 'error.main'
                      }
                    >
                      <Button
                        size="small"
                        onClick={() => changeValue(i.id, index, value.value)}
                        color={'inherit'}
                        disabled={!canEdit}
                      >
                        {formatValue(value.value, i.marginType)}
                      </Button>
                    </Box>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
