import * as yup from 'yup';
/* eslint-disable */
const regExpAbsolute: RegExp = /^[1-9]?[0-9]{0,4}?\d((\.|\,)\d\d?)?$/;
const regExpProcent: RegExp = /^[1-9]?\d((\.|\,)\d([0-9]{0,1}?))?$/;

export const schema = yup.object().shape({
  marginType: yup.number(),
  margin: yup.lazy(((_value: string, obj: any) => {
    if (obj.originalValue.marginType || obj.parent.marginType === 0) {
      return yup.string().matches(regExpProcent).required();
    }
    return yup.string().matches(regExpAbsolute).required();
  }) as any),
});
