import { all, takeEvery } from 'redux-saga/effects';
import { fetchManagersAction, fetchManagersByCoAction } from '../actions';
import { fetchManagersByCoSaga } from './fetchManagersByCoSaga';
import { fetchManagersSaga } from './fetchManagersSaga';

export function* managersSaga() {
  yield all([
    takeEvery(fetchManagersAction.TYPE, fetchManagersSaga),
    takeEvery(fetchManagersByCoAction.TYPE, fetchManagersByCoSaga),
  ]);
}
