import { PackagingDriverApi } from '@app/services/packaging-driver-service/packaging-driver.service';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import {
  fetchDriverConfigurationListAction,
  resetUpdateDataAction,
  saveDriverConfigurationsAction,
  setDriverConfigurationsSavingAction,
} from '../actions';

export function* saveConfigurationsSaga(
  action: ReturnType<typeof saveDriverConfigurationsAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));
    yield put(setDriverConfigurationsSavingAction({ saving: true }));

    yield call(PackagingDriverApi.saveConfigurations, { data: action.items });
    const skip = getCurrentSkip();
    const limit = getCurrentPageLimit();
    yield put(fetchDriverConfigurationListAction({ skip, limit }));
    yield put(resetUpdateDataAction({}));
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Your changes has been successfully saved.',
          options: {
            variant: 'success',
          },
          key: 'packaging-driver-configuration-save-success',
        },
      })
    );
  } catch (e) {
    console.log('Failed to save configurations', e.message);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to save configurations, please try again later.',
          options: {
            variant: 'error',
          },
          key: 'packaging-driver-configuration-save-error',
        },
      })
    );
  } finally {
    yield put(setDriverConfigurationsSavingAction({ saving: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
