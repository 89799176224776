import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import { GroupHierarchiesFilter } from '@app/shared/components/GroupHierarchiesFilter';
import {
  selectFilters,
  selectSelectedFilters,
  setFiltersIsFullListAction,
  setSelectedFiltersAction,
} from '@app/store/filters-module/filters-state';
import {
  selectProductValueAllocationLevels,
  selectSelectedProductValueAllocationLevels,
} from '@app/store/productValue/selectors';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import qs from 'query-string';
import {
  setProductGroupAllocations,
  setProductGroupLabeling,
  setSelectedProductValueAllocationLevels,
} from '@app/store/productValue';
import {
  PRODUCT_VALUE_SECTION_1,
  PRODUCT_VALUE_SECTION_2,
} from '@app/routes/urls';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal';

const InterceptedGroupHierarchyFilters = withConfirmation([
  'onFiltersChange',
  'onAllocationLevelChange',
])(GroupHierarchiesFilter, ConfirmationModal);

interface Props {
  shouldAskForConfirm: boolean;
  onConfirm: () => void;
  onSkip: () => void;
}

export const Filters: React.FC<Props> = (props) => {
  const { shouldAskForConfirm, onConfirm, onSkip } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector(selectFilters);
  const filtersValue = useSelector(selectSelectedFilters);
  const allocationLevelOptions = useSelector(
    selectProductValueAllocationLevels
  );
  const allocationLevelsValue = useSelector(
    selectSelectedProductValueAllocationLevels
  );

  useEffect(() => {
    dispatch(setFiltersIsFullListAction({ isFullList: true }));
  }, [dispatch]);

  const handleFiltersChange = useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);
  const setEmptyData = useCallback(() => {
    if (history.location.pathname === PRODUCT_VALUE_SECTION_1.urlTemplate) {
      dispatch(
        setProductGroupAllocations({
          payload: {
            total: 0,
            data: [],
          },
        })
      );
      return;
    }
    if (history.location.pathname === PRODUCT_VALUE_SECTION_2.urlTemplate) {
      dispatch(
        setProductGroupLabeling({
          payload: {
            total: 0,
            data: [],
          },
        })
      );
      return;
    }
  }, [dispatch, history]);
  const onFiltersChange = React.useCallback(
    (filters: TFilterItemResponse[]) => {
      dispatch(setSelectedFiltersAction({ filters }));
      if (!filters.length) {
        dispatch(setSelectedProductValueAllocationLevels({ levels: [] }));
        setEmptyData();
        return;
      }
      if (filters.length && !allocationLevelsValue.length) {
        dispatch(
          setSelectedProductValueAllocationLevels({
            levels: allocationLevelOptions,
          })
        );
      }
      handleFiltersChange();
    },
    [
      handleFiltersChange,
      allocationLevelOptions,
      allocationLevelsValue.length,
      dispatch,
      setEmptyData,
    ]
  );

  const handleAllocationLevelsChange = React.useCallback(
    (items: TFilterAllocationLevels) => {
      dispatch(setSelectedProductValueAllocationLevels({ levels: items }));
      if (!filtersValue.length && items.length) {
        dispatch(setSelectedFiltersAction({ filters: filters }));
      }
      if (!items.length) {
        dispatch(setSelectedFiltersAction({ filters: [] }));
        setEmptyData();
        return;
      }
      handleFiltersChange();
    },
    [handleFiltersChange, dispatch, filters, filtersValue.length, setEmptyData]
  );

  return (
    <>
      <InterceptedGroupHierarchyFilters
        onAllocationLevelChange={handleAllocationLevelsChange}
        filters={filters}
        filtersValue={filtersValue}
        onFiltersChange={onFiltersChange}
        allocationLevels={allocationLevelOptions}
        allocationLevelsValue={allocationLevelsValue}
        modalProps={undefined}
        shouldAskForConfirm={shouldAskForConfirm}
        onConfirm={onConfirm}
        onSkip={onSkip}
      />
    </>
  );
};
