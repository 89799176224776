import { useProductFilters } from '@app/shared/hooks/useProductFilters.ts/useProductFilters';
import * as React from 'react';
import { AppAutocomplete } from '../AppAutocomplete';
import { AppDropdown } from '../AppDropdown/AppDropdown';
import Chip from '@material-ui/core/Chip';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { useSelector, useDispatch } from 'react-redux';
import {
  getIndustriesFilter,
  getSelectedIndustries,
  setSelectedIndustries,
} from '@app/store/filters-module/industries';
import { flatArray } from '@app/shared/utils/flatArray';

interface Props {
  filters: TFilterItemResponse[];
}

export const IndustriesFilters = (props: Props) => {
  const dispatch = useDispatch();
  const filters = useSelector(getIndustriesFilter);
  const selectedIndustryFilters = useSelector(getSelectedIndustries);
  const [
    staticMatrix,
    filterMatrix,
    valueMatrix,
    changeFilters,
  ] = useProductFilters(filters, selectedIndustryFilters);

  const handleChange = React.useCallback(
    (index: number, data: TFilterItemResponse[]) => {
      const changedFilters = changeFilters(index, data);
      const filters = flatArray(changedFilters);
      dispatch(setSelectedIndustries({ filters: filters }));
    },
    [changeFilters, dispatch]
  );

  const DynamicFilters = React.useMemo(() => {
    return filterMatrix.map((filters, index) => {
      const value = valueMatrix[index];
      const defaultValue = staticMatrix[index];
      return (
        <AppDropdown.Item title={`Industry level ${index + 1}`} key={index}>
          <AppAutocomplete
            options={filters.length ? filters : defaultValue}
            getOptionLabel={(o) => o.name}
            getOptionSelected={(o) => {
              return value.some((option) => option.id === o.id);
            }}
            value={value || []}
            defaultAllValue={defaultValue}
            onChange={(data) => {
              handleChange(index, data as TFilterItemResponse[]);
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => {
                return (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    // disabled={
                    //   value.length === 1 || option.id === 'all' ? true : false
                    // }
                  />
                );
              });
            }}
          />
        </AppDropdown.Item>
      );
    });
  }, [filterMatrix, valueMatrix, staticMatrix, handleChange]);

  return <React.Fragment>{DynamicFilters}</React.Fragment>;
};
