import { TeamRightsTableItem } from './mapUtils';

export const sortTeamName = (
  data: TeamRightsTableItem[],
  order: any
): TeamRightsTableItem[] => {
  const sorting = (a: TeamRightsTableItem, b: TeamRightsTableItem) => {
    const aField = a.userProfileName;
    const bField = b.userProfileName;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};

export const sortAccessType = (
  data: TeamRightsTableItem[],
  order: any
): TeamRightsTableItem[] => {
  const sorting = (a: TeamRightsTableItem, b: TeamRightsTableItem) => {
    const aField = a.roleAccessType;
    const bField = b.roleAccessType;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
