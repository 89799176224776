import { defineActionGroup } from 'rd-redux-utils';
import {
  BaseMarginData,
  UpdateBaseMatrixDto,
  SetBulkBaseMargin,
  BaseMatrixExceptionsDTO,
  BaseMarginExceptions,
  UpdateBaseMarginException,
  ProductGroups,
  ProductsName,
  IndustriesData,
  CreateException,
  UpdateData,
  Exception,
  CommercialOrganizationsWithCurrency,
  ExceptionHighlight,
} from '@shared/types/baseMargin';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';

export const baseMarginActionGroup = defineActionGroup('BASE_MARGIN_ACTIONS');

export const fetchBaseMarginAction = baseMarginActionGroup.defineAction<{}>(
  'FETCH BASE MARGIN'
);

export const fetchCOWithCurrency = baseMarginActionGroup.defineAction(
  'FETCH CO CURRENCY'
);
export const setCOWithCurrency = baseMarginActionGroup.defineAction<{
  payload: CommercialOrganizationsWithCurrency[];
}>('SET CO CURRENCY');
export const setBaseMarginAction = baseMarginActionGroup.defineAction<{
  payload: BaseMarginData;
}>('SET BASE MARGIN');

export const fetchIndustriesFilterIds = baseMarginActionGroup.defineAction<{
  payload: string;
}>('FETCH INDUSTRIES FILTERS IDS');
export const setIndustriesFilterIds = baseMarginActionGroup.defineAction<{
  payload: string[];
}>('SET INDUSTRIES FILTERS IDS');

export const setIndustriesFiltersSelectedAction = baseMarginActionGroup.defineAction<{
  payload: TFilterItemResponse[];
}>('SET SELECTED FILTER');

export const fetchGroupFilterBaseMatrixAction = baseMarginActionGroup.defineAction<{
  payload: string;
}>('FETCH GROUP FILTER BASE MATRIX');

export const setGroupFilterBaseMatrixAction = baseMarginActionGroup.defineAction<{
  payload: {
    data: TFilterItemResponse[];
    total: number;
  };
}>('SET GROUP FILTER BASE MATRIX');

export const saveBaseMarginAction = baseMarginActionGroup.defineAction<{
  payload: UpdateBaseMatrixDto;
}>('SAVE BASE MARGIN');
export const saveBaseMarginToPagination = baseMarginActionGroup.defineAction<{
  payload: UpdateBaseMatrixDto;
}>('SAVE BASE MARGIN TO PAGINATION');

export const setBaseMarginLoadingAction = baseMarginActionGroup.defineAction<{
  payload: boolean;
}>('SET BASE MARGIN LOADING');

export const setBaseMarginBulk = baseMarginActionGroup.defineAction<{
  payload: SetBulkBaseMargin;
}>('SET BASE MARGIN BULK');
export const setShowExceptionAction = baseMarginActionGroup.defineAction<{
  payload: boolean;
}>('SET SHOW EXCEPTION');

// exceptions actions //

export const fetchBaseMarginExceptionAction = baseMarginActionGroup.defineAction<{
  payload: BaseMatrixExceptionsDTO;
}>('FETCH BASE MARGIN EXCEPTIONS');

export const fetchExceptionHighlightsAction = baseMarginActionGroup.defineAction<{}>('FETCH EXCEPTIONS HIGHLIGHTS');

export const setBaseMarginExceptionAction = baseMarginActionGroup.defineAction<{
  payload: BaseMarginExceptions;
}>('SET BASE MARGIN EXCEPTIONS');

export const updateBaseMarginExceptionAction = baseMarginActionGroup.defineAction<{
  payload: UpdateBaseMarginException[];
}>('UPDATE BASE MARGIN EXCEPTION');
export const resetException = baseMarginActionGroup.defineAction(
  'RESET EXCEPTION'
);
export const updateBaseMarginExceptionPaginationAction = baseMarginActionGroup.defineAction<{
  payload: UpdateBaseMarginException[];
}>('UPDATE BASE MARGIN EXCEPTION PAGINATION');

export const deleteExceptionAction = baseMarginActionGroup.defineAction<{
  payload: string[];
}>('DELETE EXCEPTION');

export const setUpdateBaseMarginException = baseMarginActionGroup.defineAction<{
  payload: Record<string, UpdateData>;
}>('SET UPDATE BASE MARGIN EXCEPTION');
export const fetchUpdateBaseMarginException = baseMarginActionGroup.defineAction<{
  payload: Record<string, UpdateData>;
}>('FETCH UPDATE BASE MARGIN EXCEPTION');

export const getCurrentExceptionIds = baseMarginActionGroup.defineAction<{
  payload: Exception[];
}>('GET CURRENT EXCEPTION IDS');
export const setCurrentExceptionIds = baseMarginActionGroup.defineAction<{
  payload: Exception[];
}>('SET CURRENT EXCEPTION IDS');

export const setExceptionHighlights = baseMarginActionGroup.defineAction<{
  payload: ExceptionHighlight[];
}>('SET EXCEPTION HIHGLILGHTS');

//exception modal
export const fetchProductGroups = baseMarginActionGroup.defineAction<{
  payload: {
    id: string[] | undefined;
  };
}>('FETCH PRODUCT GROUP');
export const setProductGroup = baseMarginActionGroup.defineAction<{
  payload: ProductGroups;
}>('SET PRODUCT GROUP');

export const fetchProductsName = baseMarginActionGroup.defineAction<{
  payload: {
    id: string[];
    valueAllocation: number[];
  };
}>('FETCH PRODUCTS NAME');
export const setProductsName = baseMarginActionGroup.defineAction<{
  payload: ProductsName;
}>('SET PRODUCTS NAME');

export const fetchIndustries = baseMarginActionGroup.defineAction<{
  payload: {
    industryId: string[] | undefined;
  };
}>('FETCH INDUSTRIES');
export const setIndusties = baseMarginActionGroup.defineAction<{
  payload: IndustriesData[];
}>('SET INDUSTRIES');

export const fetchExceptionName = baseMarginActionGroup.defineAction(
  'FETCH EXCEPTION NAME'
);
export const setExceptionName = baseMarginActionGroup.defineAction<{
  payload: string;
}>('SET EXCEPTION NAME');

export const createExceptionAction = baseMarginActionGroup.defineAction<{
  payload: CreateException;
}>('CREATE EXCEPTION');

export const openModalExceptionAction = baseMarginActionGroup.defineAction<{
  payload: boolean;
}>('OPEN MODAL EXCEPTION');
