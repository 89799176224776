import { useState, useCallback, useLayoutEffect } from 'react';
import { cloneArray } from './utils/cloneArray';
import {
  AbstractFilter,
  buildFilterMatrix,
  FilterMatrix,
} from './utils/buildFilterMatrix';
import { formatFilterMatrix } from './utils/formatFilterMatrix';

export type TFilters = AbstractFilter[];

export type ProductFiltersHandler = (
  matrixIndex: number,
  filters: TFilters,
  isAll?: boolean
) => FilterMatrix;

export type SetFilterOptionsHandler = (valueMatrix: FilterMatrix) => void;

function buildDefaultMatrix() {
  return new Array(4).fill([]);
}

export function useProductFilters(
  filters: AbstractFilter[],
  value?: AbstractFilter[]
): [FilterMatrix, FilterMatrix, FilterMatrix, ProductFiltersHandler] {

  const [filterState, setFilterState] = useState<{
    originFilters: AbstractFilter[];
    filterMatrix: FilterMatrix;
    valueMatrix: FilterMatrix;
    staticMatrix: FilterMatrix;
  }>({
    originFilters: buildDefaultMatrix(),
    filterMatrix: buildDefaultMatrix(),
    valueMatrix: buildDefaultMatrix(),
    staticMatrix: buildDefaultMatrix(),
  });

  const buildFilterOptionsFromValue = (valueMatrix: FilterMatrix) => {
    const originMatrix = buildFilterMatrix(filterState.originFilters);
    if (!originMatrix.length) return [];
    const options = formatFilterMatrix(
      0,
      originMatrix,
      [...valueMatrix],
      'down'
    );

    options[0] = originMatrix[0];

    return options;
  };

  useLayoutEffect(() => {
    if (value === undefined) return;

    const nextValueMatrix = value.length
      ? buildFilterMatrix(value)
      : new Array(4).fill([]);

    setFilterState((prev) => ({
      ...prev,
      valueMatrix: nextValueMatrix,
      filterMatrix: buildFilterOptionsFromValue(nextValueMatrix),
    }));
    // eslint-disable-next-line
  }, [value]);

  useLayoutEffect(() => {
    setFilterState((prev) => ({
      ...prev,
      originFilters: cloneArray(filters),
    }));
  }, [filters]);

  useLayoutEffect(() => {
    if (!filterState.originFilters.length) return;

    const filterMatrix = buildFilterMatrix(filterState.originFilters);

    setFilterState((prev) => ({
      ...prev,
      filterMatrix,
      staticMatrix: filterMatrix.map((f) => cloneArray(f)),
    }));
  }, [filterState.originFilters]);

  const handler: ProductFiltersHandler = useCallback(
    (matrixIndex, filters) => {
      const { originFilters, valueMatrix } = filterState;

      const originMatrix = buildFilterMatrix(originFilters);
      const nextValueMatrix = [...valueMatrix];

      nextValueMatrix[matrixIndex] = filters;

      const rebuildedValueMatrix = formatFilterMatrix(
        matrixIndex,
        originMatrix,
        formatFilterMatrix(
          matrixIndex,
          originMatrix,
          [...nextValueMatrix],
          'up'
        ),
        'down'
      );

      const value = rebuildedValueMatrix;
      return value;
    },
    [filterState]
  );

  return [
    filterState.staticMatrix,
    filterState.filterMatrix,
    filterState.valueMatrix,
    handler,
  ];
}
