import React from 'react';
import { useDispatch } from 'react-redux';
import { resetSelectedCoAction } from '@app/store/filters-module/co';
import { resetSelectedFilters } from '@app/store/filters-module/filters-state';
import { TabRouterLayout } from '@app/shared/components/TabRouterLayout';
import { baseMatrixTabs } from './tab-definitions';
import { fetchCOWithCurrency } from '@app/store/baseMargin';

export const BaseMarginMatrix: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCOWithCurrency({}));

    return () => {
      dispatch(resetSelectedCoAction({}));
      dispatch(resetSelectedFilters({}));
    };
  }, [dispatch]);
  return (
    <TabRouterLayout routes={baseMatrixTabs} pageTitle="Base Margin Matrix" />
  );
};
