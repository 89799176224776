import {
  CommercialOrganizationsWithCurrency,
  CustomMargins,
  DefaultMargin,
} from '@app/shared/types/baseMargin';
import { validate } from '@app/shared/utils/validate';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import { CurrentDataToUpdate } from '../BaseMatrix';
import { schema } from '../utils/validation';

interface Props {
  open: boolean;
  onClose: () => void;
  data: CurrentDataToUpdate | undefined;
  marginValues: Record<string, CustomMargins>;
  defaultMargin: Record<string, DefaultMargin>;
  currentCurrency: CommercialOrganizationsWithCurrency | undefined;
  onChangeMarginValue: (
    data: CustomMargins | DefaultMargin,
    isDefault: boolean,
    id: string
  ) => void;
}

export const EditMarginModal: React.FC<Props> = (props) => {
  const [valueMargin, setValueMargin] = useState<CustomMargins>();
  const [defaultValueMargin, setDefaultValueMargin] = useState<DefaultMargin>();
  const [marginError, setMarginError] = useState<Record<string, string>>({});
  const {
    open,
    onClose,
    data,
    marginValues,
    defaultMargin,
    currentCurrency,
    onChangeMarginValue,
  } = props;
  const industrie = useMemo(() => {
    if (data?.isDefault === false) {
      const currentIndustrie = data.row.industries.find(
        (item) => item.id === data.industrieId
      );
      return currentIndustrie;
    }
  }, [data]);
  const industriesId = useMemo(() => {
    if (data?.isDefault === false) {
      return data.row.id + industrie?.id + data.row.valueAllocation;
    }
  }, [data, industrie]);
  const defaultId = useMemo(() => {
    if (data?.isDefault) {
      return data.row.id + data.row.valueAllocation;
    }
  }, [data]);
  useEffect(() => {
    if (industrie && data?.isDefault === false) {
      const value = marginValues[
        data.row.id + industrie.id + data.row.valueAllocation
      ]
        ? marginValues[data.row.id + industrie.id + data.row.valueAllocation]
          .value
        : industrie.customMargin || '';
      const type = marginValues[
        data.row.id + industrie.id + data.row.valueAllocation
      ]
        ? marginValues[data.row.id + industrie.id + data.row.valueAllocation]
          .type
        : industrie.customMarginType || 0;

      setValueMargin({
        productGroupId: data.row.id,
        valueAllocation: data.row.valueAllocation,
        industryId: industrie.id,
        type: type || 0,
        value,
      });
    } else if (data?.isDefault) {
      const value = defaultMargin[data.row.id + data.row.valueAllocation]
        ? defaultMargin[data.row.id + data.row.valueAllocation].value
        : data.row.defaultMargin || '';
      const type = defaultMargin[data.row.id + data.row.valueAllocation]
        ? defaultMargin[data.row.id + data.row.valueAllocation].type
        : data.row.defaultMarginType || 0;
      setDefaultValueMargin({
        value,
        productGroupId: data.row.id,
        type: type || 0,
        valueAllocation: data.row.valueAllocation,
      });
    }
  }, [data, defaultMargin, industrie, marginValues]);

  const handleChangeInput = (e: any) => {
    if (data?.isDefault) {
      setDefaultValueMargin({
        ...(defaultValueMargin as DefaultMargin),
        value: e.target.value,
      });
    }
    if (!data?.isDefault) {
      setValueMargin({
        ...(valueMargin as CustomMargins),
        value: e.target.value,
        type: data?.row.defaultMarginType as number,
      });
    }
  };

  const handleChangeSelect = (e: any) => {
    if (data?.isDefault) {
      setDefaultValueMargin({
        ...(defaultValueMargin as DefaultMargin),
        type: +e.target.value,
      });
      return;
    }
    if (!data?.isDefault) {
      setValueMargin({
        ...(valueMargin as CustomMargins),
        type: +e.target.value,
      });
    }
  };

  const inputRender = () => {
    const error = Object.keys(marginError).length;

    return (
      data && (
        <TextField
          fullWidth
          type="number"
          value={
            data.isDefault
              ? defaultValueMargin?.value
              : valueMargin?.value || ''
          }
          error={error ? true : false}
          helperText={error ? 'Please input value' : ''}
          onChange={(e) => handleChangeInput(e)}
        />
      )
    );
  };

  const selectRender = () => {
    return (
      <>
        {currentCurrency && data && data.isDefault ? (
          <>
            <Select
              fullWidth
              value={
                data.isDefault
                  ? defaultValueMargin?.type
                  : valueMargin?.type || 0
              }
              onChange={(e) => handleChangeSelect(e)}
            >
              {currentCurrency.coCurrencies.map((item, index: number) => (
                <MenuItem key={index} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
            <p style={{ fontSize: '10px', color: '#ff5000', marginTop: '5px' }}>
              Сhanging the default margin type cleans up <br></br>the industry
              deviations
            </p>
          </>
        ) : null}
      </>
    );
  };

  const checkField = () => {
    if (data?.isDefault) {
      const valid = validate(
        {
          marginType: defaultValueMargin?.type,
          margin: defaultValueMargin?.value,
        },
        schema
      );
      setMarginError(valid);
      return valid;
    }
    const valid = validate(
      { marginType: defaultValueMargin?.type, margin: valueMargin?.value },
      schema
    );
    setMarginError(valid);
    return valid;
  };
  const handleClickUpdate = () => {
    const error = Object.keys(checkField()).length;
    if (data?.isDefault && defaultValueMargin && defaultId && !error) {
      onChangeMarginValue(defaultValueMargin, data.isDefault, defaultId);

      onClose();
      return;
    }
    if (data?.isDefault === false && valueMargin && industriesId && !error) {
      onChangeMarginValue(
        { ...valueMargin, rowId: data.row.id },
        data.isDefault,
        industriesId
      );
      onClose();
      return;
    }
  };


  const handleClickClear = () => {

    if (data?.isDefault && defaultValueMargin && defaultId) {

      const clearedItem = {
        ...(defaultValueMargin as DefaultMargin),
        value: '',
      }

      onChangeMarginValue(clearedItem,
        data.isDefault,
        defaultId
      );
    }
    if (data?.isDefault === false && valueMargin && industriesId) {

      const clearedItem = {
        ...valueMargin,
        rowId: data.row.id,
        value: ''
      }

      onChangeMarginValue(
        clearedItem,
        data.isDefault,
        industriesId
      );
    }

    onClose();

    return;
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Margin</DialogTitle>
      <DialogContent>
        {inputRender()}
        {selectRender()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClickClear} color="primary">
          Clear
        </Button>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={handleClickUpdate}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
