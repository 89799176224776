import { Action } from 'redux';
import { WizardState, initialState } from '@app/store/wizard/state';
import {
  addNewBaseMarginMatrixItemAction,
  addPackagingMarginAction,
  changePackagingMarginValueAction,
  deleteBaseMarginMatrixItemsByIdsAction,
  deletePackagingMarginAction,
  editBaseMarginMatrixItemByIdAction,
  editDefaultBaseMarginMatrixAction,
  setBaseMarginMatrixIndustriesActions,
  setBaseMarginMatrixOriginAction,
  setBaseMarginMatrixStateAction,
  setPackagingMarginOriginAction,
  setPackagingMarginStateAction,
  setSelectedSimulatorCoAction,
  setSelectedSimulatorProductAction,
  setSelectedSimulatorValueAllocationAction,
  setTPRBaseMarginAction,
  setTPRCOAction,
  setTPRIndustriesAction,
  setTPRMarginVolumeDriverAction,
  setTPRProductValuesAction,
  setTPRVolumeDriverAction,
  setUpdateWizardPGAVolumeDriverAction,
  setWizardCOIdAction,
  setWizardCommercialOrganizationsAction,
  setWizardCurrentAllocationLevelsAction,
  setWizardCurrentsValuesAllocationAction,
  setWizardLoadingAction,
  setWizardLookupVolumeSystemAction,
  setWizardMarginSystemAction,
  setWizardPackagingGroupAction,
  setWizardPGAVolumeDriverAction,
  setWizardProductAllocationInfoAction,
  setWizardProductGroupAllocationAction,
  setWizardProductGroupAllocationLevelsAction,
  setWizardUpdateProductAllocationInfoAction,
  setWizardVolumeSystemAction,
} from '@app/store/wizard/actions';
import { WizardPackagingMarginEntryState } from '@shared/types/wizard';
import { setTPRProductGroupAction } from '@app/store/wizard/actions';
import { setTPRPackagingDriverAction } from '@app/store/wizard/actions';

export const wizardReducer = (
  state: WizardState = initialState,
  action: Action
): WizardState => {

  if (setSelectedSimulatorCoAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        selectedCo: action.payload
      },
    };

  if (setSelectedSimulatorProductAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        selectedProduct: action.payload
      },
    };

  if (setSelectedSimulatorValueAllocationAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        selectedValueAllocation: action.payload
      },
    };


  //===== BASE =====
  if (setWizardLoadingAction.is(action))
    return {
      ...state,
      loading: action.payload,
    };

  if (setWizardCOIdAction.is(action))
    return {
      ...state,
      coId: action.payload,
      currentAllocationLevel: null,
      updateProductGroupAllocationInfo: {},
      currentsValueAllocation: [],
      productGroupAllocationLevels: [],
      packagingGroup: [],
      targetPriceResult: {
        ...state.targetPriceResult,
        productGroup: [],
      },
    };

  if (setWizardCommercialOrganizationsAction.is(action))
    return {
      ...state,
      commercialOrganizations: action.payload,
    };

  //===== DATA SETTERS =====
  if (setWizardVolumeSystemAction.is(action))
    return { ...state, volumeSystem: action.payload };

  if (setWizardLookupVolumeSystemAction.is(action))
    return { ...state, lookupVolumeSystem: action.payload };

  if (setWizardProductGroupAllocationLevelsAction.is(action))
    return { ...state, productGroupAllocationLevels: action.payload };

  if (setWizardCurrentAllocationLevelsAction.is(action))
    return {
      ...state,
      currentAllocationLevel: action.payload,
      currentsValueAllocation: [],
    };

  if (setWizardProductGroupAllocationAction.is(action))
    return { ...state, productGroupAllocations: action.payload };

  if (setWizardProductAllocationInfoAction.is(action))
    return { ...state, productGroupAllocationInfo: action.payload };

  if (setWizardUpdateProductAllocationInfoAction.is(action))
    return { ...state, updateProductGroupAllocationInfo: action.payload };

  if (setWizardPackagingGroupAction.is(action))
    return { ...state, packagingGroup: action.payload };

  if (setWizardCurrentsValuesAllocationAction.is(action))
    return { ...state, currentsValueAllocation: action.payload };

  if (setWizardMarginSystemAction.is(action))
    return { ...state, marginSystem: action.payload };

  if (setWizardPGAVolumeDriverAction.is(action))
    return { ...state, pgaVolumeDriver: action.payload };

  if (setUpdateWizardPGAVolumeDriverAction.is(action))
    return { ...state, pgaVolumeDriverUpdate: action.payload };

  if (setBaseMarginMatrixOriginAction.is(action))
    return { ...state, baseMarginMatrixOrigin: action.payload };

  if (setBaseMarginMatrixStateAction.is(action))
    return { ...state, baseMarginMatrix: action.payload };

  if (deleteBaseMarginMatrixItemsByIdsAction.is(action))
    return {
      ...state,
      baseMarginMatrix: state.baseMarginMatrix.map((i) =>
        i.valueAllocation === action.payload.valueAllocation
          ? {
            ...i,
            industryMargins: i.industryMargins.filter(
              (i) => !action.payload.ids.some((id) => i.id === id)
            ),
          }
          : i
      ),
    };

  if (editBaseMarginMatrixItemByIdAction.is(action))
    return {
      ...state,
      baseMarginMatrix: state.baseMarginMatrix.map((i) =>
        i.valueAllocation === action.payload.valueAllocation
          ? {
            ...i,
            industryMargins: i.industryMargins.map((i) =>
              i.id === action.payload.id ? action.payload.item : i
            ),
          }
          : i
      ),
    };

  if (addNewBaseMarginMatrixItemAction.is(action))
    return {
      ...state,
      baseMarginMatrix: state.baseMarginMatrix.map((i) =>
        i.valueAllocation === action.payload.valueAllocation
          ? {
            ...i,
            industryMargins: i.industryMargins.concat([action.payload.item]),
          }
          : i
      ),
    };

  if (editDefaultBaseMarginMatrixAction.is(action))
    return {
      ...state,
      baseMarginMatrix: state.baseMarginMatrix.map((i) =>
        i.valueAllocation === action.payload.valueAllocation
          ? {
            ...i,
            marginValues: action.payload.marginValues,
            marginTypes: action.payload.marginTypes,
            industryMargins:
              action.payload.marginTypes === i.marginTypes
                ? i.industryMargins
                : i.industryMargins.map((i) => ({
                  ...i,
                  marginValue: '—',
                })),
          }
          : i
      ),
    };

  if (setBaseMarginMatrixIndustriesActions.is(action))
    return { ...state, bmmIndustries: action.payload };

  //===== PACKAGING MARGIN =====

  if (setPackagingMarginOriginAction.is(action))
    return {
      ...state,
      packagingMarginOrigin: action.payload,
    };

  if (setPackagingMarginStateAction.is(action))
    return {
      ...state,
      packagingMargin: action.payload,
    };

  if (changePackagingMarginValueAction.is(action))
    return {
      ...state,
      packagingMargin: {
        ...state.packagingMargin,
        packaging: state.packagingMargin.packaging.map((i) =>
          i.id === action.payload.id
            ? {
              ...i,
              values: i.values.map((value, index) =>
                index === action.payload.index
                  ? {
                    ...value,
                    value: action.payload.value,
                  }
                  : value
              ),
            }
            : i
        ),
      },
    };

  if (deletePackagingMarginAction.is(action))
    return {
      ...state,
      packagingMargin: {
        ...state.packagingMargin,
        packaging: state.packagingMargin.packaging.reduce(
          (res, i) =>
            action.payload[i.id]
              ? i.values.some((i) => i.default)
                ? [
                  ...res,
                  {
                    ...i,
                    values: i.values.map((v) =>
                      v.default ? v : { value: '—', default: false }
                    ),
                  },
                ]
                : res
              : [...res, i],
          [] as WizardPackagingMarginEntryState[]
        ),
      },
    };

  if (addPackagingMarginAction.is(action))
    return {
      ...state,
      packagingMargin: {
        ...state.packagingMargin,
        packaging: state.packagingMargin.packaging.concat([
          {
            id: action.payload.id,
            name: action.payload.name,
            values: action.payload.values.map((value) => ({
              value,
              default: false,
            })),
            marginType: action.payload.marginType,
          },
        ]),
      },
    };

  //===== TARGET PRICE RESULTS =====

  if (setTPRCOAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        coId: action.payload,
      },
    };

  if (setTPRProductGroupAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        productGroup: action.payload,
      },
    };

  if (setTPRIndustriesAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        industries: action.payload,
      },
    };

  if (setTPRProductValuesAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        productValues: action.payload,
      },
    };

  //===== set table data =====

  if (setTPRBaseMarginAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        baseMargin: action.payload,
      },
    };
  if (setTPRPackagingDriverAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        packagingDriver: action.payload,
      },
    };
  if (setTPRVolumeDriverAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        volumeDriver: action.payload,
      },
    };
  if (setTPRMarginVolumeDriverAction.is(action))
    return {
      ...state,
      targetPriceResult: {
        ...state.targetPriceResult,
        marginVolumeDriver: action.payload,
      },
    };

  return state;
};
