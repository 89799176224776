import { TVolumeSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';
import { defineActionGroup } from 'rd-redux-utils';

export const volumeSystemConfigurationsActionGroup = defineActionGroup(
  'VOLUME SYSTEM CONFIGURATIOSN'
);

const group = volumeSystemConfigurationsActionGroup;

export const fetchVolumeDriverConfigurationsDataAction = group.defineAction<{
  skip: number;
  limit: number;
}>('FETCH DATA');

export const fetchVolumeDriverConfigurationsDoneAction = group.defineAction<{
  items: TVolumeSystemConfiguration[];
  total: number;
}>('FETCH DATA DONE');

export const setVolumeDriverConfigurationsLoadingAction = group.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setVolumeDriverConfigurationsDataAction = group.defineAction<{
  items: TVolumeSystemConfiguration[];
  total: number;
}>('SET DATA');

export const updateVolumeDriverConfigurationAction = group.defineAction<{
  id: string;
  updatedPayload: TVolumeSystemConfiguration;
}>('UPDATE ITEM');

export const setVolumeSystemConfigurationsModalOpenedAction = group.defineAction<{
  opened: boolean;
}>('SET MODAL OPENED STATE');

export const setVolumeSystemConfigurationsCreatingAction = group.defineAction<{
  creating: boolean;
}>('SET CREATING');

export interface CreateSystemConfigurationActionPayload {
  unit: number;
  name: string;
  description: string;
  firstLevel: number;
  secondLevel: number;
  thirdLevel: number;
  fourthLevel: number;
  fifthLevel: number;
}

export const createSystemConfigurationAction = group.defineAction<
  CreateSystemConfigurationActionPayload
>('CREATE SYSTEM');

export const deleteVolumeSystemConfigurationsAction = group.defineAction<{
  ids: string[];
}>('DELETE ITEMS');

export const saveVolumeSystemConfigurationsAction = group.defineAction(
  'SAVE CONFIGURATIONS'
);

export const resetUpdatedVolumeConfigurationsAction = group.defineAction(
  'RESET UPDATED ITEMS'
);

export const setNameExsitsAction = group.defineAction<{ payload: boolean }>(
  'NAME EXISTS'
);
