import * as React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

export type AppButtonProps = ButtonProps;

type Props = AppButtonProps;

export function AppButton(props: Props) {
  const { className, variant = 'contained', ...rest } = props;

  return <Button {...rest} variant={variant}></Button>;
}
