import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { RightsApi } from '@app/services/rights-service/rights-service';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select, take } from 'redux-saga/effects';
import {
  fetchProductRightsAction,
  forceSaveGroupRightsAction,
  setProductRightsDataAction,
  setProductRightsLoadingAction,
  updateGroupRightsAction,
} from '../actions';

export function* forceSaveGroupRightsSaga(
  action: ReturnType<typeof forceSaveGroupRightsAction>
) {
  try {
    yield put(setProductRightsLoadingAction({ loading: true }));

    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);
    const updateItems = action.items;

    const dto = Object.keys(updateItems).map((i) => {
      const currentManager = updateItems[i].managers.find(
        (f) => f.isPrimaryProductOwner === true
      );
      return {
        productGroupId: i,
        userId: currentManager?.id as string,
      };
    });
    yield call(RightsApi.updateProductGroupRights, {
      coId: selectedCO.id,
      items: dto,
    });
    yield put(fetchProductRightsAction({}));
    yield take(setProductRightsDataAction.TYPE);
    yield put(updateGroupRightsAction({ items: {} }));

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Rights sucessfully updated for selected group.',
          options: {
            variant: 'success',
          },
          key: 'updated-rights',
        },
      })
    );
  } catch (e) {
    console.log('Failed to set rights', e.message);
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'product-rights-save',
          },
        })
      );
    }

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'product-rights-save',
        },
      })
    );
  } finally {
    yield put(setProductRightsLoadingAction({ loading: false }));
  }
}
