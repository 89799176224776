import { Action } from 'redux';
import {
  resetUpdateDataAction,
  setDriverConfigurationsDataAction,
  setDriverConfigurationsLoadingAction,
  setDriverConfigurationsSavingAction,
  setUpdateDataAction,
} from './actions';
import { DriverConfigurationsState, initialState } from './state';

export function driverConfigurationReducer(
  state = initialState,
  action: Action
): DriverConfigurationsState {
  if (setDriverConfigurationsDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
      totalItems: action.total,
    };
  }

  if (setDriverConfigurationsLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setDriverConfigurationsSavingAction.is(action)) {
    return {
      ...state,
      isSaving: action.saving,
    };
  }
  if (setUpdateDataAction.is(action)) {
    return {
      ...state,
      updateData: {
        ...state.updateData,
        ...action.updateData,
      },
    };
  }
  if (resetUpdateDataAction.is(action)) {
    return {
      ...state,
      updateData: {},
    };
  }

  return state;
}
