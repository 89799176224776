import { FilterApi } from '@app/services/filter-service/filter-service';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { call, put } from 'redux-saga/effects';
import {
  setFiltersAction,
  setFiltersLoadingAction,
  setSelectedFiltersAction,
} from '../actions';

export function* fetchFiltersSaga() {
  try {
    yield put(setFiltersLoadingAction({ loading: true }));
    const request: {
      filters: TFilterItemResponse[];
    } = yield call(FilterApi.getFilters);

    yield put(
      setFiltersAction({
        filters: request.filters,
      })
    );

    yield put(setSelectedFiltersAction({ filters: [...request.filters] }));

    yield put(setFiltersLoadingAction({ loading: false, loaded: true }));
  } catch (e) {
    console.log('Failed to fetch filters', e.message);
  }
}
