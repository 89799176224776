import { RoleProtectedInput } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedInput';
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { UnitSelect } from '@app/modules/VolumeDriver/SystemConfigurations/components/UnitSelect';
import { validate } from '@app/shared/utils/validate';
import { volumeSystemFormSchema } from './volumeSystemFormSchema';
import { selectNameExists } from '@app/store/volume-driver/volume-system-configurations';
import { useSelector } from 'react-redux';

export interface VolumeSystemFormData {
  name: string;
  unit: number;
  description: string;
  firstLevel: number;
  secondLevel: number;
  thirdLevel: number;
  fourthLevel: number;
  fifthLevel: number;
}

interface Props {
  formId: string;
  onSubmit: (payload: VolumeSystemFormData) => void;
}

const initialFormData: VolumeSystemFormData = {
  name: '',
  unit: 1,
  description: '',
  firstLevel: null as any,
  secondLevel: null as any,
  thirdLevel: null as any,
  fourthLevel: null as any,
  fifthLevel: null as any,
};

export const VolumeSystemForm = (props: Props) => {
  const { onSubmit, formId } = props;
  const nameExsits = useSelector(selectNameExists);
  const [formData, setFormData] = React.useState<VolumeSystemFormData>(
    initialFormData
  );

  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const handleChange = React.useCallback((e: any) => {
    const { name, value, type } = e.target;

    setErrors((prev) => ({ ...prev, [name]: '' }));

    setFormData((prev) => ({
      ...prev,
      [name]: type === 'number' ? (value === '' ? null : Number(value)) : value,
    }));
  }, []);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const errors = validate(formData, volumeSystemFormSchema);

      if (Object.keys(errors).length) {
        setErrors(errors);
        return;
      }

      onSubmit(formData);
    },
    [onSubmit, formData]
  );

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <Grid container wrap="nowrap" direction="column">
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter system name"
              label="System name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors['name'] || nameExsits}
              helperText={nameExsits ? 'Name already exists' : errors['name']}
            />
          </Grid>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter description"
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <UnitSelect
              fullWidth
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter level 1"
              label="Level 1"
              name="firstLevel"
              value={String(formData.firstLevel) || ''}
              onChange={handleChange}
              type="number"
              error={!!errors['firstLevel']}
              helperText={errors['firstLevel']}
            />
          </Grid>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter level 2"
              label="Level 2"
              name="secondLevel"
              value={String(formData.secondLevel) || ''}
              onChange={handleChange}
              type="number"
              error={!!errors['secondLevel']}
              helperText={errors['secondLevel']}
            />
          </Grid>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter level 3"
              label="Level 3"
              name="thirdLevel"
              value={String(formData.thirdLevel) || ''}
              onChange={handleChange}
              type="number"
              error={!!errors['thirdLevel']}
              helperText={errors['thirdLevel']}
            />
          </Grid>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter level 4"
              label="Level 4"
              name="fourthLevel"
              value={String(formData.fourthLevel) || ''}
              onChange={handleChange}
              type="number"
              error={!!errors['fourthLevel']}
              helperText={errors['fourthLevel']}
            />
          </Grid>
          <Grid item>
            <RoleProtectedInput
              fullWidth
              placeholder="Enter level 5"
              label="Level 5"
              name="fifthLevel"
              value={String(formData.fifthLevel) || ''}
              onChange={handleChange}
              type="number"
              error={!!errors['fifthLevel']}
              helperText={errors['fifthLevel']}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
