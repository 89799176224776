import { TPackagingDriverConfiguration } from '@app/services/packaging-driver-service/response-interfaces/get-configurations.response';
import { AppSelect } from '@app/shared/components/AppSelect';
import { AppTable } from '@app/shared/components/AppTable';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import { selectPackagingGroupsItems } from '@app/store/filters-module/packaging-groups/selectors';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { sortByPackagingGroup, sortByPackagingType } from './sortHelpers';
const { Row, Head, HeadCell, Body, Cell } = AppTable;

export interface PackagingConfigurationTableItem
  extends TPackagingDriverConfiguration {}

interface Props {
  items: PackagingConfigurationTableItem[];
  totalItems: number;
  loading: boolean;
  tableData: Record<string, string>;
  onChange: (id: string, groupId: string) => void;
}

export const PackagingConfigurationsTable = (props: Props) => {
  const { totalItems, items, loading, tableData, onChange } = props;

  const packagingOptions = useSelector(selectPackagingGroupsItems);

  const SelectOptions = React.useMemo(() => {
    return packagingOptions.map((option) => {
      return (
        <AppSelect.Option value={option.id} key={option.id}>
          {option.name}
        </AppSelect.Option>
      );
    });
  }, [packagingOptions]);

  const renderBody = React.useCallback(
    (data: PackagingConfigurationTableItem[]) => {
      const items = data.map((item) => {
        const value = tableData[item.packagingType.id]
          ? tableData[item.packagingType.id]
          : item?.packagingGroup?.id;

        return (
          <Row key={item.packagingType.id}>
            <Cell width={'70%'}>{item.packagingType.name}</Cell>
            <Cell>
              <RoleProtectedSelect
                value={value || 'none'}
                displayEmpty
                name="packagingGroup"
                onChange={(e) => {
                  const newPackagingGroupId = e.target.value as string;
                  onChange(item.packagingType.id, newPackagingGroupId);
                }}
              >
                <MenuItem value="none" key="none">
                  Choose Group
                </MenuItem>
                {SelectOptions}
              </RoleProtectedSelect>
            </Cell>
          </Row>
        );
      });

      return items;
    },
    [onChange, SelectOptions, tableData]
  );

  return (
    <AppTable
      data={items}
      dataCount={totalItems}
      onPageChange={() => {}}
      loading={loading}
    >
      <Head>
        <Row isHead={true}>
          <HeadCell width={'70%'} sortHandler={sortByPackagingType}>
            Packaging Type
          </HeadCell>
          <HeadCell sortHandler={sortByPackagingGroup}>
            Packaging Group
          </HeadCell>
        </Row>
      </Head>
      <Body>{renderBody}</Body>
    </AppTable>
  );
};
