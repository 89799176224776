import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  loading?: boolean;
  className?: string;
}

export const AppLoader = (props: Props) => {
  const { loading, className } = props;

  return loading ? <CircularProgress className={className} /> : null;
};
