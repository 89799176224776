import { TGetVolumeSystemOptionsResponse } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-options.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { call, put } from 'redux-saga/effects';
import {
  fetchVolumeOptionsAction,
  setVolumeOptionsDataAction,
  setVolumeOptionsLoadingAction,
} from '../actions';

export function* fetchVolumeOptionsSaga(
  action: ReturnType<typeof fetchVolumeOptionsAction>
) {
  try {
    yield put(setVolumeOptionsLoadingAction({ loading: true }));

    const request: TGetVolumeSystemOptionsResponse = yield call(
      VolumeDriverApi.getVolumeSystemOptions
    );

    yield put(setVolumeOptionsDataAction({ items: request.data }));
  } catch (e) {
    console.log('Failed to get volume options', e.message);
  } finally {
    yield put(setVolumeOptionsLoadingAction({ loading: false }));
  }
}
