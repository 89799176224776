import { defineActionGroup } from 'rd-redux-utils';
import { ManagersItem } from './state';

const managersActionGroup = defineActionGroup('MANAGERS');

export const fetchManagersAction = managersActionGroup.defineAction<{
  filter?: {
    groupsIdLevelFour: string[];
    productGroupValues: string[];
  };
}>('GET');

export const setManagersLoadingAction = managersActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setManagersDataAction = managersActionGroup.defineAction<{
  payload: ManagersItem[];
  coId?: string;
}>('SET DATA');

export const fetchManagersByCoAction = managersActionGroup.defineAction<{
  coId: string;
}>('GET MANAGERS BY CO');
