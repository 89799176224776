import { AppSelect } from '@app/shared/components/AppSelect';
import { FilterContainer } from '@app/shared/components/FilterContainer';
import { ProtectedButtonWithHint } from '@app/shared/components/ProtectedButtonWithHint';
import { selectSelectedFilters } from '@app/store/filters-module/filters-state';
import { selectSelectedProductValueAllocationLevels } from '@app/store/productValue/selectors';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBulkAllocationValueAction } from '@app/store/productValue';
import { Filters } from '../../../Filters';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import { defaultSelectIndex } from '@app/shared/utils/appConst';

interface Props {
  shouldAskForConfirm: boolean;
  onConfirm: () => void;
  onSkip: () => void;
}

export const ProductLabelingHeader: React.FC<Props> = (props) => {
  const { shouldAskForConfirm, onConfirm, onSkip } = props;

  const dispatch = useDispatch();
  const filtersValue = useSelector(selectSelectedFilters);
  const allocationLevelsValue = useSelector(
    selectSelectedProductValueAllocationLevels
  );

  const [allocationValueBulk, setAllocationValueBulk] = useState(defaultSelectIndex);

  const onChangeBulkAllocationValue = useCallback((e: any) => {
    setAllocationValueBulk(+e.target.value);
  }, []);

  const onBulkAllocationValue = useCallback(() => {
    const productFilter4level = filtersValue.filter((i) => i.level === 4);

    dispatch(
      updateBulkAllocationValueAction({
        payload: {
          productGroupIds: productFilter4level.map((i) => i.id),
          valueAllocations: allocationLevelsValue.map((level) => level.key),
          valueAllocation: allocationValueBulk,
        },
      })
    );
    setAllocationValueBulk(defaultSelectIndex);
  }, [filtersValue, allocationLevelsValue, allocationValueBulk, dispatch]);
  return (
    <Grid item container xs={12} spacing={2} alignItems="stretch">
      <Grid item xs={6} md={5} lg={4}>
        <Filters
          shouldAskForConfirm={shouldAskForConfirm}
          onConfirm={onConfirm}
          onSkip={onSkip}
        />
      </Grid>
      <Grid item xs={6} md={5} lg={4} container wrap="nowrap" spacing={2}>
        <Grid item xs={12}>
          <FilterContainer>
            <RoleProtectedSelect
              fullWidth
              value={allocationValueBulk}
              onChange={onChangeBulkAllocationValue}
            >
              <AppSelect.Option value={0}>Choose Allocation</AppSelect.Option>
              <AppSelect.Option value={1}>Low</AppSelect.Option>
              <AppSelect.Option value={2}>Medium</AppSelect.Option>
              <AppSelect.Option value={3}>High</AppSelect.Option>
            </RoleProtectedSelect>
          </FilterContainer>
        </Grid>
        <Grid item container>
          <Grid item>
            <ProtectedButtonWithHint
              color="primary"
              type="submit"
              onClick={onBulkAllocationValue}
              style={{ height: '100%' }}
              fullWidth
              hintText={'Set Allocation Value'}
              disabled={allocationValueBulk === defaultSelectIndex}
            >
              Set Label
            </ProtectedButtonWithHint>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
