import { AppDropdown } from '@app/shared/components/AppDropdown/AppDropdown';
import * as React from 'react';
import {
  NormalizedFilterItem,
  TFilterAllocationLevel,
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { ProductGroupFilters } from '@app/shared/components/ProductGroupFilters';
import { AppAutocomplete } from '@app/shared/components/AppAutocomplete';
import Chip from '@material-ui/core/Chip';

export type MultiSelectFilterData = Record<number, NormalizedFilterItem[]>;

type LevelOption = TFilterAllocationLevel & { id: number };

export type MultiSelectData = {
  filters: TFilterItemResponse[];
  allocationLevels: LevelOption[];
};

interface Props {
  filters: TFilterItemResponse[];
  filtersValue?: TFilterItemResponse[];
  allocationLevels: TFilterAllocationLevels;
  allocationLevelsValue: TFilterAllocationLevels;
  onAllocationLevelChange?: (data: TFilterAllocationLevels) => void;
  onFiltersChange?: (data: MultiSelectData['filters']) => void;
  disabled?: boolean;
  disabledAllocation?: boolean;
}

export const GroupHierarchiesFilter = (props: Props) => {
  const {
    allocationLevels,
    onAllocationLevelChange,
    onFiltersChange,
    disabled,
    filters,
    allocationLevelsValue,
    filtersValue,
    disabledAllocation = false,
  } = props;

  const levelOptions = React.useMemo(() => {
    return allocationLevels.map((option) => ({
      ...option,
      id: option.key,
    }));
  }, [allocationLevels]);

  const allocationValue = React.useMemo(() => {
    return allocationLevelsValue.map((option) => ({
      ...option,
      id: option.key,
    }));
  }, [allocationLevelsValue]);

  const handleAllocationLevelChange = React.useCallback(
    (value: LevelOption[]) => {
      const levelsValue = value.map(({ id, ...rest }) => ({
        ...rest,
      })) as TFilterAllocationLevels;

      onAllocationLevelChange && onAllocationLevelChange(levelsValue);
    },
    [onAllocationLevelChange]
  );

  const handleFiltersChange = React.useCallback(
    (data: TFilterItemResponse[]) => {
      onFiltersChange && onFiltersChange(data);
    },
    [onFiltersChange]
  );

  return (
    <AppDropdown
      head={
        disabledAllocation ? (
          <pre>Product Group</pre>
        ) : (
          <pre>Product Group & Value</pre>
        )
      }
      disabled={disabled}
    >
      <ProductGroupFilters
        value={filtersValue}
        filters={filters}
        onChange={handleFiltersChange}
      />
      {!disabledAllocation && (
        <AppDropdown.Item title="Product group value">
          {levelOptions.length ? (
            <AppAutocomplete
              options={levelOptions}
              getOptionLabel={(o) => {
                return o.value || (o as any).name;
              }}
              value={allocationValue}
              defaultAllValue={levelOptions}
              getOptionSelected={(o) =>
                !!allocationValue.find((option) => option.id === o.id)
              }
              onChange={handleAllocationLevelChange}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => {
                  return (
                    <Chip
                      label={option.value || (option as any).name}
                      {...getTagProps({ index })}
                    />
                  );
                });
              }}
            />
          ) : (
            (null as any)
          )}
        </AppDropdown.Item>
      )}
    </AppDropdown>
  );
};
