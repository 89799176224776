import { all } from 'redux-saga/effects';
import { coSagaWatcher } from '../co/saga';
import { packagingGroupsWatcherSaga } from '../packaging-groups/saga/packagingGroupsWatcherSaga';
import { industriesSagaWatcher } from '../industries/saga';

export function* filtersModuleSagaWatcher() {
  yield all([
    coSagaWatcher(),
    packagingGroupsWatcherSaga(),
    industriesSagaWatcher(),
  ]);
}
