import * as yup from 'yup';

export function validate(
  data: any,
  schema: yup.Schema<any>
): Record<string, string> {
  try {
    schema.validateSync(data, { abortEarly: false });

    return {};
  } catch (e) {
    const errors: yup.ValidationError[] = e.inner;
    if (!errors) throw e;

    return errors.reduce((accum, item) => {
      const params: any = item.params;

      if (!params) return accum;

      accum[params.path] = item.errors[item.errors.length - 1];

      return accum;
    }, {} as Record<string, string>);
  }
}
