import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { GetExceptionsNegotiationDTO } from '@app/services/negotiation-room-service/dto/get-exceptions.items.dto';
import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { ResponseNegotitaionException } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-exception.response';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import {
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  setExceptionsNegotiationDataAction,
  setExceptionsNegotiationLoadingAction,
} from '../action';

export function* fetchExceptionsSaga() {
  try {
    yield put(setExceptionsNegotiationLoadingAction({ loading: true }));

    const [skip, limit] = [getCurrentSkip(), getCurrentPageLimit()];
    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);
    const selectedAllocationLevels: TFilterAllocationLevels = yield select(
      selectCoSelectedAllocationLevels
    );
    const filtersValue: TFilterItemResponse[] = yield select(
      selectCoSelectedFilters
    );
    const selectedProductGroups = filtersValue.filter((f) => f.level === 4);

    const dto: GetExceptionsNegotiationDTO = {
      skip,
      limit,
      orderColumn: 0,
      isAsc: true,
      coId: selectedCO.id,
      groupIds: selectedProductGroups.map((i) => i.id),
      valueAllocations: selectedAllocationLevels.map((i) => i.key),
    };
    const request: {
      data: ResponseNegotitaionException[];
      total: number;
    } = yield call(NegotiationRoomApi.getExceptions, dto);
    yield put(
      setExceptionsNegotiationDataAction({
        items: request.data,
        total: request.total,
      })
    );
  } catch (e) {
    console.log('Failed to get exceptions items', e.message);
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.title,
            options: {
              variant: 'error',
            },
            key: 'fetch quotes',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'fetch quotes',
        },
      })
    );
  } finally {
    yield put(setExceptionsNegotiationLoadingAction({ loading: false }));
  }
}
