import * as React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { PageLeaveDialog } from './components/PageLeaveDialog';

interface Props {
  when?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onSkip?: () => void;
  isQuotes?: boolean;
  disabledSave?: boolean;
}

export const PageLeavePrompt = (props: Props) => {
  const {
    when,
    onConfirm,
    onCancel,
    onSkip,
    isQuotes = false,
    disabledSave = false,
  } = props;

  return (
    <NavigationPrompt
      when={(prevLocation, nextLocation) => {
        return when
          ? prevLocation.pathname !== nextLocation?.pathname ||
              prevLocation.search !== nextLocation?.search
          : false;
      }}
    >
      {(navPromptParams) => {
        return (
          <PageLeaveDialog
            onCancel={onCancel}
            onSkip={onSkip}
            onConfirm={onConfirm}
            isQuotes={isQuotes}
            navPromptBag={navPromptParams}
            disabledSave={disabledSave}
          />
        );
      }}
    </NavigationPrompt>
  );
};
