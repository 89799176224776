import { BASE_MARGIN_MATRIX } from '@app/routes/urls';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';
import {
  fetchCoAction,
  fetchCoFiltersAction,
  selectCoData,
  selectCoFiltersId,
  selectSelectedCo,
  setSelectedCo,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import {
  fetchIndustiesAction,
  setIndustiesDataAction,
} from '@app/store/filters-module/industries';
import { AppState } from '@app/store/store';
import { LocationChangeAction } from 'connected-react-router';
import { call, put, select, take } from 'redux-saga/effects';
import { fetchBaseMarginAction } from '../actions';
import { fetchIndustriesIdsWorkerSaga } from './fetchIndustriesIdsWorkerSaga';

export function* baseMarginWorkerSaga(action: LocationChangeAction) {
  const { search, pathname } = action.payload.location;

  if (BASE_MARGIN_MATRIX.match(pathname, true).isMatched && search) {
    const coItems: TCommercialOrganization[] = yield select(selectCoData);
    const industriesFilters: Industrie[] = yield select(
      (state: AppState) => state.filters.industries.selectedIndustries
    );

    if (!coItems.length) {
      yield put(fetchCoAction({}));
      yield take([setSelectedCo.TYPE]);
    }

    if (!industriesFilters.length) {
      yield put(fetchIndustiesAction({}));
      yield take(setIndustiesDataAction.TYPE);
    }

    let selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
    const filtersCoId: string = yield select(selectCoFiltersId);
    if (selectedCo.id !== filtersCoId) {
      yield put(fetchCoFiltersAction({}));
      yield take(setSelectedCoFilters.TYPE);

      selectedCo = yield select(selectSelectedCo);

      yield call(
        fetchIndustriesIdsWorkerSaga,
        setSelectedCo({ co: selectedCo })
      );
    }

    const isShouldFetchFilterIds: boolean = yield select(
      (store: AppState) => !store.baseMarginReducer.filterIdsFetched
    );

    if (isShouldFetchFilterIds) {

      yield call(
        fetchIndustriesIdsWorkerSaga,
        setSelectedCo({ co: selectedCo })
      );

    }

    yield put(fetchBaseMarginAction({}));
  }
}
