import { BaseMarginDataTable } from '@app/shared/types/baseMargin';

export const sortGroup1 = (data: any[], order: 'asc' | 'desc'): any[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.firstGroupName.toLowerCase();
    const bField = b.firstGroupName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
export const sortGroup2 = (data: any[], order: 'asc' | 'desc'): any[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.secondGroupName.toLowerCase();
    const bField = b.secondGroupName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
export const sortGroup3 = (data: any[], order: 'asc' | 'desc'): any[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.thirdGroupName.toLowerCase();
    const bField = b.thirdGroupName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
export const sortGroup4 = (data: any[], order: 'asc' | 'desc'): any[][] => {
  const sorting = (a: any, b: any) => {
    const aField = a.fourthGroupName.toLowerCase();
    const bField = b.fourthGroupName.toLowerCase();
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};

export const sortingManager = (data: any[], order: 'asc' | 'desc'): any[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.productManagerName
      ? a.productManagerName.toLowerCase()
      : 'a';
    const bField = b.productManagerName
      ? b.productManagerName.toLowerCase()
      : 'a';
    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };

  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
export const sortProductValueAllocation = (
  data: BaseMarginDataTable[],
  order: any
): BaseMarginDataTable[] => {
  const sorting = (a: any, b: any) => {
    const aField = a.valueAllocation;
    const bField = b.valueAllocation;

    if (aField < bField) {
      return -1;
    }
    if (aField > bField) {
      return 1;
    }
    return 0;
  };
  return data.sort((a: any, b: any) => {
    return order === 'desc' ? -sorting(a, b) : sorting(a, b);
  });
};
