import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import {
  ProductGroupAllocations,
  ProductGroupAllocationsDTO,
  ProductGroupLabeling,
  ProductGroupLabelingDTO,
  UpdateAllocationLevelDTO,
  UpdateAllocationValueDTO,
  UpdateBulkAllocationLevelDTO,
  UpdateBulkAllocationValueDTO,
} from '@app/shared/types/productValue';

class ProductValueService extends AxiosRequestService {
  public getProductGroupAllocations = async (
    data: ProductGroupAllocationsDTO
  ) => {
    const result = await this.privateRequest.post<{
      data: ProductGroupAllocations[];
      total: number;
    }>('/Group/GetProductGroupAllocations', data);
    return result.data;
  };

  public updateAllocationsLevel = async (data: UpdateAllocationLevelDTO) => {
    await this.privateRequest.put('/Group/UpdateAllocationLevels', data);
  };
  public updateBulkAllocationLevel = async (
    data: UpdateBulkAllocationLevelDTO
  ) => {
    await this.privateRequest.put('/Group/BulkUpdateAllocationLevels', data);
  };

  public getProductGroupLabeling = async (data: ProductGroupLabelingDTO) => {
    const result = await this.privateRequest.post<{
      data: ProductGroupLabeling[];
      total: number;
    }>('/Group/GetProductGroupLabeling', data);
    return result.data;
  };
  public updateBulkAllocationValue = async (
    data: UpdateBulkAllocationValueDTO
  ) => {
    await this.privateRequest.put('/Group/BulkUpdateValueAllocations', data);
  };
  public updateAllocationValue = async (data: UpdateAllocationValueDTO) => {
    await this.privateRequest.put('/Group/UpdateValueAllocations', { ...data });
  };

  public importExcel = async (data: { file: FormData; isProduct: boolean }) => {
    const currentUri = data.isProduct
      ? '/DataParser/UploadProductValueSecondSection'
      : '/DataParser/UploadProductValueFirstSection';
    await this.privateRequest.post(currentUri, data.file);
  };
}

export default new ProductValueService();
