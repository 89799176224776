import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';
import { AppTable } from '@app/shared/components/AppTable';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { TableCellInput } from '@app/shared/components/TableCellInput/TableCellInput';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import {
  transformToQuotesDecimalString,
  transformToQuotesIntegerString,
} from '@app/shared/utils/transformUtils';
import {
  selectQuotesItemsLoadingState,
  selectUpdateDataQuotes,
  setUpdateDataQuotesAction,
} from '@app/store/negotiation-room/quotes';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

const { Row, Cell, HeadCell, Body, Head } = AppTable;

const quoteType: Record<number, string> = {
  1: 'Maximum sales price validity time (in days)',
  2: 'Maximum total quote value',
  3: 'Minimum total material margin',
};

const quoteCurrency: Record<number, string> = {
  0: 'none',
  1: 'SEK',
  2: 'NOK',
  3: 'DKK',
  4: 'EUR',
  5: 'GBP',
};

const parseData = (value: string, type: number, currency: number | string) => {
  if (type === 1 && value === '') return '';
  if (type === 1 && value !== '') return 'Days';
  return currency === 0 ? '' : quoteCurrency[currency as number];
};

interface Props {
  dataTable: TQuotesItem[];
}
export const QuotesTable: React.FC<Props> = ({ dataTable }) => {

  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;

  const dispatch = useDispatch();

  const loading = useSelector(selectQuotesItemsLoadingState);
  const updateData = useSelector(selectUpdateDataQuotes);

  const handleChange = React.useCallback(
    (e: any) => {
      const id = e.target.getAttribute('data-id') as string;
      const name = e.target.name;
      const value = e.target.value;
      const newData = { ...updateData };

      if (!updateData[id]) {
        const find = dataTable.find((f) => f.id === id);
        dispatch(
          setUpdateDataQuotesAction({
            updateData: {
              ...newData,
              [id]: {
                ...(find as TQuotesItem),
                [name]: value,
              },
            },
          })
        );
        return;
      }
      dispatch(
        setUpdateDataQuotesAction({
          updateData: {
            ...newData,
            [id]: {
              ...newData[id],
              [name]: value,
            },
          },
        })
      );
    },
    [updateData, dataTable, dispatch]
  );
  return (
    <AppTable
      isEmpty={false}
      data={dataTable}
      dataCount={7}
      loading={loading}
      hidePagination={true}
    >
      <Head>
        <Row isHead>
          <HeadCell colSpan={2}></HeadCell>
          <HeadCell colSpan={1} align="center">
            Sales Lead
          </HeadCell>
          <HeadCell colSpan={1} align="center">
            Managing Director
          </HeadCell>
        </Row>
        <Row>
          <HeadCell colSpan={1} align={'center'} preWrap>
            Quote threshold type
          </HeadCell>
          <HeadCell align="center">Description</HeadCell>
          <HeadCell align="center">Threshold value</HeadCell>
          <HeadCell align="center">Threshold value</HeadCell>
        </Row>
      </Head>
      <Body<TQuotesItem>
        noDataText={NO_FILTERS_DATA_TABLE_TEXT}
        contentColSpan={4}
      >
        {(items) =>
          items.map((i, index) => {
            return (
              <Row key={index}>
                <Cell align={'center'}>{quoteType[i.quoteType]}</Cell>
                <Cell align={'center'}>
                  <TableCellInput
                    value={i.description ? i.description : '—'}
                    type={'text'}
                    label={'Description'}
                    data-id={i.id}
                    name={'description'}
                    onChange={handleChange}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell align={'center'}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <TableCellInput
                        value={i.firstThreshold ? i.firstThreshold : ''}
                        type={'text'}
                        label={'First Threshold'}
                        data-id={i.id}
                        name={'firstThreshold'}
                        onChange={handleChange}
                        transformToViewValue={
                          i.quoteType !== 1
                            ? transformToQuotesDecimalString
                            : transformToQuotesIntegerString
                        }
                        roleProtected={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid xs={12} container item justify="flex-start">
                          {parseData(
                            i.firstThreshold,
                            i.quoteType,
                            i.currencyType
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Cell>
                <Cell align={'center'}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <TableCellInput
                        value={i.secondThreshold ? i.secondThreshold : ''}
                        type={'text'}
                        label={'Third Threshold'}
                        data-id={i.id}
                        name={'secondThreshold'}
                        onChange={handleChange}
                        transformToViewValue={
                          i.quoteType !== 1
                            ? transformToQuotesDecimalString
                            : transformToQuotesIntegerString
                        }
                        roleProtected={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid xs={12} container item justify="flex-start">
                          {parseData(
                            i.secondThreshold,
                            i.quoteType,
                            i.currencyType
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Cell>
              </Row>
            );
          })
        }
      </Body>
    </AppTable>
  );
};
