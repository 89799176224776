import { FilterContainer } from '@app/shared/components/FilterContainer';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { AppSelect } from '@app/shared/components/AppSelect';
import { useSelector } from 'react-redux';
import { selectVolumeOptionsItems } from '@app/store/volume-driver/volume-options';
import { ProtectedButtonWithHint } from '@app/shared/components/ProtectedButtonWithHint';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';

const useStyles = makeStyles(() => ({
  input: {
    height: '32px',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: '80px',
  },
  button: {
    lineHeight: '1.25',
    height: '52px',
  },
  filterContainer: {
    maxWidth: '320px',
  },
}));

interface Props {
  onSubmit?: (value: string) => void;
  disabled?: boolean;
  initialValue?: string;
}

export const VolumeSystemForm = React.memo((props: Props) => {
  const volumeOptions = useSelector(selectVolumeOptionsItems);

  const VolumeOptions = React.useMemo(() => {
    return volumeOptions.map((option) => (
      <AppSelect.Option key={option.id} value={option.id}>
        {option.name}
      </AppSelect.Option>
    ));
  }, [volumeOptions]);

  const { onSubmit, initialValue, disabled } = props;

  const styles = useStyles();

  const [inputValue, setInputValue] = React.useState<string>(
    initialValue ?? ''
  );

  const handleChange = React.useCallback((e: any) => {
    const { value } = e.target;

    setInputValue(value);
  }, []);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      onSubmit && onSubmit(inputValue);
      setInputValue('');
    },
    [inputValue, onSubmit]
  );

  const isButtonDisabled = () => {
    return disabled || !inputValue;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item classes={{ root: styles.filterContainer }}>
          <FilterContainer>
            <RoleProtectedSelect
              value={inputValue}
              onChange={handleChange}
              required
              classes={{ root: styles.input }}
            >
              {VolumeOptions}
            </RoleProtectedSelect>
          </FilterContainer>
        </Grid>
        <Grid item>
          <RoleProtectedButton
            classes={{ root: styles.button }}
            color="primary"
            type="submit"
            disabled= {isButtonDisabled()}
          >
            Set Volume
          </RoleProtectedButton>
        </Grid>
      </Grid>
    </form>
  );
});
