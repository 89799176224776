import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';

export interface CoState {
  items: TCommercialOrganization[];
  filters: TFilterItemResponse[];
  allocationLevels: TFilterAllocationLevels;
  selectedFilters: TFilterItemResponse[];
  selectedLevels: TFilterAllocationLevels;
  coFiltersId: string;
  coProductGroupFilters: TFilterItemResponse[];
  selectedItem: TCommercialOrganization | null;
  loading: boolean;
}

export const initialState: CoState = {
  items: [],
  loading: true,
  selectedItem: null,
  allocationLevels: [],
  filters: [],
  selectedFilters: [],
  selectedLevels: [],
  coFiltersId: '',
  coProductGroupFilters: [],
};
