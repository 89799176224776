import {
  TProductManagers,
  TProductRight,
} from '@app/services/rights-service/response-interfaces/get-rights.response';
import React from 'react';
import { AppTable } from '@app/shared/components/AppTable';
import { NO_TABLE_DATA_TEXT } from '@app/shared/texts/texts';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectProductRightsItems,
  selectProductRightsItemsUpdate,
  selectProductRightsLoadingState,
  selectTotalProductRightsQuantity,
} from '@app/store/settings/product-rights/selectors';
import Switch from '@material-ui/core/Switch';
import {
  deleteProductRightsAction,
  forceSaveGroupRightsAction,
} from '@app/store/settings/product-rights/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  sortGroup1,
  sortGroup2,
  sortGroup3,
  sortGroup4,
  sortUserName,
} from '../../utils/ProductValueHelperSort';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disableSwitch: {
      '&.Mui-checked.Mui-disabled': {
        color: theme.palette.primary.main,

        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  })
);

export const ProductGroupTable: React.FC = () => {
  //===== style =====
  const classes = useStyles();

  //=====  =====
  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;

  const dispatch = useDispatch();
  const data = useSelector(selectProductRightsItems);
  const updateData = useSelector(selectProductRightsItemsUpdate);

  const [currentManager, setCurrentManager] = React.useState<
    Record<string, TProductManagers>
  >();
  React.useEffect(() => {
    const currentManagers = data.map((i) => {
      let find = i.managers.find((f) => f.isPrimaryProductOwner === true);

      if (!find) {
        find = i.managers[0];
      }

      return {
        id: i.productGroupId,
        manager: find,
      };
    });
    const dataToManagers = currentManagers.reduce((accum, item) => {
      return {
        ...accum,
        [item.id]: item.manager as TProductManagers,
      };
    }, {} as Record<string, TProductManagers>);

    setCurrentManager(dataToManagers);
  }, [data]);
  const dataTable = React.useMemo(() => {
    return data.map((i) => {
      const name =
        currentManager && currentManager[i.productGroupId]
          ? currentManager[i.productGroupId].name
          : 'a';
      if (updateData[i.productGroupId]) {
        return {
          ...i,
          userName: name,
          managers: i.managers,
        };
      }
      return { ...i, userName: name };
    });
  }, [updateData, data, currentManager]);
  const total = useSelector(selectTotalProductRightsQuantity);
  const loading = useSelector(selectProductRightsLoadingState);

  const handleSwitcher = React.useCallback(
    (e: any, item: TProductRight) => {
      const value = e.target.checked;
      let data = { ...updateData };
      if (updateData[item.productGroupId]) {
        const managers = data[item.productGroupId].managers;
        managers.forEach((i) => {
          let manager = currentManager as Record<string, TProductManagers>;
          if (i.id === manager[item.productGroupId].id) {
            i.isPrimaryProductOwner = value;
            return;
          }
          i.isPrimaryProductOwner = false;
        });
        data = {
          ...data,
          [item.productGroupId]: {
            managers,
          },
        };
      }
      if (!updateData[item.productGroupId]) {
        const currentGroup = dataTable.find(
          (f) => f.productGroupId === item.productGroupId
        );
        let managers: TProductManagers[] = [];
        if (currentGroup) {
          managers = [...currentGroup.managers];
        }
        managers.forEach((i) => {
          let manager = currentManager as Record<string, TProductManagers>;
          if (i.id === manager[item.productGroupId].id) {
            i.isPrimaryProductOwner = value;
            return;
          }
          i.isPrimaryProductOwner = false;
        });
        data = {
          ...data,

          [item.productGroupId]: {
            managers,
          },
        };
      }
      dispatch(forceSaveGroupRightsAction({ items: data }));
    },
    [updateData, dispatch, currentManager, dataTable]
  );
  const handleDelete = React.useCallback(
    (items: TProductRight[]) => {
      const dto = items.map((i) => {
        const managers: Record<string, TProductManagers> = {
          ...currentManager,
        };
        return {
          userId: managers[i.productGroupId].id,
          productGroupId: i.productGroupId,
        };
      });
      dispatch(
        deleteProductRightsAction({
          items: dto as {
            userId: string;
            productGroupId: string;
          }[],
        })
      );
    },
    [dispatch, currentManager]
  );
  const handleAutoComplete = React.useCallback(
    (value: TProductManagers, item: TProductRight) => {
      setCurrentManager((state) => {
        return {
          ...state,
          [item.productGroupId]: value,
        };
      });
    },
    []
  );
  return (
    <AppTable
      data={dataTable}
      dataCount={total}
      onDelete={handleDelete}
      deletableItems={!dataTable.length ? false : true}
      loading={loading}
      isEmpty={!dataTable.length}
    >
      <AppTable.Head>
        <AppTable.Row isHead={true} disableDeletionBehavior={true}>
          <AppTable.HeadCell sortHandler={sortUserName}>
            User name
          </AppTable.HeadCell>
          <AppTable.HeadCell sortHandler={sortGroup1}>
            Product Group 1
          </AppTable.HeadCell>
          <AppTable.HeadCell sortHandler={sortGroup2}>
            Product Group 2
          </AppTable.HeadCell>
          <AppTable.HeadCell sortHandler={sortGroup3}>
            Product Group 3
          </AppTable.HeadCell>
          <AppTable.HeadCell sortHandler={sortGroup4}>
            Product Group 4
          </AppTable.HeadCell>
          <AppTable.HeadCell>Primary product owner</AppTable.HeadCell>
        </AppTable.Row>
      </AppTable.Head>
      <AppTable.Body<TProductRight>
        contentColSpan={6}
        noDataText={NO_TABLE_DATA_TEXT}
      >
        {(items) =>
          items.map((i) => {
            return (
              <AppTable.Row key={i.productGroupId} item={i}>
                <AppTable.Cell>
                  {currentManager &&
                  currentManager[i.productGroupId] &&
                  Object.keys(currentManager).length ? (
                    <Autocomplete
                      disableClearable={true}
                      options={i.managers}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(_e, value) => handleAutoComplete(value, i)}
                      value={currentManager[i.productGroupId]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="User Name"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  ) : null}
                </AppTable.Cell>
                <AppTable.Cell>{i.groupLevelOneName}</AppTable.Cell>
                <AppTable.Cell>{i.groupLevelTwoName}</AppTable.Cell>
                <AppTable.Cell>{i.groupLevelThreeName}</AppTable.Cell>
                <AppTable.Cell>{i.groupLevelFourName}</AppTable.Cell>
                <AppTable.Cell>
                  {currentManager &&
                  currentManager[i.productGroupId] &&
                  Object.keys(currentManager).length ? (
                    <Switch
                      onChange={(e: any) => handleSwitcher(e, i)}
                      checked={
                        currentManager[i.productGroupId].isPrimaryProductOwner
                      }
                      color="primary"
                      disabled={
                        isDisabled ||
                        currentManager[i.productGroupId].isPrimaryProductOwner
                      }
                      classes={{ disabled: classes.disableSwitch }}
                    />
                  ) : null}
                </AppTable.Cell>
              </AppTable.Row>
            );
          })
        }
      </AppTable.Body>
    </AppTable>
  );
};
