import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { ModalStatus } from '../../store';
import { WizardStep } from '@modules/Simulator/components/SimulatorWizard';

interface Props {
  status: ModalStatus;
  close: () => void;
  accept: (status: ModalStatus) => void;
  skip: (status: ModalStatus) => void;
  stepStatus: WizardStep;
  steps: WizardStep[];
}

const StepConfirmDialog = ({
  status,
  close,
  accept,
  skip,
  stepStatus,
  steps
}: Props) => {
  return (
    <Dialog open={status !== null} onClose={close}>
      <DialogTitle id="alert-dialog-title">Save changed data?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {stepStatus.id === steps[steps.length - 1].id
            ? 'You have unsaved data! Save data to finish configuration?'
            : 'You have unsaved data! Save data before navigate to other step?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Back</Button>
        {stepStatus.originValidate && (
          <Button onClick={() => skip(status)} autoFocus>
            Don`t save
          </Button>
        )}
        <Button color="primary" onClick={() => accept(status)} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StepConfirmDialog;
