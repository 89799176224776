export interface RootAppState {
  isLoaded: boolean;
  isLoading: boolean;
  exportLoading: boolean;
}

export const initialState: RootAppState = {
  isLoaded: false,
  isLoading: true,
  exportLoading: false,
};
