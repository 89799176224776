import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { VolumeSystemTable } from './components/VolumeSystemTable';
import { MarginSystemTable } from './components/MarginSystemTable';
import { SystemConfigurationDialog } from './components/SystemConfigurationDialog';
import { AppTableRef } from '@app/shared/components/AppTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchVolumeDriverConfigurationsDataAction,
  resetUpdatedVolumeConfigurationsAction,
  selectVolumeSystemConfigurationCreatingState,
  selectVolumeSystemUpdatedConfigurations,
} from '@app/store/volume-driver/volume-system-configurations';
import {
  fetchMarginSystemConfigurationsAction,
  selectMarginSystemConfiguration,
  selectMarginSystemConfigurationCreatingState,
} from '@app/store/volume-driver/margin-system-configurations';
import { saveConfigurationsAction } from '@app/store/volume-driver/volume-driver-configurations';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { getCurrentPageLimit, getCurrentSkip } from '@app/shared/components/AppTablePagination/paginationUtils';
import { useCallback } from 'react';
import { setBackdropLoadingAction } from '@app/store/app/actions';

interface Props { }

export const SystemConfigurations = (props: Props) => {
  const dispatch = useDispatch();

  const vsTableRef = React.useRef<AppTableRef>({} as AppTableRef);
  const msTableRef = React.useRef<AppTableRef>({} as AppTableRef);

  const isVolumeSaving = useSelector(
    selectVolumeSystemConfigurationCreatingState
  );
  const isMarginSaving = useSelector(
    selectMarginSystemConfigurationCreatingState
  );

  const volumeSystemChanges = useSelector(
    selectVolumeSystemUpdatedConfigurations
  );
  const marginSystemChanges = useSelector(selectMarginSystemConfiguration);

  const isSaving = React.useMemo(
    () => [isVolumeSaving, isMarginSaving].some((saving) => saving),
    [isVolumeSaving, isMarginSaving]
  );

  const [volumeSystemErrors, setVolumeSystemErrorsState] = React.useState(
    false
  );

  const handleVolumeSystemErrorsStateChange = React.useCallback(
    (hasErrors) => setVolumeSystemErrorsState(hasErrors),
    []
  );

  const selectedCo = useSelector(selectSelectedCo);

  const onFetchDataAfterImportFile = useCallback(() => {
    const skip = getCurrentSkip();
    const limit = getCurrentPageLimit();
    dispatch(fetchVolumeDriverConfigurationsDataAction({ skip, limit }));
    dispatch(fetchMarginSystemConfigurationsAction({ skip, limit }));
    dispatch(setBackdropLoadingAction({ payload: false }));
  }, [dispatch]);

  const [marginSystemErrorsState, setMarginSystemErrorsState] = React.useState(
    false
  );

  const handleMarginSystemErrorsStateChange = React.useCallback(
    (hasErrors) => setMarginSystemErrorsState(hasErrors),
    []
  );

  const handleSaveClick = React.useCallback(() => {
    dispatch(saveConfigurationsAction({}));
  }, [dispatch]);

  const disabledSave = React.useMemo(() => {
    if (isSaving) return true;

    if (
      !Object.keys(volumeSystemChanges).length &&
      !Object.keys(marginSystemChanges).length
    )
      return true;

    return volumeSystemErrors || marginSystemErrorsState;
  }, [
    isSaving,
    volumeSystemChanges,
    marginSystemChanges,
    volumeSystemErrors,
    marginSystemErrorsState,
  ]);

  const shouldRenderPrompt =
    Object.keys(marginSystemChanges).length ||
    Object.keys(volumeSystemChanges).length;

  const handlePromptConfirm = React.useCallback(() => {
    dispatch(saveConfigurationsAction({}));
  }, [dispatch]);
  const handleDontSave = React.useCallback(() => {
    dispatch(resetUpdatedVolumeConfigurationsAction({}));
  }, [dispatch]);

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item xs={12}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <SystemConfigurationDialog
                  msRef={msTableRef}
                  vsRef={vsTableRef}
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              color="primary"
              style={{ height: '100%' }}
              disabled={disabledSave}
              onClick={handleSaveClick}
            >
              Save
            </RoleProtectedButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid container item spacing={2}>
          <Grid item xs={6} container>
            <VolumeSystemTable
              refObj={vsTableRef}
              onErrorStateChange={handleVolumeSystemErrorsStateChange}
            />
          </Grid>
          <Grid item xs={6} container>
            <MarginSystemTable
              refObj={msTableRef}
              onErrorStateChange={handleMarginSystemErrorsStateChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadSystemConfiguration'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton
            url={`/CreateExcel/SystemConfiguration?coId=${selectedCo?.id}`}
          />
        </Grid>
      </Grid>
      <PageLeavePrompt
        when={!!shouldRenderPrompt}
        onConfirm={handlePromptConfirm}
        onSkip={handleDontSave}
      />
    </Grid>
  );
};
