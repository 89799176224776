import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { PackagingConfigurationsTable } from './PackagingConfigurationsTable';
import {
  selectDriverConfigurationsList,
  selectDriverConfigurationsLoadingState,
  selectDriverConfigurationsSavingState,
  selectDriverConfigurationsTotalItems,
  selectDriverConfigurationUpdateData,
} from '@app/store/driver/driver-configurations/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDriverConfigurationListAction,
  resetUpdateDataAction,
  saveDriverConfigurationsAction,
  setUpdateDataAction,
} from '@app/store/driver/driver-configurations/actions';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { selectSelectedCo } from '@app/store/filters-module/co/selectors';
import { getCurrentPageLimit, getCurrentSkip } from '@app/shared/components/AppTablePagination/paginationUtils';
import { setBackdropLoadingAction } from '@app/store/app/actions';
export const PackagingConfigurations = () => {
  const dispatch = useDispatch();
  const isListSaving = useSelector(selectDriverConfigurationsSavingState);
  const isListLoading = useSelector(selectDriverConfigurationsLoadingState);
  const configurationsList = useSelector(selectDriverConfigurationsList);
  const totalListItems = useSelector(selectDriverConfigurationsTotalItems);
  const updateDate = useSelector(selectDriverConfigurationUpdateData);

  const isLoading = React.useMemo(
    () => [isListLoading, isListSaving].some((isTruthy) => isTruthy),
    [isListLoading, isListSaving]
  );
  const tableItems = configurationsList;

  const isTableChanged = React.useMemo(() => !!Object.keys(updateDate).length, [
    updateDate,
  ]);

  const selectedCo = useSelector(selectSelectedCo);

  const handleTableChange = React.useCallback(
    (id: string, groupId: string) => {
      dispatch(setUpdateDataAction({ updateData: { [id]: groupId } }));
    },
    [dispatch]
  );
  const handleSaveClick = React.useCallback(() => {
    let items = Object.entries(updateDate).map((pair) => {
      const packageTypeId = pair[0];
      const packageGroupId = pair[1];

      return {
        packageTypeId,
        packageGroupId,
      };
    });
    dispatch(
      saveDriverConfigurationsAction({
        items: items,
      })
    );
    dispatch(resetUpdateDataAction({}));
  }, [updateDate, dispatch]);

  const handleConfirm = useCallback(() => {
    handleSaveClick();
    dispatch(resetUpdateDataAction({}));
  }, [handleSaveClick, dispatch]);
  const handleDontSave = useCallback(() => {
    dispatch(resetUpdateDataAction({}));
  }, [dispatch]);

  const onFetchDataAfterImportFile = useCallback(() => {
    const skip = getCurrentSkip();
    const limit = getCurrentPageLimit();
    dispatch(fetchDriverConfigurationListAction({ skip, limit }));
    dispatch(setBackdropLoadingAction({ payload: false }));
  }, [dispatch]);

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item>
          <RoleProtectedButton
            color="primary"
            variant="contained"
            disabled={!isTableChanged || isLoading}
            onClick={handleSaveClick}
          >
            Save
          </RoleProtectedButton>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <PackagingConfigurationsTable
          tableData={updateDate}
          onChange={handleTableChange}
          totalItems={totalListItems}
          loading={isLoading}
          items={tableItems}
        />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadPackagingConfiguration'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton
            url={`/CreateExcel/PackagingConfiguration?coId=${selectedCo?.id}`}
          />
        </Grid>
      </Grid>
      <PageLeavePrompt
        onSkip={handleDontSave}
        when={isTableChanged}
        onConfirm={handleConfirm}
      />
    </Grid>
  );
};
