import { Action } from 'redux';
import {
  fetchProductRightsAction,
  setProductRightsDataAction,
  setProductRightsLoadingAction,
  toggleModalAction,
  updateGroupRightsAction,
} from './actions';
import { initialState, ProductRightsState } from './store';

export function productRightsReducer(
  state = initialState,
  action: Action
): ProductRightsState {
  if (setProductRightsLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setProductRightsDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
      total: action.total,
    };
  }
  if (toggleModalAction.is(action)) {
    return {
      ...state,
      isOpenModal: action.isOpen,
    };
  }
  if (updateGroupRightsAction.is(action)) {
    return {
      ...state,
      updateItems: action.items,
    };
  }

  if (fetchProductRightsAction.is(action)) {
    return {
      ...state,
      loading: true,
      isLoaded: false,
    };
  }

  return state;
}
