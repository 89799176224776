import { call, put, select } from 'redux-saga/effects';
import {
  resetUpdateLevelAction,
  updateAllocationsLevelAction,
} from '../actions';
import ProductValueService from '@services/product-value-service/product-value-service';
import { enqueSnackAction } from '@app/store/snacks';
import { selectUpdateLevel } from '../selectors';
import { UpdateAllocationsLevel } from '@app/shared/types/productValue';

export function* updateAllocationLevelsSaga(
  action: ReturnType<typeof updateAllocationsLevelAction>
) {
  try {
    const updateData: Record<string, UpdateAllocationsLevel> = yield select(
      selectUpdateLevel
    );
    const data = Object.keys(updateData).map((i) => {
      return {
        productGroupId: i,
        allocationLevel: updateData[i].allocationLevel,
      };
    });
    yield call(ProductValueService.updateAllocationsLevel, { data });
    yield put(resetUpdateLevelAction({}));
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Allocations Level is saved',
          options: {
            variant: 'success',
          },
          key: 'save allocation level',
        },
      })
    );
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create product update level error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create product update level error',
        },
      })
    );
    console.log(`Failed to update allocation level: ${e.toString()}`);
  }
}
