import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { FiltersExceptions } from './components/FiltersExceptions';
import { ExceptionsTable } from './components/ExceptionsTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveExceptionNegotiationAction,
  selectIsOpenModal,
  selectNegotiationExceptionsUpdate,
  setIsOpenModalAction,
  setUpdateDataExceptionsNegotiationAction,
} from '@app/store/negotiation-room/exceptions';
import { AddExceptionModal } from './components/AddExceptionModal';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import {
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';

export const ExceptionsTab = () => {
  const dispatch = useDispatch();
  const modal = useSelector(selectIsOpenModal);
  const updateItems = useSelector(selectNegotiationExceptionsUpdate);
  const selectCO = useSelector(selectSelectedCo);
  const filtersValue = useSelector(selectCoSelectedFilters);
  const filterLevelsValue = useSelector(selectCoSelectedAllocationLevels);

  const shouldAskForConfirm = React.useMemo(() => {
    return !!Object.keys(updateItems).length;
  }, [updateItems]);
  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item container xs={10}>
            <Grid container spacing={2}>
              <FiltersExceptions />
              <Grid item xs={3}>
                <RoleProtectedButton
                  variant="contained"
                  onClick={() =>
                    dispatch(setIsOpenModalAction({ isOpen: true }))
                  }
                  color="primary"
                  style={{ height: '100%' }}
                >
                  Add Exception
                </RoleProtectedButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={2}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <RoleProtectedButton
                  color="primary"
                  variant="contained"
                  onClick={() => dispatch(saveExceptionNegotiationAction({}))}
                  style={{ height: '100%' }}
                  disabled={!shouldAskForConfirm}
                >
                  Save
                </RoleProtectedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PageLeavePrompt
          onSkip={() =>
            dispatch(
              setUpdateDataExceptionsNegotiationAction({ updateData: {} })
            )
          }
          onConfirm={() => dispatch(saveExceptionNegotiationAction({}))}
          when={shouldAskForConfirm}
        />
      </Grid>
      <Grid item xs={12} container>
        <ExceptionsTable />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppExportButton
            url={'/CreateExcel/NegotiationsExceptions'}
            coId={selectCO?.id}
            isPost={true}
            postData={{
              coId: selectCO?.id,
              orderColumn: 0,
              isAsc: true,
              groupIds: filtersValue.map((i) => i.id),
              valueAllocations: filterLevelsValue.map((i) => i.key),
            }}
          />
        </Grid>
      </Grid>
      {modal ? <AddExceptionModal /> : null}
    </Grid>
  );
};

ExceptionsTab.TAB_TITLE = 'Negotiation exceptions';
