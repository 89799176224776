import { Action } from 'redux';
import {
  setMarginListDataAction,
  setMarginListLoadingAction,
  setMarginSavingAction,
  setMarginListLoadedAction,
  fetchDriverMarginListAction,
  fetchDriverMarginListDoneAction,
  fetchCOWithCurrencyDoneAction,
} from './actions';
import { DriverMarginsState, initialState } from './state';

export function driverMarginsReducer(
  state = initialState,
  action: Action
): DriverMarginsState {
  if (setMarginSavingAction.is(action)) {
    return {
      ...state,
      isSaving: action.isSaving,
    };
  }

  if (setMarginListLoadedAction.is(action)) {
    return {
      ...state,
      isLoaded: action.loaded,
    };
  }

  if (fetchDriverMarginListDoneAction.is(action)) {
    return {
      ...state,
      isLoaded: true,
      items: action.items,
      totalItems: action.total,
      loading: false,
    };
  }

  if (fetchCOWithCurrencyDoneAction.is(action)) {
    return {
      ...state,
      coWithCurrency: action.coWithCurrency,
    };
  }

  if (setMarginListLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setMarginListDataAction.is(action)) {
    return {
      ...state,
      totalItems: action.total,
      items: action.items,
    };
  }

  if (fetchDriverMarginListAction.is(action)) {
    return {
      ...state,
      loading: true,
      isLoaded: false,
    };
  }

  return state;
}
