import { AppDropdown } from '@app/shared/components/AppDropdown/AppDropdown';
import { AppSelect } from '@app/shared/components/AppSelect';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import {
  selectCoData,
  selectSelectedCo,
  setSelectedCo,
} from '@app/store/filters-module/co';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useHistory } from 'react-router';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal/ConfirmationModal';

interface AllProps {
  shouldAskForConfirm: boolean;
  onSkip: () => void;
  onConfirm: () => void;
}

interface Props extends AllProps {
  handleChange: (e: any) => void;
}

const Filter = withConfirmation(['handleChange'])((props: Props) => {
  const coList = useSelector(selectCoData);

  const selectedCo = useSelector(selectSelectedCo);

  const Options = React.useMemo(() => {
    return coList.map((co) => {
      return (
        <AppSelect.Option value={co.id} key={co.id}>
          {co.name}
        </AppSelect.Option>
      );
    });
  }, [coList]);
  return (
    <AppDropdown head="CO">
      <AppDropdown.Item title="Select Commercial Organization">
        {selectedCo ? (
          <AppSelect value={selectedCo.id} onChange={props.handleChange}>
            {Options}
          </AppSelect>
        ) : (
          (null as any)
        )}
      </AppDropdown.Item>
    </AppDropdown>
  );
}, ConfirmationModal);

export const CoFilter = (props: AllProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const coList = useSelector(selectCoData);

  const handleFilters = React.useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);
  const handleChange = React.useCallback(
    (e: any) => {
      const co = coList.find((co) => co.id === e.target.value);
      if (!co) throw new Error('Commercial organization not found.'); // Should never occur

      dispatch(setSelectedCo({ co }));
      handleFilters();
    },
    [coList, dispatch, handleFilters]
  );

  return <Filter handleChange={handleChange} {...props} />;
};
