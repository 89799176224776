import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { TGetVolumeDriverAllocationResponse } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import {
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import { fetchDriverAllocationsDoneAction } from '../actions';

export function* fetchVolumeDrviverAllocationsSaga() {
  const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
  const coId = selectedCo ? selectedCo.id : '';

  const filtersValue: TFilterItemResponse[] = yield select(
    selectCoSelectedFilters
  );
  if (!filtersValue.length) {
    yield put(
      fetchDriverAllocationsDoneAction({
        items: [],
        totalItems: 0,
      })
    );
    return;
  }
  const selectedAllocationLevels: TFilterAllocationLevels = yield select(
    selectCoSelectedAllocationLevels
  );
  const selectedProductGroups = filtersValue.filter((f) => f.level === 4);

  const skip = getCurrentSkip();
  const limit = getCurrentPageLimit();

  try {
    const request: TGetVolumeDriverAllocationResponse = yield call(
      VolumeDriverApi.getVolumeDriverAllocation,
      {
        skip,
        limit,
        productGroupIds: selectedProductGroups.map((i) => i.id),
        valueAllocations: selectedAllocationLevels.map((l) => l.key),
        coId,
      }
    );
    yield put(
      fetchDriverAllocationsDoneAction({
        items: request.data,
        totalItems: request.total,
      })
    );
  } catch (e) {
    console.log('Failed to get Volume Driver allocations', e.message);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get Volume Driver Allocations',
          options: {
            variant: 'error',
          },
          key: 'volume-driver-allocations-fetch-error',
        },
      })
    );
    yield put(
      fetchDriverAllocationsDoneAction({
        items: [],
        totalItems: 0,
      })
    );
  }
}
