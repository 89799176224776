import React from 'react';
import { useSelector } from 'react-redux';
import { getWizardCurrentAllocationLevelSelector } from '@app/store/wizard';
import { ProductListTable } from './ProductListTable';

interface IProps {
  canEdit: boolean
}

export const ProductGroupInfo = ({canEdit}: IProps) => {
  const currentProduct = useSelector(getWizardCurrentAllocationLevelSelector);
  return currentProduct ? <ProductListTable canEdit={canEdit} /> : null;
};
