import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import { fetchQuotesSaga } from './fetchQuotesSaga';
import { quotesWorkerSaga } from './quotesWorkerSaga';
import {
  fetchCurrencyCOAction,
  fetchQuotesDataAction,
  saveSaveQuotesAction,
} from '../action';
import { fetchCurrencyCoSaga } from './fetchCurrencyCoSaga';
import { saveQuotesSaga } from './saveQuotesSaga';

export function* quotesWatcherSaga() {
  yield all([
    takeEvery(LOCATION_CHANGE, quotesWorkerSaga),
    takeEvery(fetchQuotesDataAction.TYPE, fetchQuotesSaga),
    takeEvery(fetchCurrencyCOAction.TYPE, fetchCurrencyCoSaga),
    takeEvery(saveSaveQuotesAction.TYPE, saveQuotesSaga),
  ]);
}
