import { enqueSnackAction } from '@app/store/snacks';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import FileDownload from 'js-file-download';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { withEditPermissionCheck } from '@app/shared/hocs/withEditPermissionCheck';

interface Props {
  url: string;
  isPost?: boolean;
  coId?: string;
  industryIds?: string[];
  disabled?: boolean;
  postData?: any;
}

export const ExportButton: React.FC<Props> = ({
  url,
  isPost,
  coId,
  industryIds,
  disabled,
  postData,
}) => {
  const dispatch = useDispatch();
  const handleExport = async () => {
    const request = new AxiosRequestService();

    const timezone = new Date().getTimezoneOffset();

    let result;
    try {
      dispatch(setBackdropLoadingAction({ payload: true }));
      if (!isPost) {
        if (coId) {
          result = await request.privateRequest.get(
            `${process.env.REACT_APP_API_URI}${url}?coid=${coId}`,
            {
              responseType: 'blob',
              headers: {
                'x-timezoneoffset': timezone
              }
            }
          );
        } else {
          result = await request.privateRequest.get(
            `${process.env.REACT_APP_API_URI}${url}`,
            {
              responseType: 'blob',
              headers: {
                'x-timezoneoffset': timezone
              }
            }
          );
        }
      } else {
        if (postData) {
          result = await request.privateRequest.post(
            `${process.env.REACT_APP_API_URI}${url}`,
            { ...postData },
            {
              responseType: 'blob',
              headers: {
                'x-timezoneoffset': timezone
              }
            }
          );
        } else {
          result = await request.privateRequest.post(
            `${process.env.REACT_APP_API_URI}${url}`,
            { coId, industryIds },
            {
              responseType: 'blob',
              headers: {
                'x-timezoneoffset': timezone
              }
            }
          );
        }
      }

      let filename = result.headers['content-disposition']
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .replaceAll('"', '')
        .trim();
      const blob = new Blob([result.data]);
      FileDownload(blob, filename);
      dispatch(
        enqueSnackAction({
          notification: {
            message: 'File has exported',
            options: {
              variant: 'success',
            },
            key: 'File has exported',
          },
        })
      );
    } catch (e) {
      if (e.response) {
        let message =
          e.response.status === 409 || e.response.status === 400
            ? e.response.data.message
            : 'Something wrong...';

        dispatch(
          enqueSnackAction({
            notification: {
              message,
              options: {
                variant: 'error',
              },
              key: 'create fetch base margin error',
            },
          })
        );
        return;
      }

      dispatch(
        enqueSnackAction({
          notification: {
            message: 'Something wrong...',
            options: {
              variant: 'error',
            },
            key: 'exported file',
          },
        })
      );
    } finally {
      dispatch(setBackdropLoadingAction({ payload: false }));
    }
  };
  return (
    <Button
      disabled={disabled}
      variant="contained"
      color="default"
      onClick={handleExport}
    >
      Export
    </Button>
  );
};

export const AppExportButton = withEditPermissionCheck(ExportButton);
