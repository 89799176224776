import * as React from 'react';
import { Grid } from '@material-ui/core';
import { ProductGroupSelect } from './ProductGroupSelect';
import { ProductGroupTable } from './ProductGroupTable';

export const ProductGroupContainer = () => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <ProductGroupSelect />
      </Grid>
      <Grid item>
        <ProductGroupTable />
      </Grid>
    </Grid>
  );
};
