import React from 'react';
import { Paper } from '@material-ui/core';
import ScrollbarWithMaxHeight from '@shared/components/ScrollbarWithMaxHeight/ScrollbarWithMaxHeight';

interface WizardTableContainerProps {
  loading?: boolean;
  pagination?: JSX.Element;
}

const WizardTableContainer: React.FC<WizardTableContainerProps> = ({
  loading = false,
  children,
  pagination,
}) => {
  return (
    <Paper style={{ margin: '0.5rem 0' }}>
      <ScrollbarWithMaxHeight maxHeight="50vh" loading={loading}>
        {children}
      </ScrollbarWithMaxHeight>
      {pagination}
    </Paper>
  );
};

export default WizardTableContainer;
