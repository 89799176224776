import { TMarginSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-options.response';

export interface MarginOptionsState {
  items: TMarginSystemOption[];
  isLoading: boolean;
}

export const initialState: MarginOptionsState = {
  items: [],
  isLoading: true,
};
