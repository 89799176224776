import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { CostFormItem } from '../../../store';
import { OptionItem } from '@modules/Simulator/components/TargetPriceResults/utils';
import { Grid } from '@material-ui/core';

interface CostFormSelectFieldProps extends CostFormItem {
  options: OptionItem[];
  current: OptionItem | null;
  handlerChange: (name: string, value: OptionItem | null) => void;
}

export const CostFormSelectField = ({
  name,
  label,
  placeholder,
  current,
  options,
  handlerChange,
}: CostFormSelectFieldProps) => {
  return (
    <Grid container item spacing={2}>
      <Grid item container justify="flex-end" alignItems="center" xs={6}>
        {label}:
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={current}
          options={options}
          getOptionSelected={(i, value) => i.key === value.key}
          getOptionLabel={(i) => i.name}
          onChange={(e, value) => handlerChange(name, value as OptionItem)}
          renderInput={(params) => (
            <TextField {...params} label={placeholder} variant="outlined" />
          )}
          fullWidth={true}
          size="small"
        />
      </Grid>
    </Grid>
  );
};
