import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';

// sort industry filters by parent id at each level except root level 1

export function sortIndustriesByParentId(
  industriesMap: Record<number, { colSpan: number; industry: Industrie }[]>
): Record<number, { colSpan: number; industry: Industrie }[]> {
  const map = { ...industriesMap };
  for (let i = 1; i < 5; i++) {
    if (i === 1) continue;
    const parentGroup = map[i - 1];
    const childrenGroup = map[i];

    childrenGroup.sort((a, b) => a?.industry?.name > b?.industry?.name
          ? 1
          : a?.industry?.name < b?.industry?.name
            ? -1
            : 0)

    const groupBulk: { colSpan: number; industry: Industrie }[] = [];

    for (let j = 0; j < parentGroup.length; j++) {
      const parent = parentGroup[j];
      childrenGroup.forEach((groupItem, index) => {
        if (groupItem.industry.parentId === parent.industry.id) {
          groupBulk.push(groupItem);
        }
      });

      map[i] = groupBulk;
    }
  }

  return map;
}
