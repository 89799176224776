import React from 'react';
import {
  PACKAGING_DRIVER_CONFIGURATION,
  PACKAGING_DRIVER_MARGIN,
} from '@app/routes/urls';
import { TabRouterElement } from '@app/shared/components/TabRouterLayout';
import { PackagingConfigurations } from './components/PackagingConfigurations';
import { PackagingMargins } from './components/PackagingMargins';
import { ProtectedComponent } from '@app/shared/components/ProtectedComponent';

export const packagingDriverTabs: TabRouterElement[] = [
  {
    title: 'Packaging Margins',
    link: PACKAGING_DRIVER_MARGIN.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={PackagingMargins}
        feature={'PackagingDriverMargins'}
      />
    ),
    feature: 'PackagingDriverMargins',
  },
  {
    title: 'Packaging Configurations',
    link: PACKAGING_DRIVER_CONFIGURATION.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={PackagingConfigurations}
        feature={'PackagingDriverConfiguration'}
      />
    ),
    feature: 'PackagingDriverConfiguration',
  },
];
