import { Action } from 'redux';
import { setLoggedInAction } from './actions';
import { AuthState, initialState } from './state';

export function authReducer(
  state: AuthState = initialState,
  action: Action
): AuthState {
  if (setLoggedInAction.is(action)) {
    return {
      ...state,
      loggedIn: action.loggedIn,
    };
  }

  return state;
}
