import { Action } from 'redux';
import {
  resetUpdateAllocationAction,
  resetUpdateLevelAction,
  setLoadingGroupsLabelingTableAction,
  setLoadingGroupsTableAction,
  setProductGroupAllocations,
  setProductGroupLabeling,
  setProductValueAllocationLevels,
  setSelectedProductValueAllocationLevels,
  setUpdateDataAllocation,
  setUpdateDataLevel,
} from './actions';
import { ProductValueState, initialState } from './state';

export const productValueReducer = (
  state: ProductValueState = initialState,
  action: Action
): ProductValueState => {
  if (setProductGroupAllocations.is(action)) {
    return {
      ...state,
      productGroupAllocations: { ...action.payload },
    };
  }
  if (setLoadingGroupsTableAction.is(action)) {
    return {
      ...state,
      loadingGroupsTable: action.payload,
    };
  }
  if (setProductGroupLabeling.is(action)) {
    return {
      ...state,
      productGroupLabeling: action.payload,
    };
  }
  if (setLoadingGroupsLabelingTableAction.is(action)) {
    return {
      ...state,
      loadingGroupsLabelingTable: action.payload,
    };
  }

  if (setProductValueAllocationLevels.is(action)) {
    return {
      ...state,
      allocationLevels: action.levels,
    };
  }

  if (setSelectedProductValueAllocationLevels.is(action)) {
    return {
      ...state,
      selectedAllocationLevels: action.levels,
    };
  }
  if (setUpdateDataLevel.is(action)) {
    return {
      ...state,
      updateDataLevel: {
        ...state.updateDataLevel,
        ...action.payload,
      },
    };
  }
  if (setUpdateDataAllocation.is(action)) {
    return {
      ...state,
      updateDataAllocation: {
        ...state.updateDataAllocation,
        ...action.payload,
      },
    };
  }
  if (resetUpdateLevelAction.is(action)) {
    return {
      ...state,
      updateDataLevel: {},
    };
  }
  if (resetUpdateAllocationAction.is(action)) {
    return {
      ...state,
      updateDataAllocation: {},
    };
  }
  return state;
};
