import { TMarginSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-options.response';
import { TVolumeDriverAllocation } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';
import { TVolumeSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-options.response';
import { AppSelect } from '@app/shared/components/AppSelect';
import { AppTable } from '@app/shared/components/AppTable';
import { useEmptyTableCheck } from '@app/shared/hooks/useEmptyTableCheck';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import { validate } from '@app/shared/utils/validate';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { selectAllocationLevels } from '@app/store/filters-module/filters-state';
import { selectMarginOptionsItems } from '@app/store/volume-driver/margin-options';
import {
  selectDriverAllocationLoadedState,
  selectDriverAllocationLoadingState,
  selectDriverAllocationTotalItems,
} from '@app/store/volume-driver/volume-driver-allocations/selectors';
import { selectVolumeOptionsItems } from '@app/store/volume-driver/volume-options';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  volumeAllocationSortByAllocationLevel,
  volumeAllocationSortByGroupLevel,
  volumeAllocationSortByManagerSortByManager,
  volumeAllocationSortByMarginSystem,
  volumeAllocationSortByVolumeSystem,
} from './sortHelpers';
import { volumeDriverTableSchema } from './volumeDriverTableSchema';
import Grid from '@material-ui/core/Grid';
import { ErrorTooltip } from '@app/shared/components/ErrorTooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';

const useStyles = makeStyles(() => ({
  select: {
    width: '120px',
  },
}));

const { Head, HeadCell, Row, Cell, Body } = AppTable;

interface Props {
  onChange: (allocation: TVolumeDriverAllocation) => void;
  items: TVolumeDriverAllocation[];
  onError: (hasError: boolean) => void;
}

export const VolumeDriverAllocationTable = (props: Props) => {
  const classes = useStyles();
  const { onChange, items, onError } = props;

  const co = useSelector(selectSelectedCo);

  const isLoading = useSelector(selectDriverAllocationLoadingState);

  const allocationLevels = useSelector(selectAllocationLevels);
  const totalItems = useSelector(selectDriverAllocationTotalItems);
  const isAllocationsLoaded = useSelector(selectDriverAllocationLoadedState);

  const isEmptyTable = useEmptyTableCheck(
    isAllocationsLoaded,
    isLoading,
    totalItems
  );

  const marginOptions = useSelector(selectMarginOptionsItems);
  const volumeOptions = useSelector(selectVolumeOptionsItems);

  const VolumeOptions = React.useMemo(() => {
    return volumeOptions.map((option) => (
      <AppSelect.Option key={option.id} value={option.id}>
        {option.name}
      </AppSelect.Option>
    ));
  }, [volumeOptions]);

  const MarginOptions = React.useMemo(
    () =>
      marginOptions.map((option) => (
        <AppSelect.Option key={option.id} value={option.id}>
          {option.name}
        </AppSelect.Option>
      )),
    [marginOptions]
  );

  const handleSelectChange = React.useCallback(
    (
      options: TMarginSystemOption[] | TVolumeSystemOption[],
      group: TVolumeDriverAllocation,
      e: any
    ) => {
      const { name, value: optionId } = e.target;
      const option = options.find((o) => o.id === optionId);

      onChange({
        ...group,
        [name]: option,
      });
    },
    [onChange]
  );

  const allocationLevelsKeyToNameMap = React.useMemo(
    () =>
      allocationLevels.reduce((accum, level) => {
        accum[level.key] = level.value;
        return accum;
      }, {} as Record<number, string>),
    [allocationLevels]
  );

  const errors = React.useMemo(() => {
    return validate(items, volumeDriverTableSchema);
  }, [items]);

  React.useEffect(() => {
    onError(!!Object.keys(errors).length);
  }, [errors, onError]);

  return (
    <AppTable
      data={items}
      dataCount={totalItems}
      loading={isLoading}
      isEmpty={isEmptyTable}
      tableLayout="auto"
    >
      <Head>
        <Row isHead>
          <HeadCell colSpan={6} />
          {co?.name && (
            <HeadCell
              colSpan={2}
              align="center"
              style={{ backgroundColor: '#27a242' }}
            >
              {co?.name}
            </HeadCell>
          )}
        </Row>
        <Row isHead>
          <HeadCell
            preWrap
            sortHandler={volumeAllocationSortByManagerSortByManager}
          >
            Product Manager
          </HeadCell>
          <HeadCell
            preWrap
            sortHandler={volumeAllocationSortByGroupLevel[1]}>
            Product Group 1
          </HeadCell>
          <HeadCell
            preWrap
            sortHandler={volumeAllocationSortByGroupLevel[2]}
          >
            Product Group 2
          </HeadCell>
          <HeadCell
            preWrap
            sortHandler={volumeAllocationSortByGroupLevel[3]}>
            Product Group 3
          </HeadCell>
          <HeadCell
            preWrap
            sortHandler={volumeAllocationSortByGroupLevel[4]}
          >
            Product Group 4
          </HeadCell>
          <HeadCell preWrap sortHandler={volumeAllocationSortByAllocationLevel}>
            Product Value
          </HeadCell>
          <HeadCell
            preWrap
            align="center"
            style={{ backgroundColor: '#32d556' }}
            sortHandler={volumeAllocationSortByVolumeSystem}
          >
            Volume System
          </HeadCell>
          <HeadCell
            preWrap
            align="center"
            style={{ backgroundColor: '#32d556' }}
            sortHandler={volumeAllocationSortByMarginSystem}
          >
            Margin System
          </HeadCell>
        </Row>
      </Head>
      <Body<TVolumeDriverAllocation>
        contentColSpan={8}
        noDataText={NO_FILTERS_DATA_TABLE_TEXT}
      >
        {(data) => {
          return data.map((item) => {
            const marginValue = item.marginSystem ? item.marginSystem.id : '';
            const volumeValue = item.volumeSystem ? item.volumeSystem.id : '';

            const marginErrorPath = `${item.id}.marginSystem`;
            const volumeErrorPath = `${item.id}.volumeSystem`;

            return (
              <Row key={item.id}>
                <Cell align="center">
                  {item.managerName ? item.managerName : '—'}
                </Cell>
                <React.Fragment>
                  {item.groupLevels.map((group) => (
                    <Cell key={group.id}>{group.name}</Cell>
                  ))}
                </React.Fragment>
                <Cell>
                  {allocationLevelsKeyToNameMap[item.valueAllocationLevel]}
                </Cell>
                <Cell>
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <RoleProtectedSelect
                        classes={{ root: classes.select }}
                        value={volumeValue || 0}
                        name="volumeSystem"
                        onChange={(e) =>
                          handleSelectChange(volumeOptions, item, e)
                        }
                      // disabled={!item.isEditable}
                      >
                        <AppSelect.Option disabled value={0}>
                          Choose System
                        </AppSelect.Option>
                        {VolumeOptions}
                      </RoleProtectedSelect>
                    </Grid>
                    <Grid>
                      <ErrorTooltip
                        opened={!!errors[volumeErrorPath]}
                        errorText={errors[volumeErrorPath]}
                      />
                    </Grid>
                  </Grid>
                </Cell>
                <Cell>
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <RoleProtectedSelect
                        classes={{ root: classes.select }}
                        value={marginValue || 0}
                        name="marginSystem"
                        onChange={(e) =>
                          handleSelectChange(marginOptions, item, e)
                        }
                      // disabled={!item.isEditable}
                      >
                        <AppSelect.Option disabled value={0}>
                          Choose System
                        </AppSelect.Option>
                        {MarginOptions}
                      </RoleProtectedSelect>
                    </Grid>
                    <Grid>
                      <ErrorTooltip
                        opened={!!errors[marginErrorPath]}
                        errorText={errors[marginErrorPath]}
                      />
                    </Grid>
                  </Grid>
                </Cell>
              </Row>
            );
          });
        }}
      </Body>
    </AppTable>
  );
};
