import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';

export const FEATURE_SETTINGS: TAccountRightsFeatureList = 'Settings';
export const FEATURE_SETTINGS_PRODUCT_RIGHTS: TAccountRightsFeatureList =
  'SettingsProductRights';
export const FEATURE_SETTINGS_TEAMS_RIGHTS: TAccountRightsFeatureList =
  'SettingsTeamsRights';
export const FEATURE_SETTINGS_USER_RIGHTS: TAccountRightsFeatureList =
  'SettingsUserRights';
export const FEATURE_SETTINGS_VALUE_PRODUCTS: TAccountRightsFeatureList =
  'SettingsValueRights';
