import { put, call, select } from 'redux-saga/effects';
import { fetchIndustries, setIndusties } from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { IndustriesData } from '@app/shared/types/baseMargin';
import { enqueSnackAction } from '@app/store/snacks';

export function* fetchIndustriesWorkerSaga(
  action: ReturnType<typeof fetchIndustries>
) {
  try {
    const coId: TCommercialOrganization = yield select(selectSelectedCo);
    const result: { data: IndustriesData[] } = yield call(
      BaseMarginService.fetchIndustries,
      {
        ...action.payload,
        coId: coId.id,
      }
    );
    yield put(setIndusties({ payload: result.data }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch industries error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch industries error',
        },
      })
    );
    console.log(`Failed to save base margin bulk: ${e.toString()}`);
  }
}
