import * as React from 'react';
import styles from './FilterContainer.module.scss';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const FilterContainer = (props: Props) => {
  const { children } = props;

  return (
    <Box paddingX={1} className={styles.filterContainer}>
      <Grid container wrap="nowrap">
        {React.Children.map(children, (child, index) => {
          return (
            <Grid
              item
              key={index}
              xs={12}
              alignItems="center"
              justify="center"
              container
            >
              {child}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
