import React, { useCallback, useReducer } from 'react';
import {
  WizardBaseMarginMatrixState,
  WizardBaseMarginEntryState,
} from '@shared/types/wizard';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import WizardTableContainer from '../../../WizardTableContainer/WizardTableContainer';
import { productAllocation } from '@modules/Simulator/data-definition';
import {
  baseMarginMatrixTableReducer,
  baseMarginMatrixTableInitState,
  openEditModalAction,
  closeEditModalAction,
  openDefaultModalAction,
  closeDefaultModalAction,
  setTableOrderAction,
  openDeleteModalAction,
  closeDeleteModalAction,
  resetDeleteMarginsAction,
  toggleDeleteMarginsByIdActions,
} from './store';
import { EditMarginModal } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/EditMarginModal/EditMarginModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBaseMarginMatrixItemsByIdsAction,
  editBaseMarginMatrixItemByIdAction,
  editDefaultBaseMarginMatrixAction,
  getWizardCurrencyByKey,
} from '@app/store/wizard';
import { EditDefaultMarginModal } from './EditMarginModal/EditDefaultMarginModal';
import { TableSortLabel } from '@material-ui/core';
import { sortByDirectionWithRange } from '@app/shared/utils/table-sort';
import { DeleteMarginModal } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/EditMarginModal/DeleteMarginModal';
import './BaseMarginMatrixTable.css';
import { validateMarginValue } from './util/validateMarginValue';

interface BaseMarginMatrixTableProps {
  data: WizardBaseMarginMatrixState;
  canEdit: boolean
}

export const BaseMarginMatrixTable = ({ data, canEdit }: BaseMarginMatrixTableProps) => {
  const dispatch = useDispatch();

  //===== CREATE STATE =====

  const [tableState, tableDispatch] = useReducer(
    baseMarginMatrixTableReducer,
    baseMarginMatrixTableInitState
  );

  //===== DATA FROM STORE =====

  const units = useSelector(getWizardCurrencyByKey);

  //===== EDIT MARGIN MODAL HANDLER =====

  const handlerOpenMarginModal = useCallback(
    (id: string, value: string): void => {
      tableDispatch(openEditModalAction(id, value));
    },
    [tableDispatch]
  );

  const handlerCloseMarginModal = useCallback((): void => {
    tableDispatch(closeEditModalAction());
  }, [tableDispatch]);

  const handlerAcceptMarginModal = (value: string): void => {
    dispatch(
      editBaseMarginMatrixItemByIdAction({
        payload: {
          valueAllocation: data.valueAllocation,
          id: tableState.editMarginModal.id,
          item: {
            ...data.industryMargins.find(
              (i) => i.id === tableState.editMarginModal.id
            ),
            marginValue: value,
          } as WizardBaseMarginEntryState,
        },
      })
    );
    tableDispatch(closeEditModalAction());
  };

  //===== DELETE MARGIN MODAL =====

  const handlerOpenDeleteModal = useCallback(() => {
    tableDispatch(openDeleteModalAction());
  }, [tableDispatch]);

  const handlerCloseDeleteModal = useCallback(() => {
    tableDispatch(closeDeleteModalAction());
  }, [tableDispatch]);

  const handlerAcceptDeleteModal = () => {
    dispatch(
      deleteBaseMarginMatrixItemsByIdsAction({
        payload: {
          valueAllocation: data.valueAllocation,
          ids: Object.keys(tableState.deleteMargins) as string[],
        },
      })
    );
    tableDispatch(resetDeleteMarginsAction());
    tableDispatch(closeDeleteModalAction());
  };

  const handlerToggleDeleteItem = useCallback(
    (id: string) => {
      tableDispatch(toggleDeleteMarginsByIdActions(id));
    },
    [tableDispatch]
  );

  //===== EDIT DEFAULT MARGIN MODAL =====

  const handlerOpenDefaultModal = useCallback(
    (value: string, unit: number): void => {
      tableDispatch(openDefaultModalAction(value, unit));
    },
    [tableDispatch]
  );

  const handlerCloseDefaultModal = useCallback((): void => {
    tableDispatch(closeDefaultModalAction());
  }, [tableDispatch]);

  const handlerAcceptDefaultModal = (value: string, unit: number): void => {
    dispatch(
      editDefaultBaseMarginMatrixAction({
        payload: {
          valueAllocation: data.valueAllocation,
          marginTypes: unit,
          marginValues: value,
        },
      })
    );
    tableDispatch(closeDefaultModalAction());
  };

  //===== SORT TABLE =====

  const handlerChangeOrder = useCallback(
    (name: string) => {
      const isAsc =
        tableState.order.column === name &&
        tableState.order.direction === 'asc';
      tableDispatch(setTableOrderAction(name, isAsc ? 'desc' : 'asc'));
    },
    [tableState, tableDispatch]
  );

  return (
    <>
      <WizardTableContainer>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={5}
                align="center"
                style={{ border: '2px solid var(--white)' }}
              >
                {productAllocation[data.valueAllocation]} value products
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: '2px solid var(--white)' }}>
                <IconButton
                  disabled={Object.keys(tableState.deleteMargins).length === 0}
                  size="small"
                  onClick={() => handlerOpenDeleteModal()}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell style={{ border: '2px solid var(--white)' }}>
                <TableSortLabel
                  active
                  direction={
                    tableState.order.column === 'name'
                      ? tableState.order.direction
                      : 'asc'
                  }
                  onClick={() => handlerChangeOrder('name')}
                >
                  Margin type
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '2px solid var(--white)' }}>
                <TableSortLabel
                  active
                  direction={
                    tableState.order.column === 'industryLevel'
                      ? tableState.order.direction
                      : 'asc'
                  }
                  onClick={() => handlerChangeOrder('industryLevel')}
                >
                  Industry level
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '2px solid var(--white)' }}>
                <TableSortLabel
                  active
                  direction={
                    tableState.order.column === 'industryName'
                      ? tableState.order.direction
                      : 'asc'
                  }
                  onClick={() => handlerChangeOrder('industryName')}
                >
                  Industry
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ border: '2px solid var(--white)' }}>
                <TableSortLabel
                  active
                  direction={
                    tableState.order.column === 'marginValue'
                      ? tableState.order.direction
                      : 'asc'
                  }
                  onClick={() => handlerChangeOrder('marginValue')}
                >
                  Margin
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>Default</TableCell>
              <TableCell>n\a</TableCell>
              <TableCell>n\a</TableCell>
              <TableCell>
                <Box
                  color={
                    isNaN(Number(data.marginValues))
                      ? 'error.main'
                      : 'text.primary'
                  }
                >
                  <Button
                    size="small"
                    style={{ textTransform: 'none' }}
                    color={'inherit'}
                    onClick={() =>
                      handlerOpenDefaultModal(
                        data.marginValues,
                        Number(data.marginTypes)
                      )
                    }
                    disabled={!canEdit}
                  >
                    {isNaN(Number(data.marginValues))
                      ? data.marginValues.replaceAll(
                          '$UNIT$',
                          data.marginTypes !== null
                            ? units[data.marginTypes]
                            : ''
                        )
                      : Number(data.marginValues).toFixed(2) +
                        (data.marginTypes !== null
                          ? ' ' + units[data.marginTypes]
                          : '')}
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
            {data.industryMargins
              .sort(
                sortByDirectionWithRange(
                  tableState.order.direction,
                  tableState.order.column,
                  tableState.order.column === 'marginValue'
                )
              )
              .map((i) => (
                <TableRow key={i.id}>
                  <TableCell>
                    <Checkbox
                      checked={Boolean(tableState.deleteMargins[i.id])}
                      onChange={() => handlerToggleDeleteItem(i.id)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      name="exceptionCheck"
                      color="primary"
                      disabled={!canEdit}
                    />
                  </TableCell>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{i.industryLevel}</TableCell>
                  <TableCell>{i.industryName}</TableCell>
                  <TableCell>
                    <Box
                      color={
                        validateMarginValue(
                          i.marginValue,
                          data.marginTypes === null ? 0 : data.marginTypes
                        )
                          ? 'text.primary'
                          : 'error.main'
                      }
                    >
                      <Button
                        size="small"
                        style={{ textTransform: 'none' }}
                        onClick={() =>
                          handlerOpenMarginModal(i.id, i.marginValue)
                        }
                        color={'inherit'}
                        disabled={!canEdit}
                      >
                        {isNaN(Number(i.marginValue))
                          ? i.marginValue.replaceAll(
                              '$UNIT$',
                              data.marginTypes !== null
                                ? units[data.marginTypes]
                                : ''
                            )
                          : Number(i.marginValue).toFixed(2) +
                            (data.marginTypes !== null
                              ? ' ' + units[data.marginTypes]
                              : '')}
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </WizardTableContainer>
      <EditMarginModal
        status={tableState.editMarginModal.status}
        value={tableState.editMarginModal.value}
        unitType={data.marginTypes}
        close={handlerCloseMarginModal}
        accept={handlerAcceptMarginModal}
      />
      <EditDefaultMarginModal
        status={tableState.editDefaultMarginModal.status}
        value={tableState.editDefaultMarginModal.value}
        unit={tableState.editDefaultMarginModal.unit}
        close={handlerCloseDefaultModal}
        accept={handlerAcceptDefaultModal}
      />
      <DeleteMarginModal
        status={tableState.deleteMarginModal.status}
        close={handlerCloseDeleteModal}
        accept={handlerAcceptDeleteModal}
      />
    </>
  );
};
