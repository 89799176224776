import { pickTableError } from '@app/modules/VolumeDriver/utils/tableUtils';
import { TVolumeSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';
import { AppTable, AppTableRef } from '@app/shared/components/AppTable';
import { useEmptyTableCheck } from '@app/shared/hooks/useEmptyTableCheck';
import { NO_TABLE_DATA_TEXT } from '@app/shared/texts/texts';
import { validate } from '@app/shared/utils/validate';
import {
  deleteVolumeSystemConfigurationsAction,
  fetchVolumeDriverConfigurationsDataAction,
  selectVolumeSystemConfigurationLoadedState,
  selectVolumeSystemConfigurationsItemsList,
  selectVolumeSystemConfigurationsLoadingState,
  selectVolumeSystemConfigurationsTotalItems,
  selectVolumeSystemConfigurationsValues,
  updateVolumeDriverConfigurationAction,
} from '@app/store/volume-driver/volume-system-configurations';
import { debounce } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableUnitSelect } from './components/TableUnitSelect';
import { volumeTableSchema } from './volumeTableSchema';
import {
  systemSort,
  descriptionSort,
  level1Sort,
  level2Sort,
  level3Sort,
  level4Sort,
  level5Sort,
  unitSort,
} from '../VolumeSystemTable/sortHelpers';
import { TableCellInput } from '@app/shared/components/TableCellInput';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';

const useStyles = makeStyles(() => ({
  cell: {
    height: '54px',
  },
}));

const { Head, HeadCell, Row, Body, Cell } = AppTable;

interface Props {
  refObj: React.MutableRefObject<AppTableRef>;
  onErrorStateChange: (hasErrors: boolean) => void;
}

export const VolumeSystemTable = (props: Props) => {
  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;
  const { onErrorStateChange } = props;

  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const styles = useStyles();
  const dispatch = useDispatch();

  const configurationsList = useSelector(
    selectVolumeSystemConfigurationsItemsList
  );

  const configurationsValues = useSelector(
    selectVolumeSystemConfigurationsValues
  );

  const totalItems = useSelector(selectVolumeSystemConfigurationsTotalItems);

  const isLoaded = useSelector(selectVolumeSystemConfigurationLoadedState);

  const isLoading = useSelector(selectVolumeSystemConfigurationsLoadingState);

  const isTableEmpty = useEmptyTableCheck(isLoaded, isLoading, totalItems);

  const fetchData = React.useCallback(
    (skip: number, limit: number) => {
      dispatch(fetchVolumeDriverConfigurationsDataAction({ skip, limit }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    fetchData(0, 25);
  }, [fetchData]);

  const handlePageChange = React.useCallback(
    (_: number, items: number, skip: number) => {
      fetchData(skip, items);
    },
    [fetchData]
  );

  const handleSelectChange = React.useCallback(
    (configurationId: string, value: number) => {
      dispatch(
        updateVolumeDriverConfigurationAction({
          id: configurationId,
          updatedPayload: {
            ...configurationsValues[configurationId],
            unit: value,
          },
        })
      );
    },
    [dispatch, configurationsValues]
  );

  const handleInputChange = React.useCallback(
    (e: any) => {
      const id = e.target.getAttribute('data-id');
      const name = e.target.name;
      const value = e.target.value;

      dispatch(
        updateVolumeDriverConfigurationAction({
          id: id,
          updatedPayload: {
            ...configurationsValues[id],
            [name]: value,
          },
        })
      );
    },
    [dispatch, configurationsValues]
  );

  const handleDelete = React.useCallback(
    (items: TVolumeSystemConfiguration[]) => {
      props.refObj.current.resetPagination();
      dispatch(
        deleteVolumeSystemConfigurationsAction({
          ids: items.map((item) => item.id),
        })
      );
    },
    [dispatch, props.refObj]
  );

  React.useEffect(() => {
    const errors = validate(configurationsList, volumeTableSchema);

    setErrors(errors);
  }, [configurationsList]);

  const liftErrorState = debounce(() => {
    const hasErrors = !!Object.keys(errors).length;
    onErrorStateChange(hasErrors);
  }, 50);

  React.useLayoutEffect(liftErrorState, [errors]);

  return (
    <AppTable
      data={configurationsList}
      dataCount={totalItems}
      deletableItems
      useInMemoryPagination
      loading={isLoading}
      onPageChange={handlePageChange}
      refObj={props.refObj}
      onDelete={handleDelete}
      tableLayout="auto"
      isEmpty={isTableEmpty}
    >
      <Head>
        <Row isHead>
          <HeadCell colSpan={4} classes={{ head: styles.cell }}></HeadCell>
          <HeadCell
            colSpan={5}
            align="center"
            style={{ backgroundColor: '#d1d1d1' }}
          >
            Volume thresholds
          </HeadCell>
        </Row>
        <Row isHead disableDeletionBehavior={true}>
          <HeadCell preWrap sortHandler={systemSort}>
            Volume System
          </HeadCell>
          <HeadCell sortHandler={unitSort}>Unit</HeadCell>
          <HeadCell sortHandler={descriptionSort}>Description</HeadCell>
          <HeadCell
            sortHandler={level1Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 1
          </HeadCell>
          <HeadCell
            sortHandler={level2Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 2
          </HeadCell>
          <HeadCell
            sortHandler={level3Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 3
          </HeadCell>
          <HeadCell
            sortHandler={level4Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 4
          </HeadCell>
          <HeadCell
            sortHandler={level5Sort}
            preWrap
            style={{ minWidth: '80px' }}
          >
            Level 5
          </HeadCell>
        </Row>
      </Head>
      <Body<TVolumeSystemConfiguration>
        contentColSpan={9}
        noDataText={NO_TABLE_DATA_TEXT}
      >
        {(items) => {
          return items.map((item, index) => {
            const unitValue = configurationsValues[item.id].unit;

            const nameError = pickTableError(index, 'name', errors);

            const firstLevelError = pickTableError(index, 'firstLevel', errors);
            const secondLevelError = pickTableError(
              index,
              'secondLevel',
              errors
            );
            const thirdLevelError = pickTableError(index, 'thirdLevel', errors);
            const fourthLevelError = pickTableError(
              index,
              'fourthLevel',
              errors
            );
            const fifthLevelError = pickTableError(index, 'fifthLevel', errors);
            return (
              <Row item={item} key={item.id}>
                <Cell>
                  <TableCellInput
                    label="Volume System Name"
                    value={item.name}
                    data-id={item.id}
                    data-index={index}
                    name="name"
                    error={nameError}
                    onChange={handleInputChange}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell>
                  <TableUnitSelect
                    onChange={handleSelectChange}
                    value={unitValue}
                    name={item.id}
                  />
                </Cell>
                <Cell style={{ maxWidth: '150px', minWidth: '150px' }}>
                  <TableCellInput
                    label="Volume System Description"
                    value={item.description || '—'}
                    name="description"
                    onChange={handleInputChange}
                    data-id={item.id}
                    type="description"
                    symbolsLimit={20}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell style={{ minWidth: '80px' }}>
                  <TableCellInput
                    label="Level 1"
                    value={item.firstLevel}
                    name="firstLevel"
                    error={firstLevelError}
                    onChange={handleInputChange}
                    data-id={item.id}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell style={{ minWidth: '80px' }}>
                  <TableCellInput
                    label="Level 2"
                    value={item.secondLevel}
                    name="secondLevel"
                    error={secondLevelError}
                    onChange={handleInputChange}
                    data-id={item.id}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell style={{ minWidth: '80px' }}>
                  <TableCellInput
                    label="Level 3"
                    value={item.thirdLevel}
                    name="thirdLevel"
                    error={thirdLevelError}
                    onChange={handleInputChange}
                    data-id={item.id}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell style={{ minWidth: '80px' }}>
                  <TableCellInput
                    label="Level 4"
                    value={item.fourthLevel}
                    name="fourthLevel"
                    error={fourthLevelError}
                    onChange={handleInputChange}
                    data-id={item.id}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell style={{ minWidth: '80px' }}>
                  <TableCellInput
                    label="Level 5"
                    value={item.fifthLevel}
                    name="fifthLevel"
                    error={fifthLevelError}
                    onChange={handleInputChange}
                    data-id={item.id}
                    type="number"
                    roleProtected={isDisabled}
                  />
                </Cell>
              </Row>
            );
          });
        }}
      </Body>
    </AppTable>
  );
};
