import { TPackagingDriverConfiguration } from '@app/services/packaging-driver-service/response-interfaces/get-configurations.response';

export interface DriverConfigurationsState {
  items: TPackagingDriverConfiguration[];
  totalItems: number;
  loading: boolean;
  isSaving: boolean;
  updateData: Record<string, string>;
}

export const initialState: DriverConfigurationsState = {
  items: [],
  totalItems: 0,
  loading: true,
  isSaving: false,
  updateData: {},
};
