import { call, put } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';
import {
  fetchTPRTableDataAction,
  setTPRBaseMarginAction,
  setTPRMarginVolumeDriverAction,
  setTPRPackagingDriverAction,
  setTPRVolumeDriverAction,
} from '@app/store/wizard';

export function* fetchTPRTableDataSaga(
  action: ReturnType<typeof fetchTPRTableDataAction>
) {
  try {
    const coId = action.payloads.coId;
    const productId = action.payloads.productId;
    const valuesAllocations = Number(action.payloads.valuesAllocations);

    const baseMargin = yield call(
      WizardServices.getBaseMarginMatrix,
      coId,
      [productId],
      [valuesAllocations]
    );
    yield put(setTPRBaseMarginAction({ payload: baseMargin.data }));

    const packagingDriver = yield call(
      WizardServices.getPackagingMargin,
      coId,
      [productId],
      [valuesAllocations]
    );
    yield put(setTPRPackagingDriverAction({ payload: packagingDriver.data }));

    const volumeDriver = yield call(
      WizardServices.getPGAVolumeDriver,
      coId,
      [productId],
      [valuesAllocations]
    );
    yield put(setTPRVolumeDriverAction({ payload: volumeDriver.data }));

    const marginOfValueDriver = yield call(WizardServices.getMarginSystem);
    yield put(
      setTPRMarginVolumeDriverAction({ payload: marginOfValueDriver.data })
    );
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
