import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

interface DateValidPickerPropsType {
  label: string;
  placeholder: string;
  value: string | null;
  onChange: (value: string) => void;
  minDate: string;
}

export const DateValidPicker = ({
  label,
  placeholder,
  value,
  onChange,
  minDate,
}: DateValidPickerPropsType) => {
  //===== CREATE LOCAL STATE =====

  const [selectedDate, setDate] = useState(moment(value || new Date()));

  //===== DATE WATCHERS =====

  useEffect(() => {
    if (value && value !== selectedDate.toISOString()) setDate(moment(value));
  }, [value, selectedDate]);

  //===== HANDLERS =====

  const handlerChange = (value: moment.Moment | null) => {
    if (value) {
      setDate(value);
      onChange(value.toISOString());
    }
  };

  return (
    <Grid container item spacing={2}>
      <Grid item container alignItems="center" xs={6}>
        {label}:
      </Grid>
      <Grid item container alignItems="center" xs={6}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <DatePicker
            minDate={minDate}
            disableToolbar
            label={placeholder}
            value={selectedDate}
            format={'YYYY-MM-DD'}
            onChange={handlerChange}
            TextFieldComponent={(props) => (
              <TextField {...props} variant={'outlined'} fullWidth />
            )}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};
