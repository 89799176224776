import { selectAllocationLevels } from '../filters-module/filters-state';
import { AppState } from '../store';

export const productValueAllocationlevels = [
  {
    key: 0,
    value: 'Unallocated',
  },
  ...selectAllocationLevels({} as any),
];

export const selectProductValueState = (store: AppState) => store.productValue;

export const selectProductGroupAllocation = (state: AppState) =>
  selectProductValueState(state).productGroupAllocations.data;
export const selectProductGroupAllocationTotal = (state: AppState) =>
  selectProductValueState(state).productGroupAllocations.total;
export const selectLoadingGroupsTable = (state: AppState) =>
  selectProductValueState(state).loadingGroupsTable;

export const selectProductGroupLabeling = (state: AppState) =>
  selectProductValueState(state).productGroupLabeling.data;
export const selectProductGroupLabelingTotal = (state: AppState) =>
  selectProductValueState(state).productGroupLabeling.total;
export const selectLoadingGroupsLabelingTable = (state: AppState) =>
  selectProductValueState(state).loadingGroupsLabelingTable;

export const selectProductValueAllocationLevels = (state: AppState) =>
  selectProductValueState(state).allocationLevels;

export const selectSelectedProductValueAllocationLevels = (state: AppState) =>
  selectProductValueState(state).selectedAllocationLevels;

export const selectUpdateLevel = (state: AppState) =>
  selectProductValueState(state).updateDataLevel;

export const selectUpdateAllocation = (state: AppState) =>
  selectProductValueState(state).updateDataAllocation;
