import { createPath } from 'rd-url-utils';

export const ROOT_URL = createPath('/');
export const LOGIN_URL = createPath('/login');
export const PRODUCT_VALUE_URL = createPath('/product-value');
export const PRODUCT_VALUE_SECTION_1 = PRODUCT_VALUE_URL.createChildPath(
  'section-1'
);
export const PRODUCT_VALUE_SECTION_2 = PRODUCT_VALUE_URL.createChildPath(
  'section-2'
);
export const BASE_MARGIN_MATRIX = createPath('/base-margin-matrix');
export const BASE_MARGIN_MATRIX_EXCEPTIONS = BASE_MARGIN_MATRIX.createChildPath(
  'exceptions'
);
export const VOLUME_DRIVER = createPath('/volume-driver');
export const VOLUME_DRIVER_CONFIGURATIONS = VOLUME_DRIVER.createChildPath(
  'configuration'
);
export const VOLUME_DRIVER_ALLOCATION = VOLUME_DRIVER.createChildPath(
  'allocation'
);
export const SIMULATOR_WIZARD_URL = createPath('/simulator-wizard');
export const RESET_PASSWORD_URL = createPath('/reset-password');
export const PACKAGING_DRIVER = createPath('/packaging-driver');
export const PACKAGING_DRIVER_CONFIGURATION = PACKAGING_DRIVER.createChildPath(
  'configuration'
);
export const PACKAGING_DRIVER_MARGIN = PACKAGING_DRIVER.createChildPath(
  'margin'
);

export const CONTACT_AND_SUPPORT = createPath('/contact-and-support');

export const SETTINGS_URL = createPath('/settings');
export const SETTINGS_GROUP_RIGHTS = SETTINGS_URL.createChildPath(
  `group-rights`
);
export const SETTINGS_VALUE_RIGHTS = SETTINGS_URL.createChildPath(
  `value-rights`
);
export const SETTINGS_TEAM_RIGHTS = SETTINGS_URL.createChildPath(`team-rights`);
export const SETTINGS_USER_RIGHTS = SETTINGS_URL.createChildPath(`user-rights`);
export const NEGOTIATIONS_URL = createPath('/negotiation-room');
export const NEGOTIATIONS_URL_PRODUCT_LINE = NEGOTIATIONS_URL.createChildPath(
  'product-line'
);
export const NEGOTIATIONS_QUOTES_URL = NEGOTIATIONS_URL.createChildPath(
  'quotes'
);
export const NEGOTIATIONS_EXCEPTIONS_URL = NEGOTIATIONS_URL.createChildPath(
  'exceptions'
);
