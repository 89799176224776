import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import {
  deleteProductRightsAction,
  fetchProductRightsAction,
  saveGroupRightsAction,
  createProductRightAction,
  forceSaveGroupRightsAction,
} from '../actions';
import { deleteProductRightsSaga } from './deleteProductRightsSaga';
import { fetchProductRightsSaga } from './fetchProductRightsSaga';
import { productRightsWorkerSaga } from './productRightsWorkerSaga';
import { createProductRightsSaga } from './createProductRightsSaga';
import { saveGroupRightsSaga } from './saveGroupRightsSaga';
import { forceSaveGroupRightsSaga } from './forceSaveGroupRightsSaga';

export function* productRightsWatcherSaga() {
  yield all([
    takeEvery(fetchProductRightsAction.TYPE, fetchProductRightsSaga),
    takeEvery(saveGroupRightsAction.TYPE, saveGroupRightsSaga),
    takeEvery(forceSaveGroupRightsAction.TYPE, forceSaveGroupRightsSaga),
    takeEvery(createProductRightAction.TYPE, createProductRightsSaga),
    takeEvery(deleteProductRightsAction.TYPE, deleteProductRightsSaga),
    takeEvery(LOCATION_CHANGE, productRightsWorkerSaga),
  ]);
}
