import React from 'react';
import { AppTable } from '@app/shared/components/AppTable';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import { headCells } from './headers';
import {
  deleteExceptionNegotiationAction,
  selectNegotiationExceptionsData,
  selectNegotiationExceptionsLoading,
  selectNegotiationExceptionsTotal,
  selectNegotiationExceptionsUpdate,
  setUpdateDataExceptionsNegotiationAction,
} from '@app/store/negotiation-room/exceptions';
import { useDispatch, useSelector } from 'react-redux';
import { ResponseNegotitaionException } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-exception.response';
import { TableCellInput } from '@app/shared/components/TableCellInput';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import { parseDate, parseDateAsLocal, parseTimeDate } from '@app/shared/utils/dateTimeUtil';

const { Row, Cell, HeadCell, Body, Head } = AppTable;

const map: Record<number, string> = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};
export interface ExceptionsNegotiationUpdate {
  name: string;
  description: string;
  id: string;
}
export const ExceptionsTable: React.FC = () => {

  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;

  const dispatch = useDispatch();
  const dataTable = useSelector(selectNegotiationExceptionsData);
  const updateItems = useSelector(selectNegotiationExceptionsUpdate);
  const total = useSelector(selectNegotiationExceptionsTotal);

  const data = React.useMemo(() => {
    return dataTable.map((i) => {
      return {
        ...i,
        ...updateItems[i.id],
      };
    });
  }, [dataTable, updateItems]);
  const loading = useSelector(selectNegotiationExceptionsLoading);

  const handleChange = React.useCallback(
    (e: any) => {
      const id = e.target.getAttribute('data-id') as string;
      const name = e.target.name;
      const value = e.target.value;
      let newData: Record<string, ExceptionsNegotiationUpdate> = {
        ...updateItems,
      };
      if (updateItems[id]) {
        newData = {
          ...newData,
          [id]: {
            ...newData[id],
            [name]: value,
          },
        };
        dispatch(
          setUpdateDataExceptionsNegotiationAction({ updateData: newData })
        );
        return;
      }
      const find = dataTable.find((f) => f.id === id);
      newData = {
        ...newData,
        [id]: {
          name: find?.name as string,
          description: find?.description as string,
          id: find?.id as string,
          [name]: value,
        },
      };
      dispatch(
        setUpdateDataExceptionsNegotiationAction({ updateData: newData })
      );
    },
    [updateItems, dispatch, dataTable]
  );
  return (
    <AppTable
      tableLayout="auto"
      isEmpty={!data.length}
      data={data}
      dataCount={total}
      loading={loading}
      deletableItems={!!data.length}
      onDelete={(data) =>
        dispatch(
          deleteExceptionNegotiationAction({ ids: data.map((i) => i.id) })
        )
      }
    >
      <Head>
        <Row isHead={true} disableDeletionBehavior={true}>
          {headCells.map((item) => (
            <HeadCell sortHandler={item.sort} key={item.id}>
              {item.label}
            </HeadCell>
          ))}
        </Row>
      </Head>
      <Body<ResponseNegotitaionException>
        noDataText={NO_FILTERS_DATA_TABLE_TEXT}
        contentColSpan={10}
      >
        {(items) =>
          items.map((i) => {
            return (
              <Row key={i.id} item={i}>
                <Cell align={'center'}>
                  <TableCellInput
                    value={i.name ? i.name : '—'}
                    type={'text'}
                    label={'Exception name'}
                    data-id={i.id}
                    name={'name'}
                    onChange={handleChange}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell align={'center'}>
                  <TableCellInput
                    value={i.description ? i.description : '—'}
                    type={'text'}
                    label={'Description'}
                    data-id={i.id}
                    name={'description'}
                    onChange={handleChange}
                    roleProtected={isDisabled}
                  />
                </Cell>
                <Cell align={'center'}>{i.groupLevelOneName}</Cell>
                <Cell align={'center'}>{i.groupLevelTwoName}</Cell>
                <Cell align={'center'}>{i.groupLevelThreeName}</Cell>
                <Cell align={'center'}>{i.groupLevelFourName}</Cell>
                <Cell align={'center'}>{i.mainGroupName}</Cell>
                <Cell align={'center'}>{map[i.valueAllocationLevel]}</Cell>
                <Cell align={'center'}>{i.productName}</Cell>
                <Cell align={'center'}>{i.productNumber}</Cell>
                <Cell align={'center'}>{parseDateAsLocal(i.validFrom)}</Cell>
                <Cell align={'center'}>{parseDateAsLocal(i.validTo)}</Cell>
                <Cell>{i.enteredByUser ?? '—'}</Cell>
                <Cell>{parseTimeDate(i.creationDate)}</Cell>
              </Row>
            );
          })
        }
      </Body>
    </AppTable>
  );
};
