import { TPackagingOption } from '@app/services/filter-service/response-interfaces/get-packaging-groups.response';
import { defineActionGroup } from 'rd-redux-utils';

const packagingOptionsActionGroup = defineActionGroup('PACKAGING GROUPS');

export const fetchPackagingGroupsAction = packagingOptionsActionGroup.defineAction(
  'FETCH DATA'
);

export const setPackagingGroupsLoadingAction = packagingOptionsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setPackagingGroupsIsFullListAction = packagingOptionsActionGroup.defineAction<{
  isFullList: boolean;
}>('SET IS FULL LIST');

export const setPackagingGroupsDataAction = packagingOptionsActionGroup.defineAction<{
  items: TPackagingOption[];
}>('SET DATA');

export const setSelectedPackagingGroupsAction = packagingOptionsActionGroup.defineAction<{
  items: TPackagingOption[];
}>('SET SELECTED');

export const resetSelectedPackagingGroupAction = packagingOptionsActionGroup.defineAction(
  'RESET SELECTED'
);
