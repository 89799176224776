import {
  fetchBaseMarginExceptionAction,
  setBaseMarginExceptionAction,
  setBaseMarginLoadingAction,
} from '../actions';
import { put, call, select, all, take } from 'redux-saga/effects';
import { BaseMarginExceptions } from '@shared/types/baseMargin';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { enqueSnackAction } from '@app/store/snacks';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  fetchCoAction,
  selectCoData,
  selectSelectedCo,
  setCoDataAction,
  setSelectedCo,
} from '@app/store/filters-module/co';

export function* fetchBaseMarginExceptionSaga(
  action: ReturnType<typeof fetchBaseMarginExceptionAction>
) {
  try {
    yield put(setBaseMarginLoadingAction({ payload: true }));
    const coItems: TCommercialOrganization[] = yield select(selectCoData);
    if (!coItems.length) {
      yield put(fetchCoAction({}));
      yield all([take([setCoDataAction.TYPE]), take([setSelectedCo.TYPE])]);
    }
    const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
    const data: BaseMarginExceptions = yield call(
      BaseMarginService.fetchBaseMarginExceptions,
      { ...action.payload, coId: selectedCo.id }
    );

    yield put(setBaseMarginExceptionAction({ payload: data }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch exception error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch exception error',
        },
      })
    );
    console.log(`Failed to fetch base margin exception: ${e.toString()}`);
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
  }
}
