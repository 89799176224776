import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useSelector } from 'react-redux';
import { getInvalidBaseMarginValueSelector } from '@app/store/wizard';
import { productAllocation } from '@modules/Simulator/data-definition';
import { getWizardBaseMarginMatrixSelector } from '@app/store/wizard';

interface ProductValueSelectProps {
  handlerChange: (value: number | null) => void;
  productValue: number | null;
}

export const ProductValueSelect = ({
  handlerChange,
  productValue,
}: ProductValueSelectProps) => {
  const invalidBaseMarginValue = useSelector(getInvalidBaseMarginValueSelector);
  const baseMarginMatrix = useSelector(getWizardBaseMarginMatrixSelector);

  return (
    <Autocomplete
      value={
        productValue === null
          ? null
          : {
              key: productValue,
              value: productAllocation[productValue],
            }
      }
      options={baseMarginMatrix.map((i) => ({
        key: i.valueAllocation,
        value: productAllocation[i.valueAllocation],
      }))}
      getOptionSelected={(i, value) => value.key === i.key}
      getOptionDisabled={(i) =>
        invalidBaseMarginValue.some((invalid) => invalid === i.key)
      }
      getOptionLabel={(i) => i.value}
      renderOption={(i) => {
        return (
          <>
            {i.value}
            {invalidBaseMarginValue.some((invalid) => invalid === i.key) && (
              <Tooltip title="Default margin don`t be empty">
                <WarningIcon
                  color="error"
                  style={{ marginLeft: 'auto', pointerEvents: 'all' }}
                />
              </Tooltip>
            )}
          </>
        );
      }}
      onChange={(e, value) => handlerChange(value ? value.key : null)}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
