import * as React from 'react';
import styles from './StepContentLayout.module.scss';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';

interface Props {
  children: JSX.Element;
  footer: JSX.Element;
  className?: string;
}

export const StepContentLayout = (props: Props) => {
  const { footer, children, className } = props;

  return (
    <Grid
      container
      direction="column"
      className={classnames(styles.stepContentLayout, className)}
      spacing={2}
    >
      <Grid item className={styles.stepContentLayout_content}>
        {children}
      </Grid>
      <Grid item className={styles.stepContentLayout_footer}>
        {footer}
      </Grid>
    </Grid>
  );
};
