import { TVolumeSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-options.response';
import { defineActionGroup } from 'rd-redux-utils';

const volumeOptionsActionGroup = defineActionGroup('VOLUME OPTIONS');

export const fetchVolumeOptionsAction = volumeOptionsActionGroup.defineAction(
  'FETCH DATA'
);

export const setVolumeOptionsLoadingAction = volumeOptionsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setVolumeOptionsDataAction = volumeOptionsActionGroup.defineAction<{
  items: TVolumeSystemOption[];
}>('SET DATA');
