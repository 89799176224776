import { call, put } from 'redux-saga/effects';
import { enqueSnackAction } from '@app/store/snacks';
import { getCurrentPageLimit, getCurrentSkip } from '@shared/components/AppTablePagination/paginationUtils';
import { throttleEffect } from '@shared/utils/sagaUtils/sagaUtils';
import { RightsApi } from '@services/rights-service/rights-service';
import { UserRightsTablePayloadType } from '@app/modules/Settings/components/UserRights/UserRightsTable';
import { setUserRightsDataAction } from '..';

export function* fetchUserRightsSaga() {
  try {
    const payload: {
        data: UserRightsTablePayloadType[];
        total: number;
      } = yield throttleEffect(
        call(RightsApi.getUserRights, getCurrentSkip(), getCurrentPageLimit())
      );

      yield put(
        setUserRightsDataAction({
          payload: { items: payload.data, total: payload.total },
        })
      );
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get User Rights.',
          options: {
            variant: 'error',
          },
          key: 'team-rights-fetch',
        },
      })
    );
  }
}
