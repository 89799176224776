import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { FiltersSettings } from '../FiltersSettings';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { ProductGroupTable } from './ProductGroupTable';
import {
  selectProductRightsItemsUpdate,
  selectProductRightsModalIsOpen,
} from '@app/store/settings/product-rights/selectors';
import {
  saveGroupRightsAction,
  setProductRightsDataAction,
  toggleModalAction,
  updateGroupRightsAction,
} from '@app/store/settings/product-rights/actions';
import { ConfigModal } from './ConfigModal';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import {
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';


export const ProductGroupRights: React.FC = () => {
  const dispatch = useDispatch();
  // const selectCO = useSelector(selectSelectedCo);
  // const filtersValue = useSelector(selectCoSelectedFilters);
  // const filterLevelsValue = useSelector(selectCoSelectedAllocationLevels);
  const updateData = useSelector(selectProductRightsItemsUpdate);
  const modal = useSelector(selectProductRightsModalIsOpen);

  const selectCO = useSelector(selectSelectedCo);
  const filtersValue = useSelector(selectCoSelectedFilters);
  const filterLevelsValue = useSelector(selectCoSelectedAllocationLevels);

  const when = React.useMemo(() => {
    return !!Object.keys(updateData).length;
  }, [updateData]);
  const validSave = React.useMemo(() => {
    let valid = true;
    if (Object.keys(updateData).length) {
      Object.values(updateData).forEach((i) => {
        const validManager = i.managers.some(
          (s) => s.isPrimaryProductOwner === true
        );
        if (!validManager) {
          valid = false;
        }
      });
      return !valid;
    }
    return true;
  }, [updateData]);
  const onSkip = React.useCallback(() => {
    dispatch(updateGroupRightsAction({ items: {} }));
  }, [dispatch]);
  const onSave = React.useCallback(() => {
    dispatch(saveGroupRightsAction({}));
  }, [dispatch]);
  React.useEffect(() => {
    return () => {
      dispatch(setProductRightsDataAction({ items: [], total: 0 }));
      dispatch(updateGroupRightsAction({ items: {} }));
    };
  }, [dispatch]);
  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item container xs={10}>
            <Grid container spacing={2}>
              <FiltersSettings when={when} onSkip={onSkip} onSave={onSave} />
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
          <Grid item container xs={2}>
            <Grid container wrap="nowrap" justify="flex-end" spacing={2}>
              <Grid item>
                <RoleProtectedButton
                  variant="contained"
                  onClick={() => dispatch(toggleModalAction({ isOpen: true }))}
                  color="primary"
                  style={{ height: '100%' }}
                >
                  Configure Rights
                </RoleProtectedButton>
              </Grid>
              {/*<Grid item>*/}
              {/*<RoleProtectedButton*/}
              {/*  color="primary"*/}
              {/*  variant="contained"*/}
              {/*  onClick={() => dispatch(saveGroupRightsAction({}))}*/}
              {/*  style={{ height: '100%' }}*/}
              {/*  disabled={!Object.keys(updateData).length || validSave}*/}
              {/*>*/}
              {/*  Save*/}
              {/*</RoleProtectedButton>*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
        </Grid>
        <PageLeavePrompt
          disabledSave={validSave}
          onSkip={onSkip}
          onConfirm={onSave}
          when={when}
        />
      </Grid>
      <Grid item xs={12} container>
        <ProductGroupTable />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        {/* <Grid item>
          <AppImportButton url={''} onFetchDataAfterImportFile={() => {}} />
        </Grid> */}
        <Grid item>
          <AppExportButton
            url={'/CreateExcel/ProductGroupsRights'}
            isPost={true}
            postData={{
              coId: selectCO?.id,
              orderColumn: 0,
              isAsc: true,
              groupIds: filtersValue.map((i) => i.id),
              valueAllocations: filterLevelsValue.map((i) => i.key),
            }}
          />
        </Grid>
      </Grid>
      {modal ? <ConfigModal /> : null}
    </Grid>
  );
};
