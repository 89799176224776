import React from 'react';
import {
  BASE_MARGIN_MATRIX,
  BASE_MARGIN_MATRIX_EXCEPTIONS,
} from '@app/routes/urls';
import { ProtectedComponent } from '@app/shared/components/ProtectedComponent';
import { TabRouterElement } from '@app/shared/components/TabRouterLayout';
import { BaseMatrix } from './components/BaseMatrix';
import { ExeptionsConfigurations } from './components/ExceptionsConfigurations';

export const baseMatrixTabs: TabRouterElement[] = [
  {
    title: 'BASE MARGIN MATRIX',
    link: BASE_MARGIN_MATRIX.urlTemplate,
    component: () => (
      <ProtectedComponent Component={BaseMatrix} feature={'BaseMarginMatrix'} />
    ),
    feature: 'BaseMarginMatrix',
  },
  {
    title: 'EXCEPTION CONFIGURATIONS',
    link: BASE_MARGIN_MATRIX_EXCEPTIONS.urlTemplate,
    component: () => (
      <ProtectedComponent
        Component={ExeptionsConfigurations}
        feature={'Exceptions'}
      />
    ),
    feature: 'Exceptions',
  },
];
