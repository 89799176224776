import { AppState } from '@app/store';

export const selectProductLineState = (store: AppState) =>
  store.negotiationRoom.productLine;

export const selectProductLineItems = (store: AppState) =>
  selectProductLineState(store).items;

export const selectProductTotalItems = (store: AppState) =>
  selectProductLineState(store).totalItems;

export const selectProductLineItemsLoadingState = (store: AppState) =>
  selectProductLineState(store).isLoading;

export const selectProductLineUpdateItems = (store: AppState) =>
  selectProductLineState(store).updateData;
