import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';
import { AppSelect } from '@app/shared/components/AppSelect';
import { FilterContainer } from '@app/shared/components/FilterContainer';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import {
  selectCurrentCurrency,
  selectQuotesItems,
  selectUpdateDataQuotes,
  setUpdateDataQuotesAction,
} from '@app/store/negotiation-room/quotes';
import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const QuotesForm: React.FC = () => {
  const dispatch = useDispatch();
  const dataTable = useSelector(selectQuotesItems);
  const updateData = useSelector(selectUpdateDataQuotes);
  const currentCurrency = useSelector(selectCurrentCurrency);
  const [select, setSelect] = React.useState('0');
  const handleChangeSelect = useCallback(
    (event: any) => {
      setSelect(event.target.value as string);

      let newData: Record<string, TQuotesItem> = {};
      dataTable.forEach((i) => {
        if (i.quoteType === 2 || i.quoteType === 3) {
          if (updateData[i.id]) {
            newData[i.id] = {
              ...updateData[i.id],
              currencyType: +event.target.value,
            };
            return;
          }
          newData[i.id] = { ...i, currencyType: +event.target.value };
          return;
        }
        if (updateData[i.id]) {
          newData[i.id] = {
            ...updateData[i.id],
            currencyType: 0,
          };
          return;
        }
        newData[i.id] = { ...i, currencyType: 0 };
      });
      const data = {
        ...newData,
      };
      dispatch(
        setUpdateDataQuotesAction({
          updateData: data,
        })
      );
    },
    [dataTable, dispatch, updateData]
  );
  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <FilterContainer>
          <RoleProtectedSelect
            onChange={handleChangeSelect}
            value={select}
            fullWidth
            style={{ minWidth: '140px' }}
          >
            <AppSelect.Option disabled value={'0'}>
              Choose Currency
            </AppSelect.Option>
            {currentCurrency?.currency.map((i) => (
              <AppSelect.Option key={i.key} value={i.key}>
                {i.value}
              </AppSelect.Option>
            ))}
          </RoleProtectedSelect>
        </FilterContainer>
      </Grid>
    </Grid>
  );
};
