import { UpdateValueRightsItem } from '@app/services/rights-service/dto/update-product-group-rights.dto';
import { RightsApi } from '@app/services/rights-service/rights-service';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select, take } from 'redux-saga/effects';
import {
  fetchValueRightsAction,
  setValueRightsDataAction,
  setValueRightsLoadingAction,
  updateValueRightsAction,
} from '../actions';
import { selectValueUpdateData } from '../selectors';

export function* saveValueRightsSaga() {
  try {
    yield put(setValueRightsLoadingAction({ loading: true }));
    const updateItems: Record<string, UpdateValueRightsItem> = yield select(
      selectValueUpdateData
    );

    const dto = Object.keys(updateItems).map((i) => {
      const currentManager = updateItems[i].managers.find(
          (f) => f.isPrimaryProductOwner === true
      );
      return {
        productGroupId: i,
        userId: currentManager?.id as string,
          isPrimaryProductOwner: currentManager?.isPrimaryProductOwner,
      };
    });
    yield call(RightsApi.updateValueRights, {
      data: dto,
    });
    yield put(fetchValueRightsAction({}));
    yield take(setValueRightsDataAction.TYPE);
    yield put(updateValueRightsAction({ items: {} }));

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Rights sucessfully updated for selected group.',
          options: {
            variant: 'success',
          },
          key: 'value-rights-save',
        },
      })
    );
  } catch (e) {
    console.log('Failed to set rights', e.message);
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'value-rights-save',
          },
        })
      );
    }

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'value-rights-save',
        },
      })
    );
  } finally {
    yield put(setValueRightsLoadingAction({ loading: false }));
  }
}
