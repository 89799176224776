import { AppTable } from '@app/shared/components/AppTable';
import React from 'react';
import { headCells } from './headerData';
import { Industrie } from '@app/services/filter-service/response-interfaces/get-industries';
import { getHeaderColor } from '@modules/BaseMarginMatrix/components/BaseMatrix/BaseMatrixTable/BaseMatrixHeaderTable/utils/colorsHeader';

interface Props {
  headersData: Record<number, { colSpan: number; industry: Industrie }[]>;
}

export const BaseMatrixHeaderTable: React.FC<Props> = ({ headersData }) => {
  const renderedCells = React.useMemo(() => {
    let groupHeadIdCollection: Array<{ id: string; index: number }> = [];
    const defaultHeader = headCells.map((headCell) => (
      <AppTable.HeadCell
        key={headCell.id}
        align={'center'}
        sortHandler={headCell.sort}
        style={headCell.color ? { background: headCell.color } : {}}
      >
        {headCell.label}
      </AppTable.HeadCell>
    ));
    if (!Object.keys(headersData).length) {
      return <AppTable.Row>{defaultHeader}</AppTable.Row>;
    }
    return Object.entries(headersData).map((industry, index) => {
      const level = +industry[0];
      let industries = industry[1];

      return (
        <AppTable.Row key={level} isHead={index === 3}>
          <>
            {level === 4 ? (
              defaultHeader
            ) : (
              <AppTable.HeadCell colSpan={7} sticky={false}></AppTable.HeadCell>
            )}
            {industries.map((item) => {
              const { industry, colSpan } = item;

              let color = '';
              if (industry.level > 1) {
                if (industry.level === 2)
                  groupHeadIdCollection.push({
                    id: industry.id,
                    index: groupHeadIdCollection.length,
                  });
                else
                  groupHeadIdCollection.push({
                    id: industry.id,
                    index: groupHeadIdCollection.filter(
                      (i) => i.id === industry.parentId
                    )[0].index,
                  });
                color =
                  industry.level > 1
                    ? getHeaderColor(
                        groupHeadIdCollection.filter(
                          (i) => i.id === industry.id
                        )[0].index,
                        industry.level
                      )
                    : '';
              }
              return (
                <AppTable.HeadCell
                  colSpan={colSpan}
                  key={industry.id}
                  style={{
                    border: '2px solid #fff',
                    backgroundColor: color,
                    minWidth: '150px',
                  }}
                  align="center"
                  sticky={index === 3 ? true : false}
                >
                  {industry.name}
                </AppTable.HeadCell>
              );
            })}
          </>
        </AppTable.Row>
      );
    });
  }, [headersData]);

  return <AppTable.Head>{renderedCells}</AppTable.Head>;
};
