import { TGetMarginSystemOptionsResponse } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-options.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { call, put } from 'redux-saga/effects';
import {
  fetchMarginOptionsAction,
  setMarginOptionsDataAction,
  setMarginOptionsLoadingAction,
} from '../actions';

export function* fetchMarginOptionsSaga(
  action: ReturnType<typeof fetchMarginOptionsAction>
) {
  try {
    yield put(setMarginOptionsLoadingAction({ loading: true }));

    const request: TGetMarginSystemOptionsResponse = yield call(
      VolumeDriverApi.getMarginSystemOptions
    );

    yield put(setMarginOptionsDataAction({ items: request.data }));
  } catch (e) {
    console.log('Failed to get margin options', e.message);
  } finally {
    yield put(setMarginOptionsLoadingAction({ loading: false }));
  }
}
