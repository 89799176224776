import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import {
  BaseMatrix,
  CommercialOrganizationsWithCurrency,
  Exception,
  ExceptionHighlight,
  IndustriesData,
  ProductGroups,
  ProductsName,
  UpdateData,
} from '../../shared/types/baseMargin';

export interface BaseMarginState extends BaseMatrix {
  loading: boolean;
  productGroups: ProductGroups;
  productsName: ProductsName;
  industries: IndustriesData[];
  exceptionName: string;
  updateExceptionData: Record<string, UpdateData>;
  currentExceptionIds: Exception[];
  currencyCO: CommercialOrganizationsWithCurrency[];
  openModal: boolean;
  showException: boolean;
  groupFilter: {
    total: number;
    data: TFilterItemResponse[];
  };
  filterIdsFetched: boolean;
  industriesFilterIds: string[];
  currentSelectedFilters: TFilterItemResponse[];
  exceptionHighlights: ExceptionHighlight[];
}

export const initialState: BaseMarginState = {
  baseMargin: {
    data: [],
    total: 0,
  },
  exeptions: {
    data: [],
    total: 0,
  },
  loading: false,
  productGroups: { data: [], total: 0 },
  productsName: { data: [], total: 0 },
  industries: [],
  exceptionName: '',
  updateExceptionData: {},
  currentExceptionIds: [],
  currencyCO: [],
  openModal: false,
  showException: false,
  groupFilter: {
    total: 0,
    data: [],
  },
  filterIdsFetched: false,
  industriesFilterIds: [],
  currentSelectedFilters: [],
  exceptionHighlights: []
};
