import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { rightsValues } from '../';
import { RightsValuesType } from '@app/store/settings/user-rights';

interface RightsSelectPropsType {
  label: string;
  placeholder: string;
  value: RightsValuesType | null;
  onChange: (value: RightsValuesType | null) => void;
  disabled: boolean;
}

export const RightsSelect = ({
  label,
  placeholder,
  value,
  onChange,
  disabled,
}: RightsSelectPropsType) => {
  return (
    <Grid container item spacing={2}>
      <Grid item container alignItems="center" xs={6}>
        {label}:
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={value}
          options={rightsValues}
          getOptionSelected={(i, value) => i.key === value.key}
          getOptionLabel={(i) => i.name}
          loading={false}
          onChange={(e, value) => onChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              required
              variant="outlined"
            />
          )}
          fullWidth={true}
          size="small"
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
