import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { defineActionGroup } from 'rd-redux-utils';

const filtersActionGroup = defineActionGroup('FILTERS');

export const fetchFiltersAction = filtersActionGroup.defineAction<{
  productGroupLevel?: number;
}>('FETCH ');

export const setFiltersIsFullListAction = filtersActionGroup.defineAction<{
  isFullList: boolean;
}>('SET IS FULL LIST');

export const setFiltersLoadingAction = filtersActionGroup.defineAction<{
  loading: boolean;
  loaded?: boolean;
}>('SET  LOADING');

export const fetchFiltersByCoAction = filtersActionGroup.defineAction<{}>(
  'FETCH FILTERS BY CO'
);

export const setFiltersAction = filtersActionGroup.defineAction<{
  filters: TFilterItemResponse[];
}>('SET FILTERS');

export const setAllocationLevels = filtersActionGroup.defineAction<{
  levels: TFilterAllocationLevels;
}>('SET ALLOCATION LEVELS');

export const setSelectedProductGroupFiltersAction = filtersActionGroup.defineAction<{
  filters: TFilterItemResponse[];
}>('SET SELECTED FILTERS');

export const resetSelectedFilters = filtersActionGroup.defineAction<{}>(
  'RESET SELECTED FILTERS'
);

export const setSelectedAllocationLevels = filtersActionGroup.defineAction<{
  allocationLevels: TFilterAllocationLevels;
}>('SET SELECTED ALLOCATION LEVELS');

export const setSelectedFiltersAction = filtersActionGroup.defineAction<{
  filters: TFilterItemResponse[];
}>('SET SELECTED FILTERS');
