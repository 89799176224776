import { TGetTeamRightsResponse } from '@app/services/rights-service/response-interfaces/get-team-rights.response';
import { RightsApi } from '@app/services/rights-service/rights-service';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { throttleEffect } from '@app/shared/utils/sagaUtils/sagaUtils';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import { fetchTeamRightsDoneAction } from '../actions';

export function* fetchTeamRightsSagaWorker() {
  try {
    const payload: TGetTeamRightsResponse = yield throttleEffect(
      call(RightsApi.getTeamRights, {
        skip: getCurrentSkip(),
        limit: getCurrentPageLimit(),
      })
    );

    yield put(
      fetchTeamRightsDoneAction({ items: payload.data, total: payload.total })
    );
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get Team Rights.',
          options: {
            variant: 'error',
          },
          key: 'team-rights-fetch',
        },
      })
    );
    fetchTeamRightsDoneAction({ items: [], total: 0 });
  }
}
