import {
  BaseMatrixDTO,
  BaseMarginData,
  UpdateBaseMatrixDto,
  BaseMatrixExceptionsDTO,
  BaseMarginExceptions,
  UpdateBaseMarginException,
  ProductGroups,
  ProductsName,
  IndustriesData,
  CreateException,
  CommercialOrganizationsWithCurrency,
  SetBulkBaseMarginDTO,
  ExceptionHighlight,
} from '@shared/types/baseMargin';
import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { validateExpiration } from '../../shared/utils/dateTimeUtil';

class BaseMarginService extends AxiosRequestService {
  public fetchBaseMargin = async (
    data: BaseMatrixDTO
  ): Promise<BaseMarginData> => {
    const request = await this.privateRequest.post<BaseMarginData>(
      '/BaseMarginMatrix/GetBaseMarginMatrix',
      data
    );
    return request.data;
  };

  public saveBaseMargin = async (data: UpdateBaseMatrixDto) => {
    if (data.defaultMargins.length) {
      const newData = {
        coId: data.coId,
        defaultMargins: data.defaultMargins,
      };
      await this.privateRequest.put(
        'BaseMarginMatrix/UpdateDefaultMargins',
        newData
      );
    }
    if (data.customMargins.length) {
      const newData = {
        coId: data.coId,
        customMargins: data.customMargins,
      };
      await this.privateRequest.put(
        'BaseMarginMatrix/UpdateCustomMargins',
        newData
      );
    }
  };
  public saveBaseMarginBulk = async (data: SetBulkBaseMarginDTO) => {
    await this.privateRequest.put(
      '/BaseMarginMatrix/BulkUpdateMatrixMargins',
      data
    );
  };

  public fetchBaseMarginExceptions = async (
    data: BaseMatrixExceptionsDTO
  ): Promise<BaseMarginExceptions> => {
    const req = await this.privateRequest.post<BaseMarginExceptions>(
      '/ExceptionConfiguration/GetExceptions',
      data
    );
    return req.data;
  };

  public fetchProductGroup = async (data: {
    id: string[] | undefined;
    coId: string;
  }): Promise<ProductGroups> => {
    const result = await this.privateRequest.post<ProductGroups>(
      '/Group/Children',
      {
        groupIds: data.id,
        coId: data.coId
      }
    );
    return result.data;
  };
  public fetchProductsName = async (data: {
    id: string[];
    valueAllocation: number[];
    coId: string;
  }): Promise<ProductsName> => {
    const result = await this.privateRequest.post<ProductsName>(
      '/Product/GetProducts',
      {
        productGroupIds: data.id,
        productGroupValues: data.valueAllocation,
        coId: data.coId,
      }
    );

    return result.data;
  };

  public updateBaseMarginExceptions = async (
    data: UpdateBaseMarginException[]
  ) => {
    await this.privateRequest.put('/ExceptionConfiguration/Update', {
      data,
    });
  };

  public fetchIndustries = async (data: {
    coId: string;
    industryId: string[] | undefined;
  }): Promise<IndustriesData[]> => {
    const result = await this.privateRequest.post<IndustriesData[]>(
      '/Industry/Children',
      { coId: data.coId, industryIds: data.industryId }
    );
    return result.data;
  };

  public getExcetionName = async (coId: string) => {
    const result = await this.privateRequest.get<{ data: string }>(
      '/ExceptionConfiguration/ExceptionName',
      { params: { coId } }
    );
    return result.data.data;
  };

  public createException = async (data: CreateException) => {
    await this.privateRequest.post('/ExceptionConfiguration/Create', {
      ...data,
    });
  };

  public deleteException = async (data: string[]) => {
    await this.privateRequest.delete('/ExceptionConfiguration/Delete', {
      data: { ids: data },
    });
  };

  public getCOCurrency = async (): Promise<
    CommercialOrganizationsWithCurrency[]
  > => {
    const result = await this.privateRequest.get<{
      data: CommercialOrganizationsWithCurrency[];
    }>('/Filter/CommercialOrganizationsWithCurrency');
    const mapCurrency = result.data.data.map((item) => {
      if (!item.coCurrencies.length) {
        return {
          ...item,
          coCurrencies: [{ key: 0, value: '%' }],
        };
      }
      return item;
    });
    return mapCurrency;
  };

  public getFiltersGroupBaseMatrix = async (co: string) => {
    const result = await this.privateRequest.get('/Filter/Groups', {
      params: { commercialOrganizationId: co },
    });
    return result.data;
  };

  public getFiltersIndustriesIds = async (id: string): Promise<string[]> => {
    const data = await this.privateRequest.get<{ data: string[] }>(
      'BaseMarginMatrix/GetExistingIndustryExc',
      {
        params: {
          COId: id,
        },
      }
    );
    return data.data.data;
  };

  public getAllocationLevel = async (
    coId: string,
    ids: string[],
    level: number
  ) => {
    const data = { coId, groupIds: ids, level };
    const result = await this.privateRequest.post(
      '/ExceptionConfiguration/GetValueAllocationByCOIdAndMainGroupIds',
      data
    );
    return result.data;
  };

  public getExceptionHighlights = async (
      coId: string,
      groupIds: string[],
      valueAllocations: number[]
  ) : Promise<ExceptionHighlight[]> => {
    const data = { coId, groupIds: groupIds, valueAllocations };
    const result = await this.privateRequest.post(
        '/ExceptionConfiguration/GetExceptionHighlights',
        data
      );

      return result?.data?.data?.filter((x: ExceptionHighlight) => validateExpiration(x.validFrom, x.validTo) === 2);
  };
}

export default new BaseMarginService();
