import { all, takeEvery } from 'redux-saga/effects';
import { fetchCoAction, fetchCoFiltersAction } from '../actions';
import { fetchCoProductGroupFiltersSaga } from './fetchCoProductGroupFiltersSaga';
import { fetchCoSaga } from './fetchCoSaga';

export function* coSagaWatcher() {
  yield all([
    takeEvery(fetchCoAction.TYPE, fetchCoSaga),
    takeEvery(fetchCoFiltersAction.TYPE, fetchCoProductGroupFiltersSaga),
  ]);
}
