import {
  FEATURE_BASE_MARGIN_MATRIX,
  FEATURE_EXCEPTIONS,
} from '@app/modules/BaseMarginMatrix';
import {
  FEATURE_NEGOTIATION_EXSEPTIONS,
  FEATURE_NEGOTIATION_PRODUCT_LINE,
  FEATURE_NEGOTIATION_QUOTES,
} from '@app/modules/NegotiationsRoom';
import {
  FEATURE_PACKAGING_CONFIGURATION,
  FEATURE_PACKAGING_MARGINS,
} from '@app/modules/PackagingDriver';
import { FEATURE_PRODUCT_VALUE_LABELING } from '@app/modules/ProductValue';
import {
  FEATURE_SETTINGS_PRODUCT_RIGHTS,
  FEATURE_SETTINGS_TEAMS_RIGHTS,
  FEATURE_SETTINGS_USER_RIGHTS,
  FEATURE_SETTINGS_VALUE_PRODUCTS,
} from '@app/modules/Settings/module-meta';
import { FEATURE_WIZARD } from '@app/modules/Simulator';
import { FEATURE_SYSTEM_CONFIGURATION } from '@app/modules/VolumeDriver/SystemConfigurations/module-meta';

import { FEATURE_ALLOCATION } from '@app/modules/VolumeDriver/VolumeDriverAllocation/module-meta';
import { FEATURE_CONTACT_AND_SUPPORT } from '@app/modules/ContactAndSupport/module-meta';
import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';

export const selectFeatureList: {
  name: TAccountRightsFeatureList | string;
  label: string;
}[] = [
  {
    label: 'Product value labeling',
    name: FEATURE_PRODUCT_VALUE_LABELING,
  },
  {
    label: 'Base margin matrix',
    name: FEATURE_BASE_MARGIN_MATRIX,
  },
  {
    label: 'Exceptions',
    name: FEATURE_EXCEPTIONS,
  },
  {
    label: 'Volume driver system configuration',
    name: FEATURE_SYSTEM_CONFIGURATION,
  },
  {
    label: 'Volume driver allocation',
    name: FEATURE_ALLOCATION,
  },
  {
    label: 'Packaging driver configuration',
    name: FEATURE_PACKAGING_CONFIGURATION,
  },
  {
    label: 'Packaging driver margins',
    name: FEATURE_PACKAGING_MARGINS,
  },
  {
    label: 'Product line negotiations',
    name: FEATURE_NEGOTIATION_PRODUCT_LINE,
  },
  {
    label: 'Quotes negotiations',
    name: FEATURE_NEGOTIATION_QUOTES,
  },
  {
    label: 'Negotiation exceptions',
    name: FEATURE_NEGOTIATION_EXSEPTIONS,
  },

  {
    label: 'Team rights',
    name: FEATURE_SETTINGS_TEAMS_RIGHTS,
  },
  {
    label: 'User rights',
    name: FEATURE_SETTINGS_USER_RIGHTS,
  },
  {
    label: 'Product group rights',
    name: FEATURE_SETTINGS_PRODUCT_RIGHTS,
  },
  {
    label: 'Product value rights',
    name: FEATURE_SETTINGS_VALUE_PRODUCTS,
  },
  {
    label: 'Wizard',
    name: FEATURE_WIZARD,
  },
  {
    label: 'Contact & supprot',
    name: FEATURE_CONTACT_AND_SUPPORT,
  },
];
