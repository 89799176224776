import { TMarginSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-configurations.response';
import { AppState } from '@app/store';
import { createSelector } from 'reselect';
import { MarginSystemConfigurationsState } from './store';

export const selectMarginSystemConfigurationState = (
  store: AppState
): MarginSystemConfigurationsState => store.volumeDriver.marginConfigurations;

export const selectMarginSystemConfigurationsItems = (store: AppState) =>
  selectMarginSystemConfigurationState(store).items;

export const selectMarginSystemConfigurationsLoadingState = (store: AppState) =>
  selectMarginSystemConfigurationState(store).isLoading;

export const selectMarginSystemConfigurationsTotalItems = (store: AppState) =>
  selectMarginSystemConfigurationState(store).totalItems;

export const selectMarginSystemConfigurationsCreationModalState = (
  store: AppState
) => selectMarginSystemConfigurationState(store).isCreationModalOpened;

export const selectMarginSystemConfigurationCreatingState = (store: AppState) =>
  selectMarginSystemConfigurationState(store).isCreating;

export const selectMarginSystemConfiguration = (store: AppState) =>
  selectMarginSystemConfigurationState(store).updatedConfigurations;

export const selectMarginSystemConfigurationValues = createSelector(
  selectMarginSystemConfigurationsItems,
  (items) =>
    items.reduce((accum, item) => {
      accum[item.id] = item;

      return accum;
    }, {} as Record<string, TMarginSystemConfiguration>)
);

export const selectMarginSystemConfigurationsItemsList = createSelector(
  selectMarginSystemConfigurationsItems,
  selectMarginSystemConfiguration,
  (items, updatedPayload) =>
    items.map((item) =>
      updatedPayload[item.id] ? updatedPayload[item.id] : { ...item }
    )
);
