import { TGetMarginSystemConfigurationsResponse } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-configurations.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { enqueSnackAction } from '@app/store/snacks';
import { put, call } from 'redux-saga/effects';
import {
  fetchMarginSystemConfigurationsAction,
  setMarginSystemConfigurationsDataAction,
  setMarginSystemConfigurationsLoadingAction,
} from '../actions';

export function* fetchMarginSystemConfigurationsSaga(
  action: ReturnType<typeof fetchMarginSystemConfigurationsAction>
) {
  const { skip, limit } = action;

  try {
    yield put(setMarginSystemConfigurationsLoadingAction({ loading: true }));
    const request: TGetMarginSystemConfigurationsResponse = yield call(
      VolumeDriverApi.getMarginSystemConfigurations,
      { skip, limit }
    );

    yield put(
      setMarginSystemConfigurationsDataAction({
        items: request.data,
        total: request.total,
      })
    );
  } catch (e) {
    console.log('Failed to fetch Margin system configurations list', e.message);
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get Margin Systerm configurations list.',
          options: {
            variant: 'error',
          },
          key: 'volume-driver-margin-configurations-fetch-failed',
        },
      })
    );
  } finally {
    yield put(setMarginSystemConfigurationsLoadingAction({ loading: false }));
  }
}
