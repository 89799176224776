import { Action } from 'redux';
import { setManagersDataAction, setManagersLoadingAction } from './actions';
import { initialState, ManagersState } from './state';

export function managersReducer(
  state = initialState,
  action: Action
): ManagersState {
  if (setManagersLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setManagersDataAction.is(action)) {
    return {
      ...state,
      items: action.payload,
      coId: action.coId ? action.coId : state.coId,
    };
  }

  return state;
}
