import * as React from 'react';
import { LinkProps, useHistory, Link } from 'react-router-dom';

export interface AppLinkProps extends LinkProps {
  safeLink?: boolean;
}

type Props = AppLinkProps;

export const AppLink = (props: Props) => {
  const { safeLink = true, onClick, to, ...linkProps } = props;

  const history = useHistory();

  const handleClick = React.useCallback(
    (e: any) => {
      if (safeLink) {
        if (history.location.pathname === to) {
          e.preventDefault();
        }
      }

      onClick && onClick(e);
    },
    [history, safeLink, onClick, to]
  );

  return <Link onClick={handleClick} to={to} {...linkProps} />;
};
