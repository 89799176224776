import { NEGOTIATIONS_EXCEPTIONS_URL } from '@app/routes/urls';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  fetchCoAction,
  fetchCoFiltersAction,
  selectCoFiltersId,
  selectSelectedCo,
  setSelectedCo,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import { LocationChangeAction } from 'connected-react-router';
import { put, select, take } from 'redux-saga/effects';
import { fetchExceptionsNegotiationDataAction } from '../action';
import { selectFiltersIsFullList } from '@app/store/filters-module/filters-state';
import { selectPackagingGroupsIsFullList } from '@app/store/filters-module/packaging-groups/selectors';

export function* exceptionsWorkerSaga(action: LocationChangeAction) {
  const { pathname, search } = action.payload.location;

  if (NEGOTIATIONS_EXCEPTIONS_URL.match(pathname, true).isMatched && search) {
    const selectedCo: TCommercialOrganization | null = yield select(
      selectSelectedCo
    );
    if (!selectedCo) {
      yield put(fetchCoAction({}));
      yield take(setSelectedCo.TYPE);
    }
    const filtersCoId: string = yield select(selectCoFiltersId);
    const filterIsFullList: boolean = yield select(selectFiltersIsFullList);
    const packagingIsFullList: boolean = yield select(
      selectPackagingGroupsIsFullList
    );

    if (
      selectedCo?.id !== filtersCoId ||
      filterIsFullList !== packagingIsFullList
    ) {
      yield put(fetchCoFiltersAction({}));

      yield take(setSelectedCoFilters.TYPE);
    }
    yield put(fetchExceptionsNegotiationDataAction({}));
  }
}
