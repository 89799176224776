import { FilterContainer } from '@app/shared/components/FilterContainer';
import { RoleProtectedInput } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedInput';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { ProtectedButtonWithHint } from '@app/shared/components/ProtectedButtonWithHint';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';

const useStyles = makeStyles(() => ({
  input: {
    height: '32px',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: '60px',
  },
  button: {
    lineHeight: '1.25',
    height: '52px',
  },
  filterContainer: {
    maxWidth: '320px',
  },
}));

interface Props {
  onSubmit?: (marginValue: number) => void;
  disabled?: boolean;
  initialValue?: number | string;
}

export const SetMarginForm = React.memo((props: Props) => {
  const { onSubmit, initialValue, disabled } = props;
  const styles = useStyles();

  const [inputValue, setInputValue] = React.useState<string | number>(
    initialValue ?? ''
  );

  const handleChange = React.useCallback((e: any) => {
    const { value } = e.target;

    setInputValue(value);
  }, []);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const processedValue = Number(inputValue);

      if (isNaN(processedValue)) {
        throw new Error("Value is NaN, something wen't wrong.");
      }

      onSubmit && onSubmit(processedValue);
      setInputValue('');
    },
    [inputValue, onSubmit]
  );

  const isButtonDisabled = () => {
    return disabled || !inputValue;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item classes={{ root: styles.filterContainer }}>
          <FilterContainer>
            <RoleProtectedInput
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              InputProps={{ classes: { input: styles.input } }}
              value={inputValue}
              onChange={handleChange}
              required
            />
          </FilterContainer>
        </Grid>
        <Grid item>
          <RoleProtectedButton
              classes={{ root: styles.button }}
              color="primary"
              type="submit"
              disabled= {isButtonDisabled()}
          >
            Set Margin
          </RoleProtectedButton>
        </Grid>
      </Grid>
    </form>
  );
});
