import { Action } from 'redux';
import {
  setAppLoadedAction,
  setAppLoadingAction,
  setBackdropLoadingAction,
} from './actions';
import { initialState, RootAppState } from './state';

export function rootAppReducer(
  state: RootAppState = initialState,
  action: Action
): RootAppState {
  if (setAppLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }

  if (setAppLoadedAction.is(action)) {
    return {
      ...state,
      isLoaded: action.loaded,
    };
  }

  if (setBackdropLoadingAction.is(action)) {
    return {
      ...state,
      exportLoading: action.payload,
    };
  }

  return state;
}
