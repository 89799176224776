import { TVolumeSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';

export const systemSort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.name ? a.name : '';
    const bLevel = b.name ? b.name : '';
    if (order === 'asc') {
      return aLevel.localeCompare(bLevel) as number;
    }

    if (order === 'desc') {
      return bLevel.localeCompare(aLevel) as number;
    }

    return 0;
  });
};

export const unitSort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = +a.unit;
    const bLevel = +b.unit;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};

export const descriptionSort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.description ? a.description : '';
    const bLevel = b.description ? b.description : '';
    if (order === 'asc') {
      return aLevel.localeCompare(bLevel) as number;
    }

    if (order === 'desc') {
      return bLevel.localeCompare(aLevel) as number;
    }

    return 0;
  });
};

export const level1Sort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = +a.firstLevel;
    const bLevel = +b.firstLevel;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};
export const level2Sort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = +a.secondLevel;
    const bLevel = +b.secondLevel;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};
export const level3Sort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = +a.thirdLevel;
    const bLevel = +b.thirdLevel;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};
export const level4Sort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = +a.fourthLevel;
    const bLevel = +b.fourthLevel;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};
export const level5Sort = (
  data: TVolumeSystemConfiguration[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = +a.fifthLevel;
    const bLevel = +b.fifthLevel;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};
