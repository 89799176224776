import * as qs from 'query-string';

export function pickQueryParams<T = {}>(query: string, keys?: string[]): T {
  query = query[0] === '?' ? query.substr(1) : query; // by default location.search includes "?" which should not be the case, getting rid of it.

  const parsedData = qs.parse(query);

  if (keys && keys.length) {
    return keys.reduce((accum, key, index) => {
      accum[key] = parsedData[key];
      return accum;
    }, {} as Record<string, any>) as T;
  }

  return ((parsedData || {}) as any) as T;
}
