import * as React from 'react';
import styles from './Home.module.scss';

import { landingBg } from '@app/assets/images/pageIcon';
import { HomeQuickLink } from './components/HomeQuickLink';
import { linksDefinitions } from './links-definition';
import { useSelector } from 'react-redux';
import { selectUser } from '@app/store/user';

import { Layout } from '@shared/components/Layout';

export const Home: React.FC = () => {
  const user = useSelector(selectUser);

  return (
    <Layout pageName="Start page">
      <div className={styles.home}>
        <div className={styles.home_welcomeContainer}>
          <img src={landingBg} alt="" />
          <span className={styles.home_welcomeMessage}>
            Welcome, {user.fullName}
          </span>
        </div>
        <div className={styles.home_quickLinks}>
          <h2 className={styles.home_title}>Quick links</h2>
          <div className={styles.home_quickLinks_layout}>
            {linksDefinitions.map((defs, index) => {
              return (
                <div
                  className={styles.home_quickLinks_layout_col}
                  key={`link-col-${index}`}
                >
                  <div
                    className={styles.home_quickLinks_layout_col_linkWrapper}
                  >
                    <HomeQuickLink {...defs} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};
