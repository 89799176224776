import {
  formatErrorMessageTemplate,
  INVALID_SYSTEM_LEVEL_ERROR_TEMPLATE,
  MAXIMUM_LENGTH_ERROR_TEMPLATE,
  MAXIMUM_VALUE_ERROR_TEMPLATE,
  MAXIMUM_VOLUME_SYSTEM_LEVEL_VALUE,
  MAXIMUM_VOLUME_SYSTEM_NAME_LENGTH,
  MINIMUM_LENGTH_ERROR_TEMPLATE,
  MINIMUM_VALUE_ERROR_TEMPLATE,
  MINIMUM_VOLUME_SYSTEM_LEVEL_VALUE,
  MINIMUM_VOLUME_SYSTEM_NAME_LENGTH,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from '@app/shared/validation';
import { customYup as yup } from '@app/vendor/customYup';
const MIN = MINIMUM_VOLUME_SYSTEM_LEVEL_VALUE;
const MAX = MAXIMUM_VOLUME_SYSTEM_LEVEL_VALUE;

const MINIMUM_NAME_LENGTH_ERROR = formatErrorMessageTemplate(
  MINIMUM_LENGTH_ERROR_TEMPLATE,
  [{ pattern: '{length}', value: MINIMUM_VOLUME_SYSTEM_NAME_LENGTH }]
);
const MAXIMUM_NAME_LENGTH_ERROR = formatErrorMessageTemplate(
  MAXIMUM_LENGTH_ERROR_TEMPLATE,
  [{ pattern: '{length}', value: MAXIMUM_VOLUME_SYSTEM_NAME_LENGTH }]
);

const MINIMUM_VALUE_ERROR = formatErrorMessageTemplate(
  MINIMUM_VALUE_ERROR_TEMPLATE,
  [
    {
      pattern: '{value}',
      value: MIN,
    },
  ]
);
const MAXIMUM_VALUE_ERROR = formatErrorMessageTemplate(
  MAXIMUM_VALUE_ERROR_TEMPLATE,
  [
    {
      pattern: '{value}',
      value: MAX,
    },
  ]
);

export const volumeSystemFormSchema = yup.object({
  name: yup
    .string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(MINIMUM_VOLUME_SYSTEM_NAME_LENGTH, MINIMUM_NAME_LENGTH_ERROR)
    .max(MAXIMUM_VOLUME_SYSTEM_NAME_LENGTH, MAXIMUM_NAME_LENGTH_ERROR),
  description: yup.string(),
  unit: yup.number().required(REQUIRED_FIELD_ERROR_MESSAGE),
  firstLevel: yup
    .number()
    .nullable()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(MIN, MINIMUM_VALUE_ERROR)
    .max(MAX, MAXIMUM_VALUE_ERROR),
  secondLevel: yup
    .number()
    .max(MAX, MAXIMUM_VALUE_ERROR)
    .min(
      yup.ref('firstLevel'),
      formatErrorMessageTemplate(INVALID_SYSTEM_LEVEL_ERROR_TEMPLATE, [
        { pattern: '{issuer}', value: 'Level 2' },
        { pattern: '{dependency}', value: 'Level 1' },
      ])
    )
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .nullable(),
  thirdLevel: yup
    .number()
    .min(
      yup.ref('secondLevel'),
      formatErrorMessageTemplate(INVALID_SYSTEM_LEVEL_ERROR_TEMPLATE, [
        { pattern: '{issuer}', value: 'Level 3' },
        { pattern: '{dependency}', value: 'Level 2' },
      ])
    )
    .max(MAX, MAXIMUM_VALUE_ERROR)
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .nullable(),
  fourthLevel: yup
    .number()
    .min(
      yup.ref('thirdLevel'),
      formatErrorMessageTemplate(INVALID_SYSTEM_LEVEL_ERROR_TEMPLATE, [
        { pattern: '{issuer}', value: 'Level 4' },
        { pattern: '{dependency}', value: 'Level 3' },
      ])
    )
    .max(MAX, MAXIMUM_VALUE_ERROR)
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .nullable(),
  fifthLevel: yup
    .number()
    .min(
      yup.ref('fourthLevel'),
      formatErrorMessageTemplate(INVALID_SYSTEM_LEVEL_ERROR_TEMPLATE, [
        { pattern: '{issuer}', value: 'Level 5' },
        { pattern: '{dependency}', value: 'Level 4' },
      ])
    )
    .max(MAX, MAXIMUM_VALUE_ERROR)
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .nullable(),
});
