import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { TRights } from '@app/services/rights-service/response-interfaces/get-rights.response';

export interface PermissionSelectValueDefinition {
  defaultValue: TRights;
  tooltip: string;
  Component: React.ComponentType<any>;
}

export const valueDefinitions: PermissionSelectValueDefinition[] = [
  {
    Component: VisibilityOffIcon,
    defaultValue: 0,
    tooltip: "Can't view or edit",
  },
  {
    Component: VisibilityIcon,
    defaultValue: 1,
    tooltip: 'View only',
  },
  {
    Component: EditIcon,
    defaultValue: 2,
    tooltip: 'Edit',
  },
];
