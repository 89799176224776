import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

interface Props {
  hintText: string;
  color?: 'primary' | 'secondary';
}

export const HelpTooltip = (props: Props) => {
  const { hintText, color = 'primary' } = props;

  return (
    <Tooltip title={hintText}>
      <HelpIcon color={color} fontSize="small" />
    </Tooltip>
  );
};
