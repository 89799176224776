import { defineActionGroup } from 'rd-redux-utils';

const userActionGroup = defineActionGroup('USER');

export const setUserAction = userActionGroup.defineAction<{
  id: string;
  fullName: string;
  email: string;
}>('SET');

export const setUserLoadingAction = userActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');
