import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { getWizardCurrency } from '@app/store/wizard';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { validateMarginValue } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/util/validateMarginValue';

interface EditDefaultMarginModalProps {
  status: boolean;
  value?: string;
  unit?: number;
  close: () => void;
  accept: (value: string, unit: number) => void;
}

export const EditDefaultMarginModal = ({
  status,
  value,
  unit,
  close,
  accept,
}: EditDefaultMarginModalProps) => {
  //===== CREATE LOCAL STATE =====
  const [newValue, setNewValue] = useState('');
  const [newUnit, setNewUnit] = useState(0);

  //===== DATA FROM STORE =====

  const units = useSelector(getWizardCurrency);

  //===== UPDATE NEW VALUE =====
  useEffect(() => {
    if (typeof value === 'number') setNewValue(value);
  }, [setNewValue, value]);

  //===== UPDATE NEW UNIT =====
  useEffect(() => {
    if (typeof unit === 'number') setNewUnit(unit);
  }, [setNewUnit, unit]);

  //===== VALIDATE HANDLER =====

  const [isValid, setValid] = useState(false);

  useEffect(() => {
    setValid(validateMarginValue(newValue, newUnit));
  }, [newValue, newUnit, setValid]);

  return (
    <Dialog open={status} onClose={close}>
      <DialogTitle id="alert-dialog-title">Edit default margin</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              error={!isValid}
              fullWidth
              type="number"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Select
              fullWidth
              value={newUnit}
              onChange={(e) => setNewUnit(Number(e.target.value))}
            >
              {units.map((i) => (
                <MenuItem key={i.key} value={i.key}>
                  {i.value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Disagree</Button>
        <Button
          disabled={!isValid}
          color="primary"
          onClick={() => accept(newValue, newUnit)}
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
