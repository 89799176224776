import { fetchExceptionHighlightsAction, setBaseMarginAction, setBaseMarginLoadingAction } from '../actions';
import { call, put, select } from 'redux-saga/effects';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { BaseMarginData } from '@app/shared/types/baseMargin';
import { getSelectedLevel4IndustriesIds } from '@app/store/filters-module/industries';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { enqueSnackAction } from '@app/store/snacks';
import { fetchExceptionHighlightsSaga } from './fetchExceptionHighlightsSaga';

export function* fetchBaseMarginSaga() {
  const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
  const selectedIndustiesIds: string[] = yield select(
    getSelectedLevel4IndustriesIds
  );
  const filtersValue: TFilterItemResponse[] = yield select(
    selectCoSelectedFilters
  );
  const filter4Level = filtersValue.filter((f) => f.level === 4);
  const allocationLevels: number[] = yield select((store) =>
    selectCoSelectedAllocationLevels(store).map((level) => level.key)
  );
  const skip = getCurrentSkip();
  const limit = getCurrentPageLimit();
  try {
    yield put(setBaseMarginLoadingAction({ payload: true }));
    const data: BaseMarginData = yield call(BaseMarginService.fetchBaseMargin, {
      commercialOrganizationId: selectedCo.id,
      productGroupIds: filter4Level.map((i) => i.id),
      valueAllocationLevels: allocationLevels,
      skip,
      limit,
      industryIds: selectedIndustiesIds,
      isAsc: true,
      orderColumn: 0,
    });

    yield put(setBaseMarginAction({ payload: data }));

    yield put(fetchExceptionHighlightsAction({}));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch base margin error',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch base margin error',
        },
      })
    );
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
  }
}
