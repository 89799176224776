import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { PackagingDriverApi } from '@app/services/packaging-driver-service/packaging-driver.service';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import {
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { selectSelectedPackagingGroups } from '@app/store/filters-module/packaging-groups/selectors';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  fetchDriverMarginListAction,
  saveBulkMarginAction,
  setMarginSavingAction,
} from '../actions';

export function* saveBulkMarginSaga(
  action: ReturnType<typeof saveBulkMarginAction>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));

    const { margin } = action;

    const coId: string = yield select((store) => selectSelectedCo(store)?.id);
    const filtersValue: TFilterItemResponse[] = yield select(
      selectCoSelectedFilters
    );

    const selectedProductGroups = filtersValue.filter((f) => f.level === 4);
    const allocationLevels: number[] = yield select((store) =>
      selectCoSelectedAllocationLevels(store).map((level) => level.key)
    );
    const packagingGroupIds: string[] = yield select((store) =>
      selectSelectedPackagingGroups(store).map((group) => group.id)
    );

    yield put(setMarginSavingAction({ isSaving: true }));

    yield call(PackagingDriverApi.saveMarginBulk, {
      coId: coId,
      margin: margin,
      packagingGroupIds: packagingGroupIds,
      productGroupIds: selectedProductGroups.map((i) => i.id),
      valueAllocations: allocationLevels,
    });

    yield put(fetchDriverMarginListAction({}));

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Product Margins saved.',
          options: {
            variant: 'success',
          },
          key: 'product-margins-save-saved',
        },
      })
    );
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to save margins.',
          options: {
            variant: 'error',
          },
          key: 'product-margins-save-error',
        },
      })
    );
  } finally {
    yield put(setMarginSavingAction({ isSaving: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
