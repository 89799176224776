import { emptyData } from '@app/modules/NegotiationsRoom/tabs/QuotesTab/components/QuotesTable/mapEmtyData';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { GetQuotesItemsDto } from '@app/services/negotiation-room-service/dto/get-quotes.dto';
import { NegotiationRoomApi } from '@app/services/negotiation-room-service/negotiation-room.service';
import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import { setQuotesDataAction, setQuotesLoadingAction } from '../action';

export function* fetchQuotesSaga() {
  try {
    yield put(setQuotesLoadingAction({ loading: true }));
    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);
    const dto: GetQuotesItemsDto = {
      coId: selectedCO.id,
    };
    const request: TQuotesItem[] = yield call(
      NegotiationRoomApi.getQuotesItems,
      dto
    );
    const data = request.length ? request : emptyData;
    yield put(setQuotesDataAction({ items: data, total: 0 }));
  } catch (e) {
    console.log('Failed to get quotes items', e.message);
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.title,
            options: {
              variant: 'error',
            },
            key: 'fetch quotes',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'fetch quotes',
        },
      })
    );
  } finally {
    yield put(setQuotesLoadingAction({ loading: false }));
  }
}
