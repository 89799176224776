import {
  driverIcon,
  expectionsIcon,
  marginIcon,
  negotiationIcon,
  settingsIcon,
  valueIcon,
  volumneIcon,
  wizardIcon,
} from '@app/assets/images/pageIcon';
import { QuickLinkProps } from './components/HomeQuickLink';
import * as urls from '@app/routes/urls';

export const linksDefinitions: QuickLinkProps[] = [
  {
    pathTo: urls.PRODUCT_VALUE_SECTION_1.urlTemplate,
    iconSrc: valueIcon,
    title: 'Product Value',
  },
  {
    pathTo: urls.BASE_MARGIN_MATRIX.urlTemplate,
    iconSrc: marginIcon,
    title: 'Base Margin Matrix',
  },

  {
    pathTo: urls.PACKAGING_DRIVER_MARGIN.urlTemplate,
    iconSrc: driverIcon,
    title: 'Packaging Driver',
  },
  {
    pathTo: urls.VOLUME_DRIVER_ALLOCATION.urlTemplate,
    iconSrc: volumneIcon,
    title: 'Volume Driver',
  },

  {
    pathTo: urls.BASE_MARGIN_MATRIX_EXCEPTIONS.urlTemplate,
    iconSrc: expectionsIcon,
    title: 'Exceptions',
  },
  {
    pathTo: urls.NEGOTIATIONS_URL_PRODUCT_LINE.urlTemplate,
    iconSrc: negotiationIcon,
    title: 'Negotiation Room',
  },
  {
    pathTo: urls.SIMULATOR_WIZARD_URL.urlTemplate,
    iconSrc: wizardIcon,
    title: 'Wizard',
  },
  {
    pathTo: urls.SETTINGS_TEAM_RIGHTS.urlTemplate,
    iconSrc: settingsIcon,
    title: 'Settings',
  },
];
