import * as React from 'react';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';

interface Props {
  onClick: () => void;
}

const DeleteButtonDumb = (props: Props) => {
  return (
    <IconButton aria-label="delete" {...props}>
      <DeleteIcon color="primary" fontSize="small" />
    </IconButton>
  );
};

export const DeleteButton = withConfirmation(['onClick'])(
  DeleteButtonDumb,
  ConfirmDeleteDialog
);
