import React from 'react';
import { useReducer } from 'react';
import {
  reducer,
  initialState,
  setPackagingAction,
  setPackagingValueAction,
  resetPackagingAddAction,
  setMarginTypeAction,
} from './store';
import { DialogTitle, TextField } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  addPackagingMarginAction,
  getPackagingMarginValueAllocationSelector,
} from '@app/store/wizard';
import { productAllocation } from '@modules/Simulator/data-definition';
import { PackagingSelect } from '@modules/Simulator/components/SimulatorPackagingMarginStep/components/AddPackagingMargin/components/PackagingSelect';
import { useCallback } from 'react';
import { PackagingGroup } from '@shared/types/wizard';
import { useDispatch } from 'react-redux';
import { MarginTypeSelect } from './components/MarginTypeSelect';

interface AddPackagingMarginProps {
  status: boolean;
  close: () => void;
  accept: () => void;
}

export const AddPackagingMargin = ({
  status,
  close,
  accept,
}: AddPackagingMarginProps) => {
  const dispatch = useDispatch();

  //===== CREATE LOCAL STATE =====

  const [formState, formDispatch] = useReducer(reducer, initialState);

  //===== DATA FROM STATE =====

  const valueAllocation = useSelector(
    getPackagingMarginValueAllocationSelector
  );

  //===== HANDLERS =====

  const handlerChangePackaging = useCallback(
    (value: PackagingGroup | null) => {
      formDispatch(setPackagingAction(value));
    },
    [formDispatch]
  );

  const handlerChangeValue = useCallback(
    (value) => {
      formDispatch(setPackagingValueAction(value));
    },
    [formDispatch]
  );

  const handleTypeChange = useCallback(
    (marginType: number) => {
      formDispatch(setMarginTypeAction(marginType));
    },
    [formDispatch]
  );

  const handlerAccept = () => {
    if (formState.packaging != null) {
      dispatch(
        addPackagingMarginAction({
          payload: {
            id: formState.packaging.id,
            name: formState.packaging.name,
            values: valueAllocation.map((i) =>
              formState.values[i] ? formState.values : '—'
            ),
            marginType: formState.marginType,
          },
        })
      );
    }
    formDispatch(resetPackagingAddAction());
    accept();
  };

  return (
    <Dialog open={status} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Add new packaging margin</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={4}
          style={{ marginBottom: 0 }}
        >
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              Packaging group:
            </Grid>
            <Grid item xs={8}>
              <PackagingSelect
                packaging={formState.packaging}
                handlerChange={handlerChangePackaging}
              />
            </Grid>
          </Grid>
          {valueAllocation.map((i) => (
            <Grid item container spacing={2} key={i}>
              <Grid item xs={4}>
                {productAllocation[i]}:
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  error={!formState.isValid}
                  type="number"
                  value={formState.values}
                  onChange={(e) => handlerChangeValue(e.target.value)}
                />
              </Grid>
            </Grid>
          ))}
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              Type:
            </Grid>
            <Grid item xs={8}>
              <MarginTypeSelect
                marginType={formState.marginType}
                onChange={handleTypeChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          color="primary"
          onClick={handlerAccept}
          autoFocus
          disabled={!formState.isValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
