import React from 'react';
import { AccessValuesType } from '@app/store/settings/user-rights';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { productAccessValues } from '../';

interface ProductAccessSelectPropsType {
  label: string;
  placeholder: string;
  value: AccessValuesType | null;
  onChange: (value: AccessValuesType | null) => void;
}

export const ProductAccessSelect = ({
  label,
  placeholder,
  value,
  onChange,
}: ProductAccessSelectPropsType) => {
  return (
    <Grid container item spacing={2}>
      <Grid item container alignItems="center" xs={6}>
        {label}:
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={value}
          options={productAccessValues}
          getOptionSelected={(i, value) => i.key === value.key}
          getOptionLabel={(i) => i.name}
          loading={false}
          onChange={(e, value) => onChange(value)}
          renderInput={(params) => (
            <TextField {...params} label={placeholder} required variant="outlined" />
          )}
          fullWidth={true}
          size="small"
        />
      </Grid>
    </Grid>
  );
};
