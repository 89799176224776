import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface Props {
  Component: React.FC<{}>;
  feature: TAccountRightsFeatureList;
}

export const ProtectedComponent: React.FC<Props> = ({ Component, feature }) => {
  const [canVisit] = useAccountRoleRights(feature);
  return (
    <>
      {canVisit() ? (
        <Component />
      ) : (
        <Grid container direction="column" alignItems="center">
          <Typography variant="h3" align="center">
            Woops.
          </Typography>
          <Typography variant="h4" align="center">
            Unfortunately you don't have permission to view this page :(
          </Typography>
        </Grid>
      )}
    </>
  );
};
