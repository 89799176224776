import { TAccountRightsFeatureList } from '@app/services/account-rights-service/response-interfaces/account-rights';
import {
  TTeamRights,
  TTeamRightSection,
} from '@app/services/rights-service/response-interfaces/get-team-rights.response';
import {
  TeamRightsTableData,
  TeamRightsTableOnChangePayload,
} from './TeamsRightsTable';

export interface TeamRightsTableItem extends Omit<TTeamRights, 'sections'> {
  sections: Record<string, TTeamRightSection>;
}

export function normalizeTeamRightsForTable(
  items: TTeamRights[]
): TeamRightsTableItem[] {
  return items.map((item) => {
    return {
      ...item,
      sections: item.sections.reduce((accum, item) => {
        accum[item.key] = item;
        return accum;
      }, {} as Record<string, TTeamRightSection>),
    };
  });
}

export function mapTableDataToChangePayload(
  tableData: TeamRightsTableData,
  accessType: Record<string, number>
): TeamRightsTableOnChangePayload {
  return Object.entries(tableData).map((payload) => {
    const profileId = payload[0];
    const sectionsMap = payload[1];

    return {
      profileId: profileId,
      roleAccessType: accessType[profileId],
      sections: Object.entries(sectionsMap).map((sectionPayload) => {
        const featureName = sectionPayload[0];
        const permissionValue = sectionPayload[1].value;

        return {
          key: featureName as TAccountRightsFeatureList,
          value: permissionValue,
        };
      }),
    };
  });
}
