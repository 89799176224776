import { useState, useEffect } from 'react';

function isEmpty(isLoaded: boolean, totalItems: number): boolean {
  return isLoaded && totalItems === 0;
}

export function useEmptyTableCheck(
  isLoaded: boolean,
  isLoading: boolean,
  totalItems: number
) {
  const [empty, setEmpty] = useState<boolean>(() =>
    isLoading ? false : isEmpty(isLoaded, totalItems)
  );

  useEffect(() => {
    const nextState = isLoading ? false : isEmpty(isLoaded, totalItems);

    if (nextState === empty) return;

    setEmpty(nextState);
  }, [isLoaded, totalItems, empty, isLoading]);

  return empty;
}
