import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { GroupHierarchiesFilter } from '@app/shared/components/GroupHierarchiesFilter';
import React, { useCallback, useEffect } from 'react';
import { IndustriesWrapFilter } from '@app/shared/components/IndustriesFilter';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { getIndustriesFilter } from '@app/store/filters-module/industries';
import { useDispatch } from 'react-redux';

import {
  selectCoAllocaitonLevels,
  selectCoData,
  selectCoFilters,
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  setSelectedCo,
  setSelectedCoAllocationLevels,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';

import { setBaseMarginAction } from '@app/store/baseMargin';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal';
import { setFiltersIsFullListAction } from '@app/store/filters-module/filters-state';

interface Props {
  disabled?: boolean;
  handleFilters: () => void;
  shouldAskForConfirmation: boolean;
  onSkip: () => void;
  onConfirm: () => void;
}
const InterceptedGroupHierarchyFilters = withConfirmation([
  'onFiltersChange',
  'onAllocationLevelChange',
])(GroupHierarchiesFilter, ConfirmationModal);

export const BaseMarginFilters: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { disabled = false, handleFilters } = props;
  const industries = useSelector(getIndustriesFilter);
  const allocationLevels = useSelector(selectCoAllocaitonLevels);
  const selectedAllocationLevels = useSelector(
    selectCoSelectedAllocationLevels
  );
  const coList = useSelector(selectCoData);
  const coFilters = useSelector(selectCoFilters);
  const filtersValue = useSelector(selectCoSelectedFilters);

  useEffect(() => {
    dispatch(setFiltersIsFullListAction({ isFullList: false }));
  }, [dispatch]);

  const handleFiltersChange = useCallback(
    (filters: TFilterItemResponse[]) => {
      dispatch(
        setSelectedCoFilters({
          filters,
        })
      );
      if (!filters.length) {
        dispatch(
          setBaseMarginAction({
            payload: {
              data: [],
              total: 0,
            },
          })
        );
        dispatch(setSelectedCoAllocationLevels({ levels: [] }));
        dispatch(
          setSelectedCoFilters({
            filters,
          })
        );
        return;
      }
      if (filters.length && !selectedAllocationLevels.length) {
        dispatch(setSelectedCoAllocationLevels({ levels: allocationLevels }));
      }
      handleFilters();
    },
    [handleFilters, dispatch, allocationLevels, selectedAllocationLevels]
  );
  const handleAllocationLevelsChange = useCallback(
    (levels: TFilterAllocationLevels) => {
      dispatch(setSelectedCoAllocationLevels({ levels: levels }));
      if (!filtersValue.length && levels.length) {
        dispatch(setSelectedCoFilters({ filters: coFilters }));
      }
      if (!levels.length) {
        dispatch(setSelectedCoFilters({ filters: [] }));
        dispatch(
          setBaseMarginAction({
            payload: {
              data: [],
              total: 0,
            },
          })
        );
        return;
      }

      handleFilters();
    },
    [handleFilters, dispatch, filtersValue.length, coFilters]
  );

  const handlaChangeCoFilter = useCallback(
    (e: any) => {
      const coId = e.target.value;

      const matchedCo = coList.find((co) => co.id === coId);

      if (!matchedCo) throw new Error(`CO with id: ${coId} not found!`);
      dispatch(setSelectedCo({ co: matchedCo }));
      handleFilters();
    },
    [dispatch, coList, handleFilters]
  );
  return (
    <>
      <Grid item xs={3}>
        <InterceptedGroupHierarchyFilters
          filters={coFilters}
          filtersValue={filtersValue}
          allocationLevels={allocationLevels}
          onFiltersChange={handleFiltersChange}
          allocationLevelsValue={selectedAllocationLevels}
          onAllocationLevelChange={handleAllocationLevelsChange}
          disabled={disabled}
          shouldAskForConfirm={props.shouldAskForConfirmation}
          modalProps={undefined}
          onSkip={props.onSkip}
          onConfirm={props.onConfirm}
        />
      </Grid>
      <Grid item xs={3}>
        <IndustriesWrapFilter
          modalProps={undefined}
          shouldAskForConfirm={props.shouldAskForConfirmation}
          onSkip={props.onSkip}
          onConfirm={props.onConfirm}
          filters={industries}
          handlaChangeCoFilter={handlaChangeCoFilter}
        />
      </Grid>
    </>
  );
};
