import { DeleteButton } from '@app/shared/components/AppTable/components/AppTableRow/components/DeleteButton';
import { withEditPermissionCheck } from '@app/shared/hocs/withEditPermissionCheck';
import React from 'react';


export const RoleProtectedButton = withEditPermissionCheck(
    (props: any) => {
      const { ...restProps } = props;
      return <DeleteButton {...restProps} />;
    }
  );
