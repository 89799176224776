import { put, call } from 'redux-saga/effects';
import { setCOWithCurrency } from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { CommercialOrganizationsWithCurrency } from '@app/shared/types/baseMargin';
import { enqueSnackAction } from '@app/store/snacks';

export function* fetchCOCurrencyWorkerSaga() {
  try {
    const result: CommercialOrganizationsWithCurrency[] = yield call(
      BaseMarginService.getCOCurrency
    );
    yield put(setCOWithCurrency({ payload: result }));
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'create fetch COerror',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'create fetch CO error',
        },
      })
    );
  }
}
