import { TProduct } from '@app/services/product-service/response-interfaces/get-products.response';

export interface ProductsState {
  loading: boolean;
  items: TProduct[];
  itemCount: number;
  isSaving: boolean;
}

export const initialState: ProductsState = {
  loading: false,
  items: [],
  itemCount: 0,
  isSaving: false,
};
