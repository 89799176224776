import React, { useCallback, useEffect, useState } from 'react';
import styles from './UserRights.module.scss';
import { Grid, Paper } from '@material-ui/core';
import { ConfigureDialog } from './ConfigureDialog';
import { ConfigureTemporaryDialog } from './ConfigureTemporaryDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserRightsItemCount,
  selectUserRightsItems,
  selectUserRightsLoading,
} from '@app/store/settings/user-rights/selectors';
import { UserRightsTable, UserRightsTablePayloadDeleteType, UserRightsTablePayloadType } from './UserRightsTable';
import { deleteUserRightsItemsAction, updateUserRightsItemsAction } from '@app/store/settings/user-rights';
import { RoleProtectedButton } from '@shared/components/RoleBasedRoute/components/RoleProtectedButton';
import { PageLeavePrompt } from '@modules/PageLeavePrompt';

export const UserRights: React.FC = () => {
  const dispatch = useDispatch();

  //===== CREATE LOCAL STATE =====

  const [openConfigure, setOpenConfigure] = useState(false);
  const [openConfigureTemporary, setOpenConfigureTemporary] = useState(false);
  const [isTableChanged, setIsTableChanged] = useState(false);
  const [updateTableData, setUpdateTableData] = useState<
    Record<string, UserRightsTablePayloadType>
  >({});

  //===== DATA FROM STATE =====

  const userRightsItems = useSelector(selectUserRightsItems);
  const userRightTotal = useSelector(selectUserRightsItemCount);
  const userRightLoading = useSelector(selectUserRightsLoading);

  //===== DATA WATCHERS =====

  useEffect(() => {
    if (userRightLoading) setIsTableChanged(false);
  }, [userRightLoading]);

  //===== HANDLERS =====

  const handleClickOpenConfigure = () => {
    setOpenConfigure(true);
  };

  const handleClickOpenConfigureTemporary = () => {
    setOpenConfigureTemporary(true);
  };

  const handleCloseConfigure = () => {
    setOpenConfigure(false);
  };

  const handleCloseConfigureTemporary = () => {
    setOpenConfigureTemporary(false);
  };

  const handlerChangeTableData = useCallback(
    (payload: UserRightsTablePayloadType) => {
      setUpdateTableData({
        ...updateTableData,
        [payload.id]: payload,
      });
      !isTableChanged && setIsTableChanged(true);
    },
    [updateTableData, isTableChanged, setUpdateTableData, setIsTableChanged]
  );

  const handlerDeleteItems = useCallback(
    (items: UserRightsTablePayloadDeleteType[]) => {
      dispatch(
        deleteUserRightsItemsAction({
          payload: { items },
        })
      );
    },
    [dispatch]
  );

  const handlerSaveTableData = useCallback(() => {
    isTableChanged &&
      dispatch(
        updateUserRightsItemsAction({
          payload: Object.values(updateTableData).map((i) => ({
            id: i.id,
            userId: i.userId,
            valueLabelProductAccess: i.valueLabelProductAccess,
            generalProductAccess: i.generalProductAccess,
            sections: i.sections,
          })),
        })
      );
  }, [dispatch, updateTableData, isTableChanged]);

  const handlePromptDontSave = useCallback(() => {
    setUpdateTableData({});
    setIsTableChanged(false);
  }, []);
  const handlePromptConfirm = useCallback(() => {
    handlerSaveTableData();
  }, [handlerSaveTableData]);

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={2}
        className={styles.wrapper}
      >
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <RoleProtectedButton variant="contained" onClick={handleClickOpenConfigure}>
              Configure rights
            </RoleProtectedButton>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              variant="contained"
              onClick={handleClickOpenConfigureTemporary}
            >
              Configure temporary rights
            </RoleProtectedButton>
          </Grid>
          <Grid item>
            <RoleProtectedButton
              color="primary"
              variant="contained"
              disabled={!isTableChanged}
              onClick={handlerSaveTableData}
            >
              Save
            </RoleProtectedButton>
          </Grid>
        </Grid>
        <Grid item xs={12} container direction="column" wrap="nowrap">
          <Grid item xs={12}>
            <Paper className={styles.tableWrapper}>
              <Grid container direction="column" wrap="nowrap">
                <Grid item xs={12} container>
                  <UserRightsTable
                    itemsCount={userRightTotal}
                    originItems={userRightsItems}
                    updateItems={updateTableData}
                    loading={userRightLoading}
                    onDelete={handlerDeleteItems}
                    onChange={handlerChangeTableData}
                    isEmpty={!userRightLoading && userRightTotal === 0}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <ConfigureDialog
        configureOpen={openConfigure}
        handleClickOpen={handleClickOpenConfigure}
        handleClose={handleCloseConfigure}
      />
      <ConfigureTemporaryDialog
        configureOpen={openConfigureTemporary}
        handleClickOpen={handleClickOpenConfigureTemporary}
        handleClose={handleCloseConfigureTemporary}
      />
      <PageLeavePrompt
        onSkip={handlePromptDontSave}
        when={isTableChanged}
        onConfirm={handlePromptConfirm}
      />
    </React.Fragment>
  );
};
