import { TFilterAllocationLevels } from '@app/services/filter-service/response-interfaces/get-filters.response';

export const allocationLevels: TFilterAllocationLevels = [
  {
    key: 1,
    value: 'Low',
  },
  {
    key: 2,
    value: 'Medium',
  },
  {
    key: 3,
    value: 'High',
  },
];
