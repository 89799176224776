import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import React, { useCallback, useMemo } from 'react';
import {
  hierarchiesTableSortingManager,
  sortGroup1,
  sortGroup2,
  sortGroup3,
  sortGroup4,
  hierarchiesTableSortingLevel,
} from '../../../utils/sortingHelpers';
import { Grid } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';

import { AppTable } from '@app/shared/components/AppTable';
import {
  selectLoadingGroupsTable,
  selectProductGroupAllocation,
  selectProductGroupAllocationTotal,
  selectUpdateLevel,
} from '@app/store/productValue/selectors';
import { ProductGroupAllocations } from '@app/shared/types/productValue';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import { setUpdateDataLevel } from '@app/store/productValue';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';

export const HierarchiesTable: React.FC = () => {
  const loading = useSelector(selectLoadingGroupsTable);
  const dispatch = useDispatch();

  const productGroupAllocations = useSelector(selectProductGroupAllocation);
  const productGroupAllocationsTotal = useSelector(
    selectProductGroupAllocationTotal
  );
  const updateData = useSelector(selectUpdateLevel);
  const data = useMemo(() => {
    return productGroupAllocations.map((i) => {
      return {
        ...i,
        ...updateData[i.id],
      };
    });
  }, [productGroupAllocations, updateData]);

  const handleChangeLevel = useCallback(
    (e: any, id: string) => {
      dispatch(
        setUpdateDataLevel({
          payload: {
            [id]: { allocationLevel: +e.target.value },
          },
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid item xs={12} container direction="column" wrap="nowrap">
      <Grid item xs={12} container>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            item
            xs={12}
            container
            style={{
              height: '0',
            }}
          >
            <AppTable
              data={data}
              dataCount={productGroupAllocationsTotal}
              deletableItems={false}
              loading={loading}
              isEmpty={data.length ? false : true}
            >
              <AppTable.Head>
                <AppTable.Row isHead={true}>
                  <AppTable.HeadCell
                    sortHandler={hierarchiesTableSortingManager}
                  >
                    Product Value Label Manager
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup1}>
                    Product Group 1
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup2}>
                    Product Group 2
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup3}>
                    Product Group 3
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup4}>
                    Product Group 4
                  </AppTable.HeadCell>
                  <AppTable.HeadCell sortHandler={hierarchiesTableSortingLevel}>
                    Product Group Allocation Level
                  </AppTable.HeadCell>
                </AppTable.Row>
              </AppTable.Head>

              <AppTable.Body
                noDataText={NO_FILTERS_DATA_TABLE_TEXT}
                contentColSpan={6}
              >
                {(data) =>
                  data.map((row: ProductGroupAllocations, index) => {
                    return (
                      <AppTable.Row<ProductGroupAllocations>
                        hover
                        role="checkbox"
                        item={row}
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell align="center">
                          {row.productManagerName
                            ? row.productManagerName
                            : '—'}
                        </TableCell>
                        <TableCell align="left">{row.firstGroupName}</TableCell>
                        <TableCell align="left">
                          {row.secondGroupName}
                        </TableCell>
                        <TableCell align="left">{row.thirdGroupName}</TableCell>
                        <TableCell align="left">
                          {row.fourthGroupName}
                        </TableCell>
                        <TableCell align="left">
                          <RoleProtectedSelect
                            value={row.allocationLevel}
                            onChange={(e) => {
                              handleChangeLevel(e, row.id);
                            }}
                            variant="outlined"
                            fullWidth
                          >
                            <MenuItem value={0}>
                              Choose Level
                            </MenuItem>
                            <MenuItem value={1}>Level 1</MenuItem>
                            <MenuItem value={2}>Level 2</MenuItem>
                            <MenuItem value={3}>Level 3</MenuItem>
                            <MenuItem value={4}>Level 4</MenuItem>
                          </RoleProtectedSelect>
                        </TableCell>
                      </AppTable.Row>
                    );
                  })
                }
              </AppTable.Body>
            </AppTable>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
