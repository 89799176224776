import {
  WizardStep,
  WizardStepsStatus,
} from '@modules/Simulator/components/SimulatorWizard';

export const updateStepByIndex = (
  steps: WizardStep[],
  index: number,
  updateData: WizardStepsStatus
): WizardStep[] =>
  steps.map((i, n) => (n === index ? { ...i, ...updateData } : i));
