import {
  WizardPackagingMarginOrigin,
  WizardPackagingMarginState,
} from '@shared/types/wizard';
import { validatePercentValueWithEmptyPackagingMargin } from '@modules/Simulator/components/SimulatorBaseMarginMatrixStep/components/BaseMarginMatrixTable/util/validateMarginValue';
import { pmStateToOrigin } from '@app/store/wizard/utils/convetres';

export const pmOriginValidate = (
  origin: WizardPackagingMarginOrigin[]
): boolean =>
  origin.some(
    (packaging) =>
      !packaging.productValues.some(
        (values) =>
          values.margin.length !== 1 ||
          isNaN(Number(values.margin[0].replace(',', '.')))
      )
  );

export const pmCurrentValidate = (state: WizardPackagingMarginState): boolean =>
  !state.packaging.some((packaging) =>
    packaging.values.some(
      (values) => !validatePercentValueWithEmptyPackagingMargin(values.value)
    )
  );

export const pmChangedValidate = (
  origin: WizardPackagingMarginOrigin[],
  state: WizardPackagingMarginState
): boolean => JSON.stringify(origin) !== JSON.stringify(pmStateToOrigin(state));
