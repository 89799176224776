import { Action } from 'redux';
import {
  resetCoFilters,
  resetSelectedCoAction,
  setCoAllocationLevels,
  setCoDataAction,
  setCoFiltersAction,
  setCoLoadingAction,
  setCoProductGroupFiltersAction,
  setSelectedCo,
  setSelectedCoAllocationLevels,
  setSelectedCoFilters,
} from './actions';
import { CoState, initialState } from './state';

export function coReducer(state = initialState, action: Action): CoState {
  if (setCoAllocationLevels.is(action)) {
    return {
      ...state,
      allocationLevels: action.levels,
    };
  }

  if (setSelectedCoAllocationLevels.is(action)) {
    return {
      ...state,
      selectedLevels: action.levels,
    };
  }

  if (setCoLoadingAction.is(action)) {
    return {
      ...state,
      loading: action.loading,
    };
  }

  if (setSelectedCoFilters.is(action)) {
    return {
      ...state,
      selectedFilters: action.filters,
    };
  }

  if (setCoDataAction.is(action)) {
    return {
      ...state,
      items: action.payload,
    };
  }

  if (setSelectedCo.is(action)) {
    return {
      ...state,
      selectedItem: action.co,
    };
  }

  if (setCoFiltersAction.is(action)) {
    return {
      ...state,
      filters: action.productGroupFilters,
      coFiltersId: action.coId,
    };
  }

  if (setCoProductGroupFiltersAction.is(action)) {
    return {
      ...state,
      coProductGroupFilters: action.filters,
    };
  }

  if (resetSelectedCoAction.is(action)) {
    return {
      ...state,
      selectedItem: state.selectedItem && state.items?.some(x => x.id == state.selectedItem?.id) ?
        state.selectedItem : state.items.length ?
          state.items[0] : null,
    };
  }

  if (resetCoFilters.is(action)) {
    return {
      ...state,
      coProductGroupFilters: initialState.coProductGroupFilters,
      filters: initialState.filters,
      coFiltersId: initialState.coFiltersId,
    };
  }

  return state;
}
