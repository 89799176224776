import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { DEFAULT_PAGINATION_PAGE_LIMIT } from '@app/shared/components/AppTablePagination/consts';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put } from 'redux-saga/effects';
import {
  deleteMarginSystemConfigurationsAction,
  fetchMarginSystemConfigurationsAction,
  setMarginSystemConfigurationsLoadingAction,
} from '../actions';

export function* deleteMarginSystemConfigurationsSaga(
  action: ReturnType<typeof deleteMarginSystemConfigurationsAction>
) {
  try {
    yield put(setMarginSystemConfigurationsLoadingAction({ loading: true }));

    yield call(VolumeDriverApi.deleteMarginSystemConfigurations, {
      ids: action.ids,
    });
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Selected Margin system configurations has been deleted.',
          options: {
            variant: 'success',
          },
          key: 'Margin-system-configuration-delete-success',
        },
      })
    );
    yield put(
      fetchMarginSystemConfigurationsAction({
        skip: 0,
        limit: DEFAULT_PAGINATION_PAGE_LIMIT,
      })
    );
  } catch (e) {
    console.log('Failed to delete Margin system configurations', e.message);
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message:
              'Failed to delete,check your selection.Some items might be used on Margin Driver Allocation.',
            options: {
              variant: 'warning',
            },
            key: 'margin-system-configuration-delete-failed',
          },
        })
      );
    } else {
      yield put(
        enqueSnackAction({
          notification: {
            message: 'Failed to delete, try again later.',
            options: {
              variant: 'error',
            },
            key: 'margin-system-configuration-delete-failed',
          },
        })
      );
    }
    yield put(setMarginSystemConfigurationsLoadingAction({ loading: false }));
  }
}
