import { all } from 'redux-saga/effects';
import { productRightsWatcherSaga } from '../product-rights/saga';
import { teamRightsWatcherSaga } from '../team-rights/saga/teamRightsWatcherSaga';
import { valueRightsWatcherSaga } from '../value-rights/saga';
import { userRightsWatcherSaga } from '../user-rights/saga/userRightWatcherSaga';

export function* settingsWatcherSaga() {
  yield all([
    productRightsWatcherSaga(),
    teamRightsWatcherSaga(),
    valueRightsWatcherSaga(),
    userRightsWatcherSaga(),
  ]);
}
