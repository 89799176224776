import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchDriverConfigurationListAction,
  saveDriverConfigurationsAction,
} from '../actions';
import { driverConfigurationWorkerSaga } from './driverConfigurationsWorkerSaga';
import { fetchDriverConfigurationListSaga } from './fetchDriverConfigurationListSaga';
import { saveConfigurationsSaga } from './saveConfigurationsSaga';

export function* driverConfigurationsWatcherSaga() {
  yield all([
    takeLatest(LOCATION_CHANGE, driverConfigurationWorkerSaga),
    takeEvery(
      fetchDriverConfigurationListAction.TYPE,
      fetchDriverConfigurationListSaga
    ),
    takeEvery(saveDriverConfigurationsAction.TYPE, saveConfigurationsSaga),
  ]);
}
