import { call, put, select } from 'redux-saga/effects';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';
import {
  fetchTPRTableDataAction,
  fetchWizardProductGroupInfoAction,
  getTPRSelectedCOSelector,
  getTPRSelectedProductSelector,
  getTPRSelectedValueAllocationSelector,
  getUpdateProductGroupInfoSelector,
} from '..';
import WizardServices from '@services/wizard/wizardServices';
import {
  ProductGroupAllocationInfo,
  UpdateProductGroupAllocationInfo,
} from '@shared/types/wizard';

export function* sendWizardProductGroupInfoSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);

    const co = yield select(getTPRSelectedCOSelector);
    const product = yield select(getTPRSelectedProductSelector);
    const valueAllocation = yield select(getTPRSelectedValueAllocationSelector);

    const updateProductGroup: Record<
      string,
      ProductGroupAllocationInfo
    > = yield select(getUpdateProductGroupInfoSelector);
    const res = yield call(
      WizardServices.updateProductGroupAllocations,
      coId,
      Object.values(updateProductGroup).map(
        (i): UpdateProductGroupAllocationInfo => ({
          productGroupAllocId: i.id,
          valueAllocation: i.valueAllocation,
          standardPackagingId: i.standardPackagingIds[0],
          volumeSystemId: i.volumeSystemIds[0],
        })
      )
    );
    if (res.status === 200) {
      yield put(fetchWizardProductGroupInfoAction({}));

      if (co && product && valueAllocation) {
        if (co.key === coId) {
          yield put(
            fetchTPRTableDataAction({
              payloads: {
                coId: co.key || '',
                productId: product.key || '',
                valuesAllocations: valueAllocation.key || '',
              },
            })
          );
        }
      }
    }
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
