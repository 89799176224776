import * as React from 'react';
import { UnitSelect } from '../../../UnitSelect';
import makeStyles from '@material-ui/styles/makeStyles';

interface Props {
  value: number;
  name: string;
  onChange: (name: string, value: number) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100px',
  },
}));

export const TableUnitSelect = (props: Props) => {
  const { name, onChange, value } = props;
  const classes = useStyles();

  const handleChange = React.useCallback(
    (e: any) => {
      const value = +e.target.value;

      onChange(name, value);
    },
    [onChange, name]
  );

  return (
    <UnitSelect
      onChange={handleChange}
      value={value}
      classes={{ root: classes.root }}
    />
  );
};
