import { TPackagingMarginListItem } from '@app/services/packaging-driver-service/response-interfaces/get-margin-list.response';
import { CommercialOrganizationsWithCurrency } from '@app/services/packaging-driver-service/response-interfaces/get-commercial-organizations-with-currency.response';

export interface DriverMarginsState {
  items: TPackagingMarginListItem[];
  totalItems: number;
  loading: boolean;
  isSaving: boolean;
  isLoaded: boolean;
  coWithCurrency?: CommercialOrganizationsWithCurrency;
}

export const initialState: DriverMarginsState = {
  items: [],
  totalItems: 0,
  loading: true,
  isSaving: false,
  isLoaded: false,
};
