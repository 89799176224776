import { PRODUCT_VALUE_URL } from '@app/routes/urls';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { selectFilters } from '@app/store/filters-module/filters-state';
import { fetchFiltersSaga } from '@app/store/filters-module/filters-state/sagas/fetchFIltersSaga';
import { call, put, select } from '@redux-saga/core/effects';
import { LocationChangeAction } from 'connected-react-router';
import {
  setProductValueAllocationLevels,
  setSelectedProductValueAllocationLevels,
} from '../actions';
import {
  productValueAllocationlevels,
  selectProductValueAllocationLevels,
} from '../selectors';

export function* productValueWorkerSaga(action: LocationChangeAction) {
  if (PRODUCT_VALUE_URL.match(action.payload.location.pathname).isMatched) {
    const filters: TFilterItemResponse[] = yield select(selectFilters);
    const allocationLevels: TFilterAllocationLevels = yield select(
      selectProductValueAllocationLevels
    );

    if (!filters.length) {
      yield call(fetchFiltersSaga);
    }

    if (!allocationLevels.length) {
      yield put(
        setProductValueAllocationLevels({
          levels: [...productValueAllocationlevels],
        })
      );
      yield put(
        setSelectedProductValueAllocationLevels({
          levels: [...productValueAllocationlevels],
        })
      );
    }
  }
}
