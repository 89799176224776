import { FilterApi } from '@app/services/filter-service/filter-service';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { TFiltersByCoResponse } from '@app/services/filter-service/response-interfaces/get-filters-by-co.response';
import { TFilterAllocationLevels } from '@app/services/filter-service/response-interfaces/get-filters.response';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  setCoAllocationLevels,
  setCoFiltersAction,
  setCoLoadingAction,
  setSelectedCoAllocationLevels,
  setSelectedCoFilters,
} from '../actions';
import { selectSelectedCo } from '../selectors';
import { selectFiltersIsFullList } from '@app/store/filters-module/filters-state';
import { setPackagingGroupsIsFullListAction } from '@app/store/filters-module/packaging-groups/actions';

export function* fetchCoProductGroupFiltersSaga() {
  try {
    yield put(setCoLoadingAction({ loading: true }));

    const co: TCommercialOrganization = yield select(selectSelectedCo);
    const isFullList = yield select(selectFiltersIsFullList);

    const request: TFiltersByCoResponse = yield call(
      FilterApi.getGroupFilters,
      co.id,
      isFullList
    );

    const allocationLevels: TFilterAllocationLevels = yield call(
      FilterApi.getAllocationLevels
    );

    yield put(
      setCoFiltersAction({ productGroupFilters: request.data, coId: co.id })
    );
    yield put(setCoAllocationLevels({ levels: [...allocationLevels] }));
    yield put(setSelectedCoAllocationLevels({ levels: [...allocationLevels] }));
    yield put(setPackagingGroupsIsFullListAction({ isFullList }));
    yield put(
      setSelectedCoFilters({
        filters: request.data,
      })
    );
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to get Filters list.',
          options: {
            variant: 'error',
          },
          key: 'filters-by-co-fetch',
        },
      })
    );
  } finally {
    yield put(setCoLoadingAction({ loading: false }));
  }
}
