import { AppState } from '@app/store/store';

export const selectDriverConfigurationsState = (store: AppState) =>
  store.driver.configurations;

export const selectDriverConfigurationsList = (store: AppState) =>
  selectDriverConfigurationsState(store).items;

export const selectDriverConfigurationsTotalItems = (store: AppState) =>
  selectDriverConfigurationsState(store).totalItems;

export const selectDriverConfigurationsLoadingState = (store: AppState) =>
  selectDriverConfigurationsState(store).loading;

export const selectDriverConfigurationsSavingState = (store: AppState) =>
  selectDriverConfigurationsState(store).isSaving;

export const selectDriverConfigurationUpdateData = (store: AppState) =>
  selectDriverConfigurationsState(store).updateData;
