import React from 'react';
import { useHistory, withRouter } from 'react-router';
import { setSettingsLoadedAction } from '@app/store/settings/actions';
import { useDispatch } from 'react-redux';
import { TabRouterLayout } from '@app/shared/components/TabRouterLayout';
import { settingsTabRoutes } from './tab-definitions';

export const Settings = withRouter(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { pathname } = history.location;

  React.useEffect(() => {
    return () => {
      dispatch(setSettingsLoadedAction({ loaded: false }));
    };
  }, [pathname, dispatch]);

  return <TabRouterLayout pageTitle="Settings" routes={settingsTabRoutes} />;
});
