import * as React from 'react';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/styles/makeStyles';

// TO DO: Implement sticky feature

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
  },
}));

interface Props extends TableCellProps {
  fixedCell?: boolean;
}

export const AppTableCell = (props: Props) => {
  const classes = useStyles();

  return <TableCell classes={{ root: classes.root }} {...props} />;
};
