import { all, cancel, cancelled, fork, take } from 'redux-saga/effects';
import { productValueRootSaga } from './productValue/sagas';
import { productsRootSaga } from './products/saga/productsRootSaga';
import { settingsWatcherSaga } from './settings/saga/settingsWatcherSaga';
import { filtersModuleSagaWatcher } from './filters-module/saga';
import { baseMarginRootSaga } from './baseMargin/sagas/baseMarginRootSaga';
import { appSagaWatcher } from './app/sagas/appSagaWatcher';
import { authSagaWatcher } from './auth/sagas/authSagaWatcher';
import { packagingDriverWatcherSaga } from './driver/saga/packagingDriverWatcherSaga';
import { volumeDriverWatcherSaga } from './volume-driver/saga/volumeDriverWatcherSaga';
import { negotiationRoomWatcherSaga } from './negotiation-room/saga/negotiationRoomWatcherSaga';
import { managersSaga } from './managers/sagas';
import { wizardRootSaga } from './wizard/sagas/wizardRootSata';
import { userSagaWatcher } from './user/sagas/userWatcherSaga';
import { cancelSagasAction } from './actions';

function* main() {
  try {
    yield all([
      appSagaWatcher(),
      authSagaWatcher(),
      userSagaWatcher(),
      productValueRootSaga(),
      productsRootSaga(),
      settingsWatcherSaga(),
      filtersModuleSagaWatcher(),
      baseMarginRootSaga(),
      packagingDriverWatcherSaga(),
      volumeDriverWatcherSaga(),
      negotiationRoomWatcherSaga(),
      managersSaga(),
      wizardRootSaga(),
    ]);
  } finally {
    if (yield cancelled()) {
      console.log('Cancelled sagas.');
    }
  }
}

export function* rootSaga() {
  while (true) {
    console.log("Saga's tasks started");
    const task = yield fork(main);

    yield take([cancelSagasAction.TYPE]);
    yield cancel(task);
  }
}
