import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import { FilterContainer } from '@app/shared/components/FilterContainer';
import { ButtonWithHintText } from '@app/shared/components/ButtonWithHintText';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import { AppSelect } from '@app/shared/components/AppSelect';
import { RoleProtectedInput } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedInput';
import { useDispatch } from 'react-redux';
import { bulkProductLineAction } from '@app/store/negotiation-room/product-line';
import { transformToSaveDecimalString } from '@app/shared/utils/transformUtils';

const useStyles = makeStyles(() => ({
  input: {
    height: '32px',
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: '80px',
  },
  select: {
    maxWidth: '200px',
  },
}));

export const ProdutcLineForm = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [valueSelect, setValueSelect] = React.useState<string>('1');
  const [limit, setLimit] = React.useState<string>('');
  const handleLimit = React.useCallback((e: any) => {
    if (e.target.value < 0 || e.target.value > 99.99) return;
    setLimit(e.target.value as string);
  }, []);
  const handleChangeSelect = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setValueSelect(event.target.value as string);
    },
    []
  );
  const handleSetBulk = React.useCallback(() => {
    dispatch(
      bulkProductLineAction({
        payload: {
          limit: transformToSaveDecimalString(limit),
          limitLevels: +valueSelect,
        },
      })
    );
    setLimit('');
    setValueSelect('1');
  }, [dispatch, limit, valueSelect]);
  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <FilterContainer>
          <RoleProtectedSelect
            onChange={handleChangeSelect}
            value={valueSelect}
            fullWidth
            className={styles.select}
          >
            <AppSelect.Option value={'1'}>Negotiation limit 1</AppSelect.Option>
            <AppSelect.Option value={'2'}>Negotiation limit 2</AppSelect.Option>
            <AppSelect.Option value={'3'}>Negotiation limit 3</AppSelect.Option>
          </RoleProtectedSelect>
        </FilterContainer>
      </Grid>
      <Grid item>
        <FilterContainer>
          <RoleProtectedInput
            size="small"
            variant="outlined"
            value={limit}
            onChange={handleLimit}
            type="number"
            InputProps={{
              classes: { input: styles.input },
            }}
          />
        </FilterContainer>
      </Grid>

      <Grid item>
        <ButtonWithHintText
          hintText="Set limit to all customer segments"
          style={{ height: '100%' }}
          onClick={handleSetBulk}
          disabled={!valueSelect.length || !limit.length}
        >
          Set
        </ButtonWithHintText>
      </Grid>
    </Grid>
  );
};
