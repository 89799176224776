import { TVolumeSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-options.response';

export interface VolumeOptionsState {
  items: TVolumeSystemOption[];
  isLoading: boolean;
}

export const initialState: VolumeOptionsState = {
  items: [],
  isLoading: true,
};
