import * as yup from 'yup';

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;

export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      'Password must be minimum 12 symbols length,contain digit and special symbol.'
    ),
  confirm: yup.string().oneOf([yup.ref('password')], 'Passwords must be same'),
});
