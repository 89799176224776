import { createSelector } from 'reselect';
import { AppState } from '@app/store';
import {
  CommercialOrganizations,
  LookupValueSystem,
  PackagingGroup,
  ProductAllocationValue,
  ProductGroupAllocation,
  ProductGroupAllocationInfo,
  ProductGroupAllocationLevel,
  UpdateWizardVolumeDriver,
  WizardBaseMarginMatrixOrigin,
  WizardBaseMarginMatrixSending,
  WizardBaseMarginMatrixState,
  WizardMarginSystem,
  WizardPGAVolumeDriver,
} from '@shared/types/wizard';
import { WizardBMMIndustry } from '@shared/types/wizard';
import { bmmConverterStateToSending } from './utils/convetres';
import { WizardPackagingMarginState } from '@shared/types/wizard';
import { WizardPackagingMarginEntryState } from '@shared/types/wizard';
import { WizardPackagingMarginOrigin } from '@shared/types/wizard';
import { pmStateToSending } from './utils/convetres';
import { OptionItem } from '@modules/Simulator/components/TargetPriceResults/utils';
import { productAllocation } from '@modules/Simulator/data-definition';

//===== COMMERCIAL ORGANIZATION AND CURRENCY  =====

export const getWizardCOIdSelector = (state: AppState): string =>
  state.wizard.coId;

export const getWizardCommercialOrganizationsSelector = (
  state: AppState
): CommercialOrganizations[] => state.wizard.commercialOrganizations;

export const getWizardCurrency = createSelector(
  getWizardCOIdSelector,
  getWizardCommercialOrganizationsSelector,
  (id, coItems) => coItems.filter((i) => i.id === id)[0].coCurrencies
);

export const getWizardCurrencyByKey = createSelector(
  getWizardCOIdSelector,
  getWizardCommercialOrganizationsSelector,
  (id, coItems): Record<number, string> =>
    coItems
      .filter((i) => i.id === id)[0]
      .coCurrencies.reduce((byKey, i) => ({ ...byKey, [i.key]: i.value }), {})
);

//=====  =====

export const getWizardProductGroupAllocationLevelSelector = (
  state: AppState
): ProductGroupAllocationLevel[] => state.wizard.productGroupAllocationLevels;

//===== CURRENT PRODUCT GROUP =====

export const getWizardCurrentAllocationLevelSelector = (
  state: AppState
): ProductGroupAllocationLevel | null => state.wizard.currentAllocationLevel;

export const getWizardCurrentProductIdSelector = (state: AppState): string =>
  state.wizard.currentAllocationLevel
    ? state.wizard.currentAllocationLevel.id
    : '';

export const getWizardCurrentProductNameSelector = (state: AppState): string =>
  state.wizard.currentAllocationLevel
    ? state.wizard.currentAllocationLevel.name
    : '';

export const getProductGroupAllocationsSelector = (
  state: AppState
): ProductGroupAllocation[] => state.wizard.productGroupAllocations.data;

export const getProductGroupAllocationsTotalSelector = (
  state: AppState
): number => state.wizard.productGroupAllocations.total;

export const getWizardLoadingSelector = (state: AppState): boolean =>
  state.wizard.loading;

export const getWizardLookupVolumeSystemSelector = (
  state: AppState
): LookupValueSystem[] => state.wizard.lookupVolumeSystem;

export const getWizardVolumeSystemByIdSelector = (
  state: AppState
): Record<string, LookupValueSystem> =>
  state.wizard.lookupVolumeSystem.reduce(
    (akk: Record<string, LookupValueSystem>, item: LookupValueSystem) => ({
      ...akk,
      [item.id]: item,
    }),
    {}
  );

export const getWizardProductGroupInfoSelector = (
  state: AppState
): ProductGroupAllocationInfo[] => state.wizard.productGroupAllocationInfo;

export const getUpdateProductGroupInfoSelector = (
  state: AppState
): Record<string, ProductGroupAllocationInfo> =>
  state.wizard.updateProductGroupAllocationInfo;

export const getWizardPackagingGroupByIdSelector = (
  state: AppState
): Record<string, PackagingGroup> =>
  state.wizard.packagingGroup.reduce(
    (akk: Record<string, PackagingGroup>, item: PackagingGroup) => ({
      ...akk,
      [item.id]: item,
    }),
    {}
  );

export const getWizardPackagingGroupSelector = (
  state: AppState
): PackagingGroup[] => state.wizard.packagingGroup;

export const getWizardCurrentsValueAllocationSelector = (
  state: AppState
): ProductAllocationValue[] => state.wizard.currentsValueAllocation;

export const getWizardProductGroupInfoWithFilterSelector = createSelector(
  getWizardProductGroupInfoSelector,
  getWizardCurrentsValueAllocationSelector,
  (priductGroups, values): ProductGroupAllocationInfo[] =>
    priductGroups.filter((i) =>
      values.length ? values.some((v) => v.key === i.valueAllocation) : true
    )
);

export const getValueAllocationQuerySelector = createSelector(
  getWizardCurrentsValueAllocationSelector,
  (values) => values.map((i) => i.key)
);

//===== MARGIN SYSTEM =====

export const getWizardMarginSystemSelector = (
  state: AppState
): WizardMarginSystem[] => state.wizard.marginSystem;

export const getWizardMarginSystemByIdSelector = createSelector(
  getWizardMarginSystemSelector,
  (system) => system.reduce((obj, i) => ({ ...obj, [i.id]: i }), {})
);

//===== PGA VOLUME DRIVER =====

export const getWizardPGAVolumeDriverSelector = (
  state: AppState
): WizardPGAVolumeDriver[] => state.wizard.pgaVolumeDriver;

export const getUpdateWizardPGAVolumeDriverSelector = (
  state: AppState
): Record<string, UpdateWizardVolumeDriver> =>
  state.wizard.pgaVolumeDriverUpdate;

//===== BASE MARGIN MATRIX =====

export const getWizardBaseMarginMatrixSelector = (
  state: AppState
): WizardBaseMarginMatrixState[] => state.wizard.baseMarginMatrix;

export const getWizardBaseMarginMatrixOriginSelector = (
  state: AppState
): WizardBaseMarginMatrixOrigin[] => state.wizard.baseMarginMatrixOrigin;

export const getInvalidBaseMarginValueSelector = (state: AppState): number[] =>
  state.wizard.baseMarginMatrix
    .filter((i) => isNaN(Number(i.marginValues)))
    .map((i) => i.valueAllocation);

export const getWizardBMMIndustriesSelector = (
  state: AppState
): WizardBMMIndustry[] => state.wizard.bmmIndustries;

export const getWizardBMMIndustriesFilterSelector = createSelector(
  getWizardBaseMarginMatrixSelector,
  getWizardBMMIndustriesSelector,
  (bmm, industries): Record<string, WizardBMMIndustry[]> =>
    bmm.reduce<Record<number, WizardBMMIndustry[]>>(
      (res, bmmItem) => ({
        ...res,
        [bmmItem.valueAllocation]: industries.filter(
          (industry) =>
            !bmmItem.industryMargins.some((i) => i.industryId === industry.id)
        ),
      }),
      {}
    )
);

export const getWizardBaseMarginMatrixSendingSelector = createSelector(
  getWizardBaseMarginMatrixSelector,
  (state): WizardBaseMarginMatrixSending[] => bmmConverterStateToSending(state)
);

//===== PACKAGING MARGIN =====

export const getPackagingMarginSelector = (
  state: AppState
): WizardPackagingMarginState => state.wizard.packagingMargin;

export const getPackagingMarginOriginSelector = (
  state: AppState
): WizardPackagingMarginOrigin[] => state.wizard.packagingMarginOrigin;

export const getPackagingMarginSending = createSelector(
  getPackagingMarginSelector,
  getPackagingMarginOriginSelector,
  (state, origin) => pmStateToSending(state, origin)
);

export const getPackagingMarginEntrySelector = (
  state: AppState
): WizardPackagingMarginEntryState[] => state.wizard.packagingMargin.packaging;

export const getPackagingMarginValueAllocationSelector = (
  state: AppState
): number[] => state.wizard.packagingMargin.valueAllocation;

export const getDefaultProductGroupSelector = createSelector(
  getWizardProductGroupInfoSelector,
  (groups): Record<number, string> =>
    groups.reduce(
      (data, i) => ({
        ...data,
        [i.valueAllocation]: i.standardPackagingIds[0],
      }),
      {}
    )
);

export const getAvailablePackagingGroupSelector = createSelector(
  getPackagingMarginEntrySelector,
  getWizardPackagingGroupSelector,
  (values, group): PackagingGroup[] =>
    group.filter((i) => !values.some((value) => value.id === i.id))
);

//===== TARGET PRICE RESULT =====

export const getTPRSelectedCOSelector = (state: AppState): OptionItem | null =>
  state.wizard.targetPriceResult.selectedCo;

export const getTPRSelectedValueAllocationSelector = (state: AppState): OptionItem | null =>
  state.wizard.targetPriceResult.selectedValueAllocation;

export const getTPRSelectedProductSelector = (state: AppState): OptionItem | null =>
  state.wizard.targetPriceResult.selectedProduct;

//===== select form =====

export const getTPROptionsCOSelector = (state: AppState): OptionItem[] =>
  state.wizard.targetPriceResult.coId.map((i) => ({
    key: i.id,
    name: i.name,
  }));

export const getTPROptionsProductGroupSelector = (
  state: AppState
): OptionItem[] =>
  state.wizard.targetPriceResult.productGroup.map((i) => ({
    key: i.id,
    name: i.name,
  }));

export const getTPROptionsIndustriesSelector = (
  state: AppState
): OptionItem[] =>
  state.wizard.targetPriceResult.industries.map((i) => ({
    key: i.id,
    name: i.name,
  }));

export const getTPROptionsProductValueSelector = (
  state: AppState
): OptionItem[] =>
  state.wizard.targetPriceResult.productValues.map((i) => ({
    key: String(i.valueAllocation),
    name: productAllocation[i.valueAllocation],
  }));

//===== table data =====

export const getTPRTableDataSelector = (
  state: AppState
): {
  coId: CommercialOrganizations[];
  baseMargin: WizardBaseMarginMatrixOrigin[];
  packagingDriver: WizardPackagingMarginOrigin[];
  volumeDriver: WizardPGAVolumeDriver[];
  marginVolumeDriver: LookupValueSystem[];
} => ({
  coId: state.wizard.targetPriceResult.coId,
  baseMargin: state.wizard.targetPriceResult.baseMargin,
  packagingDriver: state.wizard.targetPriceResult.packagingDriver,
  volumeDriver: state.wizard.targetPriceResult.volumeDriver,
  marginVolumeDriver: state.wizard.targetPriceResult.marginVolumeDriver,
});
