import { put, call, select } from 'redux-saga/effects';
import { setExceptionName } from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { selectSelectedCo } from '@app/store/filters-module/co';

export function* fetchExceptionNameWorkerSaga() {
  try {
    const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);

    const result: string = yield call(
      BaseMarginService.getExcetionName,
      selectedCo.id
    );
    yield put(setExceptionName({ payload: result }));
  } catch (e) {
    console.log(`Failed to get exception name: ${e.toString()}`);
  }
}
