import { UserRightsTablePayloadType } from '@app/modules/Settings/components/UserRights/UserRightsTable';
import {
  COReferenceDataType,
  TeamReferenceDataType,
  UserReferenceDataType,
} from '.';

export interface UserRightsState {
  loading: boolean;
  isLoaded: boolean;
  total: number;
  items: UserRightsTablePayloadType[];
  referenceData: {
    users: UserReferenceDataType[];
    usersForTempRights: UserReferenceDataType[];
    teams: TeamReferenceDataType[];
    CO: COReferenceDataType[];
  };
}

export const initialState: UserRightsState = {
  loading: true,
  isLoaded: false,
  total: 0,
  items: [],
  referenceData: {
    users: [],
    usersForTempRights:[],
    teams: [],
    CO: [],
  },
};
