import { CreatUserRightsStateType, rightsHeaders } from '../UserRightsTable';
import { UserReferenceDataType } from '@app/store/settings/user-rights';
import { TeamReferenceDataType } from '@app/store/settings/user-rights';
import { COReferenceDataType } from '@app/store/settings/user-rights';
import { AccessValuesType } from '@app/store/settings/user-rights';
import { RightsValuesType } from '@app/store/settings/user-rights';

//===== TYPES =====

const setUserType = 'SET_USER';
const setTeamType = 'SET_TEAM';
const setCOType = 'SET_CO';
const setValueLabelAccessType = 'SET_VALUE_LABEL_ACCESS';
const setGeneralAccessType = 'SET_GENERAL_ACCESS';
const setSectionRightsType = 'SET_SECTIONS_RIGHTS';
const setSectionRightsBulkType = 'SET_SECTION_RIGHTS_BULK';
const changeEditableSectionRightsType = 'CHANGE_EDITABLE_SECTION_RIGHTS';
const resetStateType = 'RESET_STATE';

//===== INITIAL STATE =====

export const initialState: CreatUserRightsStateType = {
  userId: null,
  teamId: null,
  COId: null,
  valueLabelAccess: null,
  generalAccess: null,
  sections: rightsHeaders.map((i) => ({ key: i.key, value: null })),
  sectionsEditable: false,
};

//===== REDUCER =====

export const createUserRightsReducer = (
  state: CreatUserRightsStateType,
  action: { type: string; payload?: any }
): CreatUserRightsStateType => {
  switch (action.type) {
    case setUserType:
      return { ...state, userId: action.payload };
    case setTeamType:
      return { ...state, teamId: action.payload };
    case setCOType:
      return { ...state, COId: action.payload };
    case setValueLabelAccessType:
      return { ...state, valueLabelAccess: action.payload };
    case setGeneralAccessType:
      return { ...state, generalAccess: action.payload };
    case setSectionRightsType:
      return {
        ...state,
        sections: state.sections.map((i) =>
          i.key === action.payload.key ? action.payload : i
        ),
      };
    case setSectionRightsBulkType:
      return { ...state, sections: action.payload };
    case changeEditableSectionRightsType:
      return { ...state, sectionsEditable: action.payload };

    case resetStateType:
      return {
        userId: null,
        teamId: null,
        COId: null,
        valueLabelAccess: null,
        generalAccess: null,
        sections: rightsHeaders.map((i) => ({ key: i.key, value: null })),
        sectionsEditable: false,
      };
    default:
      return state;
  }
};

//===== ACTIONS =====

export const setUserAction = (user: UserReferenceDataType | null) => ({
  type: setUserType,
  payload: user,
});
export const setTeamAction = (team: TeamReferenceDataType | null) => ({
  type: setTeamType,
  payload: team,
});
export const setCOAction = (CO: COReferenceDataType | null) => ({
  type: setCOType,
  payload: CO,
});
export const setValueLabelAccessAction = (access: AccessValuesType | null) => ({
  type: setValueLabelAccessType,
  payload: access,
});
export const setGeneralAccessAction = (access: AccessValuesType | null) => ({
  type: setGeneralAccessType,
  payload: access,
});
export const changeEditableSectionRightsAction = (isEditable: boolean) => ({
  type: changeEditableSectionRightsType,
  payload: isEditable,
});

export const setSectionsRightsAction = (rights: {
  key: string;
  value: RightsValuesType | null;
}) => ({
  type: setSectionRightsType,
  payload: rights,
});
export const setSectionsRightsBulkAction = (
  sections: {
    key: string;
    value: RightsValuesType | null;
  }[]
) => ({ type: setSectionRightsBulkType, payload: sections });

export const resetStateAction = () => ({ type: resetStateType });
