import {
  TProductRightResponse,
  TValueRightResponse,
  TValueRights,
} from './response-interfaces/get-rights.response';
import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { GetProductGroupRightsDto } from './dto/get-product-group-rights.dto';
import {
  UpdateProductGroupRightsDto,
  UpdateValueRightsDto,
} from './dto/update-product-group-rights.dto';
import { CreateProductGroupRightDto } from './dto/create-product-right.dto';
import { DeleteProductRightsDto } from './dto/delete-product-rights.dto';
import { GetTeamRightsDto } from './dto/get-team-rights.dto';
import { TGetTeamRightsResponse } from './response-interfaces/get-team-rights.response';
import { DeleteTeamRightsDto } from './dto/delete-team-rights.dto';
import { CreateTeamRightsDto } from './dto/create-team-rights.dto';
import { UpdateTeamRightsDto } from './dto/update-team-rights.dto';
import { GetValueGroupRightsDto } from './dto/get-value-rights.dto';
import {
  UserRightsTablePayloadCreateType,
  UserRightsTablePayloadDeleteType,
  UserRightsTablePayloadUpdateType,
  UserTemporaryRightsPayloadType,
} from '@modules/Settings/components/UserRights/UserRightsTable';
import { TGetUserCoId } from './response-interfaces/get-user-coid';
import { ProductGroups } from '@app/shared/types/baseMargin';

class RightsService extends AxiosRequestService {

  getTeamRights = async (
    dto: GetTeamRightsDto
  ): Promise<TGetTeamRightsResponse> => {
    const request = await this.privateRequest.post<TGetTeamRightsResponse>(
      '/Rights/TeamRights',
      dto
    );

    return request.data;
  };

  createTeamRights = async (dto: CreateTeamRightsDto) => {
    await this.privateRequest.post('/Rights/CreateTeamRights', dto);
  };

  updateTeamRights = async (dto: UpdateTeamRightsDto[]) => {
    await this.privateRequest.put('/Rights/UpdateTeamRights', { data: dto });
  };

  deleteTeamRights = async (dto: DeleteTeamRightsDto) => {
    await this.privateRequest.delete('/Rights/DeleteTeamRights', {
      data: dto,
    });
  };

  //Group Rights

  getProductGroupRights = async (
    dto: GetProductGroupRightsDto
  ): Promise<TProductRightResponse> => {
    const request = await this.privateRequest.post<TProductRightResponse>(
      '/ProductUserRight/Get',
      dto
    );

    return {
      total: request.data.total,
      data: request.data.data,
    };
  };

  createProductRight = async (
    dto: CreateProductGroupRightDto
  ): Promise<void> => {
    await this.privateRequest.post('/ProductUserRight/Create', dto);
  };

  updateProductGroupRights = async (
    dto: UpdateProductGroupRightsDto
  ): Promise<void> => {
    await this.privateRequest.put('/ProductUserRight/Update', dto);
  };

  deleteProductGroupRights = async (
    dto: DeleteProductRightsDto[]
  ): Promise<void> => {
    await this.privateRequest.delete('/ProductUserRight/Delete', {
      data: { items: dto },
    });
  };

  // getMangersCoId = async (dto: string): Promise<TGetUserCoId[]> => {
  //   const data = await this.privateRequest.get<{ data: TGetUserCoId[] }>(
  //     '/Admin/GetUsersByCoId',
  //     { params: { coId: dto } }
  //   );
  //   return data.data.data;
  // };

  //may be rework, HOT FIX!!!
  getUserWithSpecificProductsRigthsByCOId = async (dto: string): Promise<TGetUserCoId[]> => {
    const data = await this.privateRequest.get<{ data: TGetUserCoId[] }>(
      '/UserRights/GetUserWithSpecificProductsRigthsByCOId',
      { params: { coId: dto } }
    );
    return data.data.data;
  };

  // HOT FIX params
  getUserByProductAccessType = async (): Promise<TGetUserCoId[]> => {
    const data = await this.privateRequest.get<{ data: TGetUserCoId[] }>(
      '/UserRights/GetUserByProductAccessType',
      { params: { productAccess: 2 } });
    return data.data.data;
  };

  //Value Rights
  getValueGroupRights = async (
    dto: GetValueGroupRightsDto
  ): Promise<{ total: number; data: TValueRights[] }> => {
    const request = await this.privateRequest.post<TValueRightResponse>(
      '/ProductValueRight/Get',
      dto
    );
    return {
      total: request.data.total,
      data: request.data.data,
    };
  };

  public fetchFullProductGroups = async (data: {
    id: string[] | undefined;
    coId: string;
    isFullList: boolean
  }): Promise<ProductGroups> => {
    const result = await this.privateRequest.post<ProductGroups>(
      '/Group/Children',
      {
        COId: data.coId,
        groupIds: data.id,
        isFullList: data.isFullList
      }
    );
    return result.data;
  };

  public fetchFullChildrenGroups = async (data: {
    id: string[] | undefined;
  }): Promise<ProductGroups> => {
    const result = await this.privateRequest.post<ProductGroups>(
      '/Group/FullChildren',
      {
        groupIds: data.id,
        isFullList: true
      }
    );
    return result.data;
  };



  createValueRight = async (dto: CreateProductGroupRightDto): Promise<void> => {
    await this.privateRequest.post('/ProductValueRight/Create', dto);
  };

  updateValueRights = async (dto: UpdateValueRightsDto): Promise<void> => {
    await this.privateRequest.put('/ProductValueRight/Update', dto);
  };

  deleteValueRights = async (dto: DeleteProductRightsDto[]): Promise<void> => {
    await this.privateRequest.delete('/ProductValueRight/Delete', {
      data: { items: dto },
    });
  };

  getMangers = async (): Promise<TGetUserCoId[]> => {
    const data = await this.privateRequest.get<{ data: TGetUserCoId[] }>(
      '/UserRights/GetUsersWithoutRights'
    );
    return data.data.data;
  };

  //===== USER RIGHTS =====

  getUserRights = async (skip: number, limit: number) => {
    const res = await this.privateRequest.post('/UserRights/Get', {
      skip: skip,
      limit: limit,
      orderColumn: 0,
      isAsc: true,
    });

    return res.data;
  };

  deleteUserRights = async (items: UserRightsTablePayloadDeleteType[]) => {
    console.log('DELETE USER RIGHTS');

    const res = await this.privateRequest.delete('/UserRights/Delete', {
      data: {
        userRights: items,
      },
    });

    return res.data;
  };

  saveUserRights = async (items: UserRightsTablePayloadUpdateType[]) => {
    await this.privateRequest.put('/UserRights/Update', { data: items });
  };

  createUserRights = async (item: UserRightsTablePayloadCreateType) => {
    const res = await this.privateRequest.post('/UserRights/Create', item);

    return res.data;
  };

  createUserTemporaryRights = async (item: UserTemporaryRightsPayloadType) => {
    item.sections = item.sections.filter(x => x.value);
    const res = await this.privateRequest.post('/UserRights/CreateTemporary', item);

    return res.data;
  };

  //===== USER RIGHTS REFERENCE DATA =====

  getUserReferenceDataUR = async () => {
    const res = await this.privateRequest.get('/UserRights/GetUsersWithoutRights');

    return res.data;
  };

  getUserForTempUR = async () => {
    const res = await this.privateRequest.get('/UserRights/GetUsersForTempRights');

    return res.data;
  }

  getTeamReferenceDataUR = async () => {
    const request = await this.privateRequest.post<TGetTeamRightsResponse>(
      '/Rights/TeamRights',
      {
        skip: 0,
        limit: 100,
        orderColumn: 0,
        isAsc: true,
      }
    );

    return request.data;
  };
  getCOReferenceDataUR = async () => {
    const res = await this.privateRequest.get(
      '/Filter/CommercialOrganizations'
    );

    return res.data;
  };
}

export const RightsApi = new RightsService();
