import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import {
  ProductGroupAllocation,
  LookupValueSystem,
  ProductGroupAllocationLevel,
  ValueAllocation,
  ValueSystem,
  CO,
  ProductGroupAllocationInfo,
  PackagingGroup,
  UpdateProductGroupAllocationInfo,
  WizardMarginSystem,
  WizardPGAVolumeDriver,
  UpdateWizardVolumeDriver,
  WizardBaseMarginMatrixSending,
  WizardPackagingMarginOrigin,
  WizardPackagingMarginSending,
} from '@shared/types/wizard';
import { WizardBaseMarginMatrixOrigin } from '@shared/types/wizard';

class WizardServices extends AxiosRequestService {
  public getVolumeSystems = async (): Promise<ValueSystem[]> => {
    const result = await this.privateRequest.get<ValueSystem[]>(
      '/Wizard/GetVolumeSystems'
    );
    return result.data;
  };

  public getProductGroupAllocationLevels = async (
    COId: string
  ): Promise<ProductGroupAllocationLevel[]> => {
    const result = await this.privateRequest.get<ProductGroupAllocationLevel[]>(
      '/Wizard/GetProductGroupAllocationLevels',
      { params: { COId } }
    );
    return result.data;
  };

  public getLookupVolumeSystem = async (
    volumeSystemIds: string[] = []
  ): Promise<LookupValueSystem[]> => {
    const results = await this.privateRequest.post<LookupValueSystem[]>(
      '/Wizard/GetLookupVolumeSystem',
      {
        volumeSystemIds,
      }
    );
    return results.data;
  };

  public getProductGroupAllocations = async (
    coId: string,
    limit: number,
    page: number,
    orderColumn: number,
    isAsc: boolean,
    productGroupAllocIds: string[] = [],
    valueAllocations: ValueAllocation[] = []
  ): Promise<ProductGroupAllocation[]> => {
    const results = await this.privateRequest.post<ProductGroupAllocation[]>(
      '/Wizard/GetProductGroupAllocations',
      {
        skip: page * limit,
        limit: limit,
        orderColumn: orderColumn,
        isAsc: isAsc,
        coId,
        productGroupAllocIds,
        valueAllocations,
      }
    );
    return results.data;
  };

  public getCO = async (): Promise<CO[]> => {
    const result = await this.privateRequest.get<CO[]>(
      '/Filter/CommercialOrganizationsWithCurrency'
    );
    return result.data;
  };

  public getProductGroupAllocationWithFullInfo = async (
    coId: string,
    productGroupAllocId: string
  ) => {
    const results = await this.privateRequest.post<
      ProductGroupAllocationInfo[]
    >('/Wizard/GetProductGroupAllocationsWithFullInfo', {
      skip: 0,
      limit: 100,
      orderColumn: 0,
      isAsc: true,
      coId,
      productGroupAllocIds: [productGroupAllocId],
      valueAllocations: [],
    });
    return results.data;
  };

  public getPackagingTypes = async (
    COId: string,
    AllocationGroupId: string
  ) => {
    const results = await this.privateRequest.get<PackagingGroup[]>(
      '/Wizard/GetPackagingTypes',
      { params: { AllocationGroupId, COId } }
    );
    return results.data;
  };

  public updateProductGroupAllocations = async (
    coId: string,
    items: UpdateProductGroupAllocationInfo[]
  ) => {
    const results = await this.privateRequest.put(
      '/Wizard/UpdateProductGroupAllocations',
      {
        coId,
        items,
      }
    );
    return results;
  };

  public getMarginSystem = async (): Promise<WizardMarginSystem[]> => {
    const res = await this.privateRequest.post<WizardMarginSystem[]>(
      '/Wizard/GetLookupMarginSystems',
      {
        ids: [],
      }
    );
    return res.data;
  };

  public getPGAVolumeDriver = async (
    coId: string,
    productGroupAllocIds: string[],
    valueAllocations: number[]
  ): Promise<WizardPGAVolumeDriver[]> => {
    const res = await this.privateRequest.post<WizardPGAVolumeDriver[]>(
      '/Wizard/GetProductGroupAllocationVolumeDriverWithFullInfo',
      {
        skip: 0,
        limit: 100,
        orderColumn: 0,
        isAsc: true,
        coId,
        productGroupAllocIds,
        valueAllocations,
      }
    );
    return res.data;
  };

  public updatePGAVolumeDriver = async (
    coId: string,
    items: UpdateWizardVolumeDriver[]
  ) => {
    const results = await this.privateRequest.put(
      '/Wizard/UpdateWizardVolumeDrivers',
      {
        coId,
        items,
      }
    );
    return results;
  };

  public getBaseMarginMatrix = async (
    coId: string,
    productGroupAllocIds: string[],
    valueAllocations: number[]
  ): Promise<WizardBaseMarginMatrixOrigin[]> => {
    const res = await this.privateRequest.post<WizardBaseMarginMatrixOrigin[]>(
      '/Wizard/GetBaseMargins',
      {
        skip: 0,
        limit: 100,
        orderColumn: 0,
        isAsc: true,
        coId,
        productGroupAllocIds,
        valueAllocations,
      }
    );
    return res.data;
  };

  public updateBaseMarginMatrix = async (
    coId: string,
    productGroupAllocId: string,
    items: WizardBaseMarginMatrixSending[]
  ) => {
    const results = await this.privateRequest.post<any>(
      '/Wizard/UpdateBaseMargins',
      {
        coId,
        productGroupAllocId,
        items,
      }
    );
    return results;
  };

  public getIndustry = async (level: number) => {
    const result = await this.privateRequest.get<ProductGroupAllocationLevel[]>(
      '/Industry/GetByLevel',
      { params: { level } }
    );
    return result.data;
  };

  //===== STEP 3  =====

  public getPackagingMargin = async (
    coId: string,
    productGroupAllocIds: string[],
    valueAllocations: number[]
  ): Promise<WizardPackagingMarginOrigin[]> => {
    const res = await this.privateRequest.post<WizardPackagingMarginOrigin[]>(
      '/Wizard/GetPackagingDriver',
      {
        skip: 0,
        limit: 100,
        orderColumn: 0,
        isAsc: true,
        coId,
        productGroupAllocIds,
        valueAllocations,
      }
    );
    return res.data;
  };

  public updatePackagingMargin = async (
    coId: string,
    productGroupAllocId: string,
    data: WizardPackagingMarginSending
  ) => {
    const results = await this.privateRequest.put<any>(
      '/Wizard/UpdatePackagingDriver',
      {
        coId,
        productGroupAllocId,
        ...data,
      }
    );
    return results;
  };
}

export default new WizardServices();
