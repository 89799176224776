import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import styles from './AppSelect.module.scss';
import classnames from 'classnames';

interface Props {
  value: string | number;
  hidden?: boolean;
  disabled?: boolean;
  children: any;
}

export const AppSelectOption = React.forwardRef((props: Props, ref: any) => {
  const { value, children, disabled, hidden, ...restProps } = props;
  return (
    <MenuItem
      {...restProps}
      value={value}
      ref={ref}
      disabled={disabled}
      hidden={hidden}
      className={classnames(styles.option, { [styles.hidden]: hidden })}
    >
      {children}
    </MenuItem>
  );
});
