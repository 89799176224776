import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal';
import { GroupHierarchiesFilter } from '@app/shared/components/GroupHierarchiesFilter';
import {
  selectCoFilters,
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  selectSelectedCo,
  setSelectedCo,
  setSelectedCoAllocationLevels,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import {
  selectAllocationLevels,
  selectSelectedAllocationLevels,
} from '@app/store/filters-module/filters-state';
import { fetchDriverAllocationsDoneAction } from '@app/store/volume-driver/volume-driver-allocations';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withConfirmation } from '../../../../../shared/hocs/withConfirmation/withConfirmation';
import {
  VolumeDriverFilters,
  VolumeDriverFiltersPayload,
} from '../VolumeDriverFilters';

const InterceptedGroupHierarchyFilters = withConfirmation([
  'onFiltersChange',
  'onAllocationLevelChange',
])(GroupHierarchiesFilter, ConfirmationModal);

export interface VolumeDriverAllocationFiltersPayload {
  co: TCommercialOrganization;
}

interface Props {
  onChange: () => void;
  onSave: () => void;
  onSkip: () => void;
  disabled?: boolean;
  filters: TFilterItemResponse[];
  allocationLevels: TFilterAllocationLevels;
  shouldAskForConfirmation?: boolean;
}

export const VolumeDriverAllocationFilters = (props: Props) => {
  const dispatch = useDispatch();

  const {
    onChange,
    onSave: handleConfirm,
    disabled = false,
    filters,
    allocationLevels,
    shouldAskForConfirmation,
    onSkip,
  } = props;

  const levels = useSelector(selectSelectedAllocationLevels);
  const selectedCo = useSelector(selectSelectedCo);
  const selectedAllocationLevels = useSelector(
    selectCoSelectedAllocationLevels
  );
  const volumeDriverValue = React.useMemo(() => {
    return { co: selectedCo || ({} as TCommercialOrganization) };
  }, [selectedCo]);
  const filtersValue = useSelector(selectCoSelectedFilters);
  const allAllocations = useSelector(selectAllocationLevels);
  const coFilters = useSelector(selectCoFilters);

  const handleFiltersChange = React.useCallback(
    (filters: TFilterItemResponse[]) => {
      dispatch(
        setSelectedCoFilters({
          filters,
        })
      );

      if (!filters.length) {
        dispatch(setSelectedCoAllocationLevels({ levels: [] }));
        dispatch(
          fetchDriverAllocationsDoneAction({ items: [], totalItems: 0 })
        );
        dispatch(
          setSelectedCoFilters({
            filters,
          })
        );
        return;
      }
      if (filters.length && !levels.length) {
        dispatch(setSelectedCoAllocationLevels({ levels: allAllocations }));
      }
      onChange();
    },
    [onChange, dispatch, allAllocations, levels.length]
  );

  const handleAllocationLevelChange = React.useCallback(
    (levels: TFilterAllocationLevels) => {
      dispatch(setSelectedCoAllocationLevels({ levels: levels }));
      if (!filtersValue.length && levels.length) {
        dispatch(setSelectedCoFilters({ filters: coFilters }));
      }
      if (!levels.length) {
        dispatch(setSelectedCoFilters({ filters: [] }));
        dispatch(
          fetchDriverAllocationsDoneAction({ items: [], totalItems: 0 })
        );
        return;
      }
      onChange();
    },
    [onChange, dispatch, coFilters, filtersValue.length]
  );

  const handlePackagingFilterChange = React.useCallback(
    (payload: VolumeDriverFiltersPayload) => {
      dispatch(setSelectedCo({ co: payload.co }));
      onChange();
    },
    [onChange, dispatch]
  );

  return (
    <Grid container spacing={2} wrap="nowrap">
      <Grid item xs={6}>
        <InterceptedGroupHierarchyFilters
          filters={filters}
          allocationLevels={allocationLevels}
          onFiltersChange={handleFiltersChange}
          disabled={disabled}
          filtersValue={filtersValue}
          allocationLevelsValue={selectedAllocationLevels}
          onAllocationLevelChange={handleAllocationLevelChange}
          shouldAskForConfirm={shouldAskForConfirmation}
          onConfirm={handleConfirm}
          onSkip={onSkip}
          modalProps={undefined}
        />
      </Grid>
      <Grid item xs={6}>
        <VolumeDriverFilters
          onConfirm={handleConfirm}
          onChange={handlePackagingFilterChange}
          value={volumeDriverValue}
          shouldAskForConfirm={shouldAskForConfirmation}
          modalProps={undefined}
          onSkip={onSkip}
        />
      </Grid>
    </Grid>
  );
};
