import {
  TFilterAllocationLevels,
  TFilterItemResponse,
} from '@app/services/filter-service/response-interfaces/get-filters.response';
import { getCurrentPageLimit } from '@app/shared/components/AppTablePagination/paginationUtils';
import { ConfirmationModal } from '@app/shared/components/ConfirmationModal';
import { GroupHierarchiesFilter } from '@app/shared/components/GroupHierarchiesFilter';
import { withConfirmation } from '@app/shared/hocs/withConfirmation/withConfirmation';
import { setMarginListDataAction } from '@app/store/driver/driver-margins/actions';
import {
  selectCoAllocaitonLevels,
  selectCoFilters,
  selectCoSelectedAllocationLevels,
  selectCoSelectedFilters,
  setSelectedCoAllocationLevels,
  setSelectedCoFilters,
} from '@app/store/filters-module/co';
import {
  selectAllocationLevels,
  selectSelectedAllocationLevels,
} from '@app/store/filters-module/filters-state/selectors';
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { CoFilter } from '../../../../../../shared/components/CoFilter';
import {
  saveExceptionNegotiationAction,
  selectNegotiationExceptionsUpdate,
  setUpdateDataExceptionsNegotiationAction,
} from '@app/store/negotiation-room/exceptions';
import { setFiltersIsFullListAction } from '@app/store/filters-module/filters-state';

const InterceptedGroupHierarchyFilters = withConfirmation([
  'onFiltersChange',
  'onAllocationLevelChange',
])(GroupHierarchiesFilter, ConfirmationModal);

export const FiltersExceptions: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const levels = useSelector(selectSelectedAllocationLevels);
  const allocationLevels = useSelector(selectCoAllocaitonLevels);

  const allAllocations = useSelector(selectAllocationLevels);
  const selectedAllocationLevels = useSelector(
    selectCoSelectedAllocationLevels
  );
  const coFilters = useSelector(selectCoFilters);

  const filtersValue = useSelector(selectCoSelectedFilters);

  const updateItems = useSelector(selectNegotiationExceptionsUpdate);
  const shouldAskForConfirm = React.useMemo(() => {
    return !!Object.keys(updateItems).length;
  }, [updateItems]);

  const onChange = React.useCallback(() => {
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
  }, [history]);

  useEffect(() => {
    dispatch(setFiltersIsFullListAction({ isFullList: false }));
  }, [dispatch]);

  const handleFiltersChange = React.useCallback(
    (filters: TFilterItemResponse[]) => {
      if (!filters.length) {
        dispatch(setSelectedCoAllocationLevels({ levels: [] }));
        dispatch(setMarginListDataAction({ items: [], total: 0 }));
        dispatch(
          setSelectedCoFilters({
            filters,
          })
        );
        return;
      }
      if (filters.length && !levels.length) {
        dispatch(setSelectedCoAllocationLevels({ levels: allAllocations }));
      }
      dispatch(
        setSelectedCoFilters({
          filters,
        })
      );
      onChange();
    },
    [onChange, dispatch, allAllocations, levels.length]
  );

  const handleAllocationLevelChange = React.useCallback(
    (levels: TFilterAllocationLevels) => {
      dispatch(setSelectedCoAllocationLevels({ levels }));
      if (!filtersValue.length && levels.length) {
        dispatch(setSelectedCoFilters({ filters: coFilters }));
      }
      if (!levels.length) {
        dispatch(setSelectedCoFilters({ filters: [] }));
        dispatch(setMarginListDataAction({ items: [], total: 0 }));
        return;
      }
      onChange();
    },
    [onChange, dispatch, coFilters, filtersValue.length]
  );
  return (
    <React.Fragment>
      <Grid item xs={3}>
        <InterceptedGroupHierarchyFilters
          filters={coFilters}
          allocationLevels={allocationLevels}
          onFiltersChange={handleFiltersChange}
          // disabled={disabled}
          allocationLevelsValue={selectedAllocationLevels}
          onAllocationLevelChange={handleAllocationLevelChange}
          filtersValue={filtersValue}
          modalProps={undefined}
          shouldAskForConfirm={shouldAskForConfirm}
          onSkip={() =>
            dispatch(
              setUpdateDataExceptionsNegotiationAction({ updateData: {} })
            )
          }
          onConfirm={() => dispatch(saveExceptionNegotiationAction({}))}
        />
      </Grid>
      <Grid item xs={3}>
        <CoFilter
          shouldAskForConfirm={false}
          onSkip={() =>
            dispatch(
              setUpdateDataExceptionsNegotiationAction({ updateData: {} })
            )
          }
          onConfirm={() => dispatch(saveExceptionNegotiationAction({}))}
        />
      </Grid>
    </React.Fragment>
  );
};
