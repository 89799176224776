import { OptionItem } from '@app/modules/Simulator/components/TargetPriceResults/utils';
import {
  LookupValueSystem,
  ProductGroupAllocationLevel,
  ValueSystem,
  ProductGroupAllocation,
  ProductGroupAllocationInfo,
  PackagingGroup,
  ProductAllocationValue,
  CommercialOrganizations,
  WizardMarginSystem,
  WizardPGAVolumeDriver,
  UpdateWizardVolumeDriver,
  WizardBaseMarginMatrixOrigin,
  WizardBaseMarginMatrixState,
  WizardBMMIndustry,
  WizardPackagingMarginState,
  WizardPackagingMarginOrigin,
} from '@shared/types/wizard';

export interface WizardState {
  loading: boolean;
  coId: string;
  commercialOrganizations: CommercialOrganizations[];
  volumeSystem: ValueSystem[];
  lookupVolumeSystem: LookupValueSystem[];
  productGroupAllocationLevels: ProductGroupAllocationLevel[];
  currentAllocationLevel: ProductGroupAllocationLevel | null;
  productGroupAllocations: {
    total: number;
    data: ProductGroupAllocation[];
  };
  productGroupAllocationInfo: ProductGroupAllocationInfo[];
  updateProductGroupAllocationInfo: Record<string, ProductGroupAllocationInfo>;
  packagingGroup: PackagingGroup[];
  currentsValueAllocation: ProductAllocationValue[];
  marginSystem: WizardMarginSystem[];
  pgaVolumeDriver: WizardPGAVolumeDriver[];
  pgaVolumeDriverUpdate: Record<string, UpdateWizardVolumeDriver>;
  baseMarginMatrix: WizardBaseMarginMatrixState[];
  baseMarginMatrixOrigin: WizardBaseMarginMatrixOrigin[];
  bmmIndustries: WizardBMMIndustry[];
  packagingMargin: WizardPackagingMarginState;
  packagingMarginOrigin: WizardPackagingMarginOrigin[];
  targetPriceResult: {
    coId: CommercialOrganizations[];
    productGroup: ProductGroupAllocationLevel[];
    industries: WizardBMMIndustry[];
    productValues: ProductGroupAllocationInfo[];
    baseMargin: WizardBaseMarginMatrixOrigin[];
    packagingDriver: WizardPackagingMarginOrigin[];
    volumeDriver: WizardPGAVolumeDriver[];
    marginVolumeDriver: LookupValueSystem[];
    selectedCo: OptionItem | null;
    selectedProduct: OptionItem | null;
    selectedValueAllocation: OptionItem | null;
  };
}

export const initialState: WizardState = {
  loading: false,
  coId: '',
  commercialOrganizations: [],
  volumeSystem: [],
  lookupVolumeSystem: [],
  productGroupAllocationLevels: [],
  currentAllocationLevel: null,
  productGroupAllocations: { total: 0, data: [] },
  productGroupAllocationInfo: [],
  updateProductGroupAllocationInfo: {},
  packagingGroup: [],
  currentsValueAllocation: [],
  marginSystem: [],
  pgaVolumeDriver: [],
  pgaVolumeDriverUpdate: {},
  baseMarginMatrix: [],
  baseMarginMatrixOrigin: [],
  bmmIndustries: [],
  packagingMargin: { valueAllocation: [], packaging: [] },
  packagingMarginOrigin: [],
  targetPriceResult: {
    coId: [],
    productGroup: [],
    industries: [],
    productValues: [],
    baseMargin: [],
    packagingDriver: [],
    volumeDriver: [],
    marginVolumeDriver: [],
    selectedCo: null,
    selectedProduct: null,
    selectedValueAllocation: null,
  },
};
