import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import {
  bmmIndustryLevel,
  bmmIndustryLevelByKey,
} from '@app/modules/Simulator/data-definition';

interface IndustryLevelSelectProps {
  handlerChange: (value: number | null) => void;
  industryLevel: number | null;
}

export const IndustryLevelSelect = ({
  handlerChange,
  industryLevel,
}: IndustryLevelSelectProps) => {
  return (
    <Autocomplete
      value={
        industryLevel === null
          ? null
          : {
              key: industryLevel,
              value: bmmIndustryLevelByKey[industryLevel],
            }
      }
      options={bmmIndustryLevel}
      getOptionSelected={(i, value) => i.key === value.key}
      getOptionLabel={(i) => i.value}
      onChange={(e, value) => handlerChange(value ? value.key : null)}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
