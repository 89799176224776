import {
  sortGroup1,
  sortGroup2,
  sortGroup3,
  sortGroup4,
  sortProductValueAllocation,
  sortExceptionName,
  sortDescription,
  sortProductName,
  sortProductNumber,
  sortIndustry1,
  sortIndustry2,
  sortIndustry3,
  sortIndustry4,
  sortTargetMarginException,
  sortValidTo,
  sortValidFrom,
  sortReminder,
  sortStatus,
  sortMainGroup,
  sortEnteredByUser,
  sortCreationDate,
} from './sorting';

export const headCells = [
  {
    id: 'exceptionName',
    disablePadding: false,
    label: 'Exception name',
    sort: sortExceptionName,
  },
  {
    id: 'description',
    disablePadding: false,
    label: 'Description',
    sort: sortDescription,
  },
  {
    id: 'productGroup1',
    disablePadding: false,
    label: 'Product Group 1',
    sort: sortGroup1,
  },
  {
    id: 'productGroup2',
    disablePadding: false,
    label: 'Product Group 2',
    sort: sortGroup2,
  },
  {
    id: 'productGroup3',
    disablePadding: false,
    label: 'Product Group 3',
    sort: sortGroup3,
  },
  {
    id: 'productGroup4',
    disablePadding: false,
    label: 'Product Group 4',
    sort: sortGroup4,
  },
  {
    id: 'mainGroup',
    disablePadding: false,
    label: 'Main Product',
    sort: sortMainGroup,
  },
  {
    id: 'productValue',
    disablePadding: false,
    label: 'Product Value',
    sort: sortProductValueAllocation,
  },
  {
    id: 'productName',
    disablePadding: false,
    label: 'Product Name',
    sort: sortProductName,
  },
  {
    id: 'productNumber',
    disablePadding: false,
    label: 'Product number',
    sort: sortProductNumber,
  },
  {
    id: 'industryLevel1',
    disablePadding: false,
    label: 'Industry level 1',
    sort: sortIndustry1,
  },
  {
    id: 'industryLevel2',
    disablePadding: false,
    label: 'Industry level 2',
    sort: sortIndustry2,
  },
  {
    id: 'industryLevel3',
    disablePadding: false,
    label: 'Industry level 3',
    sort: sortIndustry3,
  },
  {
    id: 'industryLevel4',
    disablePadding: false,
    label: 'Industry level 4',
    sort: sortIndustry4,
  },
  {
    id: 'targetMarginException',
    disablePadding: false,
    label: 'Target margin exception',
    sort: sortTargetMarginException,
  },
  {
    id: 'validFrom',
    disablePadding: false,
    label: 'Valid from',
    sort: sortValidFrom,
  },
  {
    id: 'validTo',
    disablePadding: false,
    label: 'Valid to  ',
    sort: sortValidTo,
  },
  {
    id: 'reminder',
    disablePadding: false,
    label: 'Reminder',
    sort: sortReminder,
  },
  { id: 'status', disablePadding: false, label: 'Status', sort: sortStatus },
  { id: 'enteredByUser', disablePadding: false, label: 'Exception Creator', sort: sortEnteredByUser },
  {
    id: 'creationDate',
    disablePadding: false,
    label: 'Creation Date',
    sort: sortCreationDate,
  },
];
