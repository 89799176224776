import { createContext } from 'react';

export type SortHandler<T = any> = (
  handler: (data: T[], order: 'asc' | 'desc') => T[],
  order: 'asc' | 'desc'
) => void;

export interface AppTableContext<TData = any> {
  applySortHandler: SortHandler;
  data: TData[];
  loading: boolean;
  deletableItems: boolean;
  enqueueForDeletion: (item: TData) => void;
  ejectDeletedItem: (item: TData) => void;
  deleteItems: () => void;
  deletionQueue: TData[];
  setActiveCell: (id: string) => void;
  activeCellId: string | undefined;
  scrollRef: React.Ref<HTMLTableElement>;
  isEmpty?: boolean;
}

export const appTableContext = createContext({} as AppTableContext);
