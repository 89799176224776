import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Grow from '@material-ui/core/Grow';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  tooltip: {
    background: '#ff5000',
  },
  arrow: {
    '&::before': {
      backgroundColor: '#ff5000',
      border: '2px solid #ff5000',
    },
  },
}));

interface Props {
  opened?: boolean;
  errorText: string;
}

export const ErrorTooltip = (props: Props) => {
  const classes = useStyles();

  const { errorText = '', opened } = props;

  return (
    <Grow in={opened} appear={true} timeout={500} unmountOnExit>
      <Tooltip
        title={errorText}
        color="primary"
        arrow
        placement="top"
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      >
        <HelpIcon fontSize="small" color="primary" />
      </Tooltip>
    </Grow>
  );
};
