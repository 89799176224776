import { useLocation } from 'react-router';
import { pickQueryParams } from '../utils/pickQueryParams';

export function useLocationQuery<TQuery>(keys?: string[]): TQuery {
  const location = useLocation();

  const query = location.search;

  const result = pickQueryParams<TQuery>(query, keys);

  return result;
}
