import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { getWizardCurrency } from '@app/store/wizard';
import { useSelector } from 'react-redux';

export interface MarginTypeSelectProps {
  marginType: number;
  onChange: (marginType: number) => void;
}

export const MarginTypeSelect = (props: MarginTypeSelectProps) => {
  const { marginType, onChange } = props;
  const unitOptions = useSelector(getWizardCurrency);

  return (
    <Select
      fullWidth
      value={marginType}
      onChange={(e) => onChange(Number(e.target.value))}
    >
      {unitOptions.map((unit) => (
        <MenuItem key={unit.key} value={unit.key}>
          {unit.value}
        </MenuItem>
      ))}
    </Select>
  );
};
