import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';
import * as React from 'react';
import { AppTableHeadCell } from '../AppTableHeadCell';
import DeleteIcon from '@material-ui/icons/Delete';
import { appTableContext } from '../../appTableContext';
import { useSnackbar } from 'notistack';
import { DeleteButton } from './components';
import { RoleProtectedCheckbox } from '@app/shared/components/RoleBasedRoute/components/RoleProtecedCheckbox';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedDeleteButton/RoleProtectedDeleteButton';
import { roleBasedRouteContext } from '@app/shared/components/RoleBasedRoute/roleBasedRouteContext';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';

const useStyles = makeStyles(() => {
  return {
    deleteCell: {
      width: '70px',
    },
  };
});

interface Props<T> extends TableRowProps {
  isHead?: boolean;
  disableDeletionBehavior?: boolean;
  selectDeleted?: (queue: T[]) => T | undefined;
  item?: T;
  isCanBeDeleted?: boolean;
}

function defaultItemPick<T>(queue: T[], match: T): T | undefined {
  return queue.find((item) => item === match);
}

export function AppTableRow<T>(props: Props<T>) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    isHead,
    disableDeletionBehavior,
    children,
    item,
    selectDeleted,
    isCanBeDeleted = true,
    ...rowProps
  } = props;
  const styles = useStyles();

  const ctx = React.useContext(roleBasedRouteContext);
  const roleHooks = useAccountRoleRights(ctx.feature);
  const canEdit = roleHooks[1];
  const isDisabled = canEdit() ? false : true;

  const context = React.useContext(appTableContext);

  const isDeleted = React.useMemo(() => {
    if (!selectDeleted) return !!defaultItemPick(context.deletionQueue, item);

    return !!selectDeleted(context.deletionQueue);
  }, [context.deletionQueue, selectDeleted, item]);

  const handleCheckbox = React.useCallback(
    (e: any) => {
      const checked = e.target.checked;

      if (checked) {
        context.enqueueForDeletion(item);
      } else {
        context.ejectDeletedItem(
          selectDeleted
            ? selectDeleted(context.deletionQueue)
            : defaultItemPick(context.deletionQueue, item)
        );
      }
    },
    [item, context, selectDeleted]
  );

  const handleDeletionWhenNooneSelected = React.useCallback(() => {
    enqueueSnackbar('At least one item must be selected', {
      variant: 'warning',
    });
  }, [enqueueSnackbar]);

  const deletionEnabled = disableDeletionBehavior;

  return (
    <TableRow {...rowProps}>
      {!context.deletableItems ? null : isHead ? (
        deletionEnabled ? (
          <AppTableHeadCell
            classes={{ root: styles.deleteCell }}
            className="table-delete-cell"
            style={{
              left: '0px',
              width: '68px',
              zIndex: 18,
            }}
          >
            {context.deletionQueue.length ? (
              <RoleProtectedButton
                onClick={context.deleteItems}
                modalProps={{
                  title: 'Delete items',
                  text:
                    'Please note: Selected items will be deleted.Changes will be applied on confirm.',
                }}
              />
            ) : (
              <IconButton onClick={handleDeletionWhenNooneSelected} disabled={isDisabled}>
                <DeleteIcon color={isDisabled ? 'disabled' : 'primary'} fontSize="small" />
              </IconButton>
            )}
          </AppTableHeadCell>
        ) : null
      ) : (
        <TableCell
          className="table-delete-cell"
          classes={{ root: styles.deleteCell }}
          style={{
            left: '0px',
            background: '#fff',
            width: '68px',
            zIndex: 16,
            position: 'sticky',
          }}
        >
          <RoleProtectedCheckbox
            color="primary"
            checked={isDeleted}
            disabled={!isCanBeDeleted}
            onChange={handleCheckbox}
          />
        </TableCell>
      )}

      {children}
    </TableRow>
  );
}
