import { AxiosRequestService } from '@app/shared/utils/axios-request-service/axios-request.service';
import { allocationLevels } from './allocationLevels';
import {
  TCommercialOrganization,
  TCoResponse,
} from './response-interfaces/get-co.response';
import { TFiltersByCoResponse } from './response-interfaces/get-filters-by-co.response';
import {
  TFilterAllocationLevels,
  TFilterItemResponse,
  TFiltersResponse,
} from './response-interfaces/get-filters.response';
import { ResponseIndustries } from './response-interfaces/get-industries';
import { GetPackagingGroupsResponse } from './response-interfaces/get-packaging-groups.response';

class FilterService extends AxiosRequestService {
  getFilters = async (): Promise<{
    filters: TFilterItemResponse[];
  }> => {
    const request = await this.privateRequest.get<TFiltersResponse>(
      '/Filter/GetProductGroupFilter'
    );

    return {
      filters: request.data.groupLevels,
    };
  };

  getAllocationLevels = async (): Promise<TFilterAllocationLevels> => {
    return [...allocationLevels];
  };

  getCo = async (): Promise<TCommercialOrganization[]> => {
    const request = await this.privateRequest.get<TCoResponse>(
      '/Filter/CommercialOrganizations'
    );

    return request.data.data;
  };

  getGroupFilters = async (
    coId: string,
    isFullList: boolean
  ): Promise<TFiltersByCoResponse> => {
    const request = await this.privateRequest.get('/Filter/Groups', {
      params: { commercialOrganizationId: coId, isFullList },
    });

    return request.data;
  };

  getPackagingGroups = async (): Promise<GetPackagingGroupsResponse> => {
    const request = await this.privateRequest.get('/Filter/PackagingGroups');
    return request.data;
  };

  getIndustries = async (): Promise<ResponseIndustries> => {
    const request = await this.privateRequest.get<ResponseIndustries>(
      '/Filter/Industries'
    );
    return request.data;
  };
}

export const FilterApi = new FilterService();
