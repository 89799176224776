import { REQUIRED_FIELD_ERROR_MESSAGE } from '@app/shared/validation';
import * as yup from 'yup';

const volumeDriverItemSchema = yup.object({
  id: yup.string().required(),
  volumeSystem: yup
    .mixed()
    .test('volumeSystemTest', REQUIRED_FIELD_ERROR_MESSAGE, function (
      this: any,
      value: any
    ) {
      if (value !== null && this.parent.marginSystem === null) {
        throw this.createError({
          path: `${this.parent.id}.marginSystem`,
          message: REQUIRED_FIELD_ERROR_MESSAGE,
        });
      }

      return true;
    }),
  marginSystem: yup
    .mixed()
    .test('marginSystemTest', REQUIRED_FIELD_ERROR_MESSAGE, function (
      this: any,
      value: any
    ) {
      if (value !== null && this.parent.volumeSystem === null) {
        throw this.createError({
          path: `${this.parent.id}.volumeSystem`,
          message: REQUIRED_FIELD_ERROR_MESSAGE,
        });
      }

      return true;
    }),
});

export const volumeDriverTableSchema = yup.array().of(volumeDriverItemSchema);
