import React, {
  Fragment,
  useCallback,
  useReducer,
  createContext,
  useEffect,
} from 'react';
import { Grid } from '@material-ui/core';
import { TargetPriceResults } from '../TargetPriceResults';
import Box from '@material-ui/core/Box';
import { SimulatorStepper } from './components/SimulatorStepper/SimulatorStepper';
import StepConfirmDialog from '@modules/Simulator/components/SimulatorWizard/components/StepConfirmDialog/StepConfirmDialog';
import {
  initialWizardState,
  wizardReducer,
  ActionType,
  WizardState,
  WizardSelector,
  changeActiveStepByIndexAction,
  toNextStepAction,
  toPrevStepAction,
  useWizardSelector,
  changeModalQueryAction,
  ModalStatus,
  changeActiveStepStatusAction,
  resetStepsAction,
  resetAllStepsAction,
} from './store';
import useWizardStepSaveSwitch from '@modules/Simulator/components/SimulatorWizard/hooks/useWizardStepSaveSwitch';
import useWizardStepSkipSwitch from '@modules/Simulator/components/SimulatorWizard/hooks/useWizardStepSkipSwitch';
import { useSelector } from 'react-redux';
import { getWizardCurrentProductIdSelector } from '@app/store/wizard';
import { useDispatch } from 'react-redux';
import { setWizardCOIdAction } from '@app/store/wizard';
import styles from '@modules/Simulator/components/StepLayout/StepLayout.module.scss';
import { useAccountRoleRights } from '@app/shared/hooks/useAccountRoleRights';

export const WizardStateContext = createContext<WizardState>({} as WizardState);
export const WizardDispatchContext = createContext<
  (action: ActionType) => void
>((action) => null);

interface IProps {
  canEdit: boolean
}

export const SimulatorWizard = ({canEdit}: IProps) => {

  const dispatch = useDispatch();

  //===== RESET STORE ====
  
  useEffect(() => {
    return () => {
      dispatch(setWizardCOIdAction({ payload: '' }));
    };
  }, [dispatch]);

  //===== CREAT LOCAL STORE  =====
  const [wizardState, wizardDispatch] = useReducer(
    wizardReducer,
    initialWizardState
  );
  const wizardSelector: WizardSelector = useWizardSelector(wizardState);

  const ActiveStep = wizardSelector.getActiveStep();
  const saveSwitch = useWizardStepSaveSwitch();
  const skipSwitch = useWizardStepSkipSwitch();

  //===== CHANGE STEP CALLBACKS =====
  const handlerNextCallback = useCallback(() => {
    if (wizardState.steps[wizardState.activeStepIndex].changed)
      wizardDispatch(
        changeModalQueryAction(
          wizardSelector.isLastActiveStep()
            ? wizardState.activeStepIndex
            : wizardState.activeStepIndex + 1
        )
      );
    else wizardDispatch(toNextStepAction());
  }, [wizardDispatch, wizardState, wizardSelector]);

  const handlerPrevCallback = useCallback(() => {
    if (wizardState.steps[wizardState.activeStepIndex].changed)
      wizardDispatch(
        changeModalQueryAction(
          wizardSelector.isFirstActiveStep()
            ? wizardState.activeStepIndex
            : wizardState.activeStepIndex - 1
        )
      );
    else wizardDispatch(toPrevStepAction());
  }, [wizardDispatch, wizardState, wizardSelector]);

  const handlerChangeStepCallback = useCallback(
    (index: number) => {
      if (wizardSelector.getActiveStep().changed) {
        wizardDispatch(changeModalQueryAction(index));
      } else {
        wizardDispatch(changeActiveStepByIndexAction(index));
      }
    },
    [wizardDispatch, wizardSelector]
  );

  //===== MODAL CALLBACKS =====
  const handlerCloseModalCallback = useCallback(() => {
    wizardDispatch(changeModalQueryAction(null));
  }, []);
  const handlerAcceptModalCallback = useCallback(
    (index: ModalStatus) => {
      saveSwitch(wizardSelector.getActiveIndex());
      wizardDispatch(
        changeActiveStepStatusAction({
          changed: false,
          currentValidate: wizardSelector.getActiveStep().originValidate,
        })
      );
      wizardDispatch(changeModalQueryAction(null));
      wizardDispatch(changeActiveStepByIndexAction(index as number));
    },
    [wizardDispatch, wizardSelector, saveSwitch]
  );
  const handlerSkipModalCallback = useCallback(
    (index: ModalStatus) => {
      skipSwitch(wizardSelector.getActiveIndex());
      wizardDispatch(
        changeActiveStepStatusAction({
          changed: false,
          currentValidate: wizardSelector.getActiveStep().originValidate,
        })
      );
      wizardDispatch(changeModalQueryAction(null));
      wizardDispatch(changeActiveStepByIndexAction(index as number));
    },
    [wizardDispatch, wizardSelector, skipSwitch]
  );

  if (!ActiveStep || !ActiveStep.component)
    throw new Error(
      `Step with index: ${wizardSelector.getActiveIndex()} not exists.`
    );

  //===== RESET STEPS =====
  const productId = useSelector(getWizardCurrentProductIdSelector);

  useEffect(() => {
    if (productId !== '') {
      wizardDispatch(resetStepsAction());
    } else {
      wizardDispatch(resetAllStepsAction());
    }
  }, [productId, wizardDispatch]);
  return (
    <Fragment>
      <StepConfirmDialog
            stepStatus={wizardSelector.getActiveStep()}
            status={wizardSelector.getModalQuery()}
            close={handlerCloseModalCallback}
            accept={handlerAcceptModalCallback}
            skip={handlerSkipModalCallback}
            steps={wizardSelector.getSteps()}
          />
      <Box>
        <Grid container>
          <Grid item xs={6}>
            <div className={styles.titleWrap}>
              <div className={styles.title}>Configuration</div>
            </div>

            <SimulatorStepper
              steps={wizardSelector.getSteps()}
              activeStep={wizardSelector.getActiveIndex()}
              setActiveStep={handlerChangeStepCallback}
            />
            <WizardStateContext.Provider value={wizardState}>
              <WizardDispatchContext.Provider value={wizardDispatch}>
                <ActiveStep.component
                  step={wizardSelector.getActiveStep()}
                  next={handlerNextCallback}
                  prev={handlerPrevCallback}
                  canEdit={canEdit}
                />
              </WizardDispatchContext.Provider>
            </WizardStateContext.Provider>
          </Grid>
          <Grid item xs={6}>
            <TargetPriceResults />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
