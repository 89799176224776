import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchWizardPGAVolumeDriverAction,
  getUpdateWizardPGAVolumeDriverSelector,
  getWizardMarginSystemByIdSelector,
  getWizardPGAVolumeDriverSelector,
  setUpdateWizardPGAVolumeDriverAction,
} from '@app/store/wizard';
import { PGAVolumeDriverTable } from '@modules/Simulator/components/SimulatorVolumeMarginStep/components/PGAVolumeDriver/PGAVolumeDriverTable';
import { UpdateWizardVolumeDriver } from '@app/shared/types/wizard';
import { WizardDispatchContext } from '@modules/Simulator/components/SimulatorWizard';
import { changeActiveStepStatusAction } from '@modules/Simulator/components/SimulatorWizard/store';
import isEmptyObject from '@shared/utils/isEmptyObject';

interface IProps {
  canEdit: boolean
}

export const PGAVolumeDriverContainer = ({canEdit}: IProps) => {
  const dispatch = useDispatch();

  //===== fetch data =====

  useEffect(() => {
    dispatch(fetchWizardPGAVolumeDriverAction({}));
  }, [dispatch]);

  //===== store data =====

  const data = useSelector(getWizardPGAVolumeDriverSelector);
  const marginSystem = useSelector(getWizardMarginSystemByIdSelector);
  const updateData = useSelector(getUpdateWizardPGAVolumeDriverSelector);

  const handlerChangeMarginSystem = useCallback(
    (uploadDate: Record<string, UpdateWizardVolumeDriver>) =>
      dispatch(setUpdateWizardPGAVolumeDriverAction({ payload: uploadDate })),
    [dispatch]
  );

  // steps dispatch
  const wizardDispatch = useContext(WizardDispatchContext);

  // set step origin validate
  useEffect(() => {
    wizardDispatch(
      changeActiveStepStatusAction({
        originValidate: data.reduce(
          (res: boolean, i) =>
            res &&
            i.marginSystemIds.length === 1 &&
            Boolean(i.marginSystemIds[0]),
          true
        ),
        changed: false,
      })
    );
    dispatch(setUpdateWizardPGAVolumeDriverAction({ payload: {} }));
  }, [data, wizardDispatch, dispatch]);

  // set step current validate
  useEffect(() => {
    let isCurrentValidate = data.reduce((res: boolean, i) => {
      const itemCheck = updateData[i.id + i.valueAllocation]
        ? updateData[i.id + i.valueAllocation].marginSystemId !== ''
        : i.marginSystemIds.length === 1 && Boolean(i.marginSystemIds[0]);
      return res && itemCheck;
    }, true);

    wizardDispatch(
      changeActiveStepStatusAction({
        currentValidate: isCurrentValidate,
        changed: !isEmptyObject(updateData),
      })
    );
  }, [data, updateData, wizardDispatch]);

  return (
    <PGAVolumeDriverTable
      data={data}
      marginSystem={marginSystem}
      handlerChangeMarginSystem={handlerChangeMarginSystem}
      canEdit={canEdit}
    />
  );
};
