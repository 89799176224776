import { defineActionGroup } from 'rd-redux-utils';

const volumeDriverConfigurationsActionGroup = defineActionGroup(
  'VOLUME DRIVER CONFIGURATIONS'
);

export const setConfigurationsOpenedAction = volumeDriverConfigurationsActionGroup.defineAction<{
  opened: boolean;
}>('SET MODAL OPENED');

export const setConfigurationsCreatingAction = volumeDriverConfigurationsActionGroup.defineAction<{
  creating: boolean;
}>('SET CREATING');

export const saveConfigurationsAction = volumeDriverConfigurationsActionGroup.defineAction(
  'SAVE CONFIGURATIONS'
);
