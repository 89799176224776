import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';
import { RightsApi } from '@app/services/rights-service/rights-service';
import { selectSelectedCo } from '@app/store/filters-module/co';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  createProductRightAction,
  fetchProductRightsAction,
  toggleModalAction,
} from '../actions';

export function* createProductRightsSaga(
  action: ReturnType<typeof createProductRightAction>
) {
  try {
    const selectedCO: TCommercialOrganization = yield select(selectSelectedCo);

    const dto = action.item;
    yield call(RightsApi.createProductRight, {
      ...dto,
      coId: selectedCO.id,
    });
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Product group rights successfully added.',
          options: {
            variant: 'success',
          },
          key: 'product-rights-add',
        },
      })
    );
    yield put(fetchProductRightsAction({}));
    yield put(toggleModalAction({ isOpen: false }));
  } catch (e) {
    yield put(toggleModalAction({ isOpen: true }));
    console.log('Failed to add product rights', e.message);
    if (
      e.response &&
      (e.response.status === 409 || e.response.status === 400)
    ) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'product-rights-create',
          },
        })
      );
    }
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'product-rights-create',
        },
      })
    );
  }
}
