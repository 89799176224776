import { all, takeEvery } from 'redux-saga/effects';
import {
  fetchBaseMargiMatrixIndustriesActions,
  fetchPackagingMarginOriginAction,
  fetchTPRProductGroupAction,
  fetchTPRProductValuesAction,
  fetchWizardBaseMarginMatrixAction,
  fetchWizardCOIdAction,
  fetchWizardLookupVolumeSystemAction,
  fetchWizardMarginSystemAction,
  fetchWizardPGAVolumeDriverAction,
  fetchWizardProductGroupAllocationAction,
  fetchWizardProductGroupAllocationLevelsAction,
  fetchWizardProductGroupInfoAction,
  fetchWizardVolumeSystemAction,
  sendPackagingMarginAction,
  sendWizardBaseMarginMatrixAction,
  sendWizardPGAVolumeDriverAction,
  sendWizardProductGroupInfoAction,
  fetchTPRIndustriesAction,
} from '@app/store/wizard/actions';
import { fetchCOIdWorkerSaga } from './fetchCOIdWorkerSaga';
import { fetchWizardVolumeSystemWorkerSaga } from './fetchWizardVolumeSystemWorkerSaga';
import { fetchWizardLookupVolumeSystemWorkerSaga } from './fetchWizardLookupVolumeSystemWorkerSaga';
import { fetchWizardProductGroupAllocationLevelsWorkerSaga } from './fetchWizardProductGroupAllocationLevelsWorkerSaga';
import { fetchWizardProductGroupAllocationWorkerSaga } from './fetchWizardProductGroupAllocationWorkerSaga';
import { fetchWizardProductGroupInfoWorkerSaga } from '@app/store/wizard/sagas/fetchWizardProductGroupInfoWorkerSaga';
import { sendWizardProductGroupInfoSaga } from './sendWizardProductGroupInfoSaga';
import { fetchWizardMarginSystemSaga } from './fetchWizardMarginSystemSaga';
import { fetchWizardPGAVolumeDriverSaga } from '@app/store/wizard/sagas/fetchWizardPGAVolumeDriverSaga';
import { sendWizardPGAVolumeDriverSaga } from '@app/store/wizard/sagas/sendWizardPGAVolumeDriverSaga';
import { fetchWizardBaseMarginMatrixSaga } from '@app/store/wizard/sagas/fetchWizardBaseMarginMatrixSaga';
import { fetchWizardBMMIndustriesSaga } from './fetchWizardBMMIndustriesSaga';
import { sendWizardBaseMarginMatrixSaga } from './sendWizardBaseMarginMatrixSaga';
import { fetchWizardPackagingMarginSaga } from '@app/store/wizard/sagas/fetchWizardPackagingMarginSaga';
import { sentPackagingMarginSaga } from './sentPackagingMarginSaga';
import { fetchTPRProductGroupSaga } from './fetchTPRProductGroupSaga';
import { fetchTPRIndustriesSaga } from './fetchTPRIndustriesSaga';
import { fetchTPRProductValuesSaga } from './fetchTPRProductValuesSaga';
import { fetchTPRTableDataAction } from '@app/store/wizard/actions';
import { fetchTPRTableDataSaga } from '@app/store/wizard/sagas/fetchTPRTableDataSaga';
import { fetchTPRCOAction } from '@app/store/wizard/actions';
import { fetchTPRCOSaga } from './fetchTPRCOSaga';

export function* wizardRootSaga() {
  yield all([
    takeEvery(fetchWizardCOIdAction.TYPE, fetchCOIdWorkerSaga),
    takeEvery(
      fetchWizardVolumeSystemAction.TYPE,
      fetchWizardVolumeSystemWorkerSaga
    ),
    takeEvery(
      fetchWizardLookupVolumeSystemAction.TYPE,
      fetchWizardLookupVolumeSystemWorkerSaga
    ),
    takeEvery(
      fetchWizardProductGroupAllocationLevelsAction.TYPE,
      fetchWizardProductGroupAllocationLevelsWorkerSaga
    ),
    takeEvery(
      fetchWizardProductGroupAllocationAction.TYPE,
      fetchWizardProductGroupAllocationWorkerSaga
    ),
    takeEvery(
      fetchWizardProductGroupInfoAction.TYPE,
      fetchWizardProductGroupInfoWorkerSaga
    ),
    takeEvery(
      sendWizardProductGroupInfoAction.TYPE,
      sendWizardProductGroupInfoSaga
    ),
    takeEvery(fetchWizardMarginSystemAction.TYPE, fetchWizardMarginSystemSaga),
    takeEvery(
      fetchWizardPGAVolumeDriverAction.TYPE,
      fetchWizardPGAVolumeDriverSaga
    ),
    takeEvery(
      sendWizardPGAVolumeDriverAction.TYPE,
      sendWizardPGAVolumeDriverSaga
    ),
    takeEvery(
      fetchWizardBaseMarginMatrixAction.TYPE,
      fetchWizardBaseMarginMatrixSaga
    ),
    takeEvery(
      fetchBaseMargiMatrixIndustriesActions.TYPE,
      fetchWizardBMMIndustriesSaga
    ),
    takeEvery(
      sendWizardBaseMarginMatrixAction.TYPE,
      sendWizardBaseMarginMatrixSaga
    ),
    takeEvery(
      fetchPackagingMarginOriginAction.TYPE,
      fetchWizardPackagingMarginSaga
    ),
    takeEvery(sendPackagingMarginAction.TYPE, sentPackagingMarginSaga),
    takeEvery(fetchTPRCOAction.TYPE, fetchTPRCOSaga),
    takeEvery(fetchTPRProductGroupAction.TYPE, fetchTPRProductGroupSaga),
    takeEvery(fetchTPRIndustriesAction.TYPE, fetchTPRIndustriesSaga),
    takeEvery(fetchTPRProductValuesAction.TYPE, fetchTPRProductValuesSaga),
    takeEvery(fetchTPRTableDataAction.TYPE, fetchTPRTableDataSaga),
  ]);
}
