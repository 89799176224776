import React from 'react';
import { TableDataRow } from '@modules/Simulator/components/TargetPriceResults/store';
import WizardTableContainer from '@app/modules/Simulator/components/WizardTableContainer/WizardTableContainer';
import { Table, TableBody, TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableSortLabel } from '@material-ui/core';
import { sortByDirectionWithRange } from '@shared/utils/table-sort';
import { Alert } from '@material-ui/lab';

interface ResultTableProps {
  data: TableDataRow[];
  volumeDriver: { key: string; name: string; value: number }[];
  order: {
    column: string;
    direction: 'asc' | 'desc';
  };
  handlerChangeOrder: (name: string) => void;
  currency: string;
  unit: string;
}

const renderRow = (data: TableDataRow, currency: string): JSX.Element => {
  if (
    data.firstLevel === 0 &&
    data.secondLevel === 0 &&
    data.thirdLevel === 0 &&
    data.fourthLevel === 0 &&
    data.fifthLevel === 0
  ) {
    return (
      <TableRow key={data.key}>
        <TableCell>{data.name}</TableCell>
        <TableCell colSpan={6}>
          <Alert severity="error">
            Value cannot be saved because this base and packaging margin
            combination is not allowed.
          </Alert>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow key={data.key}>
        <TableCell>{data.name}</TableCell>
        <TableCell>
          {data.firstLevel.toFixed(2)}
          {currency}
        </TableCell>
        <TableCell>
          {data.secondLevel.toFixed(2)}
          {currency}
        </TableCell>
        <TableCell>
          {data.thirdLevel.toFixed(2)}
          {currency}
        </TableCell>
        <TableCell>
          {data.fourthLevel.toFixed(2)}
          {currency}
        </TableCell>
        <TableCell>
          {data.fifthLevel.toFixed(2)}
          {currency}
        </TableCell>
      </TableRow>
    );
  }
};

export const ResultTable = ({
  data,
  volumeDriver,
  handlerChangeOrder,
  order,
  currency,
  unit,
}: ResultTableProps) => {
  return (
    <WizardTableContainer>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              colSpan={volumeDriver.length}
              align="center"
              style={{ border: '2px solid var(--white)' }}
            >
              Target margin per volume level (in {unit})
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="center"
              style={{ border: '2px solid var(--white)' }}
            >
              <TableSortLabel
                active
                direction={order.column === 'name' ? order.direction : 'asc'}
                onClick={() => handlerChangeOrder('name')}
              >
                Packaging type
              </TableSortLabel>
            </TableCell>
            {volumeDriver.map((i, index, data) => (
              <TableCell
                key={i.key}
                align="center"
                style={{ border: '2px solid var(--white)' }}
              >
                <TableSortLabel
                  active
                  direction={order.column === i.key ? order.direction : 'asc'}
                  onClick={() => handlerChangeOrder(i.key)}
                >
                  {data[index + 1]
                    ? `${i.name} to <${data[index + 1].name}`
                    : `≥${i.name}`}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .sort(
              sortByDirectionWithRange(
                order.direction,
                order.column,
                order.column !== 'name'
              )
            )
            .map((data) => renderRow(data, currency))}
        </TableBody>
      </Table>
    </WizardTableContainer>
  );
};
