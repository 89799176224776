import { TFilterAllocationLevels } from '@app/services/filter-service/response-interfaces/get-filters.response';
import {
  ProductGroupAllocations,
  ProductGroupLabeling,
  UpdateAllocationsLevel,
  UpdateAllocationValue,
} from '@shared/types/productValue';

export interface ProductValueState {
  productGroupAllocations: {
    data: ProductGroupAllocations[];
    total: number;
  };
  productGroupLabeling: {
    data: ProductGroupLabeling[];
    total: number;
  };
  loadingGroupsTable: boolean;
  loadingGroupsLabelingTable: boolean;
  allocationLevels: TFilterAllocationLevels;
  selectedAllocationLevels: TFilterAllocationLevels;
  updateDataLevel: Record<string, UpdateAllocationsLevel>;
  updateDataAllocation: Record<string, UpdateAllocationValue>;
}

export const initialState: ProductValueState = {
  productGroupAllocations: {
    data: [],
    total: 0,
  },
  productGroupLabeling: {
    data: [],
    total: 0,
  },
  loadingGroupsTable: false,
  loadingGroupsLabelingTable: false,
  allocationLevels: [],
  selectedAllocationLevels: [],
  updateDataLevel: {},
  updateDataAllocation: {},
};
