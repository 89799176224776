import { TMarginSystemOption } from '@app/services/volume-driver-service/response-interfaces/get-margin-system-options.response';
import { defineActionGroup } from 'rd-redux-utils';

const marginOptionsActionGroup = defineActionGroup('MARGIN OPTIONS');

export const fetchMarginOptionsAction = marginOptionsActionGroup.defineAction(
  'FETCH DATA'
);

export const setMarginOptionsLoadingAction = marginOptionsActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setMarginOptionsDataAction = marginOptionsActionGroup.defineAction<{
  items: TMarginSystemOption[];
}>('SET DATA');
