import React, { useRef, useState, useEffect } from 'react';
import Scrollbars from 'react-scrollbars-custom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import ResizeObserver from 'resize-observer-polyfill';

interface ScrollbarWithMaxHeightProps {
  maxHeight: string;
  loading?: boolean;
}

const ScrollbarWithMaxHeight: React.FC<ScrollbarWithMaxHeightProps> = ({
  maxHeight,
  loading = false,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [currentHeight, setCurrentHeight] = useState(0);
  const [withPadding, setWithPadding] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const div = ref.current;
      const resizeObserver = new ResizeObserver((e) =>
        setCurrentHeight(e[0].contentRect.height)
      );
      resizeObserver.observe(div);
      return () => {
        resizeObserver.unobserve(div);
      };
    }
  }, [ref, setCurrentHeight]);
  return (
    <Scrollbars
      style={{
        height: `${withPadding ? currentHeight + 10 : currentHeight}px`,
        maxHeight: maxHeight,
      }}
      onUpdate={(e) => {
        setWithPadding(Boolean(e.trackXVisible));
      }}
    >
      {loading && (
        <Fade in={loading} timeout={{ enter: 500, exit: 500 }}>
          <LinearProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 7,
              borderTopRightRadius: '2px',
              borderTopLeftRadius: '2px',
              height: '2px',
            }}
          />
        </Fade>
      )}
      <div
        style={{
          height: '100%',
          minWidth: 'min-content',
        }}
        ref={ref}
      >
        {children}
      </div>
    </Scrollbars>
  );
};

export default ScrollbarWithMaxHeight;
