import * as yup from 'yup';
/* eslint-disable */

const arrScheme = yup.array().min(1);

export const schema = yup.object().shape({
  userName: yup.string().required(),
  productGroupsLevel: yup.lazy(((value: any) => {
    const productShemes = Object.keys(value).reduce(
      (accum: any, current: any) => {
        return {
          ...accum,
          [current]: arrScheme,
        };
      },
      {} as Record<number, any>
    );
    return yup.object(productShemes);
  }) as any),
});
