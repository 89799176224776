import {
  BASE_MARGIN_MATRIX,
  NEGOTIATIONS_URL_PRODUCT_LINE,
  PACKAGING_DRIVER_MARGIN,
  PRODUCT_VALUE_SECTION_1,
  SIMULATOR_WIZARD_URL,
  VOLUME_DRIVER_ALLOCATION,
} from '@app/routes/urls';
import {
  marginIcon,
  valueIcon,
  volumneIcon,
  wizardIcon,
  driverIcon,
  negotiationIcon,
} from '@app/assets/images/pageIcon';
import { FEATURE_PRODUCT_VALUE_LABELING } from '@app/modules/ProductValue';
import { FEATURE_BASE_MARGIN_MATRIX } from '@app/modules/BaseMarginMatrix';
import { FEATURE_PACKAGING_DRIVER } from '@app/modules/PackagingDriver';
import { FEATURE_VOLUME_DRIVER } from '@app/modules/VolumeDriver';
import { FEATURE_NEGOTIATION_ROOM } from '@app/modules/NegotiationsRoom';
import { FEATURE_WIZARD } from '@app/modules/Simulator';

export const navigationLinks = [
  {
    link: PRODUCT_VALUE_SECTION_1.urlTemplate,
    name: 'Product Value',
    iconSrc: valueIcon,
    feature: FEATURE_PRODUCT_VALUE_LABELING,
  },
  {
    link: BASE_MARGIN_MATRIX.urlTemplate,
    name: 'Base Margin Matrix',
    iconSrc: marginIcon,
    feature: FEATURE_BASE_MARGIN_MATRIX,
  },
  {
    link: PACKAGING_DRIVER_MARGIN.urlTemplate,
    name: 'Packaging Driver',
    iconSrc: driverIcon,
    feature: FEATURE_PACKAGING_DRIVER,
  },
  {
    link: VOLUME_DRIVER_ALLOCATION.urlTemplate,
    name: 'Volume Driver',
    iconSrc: volumneIcon,
    feature: FEATURE_VOLUME_DRIVER,
  },
  {
    link: NEGOTIATIONS_URL_PRODUCT_LINE.urlTemplate,
    name: 'Negotiation Room',
    iconSrc: negotiationIcon,
    feature: FEATURE_NEGOTIATION_ROOM,
  },
  {
    link: SIMULATOR_WIZARD_URL.urlTemplate,
    name: 'Wizard',
    iconSrc: wizardIcon,
    feature: FEATURE_WIZARD,
  },
];
