import { Industrie } from '@services/filter-service/response-interfaces/get-industries';

export interface IndustriesState {
  items: Industrie[];
  total: number;
  loading: boolean;
  selectedIndustries: Industrie[];
}

export const initialState: IndustriesState = {
  items: [],
  loading: true,
  total: 0,
  selectedIndustries: [],
};
