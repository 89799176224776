import {
  fetchWizardProductGroupAllocationAction,
  setWizardLoadingAction,
  setWizardProductGroupAllocationAction,
} from '../actions';
import { call, put } from 'redux-saga/effects';
import WizardServices from '@services/wizard/wizardServices';
import { fetchCOIdWorkerSaga } from './fetchCOIdWorkerSaga';

export function* fetchWizardProductGroupAllocationWorkerSaga(
  action: ReturnType<typeof fetchWizardProductGroupAllocationAction>
) {
  try {
    yield put(setWizardLoadingAction({ payload: true }));
    let coId = yield call(fetchCOIdWorkerSaga);
    const res = yield call(
      WizardServices.getProductGroupAllocations,
      coId,
      action.payloads.limit,
      action.payloads.page,
      action.payloads.orderColumn,
      action.payloads.isAsc,
      action.payloads?.productGroupAllocIds,
      action.payloads?.valueAllocations
    );
    yield put(setWizardProductGroupAllocationAction({ payload: res }));
    yield put(setWizardLoadingAction({ payload: false }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
