import { MapResponseCurrency } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes-currency,response';
import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';
import { defineActionGroup } from 'rd-redux-utils';

const quotesActionGroup = defineActionGroup('QUOTES');

export const fetchQuotesDataAction = quotesActionGroup.defineAction(
  'FETCH DATA'
);

export const setQuotesDataAction = quotesActionGroup.defineAction<{
  items: TQuotesItem[];
  total: number;
}>('SET DATA');

export const setQuotesLoadingAction = quotesActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const fetchCurrencyCOAction = quotesActionGroup.defineAction(
  'FETCH CURRENCY CO'
);
export const setCurrencyCOAction = quotesActionGroup.defineAction<{
  currencyCo: MapResponseCurrency[];
}>('SET CURRENCY CO');

export const setUpdateDataQuotesAction = quotesActionGroup.defineAction<{
  updateData: Record<string, TQuotesItem>;
}>(' UPDATE QUOTES');

export const saveSaveQuotesAction = quotesActionGroup.defineAction(
  'SAVE QUOTES'
);
