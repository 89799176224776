import { ExceptionsNegotiationUpdate } from '@app/modules/NegotiationsRoom/tabs/ExceptionsTab/components/ExceptionsTable';
import { CreateExceptionsDTO } from '@app/services/negotiation-room-service/dto/create-exceptions.dto';
import { ResponseNegotitaionException } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-exception.response';
import { defineActionGroup } from 'rd-redux-utils';

const exceptionsNegotiationActionGroup = defineActionGroup(
  'EXCEPTIONS-NEGOTIATION'
);

export const fetchExceptionsNegotiationDataAction = exceptionsNegotiationActionGroup.defineAction(
  'FETCH DATA'
);

export const setExceptionsNegotiationDataAction = exceptionsNegotiationActionGroup.defineAction<{
  items: ResponseNegotitaionException[];
  total: number;
}>('SET DATA');

export const setExceptionsNegotiationLoadingAction = exceptionsNegotiationActionGroup.defineAction<{
  loading: boolean;
}>('SET LOADING');

export const setUpdateDataExceptionsNegotiationAction = exceptionsNegotiationActionGroup.defineAction<{
  updateData: Record<string, ExceptionsNegotiationUpdate>;
}>(' UPDATE EXCEPTIONS');

export const setIsOpenModalAction = exceptionsNegotiationActionGroup.defineAction<{
  isOpen: boolean;
}>('IS OPEN MODAL');

export const saveExceptionNegotiationAction = exceptionsNegotiationActionGroup.defineAction(
  'SAVE EXCEPTIONS'
);
export const deleteExceptionNegotiationAction = exceptionsNegotiationActionGroup.defineAction<{
  ids: string[];
}>('DELETE EXCEPTIONS');
export const createExceptionNegotiationAction = exceptionsNegotiationActionGroup.defineAction<{
  data: CreateExceptionsDTO;
}>('CREATE EXCEPTIONS');
