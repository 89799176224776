import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LookupValueSystem } from '@shared/types/wizard';

interface VolumeSystemSelectProps {
  volumeSystem: LookupValueSystem[];
  selectVolumeSystem: LookupValueSystem[];
  handlerChangeSelectVolumeSystem: (values: LookupValueSystem[]) => void;
}

export const VolumeSystemSelect = ({
  volumeSystem,
  selectVolumeSystem,
  handlerChangeSelectVolumeSystem,
}: VolumeSystemSelectProps) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="row"
      justify="flex-start"
    >
      <Grid item xs={6}>
        <Typography variant="body2" style={{ opacity: 0.7 }}>
          Look up volume system
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={selectVolumeSystem}
          multiple
          getOptionSelected={(o, v) => o.id === v.id}
          options={volumeSystem}
          getOptionLabel={(i) => i.name}
          onChange={(event, value) => handlerChangeSelectVolumeSystem(value)}
          size="small"
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Grid>
    </Grid>
  );
};
