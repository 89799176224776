import { TQuotesItem } from '@app/services/negotiation-room-service/response-interfaces/negotiation-get-quotes.response';

export const validEmptyFields = (data: TQuotesItem[]): boolean => {
  let valid = data.reduce((res, item) => {
    if (Number(item.firstThreshold) > Number(item.secondThreshold)) {
      return false;
    }

    if (item.firstThreshold === '' || item.secondThreshold === '') {
      return false;
    }

    if (item.quoteType !== 1 && item.currencyType === 0) {
      return false;
    }

    return res && true;
  }, true);
  return valid;
};

export const validQuotesFields = (data: TQuotesItem[]): boolean => {
  let valid: boolean = data.reduce((res, item): boolean => {
    switch (String(item.quoteType)) {
      case '1':
        if (Number(item.firstThreshold) > Number(item.secondThreshold)) {
          return false;
        }
        break;
      case '2':
        if (Number(item.firstThreshold) > Number(item.secondThreshold)) {
          return false;
        }
        break;
      case '3':
        if (Number(item.firstThreshold) < Number(item.secondThreshold)) {
          return false;
        }
        break;
    }

    if (item.firstThreshold === '' || item.secondThreshold === '') {
      return false;
    }

    if (item.quoteType !== 1 && item.currencyType === 0) {
      return false;
    }

    return res && true;
  }, true as boolean);
  return valid;
};
