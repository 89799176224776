import { LOGIN_URL } from '@app/routes/urls';
import {
  selectAppLoadedState,
  selectAppLoadingState,
} from '@app/store/app/selectors';
import { selectAuthLoggedIn } from '@app/store/auth';
import Fade from '@material-ui/core/Fade';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router';
import { AppLoader } from '../AppLoader';
import { RoleBasedRoute } from '../RoleBasedRoute';
import styles from './PrivateRoute.module.scss';

interface Props extends RouteProps {}

export const PrivateRoute = (props: Props) => {
  const isAppLoaded = useSelector(selectAppLoadedState);
  const isAppLoading = useSelector(selectAppLoadingState);
  const loggedIn = useSelector(selectAuthLoggedIn);

  if (isAppLoaded && !loggedIn) {
    return <Redirect to={LOGIN_URL.urlTemplate} />;
  }

  return (
    <React.Fragment>
      <Fade in={isAppLoading} appear={true} timeout={{ exit: 250 }}>
        <div className={styles.privateRoutePlaceholder}>
          <AppLoader loading={true} />
        </div>
      </Fade>
      {isAppLoading ? null : <RoleBasedRoute {...props} />}
    </React.Fragment>
  );
};
