import { call, put, select } from 'redux-saga/effects';
import {
  setBaseMarginAction,
  setBaseMarginBulk,
  setBaseMarginLoadingAction,
} from '../actions';
import BaseMarginService from '@services/base-margin-service/baseMarginService';
import {
  BaseMarginData,
  SetBulkBaseMarginDTO,
} from '@app/shared/types/baseMargin';
import { AppState } from '@app/store';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { enqueSnackAction } from '@app/store/snacks';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { getSelectedLevel4IndustriesIds } from '@app/store/filters-module/industries';
import { TFilterItemResponse } from '@app/services/filter-service/response-interfaces/get-filters.response';
import {
  selectCoSelectedFilters,
  selectSelectedCo,
} from '@app/store/filters-module/co';
import { TCommercialOrganization } from '@app/services/filter-service/response-interfaces/get-co.response';

export function* saveBaseMarginBulkSaga(
  action: ReturnType<typeof setBaseMarginBulk>
) {
  try {
    yield put(setBackdropLoadingAction({ payload: true }));

    const selectedCo: TCommercialOrganization = yield select(selectSelectedCo);
    const selectedIndustiesIds: string[] = yield select(
      getSelectedLevel4IndustriesIds
    );
    const filtersValue: TFilterItemResponse[] = yield select(
      selectCoSelectedFilters
    );
    const filter4Level = filtersValue.filter((f) => f.level === 4);
    const allocationLevels: number[] = yield select((store: AppState) =>
      store.filters.co.selectedLevels.map((i) => i.key)
    );

    const dataToBulk: SetBulkBaseMarginDTO = {
      commercialOrganizationId: selectedCo.id,
      industryIds: selectedIndustiesIds,
      productGroupIds: filter4Level.map((i) => i.id),
      valueAllocationLevels: allocationLevels,
      margin: action.payload.margin,
    };
    yield put(setBaseMarginLoadingAction({ payload: true }));

    yield call(BaseMarginService.saveBaseMarginBulk, dataToBulk);
    const data: BaseMarginData = yield call(BaseMarginService.fetchBaseMargin, {
      commercialOrganizationId: selectedCo.id,
      skip: getCurrentSkip(),
      limit: getCurrentPageLimit(),
      industryIds: selectedIndustiesIds,
      productGroupIds: filter4Level.map((i) => i.id),
      valueAllocationLevels: allocationLevels,
      orderColumn: 0,
      isAsc: true,
    });
    yield put(setBaseMarginAction({ payload: data }));
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Margins are saved.',
          options: {
            variant: 'success',
          },
          key: 'save bulk margins',
        },
      })
    );
  } catch (e) {
    if (e.response && e.response.status === 409) {
      yield put(
        enqueSnackAction({
          notification: {
            message: e.response.data.message,
            options: {
              variant: 'error',
            },
            key: 'error bulk margins',
          },
        })
      );
    }

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Something wrong...',
          options: {
            variant: 'error',
          },
          key: 'error bulk margins',
        },
      })
    );
    console.log(`Failed to save base margin bulk: ${e.toString()}`);
  } finally {
    yield put(setBaseMarginLoadingAction({ payload: false }));
    yield put(setBackdropLoadingAction({ payload: false }));
  }
}
