import * as React from 'react';
import styles from './StepLayout.module.scss';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

interface Props {
  title: string | JSX.Element;
  subheader?: string | JSX.Element;
  children: JSX.Element;
  resultView?: JSX.Element;
  className?: string;
  HeadSelector?: React.ComponentType<any>;
}

export const StepLayout = (props: Props) => {
  const { subheader, children, className, HeadSelector } = props;

  return (
    <Box p={4} className={classnames(styles.stepLayout, className)}>
      <Grid container spacing={2} wrap="nowrap" style={{ flex: '1' }}>
        <Grid
          item
          container
          direction="column"
          spacing={1}
          className={styles.contentCol}
        >
          <Grid item>
            <Grid
              container
              wrap="nowrap"
              spacing={2}
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: '2rem' }}
            >
              <Grid item>
                <div className={styles.stepLayout_header}>
                  {subheader && (
                    <div className={styles.subheader}>{subheader}</div>
                  )}
                </div>
              </Grid>
              {HeadSelector && (
                <Grid item xs={6} container justify={'flex-end'}>
                  <Grid item>
                    <HeadSelector />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container className={styles.content}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
