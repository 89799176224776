import React from 'react';
import { useDispatch } from 'react-redux';
import { resetSelectedCoAction } from '@app/store/filters-module/co';
import { resetSelectedFilters } from '@app/store/filters-module/filters-state';
import { TabRouterLayout } from '@app/shared/components/TabRouterLayout';
import { volumeDriverTabs } from './tab-definitions';

export const VolumeDriver = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectedCoAction({}));
      dispatch(resetSelectedFilters({}));
    };
  }, [dispatch]);

  return (
    <TabRouterLayout routes={volumeDriverTabs} pageTitle="Volume Driver" />
  );
};
