import { AppState } from '@app/store';

export const selectQuotesState = (store: AppState) =>
  store.negotiationRoom.quotes;

export const selectQuotesItems = (store: AppState) =>
  selectQuotesState(store).items;

export const selectQuotesTotalItems = (store: AppState) =>
  selectQuotesState(store).totalItems;

export const selectQuotesItemsLoadingState = (store: AppState) =>
  selectQuotesState(store).isLoading;

export const selectQuotesCurrencyCO = (store: AppState) =>
  selectQuotesState(store).currencyCO;

export const selectUpdateDataQuotes = (store: AppState) =>
  selectQuotesState(store).updateData;

export const selectCurrentCurrency = (store: AppState) => {
  const currentCO = store.filters.co.selectedItem?.id;
  const currentCurrency = selectQuotesState(store).currencyCO.find(
    (f) => f.id === currentCO
  );
  return currentCurrency;
};
