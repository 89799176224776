import { AppState } from '../../store';

export const selectIndustriesFiltersState = (state: AppState) =>
  state.filters.industries;

export const getIndustriesFilter = (state: AppState) =>
  selectIndustriesFiltersState(state).items;

export const getSelectedIndustries = (state: AppState) =>
  selectIndustriesFiltersState(state).selectedIndustries;

export const getSelectedLevel4IndustriesIds = (state: AppState) =>
  getSelectedIndustries(state)
    .filter((industry) => industry.level === 4)
    .map((industry) => industry.id);
