import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { productAccessValues } from '../../ConfigureDialog';
import { UserRightsProductAccessType } from '@modules/Settings/components/UserRights/UserRightsTable';

interface ProductAccessSelectPropsType {
  value: UserRightsProductAccessType | null;
  onChange: (value: UserRightsProductAccessType | null) => void;
  disabled: boolean | undefined;
}

export const ProductAccessSelect = ({
  value,
  onChange,
  disabled,
}: ProductAccessSelectPropsType) => {
  return (
    <Autocomplete
      value={productAccessValues.find((i: any) => i.key === value) || null}
      options={productAccessValues}
      getOptionSelected={(i, value) => i.key === value.key}
      getOptionLabel={(i) => i.name}
      loading={false}
      onChange={(e, value) => onChange(value && value.key)}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          variant="outlined"
          fullWidth={true}
          style={{ width: '215px' }}
          size="small"
        />
      )}
      disabled={disabled}
    />
  );
};
