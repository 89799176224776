import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { WizardMarginSystem } from '@shared/types/wizard';
import { marginSystemTableHeaders } from '../../../../data-definition';
import WizardTableContainer from '@modules/Simulator/components/WizardTableContainer/WizardTableContainer';
import { sortByDirection } from '@app/shared/utils/table-sort';
import TablePagination from '@material-ui/core/TablePagination';

interface MarginSystemTableProps {
  marginSystem: WizardMarginSystem[];
}

export const MarginSystemTable = ({ marginSystem }: MarginSystemTableProps) => {
  //===== create table sort options =====
  const [orderColumnName, setOrderColumnName] = useState('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handlerChangeOrder = (columnName: string): void => {
    const isAsc = orderColumnName === columnName && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderColumnName(columnName);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dataTable = React.useMemo(() => {
    const newData = [...marginSystem].splice(page * rowsPerPage, rowsPerPage);
    return newData;
  }, [marginSystem, page, rowsPerPage]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <WizardTableContainer>
        <Table stickyHeader={true} style={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              {marginSystemTableHeaders.map((i) => (
                <TableCell key={i.key}>
                  <TableSortLabel
                    active
                    direction={orderColumnName === i.key ? order : 'asc'}
                    onClick={() => handlerChangeOrder(i.key)}
                  >
                    {i.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTable
              .sort(sortByDirection(order, orderColumnName))
              .map((i) => (
                <TableRow key={i.id}>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{i.description}</TableCell>
                  <TableCell>{i.firstLevel}%</TableCell>
                  <TableCell>{i.secondLevel}%</TableCell>
                  <TableCell>{i.thirdLevel}%</TableCell>
                  <TableCell>{i.fourthLevel}%</TableCell>
                  <TableCell>{i.fifthLevel}%</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </WizardTableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={marginSystem.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
