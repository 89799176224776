import { Action } from 'redux';
import {
  setExceptionsNegotiationDataAction,
  setExceptionsNegotiationLoadingAction,
  setIsOpenModalAction,
  setUpdateDataExceptionsNegotiationAction,
} from './action';
import { initialState, NegotiationExceptionState } from './state';

export function negotiantionExceptionsReducer(
  state = initialState,
  action: Action
): NegotiationExceptionState {
  if (setExceptionsNegotiationDataAction.is(action)) {
    return {
      ...state,
      items: action.items,
      totalItems: action.total,
    };
  }

  if (setExceptionsNegotiationLoadingAction.is(action)) {
    return {
      ...state,
      isLoading: action.loading,
    };
  }
  if (setUpdateDataExceptionsNegotiationAction.is(action)) {
    return {
      ...state,
      updateData: action.updateData,
    };
  }
  if (setIsOpenModalAction.is(action)) {
    return {
      ...state,
      modalIsOpen: action.isOpen,
    };
  }

  return state;
}
