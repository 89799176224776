import { TVolumeDriverAllocation } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';
import { SaveVolumeDriverActionPayload } from '@app/store/volume-driver/volume-driver-allocations';

export const mapVolumeDriverTableDataToActionPayload = (
  coId: string,
  tableData: Record<string, TVolumeDriverAllocation>
): SaveVolumeDriverActionPayload => {
  const mappedItems = Object.values(tableData).map((item) => {
    return {
      productGroupId: item.id,
      volumeSystemId: item.volumeSystem ? item.volumeSystem.id : null,
      marginSystemId: item.marginSystem ? item.marginSystem.id : null,
      valueAllocation: item.valueAllocationLevel,
    };
  });

  const payload: SaveVolumeDriverActionPayload = {
    coId,
    items: mappedItems,
  };

  return payload;
};
