import { combineReducers } from 'redux';
import { coReducer } from './co';
import { filtersReducer } from './filters-state';
import { packagingGroupsReducer } from './packaging-groups/reducer';
import { industriesReducer } from './industries';

export const filtersModuleReducer = combineReducers({
  filters: filtersReducer,
  co: coReducer,
  packagingGroups: packagingGroupsReducer,
  industries: industriesReducer,
});
