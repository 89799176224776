interface ComposeEntity {
  id: string;
  valueAllocation: number;
}

export class ValueAllocationIdComposer {
  static compose<T extends ComposeEntity>(payload: T, separator = '@'): string {
    return `${payload.id}${separator}${payload.valueAllocation}`;
  }

  static decompose(idString: string): ComposeEntity {
    const decomposedStringArray = idString.split('@');

    const isValid = decomposedStringArray.length === 2;

    if (!isValid)
      throw new Error(
        `provided idString is in incorrect format, provided: ${idString}`
      );

    const result: ComposeEntity = {
      id: decomposedStringArray[0],
      valueAllocation: +decomposedStringArray[1],
    };

    return result;
  }
}
