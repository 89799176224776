import { ValueAllocation } from '@shared/types/wizard';

export interface ProductGroupAllocationHeadersType {
    key: number;
    name: string;
}

export const productGroupAllocationHeaders: ProductGroupAllocationHeadersType[] = [
    {
        key: 0,
        name: 'Product group allocation name',
    },
    {
        key: 1,
        name: 'Value allocation',
    },
    {
        key: 2,
        name: 'Main product name',
    },
    {
        key: 3,
        name: 'Product number',
    },
    {
        key: 4,
        name: 'Product name',
    },
];

export const productAllocation: Record<ValueAllocation, string> = {
    1: 'Low',
    2: 'Medium',
    3: 'High',
};

export interface LookupVolumeSystemHeadersType {
    key: string;
    name: string;
}

export const lookupVolumeSystemHeaders: LookupVolumeSystemHeadersType[] = [
    {
        key: 'name',
        name: 'Volume system',
    },
    {
        key: 'description',
        name: 'Description',
    },
    {
        key: 'firstLevel',
        name: 'Level 1',
    },
    {
        key: 'secondLevel',
        name: 'Level 2',
    },
    {
        key: 'thirdLevel',
        name: 'Level 3',
    },
    {
        key: 'fourthLevel',
        name: 'Level 4',
    },
    {
        key: 'fifthLevel',
        name: 'Level 5',
    },
];

export interface UnitOption {
    value: number;
    name: string;
}

export interface StandardPackingTableHeaderType {
    key: string;
    name: string;
}

export const standardPackingTableHeaders: StandardPackingTableHeaderType[] = [
    {
        key: 'PRODUCT GROUP ALLOCATION LEVEL',
        name: 'Product group allocation level',
    },
    {
        key: 'PRODUCT VALUE',
        name: 'Product value',
    },
    {
        key: 'STANDARD PACKAGING',
        name: 'Standard packaging',
    },
    {
        key: 'VOLUME SYSTEM',
        name: 'Volume system',
    },
    {
        key: 'STANDARD VOLUME',
        name: 'Standard volume',
    },
];

//===== MARGIN SYSTEM =====

export interface MarginSystemTableHeadersType {
    key: string;
    name: string;
}

export const marginSystemTableHeaders: MarginSystemTableHeadersType[] = [
    {
        key: 'name',
        name: 'Margin system',
    },
    {
        key: 'description',
        name: 'Description',
    },
    {
        key: 'firstLevel',
        name: 'Level 1',
    },
    {
        key: 'secondLevel',
        name: 'Level 2',
    },
    {
        key: 'thirdLevel',
        name: 'Level 3',
    },
    {
        key: 'fourthLevel',
        name: 'Level 4',
    },
    {
        key: 'fifthLevel',
        name: 'Level 5',
    },
];

//===== PGA Volume System =====

export interface PGAVolumeDriverTableHeadersType {
    key: string;
    name: string;
}

export const pgaVolumeDriverTableHeaders: PGAVolumeDriverTableHeadersType[] = [
    {
        key: 'name',
        name: 'Product group allocation level',
    },
    {
        key: 'value',
        name: 'Product value',
    },
    {
        key: 'systems',
        name: 'Volume threshold and margin system',
    },
    {
        key: 'firstLevel',
        name: 'Level 1',
    },
    {
        key: 'secondLevel',
        name: 'Level 2',
    },
    {
        key: 'thirdLevel',
        name: 'Level 3',
    },
    {
        key: 'fourthLevel',
        name: 'Level 4',
    },
    {
        key: 'fifthLevel',
        name: 'Level 5',
    },
];

//===== Wizard step 2 industry level =====

export const bmmIndustryLevel = [
    { key: 1, value: 'Level 1' },
    { key: 2, value: 'Level 2' },
    { key: 3, value: 'Level 3' },
    { key: 4, value: 'Level 4' },
];

export const bmmIndustryLevelByKey: Record<
    number,
    string
> = bmmIndustryLevel.reduce((byKey, i) => ({ ...byKey, [i.key]: i.value }), {});

export const bmmIndustryLevelByValue: Record<
    string,
    string
> = bmmIndustryLevel.reduce((byKey, i) => ({ ...byKey, [i.value]: i.key }), {});
