export function bindYupMethods(Yup: any) {
  Yup.addMethod(Yup.array, 'unique', function (
    this: any,
    message: string,
    mapper = (a: any) => a
  ) {
    return this.test('unique', message, function (list: any) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  Yup.addMethod(Yup.object, 'uniqueProperty', function (
    this: any,
    propertyName: any,
    message: any
  ) {
    return this.test('unique', message, function (this: any, value: any) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const duplicate = options.find(
        (option) =>
          option[propertyName] === value[propertyName] && option !== value
      );

      if (duplicate) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  });

  Yup.addMethod(Yup.number, 'isDecimal', function (
    this: any,
    regex: RegExp,
    message: string
  ) {
    return this.test('decimal', message, function (this: any, value: any) {
      if (value === '' || value === null) return true;

      const isMatch = String(value).match(regex);

      if (!isMatch) {
        throw this.createError({
          path: this.path,
          message,
        });
      }

      return isMatch;
    });
  });

  return Yup;
}
