import React, { useMemo, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import { Grid } from '@material-ui/core';
import { AppTable } from '@app/shared/components/AppTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  hierarchiesTableSortingManager,
  sortGroup1,
  sortGroup2,
  sortGroup3,
  sortGroup4,
  sortProductGroupAllocation,
  sortProductValueAllocation,
  sortMainProduct,
  sortMainProductCode,
} from '../../../utils/sortingHelpers';
import {
  selectLoadingGroupsLabelingTable,
  selectProductGroupLabeling,
  selectProductGroupLabelingTotal,
  selectUpdateAllocation,
} from '@app/store/productValue/selectors';
import { ProductGroupLabeling } from '@app/shared/types/productValue';
import { NO_FILTERS_DATA_TABLE_TEXT } from '@app/shared/texts/texts';
import { setUpdateDataAllocation } from '@app/store/productValue';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';

export const ProductTable: React.FC = () => {
  const dispatch = useDispatch();
  const [hoverRow, setHoverRow] = useState('');

  const data = useSelector(selectProductGroupLabeling);
  const updateDate = useSelector(selectUpdateAllocation);
  const total = useSelector(selectProductGroupLabelingTotal);
  const loading = useSelector(selectLoadingGroupsLabelingTable);

  const dataToTable = useMemo(() => {
    return data.map((i) => {
      return {
        ...i,
        ...updateDate[i.id],
      };
    });
  }, [data, updateDate]);

  const handleChangeAllocation = (e: any, id: string) => {
    dispatch(
      setUpdateDataAllocation({
        payload: {
          [id]: { valueAllocation: +e.target.value },
        },
      })
    );
  };

  return (
    <Grid item xs={12} container direction="column" wrap="nowrap">
      <Grid item xs={12} container>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            item
            xs={12}
            container
            style={{
              height: '0',
            }}
          >
            <AppTable
              data={dataToTable}
              dataCount={total}
              deletableItems={false}
              loading={loading}
              isEmpty={dataToTable.length ? false : true}
              tableLayout="auto"
            >
              <AppTable.Head>
                <AppTable.Row isHead={true}>
                  <AppTable.HeadCell
                    sortHandler={hierarchiesTableSortingManager}
                  >
                    Product Value Label Manager
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup1}>
                    Product Group 1
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup2}>
                    Product Group 2
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup3}>
                    Product Group 3
                  </AppTable.HeadCell>

                  <AppTable.HeadCell sortHandler={sortGroup4}>
                    Product Group 4
                  </AppTable.HeadCell>
                  <AppTable.HeadCell sortHandler={sortMainProduct}>
                    Main Product
                  </AppTable.HeadCell>
                  <AppTable.HeadCell sortHandler={sortMainProductCode}>
                    Product Number
                  </AppTable.HeadCell>
                  <AppTable.HeadCell sortHandler={sortProductGroupAllocation}>
                    Product Group <br /> Allocation Level
                  </AppTable.HeadCell>
                  <AppTable.HeadCell sortHandler={sortProductValueAllocation}>
                    Product Value
                  </AppTable.HeadCell>
                </AppTable.Row>
              </AppTable.Head>
              <AppTable.Body
                noDataText={NO_FILTERS_DATA_TABLE_TEXT}
                contentColSpan={8}
              >
                {(data) =>
                  data.map((row: ProductGroupLabeling, index) => {
                    return (
                      <AppTable.Row<ProductGroupLabeling>
                        style={
                          hoverRow === row.allocationGroup.id
                            ? { background: '#FFF5F1' }
                            : {}
                        }
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell align="center">
                          {row.productManagerName
                            ? row.productManagerName
                            : '—'}
                        </TableCell>
                        <TableCell align="left">{row.firstGroupName}</TableCell>
                        <TableCell align="left">
                          {row.secondGroupName}
                        </TableCell>
                        <TableCell align="left">{row.thirdGroupName}</TableCell>
                        <TableCell align="left">
                          {row.fourthGroupName}
                        </TableCell>
                        <TableCell align="left">{row.mainGroupName}</TableCell>
                        <TableCell align="left">{row.mainGroupCode}</TableCell>
                        <TableCell align="left">
                          {row.allocationGroup.name}
                        </TableCell>
                        <TableCell align="left">
                          <RoleProtectedSelect
                            value={row.valueAllocation}
                            onChange={(e) => {
                              handleChangeAllocation(e, row.id);
                            }}
                            variant="outlined"
                            fullWidth
                            onMouseEnter={() => {
                              setHoverRow(row.allocationGroup.id);
                            }}
                          >
                            <MenuItem value={0}>
                              Choose Allocation
                            </MenuItem>
                            <MenuItem value={1}>Low</MenuItem>
                            <MenuItem value={2}>Medium</MenuItem>
                            <MenuItem value={3}>High</MenuItem>
                          </RoleProtectedSelect>
                        </TableCell>
                      </AppTable.Row>
                    );
                  })
                }
              </AppTable.Body>
            </AppTable>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
