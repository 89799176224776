import { UpdateVolumeSystemDto } from '@app/services/volume-driver-service/dto/update-volume-system.dto';
import { TVolumeSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';
import { VolumeDriverApi } from '@app/services/volume-driver-service/volume-driver.service';
import { enqueSnackAction } from '@app/store/snacks';
import { call, put, select } from 'redux-saga/effects';
import {
  setVolumeSystemConfigurationsCreatingAction,
  resetUpdatedVolumeConfigurationsAction,
  setVolumeDriverConfigurationsDataAction,
} from '../actions';
import {
  selectVolumeSystemConfigurationsItems,
  selectVolumeSystemConfigurationsState,
  selectVolumeSystemUpdatedConfigurations,
} from '../selectors';

export function* saveVolumeSystemConfigurationsSaga() {
  try {
    const volumeSystemList: TVolumeSystemConfiguration[] = yield select(
      selectVolumeSystemConfigurationsItems
    );
    const updatedItemsList: Record<
      string,
      TVolumeSystemConfiguration
    > = yield select(selectVolumeSystemUpdatedConfigurations);

    const updatedItems: TVolumeSystemConfiguration[] = volumeSystemList
      .filter((item) => !!updatedItemsList[item.id])
      .map((item) => updatedItemsList[item.id]);

    if (!updatedItems.length) return;

    yield put(setVolumeSystemConfigurationsCreatingAction({ creating: true }));

    const requestPayload: UpdateVolumeSystemDto = { data: updatedItems };

    const totalItems: number = yield select(
      selectVolumeSystemConfigurationsState
    );

    yield put(
      setVolumeDriverConfigurationsDataAction({
        items: volumeSystemList.map((item) =>
          updatedItemsList[item.id] ? updatedItemsList[item.id] : item
        ),
        total: totalItems,
      })
    );

    yield call(
      VolumeDriverApi.updateVolumeSystemConfigurations,
      requestPayload
    );

    yield put(setVolumeSystemConfigurationsCreatingAction({ creating: false }));

    yield put(
      enqueSnackAction({
        notification: {
          message: 'Volume system configurations has been saved.',
          options: {
            variant: 'success',
          },
          key: 'volume-system-configurations-save-success',
        },
      })
    );

    yield put(resetUpdatedVolumeConfigurationsAction({}));
  } catch (e) {
    yield put(
      enqueSnackAction({
        notification: {
          message: 'Failed to save volume system configurations.',
          options: {
            variant: 'error',
          },
          key: 'volume-system-configurations-save-failed',
        },
      })
    );
    yield put(setVolumeSystemConfigurationsCreatingAction({ creating: false }));
  }
}
