import { call } from 'redux-saga/effects';
import { fetchCOIdWorkerSaga } from '@app/store/wizard/sagas/fetchCOIdWorkerSaga';
import { select } from 'redux-saga/effects';
import { fetchTPRTableDataAction, getTPRSelectedCOSelector, getTPRSelectedProductSelector, getTPRSelectedValueAllocationSelector, getWizardCurrentProductIdSelector } from '@app/store/wizard';
import WizardServices from '@services/wizard/wizardServices';
import { put } from 'redux-saga/effects';
import { fetchPackagingMarginOriginAction } from '@app/store/wizard';
import { getPackagingMarginSending } from '@app/store/wizard';

export function* sentPackagingMarginSaga() {
  try {
    const coId = yield call(fetchCOIdWorkerSaga);
    const productId = yield select(getWizardCurrentProductIdSelector);
    const data = yield select(getPackagingMarginSending);

    const co = yield select(getTPRSelectedCOSelector);
    const product = yield select(getTPRSelectedProductSelector);
    const valueAllocation = yield select(getTPRSelectedValueAllocationSelector);


    const res = yield call(
      WizardServices.updatePackagingMargin,
      coId,
      productId,
      data
    );

    if (res.status === 200) {
      yield put(fetchPackagingMarginOriginAction({}));


      if (co && product && valueAllocation) {
        if (co.key === coId &&
          product.key == productId) {
          yield put(
            fetchTPRTableDataAction({
              payloads: {
                coId: co.key || '',
                productId: product.key || '',
                valuesAllocations: valueAllocation.key || '',
              },
            })
          );
        }
      }


    }
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
