import { TVolumeSystemConfiguration } from '@app/services/volume-driver-service/response-interfaces/get-volume-system-configurations.response';

export interface VolumeSystemsConfigurationsState {
  isLoading: boolean;
  items: TVolumeSystemConfiguration[];
  totalItems: number;
  hasChanged: boolean;
  updatedConfigurations: Record<string, TVolumeSystemConfiguration>;
  isCreationModalOpened: boolean;
  isCreating: boolean;
  isLoaded: boolean;
  nameExists: boolean;
}

export const initialState: VolumeSystemsConfigurationsState = {
  isLoading: true,
  totalItems: 0,
  items: [],
  updatedConfigurations: {},
  hasChanged: false,
  isCreationModalOpened: false,
  isCreating: false,
  isLoaded: false,
  nameExists: false,
};
