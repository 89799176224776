import { Action } from 'redux';
import { setAccountRightsAction } from './actions';
import { initialState, AccountRightsState } from './store';

export function accountRightsReducer(
  state = initialState,
  action: Action
): AccountRightsState {
  if (setAccountRightsAction.is(action)) {
    return {
      ...state,
      ...action.rights,
    };
  }

  return state;
}
