import {
  AccessValuesType,
  RightsValuesType,
} from '@app/store/settings/user-rights';
import { rightsHeaders } from '../UserRightsTable';

export const productAccessValues: AccessValuesType[] = [
  { key: 1, name: 'All products' },
  { key: 2, name: 'Specific products' },
];

export const rightsValues: RightsValuesType[] = [
  { key: 0, name: 'Hidden' },
  { key: 1, name: 'View only' },
  { key: 2, name: 'Edit' },
];

//===== SECTIONS DATA =====

export const sectionsFieldLabels: Record<string, string> = rightsHeaders.reduce(
  (res, i) => ({ ...res, [i.key]: i.name }),
  {} as Record<string, string>
);
