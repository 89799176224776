import { all, takeEvery } from 'redux-saga/effects';
import {
  createMarginSystemConfigurationAction,
  deleteMarginSystemConfigurationsAction,
  fetchMarginSystemConfigurationsAction,
  saveMarginSystemConfigurationsAction,
} from '../actions';
import { createMarginSystemConfigurationSaga } from './createMarginSystemConfigurationSaga';
import { deleteMarginSystemConfigurationsSaga } from './deleteVolumeSystemConfigurationsSaga';
import { fetchMarginSystemConfigurationsSaga } from './fetchMarginSystemConfigurationsSaga';
import { saveMarginSystemConfigurationsSaga } from './saveMarginSystemConfigurationsSaga';

export function* marginSystemConfigurationsWatcherSaga() {
  yield all([
    takeEvery(
      fetchMarginSystemConfigurationsAction.TYPE,
      fetchMarginSystemConfigurationsSaga
    ),
    takeEvery(
      createMarginSystemConfigurationAction.TYPE,
      createMarginSystemConfigurationSaga
    ),
    takeEvery(
      deleteMarginSystemConfigurationsAction.TYPE,
      deleteMarginSystemConfigurationsSaga
    ),
    takeEvery(
      saveMarginSystemConfigurationsAction.TYPE,
      saveMarginSystemConfigurationsSaga
    ),
  ]);
}
