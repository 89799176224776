import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { ProductLineTable } from './components/ProductLineTable';
import { ProdutcLineForm } from './components/ProdutcLineForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSelectedCoAction,
  selectSelectedCo,
  setSelectedCo,
} from '@app/store/filters-module/co';
import { AppImportButton } from '@app/shared/components/AppImportButton';
import { AppExportButton } from '@app/shared/components/AppExportButton';
import { RoleProtectedButton } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedButton';
import {
  resetUpdateProductLineAction,
  saveUpdateProductLineAction,
  selectProductLineUpdateItems,
} from '@app/store/negotiation-room/product-line';
import { PageLeavePrompt } from '@app/modules/PageLeavePrompt';
import { CoFilter } from '../../../../shared/components/CoFilter';
import { useCallback } from 'react';
import qs from 'query-string';
import { getCurrentPageLimit } from '@shared/components/AppTablePagination/paginationUtils';
import { setBackdropLoadingAction } from '@app/store/app/actions';
import { useHistory } from 'react-router';
import { setFiltersIsFullListAction } from '@app/store/filters-module/filters-state';

export const ProductLine = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const updateItems = useSelector(selectProductLineUpdateItems);
  const selectCO = useSelector(selectSelectedCo);
  const isTableChanged = React.useMemo(
    () => !!Object.keys(updateItems).length,
    [updateItems]
  );
  React.useEffect(() => {
    dispatch(resetSelectedCoAction({}));
    dispatch(setFiltersIsFullListAction({ isFullList: false }));
  }, [dispatch]);

  const handleSaveItems = React.useCallback(() => {
    dispatch(saveUpdateProductLineAction({}));
  }, [dispatch]);
  const handleDontSave = React.useCallback(() => {
    dispatch(resetUpdateProductLineAction({}));
  }, [dispatch]);

  const onFetchDataAfterImportFile = useCallback(() => {
    dispatch(setSelectedCo({ co: selectCO }));
    history.push({
      search: qs.stringify({ limit: getCurrentPageLimit(), page: 1 }),
    });
    dispatch(setBackdropLoadingAction({ payload: false }));
  }, [dispatch, history, selectCO]);

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap" item>
      <Grid item container justify="flex-end">
        <Grid item container spacing={2} wrap="nowrap">
          <Grid item container xs={10}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <CoFilter
                  shouldAskForConfirm={isTableChanged}
                  onSkip={handleDontSave}
                  onConfirm={handleSaveItems}
                />
              </Grid>
              <Grid item xs={3} container>
                <ProdutcLineForm />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={2}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <RoleProtectedButton
                  color="primary"
                  variant="contained"
                  onClick={handleSaveItems}
                  style={{ height: '100%' }}
                >
                  Save
                </RoleProtectedButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <ProductLineTable />
      </Grid>
      <Grid item container spacing={1} justify="flex-end">
        <Grid item>
          <AppImportButton
            url={'/DataParser/UploadProductLineNegotiation'}
            onFetchDataAfterImportFile={onFetchDataAfterImportFile}
          />
        </Grid>
        <Grid item>
          <AppExportButton
            url={'/CreateExcel/ProductLineNegotiations'}
            coId={selectCO?.id}
            isPost={false}
            disabled={!selectCO}
          />
        </Grid>
      </Grid>
      <PageLeavePrompt
        onSkip={handleDontSave}
        when={isTableChanged}
        onConfirm={handleSaveItems}
      />
    </Grid>
  );
};

ProductLine.TAB_TITLE = 'Product line negotiations';
