import { AppSelect } from '@app/shared/components/AppSelect';
import { FilterContainer } from '@app/shared/components/FilterContainer';
import { ProtectedButtonWithHint } from '@app/shared/components/ProtectedButtonWithHint';
import { selectSelectedFilters } from '@app/store/filters-module/filters-state';
import { selectSelectedProductValueAllocationLevels } from '@app/store/productValue/selectors';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBulkAllocationLevelAction } from '@app/store/productValue';
import { Filters } from '../../../Filters';
import { RoleProtectedSelect } from '@app/shared/components/RoleBasedRoute/components/RoleProtectedSelect';
import { defaultSelectIndex } from '@app/shared/utils/appConst';

interface Props {
  shouldAskForConfirm: boolean;
  onConfirm: () => void;
  onSkip: () => void;
}

export const ProductLevelsHeader: React.FC<Props> = (props) => {
  const { shouldAskForConfirm, onConfirm, onSkip } = props;

  const dispatch = useDispatch();
  const filtersValue = useSelector(selectSelectedFilters);

  const allocationLevelsValue = useSelector(
    selectSelectedProductValueAllocationLevels
  );

  const [allocationLevelBulk, setAllocationLevelBulk] = useState(defaultSelectIndex);

  const onChangeBulkAllocationLevel = useCallback((e: any) => {
    setAllocationLevelBulk(+e.target.value);
  }, []);
  const onBulkAllocationLevel = useCallback(() => {
    const productFilter4level = filtersValue.filter((i) => i.level === 4);
    dispatch(
      updateBulkAllocationLevelAction({
        payload: {
          productGroupIds: productFilter4level.map((i) => i.id),
          valueAllocations: allocationLevelsValue.map((level) => level.key),
          allocationLevel: allocationLevelBulk,
        },
      })
    );
    setAllocationLevelBulk(defaultSelectIndex);
  }, [filtersValue, allocationLevelsValue, dispatch, allocationLevelBulk]);

  return (
    <Grid item container xs={12} spacing={2} alignItems="stretch">
      <Grid item xs={6} md={5} lg={4}>
        <Filters
          shouldAskForConfirm={shouldAskForConfirm}
          onConfirm={onConfirm}
          onSkip={onSkip}
        />
      </Grid>
      <Grid item xs={6} md={5} lg={4} container wrap="nowrap" spacing={2}>
        <Grid item xs={12}>
          <FilterContainer>
            <RoleProtectedSelect
              fullWidth
              value={allocationLevelBulk}
              onChange={onChangeBulkAllocationLevel}
            >
              <AppSelect.Option value={0}>Choose Level</AppSelect.Option>
              <AppSelect.Option value={1}>Level 1</AppSelect.Option>
              <AppSelect.Option value={2}>Level 2</AppSelect.Option>
              <AppSelect.Option value={3}>Level 3</AppSelect.Option>
              <AppSelect.Option value={4}>Level 4</AppSelect.Option>
            </RoleProtectedSelect>
          </FilterContainer>
        </Grid>
        <Grid item container>
          <Grid item>
            <ProtectedButtonWithHint
              color="primary"
              type="submit"
              onClick={onBulkAllocationLevel}
              style={{ height: '100%' }}
              fullWidth
              hintText={'Set Allocation Level'}
              disabled={allocationLevelBulk === defaultSelectIndex}
            >
              Set Label
            </ProtectedButtonWithHint>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
