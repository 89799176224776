import { fetchTPRProductValuesAction, setTPRProductValuesAction } from '..';
import WizardServices from '@services/wizard/wizardServices';
import { call, put } from 'redux-saga/effects';

export function* fetchTPRProductValuesSaga(
  action: ReturnType<typeof fetchTPRProductValuesAction>
) {
  try {
    const res = yield call(
      WizardServices.getProductGroupAllocationWithFullInfo,
      action.payload.coId,
      action.payload.productId
    );
    yield put(setTPRProductValuesAction({ payload: res.data }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
