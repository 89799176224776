import {
  fetchBaseMargiMatrixIndustriesActions,
  setBaseMarginMatrixIndustriesActions,
} from '..';
import WizardServices from '@services/wizard/wizardServices';
import { call, put } from 'redux-saga/effects';

export function* fetchWizardBMMIndustriesSaga(
  action: ReturnType<typeof fetchBaseMargiMatrixIndustriesActions>
) {
  try {
    const res = yield call(WizardServices.getIndustry, action.payload);
    yield put(setBaseMarginMatrixIndustriesActions({ payload: res.data }));
  } catch (e) {
    console.error(
      `Failed to fetchWizardLookupVolumeSystemWorkerSaga: ${e.toString()}`
    );
  }
}
