import {
  fetchWizardBaseMarginMatrixAction,
  getWizardBaseMarginMatrixOriginSelector,
  getWizardBaseMarginMatrixSelector,
} from '@app/store/wizard';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { BaseMarginMatrixTable } from '../BaseMarginMatrixTable/BaseMarginMatrixTable';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  bmmChangeValidate,
  bmmOriginValidate,
  bmmStateValidate,
} from '../../utils/stepValidation';
import { useContext } from 'react';
import { WizardDispatchContext } from '@modules/Simulator/components/SimulatorWizard';
import { changeActiveStepStatusAction } from '@modules/Simulator/components/SimulatorWizard/store';

interface IProps {
  canEdit: boolean
}

export const BaseMarginMatrixContainer = ({canEdit}: IProps) => {
  const dispatch = useDispatch();

  const baseMarginMatrix = useSelector(getWizardBaseMarginMatrixSelector);
  const baseMarginMatrixOrigin = useSelector(
    getWizardBaseMarginMatrixOriginSelector
  );

  useEffect(() => {
    dispatch(fetchWizardBaseMarginMatrixAction({}));
  }, [dispatch]);

  //===== VALIDATE =====

  const wizardDispatch = useContext(WizardDispatchContext);
  useEffect(() => {
    wizardDispatch(
      changeActiveStepStatusAction({
        originValidate: bmmOriginValidate(baseMarginMatrixOrigin),
        currentValidate: bmmStateValidate(baseMarginMatrix),
        changed: bmmChangeValidate(baseMarginMatrixOrigin, baseMarginMatrix, canEdit),
      })
    );
  }, [wizardDispatch, baseMarginMatrix, baseMarginMatrixOrigin]);
  return (
    <Grid container direction="column" spacing={6}>
      {baseMarginMatrix.map((i) => (
        <Grid key={i.valueAllocation} item>
          <BaseMarginMatrixTable 
          data={i} 
          canEdit={canEdit}
          />
        </Grid>
      ))}
    </Grid>
  );
};
