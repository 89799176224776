import { AccountRightsServiceApi } from '@app/services/account-rights-service/account-rights.service';
import { TAccountRights } from '@app/services/account-rights-service/response-interfaces/account-rights';
import StorageUtils from '@app/shared/utils/storage/storage-utils';
import { call, put } from 'redux-saga/effects';
import { setAccountRightsAction } from '../actions';

export function* fetchAccountRightsWorkerSaga() {
  const accessToken = StorageUtils.getAuthToken();
  if (!accessToken) return;

  const accountRights: TAccountRights = yield call(
    AccountRightsServiceApi.getRights
  );

  yield put(setAccountRightsAction({ rights: accountRights }));
}
