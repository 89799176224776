import { Action, combineReducers } from 'redux';
import { setSettingsLoadedAction } from './actions';
import { productRightsReducer } from './product-rights';
import { initialState, SettingsState } from './store';
import { teamRightsReducer } from './team-rights';
import { valueRightsReducer } from './value-rights';
import { userRightReducer } from './user-rights';
const combinedSettingsReducer = combineReducers({
  productRights: productRightsReducer,
  teamRights: teamRightsReducer,
  valueRights: valueRightsReducer,
  userRights: userRightReducer,
  isLoaded: (state = false, action: Action) => {
    if (setSettingsLoadedAction.is(action)) {
      return action.loaded;
    }

    return state;
  },
});

export function settingsReducer(
  state: SettingsState = initialState,
  action: Action
) {
  return combinedSettingsReducer(state, action);
}
