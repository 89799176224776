import { TVolumeDriverAllocation } from '@app/services/volume-driver-service/response-interfaces/get-volume-driver-allocation.response';

function createProductGroupSorter(level: number) {
  return (data: TVolumeDriverAllocation[], order: 'asc' | 'desc') => {
    return data.sort((a, b) => {
      const groupA = a.groupLevels[level - 1];
      const groupB = b.groupLevels[level - 1];

      if (order === 'asc') {
        return groupA?.name.localeCompare(groupB?.name || '') as number;
      }

      if (order === 'desc') {
        return groupB?.name.localeCompare(groupA?.name || '') as number;
      }

      return 0;
    });
  };
}

export const volumeAllocationSortByGroupLevel: Record<
  number,
  (
    data: TVolumeDriverAllocation[],
    order: 'asc' | 'desc'
  ) => TVolumeDriverAllocation[]
> = {
  1: createProductGroupSorter(1),
  2: createProductGroupSorter(2),
  3: createProductGroupSorter(3),
  4: createProductGroupSorter(4),
};

export const volumeAllocationSortByManagerSortByManager = (
  data: TVolumeDriverAllocation[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.managerName ? a.managerName : '';
    const bLevel = b.managerName ? b.managerName : '';
    if (order === 'asc') {
      return aLevel.localeCompare(bLevel) as number;
    }

    if (order === 'desc') {
      return bLevel.localeCompare(aLevel) as number;
    }

    return 0;
  });
};

export const volumeAllocationSortByAllocationLevel = (
  data: TVolumeDriverAllocation[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.valueAllocationLevel;
    const bLevel = b.valueAllocationLevel;

    return order === 'asc' ? aLevel - bLevel : bLevel - aLevel;
  });
};

export const volumeAllocationSortByVolumeSystem = (
  data: TVolumeDriverAllocation[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.volumeSystem ? a.volumeSystem.name : '';
    const bLevel = b.volumeSystem ? b.volumeSystem.name : '';
    if (order === 'asc') {
      return aLevel.localeCompare(bLevel) as number;
    }

    if (order === 'desc') {
      return bLevel.localeCompare(aLevel) as number;
    }

    return 0;
  });
};

export const volumeAllocationSortByMarginSystem = (
  data: TVolumeDriverAllocation[],
  order: 'asc' | 'desc'
) => {
  return data.sort((a, b) => {
    const aLevel = a.marginSystem ? a.marginSystem.name : '';
    const bLevel = b.marginSystem ? b.marginSystem.name : '';
    if (order === 'asc') {
      return aLevel.localeCompare(bLevel) as number;
    }

    if (order === 'desc') {
      return bLevel.localeCompare(aLevel) as number;
    }

    return 0;
  });
};
