import {
  INVALID_DECIMAL_VALUE_ERROR_TEMPLATE,
  // PACKAGING_GROUP_MARGIN_REGEX,
  MINIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE,
  MAXIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE,
  formatErrorMessageTemplate,
} from '@app/shared/validation';
import * as yup from 'yup';

const INVALID_DECIMAL_ERROR = formatErrorMessageTemplate(
  INVALID_DECIMAL_VALUE_ERROR_TEMPLATE,
  [
    {
      pattern: '{min}',
      value: MINIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE,
    },
    { pattern: '{max}', value: MAXIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE },
  ]
);

// export const packagingMarginSchema = yup.array().of(
//   yup.object({
//     packagingGroups: yup.array().of(
//       yup.object({
//         value: yup
//           .string()
//           .test('isValidInput', INVALID_DECIMAL_ERROR, function (
//             this: any,
//             value: any
//           ) {
//             if (!value.match(PACKAGING_GROUP_MARGIN_REGEX)) {
//               throw this.createError({
//                 path: `[${this.options.index}].${this.parent.id}`,
//                 message: 'invalid decimal',
//               });
//             }

//             return true;
//           }),
//       })
//     ),
//   })
// );

export const packagingMarginSchema = yup.array().of(
  yup.object({
    groups: yup.array().of(
      yup.object({
        parentId: yup.string(),
        value: yup
          .string()
          .test('isValidInput', INVALID_DECIMAL_ERROR, function (
            this: any,
            value: any
          ) {
            const entityId = this.parent.parentId;
            const packagingGroupId = this.parent.id;

            if (isNaN(Number(value))) {
              throw this.createError({
                path: `${entityId}.${packagingGroupId}`,
                message: 'Please enter valid decimal where max value is 99.99%',
              });
            }

            if (Number(value) < MINIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE) {
              throw this.createError({
                path: `${entityId}.${packagingGroupId}`,
                message: `Value must be more or equal to ${MINIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE}%`,
              });
            }

            if (Number(value) > MAXIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE) {
              throw this.createError({
                path: `${entityId}.${packagingGroupId}`,
                message: `Value must be lower or equal to ${MAXIMUM_PACKAGING_DRIVER_ALLOCATION_VALUE}%`,
              });
            }

            return true;
          }),
      })
    ),
  })
);
