import {
  sortGroup1,
  sortGroup2,
  sortGroup3,
  sortGroup4,
  sortingManager,
  sortProductValueAllocation,
} from './utils/sorting';

export const headCells = [
  {
    id: 'productManager',
    disablePadding: false,
    label: 'Product Manager',
    sort: sortingManager,
  },
  {
    id: 'productGroup1',
    disablePadding: false,
    label: 'Product Group 1',
    sort: sortGroup1,
  },
  {
    id: 'productGroup2',
    disablePadding: false,
    label: 'Product Group 2',
    sort: sortGroup2,
  },
  {
    id: 'productGroup3',
    disablePadding: false,
    label: 'Product Group 3',
    sort: sortGroup3,
  },
  {
    id: 'productGroup4',
    disablePadding: false,
    label: 'Product Group 4',
    sort: sortGroup4,
  },
  {
    id: 'productValue',
    disablePadding: false,
    label: 'Product Value',
    sort: sortProductValueAllocation,
  },
  {
    id: 'default',
    disablePadding: false,
    label: 'Default',
    color: '#5B7BB9',
    // sort: sortGroup1,
  },
];
