import { defineActionGroup } from 'rd-redux-utils';
import {
  CommercialOrganizations,
  LookupValueSystem,
  PackagingGroup,
  ProductAllocationValue,
  ProductGroupAllocation,
  ProductGroupAllocationInfo,
  ProductGroupAllocationLevel,
  UpdateWizardVolumeDriver,
  ValueAllocation,
  ValueSystem,
  WizardBaseMarginEntryState,
  WizardMarginSystem,
  WizardPGAVolumeDriver,
  WizardBaseMarginMatrixOrigin,
  WizardBaseMarginMatrixState,
  WizardPackagingMarginOrigin,
} from '@shared/types/wizard';
import { WizardBMMIndustry } from '@shared/types/wizard';
import { WizardPackagingMarginState } from '@shared/types/wizard';
import { OptionItem } from '@app/modules/Simulator/components/TargetPriceResults/utils';

export const wizardActionGroup = defineActionGroup('WIZARD_ACTIONS');

//=== Simulator === 

export const setSelectedSimulatorCoAction = wizardActionGroup.defineAction<{
  payload: OptionItem | null;
}>('SET SELECTED SIMULATOR CO');

export const setSelectedSimulatorProductAction = wizardActionGroup.defineAction<{
  payload: OptionItem | null;
}>('SET SELECTED SIMULATOR PRODUCT');

export const setSelectedSimulatorValueAllocationAction = wizardActionGroup.defineAction<{
  payload: OptionItem | null;
}>('SET SELECTED SIMULATOR VALUE ALLOCATION');

//===== BASE =====
export const setWizardLoadingAction = wizardActionGroup.defineAction<{
  payload: boolean;
}>('SET WIZARD LOADING');

export const setWizardCOIdAction = wizardActionGroup.defineAction<{
  payload: string; // coId
}>('SET WIZARD COID');

export const setWizardCommercialOrganizationsAction = wizardActionGroup.defineAction<{
  payload: CommercialOrganizations[];
}>('SET WIZARD COMMERCIAL ORGANIZATIONS');

//===== DATA SETTERS =====
export const setWizardVolumeSystemAction = wizardActionGroup.defineAction<{
  payload: ValueSystem[];
}>('SET WIZARD VOLUME SYSTEM');

export const setWizardLookupVolumeSystemAction = wizardActionGroup.defineAction<{
  payload: LookupValueSystem[];
}>('SET WIZARD LOOKUP VOLUME SYSTEM');

export const setWizardProductGroupAllocationLevelsAction = wizardActionGroup.defineAction<{
  payload: ProductGroupAllocationLevel[];
}>('SET WIZARD PRODUCT GROUP ALLOCATION LEVELS');

export const setWizardCurrentAllocationLevelsAction = wizardActionGroup.defineAction<{
  payload: ProductGroupAllocationLevel;
}>('SET WIZARD CURRENT ALLOCATION LEVELS');

export const setWizardProductGroupAllocationAction = wizardActionGroup.defineAction<{
  payload: {
    total: number;
    data: ProductGroupAllocation[];
  };
}>('SET WIZARD PRODUCT GROUP ALLOCATION');

export const setWizardProductAllocationInfoAction = wizardActionGroup.defineAction<{
  payload: ProductGroupAllocationInfo[];
}>('SET WIZARD PRODUCT ALLOCATION INFO');

export const setWizardUpdateProductAllocationInfoAction = wizardActionGroup.defineAction<{
  payload: Record<string, ProductGroupAllocationInfo>;
}>('SET WIZARD UPDATE PRODUCT ALLOCATION INFO');

export const setWizardCurrentsValuesAllocationAction = wizardActionGroup.defineAction<{
  payload: ProductAllocationValue[];
}>('SET WIZARD CURRENTS VALUES ALLOCATION');

export const setWizardPackagingGroupAction = wizardActionGroup.defineAction<{
  payload: PackagingGroup[];
}>('SET WIZARD PACKAGING GROUP');

export const setWizardMarginSystemAction = wizardActionGroup.defineAction<{
  payload: WizardMarginSystem[];
}>('SET WIZARD MARGIN SYSTEM');

export const setWizardPGAVolumeDriverAction = wizardActionGroup.defineAction<{
  payload: WizardPGAVolumeDriver[];
}>('SET PGA VOLUME DRIVER');

export const setUpdateWizardPGAVolumeDriverAction = wizardActionGroup.defineAction<{
  payload: Record<string, UpdateWizardVolumeDriver>;
}>('SET UPDATE PGA VOLUME DRIVER');

//===== DATA FETCH =====
export const fetchWizardCOIdAction = wizardActionGroup.defineAction(
  'FETCH WIZARD COID'
);

export const fetchWizardVolumeSystemAction = wizardActionGroup.defineAction(
  'FETCH WIZARD VOLUME SYSTEM'
);

export const fetchWizardLookupVolumeSystemAction = wizardActionGroup.defineAction<{
  payloads?: string[]; // volumeSystemIds
}>('FETCH WIZARD LOOKUP VOLUME SYSTEM');

export const fetchWizardProductGroupAllocationLevelsAction = wizardActionGroup.defineAction(
  'FETCH WIZARD PRODUCT GROUP ALLOCATION LEVELS'
);

export const fetchWizardProductGroupAllocationAction = wizardActionGroup.defineAction<{
  payloads: {
    productGroupAllocIds?: string[];
    valueAllocations?: ValueAllocation[];
    limit: number;
    page: number;
    orderColumn: number;
    isAsc: boolean;
  };
}>('FETCH WIZARD PRODUCT GROUP ALLOCATION');

export const fetchWizardProductGroupInfoAction = wizardActionGroup.defineAction(
  'FETCH WIZARD PRODUCT GROUP INFO'
);

export const fetchWizardPackagingAction = wizardActionGroup.defineAction(
  'FETCH WIZARD PACKAGING GROUP'
);

export const fetchWizardMarginSystemAction = wizardActionGroup.defineAction(
  'FETCH WIZARD MARGIN SYSTEM'
);

export const fetchWizardPGAVolumeDriverAction = wizardActionGroup.defineAction(
  'FETCH WIZARD PGA VOLUME DRIVER'
);

//===== SEND DATA =====

export const sendWizardProductGroupInfoAction = wizardActionGroup.defineAction(
  'SEND WIZARD PRODUCT GROUP INFO'
);

export const sendWizardPGAVolumeDriverAction = wizardActionGroup.defineAction(
  'SEND WIZARD PGA VOLUME DRIVER'
);

//===== BASE MARGIN MATRIX =====

export const setBaseMarginMatrixOriginAction = wizardActionGroup.defineAction<{
  payload: WizardBaseMarginMatrixOrigin[];
}>('SET BASE MARGIN MATRIX ORIGIN');

export const setBaseMarginMatrixStateAction = wizardActionGroup.defineAction<{
  payload: WizardBaseMarginMatrixState[];
}>('SET BASE MARGIN MATRIX STATE');

export const fetchWizardBaseMarginMatrixAction = wizardActionGroup.defineAction(
  'FETCH WIZARD BASE MARGIN MATRIX'
);

export const sendWizardBaseMarginMatrixAction = wizardActionGroup.defineAction(
  'SEND WIZARD BASE MARGIN MATRIX'
);

export const deleteBaseMarginMatrixItemsByIdsAction = wizardActionGroup.defineAction<{
  payload: {
    valueAllocation: number;
    ids: string[];
  };
}>('DELETE BASE MARGIN MATRIX ITEMS BY ID');

export const editBaseMarginMatrixItemByIdAction = wizardActionGroup.defineAction<{
  payload: {
    valueAllocation: number;
    id: string;
    item: WizardBaseMarginEntryState;
  };
}>('EDIT BASE MARGIN MATRIX ITEM BY ID');

export const editDefaultBaseMarginMatrixAction = wizardActionGroup.defineAction<{
  payload: {
    valueAllocation: number;
    marginValues: string;
    marginTypes: number;
  };
}>('EDIT DEFAULT BASE MARGIN MATRIX');

export const addNewBaseMarginMatrixItemAction = wizardActionGroup.defineAction<{
  payload: {
    valueAllocation: number;
    item: WizardBaseMarginEntryState;
  };
}>('ADD NEW BASE MARGIN MATRIX ITEM');

export const setBaseMarginMatrixIndustriesActions = wizardActionGroup.defineAction<{
  payload: WizardBMMIndustry[];
}>('SET BASE MARGIN MATRIX INDUSTRIES');

export const fetchBaseMargiMatrixIndustriesActions = wizardActionGroup.defineAction<{
  payload: number;
}>('FETCH BASE MARGIN MATRIX INDUSTRIES');

//===== PACKAGING MARGIN =====

export const fetchPackagingMarginOriginAction = wizardActionGroup.defineAction(
  'FETCH PACKAGING MARGIN ORIGIN'
);

export const setPackagingMarginOriginAction = wizardActionGroup.defineAction<{
  payload: WizardPackagingMarginOrigin[];
}>('SET PACKAGING MARGIN ORIGIN');

export const setPackagingMarginStateAction = wizardActionGroup.defineAction<{
  payload: WizardPackagingMarginState;
}>('SET PACKAGING MARGIN STATE');

export const changePackagingMarginValueAction = wizardActionGroup.defineAction<{
  payload: { id: string; index: number; value: string };
}>('CHANGE PACKAGING MARGIN VALUE');

export const deletePackagingMarginAction = wizardActionGroup.defineAction<{
  payload: Record<string, boolean>;
}>('DELETE PACKAGING MARGIN');

export const addPackagingMarginAction = wizardActionGroup.defineAction<{
  payload: { id: string; name: string; values: string[]; marginType: number };
}>('ADD PACKAGING MARGIN');

export const sendPackagingMarginAction = wizardActionGroup.defineAction(
  'SEND PACKAGING MARGIN'
);

//===== TARGET PRICE RESULTS =====

//===== select form =====
export const setTPRCOAction = wizardActionGroup.defineAction<{
  payload: CommercialOrganizations[];
}>('SET WIZARD TPR CO');

export const fetchTPRCOAction = wizardActionGroup.defineAction(
  'FETCH WIZARD TPR CO'
);

export const setTPRProductGroupAction = wizardActionGroup.defineAction<{
  payload: ProductGroupAllocationLevel[];
}>('SET WIZARD TPR PRODUCT GROUP');

export const fetchTPRProductGroupAction = wizardActionGroup.defineAction<{
  payload: string;
}>('FETCH WIZARD TPR PRODUCT GROUP');

export const setTPRIndustriesAction = wizardActionGroup.defineAction<{
  payload: ProductGroupAllocationLevel[];
}>('SET WIZARD TPR INDUSTRIES GROUP');

export const fetchTPRIndustriesAction = wizardActionGroup.defineAction<{
  payload: number;
}>('FETCH WIZARD TPR INDUSTRIES GROUP');

export const setTPRProductValuesAction = wizardActionGroup.defineAction<{
  payload: ProductGroupAllocationInfo[];
}>('SET WIZARD TPR PRODUCT VALUES');

export const fetchTPRProductValuesAction = wizardActionGroup.defineAction<{
  payload: {
    coId: string;
    productId: string;
  };
}>('FETCH WIZARD TPR PRODUCT VALUES');

//===== table data =====

export const setTPRBaseMarginAction = wizardActionGroup.defineAction<{
  payload: WizardBaseMarginMatrixOrigin[];
}>('SET WIZARD TPR BASE MARGIN');

export const setTPRPackagingDriverAction = wizardActionGroup.defineAction<{
  payload: WizardPackagingMarginOrigin[];
}>('SET WIZARD TPR PACKAGING DRIVER');

export const setTPRVolumeDriverAction = wizardActionGroup.defineAction<{
  payload: WizardPGAVolumeDriver[];
}>('SET WIZARD TPR VOLUME DRIVER');

export const setTPRMarginVolumeDriverAction = wizardActionGroup.defineAction<{
  payload: LookupValueSystem[];
}>('SET WIZARD TPR MARGIN VOLUME DRIVER');

export const fetchTPRTableDataAction = wizardActionGroup.defineAction<{
  payloads: {
    coId: string;
    productId: string;
    valuesAllocations: string;
  };
}>('SET WIZARD TPR TABLE DATA');
