import { ProductsApi } from '@app/services/product-service/product-service';
import { TServiceProducts } from '@app/services/product-service/response-interfaces/get-products.response';
import {
  getCurrentPageLimit,
  getCurrentSkip,
} from '@app/shared/components/AppTablePagination/paginationUtils';
import { throttleEffect } from '@app/shared/utils/sagaUtils/sagaUtils';
import { call, put } from 'redux-saga/effects';
import {
  fetchProductsAction,
  setProductsDataAction,
  setProductsLoadingAction,
} from '../actions';

export function* fetchProductsSaga(
  action: ReturnType<typeof fetchProductsAction>
) {
  try {
    yield put(setProductsLoadingAction({ loading: true }));

    const request: TServiceProducts = yield throttleEffect(
      call(ProductsApi.getProducts, {
        groups: action.groups,
        limit: getCurrentPageLimit(),
        skip: getCurrentSkip(),
      })
    );

    yield put(
      setProductsDataAction({ payload: request.products, count: request.total })
    );
  } catch (e) {
    console.log('Failed to fetch products', e.message);
  } finally {
    yield put(setProductsLoadingAction({ loading: false }));
  }
}
