import {
  formatErrorMessageTemplate,
  MARGIN_SYSTEM_DECIMAL_REGEX,
  MAXIMUM_LENGTH_ERROR_TEMPLATE,
  MAXIMUM_MARGIN_SYSTEM_LEVEL_VALUE,
  MAXIMUM_MARGIN_SYSTEM_NAME_LENGTH,
  MINIMUM_LENGTH_ERROR_TEMPLATE,
  MINIMUM_MARGIN_SYSTEM_LEVEL_VALUE,
  MINIMUM_MARGIN_SYSTEM_NAME_LENGTH,
  INVALID_DECIMAL_VALUE_ERROR_TEMPLATE,
  REQUIRED_FIELD_ERROR_MESSAGE,
  INVALID_SYSTEM_MARGIN_LEVEL_ERROR_TEMPLATE,
  MARGIN_SYSTEM_MAX_MARGIN_VALUE,
  MARGIN_SYSTEM_MIN_MARGIN_VALUE,
} from '@app/shared/validation';
import { customYup as yup } from '@app/vendor/customYup';

const MINIMUM_NAME_LENGTH_ERROR = formatErrorMessageTemplate(
  MINIMUM_LENGTH_ERROR_TEMPLATE,
  [{ pattern: '{length}', value: MINIMUM_MARGIN_SYSTEM_NAME_LENGTH }]
);
const MAXIMUM_NAME_LENGTH_ERROR = formatErrorMessageTemplate(
  MAXIMUM_LENGTH_ERROR_TEMPLATE,
  [{ pattern: '{length}', value: MAXIMUM_MARGIN_SYSTEM_NAME_LENGTH }]
);

const INVALID_DECIMAL_ERROR = formatErrorMessageTemplate(
  INVALID_DECIMAL_VALUE_ERROR_TEMPLATE,
  [
    {
      pattern: '{min}',
      value: MINIMUM_MARGIN_SYSTEM_LEVEL_VALUE,
    },
    { pattern: '{max}', value: MAXIMUM_MARGIN_SYSTEM_LEVEL_VALUE },
  ]
);

const levelSchema = yup
  .number()

  .required(REQUIRED_FIELD_ERROR_MESSAGE)
  .nullable()
  .isDecimal(MARGIN_SYSTEM_DECIMAL_REGEX, INVALID_DECIMAL_ERROR);

export const marginSystemFormSchema = yup.object({
  name: yup
    .string()
    .required(REQUIRED_FIELD_ERROR_MESSAGE)
    .min(MINIMUM_MARGIN_SYSTEM_NAME_LENGTH, MINIMUM_NAME_LENGTH_ERROR)
    .max(MAXIMUM_MARGIN_SYSTEM_NAME_LENGTH, MAXIMUM_NAME_LENGTH_ERROR),
  description: yup.string().nullable(),
  firstLevel: levelSchema
    .min(0.0, 'Level 1 value must be greater than or equal to level 2')
    .max(MARGIN_SYSTEM_MAX_MARGIN_VALUE, `Maximum value is ${MARGIN_SYSTEM_MAX_MARGIN_VALUE}`),

  thirdLevel: levelSchema
    .max(
      yup.ref('secondLevel'),
      formatErrorMessageTemplate(INVALID_SYSTEM_MARGIN_LEVEL_ERROR_TEMPLATE, [
        { pattern: '{issuer}', value: 'Level 3' },
        { pattern: '{dependency}', value: 'Level 2' },
      ])
    )
    .min(MARGIN_SYSTEM_MIN_MARGIN_VALUE, `Minimum value is ${MARGIN_SYSTEM_MIN_MARGIN_VALUE}`),
  fourthLevel: levelSchema
    .max(
      yup.ref('thirdLevel'),
      formatErrorMessageTemplate(INVALID_SYSTEM_MARGIN_LEVEL_ERROR_TEMPLATE, [
        { pattern: '{issuer}', value: 'Level 4' },
        { pattern: '{dependency}', value: 'Level 3' },
      ])
    )
    .min(MARGIN_SYSTEM_MIN_MARGIN_VALUE, `Minimum value is ${MARGIN_SYSTEM_MIN_MARGIN_VALUE}`),

  fifthLevel: levelSchema
    .max(
      yup.ref('fourthLevel'),
      formatErrorMessageTemplate(INVALID_SYSTEM_MARGIN_LEVEL_ERROR_TEMPLATE, [
        { pattern: '{issuer}', value: 'Level 5' },
        { pattern: '{dependency}', value: 'Level 4' },
      ])
    )
    .min(MARGIN_SYSTEM_MIN_MARGIN_VALUE, `Minimum value is ${MARGIN_SYSTEM_MIN_MARGIN_VALUE}`),
});
